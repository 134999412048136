import React from "react";
import { Text, View, StyleSheet, StyleProp, ViewStyle } from "react-native";
import {
  COLOR_BLUE,
  COLOR_BLUE_TESTWE,
  COLOR_RED,
  COLOR_WHITE,
  COLOR_YELLOW_INFO
} from "../../../static/misc/colors";
import {
  FONTSIZE_14,
  FONTSIZE_16,
  FONT_GILROY_BOLD,
  FONT_GILROY_REGULAR,
  PADDING_SIDES,
  WIDTH_6,
  WIDTH_8
} from "../../../static/misc/constants";
import { checkGreen, crossIcon } from "../../../static/misc/images";
import { guidGenerator } from "../../../static/misc/utils";
import { httpStatus } from "../../modules/main/types/status";
import i18n from "../../services/i18n";
import Icon from "../atoms/Icon";
import Loader from "../atoms/Loader";
import Button from "./Button";

export interface ExamTakingSyncProps {
  containerStyle?: StyleProp<ViewStyle>;
  requestStatus: httpStatus;
  navigateToHomepage: () => void;
}

export interface ExamTakingSyncState {
  canQuitTimer: boolean;
}

export default class ExamTakingSync extends React.Component<
  ExamTakingSyncProps,
  ExamTakingSyncState
> {
  constructor(props: ExamTakingSyncProps) {
    super(props);

    this.state = {
      canQuitTimer: false
    };
  }

  componentDidMount(): void {
    // We add this timer for SEB
    // in case the pending action is locked due to a bug on media sending
    // we allow the user to forcequit the application
    // if we dont add this button and timeout, the user will be stuck in SEB's closebook
    setTimeout(() => {
      this.setState({ canQuitTimer: true });
    }, 60000);
  }

  shouldComponentUpdate(nextProps: ExamTakingSyncProps): boolean {
    const { requestStatus } = this.props;
    return nextProps.requestStatus !== requestStatus;
  }

  render(): JSX.Element {
    const { containerStyle, requestStatus, navigateToHomepage } = this.props;
    const { canQuitTimer } = this.state;

    switch (requestStatus) {
      case httpStatus.SUCCESS: {
        return (
          <View style={[styles.contentContainer, containerStyle]}>
            <View style={styles.titleContainer}>
              <Text style={styles.titleText}>
                {i18n.t("syncModal.successTitle")}
              </Text>
            </View>
            <View style={styles.labelContainer}>
              <Text style={styles.labelText}>
                {i18n.t("syncModal.successLabel1")}
              </Text>
              <Text style={styles.labelText}>
                {i18n.t("syncModal.successLabel2")}
              </Text>
            </View>
            <View style={styles.iconContainer}>
              <View style={styles.iconSuccessContainer}>
                <Icon icon={checkGreen} iconStyle={styles.iconSuccess} />
              </View>
            </View>
            <View style={styles.buttonsContainer}>
              <Button
                containerStyle={[styles.mainButton]}
                label={i18n.t("syncModal.endExamMode")}
                onPress={() => navigateToHomepage()}
              />
            </View>
          </View>
        );
      }
      case httpStatus.FAIL: {
        return (
          <View style={[styles.contentContainer, containerStyle]}>
            <View style={styles.titleContainer}>
              <Text style={styles.titleText}>
                {i18n.t("syncModal.failTitle")}
              </Text>
            </View>
            <View style={styles.labelContainer}>
              <Text style={styles.labelText}>
                {i18n.t("syncModal.failLabel1")}
              </Text>
              <Text style={styles.labelText}>
                {i18n.t("syncModal.failLabel2")}
              </Text>
            </View>
            <View style={styles.iconContainer}>
              <View style={styles.iconFailContainer}>
                <Icon icon={crossIcon} iconStyle={styles.iconFail} />
              </View>
            </View>
            <View style={styles.buttonsContainer}>
              {/* TODO button is desactivated because retry never trigger when clicked */}
              {/* <Button
                containerStyle={[styles.mainButton]}
                icon={reloadIcon}
                label={i18n.t("syncModal.retry")}
                onPress={() => retry()}
              /> */}
              <Button
                containerStyle={[styles.secondaryButton]}
                textStyle={styles.secondaryButtonText}
                label={i18n.t("syncModal.endExamMode")}
                onPress={() => navigateToHomepage()}
              />
            </View>
          </View>
        );
      }
      case httpStatus.INIT:
      case httpStatus.PENDING:
      case httpStatus.NONE:
      default: {
        return (
          <View style={[styles.contentContainer, containerStyle]}>
            <View style={styles.titleContainer}>
              <Text style={styles.titleText}>
                {i18n.t("syncModal.pendingTitle")}
              </Text>
            </View>
            <View style={styles.labelContainer}>
              <Text style={styles.labelText}>
                {i18n.t("syncModal.pendingLabel1")}
              </Text>
            </View>
            <View style={styles.iconContainer}>
              <Loader key={guidGenerator()} iconStyle={styles.loader} />
            </View>
            {window && window.navigator.userAgent.includes("SEB") && (
              <View style={styles.buttonsContainer}>
                <Button
                  disabled={!canQuitTimer}
                  containerStyle={[styles.mainButton]}
                  label={i18n.t("userMenu.leaveSeb")}
                  onPress={() => navigateToHomepage()}
                />
              </View>
            )}
          </View>
        );
      }
    }
  }
}

const styles = StyleSheet.create({
  contentContainer: {
    // flexGrow: 1,
    flexDirection: "column"
  },
  titleContainer: {
    flex: 1,
    paddingVertical: PADDING_SIDES * 0.2,
    paddingHorizontal: PADDING_SIDES * 0.5,
    justifyContent: "center",
    alignItems: "center",
    minHeight: "fit-content"
  },
  titleText: {
    fontFamily: FONT_GILROY_BOLD,
    fontSize: FONTSIZE_16,
    color: COLOR_BLUE,
    textAlign: "center",
    width: WIDTH_8
  },
  labelContainer: {
    flex: 1,
    paddingVertical: PADDING_SIDES * 0.2,
    paddingHorizontal: PADDING_SIDES * 0.5,
    justifyContent: "center",
    alignItems: "center",
    minHeight: "fit-content"
  },
  labelText: {
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_14,
    color: COLOR_BLUE_TESTWE,
    textAlign: "center",
    width: WIDTH_8
  },
  iconContainer: {
    flex: 3,
    paddingVertical: PADDING_SIDES * 0.2,
    paddingHorizontal: PADDING_SIDES * 0.5,
    alignItems: "center",
    justifyContent: "center",
    minHeight: "fit-content"
  },
  loader: {
    width: 50,
    height: 50
  },
  iconSuccessContainer: {
    // backgroundColor: COLOR_GREEN_VALID,
    borderRadius: 50
  },
  iconSuccess: {
    width: 50,
    height: 50
  },
  iconFailContainer: {
    backgroundColor: COLOR_YELLOW_INFO,
    borderRadius: 50,
    alignItems: "center",
    justifyContent: "center",
    width: 50,
    height: 50
  },
  iconFail: {
    width: 20,
    height: 20,
    marginLeft: -1,
    marginTop: -1
  },
  buttonsContainer: {
    flex: 2,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: PADDING_SIDES * 0.2
  },
  mainButton: {
    width: WIDTH_6,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: COLOR_RED
  },
  secondaryButton: {
    width: WIDTH_6,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: COLOR_BLUE_TESTWE,
    backgroundColor: COLOR_WHITE,
    marginTop: PADDING_SIDES * 0.2
  },
  secondaryButtonText: {
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_14,
    color: COLOR_BLUE_TESTWE
  }
});
