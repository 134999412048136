/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  GetErrorAction,
  GETERROR,
  RESET_ERROR,
  ResetErrorAction,
  LocalApiErrorAction,
  LOCAL_API_ERROR
} from "../types/error";

export const resetError = (): ResetErrorAction => {
  return {
    type: RESET_ERROR,
    payload: {}
  };
};

export const getError = (
  errorMessage: string,
  forceLogout: boolean
): GetErrorAction => {
  return {
    type: GETERROR,
    payload: {
      forceLogout,
      errorMessage
    }
  };
};

export const localApiError = (error: any): LocalApiErrorAction => {
  return {
    type: LOCAL_API_ERROR,
    payload: {
      error
    }
  };
};
