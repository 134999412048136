import React from "react";
import Tippy from "@tippyjs/react";
import { Text, View, StyleSheet } from "react-native";

import {
  COLOR_BLUE_TESTWE,
  COLOR_BLUE_TESTWE_50,
  COLOR_GREEN_VALID,
  COLOR_GREY_EXAM_NAVBAR_COLOR_CODE,
  COLOR_SHADOW,
  COLOR_WHITE,
  COLOR_YELLOW_INFO
} from "../../../static/misc/colors";
import {
  chronoBlue,
  chronoLocked,
  questionMarkBlue
} from "../../../static/misc/images";
import Icon from "../atoms/Icon";
import { guidGenerator } from "../../../static/misc/utils";
import { FONT_GILROY_BOLD } from "../../../static/misc/constants";
import i18n from "../../services/i18n";

const Rectangle = ({ color }: { color: string }) => (
  <View style={[styles.rectangle, { backgroundColor: color }]} />
);

type TooltipDescriptionOject = {
  icon: JSX.Element;
  text: string;
};

type ExamDescriptionIconProps = {
  partOrQuestionWithChrono: boolean;
  partOrQuestionChronoAndLocked: boolean;
};
class ExamDescriptionIcon extends React.PureComponent<ExamDescriptionIconProps> {
  getDescriptions = (): TooltipDescriptionOject[] => {
    const {
      partOrQuestionWithChrono,
      partOrQuestionChronoAndLocked
    } = this.props;
    const descriptionsDefault = [
      {
        icon: <Rectangle color={COLOR_BLUE_TESTWE} />,
        text: i18n.t("examNavbar.examDescriptions.currentQuestionNotAnswered")
      },
      {
        icon: <Rectangle color={COLOR_GREY_EXAM_NAVBAR_COLOR_CODE} />,
        text: i18n.t("examNavbar.examDescriptions.questionNotVisited")
      },
      {
        icon: <Rectangle color={COLOR_GREEN_VALID} />,
        text: i18n.t("examNavbar.examDescriptions.questionAnswered")
      },
      {
        icon: <Rectangle color={COLOR_YELLOW_INFO} />,
        text: i18n.t("examNavbar.examDescriptions.questionBookmarked")
      },
      {
        icon: <Rectangle color={COLOR_BLUE_TESTWE_50} />,
        text: i18n.t("examNavbar.examDescriptions.questionVisited")
      }
    ];

    if (partOrQuestionWithChrono) {
      descriptionsDefault.push({
        icon: <Icon icon={chronoBlue} iconStyle={{ width: 25, height: 25 }} />,
        text: i18n.t("examNavbar.examDescriptions.questionChrono")
      });
    }

    if (partOrQuestionChronoAndLocked) {
      descriptionsDefault.push({
        icon: (
          <Icon icon={chronoLocked} iconStyle={{ width: 25, height: 25 }} />
        ),
        text: i18n.t("examNavbar.examDescriptions.questionChronoLocked")
      });
    }

    return descriptionsDefault;
  };

  buildTooltipContent = (): JSX.Element => (
    <View style={styles.tooltipView}>
      <Text style={styles.tooltipBoldTitle}>
        {i18n.t("examNavbar.examDescriptions.title")}
      </Text>
      <View>
        {this.getDescriptions().map(({ icon, text }) => (
          <View key={guidGenerator()} style={styles.tooltipDescriptionRow}>
            <View style={{ marginRight: 8 }}>{icon}</View>
            <Text>{text}</Text>
          </View>
        ))}
      </View>
    </View>
  );

  render(): JSX.Element {
    return (
      <Tippy placement="bottom" content={this.buildTooltipContent()}>
        <View
          style={[
            styles.container,
            { borderBottom: `1px solid ${COLOR_GREY_EXAM_NAVBAR_COLOR_CODE}` }
          ]}
        >
          <Icon icon={questionMarkBlue} iconStyle={{ width: 28, height: 28 }} />
        </View>
      </Tippy>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    height: 26,
    width: 26,
    marginTop: 6,
    paddingBottom: 8,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  tooltipView: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: COLOR_WHITE,
    borderRadius: 5,
    padding: 8,
    shadowColor: COLOR_SHADOW,
    shadowOffset: {
      width: 0,
      height: 0
    }
  },
  tooltipBoldTitle: {
    color: COLOR_BLUE_TESTWE,
    fontFamily: FONT_GILROY_BOLD,
    fontSize: 16,
    marginBottom: 16
  },
  rectangle: {
    borderRadius: 8,
    width: 25,
    height: 8
  },
  tooltipDescriptionRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 8
  }
});

export default ExamDescriptionIcon;
