import React from "react";
import { StyleSheet, View, ScrollView } from "react-native";
import { COLOR_WHITE } from "../../../../static/misc/colors";
import { WIN } from "../../../../static/misc/constants";
import {
  DisplayedExamsEnum,
  DisplayedExamsOrderEnum,
  ExamsProps
} from "../../../modules/exams/ExamsView";
import TopBodyHomepage from "../homepage/TopBodyHomepage";
import { ExamsList } from "../../organisms/ExamsList";

class ExamsHomepage extends React.PureComponent<ExamsProps> {
  render(): JSX.Element {
    const {
      token,
      locale,
      isOnline,
      nextExams,
      passedExams,
      ordered,
      navigation,
      displayedExams,
      selectedAcademicYear,
      attachedFiles,
      updateDisplayedExams,
      changeExamsOrder,
      updateSelectedAcademicYear,
      displaySingleExamInfo,
      getMyPaperBase64
    } = this.props;

    return (
      <ScrollView
        style={{ backgroundColor: COLOR_WHITE, flexGrow: 1 }}
        contentContainerStyle={styles.container}
      >
        <View style={{ flexGrow: 1 }}>
          <TopBodyHomepage
            nextExams={nextExams}
            displaySingleExamInfo={displaySingleExamInfo}
            attachedFiles={attachedFiles}
          />
          <View style={styles.row}>
            <ExamsList
              token={token}
              locale={locale}
              isOnline={isOnline}
              nextExams={nextExams}
              passedExams={passedExams}
              attachedFiles={attachedFiles}
              navigation={navigation}
              displayedExams={displayedExams}
              selectedAcademicYear={selectedAcademicYear}
              ordered={ordered}
              updateDisplayedExams={(examType: DisplayedExamsEnum) =>
                updateDisplayedExams(examType)
              }
              changeExamsOrder={(
                examType: DisplayedExamsEnum,
                order: DisplayedExamsOrderEnum,
                category: string
              ) => changeExamsOrder(examType, order, category)}
              updateSelectedAcademicYear={(academicYear?: string) =>
                updateSelectedAcademicYear(academicYear)
              }
              getMyPaperBase64={getMyPaperBase64}
              displaySingleExamInfo={displaySingleExamInfo}
            />
          </View>
        </View>
      </ScrollView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    minWidth: 0.8 * WIN.width,
    flexGrow: 1
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    flexGrow: 1
  }
});

export default ExamsHomepage;
