import React from "react";
import { StyleSheet, View } from "react-native";
import {
  COLOR_BLUE_TESTWE,
  COLOR_YELLOW_INFO
} from "../../../static/misc/colors";
import {
  ASPECT_RATIO,
  FONTSIZE_13,
  FONTSIZE_16,
  FONTSIZE_21,
  FONT_GILROY_REGULAR,
  PADDING_SIDES
} from "../../../static/misc/constants";
import CustomModal from "../atoms/CustomModal";
import ExtraBoldTitle from "../atoms/ExtraBoldTitle";
import LightText from "../atoms/LightText";
import TokenItem from "./TokenItem";
import YellowBanner from "../atoms/YellowBanner";
import i18n from "../../services/i18n";

export interface TokenModalProps {
  isVisible: boolean;
  examLaunching: boolean;
  isTimer: boolean;
  schoolSubject: string;
  examTime: string;
  showTokenModal: () => void;
  onPress: (userInput: string) => void;
}

class TokenModal extends React.PureComponent<TokenModalProps> {
  render(): JSX.Element {
    const {
      isVisible,
      schoolSubject,
      examTime,
      isTimer,
      examLaunching,
      onPress,
      showTokenModal
    } = this.props;
    return (
      <CustomModal
        heightModal={isTimer ? 496 : 340}
        widthModal={471 * (ASPECT_RATIO * 2)}
        visible={isVisible}
        showCloseButton
        onCloseModal={() => showTokenModal()}
      >
        <View>
          <ExtraBoldTitle
            text={schoolSubject}
            textStyle={styles.schoolSubjectTextStyle}
          />
          <LightText text={examTime} textStyle={[styles.examTimeTextStyle]} />
        </View>
        {isTimer ? (
          <YellowBanner
            containerStyle={styles.yellowBannerContainer}
            textStyle={styles.yellowBannerText}
            textBanner={i18n.t("tokenModal.timerAlert")}
          />
        ) : null}
        <TokenItem
          examLaunching={examLaunching}
          defaultPolicyAccept={false}
          onPress={onPress}
        />
      </CustomModal>
    );
  }
}

const styles = StyleSheet.create({
  yellowBannerContainer: {
    width: "100%",
    height: 110,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: COLOR_YELLOW_INFO,
    borderRadius: 3,
    marginVertical: PADDING_SIDES * 0.1
  },
  yellowBannerText: {
    color: COLOR_BLUE_TESTWE,
    fontFamily: FONT_GILROY_REGULAR,
    fontWeight: "500",
    fontSize: FONTSIZE_13
  },
  schoolSubjectTextStyle: {
    marginHorizontal: PADDING_SIDES * 0.3,
    fontSize: FONTSIZE_21
  },
  examTimeTextStyle: {
    marginHorizontal: PADDING_SIDES * 0.3,
    fontSize: FONTSIZE_16
  }
});
export default TokenModal;
