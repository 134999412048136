import { DEFAULT_EQUIPMENT_CHECK } from "../../../../static/misc/constants";
import {
  EquipmentCheckAction,
  EquipmentCheckState,
  GET_LATEST_EQUIPMENT_CHECK_SUCCESS,
  PUT_LATEST_EQUIPMENT_CHECK_SUCCESS
} from "../types/equipmentCheck";

const initialState: EquipmentCheckState = DEFAULT_EQUIPMENT_CHECK;

export default function reducer(
  state = initialState,
  action: EquipmentCheckAction
): EquipmentCheckState {
  switch (action.type) {
    case GET_LATEST_EQUIPMENT_CHECK_SUCCESS:
    case PUT_LATEST_EQUIPMENT_CHECK_SUCCESS:
      return { ...action.payload.data };
    default:
      return { ...state };
  }
}
