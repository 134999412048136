import qs from "query-string";
import { CLEAR_CREDENTIALS } from "../../../../static/misc/constants";
import { ClearCredentialsActions } from "../../../store/rootreducer";
import {
  ConnectUserAction,
  CONNECTUSER,
  CredentialsType,
  RefreshTokenAction,
  REFRESH_TOKEN
} from "../types/auth";
import { TOKEN } from "../../../../static/misc/network";

export function clearCredentials(): ClearCredentialsActions {
  return {
    type: CLEAR_CREDENTIALS,
    payload: {}
  };
}

export function connectUser(credentials: CredentialsType): ConnectUserAction {
  return {
    type: CONNECTUSER,
    payload: credentials
  };
}

export function askRefreshToken(RefreshToken: string): RefreshTokenAction {
  return {
    type: REFRESH_TOKEN,
    payload: {
      client: "keycloak",
      request: {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: qs.stringify({
          grant_type: "refresh_token",
          refresh_token: RefreshToken,
          client_id: "electron"
        }),
        url: TOKEN,
        method: "post"
      }
    }
  };
}
