import React from "react";
import { StyleSheet, View } from "react-native";
import _ from "lodash";
import WarningExam from "../../organisms/WarningExam";
import NextExam from "../../organisms/NextExam";
import {
  EACH_SECONDS,
  PADDING_SIDES,
  WIN
} from "../../../../static/misc/constants";
import { ExamType } from "../../../modules/exams/types/exam";
import moment from "../../../services/moment";
import NextExamPlaceholder from "../../organisms/NextExamPlaceholder";
import i18n from "../../../services/i18n";
import { MediaObjectType } from "../../../modules/exams/types/attachedfiles";

export interface TopBodyHomepageProps {
  nextExams: Array<ExamType>;
  attachedFiles: MediaObjectType[];
  displaySingleExamInfo?: (examInfoId: string) => void;
}

interface TopBodyHomepageState {
  currentExam: ExamType | null;
}
class TopBodyHomepage extends React.Component<
  TopBodyHomepageProps,
  TopBodyHomepageState
> {
  intervalTimer!: ReturnType<typeof setInterval>;

  constructor(props: TopBodyHomepageProps) {
    super(props);
    this.state = {
      currentExam: null
    };
  }

  componentDidMount(): void {
    const { nextExams } = this.props;
    this.intervalTimer = setInterval(() => {
      const nearestExam = nextExams
        .sort((m) => {
          return moment(m.startDate).valueOf() + m.timeZeroSecond * 1000;
        })
        .find((m) => {
          return moment(
            moment(m.startDate).valueOf() + m.timeZeroSecond * 1000
          ).isAfter();
        });
      if (nearestExam) {
        this.setState({ currentExam: nearestExam });
      }
    }, EACH_SECONDS);
  }

  componentWillUnmount(): void {
    clearInterval(this.intervalTimer);
  }

  render(): JSX.Element {
    const { attachedFiles, displaySingleExamInfo } = this.props;
    const { currentExam } = this.state;
    return (
      <>
        <View style={styles.notification}>
          {(currentExam && !_.isEmpty(currentExam) && (
            <NextExam
              timeZeroSecond={currentExam.timeZeroSecond}
              examId={currentExam.id}
              subject={currentExam?.subject?.name || ""}
              date={new Date(currentExam?.startDate)}
              containerStyle={styles.block}
              duration={currentExam?.duration}
              currentAttachedFiles={attachedFiles.find(
                (files) => files.examId === currentExam.id
              )}
              attachedFilesNumber={(currentExam.allAttachedFiles || []).length}
              displaySingleExamInfo={displaySingleExamInfo}
              showTimeOptions={currentExam?.examParams?.showTimeOptions ?? true}
            />
          )) || <NextExamPlaceholder />}
          <WarningExam
            containerStyle={styles.block}
            warningContent={i18n.t("homepage.read")}
            warningTitle={i18n.t("homepage.warning")}
          />
        </View>
      </>
    );
  }
}

const styles = StyleSheet.create({
  notification: {
    marginTop: 30,
    flexDirection: "row",
    justifyContent: "space-between",
    minWidth: WIN.width * 0.6,
    paddingHorizontal: PADDING_SIDES * 0.5
  },
  block: {
    width: "48%",
    minWidth: WIN.width * 0.4
  }
});

export default TopBodyHomepage;
