import React from "react";
import { View, Text, StyleSheet, Image } from "react-native";
import {
  COLOR_BLUE_TESTWE,
  COLOR_TRANSPARENT
} from "../../../static/misc/colors";
import {
  FONTSIZE_13,
  FONT_GILROY_BOLD,
  FONT_GILROY_REGULAR,
  PADDING_SIDES
} from "../../../static/misc/constants";
import { alertIcon } from "../../../static/misc/images";
import i18n from "../../services/i18n";

class ExamTakingRulesChrono extends React.PureComponent {
  render(): JSX.Element {
    return (
      <View style={[styles.container]}>
        <View style={styles.imageContainer}>
          <Image source={alertIcon} style={styles.iconStyle} />
        </View>
        <View style={styles.textContainer}>
          <Text style={[styles.textStyle]}>
            {i18n.t("rules.chrono1_1")}&nbsp;
            <Text style={[styles.boldTextStyle]}>
              {i18n.t("rules.chrono1_2")}
            </Text>
          </Text>
          <Text style={[styles.textStyle]}>{i18n.t("rules.chrono2")}</Text>
          <Text style={[styles.textStyle]}>{i18n.t("rules.chrono3")}</Text>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLOR_TRANSPARENT,
    flexDirection: "row",
    zIndex: 91,
    width: "100%",
    paddingBottom: PADDING_SIDES * 0.5,
    paddingHorizontal: PADDING_SIDES * 0.5,
    flexShrink: 1
  },
  textStyle: {
    alignSelf: "flex-start",
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_13,
    color: COLOR_BLUE_TESTWE,
    paddingBottom: PADDING_SIDES * 0.3,
    paddingLeft: PADDING_SIDES * 0.2
  },
  boldTextStyle: {
    fontFamily: FONT_GILROY_BOLD
  },
  iconStyle: {
    width: 15,
    height: 15
  },
  imageContainer: {
    alignItems: "center",
    width: 20,
    height: 15,
    paddingTop: PADDING_SIDES * 0.1
  },
  textContainer: {
    flexDirection: "column",
    flexShrink: 1
  }
});
export default ExamTakingRulesChrono;
