import React from "react";
import {
  StyleProp,
  StyleSheet,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle
} from "react-native";
import {
  COLOR_GREY_BORDER,
  COLOR_TRANSPARENT
} from "../../../static/misc/colors";
import { PADDING_SIDES } from "../../../static/misc/constants";
import { chevron } from "../../../static/misc/images";
import Icon from "./Icon";
import LightText from "./LightText";

interface InputSelectorProps {
  toggleChoices: () => void;
  containerStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  chevronStyle?: StyleProp<TextStyle>;
  isVisible: boolean;
  choice: string;
  textChevron?: boolean;
}

class InputSelector extends React.PureComponent<InputSelectorProps> {
  render(): JSX.Element {
    const {
      toggleChoices,
      isVisible,
      choice,
      textStyle,
      containerStyle,
      chevronStyle,
      textChevron
    } = this.props;
    return (
      <View>
        <TouchableOpacity
          style={[
            styles.container,
            containerStyle,
            isVisible ? styles.borderWhenToggled : null
          ]}
          onPress={() => toggleChoices()}
        >
          <LightText
            textStyle={[styles.defaultTextStyle, textStyle]}
            text={choice || ""}
          />

          <View style={styles.defaultChevronStyle}>
            {textChevron ? (
              <LightText textStyle={[textStyle, chevronStyle]} text="&#9660;" />
            ) : (
              <Icon icon={chevron} />
            )}
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderWidth: 2,
    width: "100%",
    borderRadius: 5,
    borderColor: COLOR_GREY_BORDER
  },
  borderWhenToggled: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderBottomColor: COLOR_TRANSPARENT
  },
  defaultChevronStyle: {
    paddingRight: PADDING_SIDES * 0.2
  },
  defaultTextStyle: {
    padding: PADDING_SIDES * 0.2,
    flexWrap: "wrap",
    alignItems: "flex-start"
  }
});

export default InputSelector;
