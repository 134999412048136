import React from "react";
import { StyleSheet, View } from "react-native";
import qs from "qs";
import ReloadUpdate from "../../molecules/ReloadUpdate";
import {
  ExamsHash,
  GetExamsHashAction,
  GetSyncExamAction,
  PostSyncronizeAction,
  RemoveExamsAction
} from "../../../modules/exams/types/exams";
import { ExamType } from "../../../modules/exams/types/exam";
import {
  SetNbSyncExamsAction,
  TimeVerificationStatus
} from "../../../modules/main/types/status";
import {
  DownloadMediaObjectAction,
  MediaObjectType,
  SetMediaObjectAction
} from "../../../modules/exams/types/attachedfiles";
import { fileExistsOnFs } from "../../../../static/fileutils";
import {
  ATTACHED_FILES,
  IS_PREVIEW,
  IS_WEB_ENABLE
} from "../../../../static/misc/constants";
import { isAPassedExam } from "../../../../static/misc/utils";
import { MAX_RETRY_NUMBER } from "../../../../static/misc/network";
import {
  ClearExamTakingAction,
  CreateExamTakingAction
} from "../../../modules/examTaking/types/examTaking";

interface ExamsSynchronisationHandlerProps {
  // auth & other checks
  authorizationToken?: string;
  isOnline: boolean;
  // status
  canSynchronise: boolean;
  gettingExamsHash: boolean;
  gettingPreviewExam: boolean;
  showLoader: boolean;
  nbExamsToSync: number;
  nbMediaObjectToGet: number;
  timeVerificationStatus: TimeVerificationStatus;
  // exams
  examsHash: ExamsHash[];
  nextExams: ExamType[];
  passedExams: ExamType[];
  examTaking: ExamType[];
  // files
  attachedFiles: MediaObjectType[];
  appDataPath: string | null;
  appVersion: string;
  osPlatform: string;
  // functions and actions
  backToHomepage: () => void;
  getExamsHash: (
    token: string,
    userVersion: string,
    userOs: string,
    client: string
  ) => GetExamsHashAction;
  setExamsNumberToSync: (index: number) => SetNbSyncExamsAction;
  getSyncPastExam: (token: string, examId: string) => GetSyncExamAction;
  getSyncExam: (token: string, examId: string) => GetSyncExamAction;
  removeExams: (exams: string[]) => RemoveExamsAction;
  postSyncExams: (
    userToken: string,
    examsId: string[],
    version: string
  ) => PostSyncronizeAction;
  setMediaObject: (
    examId: string,
    mediaObjectId: string
  ) => SetMediaObjectAction;
  downloadMediaObject: (
    examId: string,
    mediaId: string,
    userToken: string
  ) => DownloadMediaObjectAction;
  startExamPreview: () => void;
  getPreviewExam: (examId: string, userToken: string) => GetSyncExamAction;
  clearExamTaking: (examId: string | undefined) => ClearExamTakingAction;
  createExamTaking: (currentExam: ExamType) => CreateExamTakingAction;
}

class ExamsSynchronisationHandler extends React.PureComponent<ExamsSynchronisationHandlerProps> {
  isSynchronising = false;

  componentDidUpdate(prevProps: ExamsSynchronisationHandlerProps): void {
    const {
      canSynchronise,
      isOnline,
      showLoader,
      appVersion,
      osPlatform,
      authorizationToken,
      gettingExamsHash,
      gettingPreviewExam,
      nbExamsToSync,
      nbMediaObjectToGet,
      nextExams,
      passedExams,
      attachedFiles,
      examTaking,
      timeVerificationStatus,
      postSyncExams,
      getExamsHash,
      startExamPreview,
      createExamTaking
    } = this.props;

    if (
      !IS_PREVIEW &&
      isOnline &&
      authorizationToken &&
      timeVerificationStatus === TimeVerificationStatus.OK
    ) {
      // retrieving examshash only if cansynchronise just changed
      if (!this.isSynchronising && canSynchronise) {
        this.isSynchronising = true;
        getExamsHash(
          authorizationToken,
          appVersion,
          osPlatform,
          IS_WEB_ENABLE ? "webapp" : "desktop"
        );
      }
      // if gettingExamsHash is false and previous was true, then retrieving hashes
      // is over and we can compare what we currently have to run the exams synchronisation
      else if (
        prevProps.gettingExamsHash !== gettingExamsHash &&
        !gettingExamsHash
      ) {
        this.synchroniseExams();
      } else if (!showLoader && nbMediaObjectToGet > 0) {
        this.downloadAllAttachedFiles();
      } else if (!showLoader && nbExamsToSync === 0) {
        const examsId = Array.from(nextExams.concat(passedExams), (x) => x.id);
        // If nbExamsToSync was previously different from 0, then there was an exam synchro
        if (prevProps.nbExamsToSync !== 0 && examsId.length > 0) {
          postSyncExams(authorizationToken, examsId, appVersion);
        }

        this.synchronizeAttachedFilesInfo();
      }
    } else if (IS_PREVIEW && authorizationToken) {
      if (examTaking.length > 0) {
        startExamPreview();
      } else if (
        gettingPreviewExam === false &&
        nextExams.length > 0 &&
        ((nextExams[0].allAttachedFiles.length > 0 &&
          attachedFiles.length > 0 &&
          nbMediaObjectToGet === 0) ||
          nextExams[0].allAttachedFiles.length === 0) &&
        examTaking.length === 0
      ) {
        const tmp = { ...nextExams[0] };
        createExamTaking(tmp);
      } else if (
        gettingPreviewExam === false &&
        nextExams.length > 0 &&
        attachedFiles.length > 0
      ) {
        this.downloadAllAttachedFiles();
      } else if (
        gettingPreviewExam === false &&
        prevProps.gettingPreviewExam === true &&
        nextExams.length > 0
      ) {
        this.synchronizeAttachedFilesInfo();
      } else if (gettingPreviewExam === false && nextExams.length === 0) {
        this.getExamForPreview();
      }
    }
  }

  // function in order to get the exam preview by creating the examTaking
  getExamForPreview = (): void => {
    const { authorizationToken, getPreviewExam, clearExamTaking } = this.props;

    const args = qs.parse(window.location.search.substring(1));
    if (
      args &&
      args.state &&
      typeof args.state === "string" &&
      authorizationToken
    ) {
      const examId = args.state.split("_")[0];
      // clear the old exam taking (if there is in local storage)
      clearExamTaking(undefined);
      getPreviewExam(examId, authorizationToken);
    }
  };

  downloadAllAttachedFiles(): void {
    const {
      appDataPath,
      attachedFiles,
      authorizationToken,
      downloadMediaObject
    } = this.props;

    if (!authorizationToken || (!IS_PREVIEW && !IS_WEB_ENABLE && !appDataPath))
      return;

    if (!IS_PREVIEW) {
      // if a media is already downloading, no other download must be run until it is over
      const alreadyDownloading = attachedFiles.find((exam) =>
        exam.medias.some((media) => media.isDownloading)
      );

      if (alreadyDownloading) {
        return;
      }
    }

    // checking if there is an exam with media to download
    const examMedias = attachedFiles.find((exam) =>
      exam.medias.some(
        (media) =>
          !media.isDownloading &&
          (!media.isErrored ||
            (media.isErrored && media.retryCount < MAX_RETRY_NUMBER)) &&
          (!media.isDownloaded ||
            (!IS_PREVIEW &&
              !IS_WEB_ENABLE &&
              media.isDownloaded &&
              !fileExistsOnFs(
                media.mediaId,
                appDataPath || "",
                ATTACHED_FILES
              )))
      )
    );

    if (!examMedias) {
      return;
    }

    // retrieving the media to download
    const toDownload = examMedias.medias.find(
      (media) =>
        !media.isDownloading &&
        ((media.isDownloaded &&
          !IS_WEB_ENABLE &&
          !fileExistsOnFs(media.mediaId, appDataPath || "", ATTACHED_FILES)) ||
          !media.isDownloaded)
    );

    // should not be the case, but just in case
    if (!toDownload) {
      return;
    }

    // then download the media
    downloadMediaObject(
      examMedias.examId,
      toDownload.mediaId,
      authorizationToken
    );
  }

  synchronizeAttachedFilesInfo(): void {
    const {
      nextExams,
      passedExams,
      attachedFiles,
      appDataPath,
      setMediaObject
    } = this.props;

    let exams = [];
    if (!IS_PREVIEW) {
      // as we are not sure passedExams and nextExams are correctly sorted at this point, better to do this
      exams = nextExams
        .concat(passedExams)
        .filter((exam) => !isAPassedExam(exam));
    } else {
      exams = nextExams.concat(passedExams);
    }

    // for all incoming exams, check if exam has attached files
    exams.forEach((exam) => {
      if (
        exam.allAttachedFiles &&
        exam.allAttachedFiles.length > 0 &&
        ((!IS_PREVIEW && !IS_WEB_ENABLE && appDataPath) ||
          IS_PREVIEW ||
          IS_WEB_ENABLE)
      ) {
        const mediaObject = attachedFiles.find((mo) => mo.examId === exam.id);

        exam.allAttachedFiles.forEach((attachedFile) => {
          const fileExists = fileExistsOnFs(
            attachedFile,
            appDataPath || "",
            ATTACHED_FILES
          );
          const media = mediaObject?.medias.find(
            (m) => m.mediaId === attachedFile
          );

          if (IS_WEB_ENABLE && media && media.isDownloaded) return;

          if (
            // if not mediaobjects registered for this exam
            !mediaObject ||
            // or at least no media for this attached file
            !media ||
            // or there is a media registered but it is not downloading and does not exist in fs
            // or exists on fs BUT isDownloaded is false
            // or we are on the web and isDownloaded is false
            (media &&
              ((media.isDownloading === false &&
                media.retryCount < MAX_RETRY_NUMBER &&
                !fileExists) ||
                (fileExists && !media.isDownloaded)))
          ) {
            setMediaObject(exam.id, attachedFile);
          }
        });
      }
    });
  }

  synchroniseExams(): void {
    const {
      authorizationToken,
      examsHash,
      nextExams,
      passedExams,
      setExamsNumberToSync,
      getSyncPastExam,
      getSyncExam,
      removeExams
    } = this.props;

    // if no token, no need to go further
    if (!authorizationToken) return;

    // retrieving hashes and id of current stored exams
    const currentHashes: ExamsHash[] = nextExams
      .map((exam) => ({
        id: exam.id,
        hash: exam.hash,
        examFromPast: false
      }))
      .concat(
        passedExams.map((exam) => ({
          id: exam.id,
          hash: exam.hash,
          examFromPast: true
        }))
      );

    // for each exam hash, checking if id and hash is up-to-date
    const examsToSync = examsHash.filter(
      (e) =>
        currentHashes.filter((c) => c.id === e.id && c.hash === e.hash)
          .length === 0
    );

    // removing currently stored exams which hash was not included in getExamsHash answer
    removeExams(
      currentHashes
        .filter(
          (e) =>
            examsHash.filter((c) => c.id === e.id && c.hash === e.hash)
              .length === 0
        )
        .map((x) => x.id)
    );

    setExamsNumberToSync(examsToSync.length);
    // synchronising exams subjects
    examsToSync.forEach((ex) => {
      if (ex.examFromPast) {
        getSyncPastExam(authorizationToken, ex.id);
      } else {
        getSyncExam(authorizationToken, ex.id);
      }
    });
  }

  render(): JSX.Element {
    const { canSynchronise, nbExamsToSync, backToHomepage } = this.props;

    return (
      <View style={styles.viewStyle}>
        {!IS_PREVIEW && !IS_WEB_ENABLE && (
          <ReloadUpdate
            isLoading={canSynchronise && nbExamsToSync > 0}
            backToHomepage={backToHomepage}
          />
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  viewStyle: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  }
});

export default ExamsSynchronisationHandler;
