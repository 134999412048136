export const GET_LATEST_EQUIPMENT_CHECK = "GET_LATEST_EQUIPMENT_CHECK";
export const GET_LATEST_EQUIPMENT_CHECK_SUCCESS =
  "GET_LATEST_EQUIPMENT_CHECK_SUCCESS";
export const GET_LATEST_EQUIPMENT_CHECK_FAIL =
  "GET_LATEST_EQUIPMENT_CHECK_FAIL";

export const PUT_LATEST_EQUIPMENT_CHECK = "PUT_LATEST_EQUIPMENT_CHECK";
export const PUT_LATEST_EQUIPMENT_CHECK_SUCCESS =
  "PUT_LATEST_EQUIPMENT_CHECK_SUCCESS";
export const PUT_LATEST_EQUIPMENT_CHECK_FAIL =
  "PUT_LATEST_EQUIPMENT_CHECK_FAIL";

export interface GetLastestEquipmentCheck {
  type:
    | typeof GET_LATEST_EQUIPMENT_CHECK
    | typeof GET_LATEST_EQUIPMENT_CHECK_SUCCESS
    | typeof GET_LATEST_EQUIPMENT_CHECK_FAIL;
  payload: any;
}

export interface PutLastestEquipmentCheck {
  type:
    | typeof PUT_LATEST_EQUIPMENT_CHECK
    | typeof PUT_LATEST_EQUIPMENT_CHECK_SUCCESS
    | typeof PUT_LATEST_EQUIPMENT_CHECK_FAIL;
  payload: any;
}

export type EquipmentCheckState = EquipmentCheck;

export type EquipmentCheck = {
  syncDate?: string;
  webBrowser?: string;
  webVersion?: string;
  desktopVersion?: string;
  osName?: string;
  osVersion?: string;
  microphone?: boolean;
  sound?: boolean;
  camera?: boolean;
  networkUpload?: number;
  networkDownload?: number;
  exam?: string;
  memoryTotal?: number;
  memoryAvailable?: number;
  proxy?: string;
  screenNumbers?: number;
  screenResolutions?: string[];
};

export type EquipmentCheckAction =
  | GetLastestEquipmentCheck
  | PutLastestEquipmentCheck;
