/* eslint-disable camelcase */
import { Dispatch } from "react";
import { Action, Middleware, MiddlewareAPI } from "redux";
import { ipcRenderer } from "electron";
import _ from "lodash";
import {
  CREATE_ARCHIVE_BEFORE_UPLOAD_SUCCESS,
  DOWNLOAD_MEDIA_OBJECT_FAIL,
  DOWNLOAD_MEDIA_OBJECT_SUCCESS,
  FILE_TYPE_EXAM,
  FILE_TYPE_LOG,
  FILE_TYPE_STUDENTPAPER_NAME,
  GENERATE_SIGNED_URL_SUCCESS
} from "../../modules/exams/types/attachedfiles";
import { writeFileOnFs } from "../../../static/fileutils";
import {
  ATTACHED_FILES,
  EXPORT_FILES,
  IS_PREVIEW,
  IS_WEB_ENABLE
} from "../../../static/misc/constants";
import {
  EXPORT_STUDENT_PAPER,
  EXPORT_STUDENT_PAPER_FAIL,
  EXPORT_STUDENT_PAPER_SUCCESS,
  MyStudentPaperType,
  StudentPaperType
} from "../../modules/examTaking/types/studentPaper";
import {
  createStudentPaper,
  exportStudentPaperToZip
} from "../../modules/examTaking/actions/studentPaper";
import {
  initUploadMediaObjectOnAWS,
  uploadDataOnAWS,
  uploadMediaObjectOnAWS
} from "../../modules/exams/actions/attachedfiles";
import {
  GET_EXAM_FOR_PREVIEW_FAIL,
  GET_SYNC_EXAM_SUCCESS,
  GET_SYNC_PAST_EXAM_SUCCESS
} from "../../modules/exams/types/exams";
import { getError } from "../../modules/main/actions/error";
import i18n from "../../services/i18n";
import { DOWNLOAD_TEST_FILE_SUCCESS } from "../../modules/testing/types/connectionTesting";
import { uploadTestFile } from "../../modules/testing/actions/connectionTesting";
import { hasStudentPaper } from "../../modules/exams/utils";

interface EffectAction extends Action {
  meta?: any;
  error: {
    response: string;
  };
  payload: any;
}

// Homemade middleware to handle files and some other actions
const actionHandlerMiddleware: Middleware = (store: MiddlewareAPI<any>) => (
  next: Dispatch<EffectAction>
) =>
  ((action: EffectAction) => {
    const { isOnline } = store.getState().status;

    // if studentpaper exists in database but not in local, means that the app crashed before saving the sp in local
    // therefore, recreating the student paper locally based on what the API sends
    if (
      action.type === GET_SYNC_EXAM_SUCCESS ||
      action.type === GET_SYNC_PAST_EXAM_SUCCESS
    ) {
      if (
        action.payload.data.myStudentPaper &&
        action.payload.data.myStudentPaper.status &&
        action.payload.data.myStudentPaper.status === "started"
      ) {
        const { studentPapers } = store.getState().studentPaper;
        if (
          !studentPapers
            .map((sp: MyStudentPaperType) => sp.id)
            .includes(action.payload.data.myStudentPaper.id)
        ) {
          const { id: currentUserId } = store.getState().user;
          if (
            !hasStudentPaper(
              action.payload.data.id,
              currentUserId,
              studentPapers
            )
          ) {
            setTimeout(() => {
              store.dispatch(
                createStudentPaper(
                  action.payload.data.examId,
                  currentUserId,
                  undefined,
                  action.payload.data.myStudentPaper.startDate
                )
              );
            }, 100);
          }
        }
      }
      return next(action);
    }
    // dispatching an error message when retrieving exam preview failed
    if (action.type === GET_EXAM_FOR_PREVIEW_FAIL) {
      setTimeout(() => {
        store.dispatch(getError(i18n.t("errorPreview"), false));
      }, 1000);
      return next(action);
    }
    // after asking API to download file
    if (action.type === DOWNLOAD_MEDIA_OBJECT_SUCCESS) {
      if (!IS_PREVIEW && !IS_WEB_ENABLE) {
        try {
          // then write file on filesystem
          writeFileOnFs(
            action.payload.data.data,
            action.payload.data.id,
            store.getState().user.appDataPath,
            ATTACHED_FILES
          );
          // returning original action
          return next(action);
        } catch (err) {
          // if error, change action type to failure and returning it
          const failure = { ...action };
          failure.type = DOWNLOAD_MEDIA_OBJECT_FAIL;
          failure.error.response = "Could not write file on filesystem";
          return next(failure);
        }
      } else {
        return next(action);
      }
    }

    // dispatching upload media object action after generating signed url
    else if (action.type === GENERATE_SIGNED_URL_SUCCESS && isOnline) {
      setTimeout(() => {
        if (!IS_WEB_ENABLE) {
          store.dispatch(
            uploadMediaObjectOnAWS(
              _.includes(
                [FILE_TYPE_LOG],
                action.meta.previousAction.payload.filetype
              )
                ? ""
                : action.meta.previousAction.payload.examId, // examId, empty for logs
              !_.includes(
                [FILE_TYPE_EXAM],
                action.meta.previousAction.payload.filetype
              )
                ? ""
                : action.meta.previousAction.payload.questionId, // questionId, empty for all but audio and spreadsheet files
              action.meta.previousAction.payload.filepath,
              action.payload.data.signedUrl
            )
          );
        } else {
          store.dispatch(
            uploadDataOnAWS(
              action.meta.previousAction.payload.filedata,
              action.payload.data.signedUrl
            )
          );
        }
      }, 1000);
      return next(action);
    }

    // if action requires to be sent to main process
    else if (action.payload?.client === "ipc") {
      // creating listener if not already existing
      // TODO SBE: proctoring live in web version
      if (
        !IS_WEB_ENABLE &&
        ipcRenderer.rawListeners(`${action.type}_RESPOND`).length === 0
      ) {
        ipcRenderer.on(`${action.type}_RESPOND`, (evt, args) => {
          store.dispatch(JSON.parse(args));
        });
      }

      // then sending action to main process
      ipcRenderer.send(action.type, JSON.stringify(action.payload));
      return next(action);
    }

    // dispatching initUploadOnAws after logs or proctoring archive successfully created
    else if (action.type === CREATE_ARCHIVE_BEFORE_UPLOAD_SUCCESS) {
      if (action.payload.data.path !== "" && isOnline) {
        setTimeout(() => {
          store.dispatch(
            initUploadMediaObjectOnAWS(
              store.getState().auth.credentials?.access_token,
              action.meta.previousAction.payload.meta.userId,
              action.meta.previousAction.payload.meta.examId,
              action.meta.previousAction.payload.filename,
              action.meta.previousAction.payload.archiveType,
              action.payload.data,
              null
            )
          );
        }, 1000);
      } else if (action.payload.data.type === "archive") {
        ipcRenderer.send("NO_PROCTOR_ARCHIVE_TO_SEND", {
          examId: action.payload.data.examId
        });
      } else {
        ipcRenderer.send(
          "LOG_INFO",
          "No archive was created, therefore no upload to trigger"
        );
      }
      return next(action);
    }

    // if action needs to export student paper
    else if (action.type === EXPORT_STUDENT_PAPER) {
      // timeout so the success/fail is dispatched after the original action
      setTimeout(() => {
        try {
          // retrieving from store the student paper we want to export
          const spTmp = store
            .getState()
            .studentPaper.studentPapers.find(
              (sp: StudentPaperType) => sp.examId === action.payload.examId
            );

          if (spTmp) {
            // exporting student paper to json file on filesystem
            writeFileOnFs(
              JSON.stringify(spTmp),
              FILE_TYPE_STUDENTPAPER_NAME,
              store.getState().user.appDataPath,
              `${EXPORT_FILES}/${action.payload.examId}`
            );

            // then dispatching the success action
            store.dispatch({
              type: EXPORT_STUDENT_PAPER_SUCCESS,
              meta: { previousAction: action }
            });

            // then dispatching the export to zip action
            store.dispatch(
              exportStudentPaperToZip(
                action.payload.examId,
                store.getState().user.id
              )
            );
          }
        } catch (err) {
          store.dispatch({
            type: EXPORT_STUDENT_PAPER_FAIL,
            meta: { previousAction: action },
            error: err
          });
        }
      }, 1000);
      return next(action);
    } else if (action.type === DOWNLOAD_TEST_FILE_SUCCESS) {
      // eslint-disable-next-line camelcase
      const accessToken = store.getState().auth.credentials?.access_token;

      setTimeout(() => {
        const formData = new FormData();
        formData.append("files[]", action.payload.data, "1Mio.dat");
        if (formData) {
          store.dispatch(uploadTestFile(accessToken, formData));
        }
      }, 100);

      return next(action);
    }

    // for every other case
    else return next(action);
  }) as Dispatch<EffectAction>;

export default actionHandlerMiddleware;
