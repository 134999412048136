import {
  EmitAuthStudentProctoring,
  EmitExamFinishNotifProctoring,
  WS_AUTH_STUDENT,
  WS_EXAM_FINISHED,
  EmitProctoringConnectionStatus,
  WS_EXAM_STUDENT_CONNECTED,
  WS_EXAM_STUDENT_RECONNECTED,
  WS_GET_ROOM,
  WS_GET_ROOM_AGAIN,
  EmitGetProctoringRoom
} from "../types/proctoringlive";

export function authentStudentProctoringLive(
  token: string
): EmitAuthStudentProctoring {
  return {
    type: WS_AUTH_STUDENT,
    payload: {
      token
    }
  };
}

export function notifyEndOfExamProctoringLive(
  examId: string,
  roomId: string,
  userId: string,
  endDate: moment.Moment | string
): EmitExamFinishNotifProctoring {
  return {
    type: WS_EXAM_FINISHED,
    payload: {
      examId,
      roomId,
      userId,
      endDate
    }
  };
}

export function getProctoringRoomForExam(
  again: boolean,
  userId: string,
  examId: string
): EmitGetProctoringRoom {
  return {
    type: again ? WS_GET_ROOM_AGAIN : WS_GET_ROOM,
    payload: {
      userId,
      examId
    }
  };
}

export function sendProctoringConnectionStatus(
  again: boolean,
  examId: string,
  roomId: string,
  userId: string,
  connectedAt: moment.Moment | string
): EmitProctoringConnectionStatus {
  return {
    type: again ? WS_EXAM_STUDENT_RECONNECTED : WS_EXAM_STUDENT_CONNECTED,
    payload: {
      examId,
      roomId,
      userId,
      connectedAt
    }
  };
}
