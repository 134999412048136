import React from "react";
import { StyleSheet, Image, View } from "react-native";
import { sablier } from "../../../static/misc/images";

class WaitIcon extends React.PureComponent {
  render(): JSX.Element {
    return (
      <View style={styles.waitIconContainer}>
        <Image source={sablier} style={styles.waitIcon} />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  waitIconContainer: {
    alignItems: "center",
    justifyContent: "center"
  },
  waitIcon: {
    width: 30,
    height: 20
  }
});

export default WaitIcon;
