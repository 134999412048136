// FILENAME IS images.electron because on web, @1x @2x and @2x are not handle.
// If you want to deploy on web as well, please copy this file and rename it with : images.web.ts

/* eslint-disable prettier/prettier */
import logoTestWe from "../assets/logo/logotype@3x.png";
import logoTestWeBleu from "../assets/logo/logotype-bleu.svg";

// Icons
import wifi from "../assets/icons/wifi@3x.png";
import nowifi from "../assets/icons/no-wifi@3x.png";
import playIcon from "../assets/icons/play@3x.png";
import crossIcon from "../assets/icons/cross@3x.png";
import viewIcon from "../assets/icons/view@3x.png";
import infoIcon from "../assets/icons/info@3x.png";
import cannotViewIcon from "../assets/icons/cannotView@3x.png";
import logoutIcon from "../assets/icons/logout@3x.png";
import bookIcon from "../assets/icons/livre@3x.png";
import parameterIcon from "../assets/icons/parametre@3x.png";
import planetIcon from "../assets/icons/planete@3x.png";
import userIcon from "../assets/icons/user@3x.png";
import checkIcon from "../assets/icons/coche@3x.png";
import crossRedIcon from "../assets/icons/croix@3x.png";
import sablier from "../assets/icons/sablier@3x.png";
import copie from "../assets/icons/copie@3x.png";
import commentary from "../assets/icons/com@3x.png";
import reloadIcon from "../assets/icons/reload-ui@3x.png";
import alertIcon from "../assets/icons/alerte@3x.png";
import bookmarkFilled from "../assets/icons/bookmark-on@3x.png";
import bookmark from "../assets/icons/bookmark@3x.png";
import checkBlue from "../assets/icons/cocheok@3x.png";
import checkWhite from "../assets/icons/cocheok-white@3x.png";
import checkGreen from "../assets/icons/cocheok-green@3x.png";
import coche from "../assets/icons/coche-seule@3x.png";
import placeholderProfil from "../assets/icons/profil@3x.png";
import illuHomepage1 from "../assets/icons/testwe-illu-source-illu-2@3x.png";
import illuHomepage2 from "../assets/icons/testwe-illu-source-loupe-2@3x.png";
import question from "../assets/icons/question@3x.png";
import loaderColor from "../assets/icons/loader-color@3x.png";
import loaderWhite from "../assets/icons/group@3x.png";
import micro from "../assets/icons/micro@3x.png";
import chevron from "../assets/icons/chevron@3x.png";
import watch from "../assets/icons/montre@3x.png";
import horse from "../assets/icons/cheval@3x.png";
import calc from "../assets/icons/calculette@3x.png";
import medias from "../assets/icons/media-2@3x.png";
import keyboard from "../assets/icons/clavier@3x.png";
import qrcode from "../assets/icons/qrcode@3x.png";
import chevronRight from "../assets/icons/chevron-fermer@3x.png";
import chevronLeft from "../assets/icons/chevron-ouvrir@3x.png";
import arrowRight from "../assets/icons/fleche@3x.png";
import battery from "../assets/icons/batterie@3x.png";
import tel from "../assets/icons/tel@3x.png";
import chrono from "../assets/icons/chrono@3x.png";
import arrowBlueBottom from "../assets/icons/flechebluebottom@3x.png";
import arrowBlueRight from "../assets/icons/flecheblueright@3x.png";
import lock from "../assets/icons/lock@3x.png";
import fullBtr from "../assets/icons/batteryIcons/fullBtr.png";
import largeBtr from "../assets/icons/batteryIcons/80Btr.png";
import smallBtr from "../assets/icons/batteryIcons/20Btr.png";
import emptyBtr from "../assets/icons/batteryIcons/emptyBtr.png";
import download from "../assets/icons/download.png";
import download2 from "../assets/icons/download@3x.png";
import download3 from "../assets/icons/download@2x.png";
import sound from "../assets/icons/audio@3x.png";
import idCardTemplate from "../assets/icons/idCardTemplate.svg";
import faceTemplate from "../assets/icons/faceTemplate.svg";
import resume from "../assets/icons/Resume@3x.png";
import outlinePhoto from "../assets/icons/outlinePhoto@2x.png";
import outlineVideo from "../assets/icons/outlineVideo@2x.png";
import networkCheck from "../assets/icons/network-check@3x.png";
import warning from "../assets/icons/warning@3x.png";
import testCamera from "../assets/icons/test-camera@3x.png";
import testMicro from "../assets/icons/test-micro@3x.png";
import testSpeed from "../assets/icons/test-speed@3x.png";
import cableData from "../assets/icons/cable_data_icon@3x.png";
import frontDesk from "../assets/icons/front_desk_icon@3x.png";
import identityCard from "../assets/icons/ididentitycarddriverlicense@3x.png";
import lamp from "../assets/icons/lamp@3x.png";
import permCameraMic from "../assets/icons/perm-camera-mic@3x.png";
import wc from "../assets/icons/wc@3x.png";
import pen from "../assets/icons/pen.png";
import highlighter from "../assets/icons/fa-solid_highlighter@3x.png";
import comment from "../assets/icons/comment.png";
import one from "../assets/icons/one@3x.png";
import two from "../assets/icons/two@3x.png";
import three from "../assets/icons/three@3x.png";
import four from "../assets/icons/four@3x.png";
import five from "../assets/icons/five@3x.png";
import fullscreenIn from "../assets/icons/fullscreenIn@3x.png";
import fullscreenInBlue from "../assets/icons/fullscreenInBlue@3x.png";
import fullscreenOut from "../assets/icons/fullscreenOut@3x.png";
import fullscreenOutBlue from "../assets/icons/fullscreenOutBlue@3x.png";
import establishmentIcon from "../assets/icons/establishment@3x.png";
import downArrow from "../assets/icons/downArrow@3x.png";
import settings from "../assets/icons/settings.svg";
import time from "../assets/icons/time.svg";
import toggle from "../assets/icons/toggle.svg";
import bin from "../assets/icons/bin.png";
import questionMarkBlue from "../assets/icons/question-mark-blue.svg";
import chronoBlue from "../assets/icons/chrono-blue.svg";
import chronoLocked from "../assets/icons/chrono-locked.svg";
import chronoFilledBlue from "../assets/icons/chrono-filled-blue.svg";
import chronoExamPart from "../assets/icons/chrono-part.svg";

export { logoTestWe };

export {
  chronoLocked,
  chronoBlue,
  questionMarkBlue,
  chronoFilledBlue,
  wifi,
  nowifi,
  playIcon,
  fullBtr,
  largeBtr,
  smallBtr,
  emptyBtr,
  placeholderProfil,
  illuHomepage1,
  illuHomepage2,
  download,
  download2,
  download3,
  battery,
  tel,
  crossIcon,
  checkBlue,
  viewIcon,
  infoIcon,
  alertIcon,
  copie,
  bookmarkFilled,
  bookmark,
  commentary,
  cannotViewIcon,
  sablier,
  logoutIcon,
  bookIcon,
  parameterIcon,
  planetIcon,
  checkIcon,
  crossRedIcon,
  userIcon,
  reloadIcon,
  coche,
  question,
  loaderColor,
  loaderWhite,
  chevron,
  micro,
  checkWhite,
  checkGreen,
  watch,
  horse,
  calc,
  medias,
  keyboard,
  qrcode,
  chevronRight,
  chevronLeft,
  arrowRight,
  chrono,
  arrowBlueRight,
  arrowBlueBottom,
  lock,
  sound,
  idCardTemplate,
  faceTemplate,
  logoTestWeBleu,
  resume,
  outlinePhoto,
  outlineVideo,
  networkCheck,
  warning,
  testCamera,
  testMicro,
  testSpeed,
  cableData,
  frontDesk,
  identityCard,
  lamp,
  permCameraMic,
  wc,
  pen,
  highlighter,
  comment,
  one,
  two,
  three,
  four,
  five,
  fullscreenIn,
  fullscreenInBlue,
  fullscreenOut,
  fullscreenOutBlue,
  establishmentIcon,
  downArrow,
  settings,
  time,
  toggle,
  bin,
  chronoExamPart
};
