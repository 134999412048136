export const GETERROR = "GETERROR";
export const RESET_ERROR = "RESET_ERROR";
export const LOCAL_API_ERROR = "LOCAL_API_ERROR";

export interface ResetErrorAction {
  type: typeof RESET_ERROR;
  payload: any;
}

export interface GetErrorAction {
  type: typeof GETERROR;
  payload: any;
}

export interface LocalApiErrorAction {
  type: typeof LOCAL_API_ERROR;
  payload: any;
}

export interface ErrorState {
  errorMessage: string | null;
  status: number;
  error: boolean;
  forceLogout: boolean;
}

export type ErrorActionTypes =
  | ResetErrorAction
  | GetErrorAction
  | LocalApiErrorAction
  | any;
