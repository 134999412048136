import React from "react";
import {
  StyleSheet,
  View,
  Image,
  Text,
  StyleProp,
  ViewStyle,
  TextStyle
} from "react-native";
import { checkIcon, crossRedIcon } from "../../../static/misc/images";
import {
  FONT_GILROY_REGULAR,
  FONTSIZE_11,
  PADDING_SIDES
} from "../../../static/misc/constants";
import { COLOR_BLUE_TESTWE, COLOR_WHITE } from "../../../static/misc/colors";

interface RuleExamItemProps {
  containerStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  text: string;
  isAvailable: boolean;
}

class RuleExamItem extends React.PureComponent<RuleExamItemProps> {
  render(): JSX.Element {
    const { text, isAvailable, containerStyle, textStyle } = this.props;
    return (
      <View style={[styles.container, containerStyle]}>
        <View style={styles.imageContainer}>
          <Image
            source={isAvailable ? checkIcon : crossRedIcon}
            style={[
              isAvailable
                ? { width: 17, height: 11 }
                : { width: 12, height: 12 }
            ]}
          />
        </View>
        <Text style={[styles.text, textStyle]}>{text}</Text>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    backgroundColor: COLOR_WHITE,
    width: "100%",
    paddingVertical: 8,
    alignItems: "center",
    flexShrink: 1
  },
  imageContainer: {
    alignItems: "center",
    width: 20,
    height: 15
  },
  text: {
    fontFamily: FONT_GILROY_REGULAR,
    color: COLOR_BLUE_TESTWE,
    fontSize: FONTSIZE_11,
    paddingLeft: PADDING_SIDES * 0.15
  }
});

export default RuleExamItem;
