import React from "react";
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  NativeSyntheticEvent,
  TextInputKeyPressEventData
} from "react-native";
import { create, all } from "mathjs";
import CalcUtils from "../../../static/misc/calcutils";
import {
  COLOR_BLACK,
  COLOR_BLUE_TESTWE,
  COLOR_GREY_BUTTON_DARKER,
  COLOR_GREY_LIGHT,
  COLOR_GREY_PLACEHOLDER
} from "../../../static/misc/colors";
import {
  FONTSIZE_18,
  FONTSIZE_24,
  FONT_GILROY_BOLD,
  PADDING_SIDES
} from "../../../static/misc/constants";
import {
  CalculatorButtonTypeEnum,
  CalculatorTypeEnum
} from "../../modules/examTaking/types/calculator";
import CalculatorBasicKeyboard from "../molecules/CalculatorBasicKeyboard";
import CalculatorCommonKeyboard from "../molecules/CalculatorCommonKeyboard";
import CalculatorScientificKeyboard from "../molecules/CalculatorScientificKeyboard";
import i18n from "../../services/i18n";

const math = create(all);
math.config({ number: "BigNumber" });

interface CalculatorProps {
  type: CalculatorTypeEnum;
}

interface CalculatorState {
  expressionStack: string[];
  currentStack: string[];
  memoryStack: string[];
  isError: boolean;
  isResult: boolean;
  waitForUserInput: boolean;
  showSecondButtons: boolean;
}

class Calculator extends React.PureComponent<CalculatorProps, CalculatorState> {
  constructor(props: CalculatorProps) {
    super(props);

    this.state = {
      expressionStack: [],
      currentStack: [],
      memoryStack: [],
      isError: false,
      isResult: false,
      waitForUserInput: false,
      showSecondButtons: false
    };

    this.addChar = this.addChar.bind(this);
    this.addFunction = this.addFunction.bind(this);
    this.compute = this.compute.bind(this);
    this.memory = this.memory.bind(this);
    this.clearAll = this.clearAll.bind(this);
    this.clearCurrentExpression = this.clearCurrentExpression.bind(this);
    this.onShowSecondButtons = this.onShowSecondButtons.bind(this);
  }

  // Display new buttons when clicking on "2nd" button
  onShowSecondButtons(): void {
    const { showSecondButtons } = this.state;

    this.setState({
      showSecondButtons: !showSecondButtons
    });
  }

  addChar(char: string, buttonType: CalculatorButtonTypeEnum): void {
    const {
      expressionStack,
      currentStack,
      isResult,
      isError,
      waitForUserInput
    } = this.state;

    // If the current stack is not a result neither an error, we can continue using the expression stack
    // If it is a result and the button used is an operator, we can continue using the expression stack
    // Else, we need to empty it to build a new expression
    let tmpExpStack =
      !isResult && !isError
        ? [...expressionStack]
        : isResult && buttonType === CalculatorButtonTypeEnum.OPERATOR
        ? [...currentStack]
        : [];
    let tmpCurrentStack = !isResult && !isError ? [...currentStack] : [];

    let updateState = false;

    let lastElem;
    let lastElemType;

    // Checking if there is already elements in current and expression stacks,
    // to check what was the last element and determine if the hit button must be ignored
    // toi avoid any error
    if (tmpCurrentStack.length > 0) {
      lastElem = tmpCurrentStack[tmpCurrentStack.length - 1];
    } else if (tmpExpStack.length > 0) {
      lastElem = tmpExpStack[tmpExpStack.length - 1];
    }

    // If the input is PI or E, we retrieve the value from the lib math
    let input = "";
    if (buttonType === CalculatorButtonTypeEnum.E) {
      input = Math.E.toString();
    } else {
      input = Math.PI.toString();
    }

    // If the current expression is empty, only numbers or opening bracket can be typed
    if (!lastElem) {
      if (
        buttonType === CalculatorButtonTypeEnum.NUMBER ||
        buttonType === CalculatorButtonTypeEnum.E ||
        buttonType === CalculatorButtonTypeEnum.PI ||
        buttonType === CalculatorButtonTypeEnum.OPENINGBRACKET
      ) {
        if (
          buttonType === CalculatorButtonTypeEnum.E ||
          buttonType === CalculatorButtonTypeEnum.PI
        ) {
          tmpCurrentStack = [...tmpCurrentStack, ...input.split("")];
        } else {
          tmpCurrentStack.push(char);
        }
        updateState = true;
      } else if (buttonType === CalculatorButtonTypeEnum.OPERATOR) {
        tmpCurrentStack.push("0");
        tmpCurrentStack.push(char);
        tmpExpStack = [...tmpExpStack, ...tmpCurrentStack];
        tmpCurrentStack = [];
        updateState = true;
      } else if (buttonType === CalculatorButtonTypeEnum.DOT) {
        tmpCurrentStack.push("0");
        tmpCurrentStack.push(char);
        updateState = true;
      }
    } else {
      // We check the last element type
      lastElemType = CalcUtils.checkCharType(lastElem);

      // If the previous elem was a function and needs another user input, we accept only
      // number, pi, e and opening bracket
      if (
        lastElemType === CalculatorButtonTypeEnum.FUNCTION &&
        waitForUserInput &&
        (buttonType === CalculatorButtonTypeEnum.NUMBER ||
          buttonType === CalculatorButtonTypeEnum.PI ||
          buttonType === CalculatorButtonTypeEnum.E ||
          buttonType === CalculatorButtonTypeEnum.OPENINGBRACKET)
      ) {
        tmpCurrentStack.push(char);
        updateState = true;
      }

      // Checking if current button type and previous element type are compatible
      if (
        (buttonType === CalculatorButtonTypeEnum.E ||
          buttonType === CalculatorButtonTypeEnum.PI) &&
        (lastElemType === CalculatorButtonTypeEnum.OPERATOR ||
          lastElemType === CalculatorButtonTypeEnum.OPENINGBRACKET)
      ) {
        // Splitting PI/E numbers to make it like the user typed it manually
        tmpCurrentStack = [...tmpCurrentStack, ...input.split("")];
        updateState = true;
      } else if (
        buttonType === CalculatorButtonTypeEnum.NUMBER &&
        (lastElemType === CalculatorButtonTypeEnum.NUMBER ||
          lastElemType === CalculatorButtonTypeEnum.DOT ||
          lastElemType === CalculatorButtonTypeEnum.OPERATOR ||
          lastElemType === CalculatorButtonTypeEnum.OPENINGBRACKET)
      ) {
        tmpCurrentStack.push(char);
        updateState = true;
      } else if (
        buttonType === CalculatorButtonTypeEnum.OPERATOR &&
        (lastElemType === CalculatorButtonTypeEnum.NUMBER ||
          lastElemType === CalculatorButtonTypeEnum.CLOSINGBRACKET)
      ) {
        // If operator is typed, the current stack content must be moved to the expression stack
        // because it means the user will type a new operand
        tmpCurrentStack.push(char);
        tmpExpStack = [...tmpExpStack, ...tmpCurrentStack];
        tmpCurrentStack = [];
        updateState = true;
      } else if (
        buttonType === CalculatorButtonTypeEnum.OPERATOR &&
        lastElemType === CalculatorButtonTypeEnum.OPERATOR
      ) {
        // If operator is typed while the previous element was already an operator, will be replacing the previous element
        // instead of being stacked
        tmpExpStack[tmpExpStack.length - 1] = char;
        tmpCurrentStack = [];
        updateState = true;
      } else if (
        buttonType === CalculatorButtonTypeEnum.DOT &&
        lastElemType === CalculatorButtonTypeEnum.NUMBER
      ) {
        // If button type is a dot, can only be added if previous element is a number
        // and if there is not already a dot in the current expression
        const alreadyDotted = currentStack.indexOf(".") > -1;
        if (!alreadyDotted) {
          tmpCurrentStack.push(char);
          updateState = true;
        }
      } else if (buttonType === CalculatorButtonTypeEnum.SIGNCHANGE) {
        // If sign change, check if there is already a - at the beginning of the current stack
        // to see if needs to add it or to remove it
        const firstElem = tmpCurrentStack[0];

        if (firstElem === "-") {
          tmpCurrentStack.splice(0, 1);
        } else {
          tmpCurrentStack = [" ", "-", ...tmpCurrentStack];
        }
        updateState = true;
      } else if (
        buttonType === CalculatorButtonTypeEnum.OPENINGBRACKET &&
        (lastElemType === CalculatorButtonTypeEnum.OPENINGBRACKET ||
          lastElemType === CalculatorButtonTypeEnum.OPERATOR)
      ) {
        // If opening bracket is selected, can only be added if previous element is an operator or another opening bracket
        tmpCurrentStack.push(char);
        updateState = true;
      } else if (
        buttonType === CalculatorButtonTypeEnum.CLOSINGBRACKET &&
        (lastElemType === CalculatorButtonTypeEnum.CLOSINGBRACKET ||
          lastElemType === CalculatorButtonTypeEnum.NUMBER)
      ) {
        // If closing bracket is selected, can only be added if previous element is a number or another closing bracket
        tmpCurrentStack.push(char);
        updateState = true;
      }
    }

    if (updateState) {
      this.setState({
        expressionStack: tmpExpStack,
        currentStack: tmpCurrentStack,
        isError: false,
        isResult: false,
        waitForUserInput: false
      });
    }
  }

  // Remove the last character of the expression
  removeLastChar(): void {
    const { currentStack } = this.state;
    const tmpCurrentStack = [...currentStack];

    if (tmpCurrentStack.length > 0) {
      tmpCurrentStack.splice(tmpCurrentStack.length - 1, 1);

      this.setState({
        currentStack: tmpCurrentStack
      });
    }
  }

  // Calculate the current expression
  compute(): void {
    const { expressionStack, currentStack, isResult, isError } = this.state;
    let finalExpression = [...expressionStack, ...currentStack].join("");

    // We don't want to recalculate if it is already a result or if it is errored
    if (isResult || isError) {
      return;
    }

    // Counting the occurrences of ( and )
    const openingBracketCount = (finalExpression.match(/\(/g) || []).length;
    const closingBracketCount = (finalExpression.match(/\)/g) || []).length;

    // If more ( than ), we can add the missing ) at the end of the expression
    // The other way around is not possible though, will throw an "Entrée non valide" error
    if (openingBracketCount > closingBracketCount) {
      for (let x = 0; x < openingBracketCount - closingBracketCount; x++) {
        finalExpression = finalExpression.concat(")");
      }
    }

    let result = "";
    let isErrored = false;
    try {
      result = math.format(math.evaluate(finalExpression));
      if (typeof result !== "string") {
        isErrored = true;
      }
    } catch (e) {
      isErrored = true;
    } finally {
      this.setState({
        expressionStack: [finalExpression],
        currentStack: isError ? [] : [...result],
        isError: isErrored,
        isResult: true,
        waitForUserInput: false
      });
    }
  }

  // Handling the calculator memory
  memory(buttonType: CalculatorButtonTypeEnum): void {
    const { memoryStack, currentStack } = this.state;
    let tmpMemoryStack = [...memoryStack];
    let tmpCurrentStack = [...currentStack];

    switch (buttonType) {
      case CalculatorButtonTypeEnum.MC: {
        tmpMemoryStack = [];
        break;
      }
      case CalculatorButtonTypeEnum.MR: {
        tmpCurrentStack =
          tmpMemoryStack.length > 0
            ? tmpMemoryStack[tmpMemoryStack.length - 1].split("")
            : tmpCurrentStack;
        break;
      }
      case CalculatorButtonTypeEnum.MPLUS: {
        const exp =
          tmpMemoryStack.length > 0
            ? `${
                tmpMemoryStack[tmpMemoryStack.length - 1]
              } + ${tmpCurrentStack.join("")}`
            : undefined;
        if (exp !== undefined) {
          const result = math.format(math.evaluate(exp));
          tmpMemoryStack = [result];
        }
        break;
      }
      case CalculatorButtonTypeEnum.MMINUS: {
        const exp =
          tmpMemoryStack.length > 0
            ? `${
                tmpMemoryStack[tmpMemoryStack.length - 1]
              } - ${tmpCurrentStack.join("")}`
            : undefined;
        if (exp !== undefined) {
          const result = math.format(math.evaluate(exp));
          tmpMemoryStack = [result];
        }
        break;
      }
      case CalculatorButtonTypeEnum.MS: {
        // TODO handling one number in memory for now, maybe we'll see later for several numbers
        tmpMemoryStack = [currentStack.join("")];
        break;
      }
      default:
        break;
    }

    this.setState({
      memoryStack: tmpMemoryStack,
      currentStack: tmpCurrentStack
    });
  }

  addFunction(fn: string, waitForUserInput: boolean): void {
    const { currentStack, expressionStack } = this.state;
    let tmpExpStack = [...expressionStack];
    let tmpFn = fn;

    // Retrieving the last element of the expression and its type,
    // to see if the function must be concatenated to the existing expression or if it must replace it
    const lastElem =
      expressionStack.length > 0
        ? expressionStack[expressionStack.length - 1]
        : undefined;
    const lastElemType = lastElem
      ? CalcUtils.checkCharType(lastElem)
      : undefined;

    // Checking if the function needs to apply to the current stack
    // If the current stack is empty, assume it means 0
    const hasX = fn.indexOf("x") > -1;
    if (hasX) {
      tmpFn = fn.replace(
        "x",
        currentStack.length > 0 ? currentStack.join("") : "0"
      );
    }

    // If there is no previous element or if the last element is not an operator nor an opening bracket,
    // it cannot be concatenated so the expression stack is emptied
    if (
      !lastElem ||
      (lastElemType !== CalculatorButtonTypeEnum.OPERATOR &&
        lastElemType !== CalculatorButtonTypeEnum.OPENINGBRACKET &&
        lastElemType !== CalculatorButtonTypeEnum.FUNCTION)
    ) {
      tmpExpStack = [];
    }

    // If we need another input from the user, the current stack content is moved to the expression stack
    if (waitForUserInput) {
      tmpExpStack.push(tmpFn);
    }

    this.setState({
      expressionStack: tmpExpStack,
      currentStack: waitForUserInput ? [] : [tmpFn],
      isResult: false,
      isError: false,
      waitForUserInput
    });
  }

  clearAll(): void {
    // Clear everything on screen but the memory
    this.setState({
      expressionStack: [],
      currentStack: [],
      isError: false,
      isResult: false,
      waitForUserInput: false
    });
  }

  clearCurrentExpression(): void {
    // Clear only what the user has typed in current stack
    this.setState({
      currentStack: [],
      isError: false,
      isResult: false
    });
  }

  updateExpression(key: string): void {
    // If backspace on keyboard is used, will remoe the last char in current expression
    if (key === "Backspace") {
      this.removeLastChar();
    } else if (key === "Delete") {
      this.clearCurrentExpression();
    } else if (key.length === 1) {
      // If comma is used instead of dot, must be replaced by a dot to avoid problems in functions
      // which use a comma to separate the operands
      const keyTmp = key === "," ? "." : key;
      const charType = CalcUtils.checkCharType(keyTmp);
      if (charType !== CalculatorButtonTypeEnum.OTHER) {
        this.addChar(keyTmp, charType);
      }
    }
  }

  render(): JSX.Element {
    const { type } = this.props;
    const {
      expressionStack,
      currentStack,
      isError,
      showSecondButtons
    } = this.state;

    return (
      <View
        style={[
          styles.viewContainerStyle,
          { width: type === CalculatorTypeEnum.BASIC ? 300 : 450 }
        ]}
      >
        <View style={styles.calculatorContainer}>
          <View style={styles.calculatorBar} />
          <View style={styles.calculatorScreen}>
            <View style={styles.calculatorScreenTextContainer}>
              {expressionStack.length > 0 ? (
                <View style={styles.finalExpressionContainer}>
                  <Text style={styles.calculatorFinalExpressionText}>
                    {expressionStack.join("")}
                  </Text>
                </View>
              ) : (
                <Text />
              )}
              <View style={styles.finalExpressionContainer}>
                <TextInput
                  style={styles.calculatorScreenText}
                  onKeyPress={(
                    e: NativeSyntheticEvent<TextInputKeyPressEventData>
                  ) => this.updateExpression(e.nativeEvent.key)}
                  onSubmitEditing={() => this.compute()}
                  value={
                    isError
                      ? i18n.t("calculator.invalidEntry")
                      : currentStack.length > 0
                      ? currentStack.join("")
                      : "0"
                  }
                />
              </View>
            </View>
          </View>
          <CalculatorCommonKeyboard
            compute={this.compute}
            memory={this.memory}
            clearAll={this.clearAll}
            clearCurrentExpression={this.clearCurrentExpression}
          />
          <View style={{ flexDirection: "row" }}>
            {type === CalculatorTypeEnum.SCIENTIFIC ? (
              <CalculatorScientificKeyboard
                addChar={this.addChar}
                addFunction={this.addFunction}
                showSecondButtons={showSecondButtons}
                onShowSecondButtons={this.onShowSecondButtons}
                containerStyle={[{ width: "60%" }]}
              />
            ) : (
              <Text />
            )}
            <CalculatorBasicKeyboard
              addChar={this.addChar}
              addFunction={this.addFunction}
              compute={this.compute}
              clearAll={this.clearAll}
              clearCurrentExpression={this.clearCurrentExpression}
              containerStyle={[
                { width: type === CalculatorTypeEnum.BASIC ? "100%" : "40%" }
              ]}
            />
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  viewContainerStyle: {
    minHeight: PADDING_SIDES,
    flexDirection: "column",
    alignItems: "center",
    shadowColor: COLOR_BLACK,
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.3,
    shadowRadius: 4.65,
    elevation: 1
  },
  calculatorContainer: {
    width: "100%",
    borderWidth: 0,
    borderColor: COLOR_BLUE_TESTWE,
    backgroundColor: COLOR_GREY_PLACEHOLDER,
    padding: 4
  },
  calculatorBar: {
    // flexGrow: 1,
    height: PADDING_SIDES * 0.5,
    cursor: "grab"
  },
  calculatorScreen: {
    width: "100%",
    flex: 1,
    backgroundColor: COLOR_GREY_PLACEHOLDER,
    borderWidth: 1,
    borderColor: COLOR_GREY_PLACEHOLDER,
    marginBottom: 4
  },
  calculatorScreenText: {
    fontFamily: FONT_GILROY_BOLD,
    color: COLOR_BLACK,
    fontSize: FONTSIZE_24,
    alignSelf: "flex-end",
    textAlign: "right",
    flex: 1,
    width: "100%",
    outlineWidth: 0
  },
  calculatorFinalExpressionText: {
    fontFamily: FONT_GILROY_BOLD,
    color: COLOR_GREY_BUTTON_DARKER,
    fontSize: FONTSIZE_18,
    alignSelf: "flex-end"
  },
  calculatorScreenTextContainer: {
    flexGrow: 1,
    paddingVertical: PADDING_SIDES * 0.3,
    paddingHorizontal: PADDING_SIDES * 0.1,
    borderWidth: 1,
    borderColor: COLOR_GREY_LIGHT,
    backgroundColor: COLOR_GREY_LIGHT,
    flexDirection: "column"
  },
  finalExpressionContainer: {
    borderWidth: 1,
    borderColor: COLOR_GREY_LIGHT
  }
});

export default Calculator;
