import React from "react";
import { StyleSheet, View } from "react-native";
import { guidGenerator } from "../../../static/misc/utils";
import {
  ChoiceTypeWithSelection,
  ExamChoice
} from "../../modules/exams/types/exam";
import i18n from "../../services/i18n";
import QcmItem from "../molecules/QcmItem";

interface QcmState {
  list: ChoiceTypeWithSelection[];
}

export interface QcmProps {
  previousAnswer?: Array<string> | undefined;
  givenChoices?: string[] | undefined;
  items: Array<ExamChoice>;
  itemsSelected: (selections: ChoiceTypeWithSelection[]) => void;
}

class Qcm extends React.PureComponent<QcmProps, QcmState> {
  constructor(props: QcmProps) {
    super(props);

    const { items, previousAnswer } = this.props;

    this.state = {
      list: items.map((v) => ({
        ...v,
        isSelected:
          previousAnswer && v.id && previousAnswer?.includes(v.id)
            ? previousAnswer?.includes(v.id)
            : false
      }))
    };
  }

  componentDidUpdate(prevProps: QcmProps): void {
    const { givenChoices } = this.props;

    if (
      givenChoices &&
      givenChoices.length !== prevProps.givenChoices?.length
    ) {
      this.mapExistingGivenChoices();
    }
  }

  // If the student paper has already answers for this question, mapping it
  mapExistingGivenChoices(): void {
    const { items, givenChoices } = this.props;

    this.setState({
      list: items.map((v) => ({
        ...v,
        isSelected:
          givenChoices && v.id && givenChoices?.includes(v.id)
            ? givenChoices?.includes(v.id)
            : false
      }))
    });
  }

  // Change the choice selection
  changeSelection(index: number): void {
    const { itemsSelected } = this.props;
    const { list } = this.state;
    let listTmp = [...list];

    listTmp[index].isSelected = !listTmp[index].isSelected;
    if (
      listTmp[index].name === i18n.t("examTaking.questions.noGoodAnswers") &&
      listTmp[index].isSelected
    ) {
      // Unselect every others answers if noGoodAnswers is selected
      listTmp = listTmp.map((item) => {
        const tmp = item;
        if (item.name !== i18n.t("examTaking.questions.noGoodAnswers"))
          tmp.isSelected = false;
        return tmp;
      });
    } else {
      // Unselect No goodAnswers if it was selected
      listTmp = listTmp.map((item) => {
        const tmp = item;
        if (
          item.name === i18n.t("examTaking.questions.noGoodAnswers") &&
          item.isSelected
        )
          tmp.isSelected = false;
        return tmp;
      });
    }
    this.setState(
      {
        list: listTmp
      },
      () => {
        itemsSelected(listTmp);
      }
    );
  }

  render(): JSX.Element {
    const { list } = this.state;
    return (
      <View>
        {list.map((item, index) => {
          return (
            <View style={styles.itemSeparator} key={guidGenerator()}>
              <QcmItem
                onSelect={() => this.changeSelection(index)}
                answer={item.name}
                isSelected={item.isSelected}
              />
            </View>
          );
        })}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  itemSeparator: {
    paddingVertical: 5
  }
});

export default Qcm;
