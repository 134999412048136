import { ExamType } from "../../exams/types/exam";
import { ListeningAddTimeExam } from "../../proctoringLive/types/proctoringlive";

export const UPDATE_TIMER = "UPDATE_TIMER";
export const CREATE_TIMER = "CREATE_TIMER";
export const CLEAR_TIMER = "CLEAR_TIMER";

export const SET_CURSOR_EXAM = "SET_CURSOR_EXAM";

export interface TimerState {
  timers: TimerType[];
  cursorExam: any;
}

export interface TimerType {
  id: string;
  timerType: TimerTypeEnum;
  remainingDuration: number;
  timers: TimerType[] | undefined;
}

export enum TimerTypeEnum {
  EXERCISE,
  QUESTION,
  PART,
  EXAM,
  MODAL
}

export interface SetCursorExamAction {
  type: typeof SET_CURSOR_EXAM;
  payload:
    | {
        examPart: string;
        partIndex: string;
      }
    | undefined;
}
export interface UpdateTimerAction {
  type: typeof UPDATE_TIMER;
  payload: any;
}
export interface ClearTimerAction {
  type: typeof CLEAR_TIMER;
  payload: any;
}
export interface CreateTimerAction {
  type: typeof CREATE_TIMER;
  payload: {
    currentExam: ExamType;
  };
}

export type TimerActionsType =
  | UpdateTimerAction
  | CreateTimerAction
  | ClearTimerAction
  | ListeningAddTimeExam
  | SetCursorExamAction;
