import { ipcRenderer, shell } from "electron";
import React from "react";
import os from "os";
import { StyleSheet, View, Text } from "react-native";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { COLOR_BLUE_TESTWE } from "../../../static/misc/colors";
import {
  FONTSIZE_14,
  FONT_GILROY_REGULAR,
  PADDING_SIDES
} from "../../../static/misc/constants";
import i18n from "../../services/i18n";
import CustomModal from "../atoms/CustomModal";
import ExtraBoldTitle from "../atoms/ExtraBoldTitle";
import Loader from "../atoms/Loader";
import RegularText from "../atoms/RegularText";
import Button from "../molecules/Button";
import { startExitingApplication } from "../../modules/main/actions/status";
import { ExitApplicationAction } from "../../modules/main/types/status";

export interface UpdateModalProps {
  isVisible: boolean;
  isDownloaded: boolean;
  isErrored: boolean;
  downloadProgress: number;
  onQuit: () => void;
  onCloseModal: () => void;
  exitApplication: (action?: string) => ExitApplicationAction;
}

const cdnList = {
  win32: "https://cdn.testwe.eu/downloads/TestWe_Install_Windows.exe",
  linux: "https://cdn.testwe.eu/downloads/TestWe_Install_Linux.AppImage",
  darwin: "https://cdn.testwe.eu/downloads/TestWe_Install_macOS.dmg"
};

class UpdateModal extends React.PureComponent<UpdateModalProps> {
  render(): JSX.Element {
    const {
      isVisible,
      isDownloaded,
      isErrored,
      downloadProgress,
      onQuit,
      onCloseModal,
      exitApplication
    } = this.props;

    return (
      <CustomModal
        showCloseButton
        onCloseModal={() => onCloseModal()}
        borderRadiusModal={5}
        visible={isVisible}
        heightModal={200}
      >
        <View style={styles.modalContentContainer}>
          <View style={{ paddingBottom: 30 }}>
            <ExtraBoldTitle
              text={
                isDownloaded
                  ? i18n.t("modal.updateFinished")
                  : i18n.t("modal.update")
              }
            />
          </View>
          <View style={styles.buttonWrapper}>
            {isErrored ? (
              <View>
                {!isDownloaded ? (
                  <View>
                    <Text style={styles.textInstall}>
                      {i18n.t("modal.manualDownload")}
                    </Text>
                    <Button
                      onPress={() => {
                        const currentOs = os.platform();
                        const link = cdnList[currentOs] || cdnList.win32;
                        shell.openExternal(link);
                        exitApplication();
                      }}
                      containerStyle={[styles.buttonModal]}
                      label={i18n.t("modal.manualDownloadButton")}
                    />
                  </View>
                ) : (
                  <View>
                    <Text style={styles.textInstall}>
                      {i18n.t("modal.manualInstall")}
                    </Text>
                    <Button
                      onPress={() => {
                        ipcRenderer.send("OPEN_INSTALL_PATH");
                        exitApplication();
                      }}
                      containerStyle={[styles.buttonModal]}
                      label={i18n.t("modal.manualInstallButton")}
                    />
                  </View>
                )}
              </View>
            ) : (
              <View style={styles.modalChild}>
                <RegularText
                  text={
                    isDownloaded
                      ? i18n.t("modal.updateExpFinished")
                      : i18n.t("modal.updateExp")
                  }
                />
                {isDownloaded ? (
                  <View style={styles.downloadedContainer}>
                    <Button
                      containerStyle={[styles.button]}
                      label={i18n.t("modal.updateLeave")}
                      onPress={() => {
                        onQuit();
                      }}
                    />
                    {/* <TouchableOpacity
                      onPress={() => {
                        ipcRenderer.send("OPEN_INSTALL_PATH");
                      }}
                    >
                      <Text style={styles.textInstall}>
                        {i18n.t("modal.manualInstall")}
                      </Text>
                    </TouchableOpacity> */}
                  </View>
                ) : (
                  <View style={styles.downloadedContainer}>
                    <Loader />
                    <Text style={styles.textInstall}>{downloadProgress} %</Text>
                  </View>
                )}
              </View>
            )}
          </View>
        </View>
      </CustomModal>
    );
  }
}

const styles = StyleSheet.create({
  modalContentContainer: {
    flex: 1,
    width: "100%",
    alignItems: "flex-start",
    paddingHorizontal: 30,
    paddingVertical: 30
  },
  buttonModal: {
    width: "50%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "4%"
  },
  modalChild: {
    flex: 1,
    width: "100%",
    alignItems: "flex-start",
    justifyContent: "space-between"
  },
  textInstall: {
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_14,
    color: COLOR_BLUE_TESTWE,
    fontStyle: "italic"
  },
  button: {
    marginVertical: PADDING_SIDES / 2,
    alignSelf: "center",
    borderColor: COLOR_BLUE_TESTWE,
    backgroundColor: COLOR_BLUE_TESTWE,
    width: 300
  },
  downloadedContainer: {
    paddingTop: "5%",
    marginLeft: "auto",
    marginRight: "auto",
    flexDirection: "column"
  },
  buttonWrapper: {
    width: "100%",
    alignItems: "center"
  }
});

const mapStateToProps = () => {
  return {};
};

const mapdispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators(
      {
        exitApplication: startExitingApplication
      },
      dispatch
    )
  };
};

export default connect(mapStateToProps, mapdispatchToProps)(UpdateModal);
