import React from "react";
import { StyleSheet, View } from "react-native";
import {
  COLOR_BLUE_TESTWE,
  COLOR_GREY_BACKGROUND
} from "../../../static/misc/colors";
import CharMax from "../atoms/CharMax";
import TinyMce, { TypingContent } from "../atoms/TinyMce";
import WordMax from "../atoms/WordMax";

export interface MultipleLineFieldProps {
  previousAnswer?: string;
  width?: number | string;
  height?: number | string;
  nbCharMax?: number;
  nbWordsMax?: number;
  onTyping: (content: TypingContent) => void;
}

export interface MultipleLineFieldState {
  content: string;
}

class MultipleLineField extends React.Component<
  MultipleLineFieldProps,
  MultipleLineFieldState
> {
  static defaultProps = {
    width: "100%",
    nbCharMax: undefined,
    nbWordsMax: undefined,
    height: 300
  };

  constructor(props: MultipleLineFieldProps) {
    super(props);

    this.state = {
      content: ""
    };
  }

  componentDidMount(): void {
    const { previousAnswer } = this.props;

    if (previousAnswer && previousAnswer !== "") {
      this.setState({
        content: previousAnswer
      });
    }
  }

  render(): JSX.Element {
    const { content } = this.state;
    const {
      nbCharMax,
      nbWordsMax,
      onTyping,
      width,
      height,
      previousAnswer
    } = this.props;

    return (
      <View style={[styles.container, { width, height }]}>
        <TinyMce
          previousAnswer={previousAnswer}
          nbCharMax={nbCharMax}
          nbWordsMax={nbWordsMax}
          onTyping={(contentTyped: TypingContent) =>
            this.setState(
              {
                content: contentTyped.content
              },
              () => {
                onTyping(contentTyped);
              }
            )
          }
        />
        <View style={styles.maxCharContainer}>
          {(nbCharMax && nbCharMax > 0 && (
            <CharMax
              nbCharMax={nbCharMax}
              nbChar={content.replace(/\s+/g, "").replace(/\n/gi, "").length}
            />
          )) || <View />}
          {(nbWordsMax && nbWordsMax > 0 && (
            <WordMax
              nbWordsMax={nbWordsMax}
              nbWords={content.split(" ").length}
            />
          )) || <View />}
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 0.5,
    borderRadius: 5,
    borderColor: COLOR_BLUE_TESTWE,
    backgroundColor: COLOR_GREY_BACKGROUND,
    paddingHorizontal: 10,
    paddingVertical: 10,
    minHeight: "30vh"
  },
  maxCharContainer: {
    alignSelf: "flex-end"
  }
});

export default MultipleLineField;
