/* eslint-disable react-native/no-color-literals */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import {
  Animated,
  Easing,
  GestureResponderEvent,
  I18nManager,
  Image,
  PanResponder,
  PanResponderGestureState,
  StyleProp,
  Text,
  TouchableOpacity,
  View,
  ViewStyle
} from "react-native";
import { COLOR_TRANSPARENT } from "../../../static/misc/colors";

interface SwitchSelectorOption {
  label?: string;
  value?: string;
  customIcon?: JSX.Element;
  imageIcon?: string;
  activeColor?: string;
  testID?: string;
  accessibilityLabel?: string;
}

interface SwitchSelectorProps {
  style?: StyleProp<ViewStyle>;
  textStyle?: any;
  selectedTextStyle?: any;
  textContainerStyle?: any;
  selectedTextContainerStyle?: any;
  imageStyle?: any;
  options: SwitchSelectorOption[];
  textColor?: string;
  selectedColor?: string;
  fontSize?: number;
  backgroundColor?: string;
  borderColor?: string;
  borderRadius?: number;
  borderWidth?: number;
  hasPadding?: boolean;
  valuePadding?: number;
  height?: number;
  bold?: boolean;
  buttonMargin?: number;
  buttonColor?: string;
  returnObject?: boolean;
  animationDuration?: number;
  disabled?: boolean;
  disableValueChangeOnPress?: boolean;
  initial: number;
  value?: number;
  accessibilityLabel?: string;
  testID?: string;
  onPress: (value: any) => void;
}

interface SwitchSelectorState {
  selected: number;
  sliderWidth: number;
}

/**
 * created from https://github.com/App2Sales/react-native-switch-selector
 */
export default class SwitchSelector extends React.PureComponent<
  SwitchSelectorProps,
  SwitchSelectorState
> {
  panResponder = PanResponder.create({
    onStartShouldSetPanResponder: (
      e: GestureResponderEvent,
      gestureState: PanResponderGestureState
    ) => this.shouldSetResponder(e, gestureState),
    onMoveShouldSetPanResponder: (
      e: GestureResponderEvent,
      gestureState: PanResponderGestureState
    ) => this.shouldSetResponder(e, gestureState),
    onPanResponderRelease: (
      e: GestureResponderEvent,
      gestureState: PanResponderGestureState
    ) => this.responderEnd(e, gestureState),
    onPanResponderTerminate: (
      e: GestureResponderEvent,
      gestureState: PanResponderGestureState
    ) => this.responderEnd(e, gestureState)
  });

  animatedValue: Animated.Value;

  constructor(props: SwitchSelectorProps) {
    super(props);
    const { initial, options } = props;
    this.state = {
      selected: initial,
      sliderWidth: 0
    };

    this.animatedValue = new Animated.Value(
      initial
        ? I18nManager.isRTL
          ? -(initial / options.length)
          : initial / options.length
        : 0
    );
  }

  componentDidUpdate(prevProps: SwitchSelectorProps): void {
    const { value, disableValueChangeOnPress } = this.props;
    if (prevProps.value !== value) {
      this.toggleItem(value, !disableValueChangeOnPress);
    }
  }

  getSwipeDirection(gestureState: PanResponderGestureState): string | null {
    const { dx, dy, vx } = gestureState;
    // 0.1 velocity
    if (Math.abs(vx) > 0.1 && Math.abs(dy) < 80) {
      return dx > 0 ? "RIGHT" : "LEFT";
    }
    return null;
  }

  getBgColor(): string | undefined {
    const { selected } = this.state;
    const { options, buttonColor } = this.props;
    if (selected === -1) {
      return COLOR_TRANSPARENT;
    }
    return options[selected].activeColor || buttonColor;
  }

  animate(value: number, last: number): void {
    const { animationDuration } = this.props;
    this.animatedValue.setValue(last);
    Animated.timing(this.animatedValue, {
      toValue: value,
      duration: animationDuration,
      easing: Easing.cubic,
      useNativeDriver: true
    }).start();
  }

  responderEnd(
    evt: GestureResponderEvent,
    gestureState: PanResponderGestureState
  ): void {
    const { disabled, options } = this.props;
    const { selected } = this.state;

    if (disabled) return;
    const swipeDirection = this.getSwipeDirection(gestureState);
    if (swipeDirection === "RIGHT" && selected < options.length - 1) {
      this.toggleItem(selected + 1);
    } else if (swipeDirection === "LEFT" && selected > 0) {
      this.toggleItem(selected - 1);
    }
  }

  shouldSetResponder(
    evt: GestureResponderEvent,
    gestureState: PanResponderGestureState
  ): boolean {
    return (
      evt.nativeEvent.touches.length === 1 &&
      !(Math.abs(gestureState.dx) < 5 && Math.abs(gestureState.dy) < 5)
    );
  }

  toggleItem(index?: number, callOnPress = true): void {
    const { selected } = this.state;
    const { options, returnObject, onPress } = this.props;
    if (options.length <= 1) return;
    if (index !== null && index !== undefined) {
      this.animate(
        I18nManager.isRTL ? -(index / options.length) : index / options.length,
        I18nManager.isRTL
          ? -(selected / options.length)
          : selected / options.length
      );
      if (callOnPress && onPress) {
        onPress(returnObject ? options[index] : options[index].value);
      }
      this.setState({ selected: index });
    }
  }

  render(): JSX.Element {
    const {
      style = {},
      textStyle = {},
      selectedTextStyle = {},
      textContainerStyle = {},
      selectedTextContainerStyle = {},
      imageStyle = {},
      options = [],
      textColor = "#000000",
      selectedColor = "#FFFFFF",
      fontSize = 14,
      backgroundColor = "#FFFFFF",
      borderColor = "#C9C9C9",
      borderRadius = 50,
      borderWidth = 1,
      hasPadding = false,
      valuePadding = 1,
      height = 40,
      bold = false,
      buttonMargin = 0,
      disabled = false,
      accessibilityLabel = undefined,
      testID = undefined
    } = this.props;

    const { selected, sliderWidth } = this.state;

    const optionsMap = options.map((element: any, index: number) => {
      const isSelected = selected === index;

      return (
        <TouchableOpacity
          key={index}
          disabled={disabled}
          style={[
            styles.button,
            {
              borderRadius,
              height: hasPadding
                ? height - valuePadding * 2 - borderWidth * 2
                : height,
              backgroundColor: COLOR_TRANSPARENT,
              width:
                sliderWidth / options.length -
                ((hasPadding ? valuePadding : 0) + buttonMargin * 2)
            },
            isSelected ? selectedTextContainerStyle : textContainerStyle
          ]}
          onPress={() => this.toggleItem(index)}
          accessibilityLabel={element.accessibilityLabel}
          testID={element.testID}
        >
          {typeof element.customIcon === "function"
            ? element.customIcon(isSelected)
            : element.customIcon}
          {element.imageIcon && (
            <Image
              source={element.imageIcon}
              style={[
                {
                  height: 30,
                  width: 30,
                  tintColor: isSelected ? selectedColor : textColor
                },
                imageStyle
              ]}
            />
          )}
          <Text
            style={[
              {
                fontSize,
                fontWeight: bold ? "bold" : "normal",
                textAlign: "center",
                color: isSelected ? selectedColor : textColor
                // backgroundColor: "transparent"
              },
              isSelected ? selectedTextStyle : textStyle
            ]}
          >
            {element.label}
          </Text>
        </TouchableOpacity>
      );
    });

    return (
      <View
        style={[{ flexDirection: "row" }, style]}
        accessibilityLabel={accessibilityLabel}
        testID={testID}
      >
        <View {...this.panResponder.panHandlers} style={{ flex: 1 }}>
          <View
            style={{
              borderRadius,
              backgroundColor,
              height: height + buttonMargin * 2
            }}
            onLayout={(event) => {
              const { width } = event.nativeEvent.layout;
              this.setState({
                sliderWidth: width - (hasPadding ? 2 : 0)
              });
            }}
          >
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                borderColor,
                borderRadius,
                borderWidth: hasPadding ? borderWidth : 0,
                alignItems: "center"
              }}
            >
              {!!sliderWidth && (
                <Animated.View
                  style={[
                    {
                      height: hasPadding
                        ? height - valuePadding * 2 - borderWidth * 2
                        : height,
                      backgroundColor: this.getBgColor(),
                      width:
                        sliderWidth / options.length -
                        ((hasPadding ? valuePadding : 0) + buttonMargin * 2),
                      transform: [
                        {
                          translateX: this.animatedValue.interpolate({
                            inputRange: [0, 1],
                            outputRange: [
                              hasPadding ? valuePadding : 0,
                              sliderWidth - (hasPadding ? valuePadding : 0)
                            ]
                          })
                        }
                      ],
                      borderRadius,
                      margin: buttonMargin
                    },
                    styles.animated
                  ]}
                />
              )}
              {optionsMap}
            </View>
          </View>
        </View>
      </View>
    );
  }
}

const styles = {
  button: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  animated: {
    borderWidth: 0,
    position: "absolute"
  }
};
