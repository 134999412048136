import React from "react";
import { ImageStyle, Linking, StyleProp, StyleSheet, View } from "react-native";
import { COLOR_WHITE } from "../../../static/misc/colors";
import ExamTakingTimer, { TimerTypeEnum } from "../atoms/ExamTakingTimer";
import { guidGenerator } from "../../../static/misc/utils";
import i18n from "../../services/i18n";
import { StackNavigatorProp } from "../../router/StackNavigator";
import Button from "../molecules/Button";
import UserAvatar from "../atoms/UserAvatar";
import { placeholderProfil, question } from "../../../static/misc/images";
import TouchableButton from "../molecules/TouchableButton";
import { SEB_QUITTING_LINK } from "../../../static/misc/constants";
import ConfirmActionModal from "../molecules/ConfirmActionModal";

export interface HeaderRemoteExamBlockContentProps {
  avatarStyle?: StyleProp<ImageStyle>;
  duration?: number;
  examId?: string;
  navigation: StackNavigatorProp;
  onFinishedTimer: (timerType: TimerTypeEnum) => void;
  onExamValidated: () => void;
}

export interface HeaderRemoteExamBlockContentState {
  canQuitSeb: boolean;
  isQuittingSeb: boolean;
}

class HeaderRemoteExamBlockContent extends React.Component<
  HeaderRemoteExamBlockContentProps,
  HeaderRemoteExamBlockContentState
> {
  constructor(props: HeaderRemoteExamBlockContentProps) {
    super(props);

    this.state = {
      canQuitSeb: true,
      isQuittingSeb: false
    };
  }

  async componentDidMount(): Promise<void> {
    setTimeout(() => {
      this.setState({ canQuitSeb: false });
    }, 600000); // 5 minutes
  }

  render(): JSX.Element {
    const {
      avatarStyle,
      duration,
      examId,
      navigation,
      onFinishedTimer,
      onExamValidated
    } = this.props;
    const { canQuitSeb, isQuittingSeb } = this.state;

    return (
      <View style={styles.blockContent}>
        {duration !== undefined ? (
          <View style={[styles.examTakingTimer]}>
            <ExamTakingTimer
              key={guidGenerator()}
              timerType={TimerTypeEnum.EXAM}
              currentExamPart={0}
              examId={examId}
              running
              onFinish={(timerType: TimerTypeEnum) =>
                onFinishedTimer(timerType)
              }
            />
          </View>
        ) : null}
        {window && window.navigator.userAgent.includes("SEB") && (
          <View>
            <Button
              label={i18n.t("userMenu.leaveSeb")}
              containerStyle={[styles.buttonValidate]}
              onPress={() => this.setState({ isQuittingSeb: true })}
              disabled={!canQuitSeb}
            />
            <ConfirmActionModal
              hasCloseButton={false}
              labels={{
                description: i18n.t("examTaking.header.sebModal.description"),
                confirm: i18n.t("examTaking.header.sebModal.confirm"),
                abort: i18n.t("examTaking.header.sebModal.abort"),
                title: i18n.t("examTaking.header.sebModal.title")
              }}
              isVisible={isQuittingSeb}
              onValidateAction={() => Linking.openURL(SEB_QUITTING_LINK)}
              onDeniedAction={() => this.setState({ isQuittingSeb: false })}
            />
          </View>
        )}
        <Button
          label={i18n.t("examTaking.header.confirmSend")}
          containerStyle={[styles.buttonValidate]}
          onPress={() => onExamValidated()}
          disabled={
            window && window.navigator.userAgent.includes("SEB")
              ? canQuitSeb
              : false
          }
        />
        <TouchableButton
          containerStyle={{ marginHorizontal: 20 }}
          label={i18n.t("homepage.header.faq")}
          icon={question}
          onPress={() => navigation.navigate("Faq")}
        />
        <UserAvatar
          avatar={placeholderProfil}
          onPress={() => undefined}
          avatarStyle={avatarStyle}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  blockContent: {
    width: "40%",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  examTakingTimer: {
    backgroundColor: COLOR_WHITE,
    paddingHorizontal: 15,
    paddingVertical: 5,
    borderRadius: 15,
    alignContent: "center",
    justifyContent: "center",
    flexDirection: "row"
  },
  buttonValidate: {}
});

export default HeaderRemoteExamBlockContent;
