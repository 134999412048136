import React from "react";
import {
  EACH_SECONDS,
  MIME_MP4,
  MIME_WEBM,
  VIDEO_CONSTRAINTS
} from "../../../static/misc/constants";

export default abstract class AbstractVideoRecording<
  P,
  S = unknown
> extends React.PureComponent<P, S> {
  intervalDuration!: ReturnType<typeof setInterval>;

  mediaRecorder?: MediaRecorder;

  videoChunks?: Blob[];

  videoStream?: MediaStream;

  mimeType = `${MIME_WEBM};codecs=vp8,opus`;

  constructor(props: P) {
    super(props);

    this.onRecordingDataAvailable = this.onRecordingDataAvailable.bind(this);
  }

  componentDidMount(): void {
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: this.getMediaConstraints()
      })
      .then((stream) => {
        if (!MediaRecorder.isTypeSupported(this.mimeType)) {
          this.mimeType = MIME_MP4;
        }

        this.mediaRecorder = new MediaRecorder(stream, {
          mimeType: this.mimeType
        });

        this.mediaRecorder.ondataavailable = this.onRecordingDataAvailable;
        this.videoChunks = [];
        this.videoStream = stream;
      })
      .catch((error) => {
        this.onMediaError(error);
      });
  }

  // Ensuring stream created for video is releasing the camera
  componentWillUnmount(): void {
    if (this.videoStream) {
      this.videoStream.getTracks().forEach((track) => {
        if (track.readyState === "live") {
          track.stop();
        }
      });
    }
    if (this.mediaRecorder) {
      if (this.mediaRecorder.state !== "inactive") this.mediaRecorder.stop();
      this.mediaRecorder.ondataavailable = null;
    }
    clearInterval(this.intervalDuration);
  }

  onRecordingDataAvailable(e: BlobEvent): void {
    const blob = e.data;
    let chunks: Blob[];

    if (this.videoChunks && this.videoChunks.length > 0) {
      chunks = [...this.videoChunks];
    } else {
      chunks = [];
    }

    if (blob && blob.size > 0) {
      chunks.push(blob);
      this.videoChunks = chunks;
      // when mediaRecorder.stop() is called we trigger event dataAvalaible once with final datas
      if (this.mediaRecorder?.state === "inactive") {
        const newVideoBlob = new Blob(this.videoChunks, {
          type: this.mimeType
        });

        this.onNewVideo(newVideoBlob);
      }
    }
  }

  abstract onMediaError(error: unknown): void;

  abstract onNewVideo(blob: Blob): void;

  abstract setVideoRecording(value: boolean): void;

  abstract setRecordingDuration(value: number): void;

  getMediaConstraints(): MediaTrackConstraints {
    return {
      frameRate: 25,
      width: VIDEO_CONSTRAINTS.width,
      height: VIDEO_CONSTRAINTS.height
    };
  }

  setIntervalDuration = (): void => {
    this.intervalDuration = setInterval(() => {
      this.updateRecordingDuration();
    }, EACH_SECONDS);
  };

  getVideoDurationLabel(recordingDuration: number): string {
    const videoRecordingMinutes =
      recordingDuration >= 60 ? Math.floor((recordingDuration % 3600) / 60) : 0;
    const videoRecordingSecondes =
      recordingDuration >= 60
        ? Math.floor((recordingDuration % 3600) % 60)
        : recordingDuration;
    const videoRecordingLabel = `${
      videoRecordingMinutes > 9 ? "" : 0
    }${videoRecordingMinutes}:${
      videoRecordingSecondes > 9 ? "" : 0
    }${videoRecordingSecondes}`;

    return videoRecordingLabel;
  }

  abstract updateRecordingDuration(): void;

  async startWebcamRecording(): Promise<void> {
    if (this.mediaRecorder) {
      if (this.mediaRecorder.state !== "recording") {
        this.setVideoRecording(true);
        this.videoChunks = [];
        this.mediaRecorder.start(10);
        this.setIntervalDuration();
      } else {
        // if already recotrding and triggered startWebcam means state was to slow, we stop recording and relaunch it
        this.mediaRecorder?.stop();
        clearInterval(this.intervalDuration);
        this.setVideoRecording(true);
        this.videoChunks = [];
        this.mediaRecorder.start(10);
        this.setIntervalDuration();
      }
    }
  }

  stopWebcamRecording(): void {
    if (this.mediaRecorder && this.videoChunks && this.videoChunks.length > 0) {
      this.mediaRecorder?.stop();
      clearInterval(this.intervalDuration);
    }

    this.setVideoRecording(false);
    this.setRecordingDuration(0);
  }
}
