import React, { FC, useEffect, useState } from "react";
import { StyleSheet, View, TextInput, Text } from "react-native";
import moment from "moment";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import i18n from "../../services/i18n";

import { editStudentPaper } from "../../modules/examTaking/actions/studentPaper";

import CustomModal from "../atoms/CustomModal";
import Button from "../molecules/Button";
import {
  COLOR_BLUE_TESTWE,
  COLOR_GREY_BORDER,
  COLOR_RED_50
} from "../../../static/misc/colors";
import {
  FONTSIZE_14,
  FONT_GILROY_BOLD,
  PADDING_SIDES
} from "../../../static/misc/constants";

interface IProps {
  _editStudentPaper: any;
  studentPaper: any;
  preventMode?: boolean;
  displayModal?: boolean;
  onCloseModal?: any;
}

const ModalResumingExam: FC<IProps> = ({
  _editStudentPaper,
  studentPaper,
  preventMode,
  displayModal,
  onCloseModal = () => null
}) => {
  const [display, setDisplay] = useState(false);
  const [startAt, setStartAt] = useState(null);
  const [text, setText] = useState("");

  const nbWord = text.match(/\b(\w+)\b/g) || [];

  const disabled = text === "" || nbWord.length < 10 || nbWord.length > 100;

  useEffect(() => {
    if (!startAt) {
      setStartAt(moment(moment.now()));
    }
    // if (!preventMode) {
    //   const fnc = window.addEventListener(
    //     "focus",
    //     (e) => {
    //       setDisplay(true);
    //     },
    //     false
    //   );
    //   return window.removeEventListener("focus", fnc);
    // }
  }, []);

  const sendText = () => {
    const duration = moment
      .duration(moment(moment.now()).diff(startAt))
      .seconds();

    const format = new Date(duration * 1000).toISOString().slice(11, 19);

    const resuming = {
      reason: text,
      date: moment(moment.now()).format(),
      duration: format,
      timezone: "Europe/Paris"
    };

    _editStudentPaper({
      ...studentPaper,
      studentPaperResumings: [
        // check if is possible
        ...(studentPaper.studentPaperResumings
          ? [...studentPaper.studentPaperResumings, resuming]
          : [resuming])
      ]
    });

    setDisplay(false);
    setText("");
    onCloseModal();
  };

  const truncate = (str: string, noWords: number) => {
    return str.split(" ").splice(0, noWords).join(" ");
  };

  return (
    <CustomModal
      heightModal="30%"
      widthModal="40%"
      borderRadiusModal={5}
      showCloseButton={false}
      visible={preventMode ? displayModal : display}
      modalStyle={{ padding: 20 }}
      onCloseModal={() => null}
    >
      <View>
        <Text style={styles.title}>{i18n.t("modal.askResuming")}</Text>
        <View style={styles.inputWrapper}>
          <TextInput
            value={text}
            style={styles.input}
            multiline={10}
            onChangeText={(newText: string) => setText(truncate(newText, 100))}
            defaultValue={text}
          />
          <Text style={styles.txtError}>{i18n.t("modal.minWords")}</Text>
        </View>
        <Button
          disabled={disabled}
          containerStyle={{
            marginTop: 20,
            ...(disabled ? { background: COLOR_RED_50 } : {})
          }}
          onPress={sendText}
          label={i18n.t("modal.resumeExam")}
        />
      </View>
    </CustomModal>
  );
};

const mapStateToProps = (state, props) => ({
  studentPaper: state.studentPaper.studentPapers.find(
    (s) => s.examId === props.examId && s.userId && s.userId === state.user.id
  )
});

const mapdispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators(
      {
        _editStudentPaper: (studentPaper) => editStudentPaper(studentPaper)
      },
      dispatch
    )
  };
};

const styles = StyleSheet.create({
  title: {
    fontFamily: FONT_GILROY_BOLD,
    fontSize: FONTSIZE_14,
    textAlign: "center",
    color: COLOR_BLUE_TESTWE,
    marginBottom: PADDING_SIDES * 0.2
  },
  inputWrapper: {
    borderRadius: 10,
    border: `1px solid ${COLOR_GREY_BORDER}`
  },
  input: {
    borderBottom: `1px solid ${COLOR_GREY_BORDER}`,
    height: 100
    // padding: PADDING_SIDES * 0.1
  },
  txtError: {
    fontFamily: FONT_GILROY_BOLD,
    fontSize: 7,
    color: COLOR_BLUE_TESTWE,
    textAlign: "right",
    marginTop: PADDING_SIDES * 0.1,
    marginBottom: PADDING_SIDES * 0.1,
    marginRight: PADDING_SIDES * 0.2
  }
});

export default connect(mapStateToProps, mapdispatchToProps)(ModalResumingExam);
