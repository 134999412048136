import {
  POST_STUDENT_PAPER_SUCCESS,
  UPDATE_STUDENT_PAPER_SUCCESS
} from "../../examTaking/types/studentPaper";
import {
  CLEAR_PUBLIC_KEY,
  CREATE_PUBLIC_KEY,
  GET_EXAM_PUBLIC_KEY_SUCCESS,
  PublicKeysActionsType,
  PublicKeysState
} from "../types/publickeys";

const initialState: PublicKeysState = {
  examsPublicKeys: []
};

export default function reducer(
  state = initialState,
  action: PublicKeysActionsType
): PublicKeysState {
  switch (action.type) {
    case CLEAR_PUBLIC_KEY: {
      const pkTmp = [...state.examsPublicKeys];

      if (action.payload.examId) {
        const pkIdx = pkTmp.findIndex(
          (pk) => pk.examId === action.payload.examId
        );

        if (pkIdx > -1) {
          pkTmp.splice(pkIdx, 1);
        }
        return {
          ...state,
          examsPublicKeys: [...pkTmp]
        };
      }
      return {
        examsPublicKeys: []
      };
    }

    case CREATE_PUBLIC_KEY: {
      const examsPublicKeysCpy = [...state.examsPublicKeys];

      // if public key already exists for this exam, then returning immediately
      if (
        examsPublicKeysCpy &&
        examsPublicKeysCpy.length > 0 &&
        examsPublicKeysCpy.find((key) => key.examId === action.payload.examId)
      ) {
        return {
          ...state
        };
      }

      if (action.payload.publicKey && action.payload.publicKey.length > 0)
        examsPublicKeysCpy.push({
          examId: action.payload.examId,
          publicKey: action.payload.publicKey
        });
      return {
        ...state,
        examsPublicKeys: examsPublicKeysCpy
      };
    }

    case UPDATE_STUDENT_PAPER_SUCCESS: {
      const currentExamId =
        action.meta.previousAction.payload.request.data.examId;
      const examsPublicKeysTmp = [
        ...state.examsPublicKeys.filter((e) => e.examId !== currentExamId)
      ];
      return {
        ...state,
        examsPublicKeys: examsPublicKeysTmp
      };
    }

    case POST_STUDENT_PAPER_SUCCESS: {
      const currentExamId = action.meta.previousAction.payload.examId;
      const examsPublicKeysTmp = [
        ...state.examsPublicKeys.filter((e) => e.examId !== currentExamId)
      ];
      return {
        ...state,
        examsPublicKeys: examsPublicKeysTmp
      };
    }

    case GET_EXAM_PUBLIC_KEY_SUCCESS: {
      const examsPublicKeysCpy = [...state.examsPublicKeys];
      if (
        action.payload.data.publicKey &&
        action.payload.data.publicKey.length > 0
      )
        examsPublicKeysCpy.push({
          examId: action.meta.previousAction.payload.data.examId,
          publicKey: action.payload.data.publicKey
        });
      return {
        ...state,
        examsPublicKeys: examsPublicKeysCpy
      };
    }

    default:
      return state;
  }
}
