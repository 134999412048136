import React from "react";
import { StyleSheet, Text, View } from "react-native";
import {
  COLOR_BLUE,
  COLOR_BLUE_TESTWE,
  COLOR_WHITE
} from "../../../static/misc/colors";
import {
  FONTSIZE_14,
  FONTSIZE_18,
  FONT_GILROY_BOLD,
  FONT_GILROY_REGULAR,
  PADDING_SIDES
} from "../../../static/misc/constants";
import CustomModal from "../atoms/CustomModal";
import Button from "./Button";

interface ConfirmActionModalProps {
  isVisible: boolean;
  hasCloseButton: boolean;
  labels: {
    description: string;
    confirm: string;
    abort: string;
    title: string;
  };
  onValidateAction: () => void;
  onDeniedAction: () => void;
}

class ConfirmActionModal extends React.PureComponent<ConfirmActionModalProps> {
  render(): JSX.Element {
    const {
      isVisible,
      labels,
      hasCloseButton,
      onDeniedAction,
      onValidateAction
    } = this.props;
    return (
      <CustomModal
        heightModal="30%"
        widthModal="40%"
        borderRadiusModal={5}
        showCloseButton={hasCloseButton}
        visible={isVisible}
        onCloseModal={() => onDeniedAction()}
      >
        <View style={styles.container}>
          <Text style={styles.title}>{labels.title}</Text>
          <Text style={styles.description}>
            {labels.description}&nbsp;&nbsp;&nbsp;
          </Text>
          <View style={styles.actionButtons}>
            <Button
              label={labels.abort}
              onPress={() => onDeniedAction()}
              containerStyle={[styles.inverseButtonStyle]}
              textStyle={[styles.inverseButtonTextStyle]}
            />
            <Button
              label={labels.confirm}
              onPress={() => onValidateAction()}
              containerStyle={[styles.buttonStyle]}
              textStyle={[styles.buttonTextStyle]}
            />
          </View>
        </View>
      </CustomModal>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%"
  },
  title: {
    fontFamily: FONT_GILROY_BOLD,
    fontSize: FONTSIZE_18,
    color: COLOR_BLUE_TESTWE,
    paddingVertical: PADDING_SIDES * 0.4,
    paddingHorizontal: PADDING_SIDES * 0.2
  },
  description: {
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_14,
    color: COLOR_BLUE_TESTWE,
    padding: PADDING_SIDES * 0.2
  },
  actionButtons: {
    flexDirection: "row",
    alignItems: "flex-end"
  },
  buttonStyle: {
    backgroundColor: COLOR_BLUE,
    borderColor: COLOR_BLUE,
    padding: PADDING_SIDES * 0.15,
    margin: PADDING_SIDES * 0.2
  },
  inverseButtonStyle: {
    backgroundColor: COLOR_WHITE,
    borderColor: COLOR_BLUE,
    padding: PADDING_SIDES * 0.15,
    margin: PADDING_SIDES * 0.2
  },
  buttonTextStyle: {
    fontSize: FONTSIZE_14
  },
  inverseButtonTextStyle: {
    fontSize: FONTSIZE_14,
    color: COLOR_BLUE
  }
});

export default ConfirmActionModal;
