import React from "react";

import {
  StyleSheet,
  StyleProp,
  ViewStyle,
  TouchableOpacity,
  ImageStyle,
  TextStyle,
  ImageSourcePropType
} from "react-native";
import ButtonLabel from "../atoms/ButtonLabel";
import Icon from "../atoms/Icon";
import {
  COLOR_RED,
  COLOR_RED_25,
  COLOR_RED_50,
  COLOR_WHITE
} from "../../../static/misc/colors";
import { PADDING_SIDES } from "../../../static/misc/constants";

export interface ButtonProps {
  containerStyle?: StyleProp<ViewStyle>[];
  iconStyle?: StyleProp<ImageStyle>;
  textStyle?: StyleProp<TextStyle>;
  activeOpacity?: number;
  label?: string;
  icon?: ImageSourcePropType;
  disabled?: boolean;
  onPress: () => void;
}

class Button extends React.PureComponent<ButtonProps> {
  static defaultProps = {
    activeOpacity: 0.5
  };

  render(): JSX.Element {
    const {
      containerStyle,
      activeOpacity,
      iconStyle,
      textStyle,
      icon,
      label,
      disabled,
      onPress
    } = this.props;
    return (
      <TouchableOpacity
        style={[
          disabled ? styles.disabledButton : styles.defaultContainerStyle,
          containerStyle
        ]}
        onPress={onPress}
        disabled={disabled}
        activeOpacity={activeOpacity}
      >
        {icon && <Icon icon={icon} iconStyle={[styles.icon, iconStyle]} />}
        {label && (
          <ButtonLabel
            text={label}
            textStyle={[
              disabled ? styles.disabledButtonText : styles.text,
              textStyle
            ]}
          />
        )}
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  defaultContainerStyle: {
    paddingHorizontal: PADDING_SIDES * 0.5,
    paddingVertical: PADDING_SIDES * 0.1,
    borderRadius: 15,
    alignContent: "center",
    justifyContent: "center",
    flexDirection: "row",
    borderWidth: 1,
    borderColor: COLOR_RED,
    backgroundColor: COLOR_RED
  },
  text: {
    alignSelf: "center",
    textAlignVertical: "center",
    textAlign: "center"
  },
  icon: {
    marginRight: 10
  },
  disabledButton: {
    paddingHorizontal: PADDING_SIDES * 0.5,
    paddingVertical: PADDING_SIDES * 0.1,
    borderRadius: 15,
    alignContent: "center",
    justifyContent: "center",
    flexDirection: "row",
    backgroundColor: COLOR_RED_50,
    borderColor: COLOR_RED_25,
    borderWidth: 1,

    opacity: 0.5
  },
  disabledButtonText: {
    color: COLOR_WHITE
  }
});

export default Button;
