import React from "react";
import { StyleProp, StyleSheet, Text, TextStyle } from "react-native";
import { COLOR_BLUE } from "../../../static/misc/colors";
import {
  FONTSIZE_40,
  FONT_GILROY_BOLD,
  FONT_GILROY_REGULAR,
  PADDING_SIDES
} from "../../../static/misc/constants";

interface WelcomeTxtProps {
  textStyle?: StyleProp<TextStyle>;
  boldTextStyle?: StyleProp<TextStyle>;
  regularTxt?: string;
  boldTxt?: string;
}

class WelcomeTxt extends React.PureComponent<WelcomeTxtProps> {
  render(): JSX.Element {
    const { textStyle, boldTextStyle, regularTxt, boldTxt } = this.props;
    return (
      <Text style={[styles.defaultTxt, textStyle, styles.content]}>
        {regularTxt}
        <Text style={[styles.defaultBoldTxt, boldTextStyle]}>{boldTxt}</Text>
      </Text>
    );
  }
}

const styles = StyleSheet.create({
  defaultTxt: {
    color: COLOR_BLUE,
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_40
  },
  defaultBoldTxt: {
    fontFamily: FONT_GILROY_BOLD
  },
  content: {
    paddingHorizontal: PADDING_SIDES * 0.5,
    paddingVertical: PADDING_SIDES * 0.5
  }
});

export default WelcomeTxt;
