import React from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { upperFirst } from "lodash";
import moment from "../../services/moment";
import {
  COLOR_BLUE_TESTWE,
  COLOR_WHITE_70,
  COLOR_RED,
  COLOR_YELLOW_INFO
} from "../../../static/misc/colors";
import { playIcon } from "../../../static/misc/images";
import DateTag from "../atoms/DateTag";
import TimeTag from "../atoms/TimeTag";
import AvailableTag from "../molecules/AvailableTag";
import Button from "../molecules/Button";
import UpcomingExamDetail from "../molecules/UpcomingExamDetail";
import { EACH_SECONDS } from "../../../static/misc/constants";
import i18n from "../../services/i18n";
import InfoIconWithRules from "../molecules/InfoIconWithRules";
import { ExamParam } from "../../modules/exams/types/exam";
import { MediaObjectType } from "../../modules/exams/types/attachedfiles";

interface UpcomingExamItemProps {
  containerStyle?: StyleProp<ViewStyle>;
  subject: string;
  duration: number;
  startDate?: Date;
  startTime?: Date; // Assumming time parameter as 0000-00-00 hh:mm
  examParams?: ExamParam;
  hasExamTaking: boolean;
  hasStudentPaper: boolean;
  timeZeroSecond: number;
  examId: string;
  currentAttachedFiles?: MediaObjectType;
  attachedFilesNumber: number;
  displaySingleExamInfo?: (examInfoId: string) => void;
}

interface UpcomingExamItemState {
  isGoingBackToExam: boolean;
  isAvailable: boolean;
  isTagAvailable: boolean;
}

class UpcomingExamItem extends React.Component<
  UpcomingExamItemProps,
  UpcomingExamItemState
> {
  intervalTimer!: ReturnType<typeof setInterval>;

  constructor(props: UpcomingExamItemProps) {
    super(props);
    const {
      startDate,
      timeZeroSecond,
      hasExamTaking,
      duration,
      hasStudentPaper
    } = this.props;
    // Setting up the interval to check if the exam has become available
    if (startDate !== undefined)
      this.state = {
        isGoingBackToExam: hasExamTaking && hasStudentPaper,
        isAvailable: hasExamTaking
          ? moment(
              new Date(startDate).getTime() +
                timeZeroSecond * 1000 +
                duration * 1000
            ).isSameOrAfter(new Date().getTime())
          : moment(
              new Date(startDate).getTime() + timeZeroSecond * 1000
            ).isSameOrAfter(new Date().getTime()) === true,
        isTagAvailable: hasExamTaking
          ? true
          : moment(new Date(startDate).getTime()).isSameOrBefore(
              new Date().getTime()
            ) === true
      };
  }

  componentDidMount(): void {
    const {
      startDate,
      timeZeroSecond,
      duration,
      hasExamTaking,
      hasStudentPaper
    } = this.props;
    this.intervalTimer = setInterval(() => {
      if (startDate !== undefined) {
        if (hasExamTaking && hasStudentPaper) {
          this.setState({ isGoingBackToExam: true });
          if (
            moment(
              new Date(startDate).getTime() +
                timeZeroSecond * 1000 +
                duration * 1000
            ).isSameOrAfter(new Date().getTime()) === true
          ) {
            this.setState({ isAvailable: true, isTagAvailable: true });
          }
        } else if (
          moment(
            new Date(startDate).getTime() + timeZeroSecond * 1000
          ).isSameOrAfter(new Date().getTime()) === true
        ) {
          this.setState({ isAvailable: true });
          if (
            moment(new Date(startDate).getTime()).isSameOrBefore(
              new Date().getTime()
            ) === true
          )
            this.setState({ isTagAvailable: true });
          else this.setState({ isTagAvailable: false });
        } else {
          this.setState({ isAvailable: false });
          if (
            moment(new Date(startDate).getTime()).isSameOrBefore(
              new Date().getTime()
            ) === true
          )
            this.setState({ isTagAvailable: true });
          else this.setState({ isTagAvailable: false });
        }
      }
    }, EACH_SECONDS);
  }

  componentWillUnmount(): void {
    clearInterval(this.intervalTimer);
  }

  getAvailableText(showTimeOptions: boolean, startDate: Date): string {
    const { duration, timeZeroSecond } = this.props;
    const { isGoingBackToExam } = this.state;

    if (!showTimeOptions) {
      return `${i18n.t("homepage.available")}`;
    }

    if (isGoingBackToExam) {
      return `${i18n.t("homepage.availableBack")}: ${moment(
        new Date(
          new Date(startDate).getTime() +
            timeZeroSecond * 1000 +
            duration * 1000
        )
      ).format("LL HH[h]mm")}`;
    }

    return `${i18n.t("homepage.availableUntil")} : ${moment(
      new Date(startDate.getTime() + timeZeroSecond * 1000)
    ).format("LL HH[h]mm")}`;
  }

  render(): JSX.Element {
    const {
      containerStyle,
      subject,
      duration,
      startDate,
      startTime,
      examParams,
      examId,
      currentAttachedFiles,
      attachedFilesNumber,
      displaySingleExamInfo
    } = this.props;
    const { isAvailable, isTagAvailable, isGoingBackToExam } = this.state;
    const opacity = isAvailable ? 1 : 0.3;

    const totalFiles = attachedFilesNumber;
    const downloadedFiles =
      currentAttachedFiles && currentAttachedFiles.medias.length > 0
        ? currentAttachedFiles.medias.filter((file) => file.isDownloaded).length
        : 0;
    const percent = (downloadedFiles * 100) / totalFiles;
    const showTimeOptions = examParams?.showTimeOptions ?? true;

    return (
      <View style={[styles.defaultContainerStyle, containerStyle]}>
        <View style={styles.dateWrapper}>
          {startDate && (
            <DateTag
              day={moment(startDate).format("DD")}
              month={upperFirst(moment(startDate).format("MMM"))}
              dayStyle={{ opacity }}
            />
          )}
          {startTime && (
            <TimeTag
              time={moment(startTime).format("HH[h]mm")}
              containerStyle={{ opacity }}
            />
          )}
        </View>

        <View style={styles.detailWrapper}>
          <UpcomingExamDetail
            duration={duration}
            subject={subject}
            containerStyle={{ opacity }}
          />
          {(!isAvailable && (
            <AvailableTag
              text={
                isGoingBackToExam
                  ? `${i18n.t("homepage.backUnavailable")}`
                  : `${i18n.t("homepage.unavailable")}`
              }
              dotStyle={styles.expiredIcon}
              containerStyle={{ paddingTop: 10 }}
            />
          )) ||
            (totalFiles > 0 && downloadedFiles < totalFiles && (
              <AvailableTag
                text={`${i18n.t("homepage.incomplete", [percent.toFixed()])}`}
                dotStyle={styles.incompleteIcon}
                containerStyle={{ paddingTop: 10 }}
              />
            )) ||
            (isAvailable && isTagAvailable && startDate !== undefined && (
              <AvailableTag
                text={this.getAvailableText(showTimeOptions, startDate)}
                containerStyle={{ paddingTop: 15 }}
              />
            ))}
        </View>

        <View style={styles.buttonWrapper}>
          {examParams && <InfoIconWithRules data={examParams} />}
          <Button
            label={
              isGoingBackToExam
                ? `${i18n.t("homepage.backToExam")}`
                : `${i18n.t("homepage.exam")}`
            }
            disabled={!isAvailable || !displaySingleExamInfo}
            containerStyle={[
              !isAvailable && styles.disabledButton,
              styles.examButton
            ]}
            icon={playIcon}
            onPress={() =>
              displaySingleExamInfo ? displaySingleExamInfo(examId) : null
            }
          />
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  defaultContainerStyle: {
    borderRadius: 10,
    zIndex: -10,
    height: 100, // temporar

    backgroundColor: COLOR_BLUE_TESTWE,
    paddingHorizontal: "4%",
    paddingVertical: "2%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  dateWrapper: {
    alignContent: "space-between"
  },
  detailWrapper: {
    flex: 1,
    marginLeft: "10%",
    marginRight: "3%",
    alignContent: "flex-start"
  },
  expiredIcon: {
    backgroundColor: COLOR_RED,
    alignSelf: "flex-start"
  },
  incompleteIcon: {
    backgroundColor: COLOR_YELLOW_INFO,
    alignSelf: "flex-start"
  },
  buttonWrapper: {
    flexDirection: "row",
    alignItems: "center"
  },
  examButton: {
    flex: 1,
    marginLeft: 20
  },
  disabledButton: {
    borderColor: COLOR_BLUE_TESTWE,
    backgroundColor: COLOR_WHITE_70,
    opacity: 0.5
  }
});

export default UpcomingExamItem;
