import Iframe from "react-iframe";
import _ from "lodash";
import React from "react";
import { View, StyleSheet, LayoutChangeEvent } from "react-native";
import { COLOR_WHITE } from "../../../../static/misc/colors";
import {
  DEV,
  ENV,
  IS_PREVIEW,
  LARGE_WIDTH,
  MIME_SPREADSHEET
} from "../../../../static/misc/constants";
import {
  CALC,
  EXAM_TAKING_LEFT_MENU,
  LOGOUT,
  MEDIAS,
  RULES
} from "../../../../static/misc/menu";

import { MediaType } from "../../../modules/exams/types/attachedfiles";
import { ExamPart, ExamType } from "../../../modules/exams/types/exam";
import { CalculatorTypeEnum } from "../../../modules/examTaking/types/calculator";
import { ModifyBookMarkedAction } from "../../../modules/examTaking/types/examTaking";
import { SetCursorExamAction } from "../../../modules/examTaking/types/timer";
import {
  FillStudentPaperAction,
  StudentAnswerType,
  StudentPaperType
} from "../../../modules/examTaking/types/studentPaper";
import { LocalApiErrorAction } from "../../../modules/main/types/error";
import { TimerTypeEnum } from "../../atoms/ExamTakingTimer";
import ExamTakingContent, {
  ChangeEnum
} from "../../organisms/ExamTakingContent";
import ExamTakingMenuLeft from "../../organisms/ExamTakingMenuLeft";
import ExamTakingFullscreenMedia from "../../organisms/ExamTakingFullscreenMedia";
import RulesView from "../../organisms/RulesView";

interface ExamTakingBodyProps {
  currentExam?: ExamType;
  currentAttachedFiles: MediaType[] | undefined;
  currentStudentPaper: StudentPaperType | undefined;
  forceStopTimers: boolean;
  shouldStopRecording: boolean;
  stopSavingStudentAnswers: boolean;
  onEndExam: boolean;
  token: string;
  setExamCursor: (
    cursor:
      | {
          examPart: string;
          partIndex: string;
        }
      | undefined
  ) => SetCursorExamAction;
  appDataPath: string;
  remoteExam: boolean;
  remoteExamUrl?: string;
  onFinishExam: () => void;
  modifyBookMarked: (
    examId: string,
    currentQuestion: string
  ) => ModifyBookMarkedAction;
  fillStudentPaper: (
    examId: string,
    studentAnswers: StudentAnswerType[],
    currentUserId: string
  ) => FillStudentPaperAction;
  uploadMediaObjects: (
    studentAnswers: StudentAnswerType[],
    isLastQuestion: boolean
  ) => void;
  editAttachedFileUri(mediaId: string, uri: string): void;
  toggleAudioProctoring: () => void;
  onForceNavigation: (
    callback: (changeType: ChangeEnum) => void,
    changeType: ChangeEnum
  ) => void;
  onRemainingDurationWarning: (
    callback: (changeType: ChangeEnum) => void,
    changeType: ChangeEnum
  ) => void;
  navigateToHomepage: () => void;
  localApiError: (err: any) => LocalApiErrorAction;
  currentUserId: string;
  toggleRemainingDurationWarningModal(
    visible: boolean,
    changeType?: ChangeEnum,
    callback?: (changeType: ChangeEnum) => void
  ): void;
}

interface ExamTakingBodyState {
  currentPartIndex: number;
  currentExamPart: number;
  toggleRules: boolean;
  displayCalc: boolean;
  displayMedias: boolean;
  isMediaFullscreen: boolean;
  focusedMediaId: string;
  focusedType: string;
  screenWidth: number;
  screenHeight: number;
  currentPdfPage: number;
}

class ExamTakingBody extends React.PureComponent<
  ExamTakingBodyProps,
  ExamTakingBodyState
> {
  constructor(props: ExamTakingBodyProps) {
    super(props);
    this.state = {
      currentPartIndex: 0,
      currentExamPart: 0,
      toggleRules: false,
      displayCalc: false,
      displayMedias: false,
      isMediaFullscreen: false,
      focusedMediaId: "",
      focusedType: "",
      screenWidth: 0,
      screenHeight: 0,
      currentPdfPage: 1
    };
  }

  // Toggle the rules panel on the right
  onToggleRules(): void {
    const { toggleRules } = this.state;

    this.setState(() => ({
      toggleRules: !toggleRules
    }));
  }

  // Display the calculator
  onDisplayCalc(): void {
    const { displayCalc } = this.state;

    this.setState({
      displayCalc: !displayCalc
    });
  }

  // DEV ONLY - to forcefully leave the exam
  onLeaveExam(): void {
    const { navigateToHomepage } = this.props;

    if (ENV === DEV) navigateToHomepage();
  }

  // Display the media panel on the left
  onDisplayMedias(): void {
    const { displayMedias } = this.state;

    this.setState({
      displayMedias: !displayMedias
    });
  }

  // Triggered when a timer has ended, to force the navigation to the next part/question or end the exam
  onFinishedTimer(
    callback: (changeType: ChangeEnum) => void,
    timerType: TimerTypeEnum
  ): void {
    const { onForceNavigation } = this.props;
    const { currentExam } = this.props;
    const { currentExamPart, currentPartIndex } = this.state;
    const examParts = currentExam?.examParts as ExamPart[];

    const lengthPartIndexesOfExamParts =
      examParts[currentExamPart].partIndexes.length;
    const lengthExamParts = examParts.length;

    let changeType: ChangeEnum;

    // Based on the timer type, defining the change type - change to question, part or end exam
    if (
      (timerType === TimerTypeEnum.EXERCISE ||
        timerType === TimerTypeEnum.QUESTION) &&
      lengthPartIndexesOfExamParts > currentPartIndex + 1
    ) {
      changeType = ChangeEnum.NEXTQUESTION;
    } else if (
      (timerType === TimerTypeEnum.EXERCISE ||
        timerType === TimerTypeEnum.QUESTION ||
        timerType === TimerTypeEnum.PART) &&
      lengthExamParts > currentExamPart + 1
    ) {
      changeType = ChangeEnum.NEXTPART;
    } else if (
      timerType === TimerTypeEnum.EXAM ||
      timerType === TimerTypeEnum.EXERCISE ||
      timerType === TimerTypeEnum.QUESTION ||
      timerType === TimerTypeEnum.PART
    ) {
      changeType = ChangeEnum.FINISHEXAM;
    } else {
      changeType = ChangeEnum.NONE;
    }

    // Callback to force the navigation to the next step
    onForceNavigation(callback, changeType);
  }

  toggleMediaFullscreen(mediaId?: string, type?: string): void {
    this.setState({
      isMediaFullscreen: !!mediaId,
      focusedMediaId: mediaId ?? "",
      focusedType: type ?? ""
    });
  }

  // Update the current exam part and/or current part index
  updateCurrentIndexes(examPart: number, partIndex: number): void {
    const { setExamCursor, currentExam } = this.props;

    // Set the exam cursor to the current question or exercice
    if (currentExam?.examParams?.proctoringLive) {
      if (
        currentExam &&
        currentExam.examParts &&
        currentExam.examParts[examPart]
      ) {
        if (
          typeof currentExam.examParts[examPart] !== "string" &&
          currentExam.examParts[examPart].partIndex
        ) {
          setExamCursor({
            examPart: examPart ? currentExam.examParts[examPart].id : "",
            partIndex: partIndex
              ? currentExam.examParts[examPart].partIndex[partIndex].id
              : ""
          });
        }
        setExamCursor({
          examPart: examPart ? currentExam.examParts[examPart].id : "",
          partIndex: ""
        });
      }
    }

    this.setState({
      currentExamPart: examPart,
      currentPartIndex: partIndex
    });
  }

  render(): JSX.Element {
    const {
      currentExam,
      currentStudentPaper,
      currentAttachedFiles,
      forceStopTimers,
      shouldStopRecording,
      onEndExam,
      stopSavingStudentAnswers,
      token,
      appDataPath,
      remoteExam,
      remoteExamUrl,
      fillStudentPaper,
      modifyBookMarked,
      uploadMediaObjects,
      editAttachedFileUri,
      navigateToHomepage,
      toggleAudioProctoring,
      onFinishExam,
      onRemainingDurationWarning,
      localApiError,
      currentUserId,
      toggleRemainingDurationWarningModal
    } = this.props;
    const {
      currentExamPart,
      currentPartIndex,
      toggleRules,
      displayMedias,
      displayCalc,
      isMediaFullscreen,
      focusedMediaId,
      focusedType,
      screenWidth,
      screenHeight,
      currentPdfPage
    } = this.state;

    // Defining which controls should be available on the left menu - medias, calc, etc.
    const shouldDisplayMedias =
      currentAttachedFiles !== undefined &&
      currentAttachedFiles.filter(
        (item) => !_.includes(MIME_SPREADSHEET, item.mimeType)
      ).length > 0;
    const baseLeftMenu = EXAM_TAKING_LEFT_MENU;
    const updatedLeftMenu = baseLeftMenu.map((btn) => {
      const item = { ...btn };
      if (remoteExam) {
        item.show = btn.id === RULES;
      } else {
        if (btn.id === CALC) {
          if (
            currentExam?.examParams?.basicCalculator ||
            currentExam?.examParams?.scientificCalculator
          ) {
            item.show = true;
          }
        }
        if (btn.id === MEDIAS) {
          item.show = shouldDisplayMedias;
        }
        if (btn.id === LOGOUT) {
          if (IS_PREVIEW || ENV !== DEV) {
            item.show = false;
          }
        }
      }
      return item;
    });

    let calcType;

    // If both calculators are allowed, we want to display the scientific one only
    if (displayCalc && currentExam?.examParams?.scientificCalculator) {
      calcType = CalculatorTypeEnum.SCIENTIFIC;
    } else if (displayCalc && currentExam?.examParams?.basicCalculator) {
      calcType = CalculatorTypeEnum.BASIC;
    }
    return (
      <View
        style={styles.contentContainer}
        onLayout={(event: LayoutChangeEvent) =>
          this.setState({
            screenWidth: event.nativeEvent.layout.width,
            screenHeight: event.nativeEvent.layout.height
          })
        }
      >
        <ExamTakingMenuLeft
          items={updatedLeftMenu}
          shouldDisplayMedias={shouldDisplayMedias}
          callback={(index: number) => {
            switch (index) {
              case 0: {
                this.onToggleRules();
                break;
              }
              case 1: {
                this.onDisplayCalc();
                break;
              }
              case 2: {
                this.onDisplayMedias();
                break;
              }
              case 5: {
                this.onLeaveExam();
                break;
              }
              default: {
                break;
              }
            }
          }}
        />
        {remoteExam && remoteExamUrl ? (
          <Iframe width="100%" height="100%" url={remoteExamUrl} />
        ) : (
          <View style={[styles.column, { width: LARGE_WIDTH }]}>
            {currentExam?.examParts && (
              <ExamTakingContent
                currentUserId={currentUserId}
                localApiError={localApiError}
                toggleRemainingDurationWarningModal={
                  toggleRemainingDurationWarningModal
                }
                token={token}
                appDataPath={appDataPath}
                examId={currentExam.id}
                currentExam={currentExam}
                currentAttachedFiles={currentAttachedFiles}
                editAttachedFileUri={editAttachedFileUri}
                examParts={currentExam.examParts as ExamPart[]}
                duration={
                  !(currentExam.hasTimer === true)
                    ? currentExam.remainingDuration
                    : undefined
                }
                currentStudentPaper={currentStudentPaper}
                forceStopTimers={forceStopTimers}
                shouldStopRecording={shouldStopRecording}
                onEndExam={onEndExam}
                stopSavingStudentAnswers={stopSavingStudentAnswers}
                displayMedias={displayMedias}
                displayCalc={displayCalc}
                calcType={calcType}
                isMediaFullscreen={isMediaFullscreen}
                modifyBookMarked={modifyBookMarked}
                fillStudentPaper={fillStudentPaper}
                uploadMediaObjects={uploadMediaObjects}
                navigateToHomepage={navigateToHomepage}
                toggleAudioProctoring={toggleAudioProctoring}
                onChangeQuestionOrPart={(
                  examPart: number,
                  partIndex: number
                ) => {
                  this.updateCurrentIndexes(examPart, partIndex);
                  this.setState({
                    currentPdfPage: 1
                  });
                }}
                onFinishExam={() => onFinishExam()}
                onFinishTimer={(
                  callback: (changeType: ChangeEnum) => void,
                  timerType: TimerTypeEnum
                ) => {
                  this.onFinishedTimer(callback, timerType);
                }}
                onRemainingDurationWarning={(
                  callback: (changeType: ChangeEnum) => void,
                  changeType: ChangeEnum
                ) => onRemainingDurationWarning(callback, changeType)}
                toggleMediaFullscreen={(mediaId: string, type: string) =>
                  this.toggleMediaFullscreen(mediaId, type)
                }
                currentPdfPage={currentPdfPage}
                onPdfPageChange={(pdfPage: number) => {
                  this.setState({ currentPdfPage: pdfPage });
                }}
                workBooks={currentExam.workBooks}
              />
            )}
          </View>
        )}
        {toggleRules && (
          <RulesView
            currentExam={currentExam}
            onClose={() => this.onToggleRules()}
          />
        )}
        {isMediaFullscreen && currentExam && currentAttachedFiles && (
          <View
            style={[
              styles.fullScreenMedia,
              { width: screenWidth, height: screenHeight }
            ]}
          >
            <ExamTakingFullscreenMedia
              currentPdfPage={currentPdfPage}
              onPdfPageChange={(pdfPage: number) => {
                this.setState({ currentPdfPage: pdfPage });
              }}
              currentExamPart={currentExamPart}
              currentPartIndex={currentPartIndex}
              currentExam={currentExam}
              currentAttachedFiles={currentAttachedFiles}
              focusedMediaId={focusedMediaId}
              focusedType={focusedType}
              widthMedia={screenWidth}
              heightMedia={screenHeight}
              onUnsetFullscreen={() => this.toggleMediaFullscreen()}
              onChangedFocusedMedia={(mediaId: string) =>
                this.setState({
                  focusedMediaId: mediaId,
                  currentPdfPage: 1
                })
              }
            />
          </View>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    height: "100%",
    backgroundColor: COLOR_WHITE,
    justifyContent: "flex-start",
    flexDirection: "row"
  },
  column: {
    flexDirection: "column",
    flexGrow: 1,
    position: "relative",
    zIndex: 980
  },
  fullScreenMedia: {
    position: "absolute",
    right: 0,
    top: 0,
    backgroundColor: COLOR_WHITE,
    zIndex: 1000
  }
});

export default ExamTakingBody;
