import React, { useRef } from "react";
import { StyleSheet, Image, View, Text } from "react-native";
import { useHover } from "react-native-web-hooks";
import { commentary } from "../../../static/misc/images";
import {
  COLOR_SHADOW,
  COLOR_BLUE_TESTWE,
  COLOR_WHITE
} from "../../../static/misc/colors";
import { FONT_GILROY_ITALIC } from "../../../static/misc/constants";

interface SeeCommentProps {
  hasRemark: string | undefined;
}

const SeeComment = (props: SeeCommentProps): JSX.Element => {
  const { hasRemark } = props;
  const ref = useRef(null);
  const isHovered = useHover(ref);

  return (
    <View
      ref={ref}
      style={[styles.container, !hasRemark ? { opacity: 0.3 } : {}]}
    >
      <Image source={commentary} style={styles.images} />
      {isHovered && hasRemark && (
        <View style={styles.hoverContainer}>
          <Text style={styles.hoverText}>{hasRemark}</Text>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 48,
    height: 48,
    borderStyle: "dotted",
    borderRadius: 50,
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 0.5,
    borderColor: COLOR_BLUE_TESTWE
  },
  images: {
    width: 22,
    height: 21
  },
  hoverContainer: {
    position: "absolute",
    bottom: 0,
    zIndex: 10,
    right: 50,
    paddingHorizontal: 25,
    paddingVertical: 25,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: COLOR_WHITE,
    width: 400,
    borderRadius: 5,
    shadowColor: COLOR_SHADOW,
    shadowOffset: {
      width: 0,
      height: 0
    },
    shadowRadius: 5,
    shadowOpacity: 1
  },
  hoverText: {
    fontFamily: FONT_GILROY_ITALIC,
    color: COLOR_BLUE_TESTWE,
    fontSize: 11
  }
});

export default SeeComment;
