import React from "react";

import {
  StyleSheet,
  View,
  Text,
  Animated,
  Image,
  TouchableWithoutFeedback
} from "react-native";
import { COLOR_GREEN_VALID, COLOR_WHITE } from "../../../static/misc/colors";
import {
  DURATION_ANIMATION_NOTIFICATION,
  DURATION_NOTIFICATION,
  FONTSIZE_16,
  FONT_GILROY_REGULAR,
  HEIGHT_HEADER,
  NOTIFICATION_END_POINT,
  PADDING_SIDES,
  WIN
} from "../../../static/misc/constants";
import { crossIcon } from "../../../static/misc/images";
import {
  ResetSuccessAction,
  SuccessState
} from "../../modules/main/types/success";

interface NotificationProps {
  success: SuccessState;
  resetSuccess: () => ResetSuccessAction;
}

interface NotificationState {
  notifications: any;
  notification: any;
  running: any;
  notifTop: any;
}

class SuccessNotification extends React.Component<
  NotificationProps,
  NotificationState
> {
  private timeout: any;

  constructor(props: NotificationProps) {
    super(props);
    this.state = {
      notifications: [],
      notification: null,
      running: false,
      notifTop: new Animated.Value(-HEIGHT_HEADER)
    };
  }

  componentDidMount(): void {
    this.addNotif(null);
  }

  componentDidUpdate(prevProps: NotificationProps): void {
    this.addNotif(prevProps);
  }

  // Add an success notification to the stack
  addNotif = (prevProps: NotificationProps | null): void => {
    const { resetSuccess, success } = this.props;
    const { notifications, running } = this.state;
    if (
      (!prevProps && success.success === true) ||
      (prevProps &&
        success.success === true &&
        (prevProps.success.success !== success.success ||
          prevProps.success.successMessage !== success.successMessage))
    ) {
      resetSuccess();
      if (
        notifications.find(
          (notif: any) => notif.message === success.successMessage
        ) === undefined
      ) {
        notifications.push({
          type: "success",
          message: success.successMessage
        });
        if (!running) {
          this.updateNotification();
        }
      }
    }
  };

  // Displaying the success notification
  showNotif(tmp: Array<string>): void {
    const { notifTop } = this.state;
    this.setState({
      notification: tmp.shift(),
      notifications: tmp || [],
      running: true
    });
    Animated.timing(notifTop, {
      toValue: NOTIFICATION_END_POINT,
      duration: DURATION_ANIMATION_NOTIFICATION,
      useNativeDriver: true
    }).start(() => {
      this.timeout = setTimeout(
        this.updateNotification.bind(this),
        DURATION_NOTIFICATION
      );
    });
  }

  // Updating the success notification
  updateNotification(): void {
    const { notifications, notification, notifTop } = this.state;
    const tmp = notifications;

    clearTimeout(this.timeout);
    if (tmp && tmp.length) {
      if (notification) {
        Animated.timing(notifTop, {
          toValue: -HEIGHT_HEADER,
          duration: DURATION_ANIMATION_NOTIFICATION,
          useNativeDriver: true
        }).start(() => {
          this.showNotif(tmp);
        });
      } else this.showNotif(tmp);
    } else {
      Animated.timing(notifTop, {
        toValue: -HEIGHT_HEADER,
        duration: DURATION_ANIMATION_NOTIFICATION,
        useNativeDriver: true
      }).start(() => {
        this.setState({
          notification: null,
          notifications: [],
          running: false
        });
      });
    }
  }

  render(): JSX.Element | null {
    const { notification, notifTop } = this.state;
    if (notification && notification.message) {
      return (
        <Animated.View
          style={[styles.container, { transform: [{ translateY: notifTop }] }]}
        >
          <TouchableWithoutFeedback
            onPress={() => {
              this.updateNotification();
            }}
          >
            <View style={[styles.notification, styles.success]}>
              <Text style={styles.notificationText}>
                {notification.message}
              </Text>
              <Image style={styles.closeImg} source={crossIcon} />
            </View>
          </TouchableWithoutFeedback>
        </Animated.View>
      );
    }
    return null;
  }
}

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    width: "100%",
    zIndex: 200,
    elevation: 4
  },
  notification: {
    width: WIN.width - PADDING_SIDES * 0.5,
    opacity: 1,
    alignSelf: "center",
    borderRadius: 10,
    padding: 10,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  success: {
    backgroundColor: COLOR_GREEN_VALID
  },
  notificationText: {
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_16,
    color: COLOR_WHITE,
    width: (WIN.width / 100) * 95 - 20 - 20
  },
  closeImg: {
    tintColor: COLOR_WHITE,
    width: 10,
    height: 10
  }
});

export default SuccessNotification;
