import React from "react";
import { ScrollView, StyleSheet } from "react-native";
import CustomModal from "../atoms/CustomModal";
import i18n from "../../services/i18n";
import { PADDING_SIDES } from "../../../static/misc/constants";
import HtmlRenderer from "../atoms/HtmlRenderer";

export interface CguModalProps {
  isVisible: boolean;
  onShowCguModal: (bool: boolean) => void;
}

class CguModal extends React.PureComponent<CguModalProps> {
  render(): JSX.Element {
    const { isVisible, onShowCguModal } = this.props;

    return (
      <CustomModal
        heightModal="90vh"
        widthModal="90vw"
        borderRadiusModal={5}
        visible={isVisible}
        showCloseButton
        onCloseModal={() => onShowCguModal(false)}
      >
        <ScrollView style={styles.container}>
          <HtmlRenderer content={`${i18n.t("cgu.content")}`} isLinkUsable />
        </ScrollView>
      </CustomModal>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    padding: PADDING_SIDES,
    height: "90vh",
    width: "90vw"
  }
});

export default CguModal;
