import React from "react";
import { StyleSheet, Text } from "react-native";
import { COLOR_BLUE_TESTWE, COLOR_RED } from "../../../static/misc/colors";
import {
  FONTSIZE_11,
  FONT_GILROY_REGULAR
} from "../../../static/misc/constants";
import i18n from "../../services/i18n";

interface WordMaxProps {
  nbWords: number;
  nbWordsMax: number;
}

class WordMax extends React.PureComponent<WordMaxProps> {
  render(): JSX.Element {
    const { nbWords, nbWordsMax } = this.props;
    return (
      <Text>
        <Text
          style={[
            styles.textStyle,
            nbWords <= nbWordsMax ? {} : { color: COLOR_RED }
          ]}
        >{`${nbWords}`}</Text>
        <Text style={styles.textStyle}>{` / ${nbWordsMax} ${i18n.t(
          "exam.wordMax"
        )}`}</Text>
      </Text>
    );
  }
}

const styles = StyleSheet.create({
  textStyle: {
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_11,
    color: COLOR_BLUE_TESTWE
  }
});

export default WordMax;
