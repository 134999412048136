/* eslint-disable jsx-a11y/media-has-caption */
import React from "react";
import * as Video from "twilio-video";
import { StyleSheet, View } from "react-native";
import { ipcRenderer } from "electron";
import { IS_WEB_ENABLE } from "../../../static/misc/constants";
import CustomModal from "../atoms/CustomModal";
import ExtraBoldTitle from "../atoms/ExtraBoldTitle";
import LightText from "../atoms/LightText";
import Button from "../molecules/Button";
import i18n from "../../services/i18n";

type SourceType = {
  id: string;
  name: string;
};

type ConstraintsType = {
  [key: string]: any;
};

type MediaServiceType = {
  getUserMedia: (constraints: ConstraintsType) => Promise<MediaStream>;
  getDisplayMedia: (constraints: ConstraintsType) => Promise<MediaStream>;
};

interface ProctoringLiveHandlerProps {
  twilioAccessToken: string;
  roomId: string;
  proctorIdentity?: string;
  shouldEnableProctorAudio: boolean;
  confirmProctorStudentConnected: () => void;
}

interface ProctoringLiveHandlerState {
  currentRoom: Video.Room | null;
  currentTrack: MediaStreamTrack | null;
  isConnected: boolean;
  wrongSelection: boolean;
  manualStreamEnded: boolean;
  shareScreenModalVisible: boolean;
}

class ProctoringLiveHandler extends React.Component<
  ProctoringLiveHandlerProps,
  ProctoringLiveHandlerState
> {
  audioRef: any;

  constructor(props: ProctoringLiveHandlerProps) {
    super(props);

    this.state = {
      wrongSelection: false,
      currentTrack: null,
      currentRoom: null,
      isConnected: false,
      manualStreamEnded: false,
      shareScreenModalVisible: false
    };

    this.audioRef = React.createRef();
  }

  componentDidMount(): void {
    this.connectToRoom(true);
    this.toggleProctorAudio(true);
  }

  componentDidUpdate(
    prevProps: ProctoringLiveHandlerProps,
    prevState: ProctoringLiveHandlerState
  ): void {
    const { shouldEnableProctorAudio, proctorIdentity } = this.props;
    const {
      isConnected,
      wrongSelection,
      manualStreamEnded,
      shareScreenModalVisible,
      currentTrack
    } = this.state;

    const shouldDisplayScreenSharingModal =
      isConnected &&
      shareScreenModalVisible === false &&
      (!currentTrack ||
        (wrongSelection && !prevState.wrongSelection) ||
        (manualStreamEnded && !prevState.manualStreamEnded));

    // TODO: Check if this is a good way to reconnect to the room
    // if (!isConnected) {
    //   this.connectToRoom();
    // }

    if (shouldDisplayScreenSharingModal) {
      this.setState({ shareScreenModalVisible: true });
    }

    if (
      shouldEnableProctorAudio !== prevProps.shouldEnableProctorAudio &&
      proctorIdentity
    ) {
      this.toggleProctorAudio(shouldEnableProctorAudio);
    }
  }

  componentWillUnmount(): void {
    const { currentRoom, currentTrack } = this.state;

    currentTrack?.stop();
    currentRoom?.disconnect();
  }

  connectToRoom(askScreenSharing = false): void {
    const {
      twilioAccessToken,
      roomId,
      confirmProctorStudentConnected
    } = this.props;
    confirmProctorStudentConnected();
    Video.connect(twilioAccessToken, {
      name: roomId,
      audio: {
        name: "audio"
      },
      video: {
        name: "webcam"
      }
    })
      .then((room) => {
        ipcRenderer.send(
          "LOG_INFO",
          "The user successfully connected to the room",
          room.name
        );
        this.setState({
          isConnected: true,
          currentRoom: room
        });

        if (askScreenSharing) this.startScreenSharing();
      })
      .catch((err) => {
        ipcRenderer.send(
          "LOG_ERROR",
          "An error occurred while the user tried to connect to the room",
          err
        );
      });
  }

  async startScreenSharing(): Promise<void> {
    const { currentRoom } = this.state;
    let idScreen = 0;

    if (IS_WEB_ENABLE) {
      const mediaDevices = navigator.mediaDevices as MediaServiceType;
      const stream = await mediaDevices.getDisplayMedia({
        video: true
      });

      const streamTrack = stream.getTracks()[0];
      if (streamTrack.label.startsWith("screen:")) {
        streamTrack.addEventListener("ended", () => {
          streamTrack.stop();
          currentRoom?.localParticipant.unpublishTrack(streamTrack);

          this.setState({ manualStreamEnded: true });
        });
        const screenTrack = new Video.LocalVideoTrack(streamTrack, {
          name: `screen:${idScreen}`,
          logLevel: "info"
        });
        idScreen += 1;
        currentRoom?.localParticipant.publishTrack(screenTrack);
        this.setState({
          currentTrack: streamTrack,
          wrongSelection: false,
          shareScreenModalVisible: false,
          manualStreamEnded: false
        });
      } else {
        streamTrack.stop();
        this.setState({ wrongSelection: true });
      }
    } else {
      // This desktop capturer change occure following electron update
      // https://www.electronjs.org/fr/docs/latest/breaking-changes#changements-majeurs-pr%C3%A9vus-de-lapi-170
      const desktopCapturer = {
        getSources: (opts: { [key: string]: any }): Promise<SourceType[]> =>
          ipcRenderer.invoke("DESKTOP_CAPTURER_GET_SOURCES", opts)
      };
      desktopCapturer
        .getSources({ types: ["window", "screen"] })
        .then((sources) => {
          sources.forEach(async (source) => {
            if (
              source.id.startsWith("screen:") ||
              source.name === "Entire Screen"
            ) {
              try {
                const mediaDevices = navigator.mediaDevices as MediaServiceType;
                const stream = await mediaDevices.getUserMedia({
                  audio: false,
                  video: {
                    mandatory: {
                      chromeMediaSource: "desktop",
                      chromeMediaSourceId: source.id // on MacOS 10.15, the second number in source.id is an identifier
                    }
                  }
                });
                const screenTrack = new Video.LocalVideoTrack(
                  stream.getTracks()[0],
                  { name: `screen:${idScreen}`, logLevel: "info" }
                );
                idScreen += 1;
                currentRoom?.localParticipant.publishTrack(screenTrack);
                ipcRenderer.send(
                  "LOG_INFO",
                  "User successfully shared their screen.",
                  `${
                    sources.filter((src: any) =>
                      src.id.toLowerCase().includes("screen")
                    ).length
                  } screens detected`
                );
              } catch (err) {
                ipcRenderer.send(
                  "LOG_ERROR",
                  "An error occurred while the user tried to share their screen",
                  err
                );
              }
            }
          });
        })
        .catch((err) => {
          ipcRenderer.send(
            "LOG_ERROR",
            "An error occurred while the application tried to access the sources list",
            err
          );
        });
    }
  }

  toggleProctorAudio(shouldEnableProctorAudio: boolean): void {
    const { proctorIdentity } = this.props;
    const { currentRoom } = this.state;
    currentRoom?.participants.forEach((participant) => {
      if (participant.identity === `proctor_${proctorIdentity}`) {
        participant.audioTracks.forEach((audioTrack) => {
          if (shouldEnableProctorAudio) {
            audioTrack.track?.attach(this.audioRef.current);
          } else {
            audioTrack.track?.detach(this.audioRef.current);
          }
        });
      }
    });
  }

  render(): JSX.Element {
    const { wrongSelection, shareScreenModalVisible } = this.state;
    return (
      <View>
        {IS_WEB_ENABLE && (
          <CustomModal
            heightModal={340}
            widthModal={470}
            visible={shareScreenModalVisible}
          >
            <View style={styles.modalContent}>
              <ExtraBoldTitle
                text={
                  wrongSelection
                    ? i18n.t("proctoringLive.screenSharing.title")
                    : i18n.t("proctoringLive.streamEnded.title")
                }
              />
              <LightText
                text={
                  wrongSelection
                    ? i18n.t("proctoringLive.screenSharing.label")
                    : i18n.t("proctoringLive.streamEnded.label")
                }
                textStyle={styles.label}
              />
              <Button
                label={
                  wrongSelection
                    ? i18n.t("proctoringLive.screenSharing.button")
                    : i18n.t("proctoringLive.streamEnded.button")
                }
                containerStyle={[styles.leaveButton]}
                onPress={() => {
                  this.setState({
                    wrongSelection: false,
                    manualStreamEnded: false
                  });

                  this.startScreenSharing();
                }}
              />
            </View>
          </CustomModal>
        )}
        <View>
          <audio
            ref={this.audioRef}
            style={{
              width: 0,
              height: 0
            }}
          />
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  modalContent: {
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 50
  },
  label: {
    paddingHorizontal: 50,
    textAlign: "center"
  },
  leaveButton: {
    width: "50%"
  }
});

export default ProctoringLiveHandler;
