import {
  PARTINDEX_TYPE_EXERCISE,
  PARTINDEX_TYPE_QUESTION
} from "../../../../static/misc/constants";
import { WS_ADD_TIME_EXAM } from "../../../../static/misc/wsevents";
import { ExamIndex, ExamPart, ExamQuestion } from "../../exams/types/exam";
import {
  CLEAR_TIMER,
  CREATE_TIMER,
  SET_CURSOR_EXAM,
  TimerActionsType,
  TimerState,
  TimerType,
  TimerTypeEnum,
  UPDATE_TIMER
} from "../types/timer";

const initialState: TimerState = {
  timers: [],
  cursorExam: undefined
};

export default function reducer(
  state = initialState,
  action: TimerActionsType
): TimerState {
  switch (action.type) {
    case WS_ADD_TIME_EXAM: {
      const { examId, timeAdded } = action.payload;
      const { timers, cursorExam } = state;
      if (
        timers.length > 0 &&
        timers.filter((e) => e.id === examId).length !== 0
      ) {
        const newTimers = timers.map((timer) => {
          if (timer.id === examId) {
            if (cursorExam && cursorExam.examPart) {
              const newTimersPart = timer.timers?.map((timerPart) => {
                if (timerPart.id === cursorExam.examPart) {
                  if (cursorExam.partIndex) {
                    const newPartIndex = timerPart.timers?.map(
                      (timerPartIndex) => {
                        if (timerPartIndex.id === cursorExam.partIndex) {
                          return {
                            ...timerPartIndex,
                            remainingDuration:
                              timerPartIndex.remainingDuration + timeAdded
                          };
                        }
                        if (timerPartIndex.timers) {
                          let hasFound = false;
                          const finalTimers = timerPartIndex.timers.map(
                            (finalTimer) => {
                              if (finalTimer.id === cursorExam.partIndex) {
                                hasFound = true;
                                return {
                                  ...finalTimer,
                                  remainingDuration:
                                    finalTimer.remainingDuration + timeAdded
                                };
                              }
                              return finalTimer;
                            }
                          );
                          if (hasFound) {
                            return {
                              ...timerPartIndex,
                              timers: finalTimers
                            };
                          }
                          return {
                            ...timerPartIndex
                          };
                        }
                        return { ...timerPartIndex };
                      }
                    );
                    return {
                      ...timerPart,
                      timers: newPartIndex
                    };
                  }
                  return {
                    ...timerPart,
                    remainingDuration: timerPart.remainingDuration + timeAdded
                  };
                }
                return timerPart;
              });
              return {
                ...timer,
                remainingDuration: timer.remainingDuration + timeAdded,
                timers: newTimersPart
              };
            }
            return {
              ...timer,
              remainingDuration: timer.remainingDuration + timeAdded
            };
          }
          return timer;
        });
        return { ...state, timers: newTimers };
      }
      return {
        ...state
      };
    }

    case SET_CURSOR_EXAM:
      return {
        ...state,
        cursorExam: action.payload ? { ...action.payload } : undefined
      };

    case CLEAR_TIMER: {
      const { timers } = state;
      const { examId } = action.payload;
      if (
        timers.length > 0 &&
        timers.filter((e) => e.id === examId).length !== 0
      ) {
        const newTimers = timers.filter((obj) => obj.id !== examId);
        return { ...state, timers: newTimers };
      }
      return { ...state };
    }

    case CREATE_TIMER: {
      const { timers } = state;
      const examTaking = action.payload.currentExam;
      // if state timers are empty or examid not found in timer list, it means the exam timer doesnt exist
      // so we need to create a new timer for the exam
      if (
        timers.length >= 0 &&
        timers.filter((e) => e.id === examTaking.id).length <= 0
      ) {
        const examParts = [...(examTaking.examParts as ExamPart[])];

        const partTimers: TimerType[] = examParts.map((examPart: ExamPart) => {
          const partIndexTimers: TimerType[] = examPart.partIndexes.map(
            (partIndex: ExamIndex) => {
              if (
                partIndex.type === PARTINDEX_TYPE_QUESTION &&
                partIndex.question
              ) {
                return {
                  id: partIndex.question.id || "",
                  remainingDuration: partIndex.question.duration || -1,
                  timerType: TimerTypeEnum.QUESTION,
                  timers: undefined
                };
              }
              if (
                partIndex.type === PARTINDEX_TYPE_EXERCISE &&
                partIndex.exercise
              ) {
                const questionsTimers = partIndex.exercise.questions.map(
                  (question: ExamQuestion) => {
                    return {
                      id: question.id || "",
                      remainingDuration: question.duration || -1,
                      timerType: TimerTypeEnum.QUESTION,
                      timers: undefined
                    };
                  }
                );
                return {
                  id: partIndex.exercise.id || "",
                  remainingDuration: partIndex.exercise.duration || -1,
                  timerType: TimerTypeEnum.EXERCISE,
                  timers: questionsTimers
                };
              }
              return {
                id: "-1",
                remainingDuration: -1,
                startedDuration: -1,
                timerType: TimerTypeEnum.PART,
                timers: undefined
              };
            }
          );

          return {
            id: examPart.id || "",
            remainingDuration: examPart.duration || -1,
            timerType: TimerTypeEnum.PART,
            timers: partIndexTimers
          };
        });

        timers?.push({
          id: examTaking.id,
          remainingDuration: examTaking.duration || -1,
          timerType: TimerTypeEnum.EXAM,
          timers: partTimers
        });
        return { ...state, timers };
      }
      return { ...state };
    }

    case UPDATE_TIMER: {
      const { timers } = state;
      const {
        examId,
        remainingDuration,
        timerType,
        itemId,
        currentExamPart
      } = action.payload;
      if (
        timers.length > 0 &&
        timers.filter((e) => e.id === examId).length !== 0
      ) {
        // here we do a map on all the timers so we get a full array at the end with all the timers + updated one
        const updatedTimer: TimerType[] = timers.map((timer) => {
          if (timer.id === examId) {
            if (timerType === TimerTypeEnum.EXAM) {
              return { ...timer, remainingDuration }; // if timer is the one of the exam and timer type is EXAM return it updated
            }
            const examPartTimer: TimerType[] = timer.timers
              ? [...timer.timers]
              : [];

            const partTimers: TimerType[] = examPartTimer.map(
              // going to map on all parts of exam to get the updated array
              (partTimer, pindex) => {
                if (pindex === currentExamPart) {
                  if (timerType === TimerTypeEnum.PART) {
                    return { ...partTimer, remainingDuration }; // if timertype is part and we are at the good index we update part duration
                  }
                  if (timerType !== TimerTypeEnum.PART) {
                    const examPartIndexTimer: TimerType[] = partTimer.timers
                      ? partTimer.timers
                      : [];
                    const partIndexTimers: TimerType[] = examPartIndexTimer.map(
                      // going to map on all partIndex of exam to get the updated array
                      (partIndexTimer) => {
                        if (timerType === TimerTypeEnum.EXERCISE) {
                          if (partIndexTimer.id === itemId) {
                            return { ...partIndexTimer, remainingDuration };
                          }
                          return { ...partIndexTimer };
                        }
                        // timerType === Question
                        if (partIndexTimer.id === itemId) {
                          return { ...partIndexTimer, remainingDuration };
                        }
                        if (
                          partIndexTimer.timerType === TimerTypeEnum.EXERCISE
                        ) {
                          const exTimer: TimerType[] = partIndexTimer.timers
                            ? partIndexTimer.timers
                            : [];
                          const exTimers: TimerType[] = exTimer.map(
                            (question) => {
                              if (question.id === itemId) {
                                return { ...question, remainingDuration };
                              }
                              return { ...question };
                            }
                          );
                          return { ...partIndexTimer, timers: exTimers };
                        }
                        return { ...partIndexTimer };
                      }
                    );
                    return { ...partTimer, timers: partIndexTimers };
                  }
                  return { ...partTimer };
                }
                return { ...partTimer };
              }
            );
            return { ...timer, timers: partTimers }; // return exam timer with subUpdate if type = PART || Exercise || Question
          }
          return timer; // if timer isnt the one of the exam return it
        });

        return { ...state, timers: updatedTimer }; // switch return with updated timer
      }
      return { ...state }; // switch return if no timer match the exam ID
    }
    default:
      return { ...state };
  }
}
