/* eslint-disable react/require-default-props */
import { shell } from "electron";
import React, { useMemo } from "react";
import {
  GestureResponderEvent,
  StyleProp,
  View,
  TextStyle,
  useWindowDimensions
} from "react-native";
import HTML, {
  CustomTagRendererRecord,
  defaultHTMLElementModels,
  HTMLContentModel
} from "react-native-render-html";

interface RendererProps {
  TDefaultRenderer: any;
  props?: any;
}

const onLinkPress = (
  e: GestureResponderEvent,
  href: string,
  isLinkUsable: boolean
) => {
  e.preventDefault();
  if (isLinkUsable && href) {
    shell.openExternal(href);
  }
};

const processHtmlLayout = ({ TDefaultRenderer, ...props }: RendererProps) => {
  const { style } = props as any;
  const { domNode, children } = (props as any).tnode;

  if (style) {
    const elementStyle = style;
    elementStyle.overflow = "auto";
  }

  if (domNode.attribs?.style) {
    // we look for a line-height css attribute
    const lineHeightStyle = domNode.attribs.style.match(
      /line-height\s*:\s*([^;]*)/
    );

    if (lineHeightStyle?.length > 0) {
      const lineHeightAttrib: number = parseInt(lineHeightStyle[1], 10);

      // if we are rendering a Text node and the line height was given without units, we will apply it to the text element
      if (!Number.isNaN(lineHeightAttrib)) {
        for (let index = 0; index < children.length; index++) {
          const firstChild = children[index];
          if (firstChild.type === "phrasing") {
            const fontSize = firstChild.styles?.nativeTextFlow?.fontSize;
            if (fontSize) {
              for (
                let index2 = 0;
                index2 < firstChild.children.length;
                index2++
              ) {
                firstChild.children[index2].styles.nativeTextFlow.lineHeight =
                  fontSize * lineHeightAttrib;

                firstChild.children[index2].styles.nativeTextFlow.overflow =
                  "hidden";
              }
            }
          }
        }
      }
    }
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <TDefaultRenderer {...props} />;
};

// Personnalized renderers for table tags so table can be generated in our webview
const linkableRenderers = (
  isLinkUsable: boolean
): CustomTagRendererRecord | undefined => {
  return {
    td: ({ TDefaultRenderer, ...props }: RendererProps) => {
      const elementProps = props as any;
      if (elementProps?.style) {
        const { style } = elementProps;
        style.border = "1px solid black";
      }
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <TDefaultRenderer {...props} />;
    },
    a: ({ TDefaultRenderer, ...props }: RendererProps) => {
      return (
        <TDefaultRenderer
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          onPress={(e: GestureResponderEvent, href: string) =>
            onLinkPress(e, href, isLinkUsable)
          }
        />
      );
    },
    p: processHtmlLayout,
    div: processHtmlLayout
  };
};

const customHTMLElementModels = {
  img: defaultHTMLElementModels.img.extend({
    contentModel: HTMLContentModel.mixed, // enable inline images
    mixedUAStyles: {
      justifyContent: "flex-start"
    }
  })
};

const computeEmbeddedMaxWidth = (contentWidth: number, tagName: string) => {
  if (tagName === "img") return Infinity;
  return contentWidth;
};

interface HtmlRendererProps {
  isLinkUsable: boolean;
  content: string;
  containerStyle?: StyleProp<TextStyle>;
  width?: number | string;
}

const HtmlRenderer = React.memo(
  (props: HtmlRendererProps): JSX.Element => {
    const { isLinkUsable, content, containerStyle, width } = props;
    const { width: windowWidth } = useWindowDimensions();
    const renderers = useMemo(() => linkableRenderers(isLinkUsable), [
      isLinkUsable
    ]);

    return (
      <View style={[containerStyle, { width }]}>
        <HTML
          contentWidth={
            width && typeof width === "number" ? width : windowWidth
          }
          renderers={renderers}
          source={{ html: content }}
          customHTMLElementModels={customHTMLElementModels} // enable inline images
          computeEmbeddedMaxWidth={computeEmbeddedMaxWidth} // used to keep images size on display
        />
      </View>
    );
  }
);

export default HtmlRenderer;
