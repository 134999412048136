import React from "react";
import { View } from "react-native";
import ReactPlayer from "react-player";
import Webcam from "react-webcam";
import {
  HEIGHT_DEFAULT_ONBOARDING,
  VIDEO_CONSTRAINTS,
  WIDTH_DEFAULT_ONBOARDING
} from "../../../static/misc/constants";
import { ExamOnboardingStep } from "../../modules/exams/types/exam";
import { GetErrorAction } from "../../modules/main/types/error";
import i18n from "../../services/i18n";

export interface VideoTakingProps {
  currentVideo: string | Blob | null | undefined;
  currentOnboardingStep?: ExamOnboardingStep;
  webcamRef: React.RefObject<Webcam>;
  getError?: (message: string, forceLogout: boolean) => GetErrorAction;
  toggleLoader?: (load: boolean) => void;
}

export class VideoTaking extends React.PureComponent<VideoTakingProps> {
  render(): JSX.Element {
    const {
      currentVideo,
      currentOnboardingStep,
      webcamRef,
      getError,
      toggleLoader
    } = this.props;

    const videoConstraints = {
      width: currentOnboardingStep?.width || WIDTH_DEFAULT_ONBOARDING,
      height: currentOnboardingStep?.height || HEIGHT_DEFAULT_ONBOARDING,
      facingMode: "user"
    };

    return (
      <View>
        {currentVideo ? (
          <ReactPlayer
            url={window.URL.createObjectURL(currentVideo)}
            width={VIDEO_CONSTRAINTS.width}
            height={VIDEO_CONSTRAINTS.height}
            controls
            config={{
              file: { attributes: { controlsList: "nodownload" } }
            }}
          />
        ) : (
          <View>
            <View style={{ position: "relative" }}>
              <Webcam
                ref={webcamRef}
                audio={false}
                onUserMedia={() => {
                  if (toggleLoader) toggleLoader(false);
                }}
                onUserMediaError={(e) => {
                  if (getError) getError(i18n.t("errors.webcam", [e]), false);
                }}
                width={VIDEO_CONSTRAINTS.width}
                height={VIDEO_CONSTRAINTS.height}
                videoConstraints={videoConstraints}
              />
            </View>
          </View>
        )}
      </View>
    );
  }
}
