import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { COLOR_BLUE_TESTWE } from "../../../static/misc/colors";
import {
  FONTSIZE_14,
  FONT_GILROY_REGULAR,
  PADDING_SIDES
} from "../../../static/misc/constants";
import i18n from "../../services/i18n";
import ExamInfoAgreement from "../molecules/ExamInfoAgreement";

interface OnBoardingGeneralConditionProps {
  updateCurrentStepInfo: (
    data?: string | Blob | null,
    status?: boolean
  ) => void;
}

interface OnBoardingGeneralConditionState {
  hasAgreed: boolean;
}

export default class OnBoardingGeneralCondition extends React.PureComponent<
  OnBoardingGeneralConditionProps,
  OnBoardingGeneralConditionState
> {
  constructor(props: OnBoardingGeneralConditionProps) {
    super(props);
    this.state = {
      hasAgreed: false
    };
  }

  render(): JSX.Element {
    const { updateCurrentStepInfo } = this.props;
    const { hasAgreed } = this.state;
    return (
      <>
        <View style={styles.instructionContainer}>
          <Text style={styles.instructionText}>
            {i18n.t("exam.generalInstructions.title")}
          </Text>
        </View>
        <View style={styles.agreementAndStart}>
          <ExamInfoAgreement
            hasAgreed={hasAgreed}
            link={i18n.t("exam.generalInstructions.agreementText2")}
            text={i18n.t("exam.generalInstructions.agreementText1")}
            onPress={() => {
              this.setState({
                hasAgreed: !hasAgreed
              });
              updateCurrentStepInfo("", !hasAgreed);
            }}
          />
        </View>
      </>
    );
  }
}

const styles = StyleSheet.create({
  instructionContainer: {
    width: "100%",
    flexShrink: 1,
    flexGrow: 1
  },
  instructionText: {
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_14,
    color: COLOR_BLUE_TESTWE,
    marginBottom: PADDING_SIDES * 0.2,
    lineHeight: 20
  },
  agreementAndStart: {
    width: "100%",
    flexShrink: 1,
    flexGrow: 1
  }
});
