import { CombinedState, combineReducers, Reducer } from "redux";
import AsyncStorage from "@react-native-community/async-storage";
import { persistReducer } from "redux-persist";
import createElectronStorage from "redux-persist-electron-storage";
import { PersistPartial } from "redux-persist/lib/persistReducer";
import { PersistedStatusState } from "../modules/main/types/persistedStatus";
import auth from "../modules/authentification/reducers/auth";
import status from "../modules/main/reducers/status";
import error from "../modules/main/reducers/error";
import success from "../modules/main/reducers/success";
import user from "../modules/main/reducers/user";
import equipmentCheck from "../modules/main/reducers/equipmentCheck";
import connectionTesting from "../modules/testing/reducers/connectionTesting";
import exams from "../modules/exams/reducers/exams";
import publickeys from "../modules/exams/reducers/publickeys";
import attachedfiles from "../modules/exams/reducers/attachedfiles";
import proctoringlive from "../modules/proctoringLive/reducers/proctoringlive";

import studentPaper from "../modules/examTaking/reducers/studentPaper";
import examTaking from "../modules/examTaking/reducers/examTaking";
import {
  CLEAR_CREDENTIALS,
  IS_PREVIEW,
  IS_WEB_ENABLE
} from "../../static/misc/constants";
import timer from "../modules/examTaking/reducers/timer";
import { AuthState } from "../modules/authentification/types/auth";
import { StatusState } from "../modules/main/types/status";
import { PublicKeysState } from "../modules/exams/types/publickeys";
import { AttachedFilesState } from "../modules/exams/types/attachedfiles";
import { ErrorState } from "../modules/main/types/error";
import { ExamsState } from "../modules/exams/types/exams";
import { StudentPaperState } from "../modules/examTaking/types/studentPaper";
import { ExamTakingState } from "../modules/examTaking/types/examTaking";
import { UserState } from "../modules/main/types/user";
import { SuccessState } from "../modules/main/types/success";
import { ProctoringLiveState } from "../modules/proctoringLive/types/proctoringlive";
import { ConnectionTestingState } from "../modules/testing/types/connectionTesting";
import { TimerState } from "../modules/examTaking/types/timer";
import { EquipmentCheck } from "../modules/main/types/equipmentCheck";
import persistedStatus from "../modules/main/reducers/persistedStatus";

export interface ClearCredentialsActions {
  type: typeof CLEAR_CREDENTIALS;
  payload: any;
}

export type RootState = CombinedState<{
  auth: AuthState;
  status: StatusState;
  publickeys: PublicKeysState;
  attachedfiles: AttachedFilesState & PersistPartial;
  error: ErrorState;
  success: SuccessState;
  proctoringlive: ProctoringLiveState;
  user: UserState;
  equipmentCheck: EquipmentCheck;
  connectionTesting: ConnectionTestingState;
  exams: ExamsState & PersistPartial;
  studentPaper: StudentPaperState & PersistPartial;
  examTaking: ExamTakingState & PersistPartial;
  timer: TimerState & PersistPartial;
  persistedStatus: PersistedStatusState & PersistPartial;
}>;

export default function createRootReducer(
  deviceName: string
): Reducer<RootState> {
  let examsPersistConfig;
  let attachedFilesPersistConfig;
  let examTakingPersistConfig;
  let studentPaperPersistConfig;
  let timerPersistConfig;
  let persistedStatusConfig;

  if (IS_PREVIEW) {
    examsPersistConfig = {
      key: "exams",
      storage: AsyncStorage,
      whitelist: [] // No reducer persisted
    };
    attachedFilesPersistConfig = {
      key: "attachedfiles",
      storage: AsyncStorage,
      whitelist: [] // No reducer persisted
    };
    examTakingPersistConfig = {
      key: "examtaking",
      storage: AsyncStorage,
      whitelist: [] // No reducer persisted
    };
    timerPersistConfig = {
      key: "timer",
      storage: AsyncStorage,
      whitelist: [] // No reducer persisted
    };
    studentPaperPersistConfig = {
      key: "studentpaper",
      storage: AsyncStorage,
      whitelist: [] // No reducer persisted
    };
    persistedStatusConfig = {
      key: "persistedStatus",
      storage: AsyncStorage,
      blacklist: []
    };
  }
  if (IS_WEB_ENABLE) {
    examsPersistConfig = {
      key: "exams",
      storage: AsyncStorage,
      whitelist: [] // No reducer persisted
    };
    attachedFilesPersistConfig = {
      key: "attachedFiles",
      storage: AsyncStorage,
      whitelist: []
    };
    examTakingPersistConfig = {
      key: "examTaking",
      storage: createElectronStorage({
        electronStoreOpts: {
          name: "ExTStEl",
          // eslint-disable-next-line no-control-regex
          encryptionKey: deviceName.replace(/[^\x00-\x7F]/g, "")
        }
      }), // Save store in a file in the appPath called : ExTStEl.json (Exam Taking Storage Electron)
      blacklist: []
    };
    timerPersistConfig = {
      key: "timer",
      storage: AsyncStorage,
      whitelist: [] // No reducer persisted
    };
    studentPaperPersistConfig = {
      key: "studentpaper",
      storage: createElectronStorage({
        electronStoreOpts: {
          name: "ExTStEl",
          // eslint-disable-next-line no-control-regex
          encryptionKey: deviceName.replace(/[^\x00-\x7F]/g, "")
        }
      }), // Save store in a file in the appPath called : ExTStEl.json (Exam Taking Storage Electron)
      blacklist: []
    };
    persistedStatusConfig = {
      key: "persistedStatus",
      storage: createElectronStorage({
        electronStoreOpts: {
          name: "PSStEl",
          // eslint-disable-next-line no-control-regex
          encryptionKey: deviceName.replace(/[^\x00-\x7F]/g, "")
        }
      }), // Save store in a file in the appPath called : PSStEl.json (Persisted Status Storage Electron)
      blacklist: []
    };
  } else {
    examsPersistConfig = {
      key: "exams",
      storage: createElectronStorage({
        electronStoreOpts: {
          name: "ExStEl",
          // eslint-disable-next-line no-control-regex
          encryptionKey: deviceName.replace(/[^\x00-\x7F]/g, "")
        }
      }), // Save store in a file in the appPath called : ExTStEl.json (Exams Storage Electron)
      blacklist: []
    };
    attachedFilesPersistConfig = {
      key: "attachedFiles",
      storage: createElectronStorage({
        electronStoreOpts: {
          name: "AfEl",
          // eslint-disable-next-line no-control-regex
          encryptionKey: deviceName.replace(/[^\x00-\x7F]/g, "")
        }
      }), // Save store in a file in the appPath called : ExTStEl.json (Exam Taking Storage Electron)
      blacklist: []
    };
    examTakingPersistConfig = {
      key: "examTaking",
      storage: createElectronStorage({
        electronStoreOpts: {
          name: "ExTStEl",
          // eslint-disable-next-line no-control-regex
          encryptionKey: deviceName.replace(/[^\x00-\x7F]/g, "")
        }
      }), // Save store in a file in the appPath called : ExTStEl.json (Exam Taking Storage Electron)
      blacklist: []
    };
    timerPersistConfig = {
      key: "timer",
      storage: createElectronStorage({
        electronStoreOpts: {
          name: "TStEl",
          // eslint-disable-next-line no-control-regex
          encryptionKey: deviceName.replace(/[^\x00-\x7F]/g, "")
        }
      }), // Save store in a file in the appPath called : TStEl.json (Timer Storage Electron)
      blacklist: []
    };
    studentPaperPersistConfig = {
      key: "studentpaper",
      storage: createElectronStorage({
        electronStoreOpts: {
          name: "SPStEl",
          // eslint-disable-next-line no-control-regex
          encryptionKey: deviceName.replace(/[^\x00-\x7F]/g, "")
        }
      }), // Save store in a file in the appPath called : SPStEl.json (Student Paper Storage Electron)
      blacklist: []
    };
    persistedStatusConfig = {
      key: "persistedStatus",
      storage: createElectronStorage({
        electronStoreOpts: {
          name: "PSStEl"
        }
      }), // Save store in a file in the appPath called : PSStEl.json (Persisted Status Storage Electron)
      blacklist: []
    };
  }
  // Every reducers which will be combined to make a root reducer
  const rootReducer = combineReducers({
    auth,
    status,
    publickeys,
    proctoringlive,
    success,
    attachedfiles: persistReducer(attachedFilesPersistConfig, attachedfiles),
    error,
    connectionTesting,
    exams: persistReducer(examsPersistConfig, exams),
    studentPaper: persistReducer(studentPaperPersistConfig, studentPaper),
    examTaking: persistReducer(examTakingPersistConfig, examTaking),
    equipmentCheck,
    timer: persistReducer(timerPersistConfig, timer),
    user,
    persistedStatus: persistReducer(persistedStatusConfig, persistedStatus)
  });

  return rootReducer;
}
