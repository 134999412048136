import React from "react";
import { StyleSheet, View } from "react-native";
import {
  COLOR_BLUE_TESTWE,
  COLOR_GREY_BACKGROUND
} from "../../../static/misc/colors";
import {
  CORRECTED,
  CORRECTION_COMPLETED,
  CORRECTION_NOT_SHARED,
  CORRECTION_SHARED,
  NOT_REALIZED,
  SENT
} from "../../../static/misc/constants";
import BlurredItem from "../molecules/BlurredItem";
import { cannotViewIcon } from "../../../static/misc/images";
import WaitIcon from "../atoms/WaitIcon";
import DetailExam from "../molecules/DetailExam";
import ButtonsPassedExam from "../molecules/ButtonsPassedExam";
import { GetMyPaperBase64Action } from "../../modules/exams/types/exams";

export interface PassedExamsItemProps {
  token: string;
  blobUrl?: string;
  base64pdf?: string;
  studentPaperId: string;
  subject?: string;
  name: string;
  date?: Date;
  examId: string;
  isOnline: boolean;
  maxNote?: number | undefined;
  hasRemark: string | undefined;
  mark: number | undefined;
  status: string;
  correctionStatus?: string;
  getMyPaperBase64: (
    studentPaperId: string,
    userToken: string,
    examId: string
  ) => GetMyPaperBase64Action;
}

class PassedExamsItem extends React.PureComponent<PassedExamsItemProps> {
  render(): JSX.Element {
    const {
      token,
      examId,
      studentPaperId,
      base64pdf,
      mark,
      blobUrl,
      maxNote,
      isOnline,
      status,
      date,
      name,
      subject,
      hasRemark,
      correctionStatus,
      getMyPaperBase64
    } = this.props;

    // if status not corrected or correction_completed, then we can share the status
    // if set to corrected or correction_completed, then we need to check the correctionSharingStatus
    // if correctionSharingStatus is not to CORRECTION_NOT_SHARED and mark is not undefined, then we can show the real status
    // otherwise, we set it to SENT by default
    const displayedStatus =
      status !== CORRECTED && status !== CORRECTION_COMPLETED
        ? status
        : correctionStatus &&
          correctionStatus !== CORRECTION_NOT_SHARED &&
          mark !== undefined
        ? status
        : SENT;

    return (
      <View key={`exam-detail-${examId}-container`} style={styles.container}>
        <View key={`exam-detail-${examId}-row`} style={styles.row}>
          <View style={styles.content}>
            {correctionStatus &&
            correctionStatus !== CORRECTION_NOT_SHARED &&
            mark !== undefined ? (
              isOnline ? (
                <BlurredItem
                  icon={{
                    width: 25,
                    height: 22,
                    source: cannotViewIcon
                  }}
                  itemBlurredNext={` /${maxNote?.toString()}`}
                  itemBlurred={mark.toString()}
                />
              ) : (
                <View style={styles.emptyItem} />
              )
            ) : (
              <WaitIcon />
            )}
          </View>
          <DetailExam
            status={displayedStatus}
            date={date || new Date()}
            name={name}
            subject={subject || ""}
          />
          <ButtonsPassedExam
            key={`exam-detail-${studentPaperId}-button`}
            hasRemark={isOnline ? hasRemark : undefined}
            isUsable={
              correctionStatus === CORRECTION_SHARED && status !== NOT_REALIZED
            }
            examId={studentPaperId}
            blobUrl={blobUrl}
            base64pdf={base64pdf}
            getMyPaperBase64={() =>
              getMyPaperBase64(studentPaperId, token, examId)
            }
          />
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  emptyItem: {
    marginRight: 50,
    width: 30,
    height: 22
  },
  container: {
    borderRadius: 10,
    zIndex: -10,
    height: 100,
    marginVertical: 5,
    borderStyle: "dotted",
    borderWidth: 1,
    borderColor: COLOR_BLUE_TESTWE,
    backgroundColor: COLOR_GREY_BACKGROUND,
    paddingHorizontal: "4%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  row: {
    width: "100%",
    zIndex: -10,
    height: "100%",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  content: {
    width: 150,
    zIndex: -10,
    justifyContent: "center",
    alignItems: "center"
  }
});

export default PassedExamsItem;
