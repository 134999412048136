export enum CalculatorTypeEnum {
  BASIC,
  SCIENTIFIC
}

export enum CalculatorButtonTypeEnum {
  AC,
  C,
  NUMBER,
  DOT,
  SIGNCHANGE,
  OPERATOR,
  OPENINGBRACKET,
  CLOSINGBRACKET,
  EQUAL,
  FUNCTION,
  E,
  PI,
  SECOND,
  MC,
  MR,
  MMINUS,
  MPLUS,
  MS,
  OTHER
}

export interface CalculatorButtonType {
  label: string;
  width: string;
  backgroundColor: string;
  color: string;
  value?: string;
  buttonType?: CalculatorButtonTypeEnum;
  function?: string;
  waitForUserInput?: boolean;
}
