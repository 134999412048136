import React from "react";
import { View } from "react-native";
import _ from "lodash";
import Webcam from "react-webcam";
import { ipcRenderer } from "electron";
import { ReactMicGold, ReactMicStopEvent } from "../../../libs/react-mic-gold";
import {
  CACHED_PROCTORNB_KEY,
  HEIGHT_DEFAULT_ONBOARDING,
  HEIGHT_PROCTOR_PICTURE,
  IS_PREVIEW,
  IS_WEB_ENABLE,
  MIME_JPEG,
  MIME_MPEG,
  PROCTORING_AUDIO_TIMER,
  PROCTORING_PHOTO_TIMER,
  WIDTH_DEFAULT_ONBOARDING,
  WIDTH_PROCTOR_PICTURE
} from "../../../static/misc/constants";
import moment from "../../services/moment";
import {
  getAsyncStorageItem,
  setAsyncStorageItem
} from "../../../static/misc/utils";
import {
  prepareProctorData,
  writeProctorFile
} from "../../../static/fileutils";
import {
  FILE_TYPE_PROCTORING_AUDIO,
  FILE_TYPE_PROCTORING_WEBCAM,
  UploadMediaObjectAction
} from "../../modules/exams/types/attachedfiles";

interface ProctorHandlerProps {
  examId: string;
  authorizationToken: string | undefined;
  studentId: string;
  audio: boolean;
  stopRecordingAudio: boolean;
  appDataPath: string;
  isOnline: boolean;
  initUploadMediaObjectOnAWS: (
    userToken: string,
    studentId: string,
    examId: string,
    filename: string,
    filetype: string,
    filepath: string,
    filedata: Buffer | null
  ) => UploadMediaObjectAction;
}

interface ProctorHandlerState {
  shouldRecord: boolean;
  toggledDevice: boolean;
  unmountComponent: boolean;
}

class ProctorHandler extends React.Component<
  ProctorHandlerProps,
  ProctorHandlerState
> {
  webcamRef: Webcam | null = null;

  screenshotInterval!: ReturnType<typeof setInterval>;

  audioInterval!: ReturnType<typeof setInterval>;

  constructor(props: ProctorHandlerProps) {
    super(props);

    this.state = {
      shouldRecord: true,
      toggledDevice: true,
      unmountComponent: false
    };

    this.onRecordingDataAvailable = this.onRecordingDataAvailable.bind(this);
  }

  componentDidMount(): void {
    // Setting the interval to take webcam screenshot every 5 seconds
    this.screenshotInterval = setInterval(async () => {
      this.takeProctoringScreenshot();
    }, PROCTORING_PHOTO_TIMER);

    this.setAudioInterval();
  }

  shouldComponentUpdate(
    nextProps: ProctorHandlerProps,
    nextState: ProctorHandlerState
  ): boolean {
    const { stopRecordingAudio } = this.props;
    const { toggledDevice } = this.state;
    return (
      nextProps.stopRecordingAudio !== stopRecordingAudio ||
      nextState.toggledDevice !== toggledDevice
    );
  }

  componentDidUpdate(prevProps: ProctorHandlerProps): void {
    const { stopRecordingAudio } = this.props;

    if (prevProps.stopRecordingAudio !== stopRecordingAudio) {
      // If audio recording must stop, need to clear the interval, otherwise need to recreate it
      if (stopRecordingAudio) {
        clearInterval(this.audioInterval);
      } else {
        this.setAudioInterval();
      }
      this.setState(
        {
          shouldRecord: !stopRecordingAudio
        },
        () => {
          this.setState({
            unmountComponent: stopRecordingAudio
          });
        }
      );
    }
  }

  componentWillUnmount(): void {
    this.setState({ shouldRecord: false });
    clearInterval(this.screenshotInterval);
    clearInterval(this.audioInterval);
  }

  onRecordingDataAvailable(e: Blob): void {
    const {
      authorizationToken,
      studentId,
      examId,
      appDataPath,
      isOnline,
      initUploadMediaObjectOnAWS
    } = this.props;
    const blob = e;

    ipcRenderer.send(
      "LOG_INFO",
      "Audio recording data is available",
      blob.size
    );

    if (blob && blob.size > 0 && authorizationToken) {
      // need to convert video blob to base64
      const reader = new FileReader();
      reader.readAsDataURL(blob as Blob);
      reader.onloadend = () => {
        const data = reader.result?.toString();

        if (data) {
          const filename = `${studentId}_${examId}_audio_${moment().format(
            "x"
          )}`;
          if (IS_WEB_ENABLE) {
            const response = prepareProctorData(
              examId,
              filename,
              data,
              MIME_MPEG,
              appDataPath
            );
            initUploadMediaObjectOnAWS(
              authorizationToken,
              studentId,
              examId,
              response.filename,
              FILE_TYPE_PROCTORING_AUDIO,
              response.filepath,
              response.filedata
            );
          } else {
            writeProctorFile(examId, filename, data, MIME_MPEG, appDataPath)
              .then((response) => {
                if (response && isOnline) {
                  initUploadMediaObjectOnAWS(
                    authorizationToken,
                    studentId,
                    examId,
                    response.filename,
                    FILE_TYPE_PROCTORING_AUDIO,
                    response.filepath,
                    null
                  );
                } else {
                  ipcRenderer.send(
                    "LOG_ERROR",
                    `Could not start uploading process for file ${filename} because the response object was empty.`
                  );
                }
              })
              .catch((err) => {
                ipcRenderer.send(
                  "LOG_ERROR",
                  `An exception occurred while trying to write ${filename} on filesystem.`,
                  JSON.stringify(err)
                );
              });
          }
        } else {
          ipcRenderer.send("LOG_INFO", "No data though");
        }
      };
    }
  }

  setAudioInterval(): void {
    if (!IS_WEB_ENABLE && !IS_PREVIEW) {
      ipcRenderer.send("GET_OPTION", { key: "proctoringAudioTimer" });

      ipcRenderer.on("GET_OPTION_RESPOND", (event, arg) => {
        let time: number | null = null;
        try {
          time = Number(arg);
        } catch (e) {
          ipcRenderer.send(
            "LOG_INFO",
            `GET_OPTION proctoringAudioTimer -- could not parse ${arg}`
          );
        }
        if (!time) time = PROCTORING_AUDIO_TIMER;
        this.audioInterval = setInterval(() => {
          const { toggledDevice } = this.state;
          this.setState({
            toggledDevice: !toggledDevice
          });
        }, time);
      });
    } else {
      this.audioInterval = setInterval(() => {
        const { toggledDevice } = this.state;
        this.setState({
          toggledDevice: !toggledDevice
        });
      }, PROCTORING_AUDIO_TIMER);
    }
  }

  async takeProctoringScreenshot(): Promise<void> {
    const {
      examId,
      studentId,
      authorizationToken,
      appDataPath,
      isOnline,
      initUploadMediaObjectOnAWS
    } = this.props;
    const base64Image = this.webcamRef?.getScreenshot();

    let nbProctor = await getAsyncStorageItem(CACHED_PROCTORNB_KEY);

    if (_.isNil(nbProctor)) {
      nbProctor = "3";
    } else {
      nbProctor = (Number(nbProctor) + 1).toString();
    }

    await setAsyncStorageItem(CACHED_PROCTORNB_KEY, nbProctor);

    if (base64Image && authorizationToken) {
      const filename = `${studentId}_${examId}_${moment().format(
        "YYYYMMDDHHmmssSSS"
      )}_${nbProctor}`;
      if (IS_WEB_ENABLE) {
        const response = prepareProctorData(
          examId,
          filename,
          base64Image,
          MIME_JPEG,
          appDataPath
        );
        initUploadMediaObjectOnAWS(
          authorizationToken,
          studentId,
          examId,
          response.filename,
          FILE_TYPE_PROCTORING_WEBCAM,
          response.filepath,
          response.filedata
        );
      } else {
        writeProctorFile(examId, filename, base64Image, MIME_JPEG, appDataPath)
          .then((response) => {
            if (response && isOnline) {
              initUploadMediaObjectOnAWS(
                authorizationToken,
                studentId,
                examId,
                response.filename,
                FILE_TYPE_PROCTORING_WEBCAM,
                response.filepath,
                null
              );
            } else {
              ipcRenderer.send(
                "LOG_ERROR",
                `Could not start uploading process for file ${filename} because the response object was empty.`
              );
            }
          })
          .catch((err) => {
            ipcRenderer.send(
              "LOG_ERROR",
              `An exception occurred while trying to write ${filename} on filesystem.`,
              JSON.stringify(err)
            );
          });
      }
    }
  }

  render(): JSX.Element | null {
    const { shouldRecord, toggledDevice, unmountComponent } = this.state;
    const { audio } = this.props;
    const videoConstraints = {
      width: WIDTH_DEFAULT_ONBOARDING,
      height: HEIGHT_DEFAULT_ONBOARDING,
      facingMode: "user"
    };

    ipcRenderer.send("LOG_INFO", "Toggled device", toggledDevice);

    return (
      <View style={{ width: 0, height: 0 }}>
        <Webcam
          ref={(webcamRef) => {
            this.webcamRef = webcamRef;
          }}
          screenshotFormat={MIME_JPEG}
          minScreenshotWidth={WIDTH_PROCTOR_PICTURE}
          minScreenshotHeight={HEIGHT_PROCTOR_PICTURE}
          screenshotQuality={0.8}
          videoConstraints={videoConstraints}
          audio={false}
        />
        {audio === true && toggledDevice === true && !unmountComponent && (
          <ReactMicGold
            record={shouldRecord}
            visualSetting={undefined}
            onStop={(rec: ReactMicStopEvent) => {
              this.onRecordingDataAvailable(rec.blob);
            }}
            sampleRate={48000}
          />
        )}
        {audio === true && toggledDevice === false && !unmountComponent && (
          <ReactMicGold
            record={shouldRecord}
            visualSetting={undefined}
            onStop={(rec: ReactMicStopEvent) => {
              this.onRecordingDataAvailable(rec.blob);
            }}
            sampleRate={48000}
          />
        )}
      </View>
    );
  }
}

export default ProctorHandler;
