import _ from "lodash";
import {
  MediaPermissionsError,
  MediaPermissionsErrorType,
  requestAudioPermissions,
  requestVideoPermissions
} from "mic-check/lib/requestMediaPermissions";
import { permissions } from "../../static/misc/images";
import i18n from "./i18n";

export interface PermissionError {
  message: string;
  image?: any;
}

export interface RequestMediaPermissionsResult {
  audioError: MediaPermissionsError | null;
  videoError: MediaPermissionsError | null;
  errors: PermissionError[];
}

let checkingPermissions = false;

export const checkMediaPermissions = async (
  audioError: MediaPermissionsError | null,
  videoError: MediaPermissionsError | null,
  errors: PermissionError[],
  checkMicrophone = true,
  checkCamera = true
): Promise<RequestMediaPermissionsResult | null> => {
  if (!checkingPermissions) {
    checkingPermissions = true;

    let newAudioError: MediaPermissionsError | null = null;
    let newVideoError: MediaPermissionsError | null = null;

    let newErrors: PermissionError[] = [...errors];

    if (checkMicrophone) {
      try {
        await requestAudioPermissions();
      } catch (err: any) {
        newAudioError = err;
        if (!_.isEqual(newAudioError, audioError)) {
          newErrors = checkErrorType(newErrors, err);
        }
      }
    }

    if (checkCamera) {
      try {
        await requestVideoPermissions();
      } catch (err: any) {
        newVideoError = err;
        if (!_.isEqual(newVideoError, videoError)) {
          newErrors = checkErrorType(newErrors, err);
        }
      }
    }

    checkingPermissions = false;

    return {
      audioError: newAudioError,
      videoError: newVideoError,
      errors: newErrors
    };
  }

  return Promise.resolve(null);
};

const checkErrorType = (
  currentErrors: PermissionError[],
  err: any
): PermissionError[] => {
  const mediaPermissionsSystemDenied = i18n.t(
    "errors.mediaPermissionsSystemDenied"
  );
  const mediaPermissionsUserDenied = i18n.t(
    "errors.mediaPermissionsUserDenied"
  );
  const mediaPermissionsStartFailure = i18n.t(
    "errors.mediaPermissionsStartFailure"
  );
  const mediaPermissionsGenericError = i18n.t(
    "errors.mediaPermissionsGenericError"
  );

  const newErrors: PermissionError[] = [];
  if (err.type) {
    switch (err.type) {
      case MediaPermissionsErrorType.SystemPermissionDenied:
        if (
          currentErrors.findIndex(
            (error: PermissionError) =>
              error.message === mediaPermissionsSystemDenied
          ) === -1
        ) {
          newErrors.push({
            message: mediaPermissionsSystemDenied
          });
        }
        break;
      case MediaPermissionsErrorType.UserPermissionDenied:
        if (
          currentErrors.findIndex(
            (error: PermissionError) =>
              error.message === mediaPermissionsUserDenied
          ) === -1
        ) {
          newErrors.push({
            message: mediaPermissionsUserDenied,
            image: permissions
          });
        }
        break;
      case MediaPermissionsErrorType.CouldNotStartVideoSource:
        if (
          currentErrors.findIndex(
            (error: PermissionError) =>
              error.message === mediaPermissionsStartFailure
          ) === -1
        ) {
          newErrors.push({
            message: mediaPermissionsStartFailure
          });
        }
        break;
      default:
        if (
          currentErrors.findIndex(
            (error: PermissionError) =>
              error.message === mediaPermissionsGenericError
          ) === -1
        ) {
          newErrors.push({
            message: mediaPermissionsGenericError
          });
        }
        break;
    }
  }

  return newErrors;
};
