/* eslint-disable react/no-unused-state */
import React from "react";
import { View, StyleSheet, Text } from "react-native";
import moment from "moment";
import {
  // AVAILABLE,
  FONTSIZE_12,
  FONTSIZE_14,
  FONT_GILROY_BOLD,
  FONT_GILROY_REGULAR,
  PADDING_SIDES
  // UNAVAILABLE
} from "../../../static/misc/constants";
import { guidGenerator } from "../../../static/misc/utils";
import ListColumnItem from "../atoms/ListColumnItem";
import ButtonNextExamList from "./ButtonNextExamList";
import { DisplayedExamsListProps } from "./ExamsList";
import {
  COLOR_BLUE,
  COLOR_BLUE_TESTWE,
  COLOR_GREY_BACKGROUND
} from "../../../static/misc/colors";
import InfoIconWithRules from "../molecules/InfoIconWithRules";
import i18n from "../../services/i18n";
import { playIcon } from "../../../static/misc/images.electron";

class NextExamsList extends React.PureComponent<DisplayedExamsListProps> {
  render(): JSX.Element {
    const {
      exams,
      columnsTitlesAndWidth,
      rowStyle,
      borderStyle,
      attachedFiles,
      displaySingleExamInfo
    } = this.props;

    let initialZIndex = 1;
    return (
      <View style={styles.view}>
        {exams &&
          exams.length > 0 &&
          exams.map((exam, index) => {
            const hour = Math.floor(exam.duration / 3600);
            const minutes = Math.floor((exam.duration % 3600) / 60);

            initialZIndex += 1;

            return (
              <View
                style={[
                  rowStyle,
                  index < exams.length - 1 ? borderStyle : null,
                  { zIndex: initialZIndex - 1 }
                ]}
                key={guidGenerator()}
              >
                <ListColumnItem
                  key={guidGenerator()}
                  text={moment(exam.startDate).format("DD/MM/YYYY HH:mm")}
                  viewStyle={[
                    { width: columnsTitlesAndWidth[0].width },
                    styles.itemView
                  ]}
                  textStyle={[styles.text]}
                  textSetNbOfLines={-1}
                />
                <ListColumnItem
                  key={guidGenerator()}
                  text={exam.subject?.name || ""}
                  viewStyle={[
                    { width: columnsTitlesAndWidth[1].width },
                    styles.itemView
                  ]}
                  textStyle={[styles.text]}
                  textSetNbOfLines={-1}
                />
                <ListColumnItem
                  key={guidGenerator()}
                  text={exam.name}
                  viewStyle={[
                    { width: columnsTitlesAndWidth[2].width },
                    styles.itemView
                  ]}
                  textStyle={[styles.text, styles.boldText]}
                  textSetNbOfLines={-1}
                />
                <ListColumnItem
                  key={guidGenerator()}
                  text={`${hour}h${minutes < 10 ? `0${minutes}` : minutes}`}
                  viewStyle={[
                    { width: columnsTitlesAndWidth[3].width },
                    styles.itemView
                  ]}
                  textStyle={[styles.text]}
                  textSetNbOfLines={-1}
                />
                <View
                  key={guidGenerator()}
                  style={[
                    {
                      width: columnsTitlesAndWidth[4].width,
                      alignContent: "center"
                    },
                    styles.itemView
                  ]}
                >
                  {exam.examParams && (
                    <InfoIconWithRules data={exam.examParams} />
                  )}
                </View>
                <View
                  key={guidGenerator()}
                  style={[
                    {
                      width: columnsTitlesAndWidth[5].width,
                      alignContent: "center"
                    },
                    styles.itemView
                  ]}
                >
                  <ButtonNextExamList
                    exam={exam}
                    currentAttachedFiles={attachedFiles.find(
                      (files) => files.examId === exam.id
                    )}
                    containerTagStyle={{
                      paddingTop: 15,
                      justifyContent: "center"
                    }}
                    textTagStyle={{
                      color: COLOR_BLUE_TESTWE,
                      fontSize: FONTSIZE_12
                    }}
                    icon={playIcon}
                    onPress={() =>
                      displaySingleExamInfo
                        ? displaySingleExamInfo(exam.id)
                        : null
                    }
                  />
                </View>
              </View>
            );
          })}

        {exams.length === 0 && (
          <View style={[styles.view, rowStyle, styles.textCenter]}>
            <Text style={[styles.text]}>{i18n.t("exam.noexamsavailable")}</Text>
          </View>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  view: {
    backgroundColor: COLOR_GREY_BACKGROUND,
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    paddingHorizontal: PADDING_SIDES * 0.5,
    flexGrow: 1
  },
  itemView: {
    paddingHorizontal: PADDING_SIDES * 0.3
  },
  text: {
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_14,
    color: COLOR_BLUE_TESTWE
  },
  boldText: {
    fontFamily: FONT_GILROY_BOLD,
    color: COLOR_BLUE
  },
  textCenter: {
    justifyContent: "center"
  }
});

export default NextExamsList;
