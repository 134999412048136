import React from "react";
import {
  StyleProp,
  StyleSheet,
  TextStyle,
  TouchableHighlight,
  ScrollView,
  ViewStyle
} from "react-native";
import { COLOR_GREY_BORDER, COLOR_SHADOW } from "../../../static/misc/colors";
import { PADDING_SIDES } from "../../../static/misc/constants";
import { guidGenerator } from "../../../static/misc/utils";
import LightText from "./LightText";

export interface Choices {
  positionInText: number;
  name: string;
}

interface ItemSelectorProps {
  choices: Array<string>;
  currentIndex?: number;
  containerStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  choiceStyle?: StyleProp<ViewStyle>;
  onChangeChoice: (index: number) => void;
}

class ItemSelector extends React.PureComponent<ItemSelectorProps> {
  render(): JSX.Element {
    const {
      choices,
      onChangeChoice,
      currentIndex,
      containerStyle,
      textStyle,
      choiceStyle
    } = this.props;

    return (
      <ScrollView style={[styles.container, containerStyle]}>
        {choices.map((item, index) => {
          if (index !== currentIndex) {
            return (
              <TouchableHighlight
                key={guidGenerator()}
                underlayColor={COLOR_SHADOW}
                onPress={() => onChangeChoice(index)}
                style={[choiceStyle]}
              >
                <LightText
                  textStyle={[styles.defaultTextStyle, textStyle]}
                  text={item}
                />
              </TouchableHighlight>
            );
          }
          return null;
        })}
      </ScrollView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 2,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderTopWidth: 0,
    width: "100%",
    borderColor: COLOR_GREY_BORDER,
    position: "absolute",
    zIndex: 70,
    borderRadius: 5,
    paddingBottom: PADDING_SIDES * 0.1,
    maxHeight: 200
  },
  defaultTextStyle: {
    flexWrap: "wrap",
    alignItems: "flex-start",
    paddingVertical: PADDING_SIDES * 0.1,
    paddingHorizontal: PADDING_SIDES * 0.2
  }
});

export default ItemSelector;
