import React from "react";
import { StyleSheet, ScrollView } from "react-native";
import i18n from "../../services/i18n";
import { COLOR_BLUE_TESTWE } from "../../../static/misc/colors";
import ExtraBoldTitle from "../atoms/ExtraBoldTitle";
import LanguageCheckBox from "../molecules/LanguageCheckBox";
import moment from "../../services/moment";
import { setAsyncStorageItem } from "../../../static/misc/utils";
import { DEFAULT_LANG, PADDING_SIDES } from "../../../static/misc/constants";

export interface ListCheckButtonProps {
  setLocale: (locale: string) => void;
  closeModal: () => void;
}
interface ListCheckButtonState {
  selectedLocale: string;
}

class ListCheckButton extends React.PureComponent<
  ListCheckButtonProps,
  ListCheckButtonState
> {
  constructor(props: ListCheckButtonProps) {
    super(props);
    this.state = {
      selectedLocale: i18n.locale
    };
  }

  // Updating the selected locale in the store
  changeLocale(locale: string): void {
    const { setLocale, closeModal } = this.props;

    i18n.locale = locale;
    moment.locale(locale);
    setAsyncStorageItem(DEFAULT_LANG, locale);
    this.setState({ selectedLocale: locale }, () => setLocale(locale));
    closeModal();
  }

  render(): JSX.Element {
    const { selectedLocale } = this.state;

    return (
      <ScrollView>
        <ExtraBoldTitle
          text={i18n.t("homepage.header.changeLang")}
          textStyle={styles.textStyle}
        />
        {Object.keys(i18n.translations).map((prop) => {
          return (
            <LanguageCheckBox
              key={`${i18n.t(prop)}-${prop}`}
              local={prop}
              text={i18n.t(prop)}
              selectedLocale={selectedLocale}
              selectElement={(locale: string) => this.changeLocale(locale)}
            />
          );
        })}
      </ScrollView>
    );
  }
}

const styles = StyleSheet.create({
  textStyle: {
    color: COLOR_BLUE_TESTWE,
    paddingHorizontal: PADDING_SIDES * 0.5,
    paddingBottom: PADDING_SIDES * 0.5
  }
});

export default ListCheckButton;
