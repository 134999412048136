import {
  CalculatorButtonType,
  CalculatorButtonTypeEnum
} from "../../src/modules/examTaking/types/calculator";
import {
  COLOR_BLUE_LIGHT,
  COLOR_WHITE,
  COLOR_BLUE_TESTWE,
  COLOR_GREY_BUTTON,
  COLOR_BLUE_LIGHTER,
  COLOR_GREEN_VALID,
  COLOR_GREY_BUTTON_DARKER,
  COLOR_RED,
  COLOR_RED_50
} from "./colors";
import { WIDTH_1_5, WIDTH_2_5, WIDTH_3, WIDTH_9 } from "./constants";

export const CALC_COMMON_STRUCTURE: CalculatorButtonType[][] = [
  [
    {
      label: "MC",
      width: WIDTH_1_5,
      backgroundColor: COLOR_GREY_BUTTON_DARKER,
      color: COLOR_WHITE,
      buttonType: CalculatorButtonTypeEnum.MC
    },
    {
      label: "MR",
      width: WIDTH_1_5,
      backgroundColor: COLOR_GREY_BUTTON_DARKER,
      color: COLOR_WHITE,
      buttonType: CalculatorButtonTypeEnum.MR
    },
    {
      label: "M-",
      width: WIDTH_1_5,
      backgroundColor: COLOR_GREY_BUTTON_DARKER,
      color: COLOR_WHITE,
      buttonType: CalculatorButtonTypeEnum.MMINUS
    },
    {
      label: "M+",
      width: WIDTH_1_5,
      backgroundColor: COLOR_GREY_BUTTON_DARKER,
      color: COLOR_WHITE,
      buttonType: CalculatorButtonTypeEnum.MPLUS
    },
    {
      label: "MS",
      width: WIDTH_1_5,
      backgroundColor: COLOR_GREY_BUTTON_DARKER,
      color: COLOR_WHITE,
      buttonType: CalculatorButtonTypeEnum.MS
    },
    {
      label: "C",
      width: WIDTH_1_5,
      backgroundColor: COLOR_RED_50,
      color: COLOR_WHITE,
      buttonType: CalculatorButtonTypeEnum.C
    },
    {
      label: "AC",
      width: WIDTH_1_5,
      backgroundColor: COLOR_RED,
      color: COLOR_WHITE,
      buttonType: CalculatorButtonTypeEnum.AC
    }
  ]
];

export const CALC_SCIENTIFIC_STRUCTURE: CalculatorButtonType[][][] = [
  [
    [
      {
        label: "2nd",
        width: WIDTH_2_5,
        backgroundColor: COLOR_BLUE_LIGHT,
        color: COLOR_WHITE,
        value: "2nd",
        buttonType: CalculatorButtonTypeEnum.SECOND
      },
      {
        label: "2nd",
        width: WIDTH_2_5,
        backgroundColor: COLOR_BLUE_TESTWE,
        color: COLOR_WHITE,
        value: "2nd",
        buttonType: CalculatorButtonTypeEnum.SECOND
      }
    ],
    [
      {
        label: "rad",
        width: WIDTH_2_5,
        backgroundColor: COLOR_GREY_BUTTON,
        color: COLOR_BLUE_TESTWE,
        value: "rad",
        buttonType: CalculatorButtonTypeEnum.FUNCTION,
        function: "RADIANS(x)"
      }
    ],
    [
      {
        label: "rand",
        width: WIDTH_2_5,
        backgroundColor: COLOR_GREY_BUTTON,
        color: COLOR_BLUE_TESTWE,
        value: "rand",
        buttonType: CalculatorButtonTypeEnum.FUNCTION,
        function: "RAND()"
      },
      {
        label: "abs",
        width: WIDTH_2_5,
        backgroundColor: COLOR_GREY_BUTTON,
        color: COLOR_BLUE_TESTWE,
        value: "abs",
        buttonType: CalculatorButtonTypeEnum.FUNCTION,
        function: "ABS(x)"
      }
    ],
    [
      {
        label: "e\u207f",
        width: WIDTH_2_5,
        backgroundColor: COLOR_GREY_BUTTON,
        color: COLOR_BLUE_TESTWE,
        value: "en",
        buttonType: CalculatorButtonTypeEnum.FUNCTION,
        function: "EXP(x)"
      }
    ],
    [
      {
        label: "10\u207f",
        width: WIDTH_2_5,
        backgroundColor: COLOR_GREY_BUTTON,
        color: COLOR_BLUE_TESTWE,
        value: "10n",
        buttonType: CalculatorButtonTypeEnum.FUNCTION,
        function: "10^x"
      }
    ]
  ],
  [
    [
      {
        label: "x\u00B2",
        width: WIDTH_2_5,
        backgroundColor: COLOR_GREY_BUTTON,
        color: COLOR_BLUE_TESTWE,
        value: "x2",
        buttonType: CalculatorButtonTypeEnum.FUNCTION,
        function: "x^2"
      }
    ],
    [
      {
        label: "x\u00B3",
        width: WIDTH_2_5,
        backgroundColor: COLOR_GREY_BUTTON,
        color: COLOR_BLUE_TESTWE,
        value: "x3",
        buttonType: CalculatorButtonTypeEnum.FUNCTION,
        function: "x^3"
      }
    ],
    [
      {
        label: "x\u207f",
        width: WIDTH_2_5,
        backgroundColor: COLOR_GREY_BUTTON,
        color: COLOR_BLUE_TESTWE,
        value: "xn",
        buttonType: CalculatorButtonTypeEnum.FUNCTION,
        function: "x^",
        waitForUserInput: true
      }
    ],
    [
      {
        label: "ln",
        width: WIDTH_2_5,
        backgroundColor: COLOR_GREY_BUTTON,
        color: COLOR_BLUE_TESTWE,
        value: "ln",
        buttonType: CalculatorButtonTypeEnum.FUNCTION,
        function: "LN(x)"
      }
    ],
    [
      {
        label: "log\u00b9\u2070",
        width: WIDTH_2_5,
        backgroundColor: COLOR_GREY_BUTTON,
        color: COLOR_BLUE_TESTWE,
        value: "log10",
        buttonType: CalculatorButtonTypeEnum.FUNCTION,
        function: "LOG10(x)"
      }
    ]
  ],
  [
    [
      {
        label: "\u221a",
        width: WIDTH_2_5,
        backgroundColor: COLOR_GREY_BUTTON,
        color: COLOR_BLUE_TESTWE,
        value: "v",
        buttonType: CalculatorButtonTypeEnum.FUNCTION,
        function: "sqrt(x)"
      }
    ],
    [
      {
        label: "\u00b3\u221a",
        width: WIDTH_2_5,
        backgroundColor: COLOR_GREY_BUTTON,
        color: COLOR_BLUE_TESTWE,
        value: "3v",
        buttonType: CalculatorButtonTypeEnum.FUNCTION,
        function: "cbrt(x)"
      }
    ],
    [
      {
        label: "\u207f\u221a",
        width: WIDTH_2_5,
        backgroundColor: COLOR_GREY_BUTTON,
        color: COLOR_BLUE_TESTWE,
        value: "nv",
        buttonType: CalculatorButtonTypeEnum.FUNCTION,
        function: "nthRoot(x,",
        waitForUserInput: true
      }
    ],
    [
      {
        label: "x!",
        width: WIDTH_2_5,
        backgroundColor: COLOR_GREY_BUTTON,
        color: COLOR_BLUE_TESTWE,
        value: "x!",
        buttonType: CalculatorButtonTypeEnum.FUNCTION,
        function: "FACT(x)"
      }
    ],
    [
      {
        label: "1/x",
        width: WIDTH_2_5,
        backgroundColor: COLOR_GREY_BUTTON,
        color: COLOR_BLUE_TESTWE,
        value: "1/x",
        buttonType: CalculatorButtonTypeEnum.FUNCTION,
        function: "1/x"
      }
    ]
  ],
  [
    [
      {
        label: "sin",
        width: WIDTH_2_5,
        backgroundColor: COLOR_GREY_BUTTON,
        color: COLOR_BLUE_TESTWE,
        value: "sin",
        buttonType: CalculatorButtonTypeEnum.FUNCTION,
        function: "SIN(x)"
      },
      {
        label: "asin",
        width: WIDTH_2_5,
        backgroundColor: COLOR_GREY_BUTTON,
        color: COLOR_BLUE_TESTWE,
        value: "asin",
        buttonType: CalculatorButtonTypeEnum.FUNCTION,
        function: "ASIN(x)"
      }
    ],
    [
      {
        label: "cos",
        width: WIDTH_2_5,
        backgroundColor: COLOR_GREY_BUTTON,
        color: COLOR_BLUE_TESTWE,
        value: "cos",
        buttonType: CalculatorButtonTypeEnum.FUNCTION,
        function: "COS(x)"
      },
      {
        label: "acos",
        width: WIDTH_2_5,
        backgroundColor: COLOR_GREY_BUTTON,
        color: COLOR_BLUE_TESTWE,
        value: "acos",
        buttonType: CalculatorButtonTypeEnum.FUNCTION,
        function: "ACOS(x)"
      }
    ],
    [
      {
        label: "tan",
        width: WIDTH_2_5,
        backgroundColor: COLOR_GREY_BUTTON,
        color: COLOR_BLUE_TESTWE,
        value: "tan",
        buttonType: CalculatorButtonTypeEnum.FUNCTION,
        function: "TAN(x)"
      },
      {
        label: "atan",
        width: WIDTH_2_5,
        backgroundColor: COLOR_GREY_BUTTON,
        color: COLOR_BLUE_TESTWE,
        value: "atan",
        buttonType: CalculatorButtonTypeEnum.FUNCTION,
        function: "ATAN(x)"
      }
    ],
    [
      {
        label: "\u2107",
        width: WIDTH_2_5,
        backgroundColor: COLOR_GREY_BUTTON,
        color: COLOR_BLUE_TESTWE,
        value: "e",
        buttonType: CalculatorButtonTypeEnum.E
      }
    ],
    [
      {
        label: "\u03c0",
        width: WIDTH_2_5,
        backgroundColor: COLOR_GREY_BUTTON,
        color: COLOR_BLUE_TESTWE,
        value: "PI",
        buttonType: CalculatorButtonTypeEnum.PI
      }
    ]
  ],
  [
    [
      {
        label: "sinh",
        width: WIDTH_2_5,
        backgroundColor: COLOR_GREY_BUTTON,
        color: COLOR_BLUE_TESTWE,
        value: "sinh",
        buttonType: CalculatorButtonTypeEnum.FUNCTION,
        function: "SINH(x)"
      }
    ],
    [
      {
        label: "cosh",
        width: WIDTH_2_5,
        backgroundColor: COLOR_GREY_BUTTON,
        color: COLOR_BLUE_TESTWE,
        value: "cosh",
        buttonType: CalculatorButtonTypeEnum.FUNCTION,
        function: "COSH(x)"
      }
    ],
    [
      {
        label: "tanh",
        width: WIDTH_2_5,
        backgroundColor: COLOR_GREY_BUTTON,
        color: COLOR_BLUE_TESTWE,
        value: "tanh",
        buttonType: CalculatorButtonTypeEnum.FUNCTION,
        function: "TANH(x)"
      }
    ],
    [
      {
        label: "(",
        width: WIDTH_2_5,
        backgroundColor: COLOR_GREY_BUTTON,
        color: COLOR_BLUE_TESTWE,
        value: "(",
        buttonType: CalculatorButtonTypeEnum.OPENINGBRACKET
      }
    ],
    [
      {
        label: ")",
        width: WIDTH_2_5,
        backgroundColor: COLOR_GREY_BUTTON,
        color: COLOR_BLUE_TESTWE,
        value: ")",
        buttonType: CalculatorButtonTypeEnum.CLOSINGBRACKET
      }
    ]
  ]
];

export const CALC_BASIC_STRUCTURE: CalculatorButtonType[][] = [
  [
    {
      label: "7",
      width: WIDTH_3,
      backgroundColor: COLOR_BLUE_LIGHTER,
      color: COLOR_BLUE_TESTWE,
      value: "7",
      buttonType: CalculatorButtonTypeEnum.NUMBER
    },
    {
      label: "8",
      width: WIDTH_3,
      backgroundColor: COLOR_BLUE_LIGHTER,
      color: COLOR_BLUE_TESTWE,
      value: "8",
      buttonType: CalculatorButtonTypeEnum.NUMBER
    },
    {
      label: "9",
      width: WIDTH_3,
      backgroundColor: COLOR_BLUE_LIGHTER,
      color: COLOR_BLUE_TESTWE,
      value: "9",
      buttonType: CalculatorButtonTypeEnum.NUMBER
    },
    {
      label: "÷",
      width: WIDTH_3,
      backgroundColor: COLOR_BLUE_LIGHT,
      color: COLOR_WHITE,
      value: "/",
      buttonType: CalculatorButtonTypeEnum.OPERATOR
    }
  ],
  [
    {
      label: "4",
      width: WIDTH_3,
      backgroundColor: COLOR_BLUE_LIGHTER,
      color: COLOR_BLUE_TESTWE,
      value: "4",
      buttonType: CalculatorButtonTypeEnum.NUMBER
    },
    {
      label: "5",
      width: WIDTH_3,
      backgroundColor: COLOR_BLUE_LIGHTER,
      color: COLOR_BLUE_TESTWE,
      value: "5",
      buttonType: CalculatorButtonTypeEnum.NUMBER
    },
    {
      label: "6",
      width: WIDTH_3,
      backgroundColor: COLOR_BLUE_LIGHTER,
      color: COLOR_BLUE_TESTWE,
      value: "6",
      buttonType: CalculatorButtonTypeEnum.NUMBER
    },
    {
      label: "×",
      width: WIDTH_3,
      backgroundColor: COLOR_BLUE_LIGHT,
      color: COLOR_WHITE,
      value: "*",
      buttonType: CalculatorButtonTypeEnum.OPERATOR
    }
  ],
  [
    {
      label: "1",
      width: WIDTH_3,
      backgroundColor: COLOR_BLUE_LIGHTER,
      color: COLOR_BLUE_TESTWE,
      value: "1",
      buttonType: CalculatorButtonTypeEnum.NUMBER
    },
    {
      label: "2",
      width: WIDTH_3,
      backgroundColor: COLOR_BLUE_LIGHTER,
      color: COLOR_BLUE_TESTWE,
      value: "2",
      buttonType: CalculatorButtonTypeEnum.NUMBER
    },
    {
      label: "3",
      width: WIDTH_3,
      backgroundColor: COLOR_BLUE_LIGHTER,
      color: COLOR_BLUE_TESTWE,
      value: "3",
      buttonType: CalculatorButtonTypeEnum.NUMBER
    },
    {
      label: "-",
      width: WIDTH_3,
      backgroundColor: COLOR_BLUE_LIGHT,
      color: COLOR_WHITE,
      value: "-",
      buttonType: CalculatorButtonTypeEnum.OPERATOR
    }
  ],
  [
    {
      label: "0",
      width: WIDTH_3,
      backgroundColor: COLOR_BLUE_LIGHTER,
      color: COLOR_BLUE_TESTWE,
      value: "0",
      buttonType: CalculatorButtonTypeEnum.NUMBER
    },
    {
      label: ".",
      width: WIDTH_3,
      backgroundColor: COLOR_BLUE_LIGHTER,
      color: COLOR_BLUE_TESTWE,
      value: ".",
      buttonType: CalculatorButtonTypeEnum.DOT
    },
    {
      label: "+/-",
      width: WIDTH_3,
      backgroundColor: COLOR_BLUE_LIGHTER,
      color: COLOR_BLUE_TESTWE,
      buttonType: CalculatorButtonTypeEnum.SIGNCHANGE
    },
    {
      label: "+",
      width: WIDTH_3,
      backgroundColor: COLOR_BLUE_LIGHT,
      color: COLOR_WHITE,
      value: "+",
      buttonType: CalculatorButtonTypeEnum.OPERATOR
    }
  ],
  [
    {
      label: "=",
      width: WIDTH_9,
      backgroundColor: COLOR_GREEN_VALID,
      color: COLOR_WHITE,
      value: "=",
      buttonType: CalculatorButtonTypeEnum.EQUAL
    },
    {
      label: "%",
      width: WIDTH_3,
      backgroundColor: COLOR_BLUE_LIGHT,
      color: COLOR_WHITE,
      value: "%",
      function: "MOD(x,",
      waitForUserInput: true,
      buttonType: CalculatorButtonTypeEnum.FUNCTION
    }
  ]
];
