import axios from "axios";

import { DURATION_TIMEOUT_AXIOS } from "../../static/misc/constants";
import { KEYCLOAK_API, LOCAL_API, TESTWE_API } from "../../static/misc/network";

// create axios instance for every APIs

export const keycloak = axios.create({
  baseURL: KEYCLOAK_API,
  timeout: DURATION_TIMEOUT_AXIOS,
  responseType: "json"
});

export const api = axios.create({
  baseURL: TESTWE_API,
  timeout: DURATION_TIMEOUT_AXIOS,
  responseType: "json"
});

export const blob = axios.create({
  timeout: DURATION_TIMEOUT_AXIOS,
  responseType: "blob"
});

export const localApi = axios.create({
  baseURL: LOCAL_API,
  responseType: "json"
});
