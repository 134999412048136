import React from "react";
import { StyleProp, StyleSheet, View, ViewStyle, Image } from "react-native";
import {
  COLOR_BLUE_TESTWE,
  COLOR_GREY_BACKGROUND
} from "../../../static/misc/colors";
import BoldTitle from "../atoms/BoldTitle";
import RegularText from "../atoms/RegularText";
import { illuHomepage2 } from "../../../static/misc/images";

export interface WarningExamProps {
  containerStyle?: StyleProp<ViewStyle>;
  warningTitle: string;
  warningContent: string;
}

class WarningExam extends React.PureComponent<WarningExamProps> {
  render(): JSX.Element {
    const { containerStyle, warningContent, warningTitle } = this.props;
    return (
      <View style={[styles.defaultContainerStyle, containerStyle]}>
        <Image source={illuHomepage2} style={styles.illustration} />
        <View style={styles.textWrapper}>
          <BoldTitle text={warningTitle} textStyle={{ paddingBottom: 8 }} />
          <RegularText text={warningContent} />
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  defaultContainerStyle: {
    paddingHorizontal: "2%",
    paddingVertical: "1%",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    borderWidth: 1,
    borderColor: COLOR_BLUE_TESTWE,
    borderStyle: "dotted",
    borderRadius: 40,
    backgroundColor: COLOR_GREY_BACKGROUND
  },
  illustration: {
    width: 142,
    height: 114
  },
  textWrapper: {
    paddingLeft: 30,
    paddingBottom: 10,
    flex: 1
  }
});

export default WarningExam;
