import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "../../store/rootreducer";

import NoConnexionPage from "./NoConnexionPage";

const mapStateToProps = (state: RootState) => {
  return {
    isOnline: state.status.isOnline
  };
};

const mapdispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators({}, dispatch)
  };
};

const NoConnexionContainer = connect(
  mapStateToProps,
  mapdispatchToProps
)(NoConnexionPage);

export default NoConnexionContainer;
