import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../store/rootreducer";
import TinyEditor from "./TinyEditor";

declare const tinymce: any;

export type TypingContent = {
  content: string;
  htmlContent: string;
};

interface TinyMceProps {
  onTyping: (content: TypingContent) => void;
  nbCharMax?: number;
  nbWordsMax?: number;
  previousAnswer?: string;
  width?: string | number;
  height?: string | number;
  locale: string;
}

interface TinyMceState {
  content: string;
}

class TinyMce extends React.Component<TinyMceProps, TinyMceState> {
  static defaultProps = {
    width: "100%",
    height: "100%"
  };

  constructor(props: TinyMceProps) {
    super(props);
    const { previousAnswer } = this.props;
    this.state = {
      content: previousAnswer || ""
    };
    this.handleEditorChange = this.handleEditorChange.bind(this);
  }

  // editor: any second parameter // quick fix // lib has any ...
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  handleEditorChange(content: string, ed: any): void {
    const contentWithoutHtml = ed.getContent({ format: "text" });
    const { onTyping } = this.props;
    this.setState({ content });
    onTyping({
      content: contentWithoutHtml,
      htmlContent: content
    });
  }

  render(): JSX.Element {
    const { content } = this.state;
    const { width, height, nbCharMax, nbWordsMax, locale } = this.props;
    let tinyLocale = "en";
    // to prevent js crash on tinyInit if locale isn't in the translated files
    // we need to init it with default en
    if (locale && ["fr", "pt", "en", "it", "es", "nl"].includes(locale)) {
      tinyLocale = locale;
    }
    return (
      <TinyEditor
        initialValue={content}
        init={{
          setup(ed: any) {
            ed.on("keydown", (e: any) => {
              const nbWords = tinymce
                .get(tinymce.activeEditor.id)
                .contentDocument.body.innerText.split(" ").length;
              const nbChars = tinymce
                .get(tinymce.activeEditor.id)
                .contentDocument.body.innerText.replace(/\s+/g, "")
                .replace(/\n/gi, "").length;
              if (
                e.key !== "Backspace" &&
                ((nbCharMax && nbChars >= nbCharMax) ||
                  (nbWordsMax &&
                    nbWords >= nbWordsMax &&
                    (e.code === "Space" || e.code === "Enter")))
              ) {
                e.preventDefault();
                e.stopPropagation();
                return false;
              }
              return true;
            });
          },
          height,
          width,
          menubar: false,
          language: tinyLocale,
          toolbar:
            "undo redo | FMathEditor | formatselect | bold italic underline backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol",
          plugins: ["wordcount", "table"]
        }}
        value={content}
        onEditorChange={this.handleEditorChange}
        hasMath
      />
    );
  }
}

export default connect((state: RootState) => {
  return {
    locale: state.user.locale // removed default because not sufficiant we need to check if local exist in translations
  };
})(TinyMce);
