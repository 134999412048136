import React from "react";
import { StyleSheet, View, Text } from "react-native";
import ReactPlayer from "react-player";
import Webcam from "react-webcam";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import Loader from "../atoms/Loader";
import i18n from "../../services/i18n";
import {
  FONTSIZE_14,
  FONT_GILROY_REGULAR,
  PADDING_SIDES,
  VIDEO_CONSTRAINTS
} from "../../../static/misc/constants";
import { COLOR_BLUE_TESTWE, COLOR_WHITE } from "../../../static/misc/colors";
import { GetErrorAction } from "../../modules/main/types/error";
import { ExamOnboardingStep } from "../../modules/exams/types/exam";
import { ONBOARDING_ASSETS } from "../../../static/misc/onboarding/onboardingsteps";
import { OnboardingInfoType } from "./OnboardingItem";
import { PhotoTaking } from "../molecules/PhotoTaking";
import Button from "../molecules/Button";
import { postLatestEquipmentCheck } from "../../modules/main/actions/equipmentCheck";

enum ReadyState {
  INIT,
  PENDING,
  READY
}

interface OnboardingPhotoStepProps {
  loader: boolean;
  canReloadCurrentStep: boolean;
  currentOnboardingStep: ExamOnboardingStep;
  currentInfo?: OnboardingInfoType;
  type: string;
  getError: (message: string, forceLogout: boolean) => GetErrorAction;
  updateCurrentStepInfo: (
    data?: string | Blob | null,
    status?: boolean
  ) => void;
  toggleLoader: (load: boolean) => void;
  onEquipmentValidated: (camera: boolean) => void;
}

interface OnboardingPhotoStepViewState {
  isReady: ReadyState;
}

class OnboardingPhotoStepView extends React.PureComponent<
  OnboardingPhotoStepProps,
  OnboardingPhotoStepViewState
> {
  webcamRef?: React.RefObject<Webcam>;

  onloadInterval!: ReturnType<typeof setInterval>;

  constructor(props: OnboardingPhotoStepProps) {
    super(props);
    this.state = {
      isReady: props.currentInfo?.data ? ReadyState.READY : ReadyState.INIT
    };
  }

  componentDidMount(): void {
    const { isReady } = this.state;
    if (isReady === ReadyState.INIT) {
      this.startInterval();
    }
  }

  componentDidUpdate(prevProps: OnboardingPhotoStepProps): void {
    const { type } = this.props;
    const { isReady } = this.state;
    if (prevProps.type !== type || isReady === ReadyState.INIT) {
      this.startInterval();
    }
  }

  componentWillUnmount(): void {
    if (this.onloadInterval) {
      clearInterval(this.onloadInterval);
    }
  }

  sendEquimentResults(): void {
    const { onEquipmentValidated } = this.props;
    onEquipmentValidated(true);
  }

  startInterval(): void {
    this.setState({ isReady: ReadyState.PENDING });

    this.onloadInterval = setInterval(() => {
      if (this.webcamRef?.current?.video?.readyState === 4) {
        this.setState({ isReady: ReadyState.READY });
        clearInterval(this.onloadInterval);
      }
    }, 100);
  }

  render(): JSX.Element | null {
    const {
      loader,
      canReloadCurrentStep,
      currentOnboardingStep,
      currentInfo,
      getError,
      updateCurrentStepInfo,
      toggleLoader
    } = this.props;
    const { isReady } = this.state;
    this.webcamRef = React.createRef<Webcam>();
    const associatedAsset = ONBOARDING_ASSETS.find(
      (asset) => asset.demoVideo === currentOnboardingStep.demoVideo
    );
    const shouldUseWebmFormat =
      window.navigator.userAgent.includes("Windows") &&
      window.navigator.userAgent.includes("SEB");

    const video = shouldUseWebmFormat
      ? associatedAsset?.video.webm
      : associatedAsset?.video.mp4;

    return (
      <View style={styles.proctoringContainer}>
        {currentOnboardingStep.subtitle &&
          currentOnboardingStep.subtitle !== "" && (
            <Text style={styles.stepSubtitle}>
              {i18n.t(`onboardingModal.${currentOnboardingStep.subtitle}`)} :
            </Text>
          )}
        {loader && <Loader runAnimation={loader} />}
        <View
          style={[
            styles.steps,
            {
              display: loader ? "none" : "flex"
            }
          ]}
        >
          <View>
            <ReactPlayer
              url={video ?? null}
              width={VIDEO_CONSTRAINTS.width}
              height={VIDEO_CONSTRAINTS.height}
              controls={false}
              playing
              loop
              config={{
                file: { attributes: { controlsList: "nodownload" } }
              }}
            />
          </View>
          <View>
            <PhotoTaking
              currentPhoto={currentInfo?.data}
              currentOnboardingStep={currentOnboardingStep}
              webcamRef={this.webcamRef}
              getError={getError}
              toggleLoader={toggleLoader}
            />
            <Button
              label={
                currentInfo?.data
                  ? i18n.t("onboardingModal.reTakePicture")
                  : i18n.t("onboardingModal.takePicture")
              }
              textStyle={{
                color: COLOR_BLUE_TESTWE,
                fontFamily: FONT_GILROY_REGULAR
              }}
              containerStyle={[styles.reloadButtonStyle]}
              disabled={isReady !== ReadyState.READY}
              onPress={() => {
                if (
                  canReloadCurrentStep ||
                  (!canReloadCurrentStep && !!currentInfo?.data)
                ) {
                  this.setState({
                    isReady: ReadyState.INIT
                  });
                }
                this.sendEquimentResults();
                updateCurrentStepInfo(
                  canReloadCurrentStep
                    ? null
                    : this.webcamRef?.current?.getScreenshot(),
                  true
                );
              }}
            />
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  stepSubtitle: {
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_14,
    color: COLOR_BLUE_TESTWE,
    paddingBottom: PADDING_SIDES * 0.2
  },
  reloadButtonStyle: {
    borderStyle: "solid",
    borderColor: COLOR_BLUE_TESTWE,
    borderWidth: 1,
    borderRadius: 50,
    backgroundColor: COLOR_WHITE,
    marginTop: 15
  },
  steps: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "flex-start",
    width: "100%",
    maxWidth: 600
  },
  proctoringContainer: {
    alignItems: "center",
    minHeight: 200,
    marginBottom: 15,
    width: "100%"
  }
});

const mapdispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators(
      {
        postLatestEquipmentCheck
      },
      dispatch
    )
  };
};

const OnboardingPhotoStep = connect(
  null,
  mapdispatchToProps
)(OnboardingPhotoStepView);

export default OnboardingPhotoStep;
