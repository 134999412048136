import {
  PostStudentPaperAction,
  UpdateStudentPaperAction
} from "../../examTaking/types/studentPaper";

export const CREATE_PUBLIC_KEY = "CREATE_PUBLIC_KEY";
export const CLEAR_PUBLIC_KEY = "CLEAR_PUBLIC_KEY";

export const GET_EXAM_PUBLIC_KEY = "GET_EXAM_PUBLIC_KEY";
export const GET_EXAM_PUBLIC_KEY_SUCCESS = "GET_EXAM_PUBLIC_KEY_SUCCESS";
export const GET_EXAM_PUBLIC_KEY_FAIL = "GET_EXAM_PUBLIC_KEY_FAIL";

export interface GetExamPublicKeyAction {
  type:
    | typeof GET_EXAM_PUBLIC_KEY
    | typeof GET_EXAM_PUBLIC_KEY_SUCCESS
    | typeof GET_EXAM_PUBLIC_KEY_FAIL;
  payload: any;
  meta?: any;
}

export interface CreatePublicKeyAction {
  type: typeof CREATE_PUBLIC_KEY;
  payload: any;
}

export interface ClearPublicKeyAction {
  type: typeof CLEAR_PUBLIC_KEY;
  payload: any;
}

export interface PublicKeyType {
  examId: string;
  publicKey: string;
}
export interface PublicKeysState {
  examsPublicKeys: PublicKeyType[];
}

export type PublicKeysActionsType =
  | CreatePublicKeyAction
  | ClearPublicKeyAction
  | GetExamPublicKeyAction
  | UpdateStudentPaperAction
  | PostStudentPaperAction;
