import React from "react";
import {
  StyleSheet,
  StyleProp,
  ImageStyle,
  Animated,
  Easing
} from "react-native";
import { loaderColor, loaderWhite } from "../../../static/misc/images";

export interface LoaderProps {
  iconStyle?: StyleProp<ImageStyle>;
  runAnimation?: boolean;
  whiteLoader?: boolean;
}

interface LoaderState {
  spinAnim: Animated.Value;
}

class Loader extends React.PureComponent<LoaderProps, LoaderState> {
  static defaultProps = {
    runAnimation: true,
    whiteLoader: false
  };

  constructor(props: LoaderProps) {
    super(props);
    this.state = {
      spinAnim: new Animated.Value(0)
    };
  }

  startAnimation(): void {
    const { spinAnim } = this.state;

    Animated.loop(
      Animated.timing(spinAnim, {
        toValue: -1,
        duration: 3000,
        easing: Easing.linear,

        useNativeDriver: false
      })
    ).start();
  }

  render(): JSX.Element {
    const { runAnimation, iconStyle, whiteLoader } = this.props;
    const { spinAnim } = this.state;
    const spin = spinAnim.interpolate({
      inputRange: [0, 1],
      outputRange: ["0deg", "360deg"]
    });
    if (runAnimation === true) this.startAnimation();
    else spinAnim.stopAnimation();
    return (
      <Animated.Image
        source={whiteLoader ? loaderWhite : loaderColor}
        style={[
          {
            height: 16,
            width: 16,
            alignSelf: "center",
            transform: [{ rotate: spin }]
          },
          styles.iconStyle,
          iconStyle
        ]}
      />
    );
  }
}

const styles = StyleSheet.create({
  iconStyle: {
    flexDirection: "row",
    alignItems: "center"
  }
});

export default Loader;
