/* eslint-disable import/prefer-default-export */
import { MediaType } from "../../src/modules/exams/types/attachedfiles";
import { ExamType } from "../../src/modules/exams/types/exam";
import i18n from "../../src/services/i18n";
import { mockPdf } from "../../stories/static/mockpdf";
import {
  MIME_PDF,
  MIME_PNG,
  TRAINING,
  TRAINING_IMG1,
  TRAINING_IMG2,
  TRAINING_IMG3,
  TRAINING_IMG4,
  TRAINING_IMG5,
  TRAINING_IMG6,
  TRAINING_IMG7,
  TRAINING_IMG8,
  TRAINING_IMG9,
  TRAINING_PDF,
  TRAINING_SPREADSHEET
} from "./constants";
import { mockimage1 } from "./training/files/img1";
import { mockimage2 } from "./training/files/img2";
import { mockimage3 } from "./training/files/img3";
import { mockimage4 } from "./training/files/img4";
import { mockimage5 } from "./training/files/img5";
import { mockimage6 } from "./training/files/img6";
import { mockimage7 } from "./training/files/img7";
import { mockimage8 } from "./training/files/img8";
import { mockimage9 } from "./training/files/img9";

export function getLokalisedFiles(): MediaType[] {
  const files: MediaType[] = [
    {
      mediaId: TRAINING_IMG1,
      data: mockimage1,
      blobUrl: undefined,
      isUnciphered: true,
      isErrored: false,
      retryCount: 0,
      mimeType: MIME_PNG,
      isDownloaded: true,
      isDownloading: false
    },
    {
      mediaId: TRAINING_IMG2,
      data: mockimage2,
      blobUrl: undefined,
      isUnciphered: true,
      isErrored: false,
      retryCount: 0,
      mimeType: MIME_PNG,
      isDownloaded: true,
      isDownloading: false
    },
    {
      mediaId: TRAINING_IMG3,
      data: mockimage3,
      blobUrl: undefined,
      isUnciphered: true,
      isErrored: false,
      retryCount: 0,
      mimeType: MIME_PNG,
      isDownloaded: true,
      isDownloading: false
    },
    {
      mediaId: TRAINING_IMG4,
      data: mockimage4,
      blobUrl: undefined,
      isUnciphered: true,
      isErrored: false,
      retryCount: 0,
      mimeType: MIME_PNG,
      isDownloaded: true,
      isDownloading: false
    },
    {
      mediaId: TRAINING_IMG5,
      data: mockimage5,
      blobUrl: undefined,
      isUnciphered: true,
      isErrored: false,
      retryCount: 0,
      mimeType: MIME_PNG,
      isDownloaded: true,
      isDownloading: false
    },
    {
      mediaId: TRAINING_IMG6,
      data: mockimage6,
      blobUrl: undefined,
      isUnciphered: true,
      isErrored: false,
      retryCount: 0,
      mimeType: MIME_PNG,
      isDownloaded: true,
      isDownloading: false
    },
    {
      mediaId: TRAINING_IMG7,
      data: mockimage7,
      blobUrl: undefined,
      isUnciphered: true,
      isErrored: false,
      retryCount: 0,
      mimeType: MIME_PNG,
      isDownloaded: true,
      isDownloading: false
    },
    {
      mediaId: TRAINING_IMG8,
      data: mockimage8,
      blobUrl: undefined,
      isUnciphered: true,
      isErrored: false,
      retryCount: 0,
      mimeType: MIME_PNG,
      isDownloaded: true,
      isDownloading: false
    },
    {
      mediaId: TRAINING_IMG9,
      data: mockimage9,
      blobUrl: undefined,
      isUnciphered: true,
      isErrored: false,
      retryCount: 0,
      mimeType: MIME_PNG,
      isDownloaded: true,
      isDownloading: false
    },
    {
      mediaId: TRAINING_PDF,
      data: undefined,
      blobUrl: undefined,
      isUnciphered: true,
      isErrored: false,
      retryCount: 0,
      mimeType: MIME_PDF,
      isDownloaded: true,
      isDownloading: false
    }
  ];

  switch (i18n.locale) {
    case "it": {
      files[9].data = mockPdf;
      break;
    }
    case "pt": {
      files[9].data = mockPdf;
      break;
    }
    case "es": {
      files[9].data = mockPdf;
      break;
    }
    case "fr": {
      files[9].data = mockPdf;
      break;
    }
    case "nl": {
      files[9].data = mockPdf;
      break;
    }
    case "en":
    default: {
      files[9].data = mockPdf;
      break;
    }
  }
  return files;
}

export function getTrainingAttachedFiles(): MediaType[] {
  return getLokalisedFiles();
}

export function getTrainingExam(): ExamType {
  return {
    id: TRAINING,
    hash: TRAINING,
    examId: 0,
    highlights: [],
    name: i18n.t("trainingExam.examName"),
    subject: {
      name: "Prérequis"
    },
    academicYear: {
      name: "2020-2021"
    },
    assessmentType: {
      name: "Quizz"
    },
    startDate: "2021-07-01T15:14:16+00:00",
    duration: 36000,
    maxPoints: 20,
    instructions: "",
    publicInstructions: i18n.t("trainingExam.instructions1"),
    privateInstructions: i18n.t("trainingExam.instructions"),
    status: "finalized",
    publicKey: "HTPNJEJW",
    proctorCode: "44b8d6f0aaa4",
    attachedFiles: [],
    allAttachedFiles: [
      TRAINING_IMG1,
      TRAINING_IMG2,
      TRAINING_IMG3,
      TRAINING_IMG4,
      TRAINING_IMG5,
      TRAINING_IMG6,
      TRAINING_IMG7,
      TRAINING_IMG8,
      TRAINING_IMG9,
      TRAINING_PDF,
      TRAINING_SPREADSHEET
    ],
    examParts: [
      {
        id: "478dca37-271e-42f1-919e-4fd5389a50ee",
        name: i18n.t("trainingExam.name1"),
        instruction: i18n.t("trainingExam.instructions2"),
        numberPoint: 2,
        duration: 0,
        durationLimit: false,
        position: 0,
        randomPartIndexes: false,
        partIndexes: [
          {
            id: "7542766c-14ce-4f80-a241-4836ea31eef1",
            position: 0,
            type: "question",
            question: {
              id: "59c98fe7-8479-48cd-b06d-6b4a06565f14",
              type: "multiple_choice_question",
              position: 0,
              name: i18n.t("trainingExam.nonFormattedName1"),
              formattedName: i18n.t("trainingExam.formattedName1"),
              duration: 0,
              durationLimit: false,
              numberPoint: 1,
              randomChoices: false,
              attachedFiles: [],
              columnLabels: [
                {
                  index: 0,
                  name: ""
                }
              ],
              rowLabels: [
                {
                  index: 0,
                  name: ""
                }
              ],
              characterLimitParam: false,
              characterLimitParamValue: 0,
              wordLimitParam: false,
              wordLimitParamValue: 0,
              autoStartRecordDurationParam: false,
              autoStartRecordDurationParamValue: 0,
              maxDurationRecordParam: false,
              maxDurationRecordParamValue: 0,
              choices: [
                {
                  id: "05a1a028-43bc-425d-af34-7fbe626a1244",
                  name: i18n.t("trainingExam.answer1"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                },
                {
                  id: "9e29310d-9ef5-4637-8cf9-207d79f47b15",
                  name: i18n.t("trainingExam.answer2"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                },
                {
                  id: "881e65e6-d686-4935-b030-5393357aa07b",
                  name: i18n.t("trainingExam.answer3"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                },
                {
                  id: "1e2f0a08-0038-4e2d-9631-d9c65a1fc908",
                  name: i18n.t("trainingExam.noCorrectAnswer"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                }
              ]
            }
          },
          {
            id: "c8d4c25e-bc3d-489c-8d5b-41b3c4d5955b",
            position: 1,
            type: "question",
            question: {
              id: "b55ac677-3b8c-4937-a27c-342d320e84fd",
              type: "single_choice_question",
              position: 0,
              name: i18n.t("trainingExam.nonFormattedName2"),
              formattedName: i18n.t("trainingExam.formattedName2"),
              duration: 0,
              durationLimit: false,
              numberPoint: 1,
              randomChoices: false,
              attachedFiles: [],
              columnLabels: [
                {
                  index: 0,
                  name: ""
                }
              ],
              rowLabels: [
                {
                  index: 0,
                  name: ""
                }
              ],
              characterLimitParam: false,
              characterLimitParamValue: 0,
              wordLimitParam: false,
              wordLimitParamValue: 0,
              autoStartRecordDurationParam: false,
              autoStartRecordDurationParamValue: 0,
              maxDurationRecordParam: false,
              maxDurationRecordParamValue: 0,
              choices: [
                {
                  id: "d624bbf9-cebc-4673-b10f-5818a5c021d8",
                  name: i18n.t("trainingExam.answer4"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                },
                {
                  id: "0b810497-9624-45dd-9295-2d476725d7cc",
                  name: i18n.t("trainingExam.answer5"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                },
                {
                  id: "ad8a2670-68f6-44f9-bb08-e3fd2a372c82",
                  name: i18n.t("trainingExam.answer6"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                },
                {
                  id: "a03d696b-54d5-4961-8d6f-3ea8bcf83424",
                  name: i18n.t("trainingExam.answer7"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                },
                {
                  id: "a9e348d2-557f-43c7-ac1b-653957ec6398",
                  name: i18n.t("trainingExam.answer8"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                },
                {
                  id: "7f81d7ff-02c9-4d8a-a615-ea59d9434b19",
                  name: i18n.t("trainingExam.answer9"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                },
                {
                  id: "0de26ace-7954-4638-b132-2ea191c91ea7",
                  name: i18n.t("trainingExam.answer10"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                },
                {
                  id: "a6b17f18-5b87-426a-86d6-5692b7f043a0",
                  name: i18n.t("trainingExam.noCorrectAnswer"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                }
              ]
            }
          }
        ],
        attachedFiles: []
      },
      {
        id: "01c4b877-c7e8-4f6d-8d19-327c2f3b6211",
        name: i18n.t("trainingExam.name2"),
        instruction: i18n.t("trainingExam.instructions3"),
        numberPoint: 7,
        duration: 0,
        durationLimit: false,
        position: 1,
        randomPartIndexes: false,
        partIndexes: [
          {
            id: "a58314b4-ae7d-4ce8-a9a9-6bbaf467b0b5",
            position: 0,
            type: "question",
            question: {
              id: "6a71ad2c-4259-4d64-a623-1996a0c5795b",
              type: "text_dropdown_question",
              position: 0,
              name: "",
              formattedName: "",
              duration: 0,
              durationLimit: false,
              numberPoint: 4,
              randomChoices: false,
              attachedFiles: [],
              columnLabels: [],
              rowLabels: [],
              characterLimitParam: false,
              characterLimitParamValue: 0,
              wordLimitParam: false,
              wordLimitParamValue: 0,
              text: i18n.t("trainingExam.text1"),
              autoStartRecordDurationParam: false,
              autoStartRecordDurationParamValue: 0,
              maxDurationRecordParam: false,
              maxDurationRecordParamValue: 0,
              choices: [
                {
                  id: "a41938a7-01bf-4a5e-af52-c3105c2219d1",
                  name: i18n.t("trainingExam.answer11"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: "d425f07b-448d-428d-91a0-1a7109859a72"
                },
                {
                  id: "58b618e2-dc4a-4249-bff8-bfe824d9fca0",
                  name: i18n.t("trainingExam.answer12"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: "5b2fbf9e-b522-47ec-9bdc-77ffd196242a"
                },
                {
                  id: "0d06af8c-4f78-4aa4-8c05-60edb25862b7",
                  name: i18n.t("trainingExam.answer13"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: "5b2fbf9e-b522-47ec-9bdc-77ffd196242a"
                },
                {
                  id: "186b074b-700a-429d-9996-664735725004",
                  name: i18n.t("trainingExam.answer14"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: "8ee4aba7-39bb-4f7e-a9a7-7e567a7e601a"
                },
                {
                  id: "cb722b92-5c7b-4895-8c2e-64693b025dc3",
                  name: i18n.t("trainingExam.answer15"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: "8ee4aba7-39bb-4f7e-a9a7-7e567a7e601a"
                },
                {
                  id: "91e51693-83a5-41d8-a353-56b63250239c",
                  name: i18n.t("trainingExam.answer16"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: "c7816377-2f00-4c73-96de-be71f5cbd328"
                },
                {
                  id: "92a0ac69-1cf0-4824-a70c-057d7fc6d148",
                  name: i18n.t("trainingExam.answer17"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: "d425f07b-448d-428d-91a0-1a7109859a72"
                },
                {
                  id: "ed10c38f-6753-47dd-9c4f-34d1e799ba2f",
                  name: i18n.t("trainingExam.answer18"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: "5b2fbf9e-b522-47ec-9bdc-77ffd196242a"
                },
                {
                  id: "3aff003f-92d6-45d6-a95f-32d5a0641f1b",
                  name: i18n.t("trainingExam.answer19"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: "8ee4aba7-39bb-4f7e-a9a7-7e567a7e601a"
                },
                {
                  id: "b343711e-4e7e-4dbe-812e-4aeac1966f8f",
                  name: i18n.t("trainingExam.answer20"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: "c7816377-2f00-4c73-96de-be71f5cbd328"
                }
              ]
            }
          },
          {
            id: "23a51802-844e-4cfa-bcf0-73161142e532",
            position: 1,
            type: "question",
            question: {
              id: "d4657fa1-7382-4b6d-a129-0fc84c73211e",
              type: "single_choice_question",
              position: 0,
              name: i18n.t("trainingExam.nonFormattedName3"),
              formattedName: i18n.t("trainingExam.formattedName3"),
              duration: 0,
              durationLimit: false,
              numberPoint: 1,
              randomChoices: false,
              attachedFiles: [],
              columnLabels: [
                {
                  index: 0,
                  name: ""
                }
              ],
              rowLabels: [
                {
                  index: 0,
                  name: ""
                }
              ],
              characterLimitParam: false,
              characterLimitParamValue: 0,
              wordLimitParam: false,
              wordLimitParamValue: 0,
              autoStartRecordDurationParam: false,
              autoStartRecordDurationParamValue: 0,
              maxDurationRecordParam: false,
              maxDurationRecordParamValue: 0,
              choices: [
                {
                  id: "47bfa0f6-d55e-41c7-be37-faa403db47cb",
                  name: i18n.t("trainingExam.answer21"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                },
                {
                  id: "2abf286f-c370-4b9d-a5a3-dd9e95a4de38",
                  name: i18n.t("trainingExam.answer22"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                },
                {
                  id: "d9072de8-bf8d-419e-abb7-21976243c55b",
                  name: i18n.t("trainingExam.answer23"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                },
                {
                  id: "8536a14a-b46a-4de0-aea0-42b4ff1e1c62",
                  name: i18n.t("trainingExam.noCorrectAnswer"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                }
              ]
            }
          },
          {
            id: "fdc157e4-8035-4be4-9a8d-5fab703d358b",
            position: 2,
            type: "question",
            question: {
              id: "2181529f-06ec-44b6-b1a2-aa09dd43a93a",
              type: "single_choice_question",
              position: 0,
              name: i18n.t("trainingExam.nonFormattedName4"),
              formattedName: i18n.t("trainingExam.formattedName4"),
              duration: 0,
              durationLimit: false,
              numberPoint: 1,
              randomChoices: false,
              attachedFiles: [],
              columnLabels: [
                {
                  index: 0,
                  name: ""
                }
              ],
              rowLabels: [
                {
                  index: 0,
                  name: ""
                }
              ],
              characterLimitParam: false,
              characterLimitParamValue: 0,
              wordLimitParam: false,
              wordLimitParamValue: 0,
              autoStartRecordDurationParam: false,
              autoStartRecordDurationParamValue: 0,
              maxDurationRecordParam: false,
              maxDurationRecordParamValue: 0,
              choices: [
                {
                  id: "f6a71da6-09b6-4c74-8989-bfba1f5ba7d4",
                  name: i18n.t("trainingExam.answer24"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                },
                {
                  id: "f0a0c5cc-8e12-4980-b793-2e53db366bab",
                  name: i18n.t("trainingExam.answer25"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                },
                {
                  id: "8a2da609-1568-4e4f-9784-2db53092a2b1",
                  name: i18n.t("trainingExam.noCorrectAnswer"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                }
              ]
            }
          },
          {
            id: "35768a5b-244f-4518-ab64-ea5cce8e22a3",
            position: 3,
            type: "question",
            question: {
              id: "6e68d579-3bb6-4409-9429-abca8df26cdf",
              type: "single_choice_question",
              position: 0,
              name: i18n.t("trainingExam.nonFormattedName5"),
              formattedName: i18n.t("trainingExam.formattedName5"),
              duration: 0,
              durationLimit: false,
              numberPoint: 1,
              randomChoices: false,
              attachedFiles: [],
              columnLabels: [
                {
                  index: 0,
                  name: ""
                }
              ],
              rowLabels: [
                {
                  index: 0,
                  name: ""
                }
              ],
              characterLimitParam: false,
              characterLimitParamValue: 0,
              wordLimitParam: false,
              wordLimitParamValue: 0,
              autoStartRecordDurationParam: false,
              autoStartRecordDurationParamValue: 0,
              maxDurationRecordParam: false,
              maxDurationRecordParamValue: 0,
              choices: [
                {
                  id: "20ececed-986f-4bd7-8c7f-2761eb100d9f",
                  name: i18n.t("trainingExam.answer26"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                },
                {
                  id: "2d0ed7ad-2047-4048-94ce-1de8d03731f4",
                  name: i18n.t("trainingExam.answer27"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                },
                {
                  id: "4c71eba6-b5bb-4338-8180-7e8079066df1",
                  name: i18n.t("trainingExam.noCorrectAnswer"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                }
              ]
            }
          }
        ],
        attachedFiles: []
      },
      {
        id: "9373b910-82b4-4896-a575-01d748909ade",
        name: i18n.t("trainingExam.name3"),
        instruction: i18n.t("trainingExam.instructions4"),
        numberPoint: 8,
        duration: 0,
        durationLimit: false,
        position: 2,
        randomPartIndexes: false,
        partIndexes: [
          {
            id: "a4e7f040-fad0-40ee-9c75-2faae72fbbd8",
            position: 0,
            type: "question",
            question: {
              id: "8a7be079-9e48-4a7a-a4f7-44d368c90e1b",
              type: "single_choice_question",
              position: 0,
              name: i18n.t("trainingExam.nonFormattedName6"),
              formattedName: i18n.t("trainingExam.formattedName6"),
              duration: 0,
              durationLimit: false,
              numberPoint: 1,
              randomChoices: false,
              attachedFiles: [
                {
                  id: TRAINING_IMG1
                }
              ],
              columnLabels: [
                {
                  index: 0,
                  name: ""
                }
              ],
              rowLabels: [
                {
                  index: 0,
                  name: ""
                }
              ],
              characterLimitParam: false,
              characterLimitParamValue: 0,
              wordLimitParam: false,
              wordLimitParamValue: 0,
              autoStartRecordDurationParam: false,
              autoStartRecordDurationParamValue: 0,
              maxDurationRecordParam: false,
              maxDurationRecordParamValue: 0,
              choices: [
                {
                  id: "5237461f-0d0c-41fd-8e57-7f30684cfccd",
                  name: i18n.t("trainingExam.answer28"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                },
                {
                  id: "7666e4e7-a026-4c0d-b87d-e6a9aa158780",
                  name: i18n.t("trainingExam.answer29"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                },
                {
                  id: "b498916b-4c86-4cf3-a3e2-25fbfcf6ca7e",
                  name: i18n.t("trainingExam.answer30"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                },
                {
                  id: "e658414a-9a84-42a1-a02a-9344c9aa4c1c",
                  name: i18n.t("trainingExam.noCorrectAnswer"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                }
              ]
            }
          },
          {
            id: "fd9e04a4-8b89-4e21-95d8-bf19e3a76693",
            position: 1,
            type: "question",
            question: {
              id: "8f2e242d-c0ca-406f-9448-30072f0c1a13",
              type: "spreadsheet_question",
              position: 0,
              name: i18n.t("trainingExam.nonFormattedName7"),
              formattedName: i18n.t("trainingExam.formattedName7"),
              duration: 0,
              durationLimit: false,
              numberPoint: 1,
              randomChoices: false,
              attachedFiles: [],
              columnLabels: [],
              rowLabels: [],
              characterLimitParam: false,
              characterLimitParamValue: 0,
              wordLimitParam: false,
              wordLimitParamValue: 0,
              text: "",
              autoStartRecordDurationParam: false,
              autoStartRecordDurationParamValue: 0,
              maxDurationRecordParam: false,
              maxDurationRecordParamValue: 0,
              spreadsheetFile: {
                id: TRAINING_SPREADSHEET
              },
              choices: [
                {
                  id: "6908d32f-ebdf-411f-8b41-f0c8a8df27c4",
                  name: i18n.t("trainingExam.noCorrectAnswer"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                }
              ]
            }
          },
          {
            id: "d048f3bf-00c9-4178-b659-f515a27cf50c",
            position: 2,
            type: "question",
            question: {
              id: "f95781ea-7589-49b3-af89-89a4e0551f62",
              type: "multiple_choice_question",
              position: 0,
              name: i18n.t("trainingExam.nonFormattedName8"),
              formattedName: i18n.t("trainingExam.formattedName8"),
              duration: 0,
              durationLimit: false,
              numberPoint: 1,
              randomChoices: false,
              attachedFiles: [
                {
                  id: TRAINING_PDF
                }
              ],
              columnLabels: [],
              rowLabels: [],
              characterLimitParam: false,
              characterLimitParamValue: 0,
              wordLimitParam: false,
              wordLimitParamValue: 0,
              text: "",
              autoStartRecordDurationParam: false,
              autoStartRecordDurationParamValue: 0,
              maxDurationRecordParam: false,
              maxDurationRecordParamValue: 0,
              choices: [
                {
                  id: "419fcf43-2079-47f4-96ba-dac968377f9d",
                  name: i18n.t("trainingExam.answer31"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                },
                {
                  id: "f69e1432-2ce5-44bc-9ad8-5b5dc6f76830",
                  name: i18n.t("trainingExam.answer32"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                },
                {
                  id: "00f5434f-0a85-4307-b246-3c67f1255320",
                  name: i18n.t("trainingExam.answer33"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                },
                {
                  id: "dfa8911b-8dec-4d76-9e6c-c54ed6056dc7",
                  name: i18n.t("trainingExam.noCorrectAnswer"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                }
              ]
            }
          },
          {
            id: "080fa41c-3a95-4e59-bbe8-e6d122230baa",
            position: 3,
            type: "question",
            question: {
              id: "2b7c9e5a-4251-48a1-b532-d4fdc66e61b9",
              type: "multiple_choice_question",
              position: 0,
              name: i18n.t("trainingExam.nonFormattedName9"),
              formattedName: i18n.t("trainingExam.formattedName9"),
              duration: 0,
              durationLimit: false,
              numberPoint: 1,
              randomChoices: false,
              attachedFiles: [
                {
                  id: TRAINING_IMG2
                }
              ],
              columnLabels: [],
              rowLabels: [],
              characterLimitParam: false,
              characterLimitParamValue: 0,
              wordLimitParam: false,
              wordLimitParamValue: 0,
              text: "",
              autoStartRecordDurationParam: false,
              autoStartRecordDurationParamValue: 0,
              maxDurationRecordParam: false,
              maxDurationRecordParamValue: 0,
              choices: [
                {
                  id: "7563837e-b4b9-425d-be0a-f65d4c3652bd",
                  name: i18n.t("trainingExam.answer34"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                },
                {
                  id: "cdbeeb04-44f5-4111-a04f-5da446e87ee7",
                  name: i18n.t("trainingExam.answer35"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                },
                {
                  id: "6ba8d82a-ff48-4fc9-a135-eb9f5bf5afbc",
                  name: i18n.t("trainingExam.noCorrectAnswer"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                }
              ]
            }
          },
          {
            id: "7ddc3527-4fd9-4348-80cd-1ae8fc219f0b",
            position: 4,
            type: "question",
            question: {
              id: "ae9184bb-4038-4d60-9daf-2294a3e5a354",
              type: "multiple_choice_question",
              position: 0,
              name: i18n.t("trainingExam.nonFormattedName10"),
              formattedName: i18n.t("trainingExam.formattedName10"),
              duration: 0,
              durationLimit: false,
              numberPoint: 1,
              randomChoices: false,
              attachedFiles: [
                {
                  id: TRAINING_IMG3
                }
              ],
              columnLabels: [],
              rowLabels: [],
              characterLimitParam: false,
              characterLimitParamValue: 0,
              wordLimitParam: false,
              wordLimitParamValue: 0,
              text: "",
              autoStartRecordDurationParam: false,
              autoStartRecordDurationParamValue: 0,
              maxDurationRecordParam: false,
              maxDurationRecordParamValue: 0,
              choices: [
                {
                  id: "1034f7b4-a51e-4587-986d-aa327d3e4c58",
                  name: i18n.t("trainingExam.answer36"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                },
                {
                  id: "98c5a262-6ca1-414b-a008-e6cc1f68480d",
                  name: i18n.t("trainingExam.answer37"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                },
                {
                  id: "dd4d32a3-d36d-4919-afcc-ed73616aa3f9",
                  name: i18n.t("trainingExam.answer38"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                },
                {
                  id: "29d7321e-246f-42c0-872f-48f0e49b2dea",
                  name: i18n.t("trainingExam.noCorrectAnswer"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                }
              ]
            }
          },
          {
            id: "46d8d3d9-6215-4c2c-bc4e-4b8c8919c87d",
            position: 5,
            type: "question",
            question: {
              id: "6d081451-439e-4de5-9333-f3b732aa13e8",
              type: "multiple_choice_question",
              position: 0,
              name: i18n.t("trainingExam.nonFormattedName11"),
              formattedName: i18n.t("trainingExam.formattedName11"),
              duration: 0,
              durationLimit: false,
              numberPoint: 1,
              randomChoices: false,
              attachedFiles: [],
              columnLabels: [
                {
                  index: 0,
                  name: ""
                }
              ],
              rowLabels: [
                {
                  index: 0,
                  name: ""
                }
              ],
              characterLimitParam: false,
              characterLimitParamValue: 0,
              wordLimitParam: false,
              wordLimitParamValue: 0,
              autoStartRecordDurationParam: false,
              autoStartRecordDurationParamValue: 0,
              maxDurationRecordParam: false,
              maxDurationRecordParamValue: 0,
              choices: [
                {
                  id: "e9fd7dbf-81a6-48ba-bdb1-f7d880f41c5e",
                  name: i18n.t("trainingExam.answer39"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                },
                {
                  id: "ee8c9dd9-dca9-486c-8461-93d86bc1e2a4",
                  name: i18n.t("trainingExam.answer40"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                },
                {
                  id: "c7d26ced-e128-42fe-8abc-c92d2beb125c",
                  name: i18n.t("trainingExam.noCorrectAnswer"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                }
              ]
            }
          },
          {
            id: "94e21496-40e5-45ed-a766-c28c65bd6a34",
            position: 6,
            type: "question",
            question: {
              id: "a3745548-220d-46d7-b5d7-36d834e9f21b",
              type: "audio_question",
              position: 0,
              name: i18n.t("trainingExam.nonFormattedName12"),
              formattedName: i18n.t("trainingExam.formattedName12"),
              duration: 0,
              durationLimit: false,
              numberPoint: 1,
              randomChoices: false,
              attachedFiles: [
                {
                  id: TRAINING_IMG4
                }
              ],
              columnLabels: [],
              rowLabels: [],
              characterLimitParam: false,
              characterLimitParamValue: 0,
              wordLimitParam: false,
              wordLimitParamValue: 0,
              text: "",
              autoStartRecordDurationParam: false,
              autoStartRecordDurationParamValue: 0,
              maxDurationRecordParam: false,
              maxDurationRecordParamValue: 0,
              choices: [
                {
                  id: "e55f52b3-e414-4293-8024-d6208945468e",
                  name: i18n.t("trainingExam.noCorrectAnswer"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                }
              ]
            }
          },
          {
            id: "c273130a-0c5b-4afa-a69d-5047212fdac4",
            position: 7,
            type: "question",
            question: {
              id: "94fc47d5-219c-4be8-8ff2-a51a0c72001d",
              type: "single_choice_question",
              position: 0,
              name: i18n.t("trainingExam.nonFormattedName13"),
              formattedName: i18n.t("trainingExam.formattedName13"),
              duration: 0,
              durationLimit: false,
              numberPoint: 1,
              randomChoices: false,
              attachedFiles: [
                {
                  id: TRAINING_IMG5
                }
              ],
              columnLabels: [
                {
                  index: 0,
                  name: ""
                }
              ],
              rowLabels: [
                {
                  index: 0,
                  name: ""
                }
              ],
              characterLimitParam: false,
              characterLimitParamValue: 0,
              wordLimitParam: false,
              wordLimitParamValue: 0,
              autoStartRecordDurationParam: false,
              autoStartRecordDurationParamValue: 0,
              maxDurationRecordParam: false,
              maxDurationRecordParamValue: 0,
              choices: [
                {
                  id: "c2baa78d-54ec-4761-8bde-d9265921a630",
                  name: i18n.t("trainingExam.answer41"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                },
                {
                  id: "d258fe7e-ae82-4989-96d1-3de3811818c8",
                  name: i18n.t("trainingExam.answer42"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                },
                {
                  id: "eac12227-1d45-4c07-88a5-dc3727f3b89d",
                  name: i18n.t("trainingExam.noCorrectAnswer"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                }
              ]
            }
          }
        ],
        attachedFiles: []
      },
      {
        id: "516887d9-54fb-43e1-863d-6ec8e0298d32",
        name: i18n.t("trainingExam.name4"),
        instruction: "",
        numberPoint: 1,
        duration: 0,
        durationLimit: false,
        position: 3,
        randomPartIndexes: false,
        partIndexes: [
          {
            id: "e628839c-07c1-4d49-be88-561f800cb6ed",
            position: 0,
            type: "question",
            question: {
              id: "771ee8e5-d38a-4894-b7a0-2b0481656584",
              type: "single_choice_question",
              position: 0,
              name: i18n.t("trainingExam.nonFormattedName14"),
              formattedName: i18n.t("trainingExam.formattedName14"),
              duration: 300,
              durationLimit: true,
              numberPoint: 1,
              randomChoices: false,
              attachedFiles: [
                {
                  id: TRAINING_IMG6
                }
              ],
              columnLabels: [
                {
                  index: 0,
                  name: ""
                }
              ],
              rowLabels: [
                {
                  index: 0,
                  name: ""
                }
              ],
              characterLimitParam: false,
              characterLimitParamValue: 0,
              wordLimitParam: false,
              wordLimitParamValue: 0,
              autoStartRecordDurationParam: false,
              autoStartRecordDurationParamValue: 0,
              maxDurationRecordParam: false,
              maxDurationRecordParamValue: 0,
              choices: [
                {
                  id: "f51ae793-1076-45dd-aa44-18dbcd2d5bed",
                  name: i18n.t("trainingExam.answer43"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                },
                {
                  id: "f67f5416-6da9-4575-805a-b1090fb9b76e",
                  name: i18n.t("trainingExam.answer44"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                },
                {
                  id: "295acd1e-6561-4240-b363-6f073030d8b8",
                  name: i18n.t("trainingExam.answer45"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                },
                {
                  id: "fd555386-e4ce-4b60-b265-bb4184f8b8de",
                  name: i18n.t("trainingExam.answer46"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                },
                {
                  id: "a6cd71da-6763-41ec-adf0-0b25d3f7e952",
                  name: i18n.t("trainingExam.answer47"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                },
                {
                  id: "4402e179-4cba-4b51-b035-c1e2b4a24d4d",
                  name: i18n.t("trainingExam.answer48"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                },
                {
                  id: "84f607e9-5749-4083-916b-d119eb8b3adf",
                  name: i18n.t("trainingExam.noCorrectAnswer"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                }
              ]
            }
          }
        ],
        attachedFiles: []
      },
      {
        id: "c513f229-584e-47ee-901d-562b69dd7d91",
        name: i18n.t("trainingExam.name5"),
        instruction: i18n.t("trainingExam.instructions5"),
        numberPoint: 2,
        duration: 0,
        durationLimit: false,
        position: 4,
        randomPartIndexes: false,
        partIndexes: [
          {
            id: "5dbd4f7a-6f33-449c-a313-dd874e69a9f3",
            position: 0,
            type: "question",
            question: {
              id: "d9d6ce4c-49cd-4844-b5ec-f76afa663244",
              type: "single_choice_question",
              position: 0,
              name: i18n.t("trainingExam.nonFormattedName15"),
              formattedName: i18n.t("trainingExam.formattedName15"),
              duration: 0,
              durationLimit: false,
              numberPoint: 1,
              randomChoices: false,
              attachedFiles: [
                {
                  id: TRAINING_IMG7
                }
              ],
              columnLabels: [
                {
                  index: 0,
                  name: ""
                }
              ],
              rowLabels: [
                {
                  index: 0,
                  name: ""
                }
              ],
              characterLimitParam: false,
              characterLimitParamValue: 0,
              wordLimitParam: false,
              wordLimitParamValue: 0,
              autoStartRecordDurationParam: false,
              autoStartRecordDurationParamValue: 0,
              maxDurationRecordParam: false,
              maxDurationRecordParamValue: 0,
              choices: [
                {
                  id: "fb20514f-3653-40b0-a026-0ed099cf79be",
                  name: i18n.t("trainingExam.answer49"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                },
                {
                  id: "27ab38f0-2e57-45e7-bd73-c42bfc58d7f4",
                  name: i18n.t("trainingExam.answer50"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                },
                {
                  id: "21cac1ba-741c-44ee-be20-229b11c30234",
                  name: i18n.t("trainingExam.answer51"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                },
                {
                  id: "876b495a-fbf4-4598-a16d-f966f4fe5435",
                  name: i18n.t("trainingExam.noCorrectAnswer"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                }
              ]
            }
          },
          {
            id: "98961397-8452-4968-b259-d7b2d05402be",
            position: 1,
            type: "question",
            question: {
              id: "7f3f263b-c0b6-49d9-b2e8-06523b6e1b07",
              type: "multiple_choice_question",
              position: 0,
              name: i18n.t("trainingExam.nonFormattedName16"),
              formattedName: i18n.t("trainingExam.formattedName16"),
              duration: 0,
              durationLimit: false,
              numberPoint: 1,
              randomChoices: false,
              attachedFiles: [
                {
                  id: TRAINING_IMG8
                },
                {
                  id: TRAINING_IMG9
                }
              ],
              columnLabels: [
                {
                  index: 0,
                  name: ""
                }
              ],
              rowLabels: [
                {
                  index: 0,
                  name: ""
                }
              ],
              characterLimitParam: false,
              characterLimitParamValue: 0,
              wordLimitParam: false,
              wordLimitParamValue: 0,
              autoStartRecordDurationParam: false,
              autoStartRecordDurationParamValue: 0,
              maxDurationRecordParam: false,
              maxDurationRecordParamValue: 0,
              choices: [
                {
                  id: "72b821a0-a937-4335-b7ec-ce12a8fcc058",
                  name: i18n.t("trainingExam.answer52"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                },
                {
                  id: "2116dbab-8b68-4057-b285-54fa5932c8f3",
                  name: i18n.t("trainingExam.answer53"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                },
                {
                  id: "46b542b8-462f-41f2-9179-09e299e0164e",
                  name: i18n.t("trainingExam.answer54"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                },
                {
                  id: "b21af7c7-9ceb-4c13-a1c7-a95650c0207e",
                  name: i18n.t("trainingExam.noCorrectAnswer"),
                  columnIndex: 0,
                  rowIndex: 0,
                  positionInText: ""
                }
              ]
            }
          }
        ],
        attachedFiles: []
      }
    ],
    examParams: {
      allowSpellChecker: false,
      allowCopyAndPaste: true,
      anonymousCorrection: false,
      scientificCalculator: false,
      basicCalculator: true,
      durationFixed: false,
      proctoring: false,
      openBook: true,
      allowTierTime: false,
      proctoringLive: false,
      proctoringLiveRecord: false,
      environment: false,
      web: false,
      remoteExam: false,
      showTimeOptions: false,
      showQuestionsPoints: false
    },
    questionTypes: [],
    hasTimer: true,
    createdAt: "2021-06-23T15:14:16+00:00",
    updatedAt: "2021-06-30T14:55:56+00:00",
    timeZeroSecond: 86400
  };
}
