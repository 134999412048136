import React from "react";
import { StyleSheet, View } from "react-native";
import { ipcRenderer } from "electron";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import Button from "../molecules/Button";
import i18n from "../../services/i18n";
import { COLOR_GREEN_VALID, COLOR_RED } from "../../../static/misc/colors";
import { PADDING_SIDES } from "../../../static/misc/constants";
import { startExitingApplication } from "../../modules/main/actions/status";
import { ExitApplicationAction } from "../../modules/main/types/status";

interface ButtonsConnexionProps {
  exitApplication: (action?: string) => ExitApplicationAction;
}

class ButtonsConnexion extends React.PureComponent<ButtonsConnexionProps> {
  render(): JSX.Element {
    const { exitApplication } = this.props;
    return (
      <View style={styles.viewContainerStyle}>
        <Button
          label={i18n.t("tryConnexion")}
          onPress={() => ipcRenderer.send("KC_RELOADING")}
          containerStyle={[
            {
              borderColor: COLOR_GREEN_VALID,
              backgroundColor: COLOR_GREEN_VALID
            }
          ]}
        />
        <Button
          label={i18n.t("userMenu.leave")}
          onPress={() => exitApplication()}
          containerStyle={[
            { borderColor: COLOR_RED, backgroundColor: COLOR_RED }
          ]}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  viewContainerStyle: {
    paddingTop: PADDING_SIDES,
    width: "60%",
    flexDirection: "row",
    justifyContent: "space-between"
  }
});

const mapStateToProps = () => {
  return {};
};

const mapdispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators(
      {
        exitApplication: startExitingApplication
      },
      dispatch
    )
  };
};

export default connect(mapStateToProps, mapdispatchToProps)(ButtonsConnexion);
