import React from "react";
import { StyleSheet, View, Text } from "react-native";
import i18n from "../../services/i18n";
import {
  FONTSIZE_14,
  FONT_GILROY_REGULAR,
  PADDING_SIDES
} from "../../../static/misc/constants";
import { COLOR_BLUE_TESTWE } from "../../../static/misc/colors";

export interface OnboardingUnlockExamStepProps {
  isProctoringLive: boolean;
  gettingRoom: boolean;
}

export interface OnboardingUnlockExamStepState {
  retry: boolean;
}

class OnboardingUnlockExamStep extends React.Component<
  OnboardingUnlockExamStepProps,
  OnboardingUnlockExamStepState
> {
  constructor(props: OnboardingUnlockExamStepProps) {
    super(props);

    this.state = {
      retry: false
    };
  }

  componentDidUpdate(prevProps: OnboardingUnlockExamStepProps): void {
    const { gettingRoom } = this.props;

    if (prevProps.gettingRoom !== gettingRoom) {
      this.setState({ retry: !gettingRoom });
    }
  }

  render(): JSX.Element | null {
    const { isProctoringLive, gettingRoom } = this.props;
    const { retry } = this.state;

    return (
      <View style={styles.proctoringContainer}>
        <View style={styles.titleContainer}>
          {isProctoringLive && gettingRoom ? (
            <Text style={styles.stepSubtitle}>
              {i18n.t("proctoringLive.waitingRoom")}
            </Text>
          ) : retry ? (
            <Text style={styles.stepSubtitle}>
              {i18n.t("proctoringLive.waitingRoomRetry")}
            </Text>
          ) : (
            <Text style={styles.stepSubtitle}>
              {i18n.t("onboardingModal.unlock_exam_step_subtitle")}
            </Text>
          )}
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  titleContainer: {
    paddingVertical: PADDING_SIDES * 0.2,
    alignItems: "center"
  },
  stepSubtitle: {
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_14,
    color: COLOR_BLUE_TESTWE,
    paddingBottom: PADDING_SIDES * 0.2,
    paddingHorizontal: PADDING_SIDES * 0.2
  },
  proctoringContainer: {
    alignItems: "center",
    minHeight: 200,
    marginBottom: 15,
    width: "100%"
  }
});
export default OnboardingUnlockExamStep;
