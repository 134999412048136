import React from "react";
import { Image, StyleSheet, Text, View } from "react-native";
import {
  COLOR_BLUE_TESTWE,
  COLOR_GREY_BACKGROUND
} from "../../../static/misc/colors";
import {
  FONTSIZE_14,
  FONT_GILROY_REGULAR,
  PADDING_SIDES
} from "../../../static/misc/constants";

interface InstructionItemProps {
  icon: any;
  title: string;
}

class InstructionItem extends React.PureComponent<InstructionItemProps> {
  render(): JSX.Element {
    const { icon, title } = this.props;
    return (
      <View style={styles.container}>
        <View style={styles.card}>
          <Image source={icon} style={styles.icon} />
        </View>
        <Text style={styles.title}>{title}</Text>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    width: 200
  },
  card: {
    backgroundColor: COLOR_GREY_BACKGROUND,
    width: 100,
    height: 82,
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 20,
    marginBottom: PADDING_SIDES * 0.1
  },
  icon: {
    width: 50,
    height: 50,
    resizeMode: "contain"
  },
  title: {
    fontSize: FONTSIZE_14,
    fontFamily: FONT_GILROY_REGULAR,
    color: COLOR_BLUE_TESTWE,
    alignSelf: "center",
    textAlign: "center"
  }
});

export default InstructionItem;
