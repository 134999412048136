import React from "react";
import { StyleSheet, View, Text } from "react-native";
import ReactPlayer from "react-player";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import i18n from "../../services/i18n";
import {
  FONTSIZE_14,
  FONT_GILROY_REGULAR,
  MIME_MP3,
  PADDING_SIDES
} from "../../../static/misc/constants";
import { COLOR_BLUE_TESTWE } from "../../../static/misc/colors";
import { ExamOnboardingStep } from "../../modules/exams/types/exam";
import { b64toblob } from "../../../static/misc/utils";
import mockMp3 from "../../../static/misc/mockmp3";
import AudioExam from "./AudioExam";
import { getError } from "../../modules/main/actions/error";
import { GetErrorAction } from "../../modules/main/types/error";

const MIN_VOLUME = 0.5;

export interface OnboardingMicroStepProps {
  currentOnboardingStep: ExamOnboardingStep;
  getError: (errorMessage: string, forceLogout: boolean) => GetErrorAction;
  updateCurrentStepInfo: (
    data?: string | Blob | null,
    status?: boolean
  ) => void;
  onEquipmentValidated: (microphone: boolean, sound: boolean) => void;
}

interface OnboardingMicroStepState {
  hasListenedSound: boolean;
  hasOutputVolume: boolean;
}

class OnboardingMicroStepView extends React.PureComponent<
  OnboardingMicroStepProps,
  OnboardingMicroStepState
> {
  maxAudioAverageValue = -1;

  javascriptNode?: ScriptProcessorNode;

  mediaStream?: MediaStream;

  mockMp3Url: string;

  constructor(props: OnboardingMicroStepProps) {
    super(props);
    this.state = {
      hasListenedSound: false,
      hasOutputVolume: false
    };
    this.mockMp3Url = window.URL.createObjectURL(b64toblob(mockMp3, MIME_MP3));
  }

  componentWillUnmount(): void {
    this.stopCheckingOutputSound();
  }

  onRecordEnded(): void {
    // eslint-disable-next-line no-shadow
    const { getError, updateCurrentStepInfo } = this.props;
    const { hasListenedSound } = this.state;

    this.stopCheckingOutputSound();

    if (this.maxAudioAverageValue > MIN_VOLUME) {
      this.setState({
        hasOutputVolume: true
      });
      this.sendEquimentResults(true, hasListenedSound);
      updateCurrentStepInfo("", true);
    } else {
      getError(i18n.t("errors.microphoneVolumeError"), false);
    }
  }

  onSoundPlayed(): void {
    const { hasOutputVolume } = this.state;

    this.setState({
      hasListenedSound: true
    });

    this.sendEquimentResults(hasOutputVolume, true);
  }

  sendEquimentResults(microphone: boolean, sound: boolean): void {
    const { onEquipmentValidated } = this.props;
    onEquipmentValidated(microphone, sound);
  }

  startCheckingOutputSound(): void {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then((stream) => {
        this.mediaStream = stream;
        const audioContext = new AudioContext();
        const analyser = audioContext.createAnalyser();
        const microphone = audioContext.createMediaStreamSource(stream);
        this.javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);

        analyser.smoothingTimeConstant = 0.8;
        analyser.fftSize = 1024;

        microphone.connect(analyser);
        analyser.connect(this.javascriptNode);
        this.javascriptNode.connect(audioContext.destination);

        const startTime = new Date().getTime();

        this.javascriptNode.onaudioprocess = () => {
          const array = new Uint8Array(analyser.frequencyBinCount);

          analyser.getByteFrequencyData(array);

          let values = 0;

          const { length } = array;

          for (let i = 0; i < length; i++) {
            values += array[i];
          }

          const average = values / length;

          // we wait for the countdown to end
          if (new Date().getTime() - startTime > 3000) {
            if (average > this.maxAudioAverageValue) {
              this.maxAudioAverageValue = average;
            }
          }
        };
      });
  }

  stopCheckingOutputSound(): void {
    if (this.javascriptNode) {
      this.javascriptNode.onaudioprocess = null;
      this.javascriptNode.disconnect();
    }

    if (this.mediaStream) {
      this.mediaStream.getTracks().forEach((track) => {
        if (track.readyState === "live") {
          track.stop();
        }
      });
    }
  }

  render(): JSX.Element | null {
    const { currentOnboardingStep } = this.props;
    return (
      <View style={styles.proctoringContainer}>
        <View style={styles.titleContainer}>
          <Text style={styles.stepSubtitle}>
            {i18n.t(`onboardingModal.testSound`)} :
          </Text>
        </View>
        <View style={styles.playerContainer}>
          <ReactPlayer
            onStart={() => this.onSoundPlayed()}
            url={this.mockMp3Url}
            width={250}
            height={40}
            controls
            config={{
              file: { attributes: { controlsList: "nodownload" } }
            }}
          />
        </View>
        {currentOnboardingStep.subtitle &&
          currentOnboardingStep.subtitle !== "" && (
            <View style={styles.titleContainer}>
              <Text style={styles.stepSubtitle}>
                {i18n.t(`onboardingModal.${currentOnboardingStep.subtitle}`)} :
              </Text>
            </View>
          )}
        <AudioExam
          containerStyle={[styles.recordExamWidth]}
          toggleAudioProctoring={() => undefined}
          reload
          recordStarted={() => this.startCheckingOutputSound()}
          recordEnded={() => this.onRecordEnded()}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  titleContainer: {
    paddingVertical: PADDING_SIDES * 0.2,
    alignItems: "center"
  },
  playerContainer: {
    marginBottom: PADDING_SIDES * 0.2
  },
  stepSubtitle: {
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_14,
    color: COLOR_BLUE_TESTWE,
    paddingBottom: PADDING_SIDES * 0.2
  },
  recordExamWidth: {
    width: 300
  },
  proctoringContainer: {
    alignItems: "center",
    minHeight: 200,
    marginBottom: 15,
    width: "100%"
  }
});

const mapdispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators(
      {
        getError
      },
      dispatch
    )
  };
};

const OnboardingMicroStep = connect(
  null,
  mapdispatchToProps
)(OnboardingMicroStepView);

export default OnboardingMicroStep;
