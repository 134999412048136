import React from "react";
import { Text, StyleSheet, View } from "react-native";
import {
  COLOR_BLUE,
  COLOR_BLUE_TESTWE,
  COLOR_RED,
  COLOR_WHITE
} from "../../../static/misc/colors";
import {
  FONTSIZE_14,
  FONTSIZE_16,
  FONT_GILROY_BOLD,
  FONT_GILROY_REGULAR,
  PADDING_SIDES,
  WIDTH_6,
  WIDTH_8
} from "../../../static/misc/constants";
import i18n from "../../services/i18n";
import CustomModal from "../atoms/CustomModal";
import YellowBanner from "../atoms/YellowBanner";
import { ChangeEnum } from "../organisms/ExamTakingContent";
import Button from "./Button";

export interface ExamTakingRemainingDurationWarningModalProps {
  typeOfChangeQuestion: ChangeEnum;
  isVisible: boolean;
  onNavigate: (changeType: ChangeEnum) => void;
  onClose: () => void;
}

export default class ExamTakingRemainingDurationWarningModal extends React.Component<ExamTakingRemainingDurationWarningModalProps> {
  shouldComponentUpdate(
    nextProps: ExamTakingRemainingDurationWarningModalProps
  ): boolean {
    const { isVisible } = this.props;
    return nextProps.isVisible !== isVisible;
  }

  render(): JSX.Element {
    const { isVisible, typeOfChangeQuestion, onNavigate, onClose } = this.props;

    return (
      <CustomModal
        heightModal="20%"
        widthModal="40%"
        borderRadiusModal={5}
        showCloseButton={false}
        visible={isVisible}
        onCloseModal={() => onClose()}
      >
        <View style={styles.contentContainer}>
          <View style={styles.titleContainer}>
            <Text style={styles.titleText}>
              {i18n.t("timerModal.remainingDurationTitle")}
            </Text>
          </View>
          <View style={styles.labelContainer}>
            <Text style={styles.labelText}>
              {i18n.t("timerModal.remainingDurationLabel1")}
            </Text>
            <Text style={styles.labelText}>
              {i18n.t("timerModal.remainingDurationLabel2")}
            </Text>
            <YellowBanner
              textBanner={i18n.t("timerModal.remainingDurationWarning")}
            />
          </View>
          <View style={styles.buttonsContainer}>
            <Button
              containerStyle={[styles.confirmButton]}
              label={
                typeOfChangeQuestion === ChangeEnum.FINISHEXAM
                  ? i18n.t("syncModal.endExamMode")
                  : i18n.t("timerModal.remainingDurationButtonNext")
              }
              onPress={() => {
                onNavigate(typeOfChangeQuestion);
                onClose();
              }}
            />
            <Button
              containerStyle={[styles.cancelButton]}
              textStyle={styles.cancelButtonText}
              label={i18n.t("timerModal.remainingDurationButtonStop")}
              onPress={() => {
                onClose();
              }}
            />
          </View>
        </View>
      </CustomModal>
    );
  }
}

const styles = StyleSheet.create({
  contentContainer: {
    flexGrow: 1,
    flexDirection: "column",
    paddingTop: PADDING_SIDES * 0.2
  },
  titleContainer: {
    flex: 1,
    paddingVertical: PADDING_SIDES * 0.2,
    paddingHorizontal: PADDING_SIDES * 0.5,
    justifyContent: "center",
    alignItems: "center",
    minHeight: "fit-content"
  },
  titleText: {
    fontFamily: FONT_GILROY_BOLD,
    fontSize: FONTSIZE_16,
    color: COLOR_BLUE,
    textAlign: "center",
    width: WIDTH_8
  },
  labelContainer: {
    flex: 1,
    paddingVertical: PADDING_SIDES * 0.2,
    paddingHorizontal: PADDING_SIDES * 0.5,
    justifyContent: "center",
    alignItems: "center",
    minHeight: "fit-content"
  },
  labelText: {
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_14,
    color: COLOR_BLUE_TESTWE,
    textAlign: "center",
    width: WIDTH_8,
    marginBottom: PADDING_SIDES * 0.2
  },
  buttonsContainer: {
    flex: 2,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: PADDING_SIDES * 0.2
  },
  confirmButton: {
    borderWidth: 1,
    borderColor: COLOR_RED,
    borderStyle: "solid",
    width: WIDTH_6
  },
  cancelButton: {
    borderWidth: 1,
    borderColor: COLOR_BLUE_TESTWE,
    borderStyle: "solid",
    backgroundColor: COLOR_WHITE,
    marginTop: PADDING_SIDES * 0.2,
    width: WIDTH_6
  },
  cancelButtonText: {
    color: COLOR_BLUE_TESTWE
  }
});
