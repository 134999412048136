import React from "react";
import { StyleSheet, View, Text, TouchableOpacity } from "react-native";
import i18n from "../../services/i18n";
import Button from "../molecules/Button";
import {
  ASPECT_RATIO,
  FONTSIZE_11,
  FONTSIZE_24,
  FONT_GILROY_BOLD,
  FONT_GILROY_LIGHT,
  FONT_GILROY_REGULAR,
  PADDING_SIDES,
  WIDTH_3,
  WIDTH_6
} from "../../../static/misc/constants";
import {
  COLOR_BLUE,
  COLOR_BLUE_TESTWE,
  COLOR_GREY_BORDER,
  COLOR_RED,
  COLOR_RED_50,
  COLOR_WHITE
} from "../../../static/misc/colors";
import LightText from "../atoms/LightText";
import CustomInput from "../atoms/CustomInput";
import { checkIcon, lock } from "../../../static/misc/images";
import Icon from "../atoms/Icon";
import RadioButton from "../atoms/RadioButton";
import Hover from "../atoms/Hover";
import Loader from "../atoms/Loader";

export interface TokenItemProps {
  defaultPolicyAccept: boolean;
  examLaunching: boolean;
  onPress: (userInput: string) => void;
}
interface TokenItemState {
  policyAccept: boolean;
  userInput: string;
}

class TokenItem extends React.PureComponent<TokenItemProps, TokenItemState> {
  constructor(props: TokenItemProps) {
    super(props);
    const { defaultPolicyAccept } = this.props;
    this.state = {
      policyAccept: defaultPolicyAccept,
      userInput: ""
    };
  }

  // Retrieving the token manually entered by the user
  getUserInput(input: string): void {
    this.setState({
      userInput: input
    });
  }

  // Updating the status of the agreement acceptance
  policyState(): void {
    const { policyAccept } = this.state;
    this.setState({
      policyAccept: !policyAccept
    });
  }

  render(): JSX.Element {
    const { onPress, examLaunching } = this.props;
    const { policyAccept, userInput } = this.state;

    return (
      <View style={styles.defaultContainer}>
        <View style={styles.inputIconContainer}>
          <View style={styles.textContainer}>
            <LightText
              text={i18n.t("tokenModal.examCode")}
              textStyle={[styles.getTokenTextStyle]}
            />
          </View>
          <View>
            <Icon icon={lock} iconStyle={styles.lockIconStyle} />
          </View>
          <View style={styles.border}>
            <CustomInput
              autoCapitalize
              fontFamily={FONT_GILROY_LIGHT}
              height={25}
              placeholder=""
              bottom={-5}
              left={ASPECT_RATIO * 70}
              borderColor={COLOR_WHITE}
              borderRadius={3}
              borderWidth={1.5}
              fontSize={FONTSIZE_24}
              color={COLOR_BLUE}
              minHeight={0}
              paddingHorizontal={0}
              padding={0}
              paddingVertical={0}
              width={150 * ASPECT_RATIO * 2}
              onChangeText={(input) => this.getUserInput(input)}
            />
          </View>
          <View style={styles.hover}>
            <Hover
              text={i18n.t("tokenModal.info")}
              containerStyle={styles.infoIconStyle}
            />
          </View>
          {userInput.length >= 1 ? (
            <Icon icon={checkIcon} iconStyle={styles.checkIconStyle} />
          ) : null}
        </View>
        <TouchableOpacity
          onPress={() => this.policyState()}
          style={styles.singleChoiceStyle}
        >
          <RadioButton isSelected={policyAccept} />
          <Text style={styles.policyTextSTyle}>
            {i18n.t("tokenModal.policy")}&nbsp;
            <Text style={styles.policyBold}>
              {i18n.t("tokenModal.policy2")}
            </Text>
          </Text>
        </TouchableOpacity>
        {(examLaunching && <Loader />) || (
          <View style={styles.buttonStartContainer}>
            {policyAccept ? (
              <Button
                onPress={() => onPress(userInput)}
                label={i18n.t("tokenModal.confirmStart")}
                containerStyle={[styles.containerButtonStartExamStyle]}
              />
            ) : (
              <Button
                onPress={() => null}
                disabled
                label={i18n.t("tokenModal.confirmStart")}
                containerStyle={[styles.containerButtonDisableStartExamStyle]}
              />
            )}
          </View>
        )}
      </View>
    );
  }
}
const styles = StyleSheet.create({
  defaultContainer: {
    marginVertical: PADDING_SIDES * 0.5
  },
  buttonStartContainer: {
    marginVertical: -ASPECT_RATIO * 0.01,
    marginHorizontal: ASPECT_RATIO * 280
  },
  containerButtonDisableStartExamStyle: {
    borderRadius: 15,
    bordercolor: COLOR_RED_50,
    backgroundColor: COLOR_RED_50,
    width: "100%",
    height: 30
  },
  containerButtonStartExamStyle: {
    bordercolor: COLOR_RED,
    backgroundColor: COLOR_RED,
    borderRadius: 15,
    width: "100%",
    height: 30
  },
  getTokenTextStyle: {
    fontSize: FONTSIZE_11,
    marginVertical: ASPECT_RATIO * 10
  },
  policyTextSTyle: {
    paddingHorizontal: PADDING_SIDES * 0.2,
    fontSize: FONTSIZE_11,
    fontFamily: FONT_GILROY_REGULAR,
    color: COLOR_BLUE_TESTWE
  },
  policyBold: {
    fontSize: FONTSIZE_11,
    fontFamily: FONT_GILROY_BOLD,
    color: COLOR_BLUE_TESTWE
  },
  singleChoiceStyle: {
    marginVertical: ASPECT_RATIO * 40,
    width: "100%",
    flexDirection: "row",
    justifyContent: "center"
  },
  infoIconStyle: {
    marginVertical: "unset",
    marginHorizontal: "unset",
    paddingHorizontal: PADDING_SIDES * 0.1,
    height: "unset",
    flex: 1
  },
  border: {
    width: WIDTH_6,
    borderWidth: 1.5,
    borderColor: COLOR_GREY_BORDER
  },
  hover: {
    width: WIDTH_3
  },
  checkIconStyle: {
    height: 11,
    width: 17,
    marginHorizontal: -ASPECT_RATIO * 300,
    marginBottom: PADDING_SIDES * 0.02
  },
  lockIconStyle: {
    width: 17,
    height: 24,
    marginVertical: ASPECT_RATIO * 15,
    marginRight: -ASPECT_RATIO * 65
  },
  inputIconContainer: {
    borderRadius: 3,
    flexDirection: "row",
    height: 45,
    width: "100%",
    marginVertical: PADDING_SIDES * 0.2
  },
  textContainer: {
    width: WIDTH_3,
    paddingRight: PADDING_SIDES * 0.2,
    alignItems: "flex-end"
  }
});
export default TokenItem;
