export const DOWNLOAD_TEST_FILE = "DOWNLOAD_TEST_FILE";
export const DOWNLOAD_TEST_FILE_SUCCESS = "DOWNLOAD_TEST_FILE_SUCCESS";
export const DOWNLOAD_TEST_FILE_FAIL = "DOWNLOAD_TEST_FILE_FAIL";

export const DOWNLOAD_PROGRESS = "DOWNLOAD_PROGRESS";
export const DOWNLOAD_PROGRESS_SUCCESS = "DOWNLOAD_PROGRESS_SUCCESS";
export const DOWNLOAD_PROGRESS_FAIL = "DOWNLOAD_PROGRESS_FAIL";

export const UPLOAD_TEST_FILE = "UPLOAD_TEST_FILE";
export const UPLOAD_TEST_FILE_SUCCESS = "UPLOAD_TEST_FILE_SUCCESS";
export const UPLOAD_TEST_FILE_FAIL = "UPLOAD_TEST_FILE_FAIL";

export const UPLOAD_PROGRESS = "UPLOAD_PROGRESS";
export const UPLOAD_PROGRESS_SUCCESS = "UPLOAD_PROGRESS_SUCCESS";
export const UPLOAD_PROGRESS_FAIL = "UPLOAD_PROGRESS_FAIL";

export interface ConnectionTestFileAction {
  type:
    | typeof DOWNLOAD_TEST_FILE
    | typeof DOWNLOAD_TEST_FILE_SUCCESS
    | typeof DOWNLOAD_TEST_FILE_FAIL
    | typeof DOWNLOAD_PROGRESS
    | typeof DOWNLOAD_PROGRESS_SUCCESS
    | typeof DOWNLOAD_PROGRESS_FAIL
    | typeof UPLOAD_TEST_FILE
    | typeof UPLOAD_TEST_FILE_SUCCESS
    | typeof UPLOAD_TEST_FILE_FAIL
    | typeof UPLOAD_PROGRESS
    | typeof UPLOAD_PROGRESS_SUCCESS
    | typeof UPLOAD_PROGRESS_FAIL;
  payload: any;
  meta?: any;
}

export enum NetworkTestStatus {
  pending,
  started,
  finished
}

export interface ConnectionTestingState {
  downloadCompletion: number;
  downloadSpeed: number;
  uploadCompletion: number;
  uploadSpeed: number;
  testStatus: NetworkTestStatus;
}

export type ConnectionTestingActionsType = ConnectionTestFileAction;
