import React from "react";
import { StyleSheet, View } from "react-native";
import SeeExam from "../organisms/SeeExam";
import SeeComment from "../atoms/SeeComment";
import { GetMyPaperBase64Action } from "../../modules/exams/types/exams";

interface ButtonsPassedExamProps {
  hasRemark: string | undefined;
  isUsable: boolean;
  examId: string;
  blobUrl?: string;
  base64pdf?: string;
  getMyPaperBase64: () => GetMyPaperBase64Action;
}

class ButtonsPassedExam extends React.PureComponent<ButtonsPassedExamProps> {
  render(): JSX.Element {
    const {
      hasRemark,
      examId,
      isUsable,
      blobUrl,
      base64pdf,
      getMyPaperBase64
    } = this.props;
    return (
      <View key={`container-see-${examId}`} style={styles.container}>
        <SeeComment hasRemark={hasRemark} />
        <SeeExam
          key={`see-${examId}`}
          blobUrl={blobUrl}
          base64pdf={base64pdf}
          isUsable={isUsable}
          examId={examId}
          getMyPaperBase64={getMyPaperBase64}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    width: 120,
    alignSelf: "center",
    justifyContent: "space-between"
  }
});

export default ButtonsPassedExam;
