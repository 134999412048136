import React from "react";
import { StyleSheet, View, ScrollView } from "react-native";
import { COLOR_WHITE } from "../../../../static/misc/colors";
import { PADDING_SIDES, WIN } from "../../../../static/misc/constants";
import { DisplayedExamsEnum } from "../../../modules/exams/ExamsView";
import {
  UpdateDisplayedExamsAction,
  GetMyPaperBase64Action
} from "../../../modules/exams/types/exams";
import { MainNameUserProps } from "../../../modules/main/types/MainTypes";
import i18n from "../../../services/i18n";
import WelcomeTxt from "../../atoms/WelcomeTxt";
import PassedExams from "./PassedExams";
import TopBodyHomepage from "./TopBodyHomepage";
import UpcomingExams from "./UpcomingExams";
import { ExamType } from "../../../modules/exams/types/exam";
import { StackNavigatorProp } from "../../../router/StackNavigator";
import { MediaObjectType } from "../../../modules/exams/types/attachedfiles";
import {
  EstablishmentsType,
  SetEstablishmentAction
} from "../../../modules/main/types/user";
import EstablishmentSelector from "../../molecules/EstablishmentSelector";

interface HomepageProps extends MainNameUserProps {
  token: string;
  isOnline: boolean;
  locale: string;
  navigation: StackNavigatorProp;
  nextExams: Array<ExamType>;
  passedExams: Array<ExamType>;
  attachedFiles: MediaObjectType[];
  currentExamTaking: ExamType[] | undefined;
  currentEstablishment?: EstablishmentsType;
  establishments: EstablishmentsType[];
  userId: string;
  setEstablishment: (
    userToken: string,
    establishmentId: string,
    userId: string
  ) => SetEstablishmentAction;
  updateDisplayedExams: (
    examType: DisplayedExamsEnum
  ) => UpdateDisplayedExamsAction;
  displaySingleExamInfo?: (examInfoId: string) => void;
  getMyPaperBase64: (
    studentPaperId: string,
    userToken: string,
    examId: string
  ) => GetMyPaperBase64Action;
}

class Homepage extends React.PureComponent<HomepageProps> {
  componentDidUpdate(prevProps: HomepageProps): void {
    const { currentEstablishment } = this.props;
    if (prevProps.currentEstablishment?.id !== currentEstablishment?.id) {
      this.backToHomepage();
    }
  }

  backToHomepage(): void {
    const { navigation } = this.props;

    navigation.reset({
      index: 0,
      routes: [
        {
          name: "Main" as any
        }
      ]
    });
  }

  changeEstablishmentChoice(establishmentName: string): void {
    const { establishments, token, setEstablishment, userId } = this.props;
    const chosenEstablishment = establishments.find(
      (establishment) => establishment.name === establishmentName
    );
    if (chosenEstablishment && token) {
      const chosenEstablishmentId = chosenEstablishment.id;
      setEstablishment(token, chosenEstablishmentId, userId);
    }
  }

  render(): JSX.Element {
    const {
      token,
      locale,
      givenName,
      isOnline,
      familyName,
      passedExams,
      navigation,
      nextExams,
      attachedFiles,
      currentExamTaking,
      currentEstablishment,
      establishments,
      updateDisplayedExams,
      displaySingleExamInfo,
      getMyPaperBase64
    } = this.props;

    return (
      <ScrollView
        key={locale}
        style={{ backgroundColor: COLOR_WHITE }}
        contentContainerStyle={styles.container}
        stickyHeaderIndices={[0]}
      >
        <View>
          <View style={[styles.row, { alignItems: "center", zIndex: 1000 }]}>
            <WelcomeTxt
              regularTxt={`${i18n.t("homepage.hello")} `}
              boldTxt={`${givenName} ${familyName}`}
            />
            <EstablishmentSelector
              establishments={establishments}
              currentEstablishment={currentEstablishment}
              changeEstablishmentChoice={(establishmentName: string) =>
                this.changeEstablishmentChoice(establishmentName)
              }
            />
          </View>

          <TopBodyHomepage
            nextExams={nextExams}
            attachedFiles={attachedFiles}
            displaySingleExamInfo={displaySingleExamInfo}
          />
          <View style={styles.row}>
            <UpcomingExams
              navigation={navigation}
              nextExams={nextExams}
              attachedFiles={attachedFiles}
              currentExamTaking={currentExamTaking}
              isOnline={isOnline}
              updateDisplayedExams={(examType: DisplayedExamsEnum) =>
                updateDisplayedExams(examType)
              }
              displaySingleExamInfo={displaySingleExamInfo}
            />
            <PassedExams
              token={token}
              navigation={navigation}
              passedExams={passedExams}
              isOnline={isOnline}
              updateDisplayedExams={(examType: DisplayedExamsEnum) =>
                updateDisplayedExams(examType)
              }
              getMyPaperBase64={getMyPaperBase64}
            />
          </View>
        </View>
      </ScrollView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    minWidth: 0.8 * WIN.width
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: PADDING_SIDES * 0.5
  }
});

export default Homepage;
