import moment from "moment";
import React from "react";
import { Image, StyleSheet, Text, View } from "react-native";
import { connect } from "react-redux";
import { COLOR_BLUE_TESTWE } from "../../../static/misc/colors";
import {
  FONTSIZE_14,
  FONT_GILROY_REGULAR,
  PADDING_SIDES
} from "../../../static/misc/constants";
import { warning } from "../../../static/misc/images";
import { guidGenerator } from "../../../static/misc/utils";
import i18n from "../../services/i18n";
import { RootState } from "../../store/rootreducer";
import CustomModal from "../atoms/CustomModal";
import ExtraBoldTitle from "../atoms/ExtraBoldTitle";
import LoadingModal from "../organisms/LoadingModal";
import BackButton from "./BackButton";
import ExamInfoStartExam from "./ExamInfoStartExam";

export interface OnBoardingUnlockExamModalProps {
  isVisible: boolean;
  isProctoring: boolean;
  startDate: Date | string;
  showTimeOptions: boolean;
  isRemoteExam: boolean;
  onClose: (ok: boolean) => void;
  startingExam: boolean;
}

export interface OnBoardingUnlockState {
  isExamLoading: boolean;
}

class OnBoardingUnlockExamModal extends React.PureComponent<
  OnBoardingUnlockExamModalProps,
  OnBoardingUnlockState
> {
  constructor(props: OnBoardingUnlockExamModalProps) {
    super(props);
    this.state = {
      isExamLoading: false
    };
  }

  render(): JSX.Element {
    const {
      isVisible,
      startDate,
      showTimeOptions,
      onClose,
      isProctoring,
      isRemoteExam,
      startingExam
    } = this.props;
    const { isExamLoading } = this.state;
    if (!isRemoteExam) {
      if (!startingExam) {
        onClose(true);
      }
      return <View />;
    }

    return isExamLoading || startingExam ? (
      <LoadingModal
        isVisible={isExamLoading}
        loadingMessage={i18n.t("loadingExamModal")}
      />
    ) : (
      <CustomModal
        heightModal="25%"
        widthModal="50%"
        borderRadiusModal={5}
        showCloseButton
        visible={isVisible}
        onCloseModal={() => onClose(false)}
      >
        <View style={styles.modalContentContainer}>
          <Text style={styles.stepSubtitle}>
            {i18n.t("onboardingModal.unlock_exam_step_subtitle")}
          </Text>
          <View style={{ flexDirection: "row" }}>
            <Image style={styles.image} source={warning} />
            <ExtraBoldTitle
              text={i18n.t("onboardingModal.unlock_exam_step_description")}
            />
          </View>
          <View style={styles.buttons}>
            <BackButton
              navigation={() => onClose(false)}
              label={i18n.t("exam.back")}
            />
            <ExamInfoStartExam
              key={guidGenerator()}
              label={i18n.t("exam.startExam")}
              totalFiles={0}
              downloadedFiles={0}
              canStartExam
              delayExpired={false}
              startLimitHour={moment(startDate).format("HH[h]mm")}
              onPress={() => {
                onClose(true);
                if (isProctoring) this.setState({ isExamLoading: true });
              }}
              showTimeOptions={showTimeOptions}
            />
          </View>
        </View>
      </CustomModal>
    );
  }
}

const styles = StyleSheet.create({
  modalContentContainer: {
    flex: 1,
    height: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 20,
    paddingHorizontal: 20
  },
  stepSubtitle: {
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_14,
    color: COLOR_BLUE_TESTWE,
    paddingBottom: PADDING_SIDES * 0.2,
    textAlign: "center"
  },
  image: {
    width: 16,
    heigth: 16,
    resizeMode: "contain",
    marginRight: PADDING_SIDES * 0.2
  },
  buttons: {
    paddingTop: PADDING_SIDES * 0.4,
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%"
  }
});

export default connect((state: RootState) => {
  return {
    startingExam: state.status.isExamStarting
  };
})(OnBoardingUnlockExamModal);
