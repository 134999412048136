import React from "react";
import { StyleSheet, Image, TouchableOpacity } from "react-native";
import { COLOR_BLUE_TESTWE } from "../../../static/misc/colors";
import { copie } from "../../../static/misc/images";

interface SeeExamButtonProps {
  disabled: boolean;
  onPress: () => void;
}

class SeeExamButton extends React.PureComponent<SeeExamButtonProps> {
  render(): JSX.Element {
    const { disabled, onPress } = this.props;
    return (
      <TouchableOpacity
        disabled={disabled}
        onPress={onPress}
        style={[styles.container, disabled ? { opacity: 0.3 } : {}]}
      >
        <Image source={copie} style={styles.images} />
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    width: 48,
    height: 48,
    borderStyle: "dotted",
    borderRadius: 50,
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 0.5,
    borderColor: COLOR_BLUE_TESTWE
  },
  images: {
    width: 19,
    height: 21
  }
});

export default SeeExamButton;
