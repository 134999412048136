import { ipcRenderer } from "electron";
import React from "react";

import { View, StyleSheet } from "react-native";
import { COLOR_BLUE_TESTWE } from "../../../static/misc/colors";
import ConnexionTemplate from "../../atomic/templates/authentification/ConnexionTemplate";

interface NoConnexionPageProps {
  isOnline: boolean;
}
class NoConnexionPage extends React.Component<NoConnexionPageProps> {
  async componentDidMount(): Promise<void> {
    ipcRenderer.send("LOG_INFO", "PAGEMOVE - NoConnexionPage");
  }

  shouldComponentUpdate(nextProps: NoConnexionPageProps): boolean {
    const { isOnline } = this.props;
    return isOnline && !nextProps.isOnline;
  }

  render(): JSX.Element | null {
    const { isOnline } = this.props;
    if (isOnline) return null;
    return (
      <View style={styles.container}>
        <ConnexionTemplate />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: COLOR_BLUE_TESTWE
  }
});

export default NoConnexionPage;
