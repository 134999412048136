import React from "react";
import { View, StyleSheet, Text, TextInput } from "react-native";
import {
  COLOR_BLUE_TESTWE,
  COLOR_BLUE_TESTWE_30,
  COLOR_GREY_BACKGROUND,
  COLOR_RED,
  COLOR_WHITE
} from "../../../static/misc/colors";
import {
  FONTSIZE_16,
  FONTSIZE_24,
  FONT_GILROY_BOLD,
  FONT_GILROY_REGULAR,
  PADDING_SIDES,
  WIDTH_10,
  WIDTH_2,
  WIDTH_5,
  WIDTH_6,
  WIDTH_8
} from "../../../static/misc/constants";
import { checkIcon } from "../../../static/misc/images";
import i18n from "../../services/i18n";
import Icon from "../atoms/Icon";
import YellowBanner from "../atoms/YellowBanner";
import Button from "./Button";

export interface ExamTakingConfirmModalItemProps {
  showCloseButton: boolean;
  onClose: () => void;
  onSend: () => void;
}

export interface ExamTakingConfirmModalItemState {
  enableSendButton: boolean;
}

export default class ExamTakingConfirmModalItem extends React.PureComponent<
  ExamTakingConfirmModalItemProps,
  ExamTakingConfirmModalItemState
> {
  constructor(props: ExamTakingConfirmModalItemProps) {
    super(props);

    this.state = {
      enableSendButton: false
    };
  }

  render(): JSX.Element {
    const { showCloseButton, onClose, onSend } = this.props;
    const { enableSendButton } = this.state;

    return (
      <View style={styles.contentContainer}>
        <View style={styles.confirmTextContainer}>
          <Text style={styles.confirmText}>
            {`${
              showCloseButton
                ? i18n.t("examTaking.confirm1")
                : i18n.t("examTaking.confirm1Time")
            } `}
            <Text style={styles.confirmTextBold}>
              {showCloseButton
                ? i18n.t("examTaking.confirm2")
                : i18n.t("examTaking.confirm2Time")}
            </Text>
          </Text>
        </View>
        <View style={styles.confirmWarningContainer}>
          <YellowBanner
            textBanner={
              showCloseButton
                ? i18n.t("examTaking.confirm3")
                : i18n.t("examTaking.confirm3Time")
            }
          />
        </View>
        <View style={styles.inputContainer}>
          <View style={styles.inputContentContainer}>
            <View style={styles.inputIconContainer}>
              <Icon icon={checkIcon} iconStyle={styles.inputIcon} />
            </View>
            <TextInput
              autoCapitalize="characters"
              maxLength={
                showCloseButton
                  ? i18n.t("examTaking.confirm4").length
                  : i18n.t("examTaking.confirm4Time").length
              }
              multiline={false}
              placeholder={
                showCloseButton
                  ? i18n.t("examTaking.confirm4")
                  : i18n.t("examTaking.confirm4Time")
              }
              placeholderTextColor={COLOR_BLUE_TESTWE_30}
              style={styles.textInput}
              onChangeText={(text: string) => {
                if (
                  text.toLocaleLowerCase() ===
                  (showCloseButton
                    ? i18n.t("examTaking.confirm4").toLocaleLowerCase()
                    : i18n.t("examTaking.confirm4Time").toLocaleLowerCase())
                ) {
                  this.setState({
                    enableSendButton: true
                  });
                } else {
                  this.setState({
                    enableSendButton: false
                  });
                }
              }}
            />
          </View>
        </View>
        <View style={styles.buttonsContainer}>
          <Button
            label={
              showCloseButton
                ? i18n.t("examTaking.confirmSend")
                : i18n.t("examTaking.confirmSendTime")
            }
            containerStyle={[
              styles.buttonSend,
              !enableSendButton && styles.buttonSendDisabled
            ]}
            textStyle={[
              styles.buttonSendText,
              !enableSendButton && styles.buttonSendTextDisabled
            ]}
            disabled={!enableSendButton}
            onPress={() => onSend()}
          />
          {showCloseButton && (
            <Button
              label={
                showCloseButton
                  ? i18n.t("examTaking.confirmCancel")
                  : i18n.t("examTaking.confirmCancelTime")
              }
              containerStyle={[styles.buttonCancel]}
              textStyle={styles.buttonCancelText}
              onPress={() => onClose()}
            />
          )}
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  contentContainer: {
    alignItems: "center",
    flexGrow: 1
  },
  confirmTextContainer: {
    width: WIDTH_8,
    justifyContent: "center",
    paddingVertical: PADDING_SIDES * 0.2,
    flexGrow: 1,
    textAlign: "center"
  },
  confirmText: {
    fontFamily: FONT_GILROY_REGULAR,
    color: COLOR_BLUE_TESTWE,
    fontSize: FONTSIZE_16
  },
  confirmTextBold: {
    fontFamily: FONT_GILROY_BOLD,
    color: COLOR_BLUE_TESTWE,
    fontSize: FONTSIZE_16
  },
  confirmWarningContainer: {
    width: WIDTH_10,
    flexGrow: 1,
    paddingVertical: PADDING_SIDES * 0.2
  },
  inputContainer: {
    alignItems: "center",
    width: "100%",
    paddingVertical: PADDING_SIDES * 0.5,
    flexGrow: 1
  },
  inputContentContainer: {
    width: WIDTH_5,
    borderColor: COLOR_BLUE_TESTWE_30,
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 5,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  inputIconContainer: {
    width: WIDTH_2,
    flexBasis: "fit-content",
    height: "100%",
    justifyContent: "center"
  },
  inputIcon: {
    width: 20,
    height: 20,
    opacity: 0.5
  },
  textInput: {
    width: WIDTH_10,
    fontFamily: FONT_GILROY_BOLD,
    fontSize: FONTSIZE_24,
    color: COLOR_BLUE_TESTWE,
    minHeight: 45,
    textTransform: "uppercase"
  },
  buttonsContainer: {
    flexDirection: "column",
    width: WIDTH_6
  },
  buttonSend: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: COLOR_RED,
    marginVertical: PADDING_SIDES * 0.1
  },
  buttonSendDisabled: {
    backgroundColor: COLOR_GREY_BACKGROUND,
    borderColor: COLOR_RED,
    borderWidth: 1,
    opacity: 0.5
  },
  buttonSendText: {
    color: COLOR_WHITE
  },
  buttonSendTextDisabled: {
    color: COLOR_RED
  },
  buttonCancel: {
    width: "100%",
    backgroundColor: COLOR_WHITE,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: COLOR_BLUE_TESTWE,
    marginVertical: PADDING_SIDES * 0.1
  },
  buttonCancelText: {
    color: COLOR_BLUE_TESTWE
  }
});
