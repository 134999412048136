import React from "react";
import {
  StyleSheet,
  Image,
  ScrollView,
  View,
  TouchableOpacity
} from "react-native";
import _ from "lodash";
import ReactPlayer from "react-player";
import { IHighlight, NewHighlight } from "react-pdf-highlighter";
import { COLOR_BLUE_HR } from "../../../static/misc/colors";
import { MediaType } from "../../modules/exams/types/attachedfiles";
import {
  MIME_AUDIO,
  MIME_IMAGE,
  MIME_PDF,
  MIME_VIDEO,
  PADDING_SIDES
} from "../../../static/misc/constants";
import PdfViewer from "../molecules/PdfViewer";
import Icon from "../atoms/Icon";
import { fullscreenIn, fullscreenOut } from "../../../static/misc/images";

export interface MediaReaderProps {
  media: MediaType;
  widthMedia: number;
  heightMedia: number;
  isOpenBook: boolean;
  isTraining: boolean;
  highlights?: Array<IHighlight>;
  isFullscreen: boolean;
  currentPdfPage: number;
  onHighlightAdded: (highlight: NewHighlight) => void;
  onHighlightModified: (highlight: NewHighlight) => void;
  onHighlightRemoved: (index: number) => void;
  onFullscreenToggle: () => void;
  onPdfPageChange: (newPage: number) => void;
}

export interface MediaReaderState {
  isMediaPressed: boolean;
  widthImage: number;
  heightImage: number;
}

class MediaReader extends React.Component<MediaReaderProps, MediaReaderState> {
  constructor(props: MediaReaderProps) {
    super(props);
    this.state = {
      isMediaPressed: false,
      widthImage: 0,
      heightImage: 0
    };
  }

  switcherMimeType(): JSX.Element | null {
    const {
      currentPdfPage,
      media,
      heightMedia,
      widthMedia,
      isOpenBook,
      isTraining,
      highlights,
      isFullscreen,
      onHighlightAdded,
      onHighlightModified,
      onHighlightRemoved,
      onFullscreenToggle,
      onPdfPageChange
    } = this.props;
    const { widthImage, heightImage, isMediaPressed } = this.state;
    if (_.includes(MIME_IMAGE, media.mimeType) && media.blobUrl !== undefined) {
      Image.getSize(
        media.blobUrl,
        (width, height) => {
          this.setState({ widthImage: width, heightImage: height });
        },
        () => {
          this.setState({ widthImage: widthMedia, heightImage: heightMedia });
        }
      );
      return (
        <View>
          <TouchableOpacity
            onPress={() =>
              this.setState((prevState) => ({
                isMediaPressed: !prevState.isMediaPressed
              }))
            }
          >
            {(isMediaPressed && (
              <ScrollView style={[{ maxHeight: heightMedia }]}>
                <ScrollView horizontal style={{ maxWidth: widthMedia }}>
                  <Image
                    source={{
                      uri: media.blobUrl
                    }}
                    resizeMode="contain"
                    style={{
                      width: widthImage,
                      height: heightImage
                    }}
                  />
                </ScrollView>
              </ScrollView>
            )) || (
              <Image
                source={{
                  uri: media.blobUrl
                }}
                resizeMode="contain"
                style={{
                  width: widthMedia - PADDING_SIDES,
                  height: heightMedia - PADDING_SIDES
                }}
              />
            )}
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => onFullscreenToggle()}
            style={styles.iconContainer}
          >
            <Icon
              icon={isFullscreen ? fullscreenOut : fullscreenIn}
              iconStyle={styles.icon}
            />
          </TouchableOpacity>
        </View>
      );
    }
    if (media.mimeType === MIME_PDF && media.blobUrl !== undefined) {
      return (
        <PdfViewer
          currentPdfPage={currentPdfPage}
          height={heightMedia}
          width={widthMedia}
          base64pdf={media.data}
          blobUrl={media.blobUrl}
          canDownload={isTraining}
          isOpenBook={isOpenBook}
          highlights={highlights}
          isFullscreen={isFullscreen}
          onHighlightAdded={onHighlightAdded}
          onHighlightModified={onHighlightModified}
          onHighlightRemoved={onHighlightRemoved}
          onFullscreenToggle={onFullscreenToggle}
          onPdfPageChange={onPdfPageChange}
        />
      );
    }
    if (_.includes(MIME_VIDEO, media.mimeType) && media.blobUrl !== undefined) {
      return (
        <ReactPlayer
          url={media.blobUrl}
          width="100%"
          height="100%"
          controls
          config={{
            file: {
              attributes: {
                controlsList: "noplaybackrate nodownload",
                disablePictureInPicture: true
              }
            }
          }}
        />
      );
    }
    if (_.includes(MIME_AUDIO, media.mimeType) && media.blobUrl !== undefined) {
      return (
        <ReactPlayer
          url={media.blobUrl}
          width={250}
          height={40}
          controls
          config={{
            file: { attributes: { controlsList: "noplaybackrate nodownload" } }
          }}
        />
      );
    }
    return null;
  }

  render(): JSX.Element {
    const { heightMedia, widthMedia } = this.props;
    return (
      <View
        style={[
          styles.container,
          { maxWidth: widthMedia, maxHeight: heightMedia }
        ]}
      >
        {this.switcherMimeType()}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: COLOR_BLUE_HR,
    borderRadius: 5,
    overflow: "hidden"
  },
  iconContainer: {
    position: "absolute",
    right: 20,
    top: 10
  },
  icon: {
    width: 20,
    height: 20
  }
});

export default MediaReader;
