import React from "react";
import { ImageStyle, StyleProp, StyleSheet, Image } from "react-native";
import { COLOR_WHITE } from "../../../static/misc/colors";
import {
  emptyBtr,
  fullBtr,
  largeBtr,
  smallBtr
} from "../../../static/misc/images";

interface BatteryImageProps {
  imageStyle?: StyleProp<ImageStyle>;
  batteryLevel: number;
}

class BatteryImage extends React.PureComponent<BatteryImageProps> {
  render(): JSX.Element {
    const { imageStyle, batteryLevel } = this.props;
    let batteryImage = fullBtr;
    if (batteryLevel <= 75) batteryImage = largeBtr;
    else if (batteryLevel <= 50) batteryImage = smallBtr;
    else if (batteryLevel <= 25) batteryImage = emptyBtr;
    return (
      <Image
        source={batteryImage}
        style={[styles.defaultImageStyle, imageStyle]}
      />
    );
  }
}

const styles = StyleSheet.create({
  defaultImageStyle: {
    width: 28,
    tintColor: COLOR_WHITE,
    height: 16,
    top: 1
  }
});

export default BatteryImage;
