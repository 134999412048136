/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { ipcRenderer } from "electron";
import * as Sentry from "sentry-expo";

import { Dispatch } from "react";
import { Action, Middleware, MiddlewareAPI } from "redux";
import _ from "lodash";
import { EffectAction } from "./middlewares.types";
import {
  GET_SYNC_EXAM,
  GET_SYNC_EXAM_FAIL,
  GET_SYNC_EXAM_SUCCESS,
  GET_SYNC_PAST_EXAM,
  GET_SYNC_PAST_EXAM_FAIL,
  GET_SYNC_PAST_EXAM_SUCCESS
} from "../../modules/exams/types/exams";
import {
  GET_EXAM_PUBLIC_KEY,
  GET_EXAM_PUBLIC_KEY_SUCCESS
} from "../../modules/exams/types/publickeys";
import { GET_USER } from "../../modules/main/types/user";
import {
  DOWNLOAD_MEDIA_OBJECT_SUCCESS,
  UPLOAD_MEDIA_OBJECT_AWS_FAIL,
  UPLOAD_MEDIA_OBJECT_AWS_SUCCESS
} from "../../modules/exams/types/attachedfiles";

// Homemade middleware, in oder to print redux FAILURE or ERROR into the logger (in app data)
const electronLogger: Middleware = (store: MiddlewareAPI<any>) => (
  next: Dispatch<EffectAction>
) =>
  ((action: EffectAction) => {
    // Logging errors and fail on sentry only if online
    if (
      store.getState().status.isOnline &&
      (action.type?.includes("_FAIL") || action.type?.includes("ERROR"))
    ) {
      // Browser is not typed on expo-sentry
      (Sentry as any).Browser.captureMessage(
        `${action.type} - ${
          action.error
            ? JSON.stringify(action.error.response)
            : action.payload && action.payload.error
            ? JSON.stringify(action.payload.error.response)
            : ""
        }`,
        {
          user: store.getState().user
        }
      );
    }

    // Logging EVERY ACTION because I'm fucking tired of not having logs when there's a problem
    // (exception duration update because will happen every second)
    // We also don't want the public key appearing clearly in the logs
    const excluded = [
      "UPDATE_TIMER",
      "GENERATE_SIGNED_URL",
      "UPLOAD_MEDIA_OBJECT_AWS_SUCCESS",
      "CREATE_ARCHIVE_BEFORE_UPLOAD",
      "NO_PROCTOR_ARCHIVE_TO_SEND"
    ];

    if (action.type && !_.includes(excluded, action.type)) {
      const type =
        action.type.includes("_FAIL") || action.type.includes("ERROR")
          ? "LOG_ERROR"
          : "LOG_INFO";
      let content;

      switch (action.type) {
        case GET_SYNC_PAST_EXAM:
        case GET_SYNC_EXAM: {
          content = `Sent request to API to synchronize exam ${action.payload.examId}`;
          break;
        }
        case GET_SYNC_EXAM_SUCCESS:
        case GET_SYNC_PAST_EXAM_SUCCESS:
        case GET_SYNC_PAST_EXAM_FAIL:
        case GET_SYNC_EXAM_FAIL: {
          content = `Received response from the API for exam ${action.meta.previousAction.payload.examId}`;
          break;
        }
        case GET_EXAM_PUBLIC_KEY: {
          content = `Sent request to API to retrieve the public key for exam ${action.payload.data.examId}`;
          break;
        }
        case GET_USER: {
          content = `Sent request to API to retrieve current user information`;
          break;
        }
        case DOWNLOAD_MEDIA_OBJECT_SUCCESS: {
          content = `Media ${action.payload.data.filePath} (id : ${action.payload.data.id}, mimeType : ${action.payload.data.mimeType}, size : ${action.payload.data.size}) was successfully downloaded`;
          break;
        }
        case UPLOAD_MEDIA_OBJECT_AWS_SUCCESS:
        case UPLOAD_MEDIA_OBJECT_AWS_FAIL: {
          content = `for media ${action.meta.previousAction.payload.filename}`;
          break;
        }
        default: {
          content = action.error ? action.error.response : action.payload;
          break;
        }
      }

      ipcRenderer.send(type, `${action.type} - ${JSON.stringify(content)}`);
    }
    return next(action);
  }) as Dispatch<EffectAction>;

export default electronLogger;
