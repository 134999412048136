import React from "react";
import { StyleSheet, View } from "react-native";
import { COLOR_TRANSPARENT } from "../../../static/misc/colors";
import {
  FONTSIZE_13,
  FONTSIZE_16,
  PADDING_SIDES
} from "../../../static/misc/constants";
import { ExamParam } from "../../modules/exams/types/exam";
import RuleExam from "./RuleExam";

export interface ExamTakingRulesProps {
  rules: ExamParam;
}

class ExamTakingRules extends React.PureComponent<ExamTakingRulesProps> {
  render(): JSX.Element {
    const { rules } = this.props;
    return (
      <View style={styles.viewStyle}>
        <RuleExam
          data={rules}
          containerStyle={styles.rulesContainer}
          itemContainerStyle={styles.itemRulesContainer}
          titleStyle={styles.rulesTitle}
          itemTextStyle={styles.itemRulesText}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  viewStyle: {
    width: "100%",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingVertical: PADDING_SIDES * 0.5,
    paddingHorizontal: PADDING_SIDES * 0.5
  },
  rulesContainer: {
    backgroundColor: COLOR_TRANSPARENT,
    borderRadius: 0,
    width: "100%",
    shadowColor: COLOR_TRANSPARENT,
    shadowOffset: {
      width: 0,
      height: 0
    },
    shadowRadius: 0,
    shadowOpacity: 0,
    paddingVertical: 0,
    paddingHorizontal: 0
  },
  itemRulesContainer: {
    backgroundColor: COLOR_TRANSPARENT
  },
  rulesTitle: {
    alignSelf: "flex-start",
    fontSize: FONTSIZE_16,
    paddingBottom: PADDING_SIDES * 0.4
  },
  itemRulesText: {
    fontSize: FONTSIZE_13
  }
});

export default ExamTakingRules;
