import React from "react";
import { StyleSheet, View } from "react-native";
import moment from "moment";
import CustomModal from "../atoms/CustomModal";
import PdfViewer from "../molecules/PdfViewer";
import Loader from "../atoms/Loader";
import SeeExamButton from "../atoms/SeeExamButton";
import { GetMyPaperBase64Action } from "../../modules/exams/types/exams";

interface SeeExamProps {
  examId: string;
  isUsable: boolean;
  blobUrl?: string;
  base64pdf?: string;
  getMyPaperBase64: () => GetMyPaperBase64Action;
}
interface SeeExamState {
  isModalOpen: boolean;
  isLoading: boolean;
  heightMedia: number;
  widthMedia: number;
  isFullscreen: boolean;
}

class SeeExam extends React.PureComponent<SeeExamProps, SeeExamState> {
  constructor(props: SeeExamProps) {
    super(props);
    this.state = {
      isModalOpen: false,
      isLoading: false,
      widthMedia: 0,
      heightMedia: 0,
      isFullscreen: false
    };
  }

  componentDidUpdate(prevProps: SeeExamProps): void {
    const { base64pdf } = this.props;
    const { isModalOpen } = this.state;

    if (prevProps.base64pdf !== base64pdf && base64pdf && !isModalOpen) {
      this.setState({ isModalOpen: true, isLoading: false });
    }
  }

  getPdf = (): void => {
    const { getMyPaperBase64 } = this.props;
    this.setState({ isLoading: true });
    getMyPaperBase64();
  };

  transformViewSizeToPixel = ({
    vh,
    vw
  }: {
    vh?: number;
    vw?: number;
  }): number => {
    if (vh) return ((window.innerHeight - 20) * vh) / 100;

    if (vw) return (window.innerWidth * vw) / 100;

    return 0;
  };

  render(): JSX.Element {
    const { isUsable, examId, base64pdf, blobUrl } = this.props;
    const {
      isModalOpen,
      isLoading,
      widthMedia,
      heightMedia,
      isFullscreen
    } = this.state;

    return (
      <>
        {isLoading ? (
          <Loader
            iconStyle={{
              width: 35,
              height: 35
            }}
          />
        ) : (
          <SeeExamButton disabled={!isUsable} onPress={() => this.getPdf()} />
        )}
        {isModalOpen && base64pdf && (
          <CustomModal
            heightModal={isFullscreen ? "100vh" : "70vh"}
            widthModal={isFullscreen ? "100vw" : "70vw"}
            borderRadiusModal={5}
            visible
            showCloseButton
            onCloseModal={() => this.setState({ isModalOpen: false })}
          >
            <View
              style={styles.pdfView}
              onLayout={(event) => {
                if (!widthMedia || !heightMedia) {
                  this.setState({
                    heightMedia: event.nativeEvent.layout.height,
                    widthMedia: event.nativeEvent.layout.width
                  });
                }
              }}
            >
              <PdfViewer
                canDownload
                height={
                  isFullscreen
                    ? this.transformViewSizeToPixel({ vh: 100 })
                    : heightMedia
                }
                width={
                  isFullscreen
                    ? this.transformViewSizeToPixel({ vw: 100 })
                    : widthMedia
                }
                isOpenBook
                pdfName={`Exam_${moment().format("DD-MM-YY")}_${examId}`}
                blobUrl={blobUrl}
                base64pdf={base64pdf}
                withHighlightsMode={false}
                isFullscreen={isFullscreen}
                onFullscreenToggle={() =>
                  this.setState({ isFullscreen: !isFullscreen })
                }
              />
            </View>
          </CustomModal>
        )}
      </>
    );
  }
}

const styles = StyleSheet.create({
  pdfView: {
    width: "100%",
    height: "100%"
  }
});

export default SeeExam;
