/* eslint-disable camelcase */
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "../../store/rootreducer";
import { ExamsView } from "./ExamsView";
import {
  updateDisplayedExams,
  changeExamsOrder,
  updateSelectedAcademicYear,
  sortExam,
  getMyPaperBase64
} from "./actions/exams";

const mapStateToProps = (state: RootState) => {
  return {
    token: state.auth.credentials?.access_token,
    locale: state.user.locale,
    isOnline: state.status.isOnline,
    nextExam: state.exams.nextExam,
    nextExams: state.exams.nextExams,
    passedExams: state.exams.passedExams,
    attachedFiles: state.attachedfiles.mediaObjects,
    displayedExams: state.exams.displayedExams,
    selectedAcademicYear: state.exams.selectedAcademicYear,
    ordered: state.exams.ordered
  };
};

const mapdispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators(
      {
        updateDisplayedExams,
        changeExamsOrder,
        sortExam,
        updateSelectedAcademicYear,
        getMyPaperBase64
      },
      dispatch
    )
  };
};

const ExamsContainer = connect(mapStateToProps, mapdispatchToProps)(ExamsView);

export default ExamsContainer;
