import React from "react";
import Tippy from "@tippyjs/react";
import { View, StyleSheet, Text } from "react-native";

import { ExamPart, ExamType } from "../../../modules/exams/types/exam";
import ExamProgressCircular from "../../molecules/ExamProgressCircular";
import ExamDescriptionIcon from "../../molecules/ExamDescriptionIcon";
import { StudentAnswerType } from "../../../modules/examTaking/types/studentPaper";
import ExamTakingTimer, { TimerTypeEnum } from "../../atoms/ExamTakingTimer";
import {
  COLOR_SHADOW,
  COLOR_BLUE_TESTWE,
  COLOR_GREY_EXAM_NAVBAR_COLOR_CODE,
  COLOR_BOX_SHADOW_TOOLTIP,
  COLOR_WHITE
} from "../../../../static/misc/colors";
import { FONT_GILROY_BOLD } from "../../../../static/misc/constants";
import i18n from "../../../services/i18n";
import ExamPartsCarousel from "../../molecules/ExamPartsCarousel";
import Divider from "../../atoms/Divider";
import {
  examHasDurationFixedAndPartOrQuestionChrono,
  examHasPartOrQuestionChrono
} from "../../../services/exam-navbar-progress";
import { ChangeEnum } from "../ExamTakingContent";

interface ExamTopbarProps {
  currentExam: ExamType;
  studentAnswers: StudentAnswerType[];
  onFinishTimer: (timerType: TimerTypeEnum) => void;
  onChangePartIndex: (index: number, examPart: ExamPart) => void;
  currentExamPartIndex: number;
  forceStopTimers: boolean;
  currentPartIndex: number;
  toggleRemainingDurationWarningModal(
    visible: boolean,
    changeType?: ChangeEnum,
    callback?: (changeType: ChangeEnum) => void
  ): void;
  selectedExamPart?: ExamPart;
}

class ExamTopbar extends React.PureComponent<ExamTopbarProps> {
  buildTooltipContent = (): JSX.Element => (
    <View style={styles.tooltipView}>
      <Text style={styles.tooltipBoldTitle}>
        {i18n.t("examNavbar.examTakingTimer.tooltip")}
      </Text>
    </View>
  );

  render(): JSX.Element {
    const {
      currentExam,
      studentAnswers,
      onFinishTimer,
      currentExamPartIndex,
      onChangePartIndex,
      forceStopTimers,
      currentPartIndex,
      toggleRemainingDurationWarningModal,
      selectedExamPart
    } = this.props;
    return (
      <View style={styles.container}>
        <ExamDescriptionIcon
          partOrQuestionWithChrono={examHasPartOrQuestionChrono(
            currentExam,
            currentExam.examParams?.durationFixed
          )}
          partOrQuestionChronoAndLocked={examHasDurationFixedAndPartOrQuestionChrono(
            currentExam,
            currentExam.examParams?.durationFixed
          )}
        />

        <Divider
          width={1}
          height={32}
          color={COLOR_GREY_EXAM_NAVBAR_COLOR_CODE}
          marginHorizontal={8}
        />

        <ExamProgressCircular
          currentExam={currentExam}
          studentAnswers={studentAnswers}
        />

        <ExamPartsCarousel
          currentPartIndex={currentPartIndex}
          currentExamPartIndex={currentExamPartIndex}
          currentExam={currentExam}
          studentAnswers={studentAnswers}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          updateCurrentExamTaking={() => {}}
          onPressExamPart={onChangePartIndex}
          selectedExamPart={selectedExamPart}
          toggleRemainingDurationWarningModal={
            toggleRemainingDurationWarningModal
          }
        />

        <Tippy placement="bottom" content={this.buildTooltipContent()}>
          <View style={{ marginLeft: "auto" }}>
            <ExamTakingTimer
              examId={currentExam.id}
              iconLetter={i18n.t("examNavbar.examTakingTimer.examLetter")}
              iconFilled
              duration={currentExam.duration}
              running={!forceStopTimers}
              timerType={TimerTypeEnum.EXAM}
              onFinish={onFinishTimer}
            />
          </View>
        </Tippy>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    maxHeight: 32
  },
  tooltipView: {
    backgroundColor: COLOR_WHITE,
    borderRadius: 5,
    padding: 8,
    boxShadow: COLOR_BOX_SHADOW_TOOLTIP,
    shadowColor: COLOR_SHADOW,
    shadowOffset: {
      width: 0,
      height: 0
    }
  },
  tooltipBoldTitle: {
    color: COLOR_BLUE_TESTWE,
    fontFamily: FONT_GILROY_BOLD,
    fontSize: 16,
    marginBottom: 16
  }
});

export default ExamTopbar;
