import React from "react";
import { StyleSheet, Text, View } from "react-native";
import _ from "lodash";
import { NewHighlight } from "react-pdf-highlighter";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import {
  COLOR_BLACK,
  COLOR_BLUE_TESTWE,
  COLOR_WHITE
} from "../../../static/misc/colors";
import {
  FONTSIZE_24,
  FONT_GILROY_EXTRA_BOLD,
  MIME_IMAGE,
  MIME_PDF,
  PADDING_SIDES,
  TRAINING
} from "../../../static/misc/constants";
import { EXAM, PART, QUESTION } from "../../../static/misc/menu";
import { MediaType } from "../../modules/exams/types/attachedfiles";
import {
  ExamExercise,
  ExamPart,
  ExamQuestion,
  ExamType
} from "../../modules/exams/types/exam";
import MediaReader from "./MediaReader";
import {
  addPdfHighlight,
  modifyPdfHighlight,
  removePdfHighlight
} from "../../modules/examTaking/actions/examTaking";
import Button from "../molecules/Button";
import i18n from "../../services/i18n";

export interface ExamTakingFullscreenMediaProps {
  currentPdfPage: number;
  currentExamPart: number;
  currentPartIndex: number;
  currentAttachedFiles: MediaType[];
  currentExam: ExamType;
  focusedMediaId: string;
  focusedType: string;
  heightMedia: number;
  widthMedia: number;
  onPdfPageChange(newPage: number): void;
  addHighlight(
    examId: number,
    attachedFileId: string,
    hightlight: NewHighlight
  ): void;
  modifyHighlight(
    examId: number,
    attachedFileId: string,
    hightlight: NewHighlight
  ): void;
  removeHighlight(examId: number, attachedFileId: string, index: number): void;
  onUnsetFullscreen: () => void;
}

export interface ExamTakingFullscreenMediaState {
  selectedIndex: number;
}

class ExamTakingFullscreenMedia extends React.Component<
  ExamTakingFullscreenMediaProps,
  ExamTakingFullscreenMediaState
> {
  medias: MediaType[] | null = null;

  constructor(props: ExamTakingFullscreenMediaProps) {
    super(props);
    this.state = {
      selectedIndex: 0
    };
  }

  componentDidMount(): void {
    this.getMedias();
  }

  getMedias(): void {
    const { currentExam, focusedMediaId, focusedType } = this.props;

    let data;
    switch (focusedType) {
      case EXAM: {
        data = this.mediaLoop(currentExam, 0, 0, "ExamType");
        break;
      }
      case PART: {
        if (
          !currentExam ||
          currentExam === undefined ||
          !currentExam.examParts ||
          !Array.isArray(currentExam.examParts)
        )
          data = null;
        else
          data = currentExam.examParts.map((examPart, index) =>
            this.mediaLoop(examPart, index, 0, "ExamPart")
          );
        break;
      }
      case QUESTION: {
        if (
          !currentExam ||
          currentExam === undefined ||
          !currentExam.examParts ||
          !Array.isArray(currentExam.examParts)
        )
          data = null;
        else
          data = currentExam.examParts.map((examPart, index) => {
            return examPart.partIndexes.map((partIndex, qNumber) => {
              if (partIndex.type === "question" && partIndex.question)
                return this.mediaLoop(
                  partIndex.question,
                  index,
                  qNumber,
                  "ExamQuestion"
                );
              if (partIndex.type === "exercise" && partIndex.exercise) {
                return this.mediaLoop(
                  partIndex.exercise,
                  index,
                  qNumber,
                  "ExamQuestion"
                );
              }
              return null;
            });
          });
        break;
      }
      default:
        data = null;
    }

    if (data) {
      data = this.flatDeep(data);
      this.medias = data.filter(
        (value: MediaType | null) =>
          value &&
          (_.includes(MIME_IMAGE, value.mimeType) ||
            value.mimeType === MIME_PDF)
      );

      this.setState({
        selectedIndex: this.medias.findIndex(
          (media: MediaType) => media.mediaId === focusedMediaId
        )
      });
    }
  }

  flatDeep(arr: any[]): any[] {
    return arr.reduce(
      (acc, val) => acc.concat(Array.isArray(val) ? this.flatDeep(val) : val),
      []
    );
  }

  changeCurrentIndex(shift: number): void {
    const { selectedIndex } = this.state;
    const { onPdfPageChange } = this.props;
    onPdfPageChange(1);
    let newIndex = selectedIndex + shift;

    const mediaLength = this.medias?.length;

    if (mediaLength) {
      if (newIndex < 0) newIndex = mediaLength - 1;
      if (newIndex >= mediaLength) newIndex = 0;

      this.setState({
        selectedIndex: newIndex
      });
    }
  }

  // Loops on the media list of the section to display it
  mediaLoop(
    loopFrom: ExamType | ExamPart | ExamQuestion | ExamExercise,
    pIndex?: number,
    qIndex?: number,
    type?: string
  ): (MediaType | null)[] {
    const {
      currentAttachedFiles,
      currentExamPart,
      currentPartIndex
    } = this.props;
    if (
      (type === "ExamPart" && currentExamPart === pIndex) ||
      (type === "ExamQuestion" &&
        currentExamPart === pIndex &&
        currentPartIndex === qIndex) ||
      type === "ExamType"
    )
      return loopFrom.attachedFiles.map((attachedFile) => {
        const currentAttachedFileIndex = currentAttachedFiles?.findIndex(
          (e) => e.mediaId === attachedFile.id
        );
        if (
          currentAttachedFileIndex !== undefined &&
          currentAttachedFileIndex >= 0 &&
          currentAttachedFiles
        ) {
          const currentFile = currentAttachedFiles[currentAttachedFileIndex];
          return currentFile;
        }
        return null;
      });
    return [];
  }

  render(): JSX.Element {
    const {
      currentPdfPage,
      currentExam,
      heightMedia,
      widthMedia,
      addHighlight,
      modifyHighlight,
      removeHighlight,
      onUnsetFullscreen,
      onPdfPageChange
    } = this.props;
    const { selectedIndex } = this.state;

    const currentAttachedFile = this.medias ? this.medias[selectedIndex] : null;

    return (
      <View style={styles.container}>
        {this.medias && currentAttachedFile && (
          <>
            <Text style={styles.title}>
              {i18n.t("media.mediaFullscreenProgress", [
                selectedIndex + 1,
                this.medias.length
              ])}
            </Text>
            <MediaReader
              currentPdfPage={currentPdfPage}
              onPdfPageChange={onPdfPageChange}
              heightMedia={heightMedia - PADDING_SIDES / 2}
              widthMedia={widthMedia}
              media={currentAttachedFile}
              isTraining={currentExam.id === TRAINING}
              isOpenBook={currentExam.examParams?.openBook || false}
              isFullscreen
              highlights={
                currentExam.highlights?.find(
                  (h) => h.fileId === currentAttachedFile.mediaId
                )?.highlights
              }
              onHighlightAdded={(highlight: NewHighlight) => {
                addHighlight(
                  currentExam.examId,
                  currentAttachedFile.mediaId,
                  highlight
                );
              }}
              onHighlightModified={(highlight: NewHighlight) =>
                modifyHighlight(
                  currentExam.examId,
                  currentAttachedFile.mediaId,
                  highlight
                )
              }
              onHighlightRemoved={(index: number) =>
                removeHighlight(
                  currentExam.examId,
                  currentAttachedFile.mediaId,
                  index
                )
              }
              onFullscreenToggle={() => onUnsetFullscreen()}
            />
          </>
        )}
        {this.medias && this.medias.length > 1 && (
          <>
            <Button
              label="<"
              containerStyle={[styles.button, styles.leftButton]}
              textStyle={styles.buttonText}
              onPress={() => this.changeCurrentIndex(-1)}
            />
            <Button
              label=">"
              containerStyle={[styles.button, styles.rightButton]}
              textStyle={styles.buttonText}
              onPress={() => this.changeCurrentIndex(1)}
            />
          </>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center"
  },
  title: {
    fontFamily: FONT_GILROY_EXTRA_BOLD,
    fontSize: FONTSIZE_24,
    color: COLOR_BLUE_TESTWE
  },
  button: {
    width: 48,
    height: 48,
    borderRadius: 24,
    backgroundColor: COLOR_WHITE,
    borderColor: COLOR_WHITE,
    shadowColor: COLOR_BLACK,
    shadowOffset: {
      width: 2,
      height: 4
    },
    shadowOpacity: 0.2
  },
  buttonText: {
    color: COLOR_BLACK
  },
  leftButton: {
    position: "absolute",
    left: 20,
    bottom: "50%"
  },
  rightButton: {
    position: "absolute",
    right: 20,
    bottom: "50%"
  }
});

export default connect(null, (dispatch: Dispatch) => {
  return {
    ...bindActionCreators(
      {
        addHighlight: addPdfHighlight,
        removeHighlight: removePdfHighlight,
        modifyHighlight: modifyPdfHighlight
      },
      dispatch
    )
  };
})(ExamTakingFullscreenMedia);
