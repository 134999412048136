import React from "react";
import { View, Text, StyleSheet } from "react-native";
import moment from "../../services/moment";
import Label from "../atoms/Label";
import i18n from "../../services/i18n";
import {
  CORRECTED,
  SENT,
  FONTSIZE_10,
  FONT_GILROY_BOLD,
  PADDING_SIDES,
  NOT_REALIZED,
  NOT_SYNCRO,
  CORRECTION_COMPLETED,
  IN_CORRECTION,
  PROCTOR_NOT_SEND,
  TOINTEGRATE
} from "../../../static/misc/constants";
import {
  COLOR_BLUE_TESTWE,
  COLOR_GREEN_VALID,
  COLOR_RED,
  COLOR_YELLOW_INFO
} from "../../../static/misc/colors";
import OpacTextWithBoldText from "../atoms/OpacTextWithBoldText";

interface DetailExamProps {
  date: Date;
  subject: string;
  name: string;
  status: string;
}

class DetailExam extends React.PureComponent<DetailExamProps> {
  showLabel = (status: string): JSX.Element | null => {
    switch (status) {
      case CORRECTION_COMPLETED:
      case CORRECTED:
        return (
          <Label
            text={i18n.t("label.corrected")}
            backgroundColor={COLOR_BLUE_TESTWE}
          />
        );
      case TOINTEGRATE:
        return (
          <Label
            text={i18n.t("label.toIntegrate")}
            backgroundColor={COLOR_YELLOW_INFO}
          />
        );
      case PROCTOR_NOT_SEND:
        return (
          <Label
            text={i18n.t("label.incomplete")}
            backgroundColor={COLOR_YELLOW_INFO}
          />
        );
      case NOT_REALIZED:
        return (
          <Label
            text={i18n.t("label.notrealized")}
            backgroundColor={COLOR_RED}
          />
        );
      case SENT:
      case IN_CORRECTION:
        return (
          <Label
            text={i18n.t("label.sent")}
            backgroundColor={COLOR_GREEN_VALID}
          />
        );
      case NOT_SYNCRO:
        return (
          <Label
            text={i18n.t("label.notsync")}
            backgroundColor={COLOR_YELLOW_INFO}
          />
        );
      default:
        return (
          <Label
            text={i18n.t("label.notrealized")}
            backgroundColor={COLOR_RED}
          />
        );
    }
  };

  render(): JSX.Element {
    const { date, name, subject, status } = this.props;

    return (
      <View style={styles.column}>
        <View style={styles.row}>
          <Text style={styles.dateStyle}>
            {moment(date).format("DD.MM.YYYY")}
          </Text>
          {this.showLabel(status)}
        </View>
        <View style={styles.rowSpace}>
          <OpacTextWithBoldText
            containerStyle={{
              flexDirection: "row",
              width: 200,
              justifyContent: "space-between"
            }}
            contentStyle={{
              width: "60%"
            }}
            opacText={i18n.t("homepage.subject")}
            boldText={subject}
          />
        </View>
        <View style={styles.rowSpace}>
          <OpacTextWithBoldText
            containerStyle={{
              flexDirection: "row",
              width: 200,
              justifyContent: "space-between"
            }}
            contentStyle={{
              width: "60%"
            }}
            opacText={i18n.t("name")}
            boldText={name}
          />
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  column: {
    paddingVertical: "2%",
    height: "100%",
    justifyContent: "space-between",
    flexDirection: "column"
  },
  row: {
    flexDirection: "row"
  },
  rowSpace: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  dateStyle: {
    paddingTop: PADDING_SIDES * 0.05,
    fontSize: FONTSIZE_10,
    paddingRight: PADDING_SIDES * 0.3,
    color: COLOR_BLUE_TESTWE,
    fontFamily: FONT_GILROY_BOLD
  }
});

export default DetailExam;
