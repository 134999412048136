/* eslint-disable react/no-unused-state */
import React from "react";
import { StyleSheet, Text, View, TouchableOpacity } from "react-native";
import { COLOR_BLUE, COLOR_RED } from "../../../static/misc/colors";
import {
  FONTSIZE_12,
  FONTSIZE_16,
  FONT_GILROY_BOLD,
  FONT_GILROY_REGULAR,
  PADDING_SIDES
} from "../../../static/misc/constants";

interface TopListMenuItemProps {
  text: string;
  isSelected: boolean;
  countExams: number;
  onPress: () => void;
}

class TopListMenuItem extends React.PureComponent<TopListMenuItemProps> {
  render(): JSX.Element {
    const { text, isSelected, countExams, onPress } = this.props;
    const viewStyle = isSelected
      ? [styles.view, styles.viewSelected]
      : [styles.view];
    const textStyle = isSelected
      ? [styles.text, styles.textSelected]
      : [styles.text];

    return (
      <TouchableOpacity onPress={onPress}>
        <View style={viewStyle}>
          <Text style={textStyle}>
            {text}
            <Text
              style={[styles.text, styles.smallText]}
            >{`(${countExams.toString()})`}</Text>
          </Text>
        </View>
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  view: {
    paddingHorizontal: PADDING_SIDES * 0.25,
    paddingVertical: PADDING_SIDES * 0.25
  },
  viewSelected: {
    borderBottomColor: COLOR_RED,
    borderBottomWidth: 4
  },
  text: {
    fontSize: FONTSIZE_16,
    fontFamily: FONT_GILROY_REGULAR,
    color: COLOR_BLUE
  },
  smallText: {
    fontSize: FONTSIZE_12
  },
  textSelected: {
    fontFamily: FONT_GILROY_BOLD
  }
});

export default TopListMenuItem;
