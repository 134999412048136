import React from "react";
import { StyleSheet, View } from "react-native";
import { COLOR_BLUE_TESTWE } from "../../../static/misc/colors";
import {
  FONTSIZE_14,
  FONTSIZE_21,
  FONT_GILROY_EXTRA_BOLD,
  FONT_GILROY_REGULAR,
  PADDING_SIDES
} from "../../../static/misc/constants";
import { ExamType } from "../../modules/exams/types/exam";
import i18n from "../../services/i18n";
import BoldTitle from "../atoms/BoldTitle";
import HtmlRenderer from "../atoms/HtmlRenderer";
import YellowBanner from "../atoms/YellowBanner";
import ExamInfoAgreement from "../molecules/ExamInfoAgreement";

interface OnboardingTeacherInstructionsProps {
  currentExam: ExamType;
  hasAgreed: boolean;
  updateAgreement: () => void;
}

export default class OnboardingTeacherInstructions extends React.PureComponent<OnboardingTeacherInstructionsProps> {
  render(): JSX.Element {
    const { currentExam, hasAgreed, updateAgreement } = this.props;
    return (
      <>
        <View style={styles.yellowBannerContainer}>
          <YellowBanner textBanner={i18n.t("exam.advice")} />
        </View>
        <View style={styles.instructionContainer}>
          <BoldTitle
            text={i18n.t("exam.instruction")}
            textStyle={styles.instructionTitle}
          />
          <HtmlRenderer
            containerStyle={[styles.instructionText]}
            content={
              currentExam.instructions !== "" &&
              currentExam.publicInstructions === ""
                ? currentExam.instructions
                : currentExam.publicInstructions
            }
            isLinkUsable
          />
        </View>
        <View style={styles.agreementAndStart}>
          <ExamInfoAgreement
            hasAgreed={hasAgreed}
            link={i18n.t("exam.agreement2")}
            text={i18n.t("exam.agreement1")}
            onPress={() => updateAgreement()}
          />
        </View>
      </>
    );
  }
}

const styles = StyleSheet.create({
  yellowBannerContainer: {
    paddingVertical: PADDING_SIDES * 0.5
  },
  instructionTitle: {
    fontFamily: FONT_GILROY_EXTRA_BOLD,
    fontSize: FONTSIZE_21,
    marginBottom: PADDING_SIDES * 0.2
  },
  instructionText: {
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_14,
    color: COLOR_BLUE_TESTWE,
    marginBottom: PADDING_SIDES * 0.2,
    lineHeight: 20
  },
  instructionContainer: {
    width: "100%",
    flexShrink: 1,
    flexGrow: 1
  },
  agreementAndStart: {
    width: "100%",
    flexShrink: 1,
    flexGrow: 1
  }
});
