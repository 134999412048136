import React from "react";
import os from "os";
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableWithoutFeedback,
  View
} from "react-native";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ipcRenderer } from "electron";
import moment from "../../services/moment";
import { COLOR_BLUE_TESTWE, COLOR_WHITE } from "../../../static/misc/colors";
import {
  DEFAULT_EQUIPMENT_CHECK,
  FONTSIZE_13,
  FONTSIZE_21,
  FONT_GILROY_BOLD,
  FONT_GILROY_REGULAR,
  IS_WEB_ENABLE,
  PADDING_SIDES
} from "../../../static/misc/constants";
import ConnectionTesting from "../../atomic/organisms/ConnectionTesting";
import HeaderContainer from "../../atomic/organisms/Header/HeaderContainer";
import { StackNavigatorProp } from "../../router/StackNavigator";
import i18n from "../../services/i18n";
import EquipmentCard from "../../atomic/molecules/EquipmentCard";
import Card from "../../atomic/atoms/Card";
import CameraTesting from "../../atomic/organisms/CameraTesting";
import CustomModal from "../../atomic/atoms/CustomModal";
import Button from "../../atomic/molecules/Button";
import MicrophoneTesting from "../../atomic/organisms/MicrophoneTesting";
import {
  checkMediaPermissions,
  RequestMediaPermissionsResult
} from "../../services/permissions";
import {
  EquipmentCheck,
  PutLastestEquipmentCheck
} from "../main/types/equipmentCheck";
import { RootState } from "../../store/rootreducer";
import { testCamera, testMicro, testSpeed } from "../../../static/misc/images";
import { getEquipmentInformation } from "../../services/equipment";
import { postLatestEquipmentCheck } from "../main/actions/equipmentCheck";
import { startExitingApplication } from "../main/actions/status";
import { ExitApplicationAction } from "../main/types/status";

export interface TestingViewProps {
  lastEquipmentCheck: EquipmentCheck;
  navigation: StackNavigatorProp;
  authorizationToken: string | undefined;
  postLatestEquipmentCheck: (
    userToken: string,
    equipmentCheck: EquipmentCheck
  ) => PutLastestEquipmentCheck;
  exitApplication: (action?: string) => ExitApplicationAction;
}

enum Equipment {
  microphone,
  camera,
  internet,
  none
}

interface TestingViewState {
  showMenu: boolean;
  testedEquipment: Equipment;
  showPermissionsModal: boolean;
  equipmentCheck: EquipmentCheck;
  syncDate?: string;
  permissions: RequestMediaPermissionsResult;
  shouldRestart: boolean;
}

class TestingView extends React.Component<TestingViewProps, TestingViewState> {
  constructor(props: TestingViewProps) {
    super(props);

    const { lastEquipmentCheck } = props;

    this.state = {
      showMenu: false,
      testedEquipment: Equipment.none,
      showPermissionsModal: false,
      equipmentCheck: DEFAULT_EQUIPMENT_CHECK,
      syncDate: lastEquipmentCheck.syncDate,
      permissions: {
        audioError: null,
        videoError: null,
        errors: []
      },
      shouldRestart: false
    };
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const { equipmentCheck, permissions } = this.state;

    const newEquipmentCheck = getEquipmentInformation({
      ...equipmentCheck
    });

    if (IS_WEB_ENABLE) {
      const results = await checkMediaPermissions(
        permissions.audioError,
        permissions.videoError,
        permissions.errors
      );

      if (results) {
        this.setState({
          showPermissionsModal: !!results.audioError || !!results.videoError,
          equipmentCheck: newEquipmentCheck,
          permissions: results
        });
      }
    } else {
      ipcRenderer.send("ASK_PROCTORING_PERM");

      ipcRenderer.on("ASK_PROCTORING_PERM_RESPOND", (event, arg) => {
        this.setState({
          showPermissionsModal: !arg,
          permissions: {
            ...permissions,
            videoError: arg
              ? null
              : {
                  name: "error"
                }
          }
        });
      });

      ipcRenderer.send("ASK_AUDIO_PERM");

      ipcRenderer.on("ASK_AUDIO_PERM_RESPOND", (event, arg) => {
        this.setState({
          showPermissionsModal: !arg,
          permissions: {
            ...permissions,
            audioError: arg
              ? null
              : {
                  name: "error"
                }
          }
        });
      });
    }

    if (!IS_WEB_ENABLE) {
      ipcRenderer.send("GET_EQUIPMENT_INFO");

      ipcRenderer.on("GET_EQUIPMENT_INFO_RESPOND", (event, arg) => {
        this.onEquipmentValidated(JSON.parse(arg));
      });
    }
  }

  componentWillUnmount(): void {
    const {
      authorizationToken,
      // eslint-disable-next-line no-shadow
      postLatestEquipmentCheck
    } = this.props;
    const { equipmentCheck } = this.state;
    if (authorizationToken) {
      postLatestEquipmentCheck(authorizationToken, equipmentCheck);
    }
  }

  onEquipmentValidated(newEquipmentCheck: EquipmentCheck): void {
    const { equipmentCheck } = this.state;
    this.setState({
      equipmentCheck: {
        syncDate: newEquipmentCheck.syncDate ?? equipmentCheck.syncDate,
        webBrowser: newEquipmentCheck.webBrowser ?? equipmentCheck.webBrowser,
        webVersion: newEquipmentCheck.webVersion ?? equipmentCheck.webVersion,
        desktopVersion:
          newEquipmentCheck.desktopVersion ?? equipmentCheck.desktopVersion,
        osName: newEquipmentCheck.osName ?? equipmentCheck.osName,
        osVersion: newEquipmentCheck.osVersion ?? equipmentCheck.osVersion,
        microphone: newEquipmentCheck.microphone ?? equipmentCheck.microphone,
        sound: newEquipmentCheck.sound ?? equipmentCheck.sound,
        camera: newEquipmentCheck.camera ?? equipmentCheck.camera,
        networkDownload:
          newEquipmentCheck.networkDownload ?? equipmentCheck.networkDownload,
        networkUpload:
          newEquipmentCheck.networkUpload ?? equipmentCheck.networkUpload,
        exam: newEquipmentCheck.exam ?? equipmentCheck.exam,
        memoryTotal:
          newEquipmentCheck.memoryTotal ?? equipmentCheck.memoryTotal,
        memoryAvailable:
          newEquipmentCheck.memoryAvailable ?? equipmentCheck.memoryAvailable,
        proxy: newEquipmentCheck.proxy ?? equipmentCheck.proxy,
        screenNumbers:
          newEquipmentCheck.screenNumbers ?? equipmentCheck.screenNumbers,
        screenResolutions:
          newEquipmentCheck.screenResolutions ??
          equipmentCheck.screenResolutions
      }
    });
  }

  setConnectionSpeed(download: number, upload: number): void {
    const { equipmentCheck } = this.state;
    const newEquipmentCheck = { ...equipmentCheck };

    if (newEquipmentCheck) {
      newEquipmentCheck.networkUpload = upload;
      newEquipmentCheck.networkDownload = download;
    }

    this.setState({
      equipmentCheck: { ...newEquipmentCheck }
    });
  }

  // Toggle the user menu
  toggleMenu(toggle?: boolean): void {
    const { showMenu } = this.state;
    this.setState({
      showMenu: toggle !== undefined ? toggle : !showMenu
    });
  }

  render(): JSX.Element {
    const { navigation, exitApplication } = this.props;
    const {
      showMenu,
      testedEquipment,
      showPermissionsModal,
      permissions,
      syncDate,
      shouldRestart
    } = this.state;

    return (
      <View style={{ flex: 1 }}>
        <CustomModal
          showCloseButton={false}
          visible={showPermissionsModal}
          onCloseModal={() => undefined}
          heightModal="20%"
        >
          <View style={styles.modalContainer}>
            <Text style={styles.modalTitle}>
              {shouldRestart
                ? i18n.t("testing.permissionsErrorPopup.titleOK")
                : i18n.t("testing.permissionsErrorPopup.titleKO")}
            </Text>
            <Text style={styles.modalDescription}>
              {shouldRestart
                ? i18n.t("testing.permissionsErrorPopup.descriptionOK")
                : i18n.t("testing.permissionsErrorPopup.descriptionKO")}
            </Text>
            <Button
              label={
                os.platform() === "darwin"
                  ? shouldRestart
                    ? i18n.t("testing.permissionsErrorPopup.restartApplication")
                    : i18n.t("testing.permissionsErrorPopup.showPreferences")
                  : i18n.t("testing.permissionsErrorPopup.dismiss")
              }
              onPress={() => {
                if (os.platform() === "darwin") {
                  if (!shouldRestart) {
                    this.setState({
                      shouldRestart: true
                    });
                    ipcRenderer.send("OPEN_SYSTEM_PREFERENCES", "security");
                  } else {
                    exitApplication("RESTART_APPLICATION");
                  }
                } else {
                  this.setState({
                    showPermissionsModal: false
                  });
                }
              }}
              containerStyle={[styles.modalButton]}
            />
          </View>
        </CustomModal>
        <TouchableWithoutFeedback
          onPress={() => {
            if (showMenu) this.toggleMenu(false);
          }}
        >
          <View style={styles.page}>
            <HeaderContainer
              toggleMenu={this.toggleMenu}
              showMenu={showMenu}
              disableSynchronisation
              navigation={navigation}
            />
            <ScrollView>
              <View style={[styles.content]}>
                <View style={styles.containerTitleAndLastCheck}>
                  <Text style={[styles.title]}>{i18n.t("testing.title")}</Text>
                  <Text style={[styles.lastCheck]}>
                    {syncDate
                      ? i18n.t("testing.lastCheck", {
                          date: moment(syncDate).format("DD/MM/YYYY"),
                          time: moment(syncDate).format("HH:mm")
                        })
                      : i18n.t("testing.noLastCheck")}
                  </Text>
                </View>
                <Text style={[styles.description]}>
                  {i18n.t("testing.description")}
                </Text>
                <View style={[styles.cardsList]}>
                  <EquipmentCard
                    icon={testCamera}
                    selected={testedEquipment === Equipment.camera}
                    title={i18n.t("testing.cameraTest")}
                    onPress={() =>
                      this.setState({
                        testedEquipment: Equipment.camera
                      })
                    }
                  />
                  <EquipmentCard
                    icon={testMicro}
                    selected={testedEquipment === Equipment.microphone}
                    title={i18n.t("testing.microphoneTest")}
                    onPress={() =>
                      this.setState({
                        testedEquipment: Equipment.microphone
                      })
                    }
                  />
                  <EquipmentCard
                    icon={testSpeed}
                    selected={testedEquipment === Equipment.internet}
                    title={i18n.t("testing.connectionTest")}
                    onPress={() =>
                      this.setState({
                        testedEquipment: Equipment.internet
                      })
                    }
                  />
                </View>
                {testedEquipment !== Equipment.none ? (
                  <Card selected={false} style={{ alignItems: "center" }}>
                    {testedEquipment === Equipment.camera ? (
                      <CameraTesting
                        hasPermission={permissions.videoError === null}
                        onEquipmentValidated={(camera: boolean) =>
                          this.onEquipmentValidated({ camera })
                        }
                      />
                    ) : null}
                    {testedEquipment === Equipment.microphone ? (
                      <MicrophoneTesting
                        hasPermission={permissions.audioError === null}
                        onEquipmentValidated={(
                          microphone: boolean,
                          sound: boolean
                        ) => this.onEquipmentValidated({ microphone, sound })}
                      />
                    ) : null}
                    {testedEquipment === Equipment.internet ? (
                      <ConnectionTesting
                        onTestCompleted={(
                          downloadSpeed: number,
                          uploadSpeed: number
                        ) =>
                          this.setConnectionSpeed(downloadSpeed, uploadSpeed)
                        }
                      />
                    ) : null}
                  </Card>
                ) : null}
              </View>
            </ScrollView>
          </View>
        </TouchableWithoutFeedback>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  page: {
    flex: 1,
    backgroundColor: COLOR_WHITE
  },
  containerTitleAndLastCheck: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10
  },
  lastCheck: {
    width: "30%",
    fontSize: FONTSIZE_13,
    fontFamily: FONT_GILROY_BOLD,
    color: COLOR_BLUE_TESTWE
  },
  content: {
    paddingHorizontal: PADDING_SIDES * 4,
    paddingVertical: PADDING_SIDES * 0.5
  },
  title: {
    marginLeft: "30%",
    width: "40%",
    fontSize: FONTSIZE_21,
    fontFamily: FONT_GILROY_BOLD,
    textAlign: "center",
    color: COLOR_BLUE_TESTWE
  },
  description: {
    fontSize: FONTSIZE_13,
    fontFamily: FONT_GILROY_REGULAR,
    textAlign: "left",
    color: COLOR_BLUE_TESTWE,
    paddingBottom: PADDING_SIDES * 0.5
  },
  cardsList: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: PADDING_SIDES * 0.5
  },
  modalContainer: {
    flex: 1,
    justifyContent: "space-evenly",
    padding: PADDING_SIDES * 0.1
  },
  modalTitle: {
    width: "100%",
    fontWeight: "bold",
    textAlign: "center",
    color: COLOR_BLUE_TESTWE
  },
  modalDescription: {
    width: "100%",
    textAlign: "center",
    color: COLOR_BLUE_TESTWE
  },
  modalButton: {
    alignSelf: "center",
    backgroundColor: COLOR_BLUE_TESTWE,
    borderColor: COLOR_BLUE_TESTWE,
    color: COLOR_WHITE
  }
});

const mapStateToProps = (state: RootState) => {
  return {
    lastEquipmentCheck: state.equipmentCheck || DEFAULT_EQUIPMENT_CHECK,
    // eslint-disable-next-line camelcase
    authorizationToken: state.auth.credentials?.access_token
  };
};

const mapdispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators(
      {
        postLatestEquipmentCheck,
        exitApplication: startExitingApplication
      },
      dispatch
    )
  };
};

const TestingContainer = connect(
  mapStateToProps,
  mapdispatchToProps
)(TestingView);

export default TestingContainer;
