import React from "react";
import { StyleSheet, Text } from "react-native";
import { COLOR_BLUE_TESTWE, COLOR_RED } from "../../../static/misc/colors";
import {
  FONTSIZE_11,
  FONT_GILROY_REGULAR
} from "../../../static/misc/constants";
import i18n from "../../services/i18n";

interface CharMaxProps {
  nbChar: number;
  nbCharMax: number;
}

class CharMax extends React.PureComponent<CharMaxProps> {
  render(): JSX.Element {
    const { nbChar, nbCharMax } = this.props;
    return (
      <Text>
        <Text
          style={[
            styles.textStyle,
            nbChar <= nbCharMax ? {} : { color: COLOR_RED }
          ]}
        >{`${nbChar}`}</Text>
        <Text style={styles.textStyle}>{` / ${nbCharMax} ${i18n.t(
          "exam.charMax"
        )}`}</Text>
      </Text>
    );
  }
}

const styles = StyleSheet.create({
  textStyle: {
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_11,
    color: COLOR_BLUE_TESTWE
  }
});

export default CharMax;
