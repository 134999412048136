import { ipcRenderer } from "electron";
import moment from "moment";
import {
  StatusState,
  StatusActionType,
  ISONLINE,
  httpStatus,
  SET_NB_SYNC_EXAMS,
  SubmittingMediasObjectsStatus,
  START_STUDENTPAPER_SUBMISSION,
  SubmittingStudentPaperStatus,
  RETRY_STUDENTPAPER_SUBMISSION,
  END_STUDENTPAPER_SUBMISSION,
  ABORT_STUDENTPAPER_SUBMISSION,
  SET_SYNC_EXAM,
  ADD_EXAM_ASYNC_ACTION,
  DELETE_EXAM_ASYNC_ACTION,
  COMPARE_TIME_WITH_SERVER,
  COMPARE_TIME_WITH_SERVER_SUCCESS,
  COMPARE_TIME_WITH_SERVER_FAIL,
  TimeVerificationStatus,
  EXITING_APPLICATION,
  CANCEL_EXITING_APPLICATION,
  SAFE_EXAM_BROWSER_NOT_FOUND,
  TOGGLE_STARTNG_EXAM,
  SET_EXAM_SYNC_STATUS,
  SyncStatus
} from "../types/status";
import { IS_PREVIEW, IS_WEB_ENABLE } from "../../../../static/misc/constants";
import { MAX_RETRY_NUMBER } from "../../../../static/misc/network";
import { guidGenerator } from "../../../../static/misc/utils";
import {
  REFRESH_TOKEN,
  REFRESH_TOKEN_FAIL,
  REFRESH_TOKEN_SUCCESS
} from "../../authentification/types/auth";
import {
  DOWNLOAD_MEDIA_OBJECT_FAIL,
  DOWNLOAD_MEDIA_OBJECT_SUCCESS,
  SET_MEDIA_OBJECT,
  UPDATE_MEDIA_OBJECT,
  UPLOAD_DATA_API,
  UPLOAD_DATA_API_FAIL,
  UPLOAD_DATA_API_SUCCESS,
  UPLOAD_DATA_AWS,
  UPLOAD_DATA_AWS_FAIL,
  UPLOAD_DATA_AWS_SUCCESS,
  UPLOAD_MEDIA_OBJECT,
  UPLOAD_MEDIA_OBJECT_FAIL,
  UPLOAD_MEDIA_OBJECT_SUCCESS
} from "../../exams/types/attachedfiles";
import {
  GET_EXAMS_HASH,
  GET_EXAMS_HASH_FAIL,
  GET_EXAMS_HASH_SUCCESS,
  GET_EXAM_FOR_PREVIEW,
  GET_EXAM_FOR_PREVIEW_FAIL,
  GET_EXAM_FOR_PREVIEW_SUCCESS,
  GET_SYNC_EXAM_FAIL,
  GET_SYNC_PAST_EXAM_FAIL,
  GET_SYNC_PAST_EXAM_SUCCESS,
  GET_SYNC_EXAM_SUCCESS
} from "../../exams/types/exams";
import {
  GET_EXAM_PUBLIC_KEY,
  GET_EXAM_PUBLIC_KEY_FAIL,
  GET_EXAM_PUBLIC_KEY_SUCCESS
} from "../../exams/types/publickeys";
import {
  StudentAnswerType,
  POST_STUDENT_PAPER,
  POST_STUDENT_PAPER_FAIL,
  POST_STUDENT_PAPER_SUCCESS,
  UPDATE_STUDENT_PAPER,
  UPDATE_STUDENT_PAPER_FAIL,
  UPDATE_STUDENT_PAPER_SUCCESS
} from "../../examTaking/types/studentPaper";
import {
  GET_USER,
  GET_USER_FAIL,
  GET_USER_SUCCESS,
  SET_ESTABLISHMENT,
  SET_ESTABLISHMENT_FAIL,
  SET_ESTABLISHMENT_SUCCESS
} from "../types/user";

const initialState: StatusState = {
  isOnline: false,
  showLoader: true,
  isExamSynchronising: false,
  isRefreshingToken: false,
  gettingUserInfo: true, // getting user info should be done as soon as the app is launched
  examAsyncActions: [],
  gettingExam: false,
  gettingExamsHash: false,
  gettingPublicKey: false,
  gettingPreviewExam: false,
  nbExamsToSync: 0,
  nbMediaObjectToGet: 0,
  nbStudentPaperToSync: 0,
  examAsyncNb: 0,
  submittingMediaObjectsStatus: [],
  submittingStudentPaperStatus: [],
  syncExams: true,
  postingEstablishment: false,
  timeVerificationStatus: TimeVerificationStatus.NOT_VERIFIED,
  timeVerificationCheck: false,
  exitingApplication: false,
  isExamStarting: false,
  examSyncStatus: SyncStatus.SUCCESS
};

export default function reducer(
  state = initialState,
  action: StatusActionType
): StatusState {
  switch (action.type) {
    case REFRESH_TOKEN: {
      return {
        ...state,
        isRefreshingToken: true
      };
    }

    case REFRESH_TOKEN_SUCCESS:
    case REFRESH_TOKEN_FAIL: {
      return {
        ...state,
        isRefreshingToken: false
      };
    }

    case ADD_EXAM_ASYNC_ACTION: {
      const examAsyncActionsTmp = [...state.examAsyncActions];
      examAsyncActionsTmp.push(action.payload);
      return {
        ...state,
        examAsyncNb: state.examAsyncNb + 1,
        examAsyncActions: examAsyncActionsTmp
      };
    }

    case DELETE_EXAM_ASYNC_ACTION: {
      const examAsyncActionsTmp = [...state.examAsyncActions];
      const examAsyncActionsIndexItem = examAsyncActionsTmp.findIndex(
        (e) => e === action.payload
      );
      if (examAsyncActionsIndexItem >= 0)
        examAsyncActionsTmp.splice(examAsyncActionsIndexItem, 1);
      return {
        ...state,
        examAsyncNb: state.examAsyncNb - 1,
        examAsyncActions: examAsyncActionsTmp
      };
    }

    case SET_NB_SYNC_EXAMS:
      return {
        ...state,
        nbExamsToSync: action.payload,
        syncExams: action.payload !== 0,
        showLoader: action.payload !== 0
      };

    case GET_EXAMS_HASH:
      return {
        ...state,
        gettingExamsHash: true,
        nbExamsToSync: 0,
        nbMediaObjectToGet: 0
      };

    case SET_SYNC_EXAM:
      return {
        ...state,
        syncExams: action.payload
      };

    case SET_MEDIA_OBJECT:
      return {
        ...state,
        nbMediaObjectToGet: state.nbMediaObjectToGet + 1
      };

    case UPDATE_MEDIA_OBJECT:
      return {
        ...state,
        nbMediaObjectToGet:
          state.nbMediaObjectToGet > 0 ? state.nbMediaObjectToGet - 1 : 0,
        syncExams: state.nbMediaObjectToGet - 1 !== 0
      };

    case DOWNLOAD_MEDIA_OBJECT_SUCCESS:
    case DOWNLOAD_MEDIA_OBJECT_FAIL: {
      const updatedNbMediasTosync =
        state.nbMediaObjectToGet > 0 ? state.nbMediaObjectToGet - 1 : 0;
      return {
        ...state,
        nbMediaObjectToGet: updatedNbMediasTosync,
        syncExams: state.nbMediaObjectToGet - 1 !== 0,
        showLoader: !IS_PREVIEW ? state.showLoader : updatedNbMediasTosync > 0
      };
    }

    case GET_EXAMS_HASH_SUCCESS:
    case GET_EXAMS_HASH_FAIL:
      return {
        ...state,
        gettingExamsHash: false
      };

    case GET_EXAM_FOR_PREVIEW: {
      return {
        ...state,
        gettingPreviewExam: true
      };
    }

    case GET_EXAM_FOR_PREVIEW_SUCCESS:
    case GET_EXAM_FOR_PREVIEW_FAIL: {
      const updatedNbExamsToSync =
        state.nbExamsToSync > 0 ? state.nbExamsToSync - 1 : 0;
      const hasMedias =
        action.payload &&
        action.payload.data &&
        action.payload.data.allAttachedFiles &&
        action.payload.data.allAttachedFiles.length > 0;
      return {
        ...state,
        gettingPreviewExam: action.type === GET_EXAM_FOR_PREVIEW_FAIL,
        nbExamsToSync: updatedNbExamsToSync,
        showLoader: action.type === GET_EXAM_FOR_PREVIEW_FAIL || hasMedias
      };
    }

    case SET_EXAM_SYNC_STATUS: {
      const examSyncStatusAction = { ...action };

      return { ...state, examSyncStatus: examSyncStatusAction.payload };
    }

    case GET_SYNC_EXAM_SUCCESS:
    case GET_SYNC_PAST_EXAM_SUCCESS:
    case GET_SYNC_PAST_EXAM_FAIL:
    case GET_SYNC_EXAM_FAIL: {
      const updatedNbExamsToSync =
        state.nbExamsToSync > 0 ? state.nbExamsToSync - 1 : 0;
      let newExamSyncStatus = state.examSyncStatus;

      if (action.type === GET_SYNC_EXAM_SUCCESS) {
        newExamSyncStatus = SyncStatus.SUCCESS;
      }

      if (action.type === GET_SYNC_EXAM_FAIL) {
        newExamSyncStatus = SyncStatus.FAIL;
      }

      return {
        ...state,
        nbExamsToSync: updatedNbExamsToSync,
        showLoader: updatedNbExamsToSync > 0,
        examSyncStatus: newExamSyncStatus
      };
    }

    case GET_EXAM_PUBLIC_KEY: {
      return {
        ...state,
        gettingPublicKey: true,
        gettingExam: true
      };
    }

    case GET_EXAM_PUBLIC_KEY_SUCCESS:
    case GET_EXAM_PUBLIC_KEY_FAIL: {
      return {
        ...state,
        gettingPublicKey: false,
        gettingExam: false
      };
    }

    case GET_USER: {
      return {
        ...state,
        gettingUserInfo: true
      };
    }

    case GET_USER_SUCCESS:
    case GET_USER_FAIL: {
      return {
        ...state,
        gettingUserInfo: false
      };
    }

    case START_STUDENTPAPER_SUBMISSION: {
      const studentPaperStatusArray = [...state.submittingStudentPaperStatus];
      const index = studentPaperStatusArray.findIndex(
        (studentPaper) =>
          studentPaper.examId === action.payload.studentPaper.examId
      );
      const tmpStudentAnswers = [
        ...action.payload.studentPaper.studentAnswers
      ] as StudentAnswerType[];
      const answersWithMedias = tmpStudentAnswers.filter(
        (answer: StudentAnswerType) =>
          answer.mediaObjects &&
          answer.mediaObjects.length > 0 &&
          answer.mediaObjects.filter((media) => media.indexOf("internal-") > -1)
            .length > 0
      );
      const hasMedias = answersWithMedias.length > 0;

      if (index === -1) {
        const studentPaperStatus: SubmittingStudentPaperStatus = {
          examId: action.payload.studentPaper.examId,
          studentPaperId: action.payload.studentPaper.studentPaperId,
          submittingStudentPaper: true,
          submittingStudentPaperStatus: httpStatus.INIT,
          submittingMediaObjects: hasMedias,
          submittingMediaObjectsStatus: hasMedias
            ? httpStatus.PENDING
            : httpStatus.NONE,
          retryCount: 0
        };
        studentPaperStatusArray.push(studentPaperStatus);
      } else {
        const studentPaperStatus = {
          ...studentPaperStatusArray[index]
        } as SubmittingStudentPaperStatus;
        studentPaperStatus.submittingStudentPaper = true;
        studentPaperStatus.submittingStudentPaperStatus = httpStatus.INIT;
        studentPaperStatus.submittingMediaObjects = hasMedias;
        studentPaperStatus.submittingMediaObjectsStatus = hasMedias
          ? httpStatus.PENDING
          : httpStatus.NONE;
        studentPaperStatus.retryCount += 1;
        studentPaperStatusArray[index] = studentPaperStatus;
      }

      // Setting medias status if hasMedias
      if (answersWithMedias.length > 0) {
        const mediaStatusArray = [...state.submittingMediaObjectsStatus];
        answersWithMedias.forEach((answer) => {
          const mediaIndex = mediaStatusArray.findIndex(
            (status) =>
              status.examId === action.payload.studentPaper.examId &&
              status.questionId.replace("/questions/", "") ===
                answer.question.replace("/questions/", "")
          );
          if (mediaIndex === -1) {
            const mediaStatus: SubmittingMediasObjectsStatus = {
              examId: action.payload.studentPaper.examId,
              studentPaperId: action.payload.studentPaper.studentPaperId,
              questionId: answer.question.replace("/questions/", ""),
              // IN CASE IF THERE IS ONLY ONE MEDIA MAXIMUM PER QUESTION
              mediaId:
                answer?.mediaObjects && answer.mediaObjects[0]
                  ? answer.mediaObjects[0]
                  : guidGenerator(),
              // -----
              submittingMediaObject: true,
              submittingMediaObjectStatus: httpStatus.INIT,
              retryCount: 0
            };
            mediaStatusArray.push(mediaStatus);
          } else {
            const status = {
              ...mediaStatusArray[mediaIndex]
            } as SubmittingMediasObjectsStatus;
            // We don't want to reupload files which were already successfully uploaded
            if (status.submittingMediaObjectStatus !== httpStatus.SUCCESS) {
              status.submittingMediaObject = true;
              status.submittingMediaObjectStatus = httpStatus.INIT;
            }
            mediaStatusArray[mediaIndex] = status;
          }
        });
        return {
          ...state,
          submittingStudentPaperStatus: studentPaperStatusArray,
          submittingMediaObjectsStatus: mediaStatusArray,
          nbStudentPaperToSync: state.nbStudentPaperToSync + 1
        };
      }

      return {
        ...state,
        submittingStudentPaperStatus: studentPaperStatusArray,
        nbStudentPaperToSync: state.nbStudentPaperToSync + 1
      };
    }

    case RETRY_STUDENTPAPER_SUBMISSION: {
      const studentPaperStatusArray = [...state.submittingStudentPaperStatus];
      const spIndex = studentPaperStatusArray.findIndex(
        (spStatus) => spStatus.examId === action.payload.studentPaper.examId
      );
      const tmpStudentPaperStatus = { ...studentPaperStatusArray[spIndex] };
      const tmpStudentAnswers = [
        ...action.payload.studentPaper.studentAnswers
      ] as StudentAnswerType[];
      const answersWithMedias = tmpStudentAnswers.filter(
        (answer: StudentAnswerType) =>
          answer.mediaObjects &&
          answer.mediaObjects.length > 0 &&
          answer.mediaObjects.indexOf("internal-") > -1
      );
      const hasMedias = answersWithMedias.length > 0;

      tmpStudentPaperStatus.submittingMediaObjects = hasMedias;
      tmpStudentPaperStatus.submittingMediaObjectsStatus = hasMedias
        ? httpStatus.PENDING
        : httpStatus.NONE;
      tmpStudentPaperStatus.submittingStudentPaper = true;
      tmpStudentPaperStatus.submittingStudentPaperStatus = httpStatus.INIT;
      tmpStudentPaperStatus.retryCount += 1;
      studentPaperStatusArray[spIndex] = tmpStudentPaperStatus;

      if (hasMedias) {
        const mediaStatusArray = [...state.submittingMediaObjectsStatus];
        answersWithMedias.forEach((answer) => {
          const mediaIndex = mediaStatusArray.findIndex(
            (status) =>
              status.examId === action.payload.studentPaper.examId &&
              status.questionId.replace("/questions/", "") ===
                answer.question.replace("/questions/", "")
          );
          const status = {
            ...mediaStatusArray[mediaIndex]
          } as SubmittingMediasObjectsStatus;
          status.submittingMediaObject = true;
          status.submittingMediaObjectStatus = httpStatus.INIT;
          status.retryCount = 0;
          mediaStatusArray[mediaIndex] = status;
        });
        return {
          ...state,
          submittingStudentPaperStatus: studentPaperStatusArray,
          submittingMediaObjectsStatus: mediaStatusArray,
          nbStudentPaperToSync: state.nbStudentPaperToSync + 1
        };
      }
      return {
        ...state,
        submittingStudentPaperStatus: studentPaperStatusArray,
        nbStudentPaperToSync: state.nbStudentPaperToSync + 1
      };
    }

    case END_STUDENTPAPER_SUBMISSION: {
      const studentPaperStatusArray = state.submittingStudentPaperStatus.filter(
        (spStatus) => spStatus.examId !== action.payload.studentPaper.examId
      );

      const mediaStatusArray = state.submittingMediaObjectsStatus.filter(
        (mediaStatus) =>
          mediaStatus.examId !== action.payload.studentPaper.examId
      );

      return {
        ...state,
        submittingStudentPaperStatus: studentPaperStatusArray,
        submittingMediaObjectsStatus: mediaStatusArray,
        nbStudentPaperToSync:
          state.nbStudentPaperToSync > 0 ? state.nbStudentPaperToSync - 1 : 0
      };
    }

    case ABORT_STUDENTPAPER_SUBMISSION: {
      const studentPaperStatusArray = [...state.submittingStudentPaperStatus];
      const spIndex = studentPaperStatusArray.findIndex(
        (spStatus) => spStatus.examId === action.payload.studentPaper.examId
      );
      const tmpStudentPaperStatus = { ...studentPaperStatusArray[spIndex] };

      const mediaStatusArray = [...state.submittingMediaObjectsStatus];
      const currentExamMedias = mediaStatusArray.filter(
        (media) => media.examId === action.payload.studentPaper.examId
      );

      const hasMedias = currentExamMedias.length > 0;
      tmpStudentPaperStatus.submittingStudentPaper = false;
      tmpStudentPaperStatus.submittingStudentPaperStatus = httpStatus.ABORTED;
      tmpStudentPaperStatus.submittingMediaObjects = false;
      tmpStudentPaperStatus.submittingMediaObjectsStatus = hasMedias
        ? httpStatus.ABORTED
        : httpStatus.NONE;
      tmpStudentPaperStatus.retryCount += 1;
      studentPaperStatusArray[spIndex] = tmpStudentPaperStatus;

      if (hasMedias) {
        const tmpMediaStatusArray = [...state.submittingMediaObjectsStatus];
        tmpMediaStatusArray.forEach((media, index) => {
          if (media.examId === action.payload.studentPaper.examId) {
            const status = {
              ...mediaStatusArray[index]
            } as SubmittingMediasObjectsStatus;
            status.submittingMediaObject = false;
            status.submittingMediaObjectStatus =
              status.submittingMediaObjectStatus !== httpStatus.SUCCESS
                ? httpStatus.ABORTED
                : status.submittingMediaObjectStatus;
            status.retryCount = 0;
            mediaStatusArray[index] = status;
          }
        });
        return {
          ...state,
          submittingStudentPaperStatus: studentPaperStatusArray,
          submittingMediaObjectsStatus: mediaStatusArray,
          nbStudentPaperToSync:
            state.nbStudentPaperToSync > 0 ? state.nbStudentPaperToSync - 1 : 0
        };
      }
      return {
        ...state,
        submittingStudentPaperStatus: studentPaperStatusArray,
        nbStudentPaperToSync:
          state.nbStudentPaperToSync > 0 ? state.nbStudentPaperToSync - 1 : 0
      };
    }

    case POST_STUDENT_PAPER: {
      if (action.payload.request.data.endDate !== null) {
        const studentPaperStatusArray = [...state.submittingStudentPaperStatus];
        const spIndex = studentPaperStatusArray.findIndex(
          (spStatus) => spStatus.examId === action.payload.request.data.examId
        );
        const tmpStudentPaperStatus = { ...studentPaperStatusArray[spIndex] };
        tmpStudentPaperStatus.submittingStudentPaper = true;
        tmpStudentPaperStatus.submittingStudentPaperStatus = httpStatus.PENDING;
        studentPaperStatusArray[spIndex] = tmpStudentPaperStatus;
        return {
          ...state,
          submittingStudentPaperStatus: studentPaperStatusArray
        };
      }
      return {
        ...state
      };
    }

    case POST_STUDENT_PAPER_SUCCESS: {
      const req = action.meta.previousAction.payload.request.data;
      if (req.endDate !== null) {
        const studentPaperStatusArray = [...state.submittingStudentPaperStatus];
        const spIndex = studentPaperStatusArray.findIndex(
          (spStatus) => spStatus.examId === req.examId
        );
        const tmpStudentPaperStatus = { ...studentPaperStatusArray[spIndex] };
        tmpStudentPaperStatus.submittingStudentPaper = false;
        tmpStudentPaperStatus.submittingStudentPaperStatus = httpStatus.SUCCESS;
        studentPaperStatusArray[spIndex] = tmpStudentPaperStatus;
        return {
          ...state,
          submittingStudentPaperStatus: studentPaperStatusArray
        };
      }
      return {
        ...state
      };
    }

    case POST_STUDENT_PAPER_FAIL: {
      const req = action.meta.previousAction.payload.request.data;
      if (req.endDate !== null) {
        const studentPaperStatusArray = [...state.submittingStudentPaperStatus];
        const spIndex = studentPaperStatusArray.findIndex(
          (spStatus) => spStatus.examId === req.examId
        );
        const tmpStudentPaperStatus = { ...studentPaperStatusArray[spIndex] };
        tmpStudentPaperStatus.submittingStudentPaper = false;
        tmpStudentPaperStatus.submittingStudentPaperStatus = httpStatus.FAIL;
        studentPaperStatusArray[spIndex] = tmpStudentPaperStatus;
        return {
          ...state,
          submittingStudentPaperStatus: studentPaperStatusArray
        };
      }
      return {
        ...state
      };
    }

    case UPDATE_STUDENT_PAPER: {
      const studentPaperStatusArray = [...state.submittingStudentPaperStatus];
      const spIndex = studentPaperStatusArray.findIndex(
        (spStatus) => spStatus.examId === action.payload.request.data.examId
      );
      const tmpStudentPaperStatus = { ...studentPaperStatusArray[spIndex] };
      tmpStudentPaperStatus.submittingStudentPaper = true;
      tmpStudentPaperStatus.submittingStudentPaperStatus = httpStatus.PENDING;
      studentPaperStatusArray[spIndex] = tmpStudentPaperStatus;
      return {
        ...state,
        submittingStudentPaperStatus: studentPaperStatusArray
      };
    }

    case UPDATE_STUDENT_PAPER_SUCCESS: {
      const studentPaperStatusArray = [...state.submittingStudentPaperStatus];
      const spIndex = studentPaperStatusArray.findIndex(
        (spStatus) =>
          spStatus.examId ===
          action.meta.previousAction.payload.request.data.examId
      );
      const tmpStudentPaperStatus = { ...studentPaperStatusArray[spIndex] };

      tmpStudentPaperStatus.submittingStudentPaper = false;
      tmpStudentPaperStatus.submittingStudentPaperStatus = httpStatus.SUCCESS;
      studentPaperStatusArray[spIndex] = tmpStudentPaperStatus;

      return {
        ...state,
        submittingStudentPaperStatus: studentPaperStatusArray
      };
    }

    case UPDATE_STUDENT_PAPER_FAIL: {
      const studentPaperStatusArray = [...state.submittingStudentPaperStatus];
      const spIndex = studentPaperStatusArray.findIndex(
        (spStatus) =>
          spStatus.examId ===
          action.meta.previousAction.payload.request.data.examId
      );
      const tmpStudentPaperStatus = { ...studentPaperStatusArray[spIndex] };

      tmpStudentPaperStatus.submittingStudentPaper = false;
      tmpStudentPaperStatus.submittingStudentPaperStatus = httpStatus.FAIL;
      studentPaperStatusArray[spIndex] = tmpStudentPaperStatus;

      return {
        ...state,
        submittingStudentPaperStatus: studentPaperStatusArray
      };
    }

    case UPLOAD_MEDIA_OBJECT: {
      const mediaStatusArray = [...state.submittingMediaObjectsStatus];
      const index = mediaStatusArray.findIndex(
        (status) =>
          status.examId === action.payload.studentPaperMeta.examId &&
          status.questionId.replace("/questions/", "") ===
            action.payload.studentPaperMeta.questionId.replace(
              "/questions/",
              ""
            )
      );
      if (index === -1) {
        const mediaStatus: SubmittingMediasObjectsStatus = {
          examId: action.payload.studentPaperMeta.examId,
          studentPaperId: action.payload.studentPaperMeta.studentPaperId,
          questionId: action.payload.studentPaperMeta.questionId.replace(
            "/questions/",
            ""
          ),
          mediaId: action.payload.filename,
          submittingMediaObject: true,
          submittingMediaObjectStatus: httpStatus.PENDING,
          retryCount: 0
        };
        mediaStatusArray.push(mediaStatus);
      } else {
        const status = {
          ...mediaStatusArray[index]
        } as SubmittingMediasObjectsStatus;
        status.submittingMediaObject = true;
        status.submittingMediaObjectStatus = httpStatus.PENDING;
        mediaStatusArray[index] = status;
      }
      return {
        ...state,
        submittingMediaObjectsStatus: mediaStatusArray
      };
    }

    case UPLOAD_MEDIA_OBJECT_SUCCESS: {
      const mediaStatusArray = [...state.submittingMediaObjectsStatus];
      const index = mediaStatusArray.findIndex(
        (status) =>
          status.examId ===
            action.meta.previousAction.payload.studentPaperMeta.examId &&
          status.questionId.replace("/questions/", "") ===
            action.meta.previousAction.payload.studentPaperMeta.questionId.replace(
              "/questions/",
              ""
            )
      );

      if (index > -1) {
        const status = {
          ...mediaStatusArray[index]
        } as SubmittingMediasObjectsStatus;
        status.submittingMediaObject = false;
        status.submittingMediaObjectStatus = httpStatus.SUCCESS;
        mediaStatusArray[index] = status;

        const allMediasForExam = mediaStatusArray.filter(
          (st) =>
            st.examId ===
            action.meta.previousAction.payload.studentPaperMeta.examId
        );
        // If all medias were all successfully uploaded, we retrieve the student paper status
        if (
          allMediasForExam.length ===
          allMediasForExam.filter(
            (stm) => stm.submittingMediaObjectStatus === httpStatus.SUCCESS
          ).length
        ) {
          const studentPaperStatus = [...state.submittingStudentPaperStatus];
          const spIdx = studentPaperStatus.findIndex(
            (st) =>
              st.examId ===
              action.meta.previousAction.payload.studentPaperMeta.examId
          );
          // We update the current student paper to end the media upload
          if (spIdx > -1) {
            const spTmp = { ...studentPaperStatus[spIdx] };
            spTmp.submittingMediaObjects = false;
            spTmp.submittingMediaObjectsStatus = httpStatus.SUCCESS;
            studentPaperStatus[spIdx] = spTmp;
            return {
              ...state,
              submittingMediaObjectsStatus: mediaStatusArray,
              submittingStudentPaperStatus: studentPaperStatus
            };
          }
        }
        return {
          ...state,
          submittingMediaObjectsStatus: mediaStatusArray
        };
      }
      return {
        ...state
      };
    }

    case UPLOAD_MEDIA_OBJECT_FAIL: {
      if (IS_WEB_ENABLE) {
        ipcRenderer.send("LOG_INFO", "failed uploading media object");
      }
      const mediaStatusArray = [...state.submittingMediaObjectsStatus];
      const index = mediaStatusArray.findIndex(
        (status) =>
          status.examId ===
            action.meta.previousAction.payload.studentPaperMeta.examId &&
          status.questionId.replace("/questions/", "") ===
            action.meta.previousAction.payload.studentPaperMeta.questionId.replace(
              "/questions/",
              ""
            )
      );
      if (index > -1) {
        const status = {
          ...mediaStatusArray[index]
        } as SubmittingMediasObjectsStatus;
        status.submittingMediaObject = false;
        status.submittingMediaObjectStatus = httpStatus.FAIL;
        status.retryCount += 1;
        mediaStatusArray[index] = status;

        const allMediasForExam = mediaStatusArray.filter(
          (st) =>
            st.examId ===
            action.meta.previousAction.payload.studentPaperMeta.examId
        );
        // If at least 1 media was not successfully uploaded and has the max retryCount
        if (
          allMediasForExam.filter(
            (stm) =>
              stm.submittingMediaObjectStatus === httpStatus.FAIL &&
              stm.retryCount >= MAX_RETRY_NUMBER
          ).length > 0
        ) {
          const studentPaperStatus = [...state.submittingStudentPaperStatus];
          const spIdx = studentPaperStatus.findIndex(
            (st) =>
              st.examId ===
              action.meta.previousAction.payload.studentPaperMeta.examId
          );
          // We update the current student paper to end the media upload and to set the student paper submission to FAIL
          if (spIdx > -1) {
            const spTmp = { ...studentPaperStatus[spIdx] };
            spTmp.submittingMediaObjects = false;
            spTmp.submittingMediaObjectsStatus = httpStatus.FAIL;
            spTmp.submittingStudentPaperStatus = httpStatus.FAIL;
            studentPaperStatus[spIdx] = spTmp;
            return {
              ...state,
              submittingMediaObjectsStatus: mediaStatusArray,
              submittingStudentPaperStatus: studentPaperStatus
            };
          }
        }
        return {
          ...state,
          submittingMediaObjectsStatus: mediaStatusArray
        };
      }
      return {
        ...state
      };
    }

    case UPLOAD_DATA_API: {
      const mediaStatusArray = [...state.submittingMediaObjectsStatus];
      const index = mediaStatusArray.findIndex(
        (status) =>
          status.examId === action.payload.studentPaperMeta.examId &&
          status.questionId.replace("/questions/", "") ===
            action.payload.studentPaperMeta.questionId.replace(
              "/questions/",
              ""
            )
      );
      if (index === -1) {
        const mediaStatus: SubmittingMediasObjectsStatus = {
          examId: action.payload.studentPaperMeta.examId,
          studentPaperId: action.payload.studentPaperMeta.studentPaperId,
          questionId: action.payload.studentPaperMeta.questionId.replace(
            "/questions/",
            ""
          ),
          mediaId: action.payload.filename,
          submittingMediaObject: true,
          submittingMediaObjectStatus: httpStatus.PENDING,
          retryCount: 0
        };
        mediaStatusArray.push(mediaStatus);
      } else {
        const status = {
          ...mediaStatusArray[index]
        } as SubmittingMediasObjectsStatus;
        status.submittingMediaObject = true;
        status.submittingMediaObjectStatus = httpStatus.PENDING;
        mediaStatusArray[index] = status;
      }
      return {
        ...state,
        submittingMediaObjectsStatus: mediaStatusArray
      };
    }

    case UPLOAD_DATA_API_SUCCESS: {
      const mediaStatusArray = [...state.submittingMediaObjectsStatus];
      const index = mediaStatusArray.findIndex(
        (status) =>
          status.examId ===
            action.meta.previousAction.payload.studentPaperMeta.examId &&
          status.questionId.replace("/questions/", "") ===
            action.meta.previousAction.payload.studentPaperMeta.questionId.replace(
              "/questions/",
              ""
            )
      );

      if (index > -1) {
        const status = {
          ...mediaStatusArray[index]
        } as SubmittingMediasObjectsStatus;
        status.submittingMediaObject = false;
        status.submittingMediaObjectStatus = httpStatus.SUCCESS;
        mediaStatusArray[index] = status;

        const allMediasForExam = mediaStatusArray.filter(
          (st) =>
            st.examId ===
            action.meta.previousAction.payload.studentPaperMeta.examId
        );
        // If all medias were all successfully uploaded, we retrieve the student paper status
        if (
          allMediasForExam.length ===
          allMediasForExam.filter(
            (stm) => stm.submittingMediaObjectStatus === httpStatus.SUCCESS
          ).length
        ) {
          const studentPaperStatus = [...state.submittingStudentPaperStatus];
          const spIdx = studentPaperStatus.findIndex(
            (st) =>
              st.examId ===
              action.meta.previousAction.payload.studentPaperMeta.examId
          );
          // We update the current student paper to end the media upload
          if (spIdx > -1) {
            const spTmp = { ...studentPaperStatus[spIdx] };
            spTmp.submittingMediaObjects = false;
            spTmp.submittingMediaObjectsStatus = httpStatus.SUCCESS;
            studentPaperStatus[spIdx] = spTmp;
            return {
              ...state,
              submittingMediaObjectsStatus: mediaStatusArray,
              submittingStudentPaperStatus: studentPaperStatus
            };
          }
        }
        return {
          ...state,
          submittingMediaObjectsStatus: mediaStatusArray
        };
      }
      return {
        ...state
      };
    }

    case UPLOAD_DATA_API_FAIL: {
      if (IS_WEB_ENABLE) {
        ipcRenderer.send("LOG_INFO", "failed uploading media object");
      }
      const mediaStatusArray = [...state.submittingMediaObjectsStatus];
      const index = mediaStatusArray.findIndex(
        (status) =>
          status.examId ===
            action.meta.previousAction.payload.studentPaperMeta.examId &&
          status.questionId.replace("/questions/", "") ===
            action.meta.previousAction.payload.studentPaperMeta.questionId.replace(
              "/questions/",
              ""
            )
      );
      if (index > -1) {
        const status = {
          ...mediaStatusArray[index]
        } as SubmittingMediasObjectsStatus;
        status.submittingMediaObject = false;
        status.submittingMediaObjectStatus = httpStatus.FAIL;
        status.retryCount += 1;
        mediaStatusArray[index] = status;

        const allMediasForExam = mediaStatusArray.filter(
          (st) =>
            st.examId ===
            action.meta.previousAction.payload.studentPaperMeta.examId
        );
        // If at least 1 media was not successfully uploaded and has the max retryCount
        if (
          allMediasForExam.filter(
            (stm) =>
              stm.submittingMediaObjectStatus === httpStatus.FAIL &&
              stm.retryCount >= MAX_RETRY_NUMBER
          ).length > 0
        ) {
          const studentPaperStatus = [...state.submittingStudentPaperStatus];
          const spIdx = studentPaperStatus.findIndex(
            (st) =>
              st.examId ===
              action.meta.previousAction.payload.studentPaperMeta.examId
          );
          // We update the current student paper to end the media upload and to set the student paper submission to FAIL
          if (spIdx > -1) {
            const spTmp = { ...studentPaperStatus[spIdx] };
            spTmp.submittingMediaObjects = false;
            spTmp.submittingMediaObjectsStatus = httpStatus.FAIL;
            spTmp.submittingStudentPaperStatus = httpStatus.FAIL;
            studentPaperStatus[spIdx] = spTmp;
            return {
              ...state,
              submittingMediaObjectsStatus: mediaStatusArray,
              submittingStudentPaperStatus: studentPaperStatus
            };
          }
        }
        return {
          ...state,
          submittingMediaObjectsStatus: mediaStatusArray
        };
      }
      return {
        ...state
      };
    }

    case TOGGLE_STARTNG_EXAM:
      return {
        ...state,
        isExamStarting: action.payload
      };

    case UPLOAD_DATA_AWS:
      return {
        ...state
      };

    case UPLOAD_DATA_AWS_SUCCESS:
      return {
        ...state
      };

    case UPLOAD_DATA_AWS_FAIL:
      ipcRenderer.send(
        "LOG_INFO",
        `an error occurred when uploading a file to ${action.meta.previousAction.payload.request.url}`
      );
      return {
        ...state
      };

    case ISONLINE: {
      let isOnline;
      if (IS_PREVIEW) {
        isOnline = true;
      } else {
        isOnline = action.payload;
      }
      return {
        ...state,
        isOnline
      };
    }

    case SET_ESTABLISHMENT:
      return { ...state, postingEstablishment: true };

    case SET_ESTABLISHMENT_FAIL:
      return { ...state, postingEstablishment: false };

    case SET_ESTABLISHMENT_SUCCESS:
      return { ...state, postingEstablishment: false };

    case COMPARE_TIME_WITH_SERVER: {
      const oldCheck = state.timeVerificationCheck;
      return {
        ...state,
        timeVerificationStatus: TimeVerificationStatus.PENDING,
        timeVerificationCheck: oldCheck
      };
    }

    case COMPARE_TIME_WITH_SERVER_SUCCESS: {
      const diff = Math.abs(
        moment(`${action.payload.data.serverTime}Z`).diff(
          moment().utc(),
          "seconds"
        )
      );

      let { timeVerificationStatus } = state;
      if (diff < 60) {
        timeVerificationStatus = TimeVerificationStatus.OK;
      } else {
        timeVerificationStatus = TimeVerificationStatus.KO;
      }
      return { ...state, timeVerificationStatus, timeVerificationCheck: true };
    }

    case COMPARE_TIME_WITH_SERVER_FAIL:
      if (state.isOnline) {
        return {
          ...state,
          timeVerificationStatus: TimeVerificationStatus.ERROR,
          timeVerificationCheck: true
        };
      }
      return {
        ...state,
        timeVerificationStatus: TimeVerificationStatus.ERROR,
        timeVerificationCheck: false
      };
    case EXITING_APPLICATION:
      return {
        ...state,
        exitingApplication: true,
        exitApplicationAction: action.payload.action
      };
    case CANCEL_EXITING_APPLICATION:
      return {
        ...state,
        exitingApplication: false,
        exitApplicationAction: undefined
      };

    case SAFE_EXAM_BROWSER_NOT_FOUND:
      return {
        ...state,
        showLoader: false
      };

    default:
      return state;
  }
}
