import { Dispatch } from "redux";
import {
  CONNECTION_TEST_FILE_URL,
  TESTWE_API,
  UPLOAD_MEDIA_OBJECTS
} from "../../../../static/misc/network";
import {
  ConnectionTestFileAction,
  DOWNLOAD_PROGRESS,
  DOWNLOAD_TEST_FILE,
  UPLOAD_PROGRESS,
  UPLOAD_TEST_FILE
} from "../types/connectionTesting";

export const downloadTestFile = () => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: DOWNLOAD_TEST_FILE,
      payload: {
        downloadStartTime: Date.now(),
        client: "blob",
        request: {
          url: `${CONNECTION_TEST_FILE_URL}?dev=${Math.floor(
            Math.random() * 100
          )}`,
          headers: {
            accept: "application/json"
          },
          onDownloadProgress: (progressEvent: any) => {
            const progress = progressEvent.loaded / progressEvent.total;
            dispatch(onDownloadProgress(progress));
          }
        },
        method: "get"
      }
    });
  };
};

export const uploadTestFile = (userToken: string, form: FormData) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: UPLOAD_TEST_FILE,
      payload: {
        uploadStartTime: Date.now(),
        request: {
          headers: {
            accept: "application/json",
            "content-type": "multipart/form-data",
            authorization: `Bearer ${userToken}`
          },
          method: "post",
          url: `${TESTWE_API}${UPLOAD_MEDIA_OBJECTS}`,
          data: form,
          onDownloadProgress: (progressEvent: any) => {
            const progress = progressEvent.loaded / progressEvent.total;
            dispatch(onUploadProgress(progress));
          }
        }
      }
    });
  };
};

const onDownloadProgress = (progress: number): ConnectionTestFileAction => {
  return {
    type: DOWNLOAD_PROGRESS,
    payload: {
      progress
    }
  };
};

const onUploadProgress = (progress: number): ConnectionTestFileAction => {
  return {
    type: UPLOAD_PROGRESS,
    payload: {
      progress
    }
  };
};
