import React from "react";
import CustomModal from "../atoms/CustomModal";
import ContentModalConfirm from "./ContentModalConfirm";
import i18n from "../../services/i18n";

export interface AntiCheatModalProps {
  isVisible: boolean;
  publicKey: string | undefined;
  onClose: () => void;
}

export default class AntiCheatModal extends React.Component<AntiCheatModalProps> {
  shouldComponentUpdate(nextProps: AntiCheatModalProps): boolean {
    const { isVisible } = this.props;
    return nextProps.isVisible !== isVisible;
  }

  render(): JSX.Element {
    const { isVisible, onClose, publicKey } = this.props;

    return (
      <CustomModal
        heightModal="50%"
        widthModal="50%"
        borderRadiusModal={5}
        showCloseButton={false}
        visible={isVisible}
        onCloseModal={() => null}
      >
        <ContentModalConfirm
          labelButton={i18n.t("modal.confirmCode")}
          textBanner={i18n.t("modal.wrongCode")}
          username={publicKey}
          description={`${i18n.t("exam.cheat")} `}
          boldText={i18n.t("exam.cheatbold")}
          descriptionNext={` ${i18n.t("exam.cheat2")}`}
          onValidation={() => onClose()}
        />
      </CustomModal>
    );
  }
}
