import React from "react";
import { StyleSheet, View } from "react-native";
import LightText from "../atoms/LightText";

export interface ExamListPlaceholderProps {
  text: string;
}

class ExamListPlaceholder extends React.PureComponent<ExamListPlaceholderProps> {
  render(): JSX.Element {
    const { text } = this.props;
    return (
      <View style={[styles.defaultContainerStyle]}>
        <LightText text={text} />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  defaultContainerStyle: {
    borderRadius: 10,
    zIndex: -10,
    height: 100, // temporar
    paddingHorizontal: "4%",
    paddingVertical: "2%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginVertical: 5
  }
});

export default ExamListPlaceholder;
