import {
  WS_DISABLE_PROCTOR_AUDIO,
  WS_ENABLE_PROCTOR_AUDIO,
  WS_GET_ROOM_AGAIN_FAILED,
  WS_GET_ROOM_AGAIN_SUCCESS,
  WS_GET_ROOM_FAILED,
  WS_GET_ROOM_SUCCESS,
  WS_PAUSE_EXAM,
  WS_STOP_EXAM,
  WS_ADD_TIME_EXAM,
  WS_UNPAUSE_EXAM
} from "../../../../static/misc/wsevents";

import { CreateStudentPaperAction } from "../../examTaking/types/studentPaper";
import { IsOnlineAction } from "../../main/types/status";

export const WS_EXAM_FINISHED = "EMIT_WS_EXAM_FINISHED";
export const EXAM_FINISHED_PENDING = "WS_EXAM_FINISHED_PENDING";
export const EXAM_FINISHED_FULFILLED = "WS_EXAM_FINISHED_FULFILLED";
export const EXAM_FINISHED_REJECTED = "WS_EXAM_FINISHED_REJECTED";

export const WS_AUTH_STUDENT = "EMIT_WS_AUTH_STUDENT";
export const AUTH_STUDENT_PENDING = "WS_AUTH_STUDENT_PENDING";
export const AUTH_STUDENT_FULFILLED = "WS_AUTH_STUDENT_FULFILLED";
export const AUTH_STUDENT_REJECTED = "WS_AUTH_STUDENT_REJECTED";

export const WS_GET_ROOM = "EMIT_WS_GET_ROOM";
export const GET_ROOM_PENDING = "WS_GET_ROOM_PENDING";
export const GET_ROOM_FULFILLED = "WS_GET_ROOM_FULFILLED";
export const GET_ROOM_REJECTED = "WS_GET_ROOM_REJECTED";

export const WS_GET_ROOM_AGAIN = "EMIT_WS_GET_ROOM_AGAIN";
export const GET_ROOM_AGAIN_PENDING = "WS_GET_ROOM_AGAIN_PENDING";
export const GET_ROOM_AGAIN_FULFILLED = "WS_GET_ROOM_AGAIN_FULFILLED";
export const GET_ROOM_AGAIN_REJECTED = "WS_GET_ROOM_AGAIN_REJECTED";

export const WS_EXAM_STUDENT_CONNECTED = "EMIT_WS_EXAM_STUDENT_CONNECTED";
export const EXAM_STUDENT_CONNECTED_PENDING =
  "WS_EXAM_STUDENT_CONNECTED_PENDING";
export const EXAM_STUDENT_CONNECTED_FULFILLED =
  "WS_EXAM_STUDENT_CONNECTED_FULFILLED";
export const EXAM_STUDENT_CONNECTED_REJECTED =
  "WS_EXAM_STUDENT_CONNECTED_REJECTED";

export const WS_EXAM_STUDENT_RECONNECTED = "EMIT_WS_EXAM_STUDENT_RECONNECTED";
export const EXAM_STUDENT_RECONNECTED_PENDING =
  "WS_EXAM_STUDENT_RECONNECTED_PENDING";
export const EXAM_STUDENT_RECONNECTED_FULFILLED =
  "WS_EXAM_STUDENT_RECONNECTED_FULFILLED";
export const EXAM_STUDENT_RECONNECTED_REJECTED =
  "WS_EXAM_STUDENT_RECONNECTED_REJECTED";

export interface EmitExamFinishNotifProctoring {
  type:
    | typeof WS_EXAM_FINISHED
    | typeof EXAM_FINISHED_PENDING
    | typeof EXAM_FINISHED_FULFILLED
    | typeof EXAM_FINISHED_REJECTED;
  payload: any;
}
export interface EmitAuthStudentProctoring {
  type:
    | typeof WS_AUTH_STUDENT
    | typeof AUTH_STUDENT_PENDING
    | typeof AUTH_STUDENT_FULFILLED
    | typeof AUTH_STUDENT_REJECTED;
  payload: any;
}

export interface EmitGetProctoringRoom {
  type:
    | typeof WS_GET_ROOM
    | typeof GET_ROOM_PENDING
    | typeof GET_ROOM_FULFILLED
    | typeof GET_ROOM_REJECTED
    | typeof WS_GET_ROOM_AGAIN
    | typeof GET_ROOM_AGAIN_PENDING
    | typeof GET_ROOM_AGAIN_FULFILLED
    | typeof GET_ROOM_AGAIN_REJECTED;
  payload: any;
}

export interface EmitProctoringConnectionStatus {
  type:
    | typeof WS_EXAM_STUDENT_CONNECTED
    | typeof WS_EXAM_STUDENT_RECONNECTED
    | typeof EXAM_STUDENT_CONNECTED_PENDING
    | typeof EXAM_STUDENT_CONNECTED_FULFILLED
    | typeof EXAM_STUDENT_CONNECTED_REJECTED
    | typeof EXAM_STUDENT_RECONNECTED_PENDING
    | typeof EXAM_STUDENT_RECONNECTED_FULFILLED
    | typeof EXAM_STUDENT_RECONNECTED_REJECTED;
  payload: any;
}

export interface ListeningOnGetRoomExam {
  type:
    | typeof WS_GET_ROOM_SUCCESS
    | typeof WS_GET_ROOM_FAILED
    | typeof WS_GET_ROOM_AGAIN_SUCCESS
    | typeof WS_GET_ROOM_AGAIN_FAILED;
  payload: any;
}

export interface ListeningAddTimeExam {
  type: typeof WS_ADD_TIME_EXAM;
  payload: any;
}
export interface ListeningOnStopExam {
  type: typeof WS_STOP_EXAM;
}
export interface ListeningOnPauseExam {
  type: typeof WS_PAUSE_EXAM;
}

export interface ListeningOnEnableProctorAudio {
  type: typeof WS_ENABLE_PROCTOR_AUDIO;
  payload: any;
}
export interface ListeningOnDisableProctorAudio {
  type: typeof WS_DISABLE_PROCTOR_AUDIO;
}

export interface ListeningOnUnPauseExam {
  type: typeof WS_UNPAUSE_EXAM;
}

export interface ProctoringLiveState {
  isWsAuth: boolean;
  proctoringRoom?: string;
  reconnection: boolean;
  examPause: boolean;
  examToBeStopped: boolean;
  proctorIdentity: string | undefined;
  proctorAudioEnable: boolean;
  twilioAccessToken?: string;
}

export type ProctoringLiveActionType =
  | IsOnlineAction
  | ListeningOnGetRoomExam
  | ListeningOnPauseExam
  | ListeningOnStopExam
  | ListeningAddTimeExam
  | EmitExamFinishNotifProctoring
  | CreateStudentPaperAction
  | ListeningOnDisableProctorAudio
  | ListeningOnEnableProctorAudio
  | EmitAuthStudentProctoring
  | EmitProctoringConnectionStatus
  | ListeningOnUnPauseExam;
