import { ipcRenderer } from "electron";
import React from "react";
import { TouchableWithoutFeedback, View } from "react-native";
import HeaderContainer from "../../atomic/organisms/Header/HeaderContainer";
import ExamsHomepage from "../../atomic/templates/exam/ExamsHomepage";
import { RouteProps, StackNavigatorProp } from "../../router/StackNavigator";
import { MediaObjectType } from "./types/attachedfiles";
import { ExamType } from "./types/exam";
import {
  ChangeOrderDisplayedExamsAction,
  ExamsOrdering,
  UpdateDisplayedExamsAction,
  GetMyPaperBase64Action
} from "./types/exams";

interface ExamsProps {
  token?: string;
  locale: string;
  navigation: StackNavigatorProp;
  isOnline: boolean;
  nextExams: ExamType[];
  passedExams: ExamType[];
  attachedFiles: MediaObjectType[];
  ordered: { nextExams: ExamsOrdering; passedExams: ExamsOrdering };
  displayedExams: DisplayedExamsEnum;
  selectedAcademicYear: string | undefined;
  updateDisplayedExams: (
    examType: DisplayedExamsEnum
  ) => UpdateDisplayedExamsAction;
  changeExamsOrder: (
    examType: DisplayedExamsEnum,
    order: DisplayedExamsOrderEnum,
    category: string
  ) => ChangeOrderDisplayedExamsAction;
  updateSelectedAcademicYear: (
    academicYear?: string
  ) => UpdateDisplayedExamsAction;
  getMyPaperBase64: (
    studentPaperId: string,
    userToken: string,
    examId: string
  ) => GetMyPaperBase64Action;
}

interface ExamsViewProps extends ExamsProps {
  route: RouteProps;
}

enum DisplayedExamsEnum {
  NEXT_EXAMS,
  PASSED_EXAMS
}

enum DisplayedExamsOrderEnum {
  ASC,
  DESC
}
export interface ExamsViewState {
  showMenu: boolean;
}
class ExamsView extends React.PureComponent<ExamsViewProps, ExamsViewState> {
  constructor(props: ExamsViewProps) {
    super(props);
    this.state = {
      showMenu: false
    };
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  async componentDidMount(): Promise<void> {
    ipcRenderer.send("LOG_INFO", "PAGEMOVE | ExamsView");
  }

  toggleMenu(toggle?: boolean): void {
    const { showMenu } = this.state;
    this.setState({
      showMenu: toggle !== undefined ? toggle : !showMenu
    });
  }

  displayExamInfo(examInfoId: string): void {
    const { navigation } = this.props;

    navigation.reset({
      index: 0,
      routes: [
        {
          name: "ExamFlow" as any,
          params: {
            screen: "ExamInfo",
            params: { examInfoId }
          }
        }
      ]
    });
  }

  render(): JSX.Element {
    const {
      token,
      locale,
      isOnline,
      nextExams,
      passedExams,
      ordered,
      displayedExams,
      route,
      navigation,
      selectedAcademicYear,
      attachedFiles,
      updateDisplayedExams,
      updateSelectedAcademicYear,
      changeExamsOrder,
      getMyPaperBase64
    } = this.props;
    const { showMenu } = this.state;

    return (
      <TouchableWithoutFeedback
        onPress={() => {
          if (showMenu) this.toggleMenu(false);
        }}
      >
        <View style={{ flex: 1, height: "100%" }}>
          <HeaderContainer
            toggleMenu={this.toggleMenu}
            showMenu={showMenu}
            currentRouteName={route.name}
            navigation={navigation}
          />
          <ExamsHomepage
            token={token}
            key={locale}
            locale={locale}
            navigation={navigation}
            isOnline={isOnline}
            nextExams={nextExams}
            passedExams={passedExams}
            attachedFiles={attachedFiles}
            ordered={ordered}
            displayedExams={displayedExams}
            selectedAcademicYear={selectedAcademicYear}
            updateDisplayedExams={(examType: DisplayedExamsEnum) =>
              updateDisplayedExams(examType)
            }
            changeExamsOrder={(
              examType: DisplayedExamsEnum,
              order: DisplayedExamsOrderEnum,
              category: string
            ) => changeExamsOrder(examType, order, category)}
            updateSelectedAcademicYear={(academicYear?: string) =>
              updateSelectedAcademicYear(academicYear)
            }
            getMyPaperBase64={getMyPaperBase64}
            displaySingleExamInfo={(examInfoId: string) =>
              this.displayExamInfo(examInfoId)
            }
          />
        </View>
      </TouchableWithoutFeedback>
    );
  }
}

export { ExamsView, ExamsProps, DisplayedExamsEnum, DisplayedExamsOrderEnum };
