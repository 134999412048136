import React from "react";

import { Text, StyleSheet } from "react-native";
import Logo from "../../atoms/Logo";
import {
  PADDING_SIDES,
  FONTSIZE_16,
  FONT_GILROY_LIGHT
} from "../../../../static/misc/constants";
import { COLOR_WHITE } from "../../../../static/misc/colors";
import i18n from "../../../services/i18n";
import Buttonsconnexion from "../../organisms/ButtonsConnexion";

class connexionTemplate extends React.PureComponent {
  render(): JSX.Element {
    return (
      <>
        <Logo />
        <Text style={styles.textStyle}>{i18n.t("noInternet")}</Text>
        <Buttonsconnexion />
      </>
    );
  }
}

const styles = StyleSheet.create({
  textStyle: {
    paddingVertical: PADDING_SIDES,
    fontSize: FONTSIZE_16,
    color: COLOR_WHITE,
    fontFamily: FONT_GILROY_LIGHT
  }
});

export default connexionTemplate;
