import * as React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import NoConnexionContainer from "../modules/authentification/NoConnexionContainer";

export type LoginFlowParamList = {
  NoConnexion: undefined;
};

const LoginFlowStack = createStackNavigator<LoginFlowParamList>();

export default function LoginFlow(): JSX.Element {
  return (
    <LoginFlowStack.Navigator initialRouteName="NoConnexion" headerMode="none">
      <LoginFlowStack.Screen
        name="NoConnexion"
        component={NoConnexionContainer}
        options={{ title: "TestWe - No connexion" }}
      />
    </LoginFlowStack.Navigator>
  );
}
