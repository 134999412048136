import React from "react";
import CustomModal from "../atoms/CustomModal";
import ContentModalConfirm from "../molecules/ContentModalConfirm";
import i18n from "../../services/i18n";

export interface ConfirmUserModalProps {
  isVisible: boolean;
  onValidation: () => void;
  username: string | undefined;
}

class ConfirmUserModal extends React.Component<ConfirmUserModalProps> {
  // Should re-render the component only for the following reason
  shouldComponentUpdate(nxtProps: ConfirmUserModalProps): boolean {
    const { isVisible } = this.props;
    return isVisible !== nxtProps.isVisible;
  }

  render(): JSX.Element {
    const { isVisible, onValidation, username } = this.props;
    return (
      <CustomModal
        visible={isVisible}
        showCloseButton={false}
        onCloseModal={() => null}
      >
        <ContentModalConfirm
          labelButton={i18n.t("modal.confirmId")}
          onValidation={onValidation}
          username={username}
          textBanner={i18n.t("modal.wrongId")}
          description={i18n.t("modal.askUserName")}
          boldText=" USERNAME "
          descriptionNext={i18n.t("modal.askUserName2")}
        />
      </CustomModal>
    );
  }
}

export default ConfirmUserModal;
