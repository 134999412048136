import React from "react";
import {
  StyleProp,
  StyleSheet,
  TextStyle,
  ViewStyle,
  TouchableOpacity
} from "react-native";
import { FONTSIZE_14 } from "../../../static/misc/constants";
import { viewIcon } from "../../../static/misc/images";
import Icon from "../atoms/Icon";
import RegularText from "../atoms/RegularText";

interface SeeAllTagProps {
  containerStyle?: StyleProp<ViewStyle>;
  labelStyle?: StyleProp<TextStyle>;
  label: string;
  onPress: () => void;
}

class SeeAllTag extends React.PureComponent<SeeAllTagProps> {
  render(): JSX.Element {
    const { containerStyle, labelStyle, label, onPress } = this.props;
    return (
      <TouchableOpacity
        activeOpacity={0.5}
        style={[styles.defaultContainerStyle, containerStyle]}
        onPress={onPress}
      >
        <Icon icon={viewIcon} iconStyle={[styles.defaultIconStyle]} />
        <RegularText
          textStyle={[styles.defaultLabelStyle, labelStyle]}
          text={label}
        />
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  defaultContainerStyle: {
    flexDirection: "row",
    alignItems: "center"
  },
  defaultLabelStyle: {
    fontSize: FONTSIZE_14,
    textAlignVertical: "center",
    paddingLeft: 8
  },
  defaultIconStyle: {
    width: 16,
    height: 16,
    resizeMode: "contain"
  }
});

export default SeeAllTag;
