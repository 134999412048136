import React from "react";
import {
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle
} from "react-native";
import {
  FONT_GILROY_REGULAR,
  FONTSIZE_11,
  FONT_GILROY_BOLD
} from "../../../static/misc/constants";
import { COLOR_BLUE_TESTWE } from "../../../static/misc/colors";

interface OpacTextWithBoldTextProps {
  boldText?: string;
  opacText: string;
  containerStyle?: StyleProp<ViewStyle>;
  groupStyle?: StyleProp<TextStyle>;
  contentStyle?: StyleProp<TextStyle>;
}

class OpacTextWithBoldText extends React.PureComponent<OpacTextWithBoldTextProps> {
  render(): JSX.Element {
    const {
      boldText,
      opacText,
      groupStyle,
      contentStyle,
      containerStyle
    } = this.props;
    return (
      <>
        <View style={[containerStyle]}>
          <Text style={[styles.group, groupStyle]}>{opacText}</Text>
          <Text
            numberOfLines={1}
            ellipsizeMode="tail"
            style={[styles.content, contentStyle]}
          >
            {boldText}
          </Text>
        </View>
      </>
    );
  }
}

const styles = StyleSheet.create({
  group: {
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_11,
    color: COLOR_BLUE_TESTWE,
    opacity: 0.5
  },
  content: {
    fontFamily: FONT_GILROY_BOLD,
    fontSize: FONTSIZE_11,
    color: COLOR_BLUE_TESTWE
  }
});

export default OpacTextWithBoldText;
