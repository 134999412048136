import { PAPERS } from "../../../../static/misc/network";
import {
  ClearStudentPaperAction,
  ClearStudentPapersAction,
  CLEAR_STUDENT_PAPER,
  CLEAR_STUDENT_PAPERS,
  CreateStudentPaperAction,
  CREATE_STUDENT_PAPER,
  EditStudentPaperAction,
  EDIT_STUDENT_PAPER,
  ExportStudentPaperAction,
  EXPORT_STUDENT_PAPER,
  EXPORT_STUDENT_PAPER_TO_ZIP,
  FillStudentPaperAction,
  FILL_STUDENT_PAPER,
  PostStudentPaperAction,
  POST_STUDENT_PAPER,
  StudentAnswerType,
  StudentPaperType,
  UpdateStudentPaperAction,
  UPDATE_STUDENT_PAPER
} from "../types/studentPaper";

// Action called to save the student paper in the store
export function fillStudentPaper(
  examId: string,
  studentAnswers: StudentAnswerType[],
  currentUserId: string
): FillStudentPaperAction {
  return {
    type: FILL_STUDENT_PAPER,
    payload: {
      examId,
      studentAnswers,
      currentUserId
    }
  };
}

// Action called to create the student paper in the store
export function createStudentPaper(
  examId: string,
  userId: string,
  studentPaperId?: string,
  startDate?: moment.Moment | string
): CreateStudentPaperAction {
  return {
    type: CREATE_STUDENT_PAPER,
    payload: {
      examId,
      userId,
      studentPaperId,
      startDate
    }
  };
}

// Action called to edit the student paper in the store
export function editStudentPaper(
  currentStudentPaper: StudentPaperType
): EditStudentPaperAction {
  return {
    type: EDIT_STUDENT_PAPER,
    payload: currentStudentPaper
  };
}

// Action called to clear all the student papers from the store
export function clearStudentPapers(): ClearStudentPapersAction {
  return {
    type: CLEAR_STUDENT_PAPERS
  };
}

// Action called to clear the student paper from the store
export function clearStudentPaper(
  examId: string,
  currentUserId: StudentPaperType["userId"]
): ClearStudentPaperAction {
  return {
    type: CLEAR_STUDENT_PAPER,
    payload: { examId, currentUserId }
  };
}

// Action called to create the student paper on the API
export function postStudentPaper(
  studentPaper: StudentPaperType,
  userToken: string,
  status?: string,
  currentUserId?: string
): PostStudentPaperAction {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { userId, ...rest }: StudentPaperType = studentPaper;

  return {
    type: POST_STUDENT_PAPER,
    payload: {
      client: "default",
      currentUserId,
      request: {
        headers: {
          accept: "application/json",
          authorization: `Bearer ${userToken}`
        },
        url: PAPERS,
        method: "post",
        data: {
          ...rest,
          status
        }
      }
    },
    meta: {}
  };
}

// Action called to update the student paper on the API
export function updateStudentPaper(
  studentPaper: StudentPaperType,
  userToken: string,
  status?: string
): UpdateStudentPaperAction {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { userId, ...rest }: StudentPaperType = studentPaper;

  return {
    meta: {},
    type: UPDATE_STUDENT_PAPER,
    payload: {
      client: "default",
      request: {
        headers: {
          accept: "application/json",
          authorization: `Bearer ${userToken}`
        },
        url: `${PAPERS}/${studentPaper.id}`,
        method: "put",
        data: {
          ...rest,
          ...(status ? { status } : {})
        }
      }
    }
  };
}

export function exportStudentPaperToFile(
  examId: string
): ExportStudentPaperAction {
  return {
    type: EXPORT_STUDENT_PAPER,
    payload: {
      examId
    }
  };
}

export function exportStudentPaperToZip(
  examId: string,
  studentId: string
): ExportStudentPaperAction {
  return {
    type: EXPORT_STUDENT_PAPER_TO_ZIP,
    payload: {
      examId,
      studentId,
      client: "ipc"
    }
  };
}
