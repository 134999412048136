import React from "react";
import { StyleSheet, View, Text } from "react-native";
import Webcam from "react-webcam";
import ReactPlayer from "react-player";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import Loader from "../atoms/Loader";
import i18n from "../../services/i18n";
import Button from "../molecules/Button";
import {
  FONTSIZE_14,
  FONT_GILROY_REGULAR,
  PADDING_SIDES,
  VIDEO_CONSTRAINTS
} from "../../../static/misc/constants";
import { COLOR_BLUE_TESTWE, COLOR_WHITE } from "../../../static/misc/colors";
import { GetErrorAction } from "../../modules/main/types/error";
import { ExamOnboardingStep } from "../../modules/exams/types/exam";
import { ONBOARDING_ASSETS } from "../../../static/misc/onboarding/onboardingsteps";
import { OnboardingInfoType } from "./OnboardingItem";
import { VideoTaking } from "../molecules/VideoTaking";
import AbstractVideoRecording from "./VideoRecording";
import { RootState } from "../../store/rootreducer";

enum ReadyState {
  INIT,
  PENDING,
  READY
}

interface OnboardingVideoStepProps {
  loader: boolean;
  canReloadCurrentStep: boolean;
  currentOnboardingStep: ExamOnboardingStep;
  currentInfo?: OnboardingInfoType;
  authorizationToken: string | undefined;
  type: string;
  getError: (message: string, forceLogout: boolean) => GetErrorAction;
  updateCurrentStepInfo: (
    data?: string | Blob | null,
    status?: boolean
  ) => void;
  toggleLoader: (load: boolean) => void;
  onEquipmentValidated: (camera: boolean) => void;
}

interface OnboardingVideoStepState {
  isReady: ReadyState;
  isVideoRecording: boolean;
  recordingDuration: number;
}

class OnboardingVideoStepView extends AbstractVideoRecording<
  OnboardingVideoStepProps,
  OnboardingVideoStepState
> {
  webcamRef?: React.RefObject<Webcam>;

  onloadInterval!: ReturnType<typeof setInterval>;

  constructor(props: OnboardingVideoStepProps) {
    super(props);
    this.state = {
      isVideoRecording: false,
      recordingDuration: 0,
      isReady: props.currentInfo?.data ? ReadyState.READY : ReadyState.INIT
    };
  }

  componentDidMount(): void {
    super.componentDidMount();
    const { isReady } = this.state;
    if (isReady === ReadyState.INIT) {
      this.startInterval();
    }
  }

  componentDidUpdate(prevProps: OnboardingVideoStepProps): void {
    const { type } = this.props;
    const { isReady } = this.state;
    if (prevProps.type !== type || isReady === ReadyState.INIT) {
      this.startInterval();
    }
  }

  componentWillUnmount(): void {
    super.componentWillUnmount();
    if (this.onloadInterval) {
      clearInterval(this.onloadInterval);
    }
  }

  onMediaError(error: unknown): void {
    // eslint-disable-next-line no-shadow
    const { getError } = this.props;
    getError(i18n.t("errors.webcam", [error]), false);
  }

  onNewVideo(blob: Blob): void {
    const { updateCurrentStepInfo } = this.props;
    this.sendEquimentResults();
    updateCurrentStepInfo(blob, true);
  }

  sendEquimentResults(): void {
    const { onEquipmentValidated } = this.props;
    onEquipmentValidated(true);
  }

  setVideoRecording(value: boolean): void {
    this.setState({
      isVideoRecording: value
    });
  }

  setRecordingDuration(value: number): void {
    this.setState({
      recordingDuration: value
    });
  }

  startInterval(): void {
    this.setState({ isReady: ReadyState.PENDING });

    this.onloadInterval = setInterval(() => {
      if (this.webcamRef?.current?.video?.readyState === 4) {
        this.setState({ isReady: ReadyState.READY });
        clearInterval(this.onloadInterval);
      }
    }, 100);
  }

  updateRecordingDuration(): void {
    const { recordingDuration } = this.state;

    this.setState({
      recordingDuration: recordingDuration + 1
    });
  }

  getMediaConstraints(): MediaTrackConstraints {
    const { currentOnboardingStep } = this.props;
    return {
      frameRate: currentOnboardingStep.fps,
      width: currentOnboardingStep.width,
      height: currentOnboardingStep.height
    };
  }

  render(): JSX.Element | null {
    const {
      loader,
      canReloadCurrentStep,
      currentOnboardingStep,
      currentInfo,
      updateCurrentStepInfo,
      getError,
      toggleLoader
    } = this.props;
    const { isReady, isVideoRecording, recordingDuration } = this.state;
    this.webcamRef = React.createRef<Webcam>();

    const videoIndex = ONBOARDING_ASSETS.findIndex(
      (vid) => vid.demoVideo === currentOnboardingStep.demoVideo
    );

    const shouldUseWebmFormat =
      window.navigator.userAgent.includes("Windows") &&
      window.navigator.userAgent.includes("SEB");

    return (
      <View style={styles.proctoringContainer}>
        {currentOnboardingStep.subtitle &&
          currentOnboardingStep.subtitle !== "" && (
            <Text style={styles.stepSubtitle}>
              {i18n.t(`onboardingModal.${currentOnboardingStep.subtitle}`)} :
            </Text>
          )}
        {loader && <Loader runAnimation={loader} />}
        <View
          style={[
            styles.steps,
            {
              display: loader ? "none" : "flex"
            }
          ]}
        >
          <View>
            {videoIndex > -1 && (
              <ReactPlayer
                url={
                  shouldUseWebmFormat
                    ? ONBOARDING_ASSETS[videoIndex].video.webm
                    : ONBOARDING_ASSETS[videoIndex].video.mp4
                }
                width={VIDEO_CONSTRAINTS.width}
                height={VIDEO_CONSTRAINTS.height}
                controls={false}
                playing
                loop
                config={{
                  file: { attributes: { controlsList: "nodownload" } }
                }}
              />
            )}
          </View>
          <View>
            <VideoTaking
              currentVideo={currentInfo?.data}
              currentOnboardingStep={currentOnboardingStep}
              webcamRef={this.webcamRef}
              getError={getError}
              toggleLoader={toggleLoader}
            />
            <Button
              label={
                isVideoRecording
                  ? `${i18n.t(
                      "onboardingModal.stopRecording"
                    )} - ${this.getVideoDurationLabel(recordingDuration)}`
                  : canReloadCurrentStep
                  ? i18n.t("onboardingModal.restartRecording")
                  : i18n.t("onboardingModal.startRecording")
              }
              textStyle={{
                color: COLOR_BLUE_TESTWE,
                fontFamily: FONT_GILROY_REGULAR
              }}
              containerStyle={[styles.reloadButtonStyle]}
              disabled={isReady !== ReadyState.READY}
              onPress={() => {
                if (
                  canReloadCurrentStep ||
                  (!canReloadCurrentStep && !!currentInfo?.data)
                ) {
                  this.setState({
                    isReady: ReadyState.INIT
                  });
                  updateCurrentStepInfo(null, true);
                  this.startWebcamRecording();
                } else if (!isVideoRecording) this.startWebcamRecording();
                else this.stopWebcamRecording();
              }}
            />
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  stepSubtitle: {
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_14,
    color: COLOR_BLUE_TESTWE,
    paddingBottom: PADDING_SIDES * 0.2
  },
  reloadButtonStyle: {
    borderStyle: "solid",
    borderColor: COLOR_BLUE_TESTWE,
    borderWidth: 1,
    borderRadius: 50,
    backgroundColor: COLOR_WHITE,
    marginTop: 15
  },
  steps: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "flex-start",
    width: "100%",
    maxWidth: 600
  },
  proctoringContainer: {
    alignItems: "center",
    minHeight: 200,
    marginBottom: 15,
    width: "100%"
  }
});

const mapStateToProps = (state: RootState) => {
  return {
    // eslint-disable-next-line camelcase
    authorizationToken: state.auth.credentials?.access_token
  };
};

const mapdispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators({}, dispatch)
  };
};

const OnboardingVideoStep = connect(
  mapStateToProps,
  mapdispatchToProps
)(OnboardingVideoStepView);

export default OnboardingVideoStep;
