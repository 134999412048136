/* eslint-disable react/no-unused-state */
import React from "react";
import { StyleSheet, View, Text, TouchableOpacity } from "react-native";
import { COLOR_BLUE, COLOR_RED } from "../../../static/misc/colors";
import {
  FONTSIZE_10,
  FONTSIZE_14,
  FONT_GILROY_BOLD,
  PADDING_SIDES
} from "../../../static/misc/constants";
import { guidGenerator } from "../../../static/misc/utils";
import {
  DisplayedExamsEnum,
  DisplayedExamsOrderEnum
} from "../../modules/exams/ExamsView";
import {
  ChangeOrderDisplayedExamsAction,
  ExamsOrderingMeta
} from "../../modules/exams/types/exams";
import { ColumnsProperties } from "../organisms/ExamsList";

interface ListColumnsTitleProps {
  columnsTitle: ColumnsProperties[];
  listType: DisplayedExamsEnum;
  ordered: ExamsOrderingMeta;
  changeExamsOrder: (
    examType: DisplayedExamsEnum,
    order: DisplayedExamsOrderEnum,
    category: string
  ) => ChangeOrderDisplayedExamsAction;
}

class ListColumnsTitle extends React.PureComponent<ListColumnsTitleProps> {
  updateListOrder(category?: string): void {
    const { listType, ordered, changeExamsOrder } = this.props;

    if (category) {
      let order =
        listType === DisplayedExamsEnum.NEXT_EXAMS
          ? ordered.nextExams.order
          : ordered.passedExams.order;
      const lastCategory =
        listType === DisplayedExamsEnum.NEXT_EXAMS
          ? ordered.nextExams.category
          : ordered.passedExams.category;
      if (category === lastCategory) {
        order =
          order === DisplayedExamsOrderEnum.ASC
            ? DisplayedExamsOrderEnum.DESC
            : DisplayedExamsOrderEnum.ASC;
      }

      changeExamsOrder(listType, order, category);
    }
  }

  render(): JSX.Element {
    const { columnsTitle, listType, ordered } = this.props;
    return (
      <View style={styles.view}>
        {columnsTitle.map((column: ColumnsProperties) => {
          const widthStyle = StyleSheet.create({
            style: {
              width: column.width,
              paddingHorizontal: PADDING_SIDES * 0.3
            }
          });

          return (
            <View key={guidGenerator()} style={widthStyle.style}>
              <TouchableOpacity
                onPress={() => this.updateListOrder(column.orderCategory)}
              >
                <Text style={[styles.text, column.textStyle]}>
                  {column.title}
                  {column.orderCategory &&
                  ((listType === DisplayedExamsEnum.NEXT_EXAMS &&
                    ordered.nextExams.order === DisplayedExamsOrderEnum.DESC) ||
                    (listType === DisplayedExamsEnum.PASSED_EXAMS &&
                      ordered.passedExams.order ===
                        DisplayedExamsOrderEnum.DESC)) ? (
                    <Text
                      style={[
                        styles.caret,
                        (listType === DisplayedExamsEnum.NEXT_EXAMS &&
                          column.orderCategory ===
                            ordered.nextExams.category) ||
                        (listType === DisplayedExamsEnum.PASSED_EXAMS &&
                          column.orderCategory === ordered.passedExams.category)
                          ? styles.selectedCaret
                          : null
                      ]}
                    >
                      &#9660;
                    </Text>
                  ) : null}
                  {column.orderCategory &&
                  ((listType === DisplayedExamsEnum.NEXT_EXAMS &&
                    ordered.nextExams.order === DisplayedExamsOrderEnum.ASC) ||
                    (listType === DisplayedExamsEnum.PASSED_EXAMS &&
                      ordered.passedExams.order ===
                        DisplayedExamsOrderEnum.ASC)) ? (
                    <Text
                      style={[
                        styles.caret,
                        (listType === DisplayedExamsEnum.NEXT_EXAMS &&
                          column.orderCategory ===
                            ordered.nextExams.category) ||
                        (listType === DisplayedExamsEnum.PASSED_EXAMS &&
                          column.orderCategory === ordered.passedExams.category)
                          ? styles.selectedCaret
                          : null
                      ]}
                    >
                      &#9650;
                    </Text>
                  ) : null}
                </Text>
              </TouchableOpacity>
            </View>
          );
        })}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  view: {
    paddingVertical: PADDING_SIDES * 0.5,
    paddingHorizontal: PADDING_SIDES * 0.5,
    flexBasis: "fit-content",
    flexDirection: "row"
  },
  text: {
    fontFamily: FONT_GILROY_BOLD,
    fontSize: FONTSIZE_14,
    color: COLOR_BLUE
  },
  caret: {
    fontSize: FONTSIZE_10,
    marginLeft: PADDING_SIDES * 0.05
  },
  selectedCaret: {
    color: COLOR_RED
  }
});

export default ListColumnsTitle;
