import React from "react";
import { View, StyleSheet } from "react-native";
import { COLOR_BLUE_TESTWE } from "../../../static/misc/colors";
import i18n from "../../services/i18n";
import CustomModal from "../atoms/CustomModal";
import ExtraBoldTitle from "../atoms/ExtraBoldTitle";
import RegularText from "../atoms/RegularText";
import Button from "./Button";

export interface ProcessKillerWarningModalProps {
  isVisible: boolean;
  onClose: (ok: boolean) => void;
}

export default class ProcessKillerWarningModal extends React.Component<ProcessKillerWarningModalProps> {
  shouldComponentUpdate(nextProps: ProcessKillerWarningModalProps): boolean {
    const { isVisible } = this.props;
    return nextProps.isVisible !== isVisible;
  }

  render(): JSX.Element {
    const { isVisible, onClose } = this.props;

    return (
      <CustomModal
        heightModal="50%"
        widthModal="50%"
        borderRadiusModal={5}
        showCloseButton
        visible={isVisible}
        onCloseModal={() => onClose(false)}
      >
        <View style={styles.modalContentContainer}>
          <ExtraBoldTitle text={i18n.t("warningModal.title")} />
          <RegularText text={i18n.t("warningModal.warning")} />
          <Button
            onPress={() => onClose(true)}
            containerStyle={[styles.button]}
            label={i18n.t("warningModal.ok")}
          />
        </View>
      </CustomModal>
    );
  }
}

const styles = StyleSheet.create({
  modalContentContainer: {
    flex: 1,
    height: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 20,
    paddingHorizontal: 20
  },
  button: {
    borderColor: COLOR_BLUE_TESTWE,
    backgroundColor: COLOR_BLUE_TESTWE,
    width: 100,
    fontSize: 18
  }
});
