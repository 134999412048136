/* eslint-disable react/no-unused-state */
import React from "react";
import {
  View,
  StyleSheet,
  Text,
  TextStyle,
  StyleProp,
  TouchableOpacity
} from "react-native";
import {
  COLOR_BLUE_TESTWE,
  COLOR_SHADOW,
  COLOR_WHITE
} from "../../../static/misc/colors";
import {
  FONTSIZE_12,
  FONT_GILROY_ITALIC,
  PADDING_SIDES
} from "../../../static/misc/constants";

export interface ExamCommentProps {
  textStyle?: StyleProp<TextStyle>;
  textSetNbOfLines: number;
  text: string;
}

export interface ExamCommentState {
  isVisible: boolean;
}

class ExamComment extends React.PureComponent<
  ExamCommentProps,
  ExamCommentState
> {
  constructor(props: ExamCommentProps) {
    super(props);

    this.state = {
      isVisible: false
    };
  }

  toggleFullComment(): void {
    const { isVisible } = this.state;

    this.setState({
      isVisible: !isVisible
    });
  }

  render(): JSX.Element {
    const { textStyle, textSetNbOfLines, text } = this.props;
    const { isVisible } = this.state;

    return (
      <View style={[styles.defaultContainerStyle]}>
        {textSetNbOfLines > 0 && text !== "..." && (
          <TouchableOpacity onPress={() => this.toggleFullComment()}>
            <Text
              numberOfLines={textSetNbOfLines}
              ellipsizeMode="tail"
              style={textStyle}
            >
              {text}
            </Text>
          </TouchableOpacity>
        )}
        {isVisible && (
          <View style={[styles.fullCommentContainer]}>
            <Text
              style={styles.fullCommentText}
              textBreakStrategy="highQuality"
            >
              {text}
            </Text>
          </View>
        )}
        {textSetNbOfLines < 0 && <Text style={textStyle}>{text}</Text>}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  defaultContainerStyle: {
    position: "relative"
  },
  fullCommentContainer: {
    position: "absolute",
    backgroundColor: COLOR_WHITE,
    top: PADDING_SIDES * 0.8,
    left: PADDING_SIDES * -1,
    right: PADDING_SIDES * -1,
    shadowColor: COLOR_SHADOW,
    shadowOffset: {
      width: 0,
      height: 0
    },
    shadowOpacity: 0.4,
    shadowRadius: 5,
    elevation: 5,
    zIndex: 90,
    padding: PADDING_SIDES * 0.3,
    borderRadius: 5
  },
  fullCommentText: {
    fontFamily: FONT_GILROY_ITALIC,
    fontSize: FONTSIZE_12,
    color: COLOR_BLUE_TESTWE
  }
});

export default ExamComment;
