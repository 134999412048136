import { MIME_SHEET } from "../../../static/misc/constants";
import { b64toblob } from "../../../static/misc/utils";
import { StudentAnswerMediaDataType } from "../../modules/examTaking/types/studentPaper";

const prepareUploadFileToApi = (
  mediaData: StudentAnswerMediaDataType,
  isSpreadsheet = false
): FormData | null => {
  const form = new FormData();

  let blob: Blob;

  if (!isSpreadsheet) {
    const mimetypes = mediaData.data.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/);
    if (!mimetypes) return null;

    const mimetype = mimetypes[0];

    blob = b64toblob(
      mediaData.data.replace(`data:${mimetype};base64,`, ""),
      mimetype
    );
  } else {
    blob = b64toblob(mediaData.data, MIME_SHEET);
  }

  form.append("files[]", blob, mediaData.filename);

  return form;
};

export default prepareUploadFileToApi;
