import React from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import { COLOR_BLUE_TESTWE } from "../../../../static/misc/colors";
import { PADDING_SIDES } from "../../../../static/misc/constants";
import { StackNavigatorProp } from "../../../router/StackNavigator";
import { TimerTypeEnum } from "../../atoms/ExamTakingTimer";
import Logo from "../../atoms/Logo";
import HeaderExamBlockContent from "../../organisms/HeaderExamBlockContent";
import HeaderRemoteExamBlockContent from "../../organisms/HeaderRemoteExamBlockContent";

interface ExamTakingHeaderProps {
  phoneNumberSupport: string;
  showPhoneNumber: boolean;
  enable?: boolean;
  remoteExam?: boolean;
  duration?: number;
  navigation: StackNavigatorProp;
  examId?: string;
  onFinishedTimer: (timerType: TimerTypeEnum) => void;
  onClickLogo: () => void;
  onExamValidated: () => void;
}

class ExamTakingHeader extends React.PureComponent<ExamTakingHeaderProps> {
  static defaultProps = {
    enable: false
  };

  render(): JSX.Element {
    const {
      phoneNumberSupport,
      enable,
      showPhoneNumber,
      remoteExam,
      duration,
      navigation,
      examId,
      onFinishedTimer,
      onClickLogo,
      onExamValidated
    } = this.props;
    return (
      <View style={[styles.contentContainer]}>
        <TouchableOpacity disabled={!enable} onPress={onClickLogo}>
          <Logo />
        </TouchableOpacity>
        {remoteExam === true ? (
          <HeaderRemoteExamBlockContent
            duration={duration}
            navigation={navigation}
            onFinishedTimer={(timerType: TimerTypeEnum) =>
              onFinishedTimer(timerType)
            }
            onExamValidated={onExamValidated}
            examId={examId}
          />
        ) : (
          <HeaderExamBlockContent
            phoneNumberSupport={phoneNumberSupport}
            showPhoneNumber={showPhoneNumber}
          />
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  contentContainer: {
    backgroundColor: COLOR_BLUE_TESTWE,
    paddingVertical: PADDING_SIDES * 0.2,
    paddingHorizontal: PADDING_SIDES * 0.4,
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row"
  }
});

export default ExamTakingHeader;
