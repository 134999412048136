import React from "react";
import { StyleSheet, Text } from "react-native";
import { COLOR_BLUE_TESTWE } from "../../../static/misc/colors";
import {
  FONTSIZE_14,
  FONT_GILROY_BOLD,
  FONT_GILROY_REGULAR,
  PADDING_SIDES
} from "../../../static/misc/constants";

export interface ExamInfoDetailItemProps {
  label: string;
  value: string;
}

class ExamInfoDetailItem extends React.PureComponent<ExamInfoDetailItemProps> {
  render(): JSX.Element {
    const { label, value } = this.props;
    return (
      <Text style={styles.text}>
        {label}: <Text style={styles.boldText}>{value}</Text>
      </Text>
    );
  }
}

const styles = StyleSheet.create({
  text: {
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_14,
    color: COLOR_BLUE_TESTWE,
    paddingVertical: PADDING_SIDES * 0.1
  },
  boldText: {
    fontFamily: FONT_GILROY_BOLD
  }
});

export default ExamInfoDetailItem;
