import {
  StudentPaperState,
  StudentPaperActionsType,
  CLEAR_STUDENT_PAPERS,
  CREATE_STUDENT_PAPER,
  FILL_STUDENT_PAPER,
  StudentAnswerType,
  POST_STUDENT_PAPER,
  POST_STUDENT_PAPER_SUCCESS,
  POST_STUDENT_PAPER_FAIL,
  UPDATE_STUDENT_PAPER,
  UPDATE_STUDENT_PAPER_SUCCESS,
  UPDATE_STUDENT_PAPER_FAIL,
  EDIT_STUDENT_PAPER,
  CLEAR_STUDENT_PAPER,
  StudentPaperType,
  EXPORT_STUDENT_PAPER,
  EXPORT_STUDENT_PAPER_SUCCESS,
  EXPORT_STUDENT_PAPER_TO_ZIP_SUCCESS
} from "../types/studentPaper";
import moment from "../../../services/moment";
import {
  findIndexStudentPaper,
  getFileExtension,
  getQuestionFileMimeType
} from "../../../../static/misc/utils";
import {
  UPLOAD_DATA_API,
  UPLOAD_DATA_API_FAIL,
  UPLOAD_DATA_API_SUCCESS,
  UPLOAD_MEDIA_OBJECT,
  UPLOAD_MEDIA_OBJECT_FAIL,
  UPLOAD_MEDIA_OBJECT_SUCCESS
} from "../../exams/types/attachedfiles";
import {
  END_STUDENTPAPER_SUBMISSION,
  START_STUDENTPAPER_SUBMISSION
} from "../../main/types/status";
import { UPDATE_SPREADSHEET } from "../types/examTaking";
import { SPREADSHEET_QUESTION } from "../../../../static/misc/constants";
import { hasStudentPaper } from "../../exams/utils";
import { completeStudentAnswerStatus } from "../../../services/exam-navbar-progress";

const initialState: StudentPaperState = {
  studentPapers: []
};

export default function reducer(
  state = initialState,
  action: StudentPaperActionsType
): StudentPaperState {
  switch (action.type) {
    case EDIT_STUDENT_PAPER: {
      // Editing the student paper in the store
      const currentPaperIndex = findIndexStudentPaper(
        state.studentPapers,
        action.payload.examId
      );
      const studentPapersTmp = [...state.studentPapers];
      studentPapersTmp[currentPaperIndex] = action.payload;
      return {
        ...state,
        studentPapers: studentPapersTmp
      };
    }

    // Saving the student answers in the student paper in the store
    case FILL_STUDENT_PAPER: {
      const studentPapersTmp = [...state.studentPapers];
      const currentStudentPaperIndex = studentPapersTmp.findIndex(
        (studentPaper) =>
          studentPaper.examId === action.payload.examId &&
          studentPaper.userId !== (undefined || null) &&
          studentPaper.userId === action.payload.currentUserId
      );

      if (currentStudentPaperIndex > -1) {
        const currentStudentPaperTmp = {
          ...studentPapersTmp[currentStudentPaperIndex]
        };

        if (currentStudentPaperTmp) {
          const studentAnswersTmp = [
            ...(currentStudentPaperTmp.studentAnswers as StudentAnswerType[])
          ];

          action.payload.studentAnswers.forEach(
            (payloadAnswer: StudentAnswerType) => {
              const currentStudentAnswerIndex = studentAnswersTmp.findIndex(
                (answer) => answer.question === payloadAnswer.question
              );

              if (currentStudentAnswerIndex >= 0) {
                studentAnswersTmp[currentStudentAnswerIndex] = {
                  ...payloadAnswer
                };
              } else {
                studentAnswersTmp.push({ ...payloadAnswer });
              }
            }
          );

          currentStudentPaperTmp.studentAnswers = studentAnswersTmp;
          studentPapersTmp[currentStudentPaperIndex] = currentStudentPaperTmp;
        }
      }
      return {
        ...state,
        studentPapers: studentPapersTmp
      };
    }

    case CREATE_STUDENT_PAPER: {
      // Creating the student paper in the store
      const studentPapersTmp = [...state.studentPapers];

      studentPapersTmp.push({
        id: action.payload.studentPaperId,
        examId: action.payload.examId,
        exam: `/exams/${action.payload.examId}`,
        startDate: action.payload.startDate
          ? action.payload.startDate
          : moment().utc().format(),
        endDate: null,
        studentAnswers: [],
        userId: action.payload.userId,
        studentPaperResumings: []
      });
      return {
        ...state,
        studentPapers: studentPapersTmp
      };
    }

    case POST_STUDENT_PAPER: {
      return { ...state };
    }

    case POST_STUDENT_PAPER_SUCCESS: {
      // Triggered when the request to create the student paper on the API is successful
      const tmpStudentPapers = [...state.studentPapers];
      // If no student paper ID in previous action, then it is an initialization
      if (!action.meta.previousAction.payload.request.data.id) {
        tmpStudentPapers.map((studentPaper, index) => {
          if (
            studentPaper.examId?.toString() ===
              action.meta.previousAction.payload.request.data.examId.toString() &&
            action.payload.data.id &&
            hasStudentPaper(
              studentPaper.examId,
              action.meta.previousAction.payload.currentUserId,
              [studentPaper]
            )
          ) {
            tmpStudentPapers[index].id = action.payload.data.id.toString();
          }

          return studentPaper;
        });
      }
      return {
        ...state,
        studentPapers: tmpStudentPapers
      };
    }

    case POST_STUDENT_PAPER_FAIL: {
      return { ...state };
    }

    case UPDATE_STUDENT_PAPER: {
      return { ...state };
    }

    case UPDATE_STUDENT_PAPER_SUCCESS: {
      return { ...state };
    }

    case UPDATE_STUDENT_PAPER_FAIL: {
      return { ...state };
    }

    case UPLOAD_DATA_API:
    case UPLOAD_MEDIA_OBJECT: {
      return { ...state };
    }

    case UPLOAD_DATA_API_SUCCESS:
    case UPLOAD_MEDIA_OBJECT_SUCCESS: {
      // Triggered when the request to upload the media objects on the API is successful
      const tmpStudentPapers = [...state.studentPapers];

      const modifiedStudentPapers: StudentPaperType[] = tmpStudentPapers.map(
        (studentPaper) => {
          const tmpStudentPaper = { ...studentPaper };
          if (
            studentPaper.examId ===
            action.meta.previousAction.payload.studentPaperMeta.examId
          ) {
            const studentAnswers = [
              ...(studentPaper.studentAnswers as StudentAnswerType[])
            ];
            const tmpAnswers = studentAnswers.map((answer) => {
              const tmpAnswer = { ...answer };
              if (
                tmpAnswer.question &&
                action.meta.previousAction.payload.studentPaperMeta
                  .questionId &&
                tmpAnswer.question?.replace("/questions/", "") ===
                  action.meta.previousAction.payload.studentPaperMeta.questionId.replace(
                    "/questions/",
                    ""
                  )
              ) {
                if (action.payload.data && action.payload.data.length > 0) {
                  const medias: string[] = [];
                  action.payload.data.forEach((file: any) => {
                    medias.push(`/media-objects/${file.id}`);
                  });
                  tmpAnswer.mediaObjects = [...medias];
                }
              }
              return tmpAnswer;
            });
            tmpStudentPaper.studentAnswers = [...tmpAnswers];
          }
          return tmpStudentPaper;
        }
      );
      return {
        ...state,
        studentPapers: [...modifiedStudentPapers]
      };
    }

    case UPLOAD_DATA_API_FAIL:
    case UPLOAD_MEDIA_OBJECT_FAIL: {
      return { ...state };
    }

    case START_STUDENTPAPER_SUBMISSION: {
      // Initializing the student paper submission
      const currentPaperIndex = findIndexStudentPaper(
        state.studentPapers,
        action.payload.studentPaper.examId
      );
      const studentPapersTmp = [...state.studentPapers];
      studentPapersTmp[currentPaperIndex] = action.payload.studentPaper;
      return {
        ...state,
        studentPapers: studentPapersTmp
      };
    }

    case END_STUDENTPAPER_SUBMISSION: {
      // Ending the student paper submission
      const tmpStudentPapers = [...state.studentPapers];
      const index = tmpStudentPapers.findIndex(
        (studentPaper) =>
          studentPaper.examId === action.payload.studentPaper.examId
      );
      if (index >= 0) tmpStudentPapers.splice(index, 1);

      return {
        ...state,
        studentPapers: tmpStudentPapers
      };
    }

    case CLEAR_STUDENT_PAPER: {
      // Cleaning the successfuly submitted student paper from the store
      const tmpStudentPapers = [...state.studentPapers].filter(
        (studentPaper) =>
          !hasStudentPaper(
            action.payload.examId,
            action.payload.currentUserId,
            [studentPaper]
          )
      );
      return {
        ...state,
        studentPapers: tmpStudentPapers
      };
    }

    case EXPORT_STUDENT_PAPER: {
      return {
        ...state
      };
    }

    case EXPORT_STUDENT_PAPER_SUCCESS: {
      const spTmp = [...state.studentPapers];
      const spIndex = state.studentPapers.findIndex(
        (sp) => sp.examId === action.meta.previousAction.payload.examId
      );

      if (spIndex > -1) {
        spTmp[spIndex].exported = true;
        return {
          ...state,
          studentPapers: spTmp
        };
      }
      return {
        ...state
      };
    }

    case EXPORT_STUDENT_PAPER_TO_ZIP_SUCCESS: {
      const spTmp = [...state.studentPapers];
      const spIndex = state.studentPapers.findIndex(
        (sp) => sp.examId === action.meta.previousAction.payload.examId
      );

      if (spIndex > -1) {
        spTmp[spIndex].zipped = true;
        return {
          ...state,
          studentPapers: spTmp
        };
      }
      return {
        ...state
      };
    }

    case CLEAR_STUDENT_PAPERS:
      return initialState;

    case UPDATE_SPREADSHEET: {
      const { examId, questionId, fileId, content } = action.payload;

      const tmpStudentPapers = [...state.studentPapers];

      const modifiedStudentPapers: StudentPaperType[] = tmpStudentPapers.map(
        (studentPaper) => {
          const tmpStudentPaper = { ...studentPaper };
          if (studentPaper.examId === examId) {
            const studentAnswers = [
              ...(studentPaper.studentAnswers as StudentAnswerType[])
            ];
            const tmpAnswers = studentAnswers.map((answer) => {
              const tmpAnswer = { ...answer };
              if (
                tmpAnswer.question &&
                questionId &&
                tmpAnswer.question?.replace("/questions/", "") ===
                  questionId.replace("/questions/", "")
              ) {
                const mimeType = getQuestionFileMimeType(
                  tmpAnswer.questionType
                );
                const fileExtension = getFileExtension(mimeType);

                let filename: string;

                if (
                  tmpAnswer.questionType === SPREADSHEET_QUESTION &&
                  fileId !== undefined
                ) {
                  filename = `internal-${examId}-${questionId}-${fileId}${fileExtension}`;
                } else {
                  filename = `internal-${examId}-${questionId}${fileExtension}`;
                }

                tmpAnswer.mediaData = [
                  {
                    data: content,
                    filename
                  }
                ];
              }

              return completeStudentAnswerStatus(tmpAnswer);
            });
            tmpStudentPaper.studentAnswers = [...tmpAnswers];
          }
          return tmpStudentPaper;
        }
      );
      return {
        ...state,
        studentPapers: [...modifiedStudentPapers]
      };
    }

    default:
      return state;
  }
}
