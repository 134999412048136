import React from "react";
import { StyleSheet, View } from "react-native";
import { PADDING_SIDES } from "../../../static/misc/constants";
import ExplainText from "../atoms/ExplainText";

export interface MultipleExplainsProps {
  showFirstText: boolean;
  firstText: string;
  firstJustifiedText: string;
  showSecondText: boolean;
  secondText: string;
  secondJustifiedText: string;
}

class MultipleExplains extends React.PureComponent<MultipleExplainsProps> {
  render(): JSX.Element {
    const {
      showFirstText,
      firstText,
      firstJustifiedText,
      showSecondText,
      secondText,
      secondJustifiedText
    } = this.props;
    return (
      <View style={styles.container}>
        {showFirstText && (
          <View style={styles.explainTextContainer}>
            <ExplainText text={firstText} justifiedText={firstJustifiedText} />
          </View>
        )}
        {showSecondText && showFirstText && (
          <View style={{ paddingVertical: PADDING_SIDES * 0.1 }} />
        )}
        {showSecondText && (
          <View style={styles.explainTextContainer}>
            <ExplainText
              text={secondText}
              justifiedText={secondJustifiedText}
            />
          </View>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    width: "100%"
  },
  explainTextContainer: {
    alignSelf: "center",
    width: "100%"
  }
});

export default MultipleExplains;
