import React, { useRef } from "react";
import { StyleSheet, View } from "react-native";
import { useHover } from "react-native-web-hooks";
import InfoIcon from "../atoms/InfoIcon";
import RuleExam, { RuleExamProps } from "./RuleExam";

const InfoIconWithRules = (props: RuleExamProps): JSX.Element => {
  const { data } = props;
  const ref = useRef(null);
  const isHovered = useHover(ref);

  return (
    <>
      <InfoIcon ref={ref} containerStyle={{ position: "relative" }} />
      {isHovered && (
        <View style={styles.rulesContainer}>
          {data !== undefined && <RuleExam data={data} />}
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  rulesContainer: {
    position: "absolute",
    right: 180,
    bottom: 0,
    zIndex: 80
  }
});

export default InfoIconWithRules;
