import React from "react";
import { StyleProp, StyleSheet, Text, TextStyle } from "react-native";
import { COLOR_BLUE } from "../../../static/misc/colors";
import {
  FONTSIZE_14,
  FONT_GILROY_REGULAR,
  FONT_GILROY_BOLD
} from "../../../static/misc/constants";
import i18n from "../../services/i18n";

interface NextExamSubjectProps {
  textStyle?: StyleProp<TextStyle>;
  subject: string;
  duration: number;
}

class NextExamSubject extends React.PureComponent<NextExamSubjectProps> {
  render(): JSX.Element {
    const { textStyle, subject, duration } = this.props;
    const hour = Math.floor((duration % (24 * 3600)) / 3600);
    const minutes = Math.floor((duration % 3600) / 60);

    return (
      <Text style={[styles.defaultRegularTxt, textStyle]}>
        {`${i18n.t("homepage.subject")} `}
        <Text style={{ fontFamily: FONT_GILROY_BOLD }}>{subject}</Text>
        {` ${i18n.t("homepage.duration")} `}
        <Text style={{ fontFamily: FONT_GILROY_BOLD }}>{`${hour}h${
          minutes < 10 ? "0" : ""
        }${minutes}`}</Text>
      </Text>
    );
  }
}

const styles = StyleSheet.create({
  defaultRegularTxt: {
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_14,
    color: COLOR_BLUE
  }
});

export default NextExamSubject;
