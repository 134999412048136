import React from "react";
import { StyleProp, StyleSheet, Text, TextStyle } from "react-native";
import { COLOR_WHITE } from "../../../static/misc/colors";
import {
  FONTSIZE_55,
  FONTSIZE_65,
  FONT_TUNGSTENCONDENSED_LIGHT,
  FONT_TUNGSTENCONDENSED_SEMIBOLD
} from "../../../static/misc/constants";

interface DateTagProps {
  dayStyle?: StyleProp<TextStyle>;
  monthStyle?: StyleProp<TextStyle>;
  day: string;
  month: string;
}

class DateTag extends React.PureComponent<DateTagProps> {
  render(): JSX.Element {
    const { dayStyle, monthStyle, day, month } = this.props;
    return (
      <Text style={[styles.defaultDayStyle, dayStyle]}>
        {day}
        <Text style={[styles.defaultMonthStyle, monthStyle]}> {month}</Text>
      </Text>
    );
  }
}

const styles = StyleSheet.create({
  defaultDayStyle: {
    fontFamily: FONT_TUNGSTENCONDENSED_SEMIBOLD,
    fontSize: FONTSIZE_65,
    color: COLOR_WHITE,
    lineHeight: 50
  },
  defaultMonthStyle: {
    fontFamily: FONT_TUNGSTENCONDENSED_LIGHT,
    fontSize: FONTSIZE_55
  }
});

export default DateTag;
