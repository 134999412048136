import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { bindActionCreators, Dispatch } from "redux";
import {
  StyleSheet,
  View,
  ScrollView,
  TouchableOpacity,
  Text,
  LayoutRectangle,
  LayoutChangeEvent
} from "react-native";

import {
  COLOR_BLACK,
  COLOR_BLUE_TESTWE,
  COLOR_WHITE
} from "../../../static/misc/colors";
import {
  completeExamPartsInExamTakingObject,
  hasCompletedAnswer
} from "../../services/exam-navbar-progress";
import { guidGenerator } from "../../../static/misc/utils";
import { ExamIndex, ExamPart, ExamType } from "../../modules/exams/types/exam";
import { StudentAnswerType } from "../../modules/examTaking/types/studentPaper";
import ExamPartCarouselItem from "./ExamPartCarouselItem";
import { updateExamTaking } from "../../modules/examTaking/actions/examTaking";
import { ChangeEnum } from "../organisms/ExamTakingContent";

interface ExamPartsCarouselProps {
  currentPartIndex: number;
  currentExam: ExamType;
  currentExamPartIndex: number;
  studentAnswers: StudentAnswerType[];
  updateCurrentExamTaking: (currentExam: ExamType) => void;
  onPressExamPart: (index: number, examPart: ExamPart) => void;
  toggleRemainingDurationWarningModal(
    visible: boolean,
    changeType?: ChangeEnum,
    callback?: (changeType: ChangeEnum) => void
  ): void;
  selectedExamPart?: ExamPart;
}

interface ExamPartsCarouselState {
  cursor: number;
}

const ScrollButton = ({
  isScrollable,
  onPress,
  direction
}: {
  isScrollable: boolean;
  onPress: () => void;
  direction: "left" | "right";
}): JSX.Element => (
  <View
    style={[
      styles.shadowBox,
      isScrollable && direction === "left" && styles.shadowBoxLeft,
      isScrollable && direction === "right" && styles.shadowBoxRight
    ]}
  >
    {isScrollable && (
      <TouchableOpacity onPress={onPress}>
        <Text style={{ color: COLOR_BLUE_TESTWE }}>
          {direction === "left" ? "<<" : ">>"}
        </Text>
      </TouchableOpacity>
    )}
  </View>
);

class ExamPartsCarouselComponent extends React.PureComponent<
  ExamPartsCarouselProps,
  ExamPartsCarouselState
> {
  // FIXME: Fix type here
  scrollViewContainer = React.createRef<any>();

  examPartsRefs = new Map<number, { node: View; layout?: LayoutRectangle }>();

  constructor(props: ExamPartsCarouselProps) {
    super(props);

    this.state = {
      cursor: 0
    };
  }

  componentDidUpdate(prevProps: ExamPartsCarouselProps): void {
    const {
      currentExam,
      currentExamPartIndex,
      updateCurrentExamTaking,
      studentAnswers
    } = this.props;

    if (
      currentExam.examParts !== undefined &&
      typeof currentExam.examParts !== "string" &&
      prevProps.currentExam.examParts !== undefined &&
      typeof prevProps.currentExam.examParts !== "string"
    ) {
      const examUpdated = completeExamPartsInExamTakingObject(
        currentExam,
        currentExamPartIndex,
        studentAnswers,
        currentExam.examParts[currentExamPartIndex]
      );
      if (
        prevProps.currentExamPartIndex !== currentExamPartIndex ||
        currentExam.examParts[currentExamPartIndex] ===
          prevProps.currentExam.examParts[prevProps.currentExamPartIndex] ||
        !_.isEqual(currentExam, examUpdated)
      ) {
        updateCurrentExamTaking(examUpdated);
      }
    }
  }

  handleRef = (index: number, node: View) =>
    !this.examPartsRefs.has(index) && this.examPartsRefs.set(index, { node });

  handleLayout = (index: number, event: LayoutChangeEvent) => {
    const { layout } = event.nativeEvent;
    const ref = this.examPartsRefs.get(index);

    if (ref) this.examPartsRefs.set(index, { node: ref.node, layout });
  };

  handleScroll = (type: "left" | "right") => {
    const { cursor } = this.state;

    const shouldSkip = !(
      this.scrollViewContainer && this.scrollViewContainer.current
    );

    if (shouldSkip) return;

    if (type === "right") {
      const nextItem = this.examPartsRefs.get(cursor + 1);

      this.scrollViewContainer.current?.scrollTo({
        x: nextItem?.layout?.x ?? 0,
        animated: true
      });

      this.setState({
        cursor: nextItem?.layout?.x ? cursor + 1 : cursor
      });
    }

    if (type === "left") {
      const prevItem = this.examPartsRefs.get(cursor - 1);

      this.scrollViewContainer.current?.scrollTo({
        x: prevItem?.layout?.x ?? 0,
        animated: true
      });

      this.setState({
        cursor: prevItem?.layout?.x ? cursor - 1 : 0
      });
    }
  };

  getNotAnsweredQuestionsCount = (
    examPart: ExamPart,
    studentAnswers: StudentAnswerType[]
  ): number => {
    const examPartIndexes = examPart.partIndexes
      ? [...examPart.partIndexes]
      : [];

    const totalCount = examPartIndexes.reduce<number>((acc, current) => {
      let result = acc;

      if (current.type === "question") result++;

      if (current.type === "exercise" && current.exercise) {
        result += current.exercise.questions.length;
      }

      return result;
    }, 0);

    const totalAnsweredCount = examPartIndexes.reduce<number>(
      (acc, current) => {
        let result = acc;

        if (current.type === "question") {
          const studentAnswer = studentAnswers.find(
            (sa) => sa.question === current.question?.id
          );

          if (studentAnswer && hasCompletedAnswer(studentAnswer)) {
            result++;
          }
        }

        if (current.type === "exercise" && current.exercise) {
          result += current.exercise.questions
            .map((q) => studentAnswers.find((sa) => sa.question === q.id))
            .filter((sa) => sa && hasCompletedAnswer(sa)).length;
        }

        return result;
      },
      0
    );

    return totalCount - totalAnsweredCount;
  };

  getBookmarkedQuestionsCount = (examPart: ExamPart) => {
    return examPart.partIndexes.reduce<number>((acc, current) => {
      let result = acc;

      if (current.type === "question" && current.question?.isBookMarked) {
        result++;
      }

      if (current.type === "exercise" && current.exercise) {
        result += current.exercise.questions.filter((q) => q.isBookMarked)
          .length;
      }

      return result;
    }, 0);
  };

  isScrollable = (direction: "left" | "right"): boolean => {
    const { cursor } = this.state;

    // No reason to scroll
    if (
      this.scrollViewContainer.current?.scrollWidth ===
      this.scrollViewContainer.current?.offsetWidth
    ) {
      return false;
    }

    if (direction === "left") {
      return !!(cursor > 0);
    }

    if (direction === "right") {
      const nextItemPositionX = this.examPartsRefs.get(cursor + 1)?.layout?.x;

      const width =
        this.scrollViewContainer.current?.scrollWidth -
        this.scrollViewContainer.current?.offsetWidth;

      return !!(nextItemPositionX && width > nextItemPositionX);
    }

    return false;
  };

  hasChronoInQuestion = (examIndex: ExamIndex): boolean => {
    return (
      examIndex.question?.durationLimit === true &&
      (examIndex.question?.remainingDuration || 0) > 0
    );
  };

  hasChronoInExercise = (examIndex: ExamIndex): boolean => {
    return (
      examIndex.exercise?.durationLimit === true &&
      (examIndex.exercise?.remainingDuration || 0) > 0
    );
  };

  hasChronoInExamPart = (examPart: ExamPart): boolean => {
    return (
      examPart.durationLimit === true && (examPart.remainingDuration || 0) > 0
    );
  };

  isLastQuestionInExamPart = (
    currentQuestionIndex: number,
    examPart?: ExamPart
  ): boolean => {
    return !!(
      (examPart?.partIndexes || []).length - 1 ===
      currentQuestionIndex
    );
  };

  hasVisited = (examPart?: ExamPart, studentAnswers?: StudentAnswerType[]) =>
    examPart?.partIndexes.some((examIndex) => {
      if (examIndex.type === "question") {
        return studentAnswers?.some(
          (studentAnswer) => studentAnswer.question === examIndex.question?.id
        );
      }

      if (examIndex.type === "exercise") {
        return studentAnswers?.some((studentAnswer) =>
          examIndex.exercise?.questions.some(
            (question) => question.id === studentAnswer.question
          )
        );
      }

      return false;
    }) || false;

  render(): JSX.Element {
    const {
      currentExam,
      currentExamPartIndex,
      studentAnswers,
      onPressExamPart,
      currentPartIndex,
      toggleRemainingDurationWarningModal,
      selectedExamPart
    } = this.props;

    if (
      // Should hide the carousel
      !currentExam.examParts ||
      currentExam.examParts.length <= 1 ||
      typeof currentExam.examParts === "string"
    )
      return <View style={styles.container} />;

    const items = [...currentExam.examParts];

    return (
      <View style={styles.container}>
        <ScrollButton
          direction="left"
          isScrollable={this.isScrollable("left")}
          onPress={() => this.handleScroll("left")}
        />

        <ScrollView
          scrollEnabled
          horizontal
          ref={this.scrollViewContainer}
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={styles.scrollContainer}
        >
          {items.map((examPart, i) => (
            <ExamPartCarouselItem
              key={guidGenerator()}
              durationFixed={currentExam.examParams?.durationFixed}
              isCurrent={examPart.position === currentExamPartIndex}
              currentPosition={examPart.position + 1}
              hasCompleted={examPart.status === "completed"}
              hasPartiallyCompleted={examPart.status === "partially-completed"}
              hasVisited={this.hasVisited(examPart, studentAnswers)}
              hasBookmarked={examPart.status === "bookmarked"}
              isLastExamPart={!!(i === items.length - 1)}
              isLastQuestionInExamPart={this.isLastQuestionInExamPart(
                currentPartIndex,
                selectedExamPart
              )}
              toggleRemainingDurationWarningModal={
                toggleRemainingDurationWarningModal
              }
              handleRef={(n) => this.handleRef(i, n)}
              onLayout={(e) => this.handleLayout(i, e)}
              onPress={() => onPressExamPart(i, examPart)}
              hasChronoInQuestion={this.hasChronoInQuestion(
                items[currentExamPartIndex].partIndexes[currentPartIndex]
              )}
              hasChronoInExercise={this.hasChronoInExercise(
                items[currentExamPartIndex].partIndexes[currentPartIndex]
              )}
              hasChronoInExamPart={this.hasChronoInExamPart(
                items[currentExamPartIndex]
              )}
              boomarkedQuestionsCount={this.getBookmarkedQuestionsCount({
                ...examPart
              })}
              notAnsweredQuestionsCount={this.getNotAnsweredQuestionsCount(
                { ...examPart },
                [...studentAnswers]
              )}
            />
          ))}
        </ScrollView>

        <ScrollButton
          direction="right"
          isScrollable={this.isScrollable("right")}
          onPress={() => this.handleScroll("right")}
        />
      </View>
    );
  }
}
const styles = StyleSheet.create({
  container: {
    overflow: "hidden",
    maxHeight: 32,
    minWidth: "82vw",
    maxWidth: "82vw"
  },
  scrollContainer: {
    marginHorizontal: 8,
    maxWidth: "82vw",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  shadowBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    bottom: 0,
    width: 16,
    backgroundColor: COLOR_WHITE
  },
  shadowBoxLeft: {
    left: 0,
    boxShadow: `16px 0px 16px -7px ${COLOR_BLACK}`,
    zIndex: 2
  },
  shadowBoxRight: {
    right: 0,
    boxShadow: `-16px 0px 16px -7px ${COLOR_BLACK}`
  }
});

const ExamPartsCarousel = connect(
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  () => ({}),
  (dispatch: Dispatch) => {
    return {
      ...bindActionCreators(
        {
          updateCurrentExamTaking: updateExamTaking
        },
        dispatch
      )
    };
  }
  // FIXME: fix type here
)(ExamPartsCarouselComponent as any);

export default ExamPartsCarousel;
