import React from "react";
import {
  ImageSourcePropType,
  StyleProp,
  StyleSheet,
  TextStyle,
  TouchableOpacity,
  ViewStyle
} from "react-native";
import Icon from "../atoms/Icon";
import LightText from "../atoms/LightText";
import { FONTSIZE_13 } from "../../../static/misc/constants";
import { COLOR_WHITE } from "../../../static/misc/colors";

interface TouchableButtonProps {
  containerStyle?: StyleProp<ViewStyle>;
  labelStyle?: StyleProp<TextStyle>;
  label: string;
  onPress: () => void;
  icon?: ImageSourcePropType;
}

/**
 * Custom Button using TouchableButton from react-native.
 *
 * @param containerStyle StyleProp<ViewStyle> (optional).
 * @param labelStyle tyleProp<ViewStyle> (optional).
 * @param label string. Mandatory text to display.
 * @param onPress function to trigger when clicking
 * @param icon ImageSourcePropType(optional).
 *
 * @returns TouchableOpacity + icon + LightText
 */

class TouchableButton extends React.PureComponent<TouchableButtonProps> {
  render(): JSX.Element {
    const { containerStyle, labelStyle, label, onPress, icon } = this.props;
    return (
      <TouchableOpacity
        activeOpacity={0.5}
        style={[styles.defaultContainerStyle, containerStyle]}
        onPress={onPress}
      >
        {icon && <Icon icon={icon} iconStyle={[styles.defaultIconStyle]} />}
        <LightText
          textStyle={[styles.defaultLabelStyle, labelStyle]}
          text={label}
        />
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  defaultContainerStyle: {
    flexDirection: "row",
    alignItems: "center"
  },
  defaultLabelStyle: {
    fontSize: FONTSIZE_13,
    textAlignVertical: "center",
    paddingLeft: 8,
    color: COLOR_WHITE
  },
  defaultIconStyle: {
    width: 16,
    height: 16,
    resizeMode: "contain"
  }
});

export default TouchableButton;
