/* eslint-disable no-control-regex */
/* eslint-disable no-irregular-whitespace */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from "react";
import { View } from "react-native";
import fse from "fs-extra";
import XLSX, { Sheet, WorkBook } from "@sheet/edit";

import "../../../libs/xspreadsheet-submodule/dist/xspreadsheet.css";
import "../../../libs/xspreadsheet-submodule/src";
import { ipcRenderer } from "electron";
import {
  AUTOSAVE_TIMER,
  FONTSIZE_14,
  FONT_GILROY_LIGHT,
  IS_PREVIEW,
  IS_WEB_ENABLE,
  SPREADSHEET_QUESTION,
  TRAINING_SPREADSHEET
} from "../../../static/misc/constants";
import CustomInput from "../atoms/CustomInput";
import { COLOR_GREY_BORDER } from "../../../static/misc/colors";
import { writeFileOnFs } from "../../../static/fileutils";
import i18n from "../../services/i18n";
import { StudentAnswerType } from "../../modules/examTaking/types/studentPaper";
import { ExamType } from "../../modules/exams/types/exam";
import { completeStudentAnswerStatus } from "../../services/exam-navbar-progress";

export interface SpreadSheetProps {
  id: string;
  questionId: string;
  content: string;
  isTraining: boolean;
  forceSaveSpreadSheet: boolean;
  saveFile: (cb: (filepath: string) => void) => void;
  updateWorkBook: (content: string) => void;
  onPushStudentAnswer: (studentAnswer: Partial<StudentAnswerType>) => void;
  currentExam: ExamType;
}

export interface SpreadSheetState {
  wb: XLSX.WorkBook | undefined;
  selectedCell: {
    text: string | undefined;
    rowNb: number;
    colNb: number;
  };
}
class SpreadSheet extends React.Component<SpreadSheetProps, SpreadSheetState> {
  intervalTimer!: ReturnType<typeof setInterval>;

  grid: any;

  constructor(props: SpreadSheetProps) {
    super(props);
    const { content, isTraining } = this.props;

    if (!isTraining && !IS_PREVIEW && !IS_WEB_ENABLE) {
      const fileContent = fse.readFileSync(content, "utf-8");
      this.state = {
        // Use SheetJS in order to read the file content
        wb: XLSX.read(fileContent, {
          type: "base64",
          cellFormula: true,
          cellStyles: false
        }),
        selectedCell: {
          text: undefined,
          rowNb: 0,
          colNb: 0
        }
      };
    } else {
      this.state = {
        // Use SheetJS in order to read the file content
        wb: XLSX.read(content, {
          type: "base64",
          cellFormula: true,
          cellStyles: false
        }),
        selectedCell: {
          text: undefined,
          rowNb: 0,
          colNb: 0
        }
      };
    }

    this.saveFileToLocal = this.saveFileToLocal.bind(this);
    this.writeFileCb = this.writeFileCb.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const { id } = this.props;
    const { wb } = this.state;

    if (wb) {
      const container = document.getElementById(
        `spreadsheet-${id}`
      ) as HTMLElement;
      // Grid content from xspreadsheet with and edit mode and defining the view
      this.grid = (window as any).x_spreadsheet(container, {
        mode: "edit",
        view: {
          height: () => 1000,
          width: () => container.parentElement?.clientWidth
        }
      });

      // Set xspreadsheet lang to current user locale
      (window as any).x_spreadsheet.locale(i18n.locale);

      this.grid.on(
        "cell-selected",
        (cell: any, rowNb: number, colNb: number) => {
          this.setState({
            selectedCell: {
              text: cell?.text,
              rowNb,
              colNb
            }
          });
        }
      );

      // Load data from the base64 content into XSPREADSHEET with stox function
      this.grid.loadData(this.stox(wb));
      // Event listener when there is a change into XSPREADSHEET
      this.grid.change(async () => {
        this.saveFileToLocal();
        this.setState({ wb: this.xtos(this.grid.getData()) });
      });
      // Validate grid
      this.grid.validate();
    }

    // Save into FS with props function every AUTOSAVE_TIMER seconds
    this.intervalTimer = setInterval(() => {
      this.saveFileToLocal();
    }, AUTOSAVE_TIMER);
  }

  componentDidUpdate(prevProps: SpreadSheetProps): void {
    const { forceSaveSpreadSheet } = this.props;

    // when asking to save Spreadsheet from props, saved it
    if (forceSaveSpreadSheet && !prevProps.forceSaveSpreadSheet) {
      this.saveFileToLocal();
    }
  }

  componentWillUnmount(): void {
    clearInterval(this.intervalTimer);
    this.saveFileToLocal();
  }

  removeDuplicates = (arrOfObj: any[]): any[] => {
    const dataArr = new Map(
      arrOfObj.map((item) => [JSON.stringify(item), item])
    );
    return [...dataArr.values()];
  };

  // Function which serialize data from SheetJS to Xspreadsheet
  stox = (wb: WorkBook): any[] => {
    const grids: Sheet[] = [];
    // Loop on each Sheets
    wb.SheetNames.forEach((name: string) => {
      const ws = wb.Sheets[name];
      const styles = this.removeDuplicates(
        Object.entries(ws)
          .map(([key, value]) => {
            const style = (value as any)?.s;
            if (!key.includes("!") && style) {
              const bgcolor = style?.fgColor?.rgb
                ? style.fgColor.rgb === "parent"
                  ? `#${style.bgColor.rgb}​​​​​`
                  : `#${style.fgColor.rgb}​​​​​`
                : "transparent";
              return {
                bgcolor,
                color: style.color?.rgb ? `#${style.color.rgb}` : "black",
                font: {
                  bold: style.bold ? style.bold : false,
                  italic: style.italic ? style.italic : false
                }
              };
            }
            return null;
          })
          .filter((isNull) => isNull)
      );
      const getEmptyCells = (num: number): Array<any> => {
        const emptyRows = [];
        for (let i = 0; i < num; i++) {
          emptyRows.push([null]);
        }
        return emptyRows;
      };
      const colWidths = ws["!cols"]?.map((col) => ({ width: col.wpx }));
      const grid: Sheet = {
        name,
        rows: {},
        styles,
        cols: { ...colWidths }
      };
      let aoa;
      let range;
      let emptyRows = [] as any[];
      let emptyCols = [] as any[];
      if (ws["!ref"]) {
        range = XLSX.utils.decode_range(ws["!ref"]!);
        aoa = XLSX.utils.sheet_to_json(ws, {
          range,
          raw: false,
          header: 1
        });
        emptyRows = getEmptyCells(range.s.r);
        emptyCols = getEmptyCells(range.s.c);
      } else {
        aoa = XLSX.utils.sheet_to_json(ws, {
          raw: false,
          header: 1
        });
      }

      [...emptyRows, ...aoa].forEach((row: any, i) => {
        const cells: any = {};
        [...emptyCols, ...row].forEach((cell: any, j: any) => {
          const cellAddress = { c: j, r: i };
          const cellRef = XLSX.utils.encode_cell(cellAddress);
          const s = ws[cellRef]?.s;
          const bgcolor = s?.fgColor?.rgb
            ? s.fgColor.rgb === "parent"
              ? `#${s.bgColor.rgb}​​​​​`
              : `#${s.fgColor.rgb}​​​​​`
            : "transparent";
          const cellStyle = {
            bgcolor,
            color: s?.color?.rgb ? `#${s.color.rgb}` : "black",
            font: {
              bold: s?.bold ? s?.bold : false,
              italic: s?.italic ? s?.italic : false
            }
          };
          // cells.style only takes the index of grid.styles
          let styleIndex = 0;
          if (grid.styles && grid.styles.length > 0) {
            styleIndex = grid.styles.findIndex((style: any) => {
              return JSON.stringify(style) === JSON.stringify(cellStyle);
            });
          }
          const styleIndexOrDefault = styleIndex > 0 ? styleIndex : 0;

          // Handle formula which include other cells from another sheet
          let textCellToShow = cell;
          if (ws[cellRef]?.f) {
            textCellToShow = `=${ws[cellRef]?.f}`;
            const cellMatched = ws[cellRef]?.f
              ?.match(/\((.*)\)/)
              ?.pop()
              ?.split("!")[0];
            if (cellMatched && wb.SheetNames.includes(cellMatched)) {
              textCellToShow = cell;
            }
          }
          //

          cells[j] = {
            text: textCellToShow,
            style: styleIndexOrDefault,
            formula: ws[cellRef]?.f
          };
        });
        const rows = { ...ws["!rows"] };
        grid.rows[i] = {
          cells,
          height: rows[i]?.hpx ? rows[i]?.hpx : 25
        };
      });
      grids.push(grid);
    });
    for (let index = 0; index < grids.length; index++) {
      if (grids[index].styles.length <= 0) {
        grids[index].styles = [
          {
            bgcolor: "transparent",
            color: "#000000",
            font: {
              bold: false,
              italic: false
            }
          }
        ];
      }
    }
    return grids;
  };

  printToLetter(number: string, result = ""): string {
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let charIndex = Number(number) % alphabet.length;
    let quotient = Number(number) / alphabet.length;
    if (charIndex - 1 === -1) {
      charIndex = alphabet.length;
      quotient--;
    }
    result = alphabet.charAt(charIndex - 1) + result;
    if (quotient >= 1) {
      this.printToLetter(quotient.toString(), result);
    }
    return result;
  }

  writeOnFormulaBar(text: string): void {
    const { selectedCell, wb } = this.state;
    let indexPage = 0;
    if (wb)
      wb.SheetNames.forEach((name: string, index: number) => {
        if (name === this.grid?.sheet?.data?.name) indexPage = index;
      });
    this.grid
      ?.cellText(selectedCell.rowNb, selectedCell.colNb, text, indexPage)
      .reRender();

    this.setState({ wb: this.xtos(this.grid.getData()) });
  }

  // Function which serialize data from Xspreadsheet to SheetJS
  xtos(sdata: Sheet[]): WorkBook {
    const wb = XLSX.utils.book_new();
    sdata.forEach((xws) => {
      const aoa = [[]];
      const rowobj = xws.rows;
      for (let ri = 0; ri < rowobj.len; ++ri) {
        const row = rowobj[ri];
        if (row) {
          aoa[ri] = [];
          Object.keys(row.cells).forEach((k) => {
            const idx = +k;
            if (Number.isNaN(idx)) return;
            (aoa[ri][idx] as any) = row.cells[k].text;
          });
        }
      }
      const ws = XLSX.utils.aoa_to_sheet(aoa);
      const { cols } = xws;
      const { rows } = xws;
      const wscols: any = [];
      const wsrows: any = [];

      if (rows)
        Object.keys(rows).forEach((k) => {
          if (k !== "len") {
            const digit = Number(k) + 1;
            Object.keys(rows[k].cells).forEach((i) => {
              const styleObj = xws.styles[rows[k].cells[i].style];
              const refCell = `${this.printToLetter(
                (Number(i) + 1).toString()
              )}${digit}`;
              if (ws[refCell]) {
                ws[refCell].s = {
                  fgColor: {
                    rgb:
                      styleObj && styleObj?.bgcolor
                        ? styleObj?.bgcolor.replace(/[^\x00-\x7F]/g, "")
                        : "transparent"
                  },
                  color: {
                    rgb:
                      styleObj && styleObj?.color
                        ? styleObj.color.replace(/[^\x00-\x7F]/g, "")
                        : "#000000"
                  },
                  italic:
                    styleObj && styleObj.font && styleObj.font.italic
                      ? styleObj?.font?.italic
                      : false,
                  bold:
                    styleObj && styleObj.font && styleObj.font.bold
                      ? styleObj?.font?.bold
                      : false
                };
              }
            });
            wsrows.push({
              hpx: rows[k].height ? rows[k].height : 25
            });
          }
        });
      if (cols)
        Object.keys(cols).forEach((k) => {
          if (k !== "len") {
            wscols.push({
              wpx: cols[k].width ? cols[k].width : 95
            });
          }
        });
      ws["!rows"] = wsrows;
      ws["!cols"] = wscols;
      XLSX.utils.book_append_sheet(wb, ws, xws.name);
    });
    return wb;
  }

  writeFileCb(filepath: string): void {
    const {
      isTraining,
      updateWorkBook,
      onPushStudentAnswer,
      currentExam,
      questionId
    } = this.props;
    const { wb } = this.state;

    onPushStudentAnswer(
      completeStudentAnswerStatus(
        {
          question: filepath === TRAINING_SPREADSHEET ? filepath : questionId,
          questionType: SPREADSHEET_QUESTION,
          mediaData: [
            {
              filename: filepath,
              data: ""
            }
          ]
        } as StudentAnswerType,
        currentExam
      )
    );

    if (wb !== undefined && !isTraining) {
      try {
        const data = XLSX.write(wb, {
          type: "base64",
          cellDates: true,
          cellStyles: true
        });
        if (!IS_WEB_ENABLE) {
          writeFileOnFs(data, "", "", filepath);
        } else {
          updateWorkBook(data);
        }
      } catch (err) {
        ipcRenderer.send(
          "LOG_ERROR",
          `An error occurred while SheetJS tried to save modifcations on file ${filepath}`
        );
        throw err;
      }
    }
  }

  saveFileToLocal(): void {
    const { isTraining, saveFile } = this.props;
    const { wb } = this.state;

    if (wb !== undefined && !isTraining) {
      saveFile(this.writeFileCb);
    } else {
      this.writeFileCb(TRAINING_SPREADSHEET);
    }
  }

  render(): JSX.Element | null {
    const { id } = this.props;
    const { selectedCell } = this.state;
    return (
      <View
        style={{ width: "100%" }}
        onLayout={() => {
          window.dispatchEvent(new Event("resize"));
        }}
      >
        <CustomInput
          fontFamily={FONT_GILROY_LIGHT}
          height={25}
          value={selectedCell.text}
          placeholder="Formula"
          bottom={-5}
          borderColor={COLOR_GREY_BORDER}
          borderRadius={3}
          fontSize={FONTSIZE_14}
          width="100%"
          onChangeText={(input) => this.writeOnFormulaBar(input)}
        />
        <div
          id={`spreadsheet-${id}`}
          onMouseLeave={() => this.saveFileToLocal()}
        />
      </View>
    );
  }
}

export default SpreadSheet;
