import React from "react";
import CustomModal from "../atoms/CustomModal";
import i18n from "../../services/i18n";
import ContentModal from "./ContentModal";

export interface FullScreenModalProps {
  isVisible: boolean;
  onPress: () => void;
}

export default class FullScreenModal extends React.Component<FullScreenModalProps> {
  shouldComponentUpdate(nextProps: FullScreenModalProps): boolean {
    const { isVisible } = this.props;
    return nextProps.isVisible !== isVisible;
  }

  render(): JSX.Element {
    const { isVisible, onPress } = this.props;

    return (
      <CustomModal
        heightModal="50%"
        widthModal="50%"
        borderRadiusModal={5}
        showCloseButton={false}
        visible={isVisible}
        onCloseModal={() => null}
      >
        <ContentModal
          onValidation={onPress}
          labelButton={i18n.t("modal.backFullscreen")}
          description={i18n.t("modal.fullscreen")}
        />
      </CustomModal>
    );
  }
}
