export const SET_BANNER_ITEMS_STATUS = "SET_BANNER_ITEMS_STATUS";

export interface BannerItemsStatus {
  ram: boolean;
  antivirus: boolean;
  network: boolean;
}

export interface SetBannerItemsStatusAction {
  type: typeof SET_BANNER_ITEMS_STATUS;
  payload: BannerItemsStatus;
}

export interface PersistedStatusState {
  bannerItemsStatus: BannerItemsStatus;
}

export type PersistedStatusAction = SetBannerItemsStatusAction;
