import React from "react";
import moment from "moment";
import { ipcRenderer } from "electron";
import HeaderContainer from "../../atomic/organisms/Header/HeaderContainer";
import { StackNavigatorProp } from "../../router/StackNavigator";
import { EstablishmentsType, SetEstablishmentAction } from "./types/user";
import Profile from "../../atomic/templates/Profile";
import {
  getAsyncStorageItem,
  setAsyncStorageItem
} from "../../../static/misc/utils";
import { TIMEZONE } from "../../../static/misc/constants";

interface ProfileViewProps {
  navigation: StackNavigatorProp;
  userId: string;
  token: string | undefined;
  firstName: string;
  lastName: string;
  email: string;
  establishments: EstablishmentsType[];
  currentEstablishment?: EstablishmentsType;
  setEstablishment: (
    userToken: string,
    establishmentId: string,
    userId: string
  ) => SetEstablishmentAction;
  postingEstablishment: boolean;
  locale: string;
}

interface ProfileState {
  showMenu: boolean;
  defaultTimeZone: string | null;
}

class ProfileView extends React.PureComponent<ProfileViewProps, ProfileState> {
  constructor(props: ProfileViewProps) {
    super(props);

    this.state = {
      showMenu: false,
      defaultTimeZone: null
    };
    this.changeEstablishmentChoice = this.changeEstablishmentChoice.bind(this);
    this.changeTimezoneChoice = this.changeTimezoneChoice.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  async componentDidMount(): Promise<void> {
    this.setState({ defaultTimeZone: await getAsyncStorageItem(TIMEZONE) });
    ipcRenderer.send("LOG_INFO", "PAGEMOVE | ProfileView");
  }

  toggleMenu(toggle?: boolean): void {
    const { showMenu } = this.state;
    this.setState({
      showMenu: toggle !== undefined ? toggle : !showMenu
    });
  }

  changeEstablishmentChoice(establishmentName: string): void {
    const { establishments, token, setEstablishment, userId } = this.props;
    const chosenEstablishment = establishments.find(
      (establishment) => establishment.name === establishmentName
    );
    if (chosenEstablishment && token) {
      const chosenEstablishmentId = chosenEstablishment.id;
      setEstablishment(token, chosenEstablishmentId, userId);
    }
  }

  changeTimezoneChoice(timezone: string): void {
    // update timezone dans localstorage
    setAsyncStorageItem(TIMEZONE, timezone);
    // update timezone sur l'app
    moment.tz.setDefault(timezone);
    // reload component
    this.setState({ defaultTimeZone: timezone });
  }

  render(): JSX.Element {
    const {
      navigation,
      firstName,
      lastName,
      email,
      establishments,
      currentEstablishment,
      postingEstablishment,
      locale
    } = this.props;
    const { showMenu, defaultTimeZone } = this.state;
    return (
      <>
        <HeaderContainer
          disableSynchronisation
          navigation={navigation}
          showMenu={showMenu}
          toggleMenu={this.toggleMenu}
        />
        <Profile
          givenName={firstName}
          familyName={lastName}
          email={email}
          establishments={establishments}
          currentEstablishment={currentEstablishment}
          changeEstablishmentChoice={this.changeEstablishmentChoice}
          changeTimezoneChoice={this.changeTimezoneChoice}
          postingEstablishment={postingEstablishment}
          key={locale}
          defaultTimeZone={defaultTimeZone}
        />
      </>
    );
  }
}

export default ProfileView;
