import React from "react";
import { StyleProp, TextInput, TextStyle, View } from "react-native";
import {
  COLOR_BLACK,
  COLOR_GREY_BLUE,
  COLOR_WHITE
} from "../../../static/misc/colors";
import {
  FONTSIZE_14,
  FONT_GILROY_REGULAR,
  INPUT_HEIGHT
} from "../../../static/misc/constants";
import i18n from "../../services/i18n";

interface CustomInputProps {
  left?: number;
  right?: number;
  maxLength?: number;
  autoCapitalize?: boolean;
  bottom?: number;
  height?: number | string;
  minHeight?: number | string;
  width?: string | number;
  multiline?: boolean;
  color?: string;
  borderRadius?: number;
  backgroundColor?: string;
  fontFamily?: string;
  fontSize?: number;
  editable?: boolean;
  borderColor?: string;
  paddingVertical?: number;
  paddingHorizontal?: number;
  padding?: number;
  borderWidth?: number;
  letterSpacing?: number;
  secureTextEntry?: boolean;
  textAlign?: "left" | "right" | "auto" | "center" | "justify" | undefined;
  onChangeText: (text: string) => void;
  placeholder?: string;
  keyboardType?:
    | "default"
    | "email-address"
    | "numeric"
    | "phone-pad"
    | "ascii-capable"
    | "numbers-and-punctuation"
    | "url"
    | "number-pad"
    | "name-phone-pad"
    | "decimal-pad"
    | "twitter"
    | "web-search"
    | "visible-password";
  value?: string;
}

interface TextInputNavState {
  text: string;
}

export default class CustomInput extends React.Component<
  CustomInputProps,
  TextInputNavState
> {
  static defaultProps = {
    placeholder: i18n.t("exam.typehere"),
    multiline: false,
    left: undefined,
    autoCapitalize: false,
    right: undefined,
    bottom: undefined,
    height: undefined,
    minHeight: INPUT_HEIGHT,
    width: "100%",
    color: COLOR_BLACK,
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_14,
    editable: true,
    borderColor: COLOR_GREY_BLUE,
    paddingVertical: 15,
    paddingHorizontal: 15,
    padding: 15,
    letterSpacing: undefined,
    borderWidth: 2,
    secureTextEntry: false,
    keyboardType: "default",
    textAlign: "left",
    backgroundColor: COLOR_WHITE
  };

  constructor(props: CustomInputProps) {
    super(props);
    this.state = {
      text: props.value || ""
    };
  }

  componentDidUpdate(prevProps: CustomInputProps): void {
    const { value } = this.props;
    if (prevProps.value !== value) {
      this.setState({
        text: value || ""
      });
    }
  }

  handleChange(textChange: string): void {
    const { onChangeText } = this.props;
    this.setState({ text: textChange });
    onChangeText(textChange);
  }

  render(): JSX.Element {
    const {
      placeholder,
      autoCapitalize,
      secureTextEntry,
      editable,
      keyboardType,
      maxLength,
      borderWidth,
      borderColor,
      padding,
      color,
      fontFamily,
      width,
      fontSize,
      height,
      minHeight,
      textAlign,
      letterSpacing,
      paddingHorizontal,
      paddingVertical,
      right,
      left,
      borderRadius,
      bottom,
      multiline,
      backgroundColor
    } = this.props;
    const { text } = this.state;

    const styles = {
      borderTextInput: {
        borderWidth,
        borderRadius,
        borderColor
      },
      textInput: {
        padding,
        height,
        color,
        backgroundColor,
        fontSize,
        fontFamily,
        textAlign,
        letterSpacing,
        paddingHorizontal,
        paddingVertical
      },
      textInputContainer: {
        width,
        padding,
        minHeight,
        right,
        left,
        bottom
      }
    };
    // forcing type because outlineWidth doesn't exist in StyleProp<TextStyle> on react-native (not web)
    const textInputStyle = {
      ...styles.textInput,
      outlineWidth: 0
    };

    return (
      <View style={styles.textInputContainer}>
        <View style={styles.borderTextInput}>
          <TextInput
            style={textInputStyle as StyleProp<TextStyle>}
            onChangeText={(newText) =>
              this.handleChange(
                autoCapitalize ? newText.toUpperCase() : newText
              )
            }
            value={autoCapitalize ? text.toUpperCase() : text}
            maxLength={maxLength}
            multiline={multiline}
            placeholder={placeholder}
            keyboardType={keyboardType}
            placeholderTextColor={COLOR_GREY_BLUE}
            secureTextEntry={secureTextEntry}
            editable={editable}
          />
        </View>
      </View>
    );
  }
}
