/* eslint-disable camelcase */
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "../../../store/rootreducer";
import {
  editStudentPaper,
  postStudentPaper,
  updateStudentPaper,
  exportStudentPaperToFile
} from "../../../modules/examTaking/actions/studentPaper";
import {
  initStudentPaperSubmission,
  stopStudentPaperSubmission
} from "../../../modules/main/actions/status";
import {
  createArchiveBeforeUpload,
  uploadMediaObjectOnAPI
} from "../../../modules/exams/actions/attachedfiles";
import { clearPublicKeys } from "../../../modules/exams/actions/publickeys";
import StudentPapersSynchronisationHandler from "./StudentPapersSynchronisationHandler";
import { updateProctorArchiveExamStatus } from "../../../modules/exams/actions/exams";

const mapStateToProps = (state: RootState) => {
  return {
    // auth
    authorizationToken: state.auth.credentials?.access_token,
    // user
    studentId: state.user.id,
    appDataPath: state.user.appDataPath,
    // status
    isOnline: state.status.isOnline,
    // student paper synchro status
    submittingStudentPaperStatus: state.status.submittingStudentPaperStatus,
    submittingMediaObjectsStatus: state.status.submittingMediaObjectsStatus,
    // exam
    examsPublicKeys: state.publickeys.examsPublicKeys,
    studentPapers: state.studentPaper.studentPapers,
    // retrieving exam data ONLY for exams for which there is a student paper not sent yet
    exams: state.exams.nextExams
      .filter((e) =>
        state.studentPaper.studentPapers.map((sp) => sp.examId).includes(e.id)
      )
      .concat(
        state.exams.passedExams.filter((e) =>
          state.studentPaper.studentPapers.map((sp) => sp.examId).includes(e.id)
        )
      ),
    currentUser: state.user
  };
};

const mapdispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators(
      {
        uploadMediaObjectOnAPI,
        exportStudentPaperToFile,
        postStudentPaper,
        editStudentPaper,
        updateStudentPaper,
        initStudentPaperSubmission,
        stopStudentPaperSubmission,
        createArchiveBeforeUpload,
        updateProctorArchiveExamStatus,
        clearPublicKeys
      },
      dispatch
    )
  };
};

const StudentPapersSynchronisationHandlerContainer = connect(
  mapStateToProps,
  mapdispatchToProps
)(StudentPapersSynchronisationHandler);

export default StudentPapersSynchronisationHandlerContainer;
