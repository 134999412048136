import React from "react";
import {
  StyleProp,
  View,
  ViewStyle,
  StyleSheet,
  TouchableOpacity
} from "react-native";
import { COLOR_GREEN_VALID } from "../../../static/misc/colors";
import { reloadIcon } from "../../../static/misc/images";
import ReloadView from "../atoms/ReloadView";

interface ReloadUpdateProps {
  isLoading?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  backToHomepage: () => void;
}

class ReloadUpdate extends React.PureComponent<ReloadUpdateProps> {
  render(): JSX.Element {
    const { containerStyle, isLoading, backToHomepage } = this.props;
    return (
      <View style={[styles.defaultContainerStyle, containerStyle]}>
        <>
          <TouchableOpacity
            disabled={isLoading}
            onPress={() => backToHomepage()}
            activeOpacity={0.7}
          >
            <ReloadView
              runAnimation={isLoading}
              icon={reloadIcon}
              iconStyle={{ width: 16, height: 16 }}
              containerStyle={{
                width: 45,
                height: 30,
                borderRadius: 15,
                backgroundColor: COLOR_GREEN_VALID
              }}
            />
          </TouchableOpacity>
        </>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  defaultContainerStyle: {
    flexDirection: "row",
    alignItems: "center"
  }
});

export default ReloadUpdate;
