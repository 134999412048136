import React from "react";
import { StyleSheet, View } from "react-native";
import { COLOR_BLUE_TESTWE } from "../../../static/misc/colors";
import i18n from "../../services/i18n";
import CustomModal from "../atoms/CustomModal";
import ExtraBoldTitle from "../atoms/ExtraBoldTitle";
import Loader from "../atoms/Loader";
import RegularText from "../atoms/RegularText";
import Button from "../molecules/Button";

export interface TrainingModalProps {
  isVisible: boolean;
  isSynchronizing: boolean;
  showTrainModal: (bool: boolean) => void;
  startTraining: () => void;
}

class TrainingModal extends React.PureComponent<TrainingModalProps> {
  render(): JSX.Element {
    const {
      isVisible,
      isSynchronizing,
      showTrainModal,
      startTraining
    } = this.props;
    return (
      <CustomModal
        showCloseButton={false}
        onCloseModal={() => null}
        borderRadiusModal={5}
        visible={isVisible}
        heightModal={200}
      >
        <View style={styles.modalContentContainer}>
          <ExtraBoldTitle text={i18n.t("trainingModal.title")} />
          <RegularText text={i18n.t("trainingModal.explanation")} />
          <View style={styles.buttonWrapper}>
            {(!isSynchronizing && (
              <Button
                onPress={() => {
                  showTrainModal(false);
                  startTraining();
                }}
                label={i18n.t("trainingModal.OK")}
                containerStyle={[styles.button]}
              />
            )) || <Loader />}
          </View>
        </View>
      </CustomModal>
    );
  }
}

const styles = StyleSheet.create({
  modalContentContainer: {
    flex: 1,
    width: "100%",
    alignItems: "flex-start",
    justifyContent: "space-between",
    paddingHorizontal: 30,
    paddingVertical: 30
  },
  button: {
    borderColor: COLOR_BLUE_TESTWE,
    backgroundColor: COLOR_BLUE_TESTWE,
    width: 100
  },
  buttonWrapper: {
    width: "100%",
    alignItems: "center"
  }
});

export default TrainingModal;
