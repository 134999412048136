import React from "react";
import { StyleSheet, View, Text } from "react-native";
import {
  FONTSIZE_18,
  FONT_GILROY_REGULAR,
  PADDING_SIDES
} from "../../../static/misc/constants";
import {
  COLOR_BLUE_TESTWE,
  COLOR_GREY_BORDER,
  COLOR_TRANSPARENT
} from "../../../static/misc/colors";

interface ProfileFieldProps {
  title: string;
  content: string;
}

class ProfileField extends React.PureComponent<ProfileFieldProps> {
  render(): JSX.Element {
    const { title, content } = this.props;
    return (
      <View style={styles.profileFieldContainer}>
        <Text style={styles.titleText}>{title}</Text>
        <View style={styles.inputContainer}>
          <Text style={styles.inputText}>{content}</Text>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  profileFieldContainer: {
    flexDirection: "row",
    alignItems: "center",
    height: 50,
    justifyContent: "space-between",
    marginBottom: 20
  },
  titleText: {
    color: COLOR_BLUE_TESTWE,
    fontFamily: FONT_GILROY_REGULAR,
    fontsize: FONTSIZE_18,
    marginRight: 20
  },
  inputContainer: {
    width: "75%",
    borderColor: COLOR_GREY_BORDER,
    backgroundColor: COLOR_TRANSPARENT,
    borderRadius: 5,
    padding: PADDING_SIDES * 0.2,
    borderWidth: 2
  },
  inputText: {
    fontWeight: "bold",
    color: COLOR_BLUE_TESTWE,
    flexWrap: "wrap",
    fontFamily: FONT_GILROY_REGULAR
  }
});

export default ProfileField;
