import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { Comment } from "react-pdf-highlighter";
import {
  COLOR_GREY_PDF_HIGHLIGHT_BACKGROUND,
  COLOR_GREY_PDF_HIGHLIGHT_FOREGROUND
} from "../../../static/misc/colors";

interface PdfHighlightPopupProps {
  comment: Comment;
}

export default class PdfHighlightPopup extends React.PureComponent<PdfHighlightPopupProps> {
  render(): JSX.Element | null {
    const { comment } = this.props;
    return comment.text ? (
      <View style={styles.container}>
        <Text style={styles.text}>{comment.text}</Text>
      </View>
    ) : null;
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLOR_GREY_PDF_HIGHLIGHT_BACKGROUND,
    padding: 5
  },
  text: {
    color: COLOR_GREY_PDF_HIGHLIGHT_FOREGROUND,
    flexShrink: 1,
    maxWidth: 600
  }
});
