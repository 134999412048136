/* eslint-disable camelcase */
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "../../../store/rootreducer";
import {
  getExamsHash,
  getSyncPastExam,
  getSyncExam,
  sortExam,
  removeExams,
  postSyncExams,
  getPreviewExam
} from "../../../modules/exams/actions/exams";
import { setExamsNumberToSync } from "../../../modules/main/actions/status";
import {
  setMediaObject,
  downloadMediaObject
} from "../../../modules/exams/actions/attachedfiles";
import ExamsSynchronisationHandler from "./ExamsSynchronisationHandler";
import {
  clearExamTaking,
  createExamTaking
} from "../../../modules/examTaking/actions/examTaking";

const mapStateToProps = (state: RootState) => {
  return {
    // auth
    authorizationToken: state.auth.credentials?.access_token,
    // exams info
    nextExams: state.exams.nextExams,
    passedExams: state.exams.passedExams,
    examsHash: state.exams.examsHash,
    examTaking: state.examTaking.exams ? state.examTaking.exams : [],
    // files
    attachedFiles: state.attachedfiles.mediaObjects,
    appDataPath: state.user.appDataPath,
    appVersion: state.user.appVersion,
    osPlatform: state.user.osPlatform,
    // status
    isOnline: state.status.isOnline,
    showLoader: state.status.showLoader,
    // synchronisation status
    isExamSynchronising: state.status.isExamSynchronising,
    gettingExamsHash: state.status.gettingExamsHash,
    gettingPreviewExam: state.status.gettingPreviewExam,
    nbExamsToSync: state.status.nbExamsToSync,
    nbMediaObjectToGet: state.status.nbMediaObjectToGet,
    timeVerificationStatus: state.status.timeVerificationStatus
  };
};

const mapdispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators(
      {
        getExamsHash,
        getSyncExam,
        getSyncPastExam,
        getPreviewExam,
        sortExam,
        removeExams,
        setExamsNumberToSync,
        postSyncExams,
        setMediaObject,
        downloadMediaObject,
        clearExamTaking,
        createExamTaking
      },
      dispatch
    )
  };
};

const ExamsSynchronisationHandlerContainer = connect(
  mapStateToProps,
  mapdispatchToProps
)(ExamsSynchronisationHandler);

export default ExamsSynchronisationHandlerContainer;
