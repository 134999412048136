import React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import {
  COLOR_BLUE_TESTWE,
  COLOR_GREY_BACKGROUND,
  COLOR_GREY_BORDER
} from "../../../static/misc/colors";
import {
  FONTSIZE_14,
  FONT_GILROY_REGULAR,
  PADDING_SIDES
} from "../../../static/misc/constants";
import { checkGreen } from "../../../static/misc/images";
import LightText from "../atoms/LightText";
import RadioButton from "../atoms/RadioButton";

interface LanguageCheckBoxProps {
  text: string;
  local: string;
  selectedLocale: string;
  selectElement: (locale: string) => void;
}

class LanguageCheckBox extends React.PureComponent<LanguageCheckBoxProps> {
  render(): JSX.Element {
    const { text, local, selectedLocale, selectElement } = this.props;

    return (
      <TouchableOpacity
        onPress={() => {
          selectElement(local);
        }}
        style={styles.container}
      >
        <RadioButton isSelected={local === selectedLocale} icon={checkGreen} />
        <View style={{ paddingHorizontal: PADDING_SIDES * 0.1 }} />
        <LightText text={text} textStyle={[styles.choiceStyle]} />
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: "1%",
    marginHorizontal: "5%"
  },
  choiceStyle: {
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_14,
    paddingVertical: PADDING_SIDES * 0.15,
    color: COLOR_BLUE_TESTWE,
    paddingHorizontal: "3%",
    borderStyle: "solid",
    borderRadius: 2,
    borderWidth: 0.5,
    width: "70%",
    borderColor: COLOR_GREY_BORDER,
    backgroundColor: COLOR_GREY_BACKGROUND
  }
});

export default LanguageCheckBox;
