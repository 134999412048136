import React from "react";
import { StyleSheet, Text, View } from "react-native";
import Webcam from "react-webcam";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { outlinePhoto, outlineVideo } from "../../../static/misc/images";
import { PhotoTaking } from "../molecules/PhotoTaking";
import { VideoTaking } from "../molecules/VideoTaking";
import Button from "../molecules/Button";
import {
  COLOR_BLACK,
  COLOR_BLUE_TESTWE,
  COLOR_BLUE_TESTWE_50,
  COLOR_GREY_PLACEHOLDER,
  COLOR_WHITE
} from "../../../static/misc/colors";
import {
  FONT_GILROY_REGULAR,
  VIDEO_CONSTRAINTS
} from "../../../static/misc/constants";
import SwitchSelector from "../molecules/SwitchSelector";
import i18n from "../../services/i18n";
import { GetErrorAction } from "../../modules/main/types/error";
import { getError } from "../../modules/main/actions/error";
import AbstractVideoRecording from "./VideoRecording";

enum ComponentMode {
  photo,
  video
}

interface CameraTestingViewProps {
  hasPermission: boolean;
  getError: (message: string, forceLogout: boolean) => GetErrorAction;
  onEquipmentValidated: (camera: boolean) => void;
}

interface CameraTestingViewState {
  currentMode: ComponentMode;
  currentPhoto: string | null | undefined;
  currentVideo: string | Blob | null | undefined;
  isVideoRecording: boolean;
  recordingDuration: number;
}

class CameraTestingView extends AbstractVideoRecording<
  CameraTestingViewProps,
  CameraTestingViewState
> {
  constructor(props: CameraTestingViewProps) {
    super(props);

    this.state = {
      currentMode: ComponentMode.photo,
      currentPhoto: null,
      currentVideo: null,
      isVideoRecording: false,
      recordingDuration: 0
    };
  }

  onMediaError(error: unknown): void {
    // eslint-disable-next-line no-shadow
    const { getError } = this.props;
    getError(i18n.t("errors.webcam", [error]), false);
  }

  onNewVideo(blob: Blob): void {
    const { onEquipmentValidated } = this.props;

    onEquipmentValidated(true);

    this.setState({
      currentVideo: blob
    });
  }

  setVideoRecording(value: boolean): void {
    this.setState({
      isVideoRecording: value
    });
  }

  setRecordingDuration(value: number): void {
    this.setState({
      recordingDuration: value
    });
  }

  updateRecordingDuration(): void {
    const { recordingDuration } = this.state;

    this.setState({
      recordingDuration: recordingDuration + 1
    });
  }

  onPress(webcamRef: React.RefObject<Webcam>): void {
    const { onEquipmentValidated } = this.props;
    const { currentMode, currentPhoto, isVideoRecording } = this.state;

    if (currentMode === ComponentMode.photo) {
      if (currentPhoto) {
        this.setState({
          currentPhoto: undefined
        });
      } else {
        const screenshot = webcamRef.current?.getScreenshot();
        this.setState({
          currentPhoto: screenshot
        });
        onEquipmentValidated(true);
      }
    }
    if (currentMode === ComponentMode.video) {
      if (!isVideoRecording) {
        this.startWebcamRecording();
        this.setState({
          currentVideo: undefined
        });
      } else {
        this.stopWebcamRecording();
      }
    }
  }

  getLabel(): string {
    const {
      currentMode,
      currentPhoto,
      currentVideo,
      isVideoRecording,
      recordingDuration
    } = this.state;

    if (currentMode === ComponentMode.photo) {
      if (currentPhoto) {
        return i18n.t("testing.cameraTesting.retakePicture");
      }
      return i18n.t("testing.cameraTesting.takePicture");
    }
    if (currentVideo) {
      return i18n.t("testing.cameraTesting.restartRecording");
    }
    if (isVideoRecording) {
      return `${i18n.t(
        "testing.cameraTesting.stopRecording"
      )}  - ${this.getVideoDurationLabel(recordingDuration)}`;
    }
    return i18n.t("testing.cameraTesting.startRecording");
  }

  render(): JSX.Element | null {
    const { hasPermission } = this.props;
    const { currentMode, currentPhoto, currentVideo } = this.state;
    const webcamRef = React.createRef<Webcam>();

    return (
      <View>
        {hasPermission ? (
          <View style={[styles.container]}>
            {currentMode === ComponentMode.photo ? (
              <PhotoTaking currentPhoto={currentPhoto} webcamRef={webcamRef} />
            ) : (
              <VideoTaking currentVideo={currentVideo} webcamRef={webcamRef} />
            )}
            <SwitchSelector
              initial={0}
              options={[
                { value: "photo", imageIcon: outlinePhoto },
                { value: "video", imageIcon: outlineVideo }
              ]}
              onPress={(value: string) =>
                this.setState({
                  currentMode:
                    value === "photo"
                      ? ComponentMode.photo
                      : ComponentMode.video
                })
              }
              selectedTextContainerStyle={{
                shadowColor: COLOR_BLACK,
                shadowOffset: { width: 0, height: 20 },
                shadowOpacity: 0.1,
                shadowRadius: 10
              }}
              buttonColor={COLOR_WHITE}
              backgroundColor={COLOR_GREY_PLACEHOLDER}
              selectedColor={COLOR_BLUE_TESTWE}
              textColor={COLOR_BLUE_TESTWE_50}
            />
            <Button
              label={this.getLabel()}
              textStyle={{
                color: COLOR_BLUE_TESTWE,
                fontFamily: FONT_GILROY_REGULAR
              }}
              containerStyle={[styles.reloadButtonStyle]}
              onPress={() => this.onPress(webcamRef)}
            />
          </View>
        ) : (
          <View>
            <Text>{i18n.t("testing.cameraTesting.cameraNotAvailable")}</Text>
          </View>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    width: VIDEO_CONSTRAINTS.width
  },
  reloadButtonStyle: {
    borderStyle: "solid",
    borderColor: COLOR_BLUE_TESTWE,
    borderWidth: 1,
    borderRadius: 50,
    backgroundColor: COLOR_WHITE,
    marginTop: 15
  }
});

const mapStateToProps = () => {
  return {};
};

const mapdispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators({ getError }, dispatch)
  };
};

const CameraTesting = connect(
  mapStateToProps,
  mapdispatchToProps
)(CameraTestingView);

export default CameraTesting;
