import React from "react";
import { StyleProp, StyleSheet, Text, TextStyle } from "react-native";
import { COLOR_BLUE } from "../../../static/misc/colors";
import {
  FONTSIZE_14,
  FONT_GILROY_REGULAR,
  FONT_GILROY_BOLD
} from "../../../static/misc/constants";

interface NextExamDateProps {
  textStyle?: StyleProp<TextStyle>;
  date: string;
  time: string;
  labelNextExam: string;
  labelAt: string;
}

class NextExamDate extends React.PureComponent<NextExamDateProps> {
  render(): JSX.Element {
    const { textStyle, date, time, labelNextExam, labelAt } = this.props;
    return (
      <Text style={[styles.defaultRegularTxt, textStyle]}>
        {`${labelNextExam} `}
        <Text style={{ fontFamily: FONT_GILROY_BOLD }}>{date}</Text>
        {` ${labelAt} `}
        <Text style={{ fontFamily: FONT_GILROY_BOLD }}>{time}</Text>
      </Text>
    );
  }
}

const styles = StyleSheet.create({
  defaultRegularTxt: {
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_14,
    color: COLOR_BLUE
  }
});

export default NextExamDate;
