import React from "react";
import { View, StyleSheet } from "react-native";
import ExtraBoldTitle from "../atoms/ExtraBoldTitle";
import SeeAllTag from "./SeeAllTag";

interface TitleAndTagsExamProps {
  title: string;
  rightText: string;
  onPress: () => void;
}

class TitleAndTagsExam extends React.PureComponent<TitleAndTagsExamProps> {
  render(): JSX.Element {
    const { title, rightText, onPress } = this.props;
    return (
      <View style={styles.headerWrapper}>
        <ExtraBoldTitle text={title} />
        <SeeAllTag label={rightText} onPress={onPress} />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  headerWrapper: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: 5
  }
});

export default TitleAndTagsExam;
