import React from "react";
import { StyleProp, View, ViewStyle, StyleSheet } from "react-native";
import ReactPlayer from "react-player";
import RecordExam from "./RecordExam";

export interface AudioExamProps {
  maxRecordingTime?: number;
  reload?: boolean;
  disabled?: boolean;
  startRecordTime?: number;
  shouldStopRecording?: boolean;
  recordContainerStyle?: StyleProp<ViewStyle>[];
  containerStyle?: StyleProp<ViewStyle>[];
  recordEnd?: () => void;
  recordEnded?: () => void;
  recordStarted?: () => void;
  getBlob?: (b64blob: string | Blob) => void;
  toggleAudioProctoring: () => void;
}

export interface AudioExamState {
  audioRecord: string;
}

class AudioExam extends React.PureComponent<AudioExamProps, AudioExamState> {
  constructor(props: AudioExamProps) {
    super(props);
    this.state = {
      audioRecord: ""
    };
  }

  render(): JSX.Element {
    const {
      reload,
      maxRecordingTime,
      startRecordTime,
      shouldStopRecording,
      recordContainerStyle,
      disabled,
      containerStyle,
      getBlob,
      recordEnd,
      recordEnded,
      recordStarted,
      toggleAudioProctoring
    } = this.props;
    const { audioRecord } = this.state;
    return (
      <View style={containerStyle}>
        <RecordExam
          disabled={disabled}
          containerStyle={[styles.record, recordContainerStyle]}
          reload={reload}
          recordEnd={recordEnd}
          recordStarted={recordStarted}
          maxRecordingTime={maxRecordingTime}
          startRecordTime={startRecordTime}
          shouldStopRecording={shouldStopRecording}
          toggleAudioProctoring={toggleAudioProctoring}
          getAudioRecord={(record) => {
            if (recordEnded) recordEnded();
            this.setState({
              audioRecord: window.URL.createObjectURL(record)
            });
          }}
          getBlob={getBlob}
        />
        {audioRecord !== "" && (
          <ReactPlayer
            url={audioRecord}
            width="100%"
            height={40}
            controls
            config={{
              file: { attributes: { controlsList: "nodownload" } }
            }}
          />
        )}
      </View>
    );
  }
}
const styles = StyleSheet.create({
  record: {
    marginBottom: 10,
    width: "100%"
  }
});
export default AudioExam;
