import { CalculatorButtonTypeEnum } from "../../src/modules/examTaking/types/calculator";

class CalcUtils {
  pi(): number {
    return Math.PI;
  }

  e(): number {
    return Math.E;
  }

  static compute(val: string): number {
    // eslint-disable-next-line no-eval
    return eval(val);
  }

  static checkCharType(char: string): CalculatorButtonTypeEnum {
    if (char.length > 1) {
      return CalculatorButtonTypeEnum.FUNCTION;
    }
    if (char >= "0" && char <= "9") {
      return CalculatorButtonTypeEnum.NUMBER;
    }
    if (
      char === "/" ||
      char === "*" ||
      char === "+" ||
      char === "-" ||
      char === "%" ||
      char === "^"
    ) {
      return CalculatorButtonTypeEnum.OPERATOR;
    }
    if (char === "(") {
      return CalculatorButtonTypeEnum.OPENINGBRACKET;
    }
    if (char === ")") {
      return CalculatorButtonTypeEnum.CLOSINGBRACKET;
    }
    if (char === ".") {
      return CalculatorButtonTypeEnum.DOT;
    }
    if (char === "+/-") {
      return CalculatorButtonTypeEnum.SIGNCHANGE;
    }
    if (char === "=") {
      return CalculatorButtonTypeEnum.EQUAL;
    }
    return CalculatorButtonTypeEnum.OTHER;
  }
}

export default CalcUtils;
