import React from "react";
import { View, StyleSheet } from "react-native";
import { COLOR_TRANSPARENT } from "../../../static/misc/colors";
import { ExamType } from "../../modules/exams/types/exam";
import ExamTakingAllRules from "./ExamTakingAllRules";

interface RulesViewProps {
  currentExam?: ExamType;
  onClose: () => void;
}

class RulesView extends React.PureComponent<RulesViewProps> {
  render(): JSX.Element {
    const { currentExam, onClose } = this.props;
    return (
      <View style={styles.viewContainer}>
        <View style={styles.viewRulesContainer}>
          {currentExam && (
            <ExamTakingAllRules
              examParams={currentExam.examParams}
              hasTimer={currentExam.hasTimer}
              onClose={() => onClose()}
            />
          )}
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  viewRulesContainer: {
    boxShadow: "0 0 2em grey",
    borderRadius: 10,
    width: "700px"
  },
  viewContainer: {
    position: "absolute",
    top: 2,
    left: 85,
    width: 2,
    height: 2,
    backgroundColor: COLOR_TRANSPARENT,
    zIndex: 999
  }
});

export default RulesView;
