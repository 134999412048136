import { StudentPaperType } from "../examTaking/types/studentPaper";
import { UserState } from "../main/types/user";
import { ExamType } from "./types/exam";

/**
 * Check if the current user has a student paper for the given exam
 * @param examId the current exam id
 * @param currentUserId  the current user id
 * @param studentPapers the student papers
 * @returns boolean
 */
export function hasStudentPaper(
  examId: ExamType["id"],
  currentUserId: UserState["id"],
  studentPapers: StudentPaperType[]
): boolean {
  const currentStudentPaper = studentPapers.find(
    (sp) =>
      sp.examId === examId &&
      sp.userId !== (undefined || null) &&
      sp.userId === currentUserId
  );

  return !!currentStudentPaper;
}

export default {
  hasStudentPaper
};
