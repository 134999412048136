import { ClearCredentialsActions } from "../../../store/rootreducer";
import { UploadMediaObjectAction } from "../../exams/types/attachedfiles";
import { StudentPaperSubmissionAction } from "../../main/types/status";
import { UpdateSpreadsheetAction } from "./examTaking";

export const CLEAR_STUDENT_PAPERS = "CLEAR_STUDENT_PAPERS";
export const CLEAR_STUDENT_PAPER = "CLEAR_STUDENT_PAPER";
export const CREATE_STUDENT_PAPER = "CREATE_STUDENT_PAPER";

export const EDIT_STUDENT_PAPER = "EDIT_STUDENT_PAPER";

export const FILL_STUDENT_PAPER = "FILL_STUDENT_PAPER";

export const POST_STUDENT_PAPER = "POST_STUDENT_PAPER";
export const POST_STUDENT_PAPER_SUCCESS = "POST_STUDENT_PAPER_SUCCESS";
export const POST_STUDENT_PAPER_FAIL = "POST_STUDENT_PAPER_FAIL";

export const UPDATE_STUDENT_PAPER = "UPDATE_STUDENT_PAPER";
export const UPDATE_STUDENT_PAPER_SUCCESS = "UPDATE_STUDENT_PAPER_SUCCESS";
export const UPDATE_STUDENT_PAPER_FAIL = "UPDATE_STUDENT_PAPER_FAIL";

export const EXPORT_STUDENT_PAPER = "EXPORT_STUDENT_PAPER";
export const EXPORT_STUDENT_PAPER_SUCCESS = "EXPORT_STUDENT_PAPER_SUCCESS";
export const EXPORT_STUDENT_PAPER_FAIL = "EXPORT_STUDENT_PAPER_FAIL";

export const EXPORT_STUDENT_PAPER_TO_ZIP = "EXPORT_STUDENT_PAPER_TO_ZIP";
export const EXPORT_STUDENT_PAPER_TO_ZIP_SUCCESS =
  "EXPORT_STUDENT_PAPER_TO_ZIP_SUCCESS";
export const EXPORT_STUDENT_PAPER_TO_ZIP_FAIL =
  "EXPORT_STUDENT_PAPER_TO_ZIP_FAIL";

export interface ExportStudentPaperAction {
  type:
    | typeof EXPORT_STUDENT_PAPER
    | typeof EXPORT_STUDENT_PAPER_SUCCESS
    | typeof EXPORT_STUDENT_PAPER_FAIL
    | typeof EXPORT_STUDENT_PAPER_TO_ZIP
    | typeof EXPORT_STUDENT_PAPER_TO_ZIP_SUCCESS
    | typeof EXPORT_STUDENT_PAPER_TO_ZIP_FAIL;
  payload: any;
  meta?: any;
}

export interface FillStudentPaperAction {
  type: typeof FILL_STUDENT_PAPER;
  payload: any;
}

export interface EditStudentPaperAction {
  type: typeof EDIT_STUDENT_PAPER;
  payload: any;
}

export interface CreateStudentPaperAction {
  type: typeof CREATE_STUDENT_PAPER;
  payload: any;
}

export interface ClearStudentPapersAction {
  type: typeof CLEAR_STUDENT_PAPERS;
}

export interface ClearStudentPaperAction {
  type: typeof CLEAR_STUDENT_PAPER;
  payload: any;
}
export interface PostStudentPaperAction {
  meta?: any;
  type:
    | typeof POST_STUDENT_PAPER
    | typeof POST_STUDENT_PAPER_SUCCESS
    | typeof POST_STUDENT_PAPER_FAIL;
  payload: any;
}

export interface UpdateStudentPaperAction {
  meta: any;
  type:
    | typeof UPDATE_STUDENT_PAPER
    | typeof UPDATE_STUDENT_PAPER_FAIL
    | typeof UPDATE_STUDENT_PAPER_SUCCESS;
  payload: any;
}

export interface MyStudentPaperType {
  id?: string;
  base64?: string;
  blobUrl?: string;
  proctorArchiveIssue?: string;
  status?: string;
  startDate?: string;
  endDate?: string;
  totalPoints?: number;
  appreciation?: string;
}

export interface StudentAnswerMediaDataType {
  filename: string;
  data: string;
}

export type StudentAnswerStatus =
  | "init"
  | "visited"
  | "bookmarked"
  | "completed";
export interface StudentAnswerType {
  question: string;
  questionType: string;
  givenAnswer?: string;
  givenChoices?: string[];
  mediaObjects?: string[];
  mediaData?: StudentAnswerMediaDataType[];
  noChoice?: boolean;
  status: StudentAnswerStatus;
}

export interface StudentPaperType {
  id?: string;
  base64?: string;
  examId: string;
  exam: string;
  startDate: moment.Moment | string;
  endDate: moment.Moment | string | null;
  studentAnswers: StudentAnswerType[] | string;
  exported?: boolean;
  zipped?: boolean;
  userId: string;
  studentPaperResumings: Array<any>;
}

export interface StudentPaperState {
  studentPapers: StudentPaperType[];
}

export type StudentPaperActionsType =
  | ExportStudentPaperAction
  | ClearStudentPapersAction
  | ClearStudentPaperAction
  | ClearCredentialsActions
  | FillStudentPaperAction
  | EditStudentPaperAction
  | CreateStudentPaperAction
  | PostStudentPaperAction
  | UpdateStudentPaperAction
  | UploadMediaObjectAction
  | StudentPaperSubmissionAction
  | UpdateSpreadsheetAction;
