import React from "react";
import {
  StyleProp,
  StyleSheet,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle
} from "react-native";
import { COLOR_BLUE_TESTWE } from "../../../static/misc/colors";
import {
  FONTSIZE_14,
  FONT_GILROY_REGULAR,
  PADDING_SIDES
} from "../../../static/misc/constants";
import LightText from "../atoms/LightText";
import RadioButton from "../atoms/RadioButton";

interface QcuItemProps {
  isSelected?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  answerStyle?: StyleProp<TextStyle>;
  onSelect: () => void;
  answer?: string;
}

class QcuItem extends React.PureComponent<QcuItemProps> {
  static defaultProps = {
    isSelected: false
  };

  render(): JSX.Element {
    const {
      isSelected,
      onSelect,
      answer,
      containerStyle,
      answerStyle
    } = this.props;
    return (
      <TouchableOpacity
        onPress={() => onSelect()}
        style={[styles.defaultContainer, containerStyle]}
      >
        <RadioButton isSelected={isSelected} />
        <View style={{ paddingHorizontal: PADDING_SIDES * 0.1 }} />
        {answer && (
          <LightText
            text={answer}
            textStyle={[styles.defaultAnswerStyle, answerStyle]}
          />
        )}
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  defaultContainer: {
    flexDirection: "row"
  },
  defaultAnswerStyle: {
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_14,
    color: COLOR_BLUE_TESTWE
  }
});

export default QcuItem;
