import React from "react";
import { StyleProp, StyleSheet, ViewStyle } from "react-native";
import Button from "./Button";
import i18n from "../../services/i18n";
import { FONT_GILROY_REGULAR } from "../../../static/misc/constants";
import { COLOR_BLUE_TESTWE, COLOR_WHITE } from "../../../static/misc/colors";

interface BackButtonProps {
  label?: string;
  containerStyle?: StyleProp<ViewStyle>;
  disabled: boolean;
  navigation: () => void;
}

class BackButton extends React.PureComponent<BackButtonProps> {
  render(): JSX.Element {
    const { navigation, containerStyle, label, disabled } = this.props;
    return (
      <Button
        disabled={disabled}
        label={label || i18n.t("exam.back")}
        containerStyle={[
          styles.backButtonContainer,
          containerStyle,
          disabled && styles.disabledButton
        ]}
        textStyle={styles.backButtonText}
        onPress={() => navigation()}
      />
    );
  }
}

const styles = StyleSheet.create({
  backButtonContainer: {
    height: "28px",
    borderStyle: "solid",
    borderColor: COLOR_BLUE_TESTWE,
    borderWidth: 1,
    borderRadius: 50,
    backgroundColor: COLOR_WHITE
  },
  disabledButton: {
    opacity: 0.5
  },
  backButtonText: {
    color: COLOR_BLUE_TESTWE,
    fontFamily: FONT_GILROY_REGULAR
  }
});

export default BackButton;
