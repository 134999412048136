import {
  DOWNLOAD_STUDENT_PAPER,
  EXAMS,
  EXAMS_STUDENT,
  EXAMS_SYNC,
  PAST_EXAMS_STUDENT,
  SYNCRO_EXAMS
} from "../../../../static/misc/network";
import { DisplayedExamsEnum, DisplayedExamsOrderEnum } from "../ExamsView";
import { ExamType } from "../types/exam";
import {
  ChangeOrderDisplayedExamsAction,
  CHANGE_DISPLAYED_EXAMS_ORDER,
  ChangingExamMyStudPaperStatusAction,
  CHANGING_EXAM_MYSTUDPAPER_STATUS,
  DISPLAY_NEXT_EXAMS,
  DISPLAY_PASSED_EXAMS,
  SortExamsAction,
  GetExamsHashAction,
  GetSyncExamAction,
  GET_EXAMS_HASH,
  GET_SYNC_PAST_EXAM,
  GET_SYNC_EXAM,
  PostSyncronizeAction,
  POST_SYNCRO,
  SORT_EXAMS,
  UpdateDisplayedExamsAction,
  UPDATE_SELECTED_ACADEMIC_YEAR,
  SetNextExamAction,
  SET_NEXT_EXAM,
  REMOVE_EXAMS,
  RemoveExamsAction,
  GET_MY_PAPER_B64,
  GetMyPaperBase64Action,
  CLEAR_EXAMS,
  GET_EXAM_FOR_PREVIEW,
  UpdateProctorArchiveExamStatusAction,
  UPDATE_PROCTOR_ARCHIVE_EXAM_STATUS,
  GetSyncExamActionApiPayload,
  GetExamHashAction,
  GET_EXAM_HASH
} from "../types/exams";

export function postSyncExams(
  userToken: string,
  examsId: string[],
  // eslint-disable-next-line camelcase
  desktop_version: string
): PostSyncronizeAction {
  return {
    type: POST_SYNCRO,
    payload: {
      client: "default",
      request: {
        headers: {
          accept: "application/json",
          authorization: `Bearer ${userToken}`
        },
        method: "post",
        url: SYNCRO_EXAMS,
        data: {
          exam_ids: examsId,
          desktop_version
        }
      }
    }
  };
}

export function getExamsHash(
  userToken: string,
  userVersion: string,
  userOs: string,
  client: string
): GetExamsHashAction {
  return {
    type: GET_EXAMS_HASH,
    payload: {
      client: "default",
      request: {
        headers: {
          accept: "application/json",
          authorization: `Bearer ${userToken}`
        },
        url: `${EXAMS_SYNC}?desktopVersion=${userVersion}&userOs=${userOs}&clientType=${client}`,
        method: "get"
      }
    }
  };
}

export function updateProctorArchiveExamStatus(
  examList: string[],
  incomplete = true
): UpdateProctorArchiveExamStatusAction {
  return {
    type: UPDATE_PROCTOR_ARCHIVE_EXAM_STATUS,
    payload: {
      examList,
      incomplete
    }
  };
}

export function changingExamMyStudPaperStatus(
  examId: string,
  status: string
): ChangingExamMyStudPaperStatusAction {
  return {
    type: CHANGING_EXAM_MYSTUDPAPER_STATUS,
    payload: {
      examId,
      status
    }
  };
}

export function removeExams(exams: string[]): RemoveExamsAction {
  return {
    type: REMOVE_EXAMS,
    payload: { exams }
  };
}

export function getSyncExam(
  userToken: string,
  examId: string
): GetSyncExamAction<GetSyncExamActionApiPayload> {
  return {
    type: GET_SYNC_EXAM,
    payload: {
      examId,
      client: "default",
      request: {
        headers: {
          accept: "application/json",
          authorization: `Bearer ${userToken}`
        },
        url: `${EXAMS_STUDENT}/${examId}`
      },
      method: "get"
    }
  };
}

export function getSyncPastExam(
  userToken: string,
  examId: string
): GetSyncExamAction<GetSyncExamActionApiPayload> {
  return {
    type: GET_SYNC_PAST_EXAM,
    payload: {
      examId,
      client: "default",
      request: {
        headers: {
          accept: "application/json",
          authorization: `Bearer ${userToken}`
        },
        url: `${PAST_EXAMS_STUDENT}/${examId}`
      },
      method: "get"
    }
  };
}

export function getPreviewExam(
  examId: string,
  userToken: string
): GetSyncExamAction<GetSyncExamActionApiPayload> {
  return {
    type: GET_EXAM_FOR_PREVIEW,
    payload: {
      examId,
      client: "default",
      request: {
        headers: {
          accept: "application/json",
          authorization: `Bearer ${userToken}`
        },
        url: `${EXAMS}/${examId}`
      },
      method: "get"
    }
  };
}

export function sortExam(): SortExamsAction {
  return {
    type: SORT_EXAMS
  };
}

export function updateDisplayedExams(
  examType: DisplayedExamsEnum
): UpdateDisplayedExamsAction {
  const type =
    examType === DisplayedExamsEnum.NEXT_EXAMS
      ? DISPLAY_NEXT_EXAMS
      : DISPLAY_PASSED_EXAMS;
  return {
    type,
    payload: examType
  };
}

export function updateSelectedAcademicYear(
  academicYear?: string
): UpdateDisplayedExamsAction {
  return {
    type: UPDATE_SELECTED_ACADEMIC_YEAR,
    payload: { academicYear }
  };
}

export function changeExamsOrder(
  examType: DisplayedExamsEnum,
  order: DisplayedExamsOrderEnum,
  category: string
): ChangeOrderDisplayedExamsAction {
  return {
    type: CHANGE_DISPLAYED_EXAMS_ORDER,
    payload: {
      examType,
      order,
      category
    }
  };
}

export function setNextExam(exam: ExamType): SetNextExamAction {
  return {
    type: SET_NEXT_EXAM,
    payload: exam
  };
}

export function getMyPaperBase64(
  studentPaperId: string,
  userToken: string,
  examId: string
): GetMyPaperBase64Action {
  return {
    type: GET_MY_PAPER_B64,
    meta: {
      studentPaperId,
      examId
    },
    payload: {
      studentPaperId,
      examId,
      client: "default",
      request: {
        headers: {
          accept: "application/json",
          authorization: `Bearer ${userToken}`
        },
        url: `${DOWNLOAD_STUDENT_PAPER(studentPaperId)}?toBase64=true`,
        method: "get"
      }
    }
  };
}

export function clearExams(): RemoveExamsAction {
  return {
    type: CLEAR_EXAMS,
    payload: {}
  };
}

export function getExamHash(
  userToken: string,
  examId: string
): GetExamHashAction {
  return {
    type: GET_EXAM_HASH,
    payload: {
      examId,
      client: "default",
      request: {
        headers: {
          accept: "application/json",
          authorization: `Bearer ${userToken}`
        },
        url: `${EXAMS_SYNC}/${examId}`,
        method: "get"
      }
    }
  };
}
