import React from "react";
import { StyleSheet, View, Text } from "react-native";
import { desktopCapturer, ipcRenderer } from "electron";
import i18n from "../../services/i18n";
import {
  EACH_SECONDS,
  FONTSIZE_14,
  FONT_GILROY_REGULAR,
  IS_WEB_ENABLE,
  PADDING_SIDES
} from "../../../static/misc/constants";
import { COLOR_BLUE_TESTWE, COLOR_RED } from "../../../static/misc/colors";
import { logoutIcon, reloadIcon } from "../../../static/misc/images";
import ReloadView from "../atoms/ReloadView";
import Button from "../molecules/Button";

interface OnboardingScreenCaptureStepState {
  hasPermission: boolean;
}

class OnboardingMicroStep extends React.PureComponent<
  unknown,
  OnboardingScreenCaptureStepState
> {
  interval!: ReturnType<typeof setInterval>;

  permissionsValidated = false;

  mounted = false;

  constructor(props: unknown) {
    super(props);
    this.state = {
      hasPermission: false
    };
  }

  componentDidMount(): void {
    this.mounted = true;

    if (!IS_WEB_ENABLE) {
      desktopCapturer.getSources({ types: ["window", "screen"] });
    }

    this.interval = setInterval(async () => {
      if (!this.permissionsValidated) {
        if (this.mounted) {
          const result = await this.ipcRendererOn();
          if (result.screen) {
            this.permissionsValidated = true;
            clearInterval(this.interval);
          }
          this.setState({
            hasPermission: result.screen
          });
        }
      }
    }, EACH_SECONDS);
  }

  componentWillUnmount(): void {
    this.mounted = false;
    clearInterval(this.interval);
  }

  ipcRendererOn(): Promise<any> {
    return new Promise((resolve) => {
      if (IS_WEB_ENABLE) {
        const result = { microphone: true, camera: true, screen: true };
        navigator.mediaDevices.getUserMedia({ audio: true, video: true });
        resolve(result);
      } else {
        ipcRenderer.once("PERMISSIONS_ASKING_RESPOND", (event, arg) => {
          resolve(arg);
        });
        ipcRenderer.send("PERMISSIONS_ASKING", {
          screen: true
        });
      }
    });
  }

  render(): JSX.Element {
    const { hasPermission } = this.state;
    return (
      <View style={styles.container}>
        {!hasPermission && (
          <>
            <Text style={styles.text}>
              {i18n.t("exam.permissions.description")}
            </Text>
            <ReloadView
              runAnimation
              icon={reloadIcon}
              iconStyle={{ width: 16, height: 16 }}
              containerStyle={{
                width: 30,
                height: 30,
                borderRadius: 15,
                backgroundColor: COLOR_RED
              }}
            />
          </>
        )}
        {hasPermission && (
          <>
            <Text style={styles.text}>
              {i18n.t("exam.permissions.validated")}
            </Text>
            <Button
              label={i18n.t("userMenu.leave")}
              icon={logoutIcon}
              onPress={() => {
                ipcRenderer.send("EXIT_APP");
              }}
            />
          </>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexShrink: 1,
    flexGrow: 1,
    alignItems: "center"
  },
  text: {
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_14,
    color: COLOR_BLUE_TESTWE,
    marginBottom: PADDING_SIDES * 0.4,
    lineHeight: 20
  }
});
export default OnboardingMicroStep;
