import React from "react";
import {
  StyleSheet,
  View,
  Image,
  Animated,
  StyleProp,
  ViewStyle,
  Easing,
  Text,
  TouchableOpacity,
  TextStyle
} from "react-native";
import {
  COLOR_BLUE_TESTWE,
  COLOR_SHADOW,
  COLOR_WHITE,
  COLOR_YELLOW_INFO
} from "../../../static/misc/colors";
import { FONT_GILROY_BOLD } from "../../../static/misc/constants";
import { alertIcon, crossRedIcon } from "../../../static/misc/images";
import Icon from "./Icon";
import Tooltip from "./Tooltip";

interface YellowBannerProps {
  textBanner: string;
  containerStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  withTooltip?: boolean;
  withCloseBtn?: boolean;
  tooltipText?: string;
  onCloseBtn?: () => void;
}

class YellowBanner extends React.PureComponent<YellowBannerProps> {
  animatedValue: Animated.Value;

  constructor(props: YellowBannerProps) {
    super(props);
    this.animatedValue = new Animated.Value(0);
  }

  componentDidMount(): void {
    this.animate();
  }

  componentDidUpdate(prevProps: YellowBannerProps): void {
    const { textBanner } = this.props;
    if (prevProps.textBanner !== textBanner) {
      this.animatedValue.stopAnimation(() => this.animate());
    }
  }

  animate(): void {
    this.animatedValue.setValue(0);
    Animated.timing(this.animatedValue, {
      toValue: 1,
      duration: 500,

      easing: Easing.linear,
      useNativeDriver: true
    }).start();
  }

  render(): JSX.Element {
    const {
      textBanner,
      containerStyle,
      withTooltip,
      tooltipText,
      withCloseBtn,
      onCloseBtn,
      textStyle
    } = this.props;
    return (
      <View
        style={[
          styles.defaultContainer,
          {
            justifyContent: withCloseBtn ? "space-between" : "center"
          },
          containerStyle
        ]}
      >
        {withCloseBtn && <View />}
        {withTooltip && tooltipText ? (
          <Tooltip
            contentText={tooltipText}
            tooltipViewStyle={{
              backgroundColor: COLOR_WHITE,
              shadowColor: COLOR_SHADOW,
              shadowOffset: { width: -2, height: 4 },
              shadowOpacity: 1,
              shadowRadius: 3
            }}
          >
            <Animated.View
              style={[
                {
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  opacity: this.animatedValue
                },
                containerStyle
              ]}
            >
              <Image source={alertIcon} style={styles.defaultAlertIcon} />
              <Text style={styles.defaultTextStyle}>{textBanner}</Text>
            </Animated.View>
          </Tooltip>
        ) : (
          <Animated.View
            style={[
              {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                opacity: this.animatedValue
              },
              containerStyle
            ]}
          >
            <Image source={alertIcon} style={styles.defaultAlertIcon} />
            <Text style={[styles.defaultTextStyle, textStyle]}>
              {textBanner}
            </Text>
          </Animated.View>
        )}

        {withCloseBtn && onCloseBtn && (
          <TouchableOpacity onPress={onCloseBtn}>
            <Icon icon={crossRedIcon} iconStyle={{ width: 16, height: 16 }} />
          </TouchableOpacity>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  defaultContainer: {
    backgroundColor: COLOR_YELLOW_INFO,
    borderRadius: 3,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 15,
    paddingHorizontal: 15
  },
  defaultAlertIcon: {
    width: 16,
    height: 16,
    marginRight: 5
  },
  defaultTextStyle: {
    color: COLOR_BLUE_TESTWE,
    fontFamily: FONT_GILROY_BOLD,
    fontStyle: "italic"
  }
});

export default YellowBanner;
