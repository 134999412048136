import React from "react";
import CustomModal from "../atoms/CustomModal";
import ListCheckButton from "./LangListButtons";

export interface LanguageModalProps {
  isVisible: boolean;
  setLocale: (locale: string) => void;
  onShowLangModal: (bool: boolean) => void;
}

class LanguageModal extends React.PureComponent<LanguageModalProps> {
  render(): JSX.Element {
    const { isVisible, onShowLangModal, setLocale } = this.props;

    return (
      <CustomModal
        heightModal="50%"
        widthModal="50%"
        borderRadiusModal={5}
        visible={isVisible}
        showCloseButton
        onCloseModal={() => onShowLangModal(false)}
      >
        <ListCheckButton
          setLocale={setLocale}
          closeModal={() => onShowLangModal(false)}
        />
      </CustomModal>
    );
  }
}

export default LanguageModal;
