import React from "react";
import {
  StyleSheet,
  StyleProp,
  Image,
  ImageStyle,
  ImageSourcePropType
} from "react-native";

interface IconProps {
  iconStyle?: StyleProp<ImageStyle>;
  icon: ImageSourcePropType;
}

class Icon extends React.PureComponent<IconProps> {
  render(): JSX.Element {
    const { iconStyle, icon } = this.props;
    return <Image source={icon} style={[styles.defaultImage, iconStyle]} />;
  }
}

const styles = StyleSheet.create({
  defaultImage: {
    height: 10,
    width: 10,
    resizeMode: "contain",
    alignSelf: "center"
  }
});

export default Icon;
