import React from "react";

import { NavigationContainer } from "@react-navigation/native";
import { useDispatch } from "react-redux";

import StackNavigator from "./StackNavigator";
import { CredentialsType } from "../modules/authentification/types/auth";
import { connectUser } from "../modules/authentification/actions/auth";

interface AppNavigatorProps {
  credentials: CredentialsType | undefined;
}

function AppNavigator({ credentials }: AppNavigatorProps): JSX.Element {
  const dispatch = useDispatch();
  // If appnavigator has credentials and it is not undefined, then connect the user
  if (credentials) dispatch(connectUser(credentials));
  return (
    <NavigationContainer>
      <StackNavigator />
    </NavigationContainer>
  );
}

export default AppNavigator;
