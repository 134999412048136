import React from "react";
import { Image, StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { infoIcon } from "../../../static/misc/images";

interface InfoIconProps {
  containerStyle?: StyleProp<ViewStyle>;
}

class InfoIcon extends React.PureComponent<InfoIconProps> {
  render(): JSX.Element {
    const { containerStyle } = this.props;
    return (
      <View style={containerStyle}>
        <Image source={infoIcon} style={styles.image} />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  image: {
    height: 16,
    width: 16,
    resizeMode: "contain",
    alignSelf: "center"
  }
});

export default InfoIcon;
