/* eslint-disable camelcase */
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { clearCredentials } from "../authentification/actions/auth";
import { RootState } from "../../store/rootreducer";
import {
  updateDisplayedExams,
  sortExam,
  getMyPaperBase64
} from "../exams/actions/exams";
import {
  putUserCguAcceptance,
  setEstablishment,
  setLocale
} from "./actions/user";
import { createExamTaking } from "../examTaking/actions/examTaking";
import { localApiError } from "./actions/error";
import MainView from "./MainView";
import { IS_PREVIEW } from "../../../static/misc/constants";
import { verifyTimeWithServer } from "./actions/status";

const mapStateToProps = (state: RootState) => {
  return {
    token: state.auth.credentials?.access_token,
    gettingUserInfo: state.status.gettingUserInfo,
    studentID: state.user.id,
    hasTakenTraining: state.user.hasTakenTraining,
    studentPapers: state.studentPaper.studentPapers,
    currentExamTaking: state.examTaking.exams,
    givenName: state.auth.credentials?.given_name,
    familyName: state.auth.credentials?.family_name,
    isOnline: state.status.isOnline,
    locale: state.user.locale,
    nextExam: state.exams.nextExam,
    nextExams: state.exams.nextExams,
    passedExams: state.exams.passedExams,
    attachedFiles: state.attachedfiles.mediaObjects,
    displayedExams: state.exams.displayedExams,
    cguAcceptance: state.user.cguAcceptance,
    showLoader:
      state.status.isOnline === true ? state.status.showLoader : false,
    isSynchronizing: !!(
      IS_PREVIEW ||
      state.status.syncExams ||
      state.status.nbExamsToSync > 0
    ),
    timeVerificationStatus: state.status.timeVerificationStatus,
    timeVerificationCheck: state.status.timeVerificationCheck,
    currentEstablishment: state.user.currentEstablishment,
    establishments: state.user.establishments
  };
};

const mapdispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators(
      {
        setLocale,
        clearCredentials,
        putUserCguAcceptance,
        createExamTaking,
        updateDisplayedExams,
        sortExam,
        localApiError,
        getMyPaperBase64,
        verifyTimeWithServer,
        setEstablishment
      },
      dispatch
    )
  };
};

const MainContainer = connect(mapStateToProps, mapdispatchToProps)(MainView);

export default MainContainer;
