import React from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { ipcRenderer } from "electron";
import HeaderItem from "../atoms/HeaderItem";
import { guidGenerator } from "../../../static/misc/utils";
import { IS_PREVIEW, PADDING_SIDES } from "../../../static/misc/constants";
import { StackNavigatorProp } from "../../router/StackNavigator";
import { TRAINING } from "../../../static/misc/menu";
import { getTrainingExam } from "../../../static/misc/mockexam";
import { ExamType } from "../../modules/exams/types/exam";
import { CreateExamTakingAction } from "../../modules/examTaking/types/examTaking";

export interface HeaderNavigationItem {
  id: string;
  show: boolean;
  label: string;
}

export interface HeaderNavigationProps {
  examTaking: ExamType[] | undefined;
  containerStyle?: StyleProp<ViewStyle>;
  launchExamPreview: boolean;
  currentRouteName?: string;
  navigation: StackNavigatorProp;
  items: HeaderNavigationItem[];
  disabled: boolean;
  createExamTaking: (exam: ExamType) => CreateExamTakingAction;
}

export interface ExamPreviewQueryString {
  state: string | undefined;
}

class HeaderNavigation extends React.Component<HeaderNavigationProps> {
  async componentDidUpdate(prevProps: HeaderNavigationProps): Promise<void> {
    const { launchExamPreview } = this.props;

    if (IS_PREVIEW && !prevProps.launchExamPreview && launchExamPreview) {
      this.startExamPreview();
    }
  }

  startExamPreview = (): void => {
    const { navigation, examTaking } = this.props;

    if (examTaking && examTaking.length === 1) {
      ipcRenderer
        .invoke("START_EXAM", examTaking[0].examParams)
        .then((result) => {
          const res = JSON.parse(result);
          if (res.type === "START_EXAM_OK") {
            navigation.reset({
              index: 0,
              routes: [
                {
                  name: "ExamFlow" as any,
                  params: {
                    screen: "ExamTaking",
                    params: { examId: examTaking[0].id }
                  }
                }
              ]
            });
          }
        })
        .catch((err) => {
          ipcRenderer.send(
            "LOG_ERROR",
            `Could not start exam ${examTaking[0].id}`,
            JSON.stringify(err)
          );
        });
    }
  };

  startTraining = (): void => {
    const { navigation, createExamTaking } = this.props;

    const examTraining = getTrainingExam();

    createExamTaking(examTraining);
    ipcRenderer
      .invoke("START_EXAM", examTraining.examParams)
      .then((result) => {
        const res = JSON.parse(result);
        if (res.type === "START_EXAM_OK") {
          navigation.reset({
            index: 0,
            routes: [
              {
                name: "ExamFlow" as any,
                params: {
                  screen: "ExamTaking",
                  params: { isTraining: true, examId: examTraining.id }
                }
              }
            ]
          });
        }
      })
      .catch((err) => {
        ipcRenderer.send(
          "LOG_ERROR",
          "Could not start training exam",
          JSON.stringify(err)
        );
      });
  };

  render(): JSX.Element {
    const {
      disabled,
      containerStyle,
      items,
      navigation,
      currentRouteName
    } = this.props;

    return (
      <View style={[styles.defaultContainer, containerStyle]}>
        {items.map((item) => {
          if (IS_PREVIEW || !item.show) return null;
          return (
            <HeaderItem
              itemSelected={
                currentRouteName ? item.id === currentRouteName : false
              }
              key={guidGenerator()}
              containerStyle={styles.headerItem}
              label={item.label}
              onPress={() => {
                if (!disabled) {
                  if (item.id.localeCompare(TRAINING) === 0) {
                    this.startTraining();
                  } else {
                    navigation.reset({
                      index: 0,
                      routes: [
                        {
                          name: item.id as any
                        }
                      ]
                    });
                  }
                }
              }}
            />
          );
        })}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  defaultContainer: {
    flexDirection: "row"
  },
  headerItem: {
    paddingHorizontal: PADDING_SIDES * 0.2
  }
});

export default HeaderNavigation;
