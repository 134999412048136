import React from "react";
import { StyleSheet, View, Text } from "react-native";
import { ipcRenderer } from "electron";
import i18n from "../../services/i18n";
import {
  FONTSIZE_16,
  FONTSIZE_24,
  FONT_GILROY_BOLD,
  FONT_GILROY_REGULAR,
  PADDING_SIDES
} from "../../../static/misc/constants";
import {
  COLOR_BLUE_TESTWE,
  COLOR_GREEN_VALID,
  COLOR_RED
} from "../../../static/misc/colors";
import Button from "../molecules/Button";

export interface OnboardingCameraPemissionStepProps {
  onPermissionsValidated: () => void;
  onPermissionsNotRequired: () => void;
}

interface OnboardingCameraPermissionStepState {
  setupFinalized: boolean;
  hasCheckedPermissions: boolean;
}

class OnboardingCameraPermissionStep extends React.PureComponent<
  OnboardingCameraPemissionStepProps,
  OnboardingCameraPermissionStepState
> {
  constructor(props: OnboardingCameraPemissionStepProps) {
    super(props);

    this.state = {
      setupFinalized: false,
      hasCheckedPermissions: false
    };
  }

  componentDidMount(): void {
    const { onPermissionsNotRequired } = this.props;

    ipcRenderer.send("ASK_PROCTORING_PERM");

    ipcRenderer.on("ASK_PROCTORING_PERM_RESPOND", (event, arg) => {
      this.setState({
        hasCheckedPermissions: true
      });
      if (arg) {
        onPermissionsNotRequired();
      }
    });
  }

  render(): JSX.Element {
    const { onPermissionsValidated } = this.props;
    const { setupFinalized, hasCheckedPermissions } = this.state;
    return hasCheckedPermissions ? (
      <View style={styles.container}>
        <Text
          style={setupFinalized ? styles.statusOKText : styles.statusNOKText}
        >
          {i18n.t("exam.permissions.desktop.cameraPermissionsNOK")}
        </Text>
        {setupFinalized ? (
          <Text style={styles.title2Text}>
            {i18n.t("exam.permissions.desktop.cameraPermissionsTitle2")}
          </Text>
        ) : (
          <>
            <Text style={styles.titleText}>
              {i18n.t("exam.permissions.desktop.cameraPermissionsTitle")}
            </Text>
            <Text style={styles.descriptionText}>
              {i18n.t("exam.permissions.desktop.cameraPermissionsDescription")}
            </Text>
            <Button
              label={i18n.t(
                "exam.permissions.desktop.cameraPermissionsShowPreferences"
              )}
              onPress={() => {
                ipcRenderer.send("OPEN_SYSTEM_PREFERENCES", "security");
                this.setState({
                  setupFinalized: true
                });
                onPermissionsValidated();
              }}
            />
          </>
        )}
      </View>
    ) : (
      <></>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexShrink: 1,
    flexGrow: 1,
    alignItems: "center"
  },
  statusOKText: {
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_24,
    color: COLOR_GREEN_VALID,
    marginBottom: PADDING_SIDES,
    lineHeight: 20
  },
  statusNOKText: {
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_24,
    color: COLOR_RED,
    marginBottom: PADDING_SIDES,
    lineHeight: 20
  },
  titleText: {
    fontFamily: FONT_GILROY_BOLD,
    fontSize: FONTSIZE_16,
    color: COLOR_BLUE_TESTWE,
    marginBottom: PADDING_SIDES * 0.4,
    lineHeight: 20
  },
  descriptionText: {
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_16,
    color: COLOR_BLUE_TESTWE,
    marginBottom: PADDING_SIDES,
    lineHeight: 20
  },
  title2Text: {
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_24,
    color: COLOR_BLUE_TESTWE,
    marginBottom: PADDING_SIDES * 0.4,
    lineHeight: 20
  }
});

export default OnboardingCameraPermissionStep;
