import React from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { COLOR_WHITE } from "../../../../static/misc/colors";
import {
  PADDING_SIDES,
  WIN,
  NOT_REALIZED,
  CORRECTION_NOT_SHARED,
  CORRECTION_SHARED
} from "../../../../static/misc/constants";
import TitleAndTagsExam from "../../molecules/TitleAndTagsExam";
import i18n from "../../../services/i18n";
import PassedExamsItem from "../../organisms/PassedExamsItem";
import ExtraBoldTitle from "../../atoms/ExtraBoldTitle";
import {
  UpdateDisplayedExamsAction,
  GetMyPaperBase64Action
} from "../../../modules/exams/types/exams";
import { DisplayedExamsEnum } from "../../../modules/exams/ExamsView";
import { ExamType } from "../../../modules/exams/types/exam";
import { StackNavigatorProp } from "../../../router/StackNavigator";
import moment from "../../../services/moment";
import ExamListPlaceholder from "../../molecules/ExamListPlaceholder";

export interface PassedExamsProps {
  token: string;
  containerStyle?: StyleProp<ViewStyle>;
  passedExams: Array<ExamType>;
  navigation: StackNavigatorProp;
  isOnline: boolean;
  updateDisplayedExams: (
    examType: DisplayedExamsEnum
  ) => UpdateDisplayedExamsAction;
  getMyPaperBase64: (
    studentPaperId: string,
    userToken: string,
    examId: string
  ) => GetMyPaperBase64Action;
}

class PassedExams extends React.PureComponent<PassedExamsProps> {
  render(): JSX.Element {
    const {
      token,
      containerStyle,
      navigation,
      isOnline,
      passedExams,
      updateDisplayedExams,
      getMyPaperBase64
    } = this.props;

    const passedExamsTmp = [...passedExams].sort((a, b) => {
      return moment(a.startDate).isBefore(b.startDate)
        ? 1
        : moment(a.startDate).isAfter(b.startDate)
        ? -1
        : 0;
    });

    return (
      <View style={[styles.defaultContainerStyle, containerStyle]}>
        {(isOnline && (
          <TitleAndTagsExam
            title={`${i18n.t("homepage.passedExams")} (${passedExams.length})`}
            rightText={`${i18n.t("homepage.allresults")} (${
              passedExams.length
            })`}
            onPress={() => {
              updateDisplayedExams(DisplayedExamsEnum.PASSED_EXAMS);
              navigation.reset({
                index: 0,
                routes: [
                  {
                    name: "Exams" as any
                  }
                ]
              });
            }}
          />
        )) || <ExtraBoldTitle text={i18n.t("homepage.passedExams")} />}
        {(passedExamsTmp &&
          passedExamsTmp.length > 0 &&
          passedExamsTmp.map((exam, index) => {
            if (index >= 3) return null;
            return (
              <PassedExamsItem
                token={token}
                key={`exam-detail-${exam.id}`}
                isOnline={isOnline}
                studentPaperId={exam.myStudentPaper?.id || ""}
                subject={exam.subject?.name || ""}
                name={exam.name}
                examId={exam.id}
                maxNote={exam.maxPoints}
                mark={
                  exam.examSharingStatus &&
                  exam.examSharingStatus !== CORRECTION_NOT_SHARED
                    ? exam.myStudentPaper?.totalPoints
                    : undefined
                }
                date={new Date(exam.startDate)}
                hasRemark={
                  exam.examSharingStatus &&
                  exam.examSharingStatus === CORRECTION_SHARED
                    ? exam.myStudentPaper?.appreciation
                    : undefined
                }
                getMyPaperBase64={getMyPaperBase64}
                base64pdf={
                  exam.examSharingStatus === CORRECTION_SHARED
                    ? exam.myStudentPaper?.base64
                    : undefined
                }
                blobUrl={
                  exam.examSharingStatus === CORRECTION_SHARED
                    ? exam.myStudentPaper?.blobUrl
                    : undefined
                }
                status={
                  exam.myStudentPaper && exam.myStudentPaper.status
                    ? exam.myStudentPaper.proctorArchiveIssue ||
                      exam.myStudentPaper.status
                    : NOT_REALIZED
                }
                correctionStatus={exam.examSharingStatus}
              />
            );
          })) || (
          <ExamListPlaceholder
            text={i18n.t("homepage.passedExamsPlaceholder")}
          />
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  defaultContainerStyle: {
    width: "48%",
    minWidth: WIN.width * 0.4,
    marginTop: PADDING_SIDES,
    flexDirection: "column",
    backgroundColor: COLOR_WHITE
  }
});

export default PassedExams;
