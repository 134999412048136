import React from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { COLOR_WHITE } from "../../../static/misc/colors";
import { FONTSIZE_12 } from "../../../static/misc/constants";
import BoldTitle from "../atoms/BoldTitle";
import LightText from "../atoms/LightText";
import i18n from "../../services/i18n";

export interface UpcomingExamDetailProps {
  containerStyle?: StyleProp<ViewStyle>;
  subject: string;
  duration: number;
}

class UpcomingExamDetail extends React.PureComponent<UpcomingExamDetailProps> {
  render(): JSX.Element {
    const { containerStyle, subject, duration } = this.props;
    const hour = duration >= 60 ? Math.floor(duration / 3600) : 0;
    const minutes = duration >= 60 ? Math.floor((duration % 3600) / 60) : 0;

    return (
      <View style={[styles.defaultContainer, containerStyle]}>
        <BoldTitle textStyle={styles.textStyle} text={subject} />
        <LightText
          textStyle={[styles.textStyle]}
          text={`${i18n.t("homepage.finalexam")} - ${hour}h${
            minutes < 10 ? "0" : ""
          }${minutes}`}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  defaultContainer: {
    flex: 1
  },
  textStyle: {
    color: COLOR_WHITE,
    fontSize: FONTSIZE_12
  }
});

export default UpcomingExamDetail;
