import React from "react";
import { StyleProp, StyleSheet, Text, TextStyle } from "react-native";
import { COLOR_BLUE_TESTWE } from "../../../static/misc/colors";
import { FONTSIZE_16, FONT_GILROY_BOLD } from "../../../static/misc/constants";
import i18n from "../../services/i18n";

interface FileTransferSpeedLabelProps {
  speed: number;
  style?: StyleProp<TextStyle>;
}

class FileTransferSpeedLabel extends React.PureComponent<FileTransferSpeedLabelProps> {
  formatSpeed(val: number): string {
    const bytes = val / 1000;
    return `${bytes.toFixed(1)}`;
  }

  render(): JSX.Element {
    const { speed, style } = this.props;
    return (
      <Text style={[styles.defaultBoldTxt, style]}>
        {i18n.t("testing.connectionTesting.connectionSpeed", {
          speed: this.formatSpeed(speed)
        })}
      </Text>
    );
  }
}

const styles = StyleSheet.create({
  defaultBoldTxt: {
    fontFamily: FONT_GILROY_BOLD,
    fontSize: FONTSIZE_16,
    color: COLOR_BLUE_TESTWE
  }
});

export default FileTransferSpeedLabel;
