import React from "react";
import { StyleSheet, View } from "react-native";
import { PADDING_SIDES } from "../../../static/misc/constants";
import {
  cableData,
  frontDesk,
  identityCard,
  lamp,
  permCameraMic,
  wc
} from "../../../static/misc/images";
import i18n from "../../services/i18n";
import InstructionItem from "../molecules/InstructionItem";

interface OnBoardingWelcomeProps {
  isProctoringLive: boolean;
  updateCurrentStepInfo: (
    data?: string | Blob | null,
    status?: boolean
  ) => void;
}

export default class OnBoardingWelcome extends React.PureComponent<OnBoardingWelcomeProps> {
  componentDidMount(): void {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.updateCurrentStepInfo("", true);
  }

  render(): JSX.Element {
    const { isProctoringLive } = this.props;

    return (
      <>
        <View style={styles.instructionsContainer}>
          <View style={styles.instructionsLine}>
            <InstructionItem
              icon={permCameraMic}
              title={i18n.t("exam.welcome.instruction1")}
            />
            <InstructionItem
              icon={identityCard}
              title={i18n.t("exam.welcome.instruction2")}
            />
            {isProctoringLive && (
              <InstructionItem
                icon={cableData}
                title={i18n.t("exam.welcome.instruction3")}
              />
            )}
          </View>
          <View style={styles.instructionsLine}>
            <InstructionItem
              icon={lamp}
              title={i18n.t("exam.welcome.instruction4")}
            />
            <InstructionItem
              icon={frontDesk}
              title={i18n.t("exam.welcome.instruction5")}
            />
            {isProctoringLive && (
              <InstructionItem
                icon={wc}
                title={i18n.t("exam.welcome.instruction6")}
              />
            )}
          </View>
        </View>
      </>
    );
  }
}

const styles = StyleSheet.create({
  instructionsContainer: {
    width: "100%",
    maxWidth: 800,
    alignSelf: "center",
    flexShrink: 1,
    flexGrow: 1,
    flexDirection: "column"
  },
  instructionsLine: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-evenly",
    width: "100%",
    margin: PADDING_SIDES * 0.5
  }
});
