import {
  WS_DISABLE_PROCTOR_AUDIO,
  WS_ENABLE_PROCTOR_AUDIO,
  WS_GET_ROOM_AGAIN_SUCCESS,
  WS_GET_ROOM_SUCCESS,
  WS_PAUSE_EXAM,
  WS_STOP_EXAM,
  WS_UNPAUSE_EXAM
} from "../../../../static/misc/wsevents";
import { ISONLINE } from "../../main/types/status";
import {
  AUTH_STUDENT_FULFILLED,
  EXAM_FINISHED_FULFILLED,
  EXAM_FINISHED_REJECTED,
  ProctoringLiveActionType,
  ProctoringLiveState,
  WS_EXAM_FINISHED
} from "../types/proctoringlive";

const initialState: ProctoringLiveState = {
  isWsAuth: false,
  proctoringRoom: undefined,
  reconnection: false,
  examPause: false,
  examToBeStopped: false,
  proctorIdentity: undefined,
  proctorAudioEnable: false
};

export default function reducer(
  state = initialState,
  action: ProctoringLiveActionType
): ProctoringLiveState {
  switch (action.type) {
    case WS_GET_ROOM_SUCCESS:
    case WS_GET_ROOM_AGAIN_SUCCESS: {
      return {
        ...state,
        proctoringRoom: action.payload.roomId,
        twilioAccessToken: action.payload.accessToken,
        reconnection: action.type === WS_GET_ROOM_AGAIN_SUCCESS
      };
    }

    case WS_DISABLE_PROCTOR_AUDIO:
      return {
        ...state,
        proctorAudioEnable: false
      };

    case WS_ENABLE_PROCTOR_AUDIO:
      return {
        ...state,
        proctorAudioEnable: true,
        proctorIdentity: action.payload.proctorIdentity
      };

    case WS_STOP_EXAM:
      return {
        ...state,
        examToBeStopped: true
      };

    case WS_PAUSE_EXAM:
      return {
        ...state,
        examPause: true
      };

    case WS_UNPAUSE_EXAM:
      return {
        ...state,
        examPause: false
      };

    case WS_EXAM_FINISHED:
    case EXAM_FINISHED_FULFILLED:
    case EXAM_FINISHED_REJECTED:
      return {
        ...state,
        proctoringRoom: undefined,
        reconnection: false,
        examPause: false,
        examToBeStopped: false,
        proctorIdentity: undefined,
        proctorAudioEnable: false
      };

    case AUTH_STUDENT_FULFILLED:
      return {
        ...state,
        isWsAuth: true
      };

    case ISONLINE: {
      // if we lose connection, ws connection and twilio room must be reset
      if (action.payload === false) {
        return {
          ...state,
          isWsAuth: false,
          proctoringRoom: undefined,
          examPause: true
        };
      }
      return {
        ...state,
        examPause: false
      };
    }
    default:
      return state;
  }
}
