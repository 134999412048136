import { EXAMS } from "../../../../static/misc/network";
import {
  ClearPublicKeyAction,
  CLEAR_PUBLIC_KEY,
  CreatePublicKeyAction,
  CREATE_PUBLIC_KEY,
  GetExamPublicKeyAction,
  GET_EXAM_PUBLIC_KEY
} from "../types/publickeys";

export function getExamPublicKey(
  examId: string,
  userToken: string
): GetExamPublicKeyAction {
  return {
    type: GET_EXAM_PUBLIC_KEY,
    payload: {
      client: "default",
      request: {
        headers: {
          accept: "application/json",
          authorization: `Bearer ${userToken}`
        },
        url: `${EXAMS}/public-key/${examId}`
      },
      data: {
        examId
      },
      method: "get"
    }
  };
}

export function createPublicKey(
  examId: string,
  publicKey: string
): CreatePublicKeyAction {
  return {
    type: CREATE_PUBLIC_KEY,
    payload: {
      examId,
      publicKey
    }
  };
}

export function clearPublicKeys(examId?: string): ClearPublicKeyAction {
  return {
    type: CLEAR_PUBLIC_KEY,
    payload: {
      examId
    }
  };
}
