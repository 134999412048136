import React from "react";
import {
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
  Image,
  Text
} from "react-native";
import { COLOR_BLUE, COLOR_WHITE } from "../../../static/misc/colors";
import { illuHomepage1 } from "../../../static/misc/images";
import {
  FONTSIZE_14,
  FONT_GILROY_REGULAR,
  PADDING_SIDES
} from "../../../static/misc/constants";
import i18n from "../../services/i18n";

export interface NextExamPlaceholderProps {
  containerStyle?: StyleProp<ViewStyle>;
}

class NextExamPlaceholder extends React.PureComponent<NextExamPlaceholderProps> {
  render(): JSX.Element {
    const { containerStyle } = this.props;
    return (
      <View style={[styles.defaultContainerStyle, containerStyle]}>
        <Image source={illuHomepage1} style={styles.illustration} />
        <View style={styles.contentWrapper}>
          <Text style={styles.defaultRegularTxt}>
            {i18n.t("homepage.nextExamsPlaceholder")}
          </Text>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  defaultContainerStyle: {
    paddingHorizontal: 10,
    paddingVertical: 10,
    alignItems: "center",
    flexDirection: "row",
    backgroundColor: COLOR_WHITE
  },
  contentWrapper: {
    left: -PADDING_SIDES * 0.5,
    paddingLeft: 30,
    flex: 1
  },
  illustration: {
    width: 191,
    height: 130
  },
  defaultRegularTxt: {
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_14,
    color: COLOR_BLUE
  }
});

export default NextExamPlaceholder;
