import React from "react";
import {
  View,
  StyleSheet,
  Text,
  TouchableOpacity,
  Image,
  ScrollView
} from "react-native";
import _ from "lodash";
import ReactPlayer from "react-player";
import { NewHighlight } from "react-pdf-highlighter";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ipcRenderer } from "electron";
import {
  COLOR_BLUE_HR,
  COLOR_BLUE_LIGHT,
  COLOR_BLUE_TESTWE,
  COLOR_WHITE
} from "../../../static/misc/colors";
import {
  FONTSIZE_11,
  FONT_GILROY_BOLD,
  FONT_GILROY_MEDIUM,
  MEDIUM_WIDTH,
  MIME_AUDIO,
  MIME_IMAGE,
  MIME_PDF,
  MIME_VIDEO,
  PADDING_SIDES,
  TRAINING,
  WIN
} from "../../../static/misc/constants";
import { EXAM, MEDIA_MENU, PART, QUESTION } from "../../../static/misc/menu";
import { MediaType } from "../../modules/exams/types/attachedfiles";
import {
  ExamExercise,
  ExamPart,
  ExamQuestion,
  ExamType
} from "../../modules/exams/types/exam";
import PdfViewer from "../molecules/PdfViewer";
import i18n from "../../services/i18n";
import { b64toblob, guidGenerator } from "../../../static/misc/utils";
import ButtonBackList from "../molecules/ButtonBackList";
import MediaReader from "./MediaReader";
import { playIcon, sound } from "../../../static/misc/images";
import Icon from "../atoms/Icon";
import {
  addPdfHighlight,
  modifyPdfHighlight,
  removePdfHighlight
} from "../../modules/examTaking/actions/examTaking";

export interface MediaMenuProps {
  currentExamPart: number;
  currentPartIndex: number;
  currentAttachedFiles?: MediaType[];
  currentExam: ExamType;
  currentPdfPage: number;
  isMediaFullscreen: boolean;
  addHighlight(
    examId: number,
    attachedFileId: string,
    hightlight: NewHighlight
  ): void;
  modifyHighlight(
    examId: number,
    attachedFileId: string,
    hightlight: NewHighlight
  ): void;
  removeHighlight(examId: number, attachedFileId: string, index: number): void;
  onMediaFullscreen: (mediaId: string, type: string) => void;
  onPdfPageChange: (newPage: number) => void;
}

type MediaMenuType = {
  id: string;
  label: string;
};
export interface MediaMenuState {
  selectedIndex: number;
  items: string[][];
  onMediaClickedIndex: number;
  heightMedia: number;
  widthMedia: number;
  currentContent: MediaMenuType | null;
}

const MEDIA_UNCLICKED = -1;

class MediaMenu extends React.Component<MediaMenuProps, MediaMenuState> {
  lastItems: string[][] = [];

  constructor(props: MediaMenuProps) {
    super(props);
    this.state = {
      selectedIndex: -1,
      heightMedia: 0,
      widthMedia: 0,
      onMediaClickedIndex: MEDIA_UNCLICKED,
      currentContent: null,
      items: []
    };
  }

  componentDidMount(): void {
    this.setupMediaMenu();
  }

  // We want to component to re-render only if the state changes (= the student changed the media section to be displayed)
  // or if part or index changes
  // otherwise there are too many refresh and it's visible on the video
  shouldComponentUpdate(
    nextProps: MediaMenuProps,
    nextState: MediaMenuState
  ): boolean {
    const { currentPartIndex, currentExamPart, isMediaFullscreen } = this.props;
    const { items } = this.state;

    const newItems: string[][] = this.getNewItems();

    return (
      !_.isEqual(this.state, nextState) ||
      !_.isEqual(newItems, items) ||
      currentExamPart !== nextProps.currentExamPart ||
      currentPartIndex !== nextProps.currentPartIndex ||
      isMediaFullscreen !== nextProps.isMediaFullscreen
    );
  }

  // If the current exam part is updated, we need to re-setup the menu
  componentDidUpdate(prevProps: MediaMenuProps): void {
    const { currentExamPart, currentPartIndex } = this.props;
    const { items } = this.state;

    const newItems: string[][] = this.getNewItems();

    if (
      !_.isEqual(newItems, items) ||
      currentExamPart !== prevProps.currentExamPart ||
      currentPartIndex !== prevProps.currentPartIndex
    ) {
      this.setupMediaMenu();
    }
  }

  getNewItems(): string[][] {
    const newItems: string[][] = [];

    // Checking the number of medias per exam/part/exercise/question...
    MEDIA_MENU.forEach((item, index) => {
      newItems[index] = this.getItems(item.id);
    });

    return newItems;
  }

  setupMediaMenu(): void {
    const { items, selectedIndex } = this.state;

    // Checking the number of medias per exam/part/exercise/question...
    const newItems: string[][] = this.getNewItems();
    let newSelectedIndex = 0;

    // ... so we can automatically set the default display to the deeper section with medias
    newItems.forEach((item, index) => {
      if (item.length > 0) {
        newSelectedIndex = index;
      }
    });

    if (selectedIndex !== newSelectedIndex || !_.isEqual(newItems, items)) {
      this.setState({
        currentContent:
          newSelectedIndex > -1 ? MEDIA_MENU[newSelectedIndex] : null,
        items: newItems,
        selectedIndex: newSelectedIndex,
        onMediaClickedIndex: MEDIA_UNCLICKED
      });
    }
  }

  // Retrieves the number of items per section
  getItems(item: string): Array<string> {
    const { currentExam, currentExamPart, currentPartIndex } = this.props;

    // let numberOfItems = 0;
    let items: Array<string> = [];
    switch (item) {
      case EXAM:
        items = currentExam.attachedFiles.map(
          (attachedFile) => attachedFile.id
        );
        return items;
      case PART:
        if (currentExam.examParts && Array.isArray(currentExam.examParts))
          items = currentExam.examParts[currentExamPart].attachedFiles.map(
            (attachedFile) => attachedFile.id
          );
        return items;
      case QUESTION:
        if (currentExam.examParts && Array.isArray(currentExam.examParts)) {
          if (
            currentExam.examParts[currentExamPart].partIndexes[currentPartIndex]
              .type === "question" &&
            currentExam.examParts[currentExamPart].partIndexes[currentPartIndex]
              .question !== undefined
          ) {
            items = items.concat(
              (currentExam.examParts[currentExamPart].partIndexes[
                currentPartIndex
              ].question as ExamQuestion).attachedFiles.map(
                (attachedFile) => attachedFile.id
              )
            );
          } else if (
            currentExam.examParts[currentExamPart].partIndexes[currentPartIndex]
              .type === "exercise" &&
            currentExam.examParts[currentExamPart].partIndexes[currentPartIndex]
              .exercise !== undefined
          )
            items = items.concat(
              (currentExam.examParts[currentExamPart].partIndexes[
                currentPartIndex
              ].exercise as ExamExercise).attachedFiles.map(
                (attachedFile) => attachedFile.id
              )
            );
        }
        return items;
      default:
        return items;
    }
  }

  // Switch the media section
  switchContent = (selectedIndex: number): void => {
    this.setState({
      currentContent: MEDIA_MENU[selectedIndex],
      selectedIndex,
      onMediaClickedIndex: MEDIA_UNCLICKED
    });
  };

  // Loops on the media list of the section to display it
  mediaLoop(
    loopFrom: ExamType | ExamPart | ExamQuestion | ExamExercise,
    docName = "",
    pIndex?: number,
    qIndex?: number,
    type?: string
  ): (JSX.Element | null)[] {
    const {
      currentAttachedFiles,
      currentExamPart,
      currentPartIndex
    } = this.props;
    if (
      (type === "ExamPart" && currentExamPart === pIndex) ||
      (type === "ExamQuestion" &&
        currentExamPart === pIndex &&
        currentPartIndex === qIndex) ||
      type === "ExamType"
    )
      return loopFrom.attachedFiles.map((attachedFile) => {
        const currentAttachedFileIndex = currentAttachedFiles?.findIndex(
          (e) => e.mediaId === attachedFile.id
        );
        if (
          currentAttachedFileIndex !== undefined &&
          currentAttachedFileIndex >= 0 &&
          currentAttachedFiles
        ) {
          const currentFile = currentAttachedFiles[currentAttachedFileIndex];
          return this.renderFile(
            currentFile,
            docName,
            currentAttachedFileIndex
          );
        }
        return null;
      });
    return loopFrom.attachedFiles.map(() => {
      return null;
    });
  }

  // Render the media section depending on the section type
  content():
    | (null | JSX.Element)
    | (JSX.Element | null)[]
    | (JSX.Element | null)[][]
    | ((JSX.Element | null)[] | null)[][] {
    const { currentExam } = this.props;
    const { currentContent } = this.state;

    if (currentContent) {
      switch (currentContent.id) {
        case EXAM: {
          return this.mediaLoop(currentExam, "", 0, 0, "ExamType");
        }
        case PART: {
          if (
            !currentExam ||
            currentExam === undefined ||
            !currentExam.examParts ||
            !Array.isArray(currentExam.examParts)
          )
            return null;
          return currentExam.examParts.map((examPart, index) =>
            this.mediaLoop(
              examPart,
              `${i18n.t("media.part")} ${index + 1}`,
              index,
              0,
              "ExamPart"
            )
          );
        }
        case QUESTION:
          if (
            !currentExam ||
            currentExam === undefined ||
            !currentExam.examParts ||
            !Array.isArray(currentExam.examParts)
          )
            return null;
          return currentExam.examParts.map((examPart, index) => {
            let exoNumber = 0;
            return examPart.partIndexes.map((partIndex, qNumber) => {
              if (partIndex.type === "question" && partIndex.question)
                return this.mediaLoop(
                  partIndex.question,
                  `${i18n.t("media.part")} ${index + 1}/${i18n.t("media.q")} ${
                    qNumber + 1 - exoNumber
                  }`,
                  index,
                  qNumber,
                  "ExamQuestion"
                );
              if (partIndex.type === "exercise" && partIndex.exercise) {
                exoNumber += 1;
                return this.mediaLoop(
                  partIndex.exercise,
                  `${i18n.t("media.part")} ${index + 1}/${i18n.t(
                    "media.e"
                  )} ${exoNumber}`,
                  index,
                  qNumber,
                  "ExamQuestion"
                );
              }
              return null;
            });
          });
        default:
          return null;
      }
    }
    return null;
  }

  focusMedia(fileIndex: number): void {
    ipcRenderer.send(
      "LOG_INFO",
      `MEDIA_VIEWER | focus-new-media: ${fileIndex}`
    );
    this.setState({ onMediaClickedIndex: fileIndex });
  }

  // Render the file depending on the mimetype
  renderFile(
    currentFile: MediaType,
    documentName: string,
    currentAttachedFileIndex: number
  ): JSX.Element | null {
    const { currentExam } = this.props;
    if (currentExam.id === TRAINING && currentFile.data) {
      // eslint-disable-next-line no-param-reassign
      currentFile.blobUrl = URL.createObjectURL(
        b64toblob(currentFile.data, currentFile.mimeType)
      );
    }
    if (
      _.includes(MIME_AUDIO, currentFile.mimeType) &&
      currentFile.blobUrl !== undefined
    ) {
      return (
        <TouchableOpacity
          key={guidGenerator()}
          onPress={() => this.focusMedia(currentAttachedFileIndex)}
          style={styles.audioVideoContainer}
        >
          <View style={styles.audioVideoPreviewContainer}>
            <Icon icon={sound} iconStyle={styles.audioPreviewIcon} />
          </View>
          <Text style={styles.documentName}>{documentName}</Text>
        </TouchableOpacity>
      );
    }
    if (
      _.includes(MIME_VIDEO, currentFile.mimeType) &&
      currentFile.blobUrl !== undefined
    ) {
      return (
        <TouchableOpacity
          key={guidGenerator()}
          onPress={() => this.focusMedia(currentAttachedFileIndex)}
          style={styles.audioVideoContainer}
        >
          <View style={styles.audioVideoPreviewContainer}>
            <View style={styles.videoPreviewIconContainer}>
              <Icon icon={playIcon} iconStyle={styles.videoPreviewIcon} />
            </View>
            <ReactPlayer
              url={currentFile.blobUrl}
              width="100%"
              height="100%"
              controls={false}
              playing={false}
              config={{
                file: { attributes: { controlsList: "nodownload" } }
              }}
            />
          </View>
          <Text style={styles.documentName}>{documentName}</Text>
        </TouchableOpacity>
      );
    }
    if (
      _.includes(MIME_IMAGE, currentFile.mimeType) &&
      currentFile.blobUrl !== undefined
    ) {
      return (
        <TouchableOpacity
          key={guidGenerator()}
          onPress={() => this.focusMedia(currentAttachedFileIndex)}
        >
          <Image
            source={{
              uri: currentFile.blobUrl
            }}
            style={styles.imageContainer}
          />
          <Text style={styles.documentName}>{documentName}</Text>
        </TouchableOpacity>
      );
    }
    if (currentFile.mimeType === MIME_PDF && currentFile.blobUrl !== undefined)
      return (
        <TouchableOpacity
          key={guidGenerator()}
          onPress={() => this.focusMedia(currentAttachedFileIndex)}
        >
          <PdfViewer
            width={168}
            base64pdf={currentFile.data}
            canDownload={currentExam.id === TRAINING}
            blobUrl={currentFile.blobUrl}
            height={168}
            disableNavBar
            isOpenBook={currentExam.examParams?.openBook || false}
            isFullscreen={false}
            highlights={
              currentExam.highlights?.find(
                (h) => h.fileId === currentFile.mediaId
              )?.highlights
            }
            onHighlightAdded={() => undefined}
            onHighlightModified={() => undefined}
            onHighlightRemoved={() => undefined}
            onFullscreenToggle={() => undefined}
          />
          <Text style={styles.documentName}>{documentName}</Text>
        </TouchableOpacity>
      );
    return null;
  }

  render(): JSX.Element {
    const {
      currentPdfPage,
      currentAttachedFiles,
      currentExam,
      onMediaFullscreen,
      addHighlight,
      modifyHighlight,
      removeHighlight,
      onPdfPageChange
    } = this.props;
    const {
      selectedIndex,
      items,
      onMediaClickedIndex,
      heightMedia,
      widthMedia,
      currentContent
    } = this.state;

    let highlights =
      currentAttachedFiles && currentAttachedFiles[onMediaClickedIndex]
        ? currentExam.highlights?.find(
            (h) =>
              h.fileId === currentAttachedFiles[onMediaClickedIndex].mediaId
          )?.highlights
        : [];

    highlights = highlights ? [...highlights] : [];

    return (
      <View style={styles.container}>
        <View style={styles.menuContainer}>
          <Text style={styles.textMenu}>{i18n.t("media.docOf")}</Text>
          <View style={styles.menuWrapper}>
            {MEDIA_MENU.map((item, index) => {
              const nbItems = items[index]?.length ?? 0;

              if (nbItems > 0) {
                return (
                  <TouchableOpacity
                    key={guidGenerator()}
                    style={styles.menuItem}
                    onPress={() => this.switchContent(index)}
                  >
                    <Text
                      style={[
                        selectedIndex === index
                          ? styles.itemSelected
                          : styles.item
                      ]}
                    >
                      {`${i18n.t(item.label)} (${nbItems})`}
                    </Text>
                    {selectedIndex === index && (
                      <View style={styles.selectionBar} />
                    )}
                  </TouchableOpacity>
                );
              }
              return null;
            })}
          </View>
        </View>
        {(onMediaClickedIndex === MEDIA_UNCLICKED && (
          <ScrollView
            style={styles.scrollViewStyle}
            contentContainerStyle={styles.contentMedia}
          >
            {this.content()}
          </ScrollView>
        )) ||
          (currentAttachedFiles && (
            <View
              onLayout={(event) => {
                this.setState({
                  heightMedia: event.nativeEvent.layout.height,
                  widthMedia: event.nativeEvent.layout.width
                });
              }}
              style={styles.mediaClickedStyleContainer}
            >
              <ButtonBackList
                onPress={() =>
                  this.setState({ onMediaClickedIndex: MEDIA_UNCLICKED })
                }
                containerStyle={styles.buttonBackContainer}
              />
              <View style={styles.separator} />
              <MediaReader
                currentPdfPage={currentPdfPage}
                heightMedia={heightMedia - PADDING_SIDES / 2}
                widthMedia={widthMedia}
                media={currentAttachedFiles[onMediaClickedIndex]}
                isTraining={currentExam.id === TRAINING}
                isOpenBook={currentExam.examParams?.openBook || false}
                highlights={highlights}
                isFullscreen={false}
                onHighlightAdded={(highlight: NewHighlight) => {
                  addHighlight(
                    currentExam.examId,
                    currentAttachedFiles[onMediaClickedIndex].mediaId,
                    highlight
                  );
                }}
                onHighlightModified={(highlight: NewHighlight) =>
                  modifyHighlight(
                    currentExam.examId,
                    currentAttachedFiles[onMediaClickedIndex].mediaId,
                    highlight
                  )
                }
                onHighlightRemoved={(index: number) =>
                  removeHighlight(
                    currentExam.examId,
                    currentAttachedFiles[onMediaClickedIndex].mediaId,
                    index
                  )
                }
                onFullscreenToggle={() =>
                  onMediaFullscreen(
                    currentAttachedFiles[onMediaClickedIndex].mediaId,
                    currentContent?.id ?? ""
                  )
                }
                onPdfPageChange={onPdfPageChange}
              />
            </View>
          ))}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: COLOR_WHITE,
    flexWrap: "wrap"
  },
  menuContainer: {
    flexDirection: "row",
    width: "100%",
    paddingBottom: 15,
    justifyContent: "space-between",
    flexWrap: "wrap"
  },
  menuWrapper: {
    flexGrow: 1,
    maxWidth: "100%",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap"
  },
  menuItem: {
    flexDirection: "column",
    paddingHorizontal: 10
  },
  textMenu: {
    color: COLOR_BLUE_LIGHT,
    fontSize: FONTSIZE_11,
    fontFamily: FONT_GILROY_MEDIUM
  },
  selectionBar: {
    paddingTop: 10,
    borderBottomColor: COLOR_BLUE_TESTWE,
    borderBottomWidth: 4
  },
  itemSelected: {
    color: COLOR_BLUE_TESTWE,
    fontFamily: FONT_GILROY_BOLD,
    fontSize: FONTSIZE_11
  },
  item: {
    color: COLOR_BLUE_TESTWE,
    fontFamily: FONT_GILROY_MEDIUM,
    fontSize: FONTSIZE_11
  },
  imageContainer: {
    borderWidth: 1,
    borderColor: COLOR_BLUE_HR,
    borderRadius: 5,
    overflow: "hidden",
    width: 168,
    height: 168
  },
  audioVideoContainer: {
    width: 168,
    height: 168
  },
  audioVideoPreviewContainer: {
    flex: 1,
    justifyContent: "center",
    borderWidth: 1,
    borderColor: COLOR_BLUE_HR,
    borderRadius: 5
  },
  audioPreviewIcon: {
    width: "50%",
    height: "50%",
    alignSelf: "center",
    tintColor: COLOR_BLUE_TESTWE
  },
  videoPreviewIcon: {
    marginTop: "40%",
    width: "20%",
    height: "20%",
    alignSelf: "center",
    tintColor: COLOR_BLUE_TESTWE
  },
  videoPreviewIconContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  scrollViewStyle: {
    width: "100%",
    height: WIN.height - PADDING_SIDES * 1.5,
    flex: 1,
    flexWrap: "wrap"
  },
  contentMedia: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around"
  },
  documentName: {
    textAlign: "center",
    fontFamily: MEDIUM_WIDTH,
    color: COLOR_BLUE_TESTWE,
    fontSize: FONTSIZE_11
  },
  mediaClickedStyleContainer: {
    flex: 1,
    width: "100%",
    height: "100%",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  separator: {
    paddingVertical: PADDING_SIDES * 0.1
  },
  buttonBackContainer: {
    alignSelf: "flex-start"
  }
});

export default connect(null, (dispatch: Dispatch) => {
  return {
    ...bindActionCreators(
      {
        addHighlight: addPdfHighlight,
        removeHighlight: removePdfHighlight,
        modifyHighlight: modifyPdfHighlight
      },
      dispatch
    )
  };
})(MediaMenu);
