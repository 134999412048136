import React from "react";
import { StyleProp, TextStyle, View, ViewStyle } from "react-native";
import BoldTitle from "../atoms/BoldTitle";

interface ExamInfoTitleProps {
  titleStyle: StyleProp<TextStyle>;
  containerStyle: StyleProp<ViewStyle>;
  title: string;
}

class ExamInfoTitle extends React.PureComponent<ExamInfoTitleProps> {
  render(): JSX.Element {
    const { title, containerStyle, titleStyle } = this.props;
    return (
      <View style={containerStyle}>
        <BoldTitle text={title} textStyle={titleStyle} />
      </View>
    );
  }
}

export default ExamInfoTitle;
