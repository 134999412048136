import React from "react";
import { StyleSheet, View, Text, Image } from "react-native";
import i18n from "../../services/i18n";
import {
  COLOR_BLUE_TESTWE,
  COLOR_TRANSPARENT
} from "../../../static/misc/colors";
import {
  FONTSIZE_13,
  FONTSIZE_16,
  FONT_GILROY_BOLD,
  FONT_GILROY_REGULAR,
  PADDING_SIDES
} from "../../../static/misc/constants";
import { checkIcon, crossRedIcon } from "../../../static/misc/images";

class ExamTakingRulesTestWe extends React.PureComponent {
  render(): JSX.Element {
    return (
      <View style={[styles.container]}>
        <Text style={[styles.titleStyle]}>{i18n.t("rules.testwe")}</Text>
        <View style={styles.ruleContainer}>
          <View style={styles.imageContainer}>
            <Image source={checkIcon} style={[styles.checkIcon]} />
          </View>
          <Text style={[styles.textStyle]}>{i18n.t("rules.battery")}</Text>
        </View>
        <View style={styles.ruleContainer}>
          <View style={styles.imageContainer}>
            <Image source={crossRedIcon} style={[styles.redCrossIcon]} />
          </View>
          <Text style={[styles.textStyle]}>{i18n.t("rules.leave")}</Text>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLOR_TRANSPARENT,
    flexDirection: "column",
    paddingTop: PADDING_SIDES * 0.5,
    paddingHorizontal: PADDING_SIDES * 0.5
  },
  titleStyle: {
    alignSelf: "flex-start",
    fontFamily: FONT_GILROY_BOLD,
    fontSize: FONTSIZE_16,
    color: COLOR_BLUE_TESTWE,
    paddingBottom: PADDING_SIDES * 0.4
  },
  textStyle: {
    alignSelf: "flex-start",
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_13,
    color: COLOR_BLUE_TESTWE,
    paddingBottom: PADDING_SIDES * 0.1,
    paddingLeft: PADDING_SIDES * 0.2
  },
  imageContainer: {
    alignItems: "center",
    width: 20,
    height: 15,
    paddingTop: PADDING_SIDES * 0.1
  },
  ruleContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
    width: "100%",
    flexShrink: 1
  },
  checkIcon: {
    width: 17,
    height: 11
  },
  redCrossIcon: {
    width: 12,
    height: 12
  }
});

export default ExamTakingRulesTestWe;
