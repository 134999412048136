import {
  IsOnlineAction,
  ISONLINE,
  SetNbSyncExamsAction,
  SET_NB_SYNC_EXAMS,
  StudentPaperSubmissionAction,
  START_STUDENTPAPER_SUBMISSION,
  END_STUDENTPAPER_SUBMISSION,
  RETRY_STUDENTPAPER_SUBMISSION,
  ABORT_STUDENTPAPER_SUBMISSION,
  SetSyncExamAction,
  SET_SYNC_EXAM,
  ADD_EXAM_ASYNC_ACTION,
  AddExamAsyncAction,
  DeleteExamAsyncAction,
  DELETE_EXAM_ASYNC_ACTION,
  CompareTimeWithServerAction,
  COMPARE_TIME_WITH_SERVER,
  ExitApplicationAction,
  EXITING_APPLICATION,
  CANCEL_EXITING_APPLICATION,
  SAFE_EXAM_BROWSER_NOT_FOUND,
  SafeExamBrowserNotFound,
  ToggleExamStartingAction,
  TOGGLE_STARTNG_EXAM,
  SET_EXAM_SYNC_STATUS,
  SetExamSyncStatusAction,
  SyncStatus
} from "../types/status";
import { GET_SERVER_TIMES } from "../../../../static/misc/network";
import { StudentPaperType } from "../../examTaking/types/studentPaper";

export function deleteExamAsyncElement(element: string): DeleteExamAsyncAction {
  return {
    type: DELETE_EXAM_ASYNC_ACTION,
    payload: element
  };
}

export function toggleExamStarting(val: boolean): ToggleExamStartingAction {
  return {
    type: TOGGLE_STARTNG_EXAM,
    payload: val
  };
}

export function addExamAsyncElement(element: string): AddExamAsyncAction {
  return {
    type: ADD_EXAM_ASYNC_ACTION,
    payload: element
  };
}

export function setSyncExam(bool: boolean): SetSyncExamAction {
  return {
    type: SET_SYNC_EXAM,
    payload: bool
  };
}

export function setExamsNumberToSync(index: number): SetNbSyncExamsAction {
  return {
    type: SET_NB_SYNC_EXAMS,
    payload: index
  };
}

export function isOnline(connexion: boolean): IsOnlineAction {
  return {
    type: ISONLINE,
    payload: connexion
  };
}

export function initStudentPaperSubmission(
  retry: boolean,
  studentPaper: StudentPaperType
): StudentPaperSubmissionAction {
  let type:
    | typeof START_STUDENTPAPER_SUBMISSION
    | typeof RETRY_STUDENTPAPER_SUBMISSION;
  if (retry) {
    type = RETRY_STUDENTPAPER_SUBMISSION;
  } else {
    type = START_STUDENTPAPER_SUBMISSION;
  }
  return {
    type,
    payload: {
      studentPaper
    }
  };
}

export function stopStudentPaperSubmission(
  abort: boolean,
  studentPaper: StudentPaperType
): StudentPaperSubmissionAction {
  let type:
    | typeof END_STUDENTPAPER_SUBMISSION
    | typeof ABORT_STUDENTPAPER_SUBMISSION;
  if (abort) {
    type = ABORT_STUDENTPAPER_SUBMISSION;
  } else {
    type = END_STUDENTPAPER_SUBMISSION;
  }
  return {
    type,
    payload: {
      studentPaper
    }
  };
}

export function verifyTimeWithServer(
  userToken: string
): CompareTimeWithServerAction {
  return {
    type: COMPARE_TIME_WITH_SERVER,
    payload: {
      client: "default",
      request: {
        headers: {
          accept: "application/json",
          authorization: `Bearer ${userToken}`
        },
        method: "get",
        url: GET_SERVER_TIMES
      }
    }
  };
}

export function startExitingApplication(
  action?: string
): ExitApplicationAction {
  return {
    type: EXITING_APPLICATION,
    payload: {
      action
    }
  };
}

export function cancelExitingApplication(
  action?: string
): ExitApplicationAction {
  return {
    type: CANCEL_EXITING_APPLICATION,
    payload: {
      action
    }
  };
}

export function safeExamBrowserNotFound(): SafeExamBrowserNotFound {
  return {
    type: SAFE_EXAM_BROWSER_NOT_FOUND
  };
}

export function setExamSyncStatus(
  status: SyncStatus = SyncStatus.SUCCESS
): SetExamSyncStatusAction {
  return {
    type: SET_EXAM_SYNC_STATUS,
    payload: status
  };
}
