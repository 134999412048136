import React from "react";
import { View, Text, StyleSheet } from "react-native";
import CustomModal from "../atoms/CustomModal";
import i18n from "../../services/i18n";
import { FONTSIZE_16, FONT_GILROY_BOLD } from "../../../static/misc/constants";
import { COLOR_BLUE_TESTWE } from "../../../static/misc/colors";
import Button from "./Button";

export interface ProctoringExamModalProps {
  isVisible: boolean;
  askForProctoringRoom: () => void;
}

export interface ProctoringExamModalState {
  gettingRoom: boolean;
}

export default class ProctoringExamModal extends React.Component<
  ProctoringExamModalProps,
  ProctoringExamModalState
> {
  gettingRoomTimeout = 0;

  constructor(props: ProctoringExamModalProps) {
    super(props);

    this.state = {
      gettingRoom: true
    };
  }

  componentDidMount(): void {
    const { isVisible } = this.props;
    if (isVisible) this.gettingRoom();
  }

  shouldComponentUpdate(
    nextProps: ProctoringExamModalProps,
    nextState: ProctoringExamModalState
  ): boolean {
    const { isVisible } = this.props;
    const { gettingRoom } = this.state;

    return (
      nextProps.isVisible !== isVisible || nextState.gettingRoom !== gettingRoom
    );
  }

  componentDidUpdate(prevProps: ProctoringExamModalProps): void {
    const { isVisible } = this.props;
    if (!prevProps.isVisible && isVisible) this.gettingRoom();
  }

  componentWillUnmount(): void {
    clearTimeout(this.gettingRoomTimeout);
  }

  gettingRoom(): void {
    const { askForProctoringRoom } = this.props;

    // first ask for a room
    askForProctoringRoom();

    // then setting timeout to allow retry in 30s
    this.gettingRoomTimeout = setTimeout(() => {
      this.setState({
        gettingRoom: false
      });
    }, 30000);
  }

  render(): JSX.Element {
    const { isVisible } = this.props;
    const { gettingRoom } = this.state;

    return (
      <CustomModal
        heightModal="80%"
        widthModal="80%"
        borderRadiusModal={5}
        showCloseButton={false}
        visible={isVisible}
        onCloseModal={() => null}
      >
        <View style={styles.modalContainer}>
          <Text style={styles.textContainer}>
            {i18n.t("proctoringLive.waitingRoomAgain")}
          </Text>

          {!gettingRoom ? (
            <Text style={styles.textContainer}>
              {i18n.t("proctoringLive.waitingRoomAgainRetry")}
            </Text>
          ) : null}

          <Button
            label={i18n.t("proctoringLive.waitingRoomButtonRetry")}
            disabled={gettingRoom}
            onPress={() => this.gettingRoom()}
          />
        </View>
      </CustomModal>
    );
  }
}

const styles = StyleSheet.create({
  modalContainer: {
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 50
  },
  textContainer: {
    alignSelf: "center",
    fontFamily: FONT_GILROY_BOLD,
    fontSize: FONTSIZE_16,
    color: COLOR_BLUE_TESTWE
  }
});
