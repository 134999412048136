import React from "react";
import { View, StyleSheet } from "react-native";

import _ from "lodash";
import { StudentAnswerType } from "../../../modules/examTaking/types/studentPaper";

import ExamTopbar from "./ExamTopbar";
import ExamBottombar from "./ExamBottombar";
import { TimerTypeEnum } from "../../atoms/ExamTakingTimer";
import { ExamPart, ExamType } from "../../../modules/exams/types/exam";
import { getExamPart } from "../../../services/exam-navbar-progress";
import { ChangeEnum } from "../ExamTakingContent";

interface ExamNavbarProps {
  currentExam: ExamType;
  studentAnswers: StudentAnswerType[];
  onChangePartIndex: (partIndex: number) => void;
  onChangeQuestionIndex: (questionIndex: number) => void;
  onFinishTimer: (timerType: TimerTypeEnum) => void;
  currentExamPartIndex: number;
  currentPartIndex: number;
  forceStopTimers: boolean;
  toggleRemainingDurationWarningModal(
    visible: boolean,
    changeType?: ChangeEnum,
    callback?: (changeType: ChangeEnum) => void
  ): void;
}

interface ExamNavbarState {
  selectedExamPart?: ExamPart;
}

class ExamNavbar extends React.PureComponent<ExamNavbarProps, ExamNavbarState> {
  constructor(props: ExamNavbarProps) {
    super(props);

    this.state = {
      selectedExamPart: getExamPart(0, props.currentExam)
    };
  }

  componentDidUpdate(prevProps: ExamNavbarProps): void {
    const { currentExamPartIndex, currentPartIndex, currentExam } = this.props;

    // Exam part index has changed, we should update the selected exam part
    if (
      currentExamPartIndex !== prevProps.currentExamPartIndex ||
      currentPartIndex !== prevProps.currentPartIndex
    ) {
      this.setState({
        selectedExamPart: getExamPart(currentExamPartIndex, currentExam)
      });
    }
  }

  handleOnPressExamPart = (index: number, examPart: ExamPart): void => {
    const {
      onChangePartIndex,
      currentExamPartIndex,
      currentExam,
      currentPartIndex
    } = this.props;
    const { selectedExamPart } = this.state;
    // If the exam part has chrono and status is already completed or visited
    // We shouldn't allow the user to change the part
    const isAlreadyVisited =
      examPart.status === "completed" || examPart.status === "initial";
    const hasChronoInExamPart = this.hasChronoInExamPart(examPart);
    const durationFixed = currentExam.examParams?.durationFixed ?? false;

    const currentIndex = getExamPart(currentExamPartIndex, currentExam)
      ?.partIndexes[currentPartIndex];
    const hasChronoInAllQuestions = examPart.partIndexes.every((ei) => {
      return (
        (ei.question?.durationLimit === true &&
          (ei.question?.remainingDuration || 0) >= 0) ||
        (ei.exercise?.durationLimit === true &&
          (ei.exercise?.remainingDuration || 0) >= 0)
      );
    });

    const currentQuestionIsLastQuestion =
      selectedExamPart &&
      currentIndex?.position === selectedExamPart.partIndexes.length - 1;

    const durationFixedAndIsAlreadyVisited =
      durationFixed &&
      isAlreadyVisited &&
      (hasChronoInAllQuestions || hasChronoInExamPart);

    const currentQuestionIsNotLastQuestionAndHasChrono =
      (hasChronoInAllQuestions || hasChronoInExamPart) &&
      !currentQuestionIsLastQuestion;

    // FIXME: This is a temporary fix for the issue where user can change the part when the duration is fixed
    // but question view is not updated
    const currentQuestionIsNotLastQuestionAndHasChronoWithFixedDuration =
      (hasChronoInAllQuestions || hasChronoInExamPart) &&
      durationFixed &&
      !currentQuestionIsLastQuestion;

    if (
      currentQuestionIsNotLastQuestionAndHasChrono ||
      durationFixedAndIsAlreadyVisited ||
      currentQuestionIsNotLastQuestionAndHasChronoWithFixedDuration
    ) {
      return;
    }

    if (!_.isEqual(selectedExamPart, examPart)) {
      this.setState({ selectedExamPart: examPart });

      onChangePartIndex(index);
    }
  };

  hasChronoInExamPart = (examPart: ExamPart): boolean => {
    return (
      examPart.durationLimit === true && (examPart.remainingDuration || 0) >= 0
    );
  };

  render(): JSX.Element {
    const { selectedExamPart } = this.state;
    const {
      currentExam,
      studentAnswers,
      onFinishTimer,
      currentExamPartIndex,
      currentPartIndex,
      onChangeQuestionIndex,
      forceStopTimers,
      toggleRemainingDurationWarningModal
    } = this.props;
    return (
      <View style={styles.container}>
        <ExamTopbar
          selectedExamPart={selectedExamPart}
          currentExam={currentExam}
          studentAnswers={studentAnswers}
          currentPartIndex={currentPartIndex}
          forceStopTimers={forceStopTimers}
          currentExamPartIndex={currentExamPartIndex}
          onFinishTimer={onFinishTimer}
          toggleRemainingDurationWarningModal={
            toggleRemainingDurationWarningModal
          }
          onChangePartIndex={this.handleOnPressExamPart}
        />

        <ExamBottombar
          currentExam={currentExam}
          studentAnswers={studentAnswers}
          selectedExamPart={selectedExamPart}
          currentPartIndex={currentPartIndex}
          onChangeQuestionIndex={onChangeQuestionIndex}
          toggleRemainingDurationWarningModal={
            toggleRemainingDurationWarningModal
          }
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: 8
  }
});

export default ExamNavbar;
