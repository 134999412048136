import { ipcRenderer } from "electron";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { COLOR_BLUE_TESTWE, COLOR_WHITE } from "../../../../static/misc/colors";
import {
  FONT_GILROY_REGULAR,
  FONT_GILROY_SEMI_BOLD,
  PADDING_SIDES
} from "../../../../static/misc/constants";
import { MainNameUserProps } from "../../../modules/main/types/MainTypes";
import { CompareTimeWithServerAction } from "../../../modules/main/types/status";
import i18n from "../../../services/i18n";
import CustomModal from "../../atoms/CustomModal";
import WelcomeTxt from "../../atoms/WelcomeTxt";
import Button from "../../molecules/Button";

interface ServerTimeSynchronisationDesktopModalProps extends MainNameUserProps {
  isVisible: boolean;
  token: string | undefined;
  verifyTimeWithServer(userToken: string): CompareTimeWithServerAction;
}

export default class ServerTimeSynchronisationDesktopModal extends React.PureComponent<ServerTimeSynchronisationDesktopModalProps> {
  render(): JSX.Element {
    const {
      isVisible,
      givenName,
      familyName,
      token,
      verifyTimeWithServer
    } = this.props;
    return (
      <CustomModal
        heightModal="100vh"
        widthModal="100vw"
        borderRadiusModal={0}
        visible={isVisible}
        showCloseButton={false}
        onCloseModal={() => undefined}
      >
        <View style={styles.container}>
          <WelcomeTxt
            regularTxt={`${i18n.t("homepage.hello")} `}
            boldTxt={`${givenName} ${familyName}`}
          />
          <Text style={styles.title}>{i18n.t("homepage.wrongTimeTitle")}</Text>
          <Text style={styles.description}>
            {i18n.t("homepage.wrongTimeDescription")}
          </Text>
          <Text style={styles.description2}>
            {i18n.t("homepage.wrongTimeDescription2")}
          </Text>
          <View style={styles.buttonContainer}>
            <Button
              label={i18n.t("homepage.showPreferences")}
              onPress={() => {
                ipcRenderer.send("OPEN_SYSTEM_PREFERENCES");
              }}
              containerStyle={[styles.button]}
              textStyle={[styles.buttonText]}
            />
            <Button
              label={i18n.t("homepage.wrongTimeCheckAgain")}
              onPress={() => {
                verifyTimeWithServer(token || "");
              }}
              containerStyle={[styles.button]}
              textStyle={[styles.buttonText]}
            />
          </View>
        </View>
      </CustomModal>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column"
  },
  title: {
    color: COLOR_BLUE_TESTWE,
    fontFamily: FONT_GILROY_SEMI_BOLD,
    fontSize: 25,
    margin: PADDING_SIDES * 2,
    alignSelf: "center",
    textAlignVertical: "center",
    textAlign: "center"
  },
  description: {
    color: COLOR_BLUE_TESTWE,
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: 19,
    marginHorizontal: PADDING_SIDES * 2.5,
    alignSelf: "center",
    textAlignVertical: "center",
    textAlign: "center"
  },
  description2: {
    color: COLOR_BLUE_TESTWE,
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: 19,
    marginBottom: PADDING_SIDES * 2,
    marginHorizontal: PADDING_SIDES * 2.5,
    alignSelf: "center",
    textAlignVertical: "center",
    textAlign: "center"
  },
  button: {
    margin: "20px",
    borderColor: COLOR_BLUE_TESTWE,
    backgroundColor: COLOR_BLUE_TESTWE,
    width: "max-content"
  },
  buttonText: {
    color: COLOR_WHITE,
    fontSize: 19
  },
  buttonContainer: {
    width: "fit-content",
    display: "flex",
    textAlign: "center",
    flexDirection: "row",
    alignSelf: "center",
    textAlignVertical: "center"
  }
});
