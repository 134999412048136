import React, { PureComponent } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import {
  COLOR_BLUE_TESTWE,
  COLOR_BLUE_TESTWE_30,
  COLOR_GREEN_VALID,
  COLOR_GREY_EXAM_NAVBAR_COLOR_CODE,
  COLOR_WHITE,
  COLOR_YELLOW_INFO
} from "../../../../static/misc/colors";
import { playIcon } from "../../../../static/misc/images";

import i18n from "../../../services/i18n";
import Icon from "../../atoms/Icon";
import Tooltip from "../../atoms/Tooltip";
import { ChangeEnum } from "../ExamTakingContent";
import { ExamQuestionExtended } from "./ExamBottombar";

interface ExamQuestionComponentProps {
  question: ExamQuestionExtended;
  hasCompleted?: boolean;
  hasChrono?: boolean;
  hasBookmarked?: boolean;
  hasVisited?: boolean;
  isCurrent: boolean;
  isFirst: boolean;
  isLast: boolean;
  index: number;
  durationFixed?: boolean;
  onPress: (index: number) => void;
  exerciseIndex?: number;
  toggleRemainingDurationWarningModal(
    visible: boolean,
    changeType?: ChangeEnum,
    callback?: (changeType: ChangeEnum) => void
  ): void;
  // eslint-disable-next-line react/no-unused-prop-types
  isLastQuestionInExamPart: boolean;
  currentPartIndex: number;
}

const Rectangle = ({
  color,
  isFirst,
  isLast
}: {
  color: string;
  isFirst: boolean;
  isLast: boolean;
}) => (
  <View
    style={[
      styles.rectangle,
      { backgroundColor: color },
      isFirst && {
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
        marginLeft: 0
      },
      isLast && {
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
        marginRight: 0
      }
    ]}
  />
);

const Circle = ({ color }: { color?: string }) => (
  <View style={[styles.circle, { backgroundColor: color }]}>
    <Icon icon={playIcon} />
  </View>
);

class ExamQuestionComponent extends PureComponent<ExamQuestionComponentProps> {
  getCurrentColor(
    isCurrent?: boolean,
    hasBookmarked?: boolean,
    hasCompleted?: boolean,
    hasVisited?: boolean
  ): string {
    if (hasBookmarked) return COLOR_YELLOW_INFO;

    if (isCurrent && !hasCompleted) return COLOR_BLUE_TESTWE;

    if (hasCompleted) return COLOR_GREEN_VALID;

    if (hasVisited) return COLOR_BLUE_TESTWE_30;

    // init
    return COLOR_GREY_EXAM_NAVBAR_COLOR_CODE;
  }

  onPressIntermediate = (): void => {
    const {
      question,
      onPress,
      hasChrono,
      toggleRemainingDurationWarningModal,
      currentPartIndex,
      durationFixed
    } = this.props;

    if (durationFixed && hasChrono) {
      return;
    }

    if (hasChrono) {
      const shouldShowWarning = question.position - currentPartIndex === 1;
      toggleRemainingDurationWarningModal(
        shouldShowWarning,
        ChangeEnum.NEXTPART,
        () => {
          onPress(question.position);
        }
      );
    }

    if (!hasChrono) {
      onPress(question.position);
    }
  };

  buildTooltipContent(
    index: number,
    question: ExamQuestionExtended,
    exerciseIndex?: number
  ): string {
    if (question.isQuestionInExercise) {
      return i18n.t(
        "examNavbar.examBottombar.questionComponentTooltipExercise",
        {
          exerciseIndex,
          positionInExercise: (question.positionInExercise || 0) + 1
        }
      );
    }

    return i18n.t("examNavbar.examBottombar.questionComponentTooltip", {
      count: index
    });
  }

  render(): JSX.Element {
    const {
      question,
      isFirst,
      isLast,
      isCurrent,
      hasBookmarked,
      hasCompleted,
      hasVisited,
      index,
      exerciseIndex
    } = this.props;

    return question ? (
      <Tooltip
        placement="bottom"
        contentText={this.buildTooltipContent(index, question, exerciseIndex)}
        tooltipViewStyle={{ background: COLOR_WHITE }}
      >
        <TouchableOpacity
          style={styles.container}
          onPress={this.onPressIntermediate}
        >
          <Rectangle
            isFirst={isFirst}
            isLast={isLast}
            color={this.getCurrentColor(
              isCurrent,
              hasBookmarked,
              hasCompleted,
              hasVisited
            )}
          />
          {isCurrent && (
            <Circle
              color={this.getCurrentColor(
                isCurrent,
                hasBookmarked,
                hasCompleted,
                hasVisited
              )}
            />
          )}
        </TouchableOpacity>
      </Tooltip>
    ) : (
      <></>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    width: 40,
    marginHorizontal: 4,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  rectangle: {
    width: 40,
    height: 10
  },
  circle: {
    width: 20,
    height: 20,
    borderRadius: 10,

    position: "absolute",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  }
});

export default ExamQuestionComponent;
