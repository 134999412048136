import React from "react";
import { View, StyleSheet } from "react-native";
import GridMultipleColumn from "./GridMultipleColumn";
import GridRow from "../molecules/GridRow";
import {
  GridLabel,
  ExamChoice,
  ChoiceGridWithSelection
} from "../../modules/exams/types/exam";

export interface GridMultipleQuestionProps {
  changeSelection: (
    rowItems: ChoiceGridWithSelection[][],
    choices: Array<ExamChoice>,
    columnLabels: GridLabel[]
  ) => void;
  columnLabels: GridLabel[];
  rowLabels: GridLabel[];
  previousAnswer?: Array<string>;
  givenChoices?: Array<string>;
  choices: ExamChoice[];
}

class GridMultipleQuestion extends React.PureComponent<GridMultipleQuestionProps> {
  render(): JSX.Element {
    const {
      columnLabels,
      rowLabels,
      choices,
      previousAnswer,
      changeSelection,
      givenChoices
    } = this.props;
    const rowName = rowLabels.map((item) => {
      return item.name;
    });
    return (
      <View style={styles.gridMultipleQuestionStyle}>
        <GridRow rowLabels={rowName} />
        <GridMultipleColumn
          previousAnswer={previousAnswer}
          givenChoices={givenChoices}
          columns={columnLabels}
          rows={rowLabels}
          choices={choices}
          changeSelection={(
            rowItems: ChoiceGridWithSelection[][],
            choicesList: ExamChoice[],
            columns: GridLabel[]
          ) => changeSelection(rowItems, choicesList, columns)}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  gridMultipleQuestionStyle: {
    flexDirection: "row"
  }
});
export default GridMultipleQuestion;
