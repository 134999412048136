import React from "react";
import { ipcRenderer } from "electron";
import YellowBanner from "./YellowBanner";
import { BannerItemsStatus } from "../../modules/main/types/persistedStatus";

export type BannerItemType = {
  key: "network" | "ram" | "antivirus";
  text: string;
  tooltip?: string;
  visible: boolean;
};

interface AnimatedBannerProps {
  intervalDelayInMs?: number;
  items: BannerItemType[];
  setBannerItemsStatus: (items: BannerItemsStatus) => void;
}

interface AnimatedBannerState {
  currentItem?: BannerItemType;
}

class AnimatedBanner extends React.PureComponent<
  AnimatedBannerProps,
  AnimatedBannerState
> {
  interval?: NodeJS.Timer;

  static defaultProps = {
    intervalDelayInMs: 4000
  };

  constructor(props: AnimatedBannerProps) {
    super(props);

    const { items } = props;

    const onlyVisibleItems = items.filter((i) => i.visible);

    this.state = {
      currentItem: onlyVisibleItems[0]
    };
  }

  componentDidUpdate(prevProps: AnimatedBannerProps): void {
    const { items } = this.props;

    if (prevProps.items !== items) {
      this.clearAnimationInterval();
      this.initializeAnimationInterval();
    }
  }

  componentWillUnmount(): void {
    this.clearAnimationInterval();
  }

  // Create interval between each text banner
  initializeAnimationInterval = (): void => {
    const { items, intervalDelayInMs } = this.props;

    let i = 0;

    this.interval = setInterval(() => {
      const onlyVisibleItems = [...items.filter((item) => item.visible)];

      if (onlyVisibleItems.length === 0) {
        this.setState({
          currentItem: undefined
        });
        this.clearAnimationInterval();
        return;
      }

      if (onlyVisibleItems.length === 1) {
        this.setState({
          currentItem: onlyVisibleItems[0]
        });

        return;
      }

      if (onlyVisibleItems.length > 1) {
        if (i >= onlyVisibleItems.length) {
          i = 0;
        }

        const currentItem = onlyVisibleItems[i];

        if (currentItem && currentItem.visible) {
          this.setState({
            currentItem
          });

          i++;
        }
      }
    }, intervalDelayInMs);
  };

  clearAnimationInterval = (): void => {
    if (this.interval) clearInterval(this.interval);
  };

  onCloseBtnPress = (): void => {
    const { setBannerItemsStatus } = this.props;

    ipcRenderer.send("LOG_INFO", `BANNER | Antivirus banner has been closed`);

    setBannerItemsStatus({
      network: false,
      antivirus: true,
      ram: false
    });
  };

  render(): JSX.Element {
    const { currentItem } = this.state;
    return currentItem && currentItem.text.length > 0 ? (
      <YellowBanner
        textBanner={currentItem.text}
        withTooltip={!!currentItem.tooltip && currentItem.tooltip.length > 0}
        tooltipText={currentItem.tooltip}
        withCloseBtn={currentItem.key === "antivirus"}
        onCloseBtn={this.onCloseBtnPress}
        containerStyle={{
          width: "100%",
          borderRadius: 0
        }}
      />
    ) : (
      <></>
    );
  }
}

export default AnimatedBanner;
