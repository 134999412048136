import React from "react";
import TinyEditor from "./TinyEditor";

export type TypingContent = {
  content: string;
  htmlContent: string;
};

interface TinyBlankProps {
  onTyping: (content: string) => void;
  previousAnswer?: string;
  width?: string | number;
  height?: string | number;
}

interface TinyBlankState {
  content: string;
}

export default class TinyBlank extends React.Component<
  TinyBlankProps,
  TinyBlankState
> {
  // FIXME: Find real type for editor
  editor?: any;

  static defaultProps = {
    width: "100%",
    height: "100%"
  };

  constructor(props: TinyBlankProps) {
    super(props);
    const { previousAnswer } = this.props;
    this.state = {
      content: previousAnswer || ""
    };

    this.handleEditorChange = this.handleEditorChange.bind(this);
  }

  componentWillUnmount(): void {
    if (this.editor) {
      this.editor
        .getBody()
        .querySelectorAll("input")
        .forEach((el: Element) => {
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          el.removeEventListener("input", () => {});

          // eslint-disable-next-line @typescript-eslint/no-empty-function
          el.removeEventListener("mouseout", () => {});
        });

      this.editor = undefined;
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  handleEditorChange(content: string, editor: any): void {
    const { onTyping } = this.props;

    this.editor = editor;

    this.editor
      .getBody()
      .querySelectorAll("input")
      .forEach((el: Element) => {
        el.addEventListener("change", (event: any) =>
          this.setInputAttrValue({ event, content })
        );

        // If the user leaves the input, we need to update the content
        // There is a delay in the update, so if the user writes something and leaves the input really fast,
        // the content will not be updated so we need to do it manually using the mouseout event
        el.addEventListener("mouseout", (event: any) =>
          this.setInputAttrValue({ event, content })
        );
      });

    onTyping(content);
    this.setState({ content });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setInputAttrValue({ event, content }: { event: any; content: string }): void {
    const input = event.target;

    if (content !== input.value) {
      input.setAttribute("value", input.value);
    }
  }

  render(): JSX.Element {
    const { content } = this.state;
    const { width, height } = this.props;
    return (
      <TinyEditor
        init={{
          height,
          width,
          // FIXME: Find real type for editor
          setup(editor: any) {
            editor.on("keydown", (e: any) => {
              if (e.target.nodeName.toLowerCase() !== "input") {
                e.preventDefault();
              }
            });
          },
          menubar: false,
          toolbar: false
        }}
        value={content}
        onEditorChange={this.handleEditorChange}
      />
    );
  }
}
