import * as React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { RouteProp } from "@react-navigation/native";
import ExamTakingContainer from "../modules/examTaking/ExamTakingContainer";
import ExamInfoContainer from "../modules/exams/ExamInfoContainer";

export type ExamTakingSettings = {
  isTraining?: boolean;
  isWsAuth?: boolean;
  examId: string;
};

export type ExamInfoSettings = {
  examInfoId?: string;
};

export type ExamFlowParamList = {
  ExamTaking?: ExamTakingSettings;
  ExamInfo?: ExamInfoSettings;
};

export type ExamTakingRouteProp = RouteProp<ExamFlowParamList, "ExamTaking">;
export type ExamInfoRouteProp = RouteProp<ExamFlowParamList, "ExamInfo">;

const ExamFlowStack = createStackNavigator<ExamFlowParamList>();

export default function ExamFlow(): JSX.Element {
  return (
    <ExamFlowStack.Navigator headerMode="none">
      <ExamFlowStack.Screen
        name="ExamInfo"
        key="ExamInfo"
        component={ExamInfoContainer}
        options={{ title: "TestWe" }}
      />
      <ExamFlowStack.Screen
        name="ExamTaking"
        key="ExamTaking"
        component={ExamTakingContainer}
        options={{ title: "TestWe" }}
      />
    </ExamFlowStack.Navigator>
  );
}
