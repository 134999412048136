/* eslint-disable react/no-unused-state */
import React from "react";
import { View, StyleSheet, Text } from "react-native";
import moment from "moment";
import {
  CORRECTED,
  CORRECTION_COMPLETED,
  CORRECTION_NOT_SHARED,
  CORRECTION_SHARED,
  FONTSIZE_14,
  FONT_GILROY_BOLD,
  FONT_GILROY_REGULAR,
  IN_CORRECTION,
  NOT_REALIZED,
  NOT_SYNCRO,
  PADDING_SIDES,
  PROCTOR_NOT_SEND,
  SENT,
  TOINTEGRATE
} from "../../../static/misc/constants";
import { guidGenerator } from "../../../static/misc/utils";
import ListColumnItem from "../atoms/ListColumnItem";
import { DisplayedExamsListProps } from "./ExamsList";
import {
  COLOR_BLUE,
  COLOR_BLUE_TESTWE,
  COLOR_GREEN_VALID,
  COLOR_GREY_BACKGROUND,
  COLOR_RED,
  COLOR_YELLOW_INFO
} from "../../../static/misc/colors";
import SeeExam from "./SeeExam";
import i18n from "../../services/i18n";
import Label from "../atoms/Label";
import { checkWhite } from "../../../static/misc/images";
import ExamComment from "../molecules/ExamComment";
import { ExamType } from "../../modules/exams/types/exam";

class PassedExamsList extends React.PureComponent<DisplayedExamsListProps> {
  // Displaying the current status of a passed exam
  displayStatus(
    exam: ExamType,
    status: string | undefined
  ): JSX.Element | null {
    const displayedStatus =
      exam.myStudentPaper?.status !== CORRECTED &&
      status !== CORRECTION_COMPLETED &&
      status !== TOINTEGRATE
        ? status
        : exam.examSharingStatus &&
          exam.examSharingStatus !== CORRECTION_NOT_SHARED &&
          exam.examSharingStatus !== TOINTEGRATE &&
          exam.myStudentPaper?.totalPoints !== undefined
        ? status
        : SENT;

    switch (displayedStatus) {
      case PROCTOR_NOT_SEND:
        return (
          <Label
            text={i18n.t("label.incomplete")}
            backgroundColor={COLOR_YELLOW_INFO}
          />
        );
      case IN_CORRECTION:
      case SENT: {
        return (
          <Label
            text={i18n.t("label.sent")}
            backgroundColor={COLOR_GREEN_VALID}
            icon={checkWhite}
            containerStyle={styles.labelContainer}
          />
        );
      }
      case CORRECTION_COMPLETED:
      case CORRECTED: {
        return (
          <Label
            text={i18n.t("label.corrected")}
            backgroundColor={COLOR_BLUE_TESTWE}
            containerStyle={styles.labelContainer}
          />
        );
      }
      case NOT_SYNCRO: {
        return (
          <Label
            text={i18n.t("label.notsync")}
            backgroundColor={COLOR_YELLOW_INFO}
            containerStyle={styles.labelContainer}
          />
        );
      }
      case NOT_REALIZED: {
        return (
          <Label
            text={i18n.t("label.notrealized")}
            backgroundColor={COLOR_RED}
            containerStyle={styles.labelContainer}
          />
        );
      }
      default: {
        return (
          <Label
            text={i18n.t("label.notrealized")}
            backgroundColor={COLOR_RED}
            containerStyle={styles.labelContainer}
          />
        );
      }
    }
  }

  render(): JSX.Element {
    const {
      token,
      exams,
      columnsTitlesAndWidth,
      rowStyle,
      borderStyle,
      getMyPaperBase64
    } = this.props;

    return (
      <View style={styles.view}>
        {exams &&
          exams.length > 0 &&
          exams.map((exam, index) => {
            return (
              <View
                style={[
                  rowStyle,
                  index < exams.length - 1 ? borderStyle : null
                ]}
                key={`row-${exam.id}`}
              >
                <ListColumnItem
                  key={guidGenerator()}
                  text={moment(exam.startDate).format("DD/MM/YYYY")}
                  viewStyle={[
                    { width: columnsTitlesAndWidth[0].width },
                    styles.itemView
                  ]}
                  textStyle={[styles.text]}
                  textSetNbOfLines={-1}
                />
                <ListColumnItem
                  key={guidGenerator()}
                  text={exam.subject?.name || ""}
                  viewStyle={[
                    { width: columnsTitlesAndWidth[1].width },
                    styles.itemView
                  ]}
                  textStyle={[styles.text]}
                  textSetNbOfLines={-1}
                />
                <ListColumnItem
                  key={guidGenerator()}
                  text={exam.name}
                  viewStyle={[
                    { width: columnsTitlesAndWidth[2].width },
                    styles.itemView
                  ]}
                  textStyle={[styles.text, styles.boldText]}
                  textSetNbOfLines={-1}
                />
                <ListColumnItem
                  key={guidGenerator()}
                  text={
                    exam.examSharingStatus !== CORRECTION_NOT_SHARED &&
                    exam.myStudentPaper?.totalPoints !== undefined
                      ? `${exam.myStudentPaper?.totalPoints?.toString()}/${
                          exam.maxPoints
                        }`
                      : "..."
                  }
                  viewStyle={[
                    { width: columnsTitlesAndWidth[3].width },
                    styles.itemView
                  ]}
                  textStyle={[styles.text]}
                  textSetNbOfLines={-1}
                />
                <View key={guidGenerator()}>
                  <ExamComment
                    textStyle={[styles.text]}
                    textSetNbOfLines={1}
                    text={
                      exam.examSharingStatus === CORRECTION_SHARED &&
                      exam.myStudentPaper?.appreciation
                        ? exam.myStudentPaper?.appreciation
                        : "..."
                    }
                  />
                </View>
                <View
                  key={guidGenerator()}
                  style={[
                    { width: columnsTitlesAndWidth[5].width },
                    styles.itemView
                  ]}
                >
                  {this.displayStatus(
                    exam,
                    exam.myStudentPaper
                      ? exam.myStudentPaper.proctorArchiveIssue ||
                          exam.myStudentPaper?.status
                      : NOT_REALIZED
                  )}
                </View>
                <View
                  key={`container-see-${exam.id}`}
                  style={[
                    {
                      width: columnsTitlesAndWidth[6].width,
                      alignContent: "center"
                    },
                    styles.itemView
                  ]}
                >
                  {getMyPaperBase64 && (
                    <SeeExam
                      key={`see-${exam.id}`}
                      getMyPaperBase64={() =>
                        getMyPaperBase64(
                          exam.myStudentPaper?.id || "",
                          token || "",
                          exam.id
                        )
                      }
                      base64pdf={
                        exam.examSharingStatus === CORRECTION_SHARED
                          ? exam.myStudentPaper?.base64
                          : undefined
                      }
                      blobUrl={
                        exam.examSharingStatus === CORRECTION_SHARED
                          ? exam.myStudentPaper?.blobUrl
                          : undefined
                      }
                      isUsable={
                        exam.examSharingStatus === CORRECTION_SHARED &&
                        exam.myStudentPaper?.status !== undefined
                      }
                      examId={exam.myStudentPaper?.id || ""}
                    />
                  )}
                </View>
              </View>
            );
          })}
        {exams && exams.length === 0 && (
          <View style={[styles.view, rowStyle, styles.textCenter]}>
            <Text style={[styles.text]}>{i18n.t("exam.noexamsavailable")}</Text>
          </View>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  view: {
    backgroundColor: COLOR_GREY_BACKGROUND,
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    paddingHorizontal: PADDING_SIDES * 0.5,
    flexGrow: 1
  },
  itemView: {
    paddingHorizontal: PADDING_SIDES * 0.3
  },
  labelContainer: {
    flexDirection: "row",
    paddingHorizontal: PADDING_SIDES * 0.05
  },
  text: {
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_14,
    color: COLOR_BLUE_TESTWE
  },
  boldText: {
    fontFamily: FONT_GILROY_BOLD,
    color: COLOR_BLUE
  },
  textCenter: {
    justifyContent: "center"
  }
});

export default PassedExamsList;
