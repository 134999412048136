import {
  EQUIPMENT_CHECK,
  EQUIPMENT_CHECK_ID
} from "../../../../static/misc/network";
import {
  EquipmentCheck,
  GetLastestEquipmentCheck,
  GET_LATEST_EQUIPMENT_CHECK,
  PutLastestEquipmentCheck,
  PUT_LATEST_EQUIPMENT_CHECK
} from "../types/equipmentCheck";

export function getLatestEquipmentCheck(
  userToken: string,
  equipmentId: string
): GetLastestEquipmentCheck {
  return {
    type: GET_LATEST_EQUIPMENT_CHECK,
    payload: {
      client: "default",
      request: {
        headers: {
          accept: "application/json",
          authorization: `Bearer ${userToken}`
        },
        method: "get",
        url: EQUIPMENT_CHECK_ID(equipmentId)
      }
    }
  };
}

export function postLatestEquipmentCheck(
  userToken: string,
  equipmentCheck: EquipmentCheck
): PutLastestEquipmentCheck {
  return {
    type: PUT_LATEST_EQUIPMENT_CHECK,
    payload: {
      client: "default",
      request: {
        headers: {
          accept: "application/json",
          authorization: `Bearer ${userToken}`
        },
        method: "post",
        url: EQUIPMENT_CHECK,
        data: {
          ...equipmentCheck
        }
      }
    }
  };
}
