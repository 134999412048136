import React from "react";
import { StyleSheet, ScrollView, View } from "react-native";
import {
  COLOR_BLUE_LIGHTER,
  COLOR_BLUE_TESTWE
} from "../../../static/misc/colors";
import { ExamParam } from "../../modules/exams/types/exam";
import ExamTakingRules from "../molecules/ExamTakingRules";
import ExamTakingRulesTestWe from "../atoms/ExamTakingRulesTestWe";
import ExamTakingRulesChrono from "../atoms/ExamTakingRulesChrono";
import Button from "../molecules/Button";
import { crossIcon } from "../../../static/misc/images";
import { WIDTH_3 } from "../../../static/misc/constants";

interface ExamTakingAllRulesProps {
  examParams?: ExamParam;
  hasTimer: boolean;
  onClose?: () => void;
}

class ExamTakingAllRules extends React.PureComponent<ExamTakingAllRulesProps> {
  render(): JSX.Element {
    const { examParams, hasTimer, onClose } = this.props;

    return (
      <View style={onClose ? styles.viewModalStyle : styles.viewStyle}>
        {onClose && (
          <Button
            icon={crossIcon}
            onPress={() => onClose()}
            iconStyle={styles.iconStyle}
            containerStyle={[styles.containerStyle]}
          />
        )}
        <ScrollView contentContainerStyle={{ flex: 1 }}>
          <ExamTakingRulesTestWe />
          {examParams && <ExamTakingRules rules={examParams} />}
          {hasTimer && <ExamTakingRulesChrono />}
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  iconStyle: {
    left: 4,
    height: 13,
    width: 13
  },
  containerStyle: {
    borderColor: COLOR_BLUE_TESTWE,
    backgroundColor: COLOR_BLUE_TESTWE,
    zIndex: 90,
    position: "absolute",
    top: 0,
    right: 0,
    paddingHorizontal: 0,
    paddingVertical: 0,
    borderRadius: 0,
    borderTopRightRadius: 10,
    width: 32,
    height: 32
  },
  viewModalStyle: {
    borderRadius: 10,
    width: "100%",
    backgroundColor: COLOR_BLUE_LIGHTER,
    position: "relative"
  },
  viewStyle: {
    width: WIDTH_3,
    backgroundColor: COLOR_BLUE_LIGHTER,
    position: "relative"
  }
});

export default ExamTakingAllRules;
