import { calc, horse, keyboard, logoutIcon, medias, qrcode } from "./images";

// MEDIA_MENU
export const EXAM = "exam";
export const PART = "part";
export const QUESTION = "question";

export const MEDIA_MENU = [
  {
    id: EXAM,
    label: "media.exam"
  },
  {
    id: PART,
    label: "media.part"
  },
  {
    id: QUESTION,
    label: "media.eNq"
  }
];

// HEADER_MENU
export const HOMEPAGE = "Main";
export const EXAMSLIST = "Exams";
export const TRAINING = "Training";
export const TESTING = "Testing";

export const HEADER_MENU = [
  {
    id: HOMEPAGE,
    show: true,
    label: "homepage.homepage"
  },
  {
    id: EXAMSLIST,
    show: true,
    label: "homepage.header.exams"
  },
  {
    id: TRAINING,
    show: true,
    label: "homepage.header.train"
  },
  {
    id: TESTING,
    show: true,
    label: "homepage.header.testing"
  }
];

// EXAM TAKING LEFT MENU

export const RULES = "rules";
export const CALC = "calc";
export const MEDIAS = "medias";
export const KEYBOARD = "keyboard";
export const QRCODE = "qrcode";
export const LOGOUT = "logout";

export const EXAM_TAKING_LEFT_MENU = [
  {
    id: RULES,
    show: true,
    label: "examTaking.menuLeft.rules",
    icon: horse
  },
  {
    id: CALC,
    show: false,
    label: "examTaking.menuLeft.cal",
    icon: calc
  },
  {
    id: MEDIAS,
    show: true,
    label: "examTaking.menuLeft.med",
    icon: medias
  },
  {
    id: KEYBOARD,
    show: false,
    label: "examTaking.menuLeft.keyboard",
    icon: keyboard
  },
  {
    id: QRCODE,
    show: false,
    label: "examTaking.menuLeft.qr",
    icon: qrcode
  },
  {
    id: LOGOUT,
    show: true,
    label: "userMenu.leave",
    icon: logoutIcon
  }
];
