import React from "react";
import { TouchableOpacity, StyleSheet, Text, View } from "react-native";
import { COLOR_BLUE_TESTWE } from "../../../static/misc/colors";
import {
  FONTSIZE_14,
  FONT_GILROY_BOLD,
  FONT_GILROY_REGULAR,
  PADDING_SIDES
} from "../../../static/misc/constants";
import RadioButton from "../atoms/RadioButton";

export interface ExamInfoAgreementProps {
  hasAgreed: boolean;
  link?: string;
  text: string;
  onPress: () => void;
}

class ExamInfoAgreement extends React.PureComponent<ExamInfoAgreementProps> {
  render(): JSX.Element {
    const { hasAgreed, link, text, onPress } = this.props;
    return (
      <View>
        <TouchableOpacity
          onPress={() => onPress()}
          style={styles.agreementContainer}
        >
          <RadioButton isSelected={hasAgreed} />
          <Text style={styles.agreementText}>
            {text} &nbsp;
            {link && <Text style={styles.agreementBold}>{link}</Text>}
          </Text>
        </TouchableOpacity>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  agreementContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingBottom: PADDING_SIDES * 0.2
  },
  agreementText: {
    color: COLOR_BLUE_TESTWE,
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_14,
    paddingLeft: PADDING_SIDES * 0.1
  },
  agreementBold: {
    fontFamily: FONT_GILROY_BOLD
  }
});

export default ExamInfoAgreement;
