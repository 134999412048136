import React from "react";

import { StyleProp, View, ViewStyle } from "react-native";
import { guidGenerator } from "../../../static/misc/utils";
import ExamInfoDetailItem, {
  ExamInfoDetailItemProps
} from "../atoms/ExamInfoDetailItem";

export interface ExamInfoDetailsProps {
  details: ExamInfoDetailItemProps[];
  containerStyle?: StyleProp<ViewStyle>;
}

class ExamInfoDetails extends React.PureComponent<ExamInfoDetailsProps> {
  render(): JSX.Element {
    const { containerStyle, details } = this.props;
    return (
      <View style={containerStyle}>
        {details.map((detail) => {
          return (
            <ExamInfoDetailItem
              key={guidGenerator()}
              label={detail.label}
              value={detail.value}
            />
          );
        })}
      </View>
    );
  }
}

export default ExamInfoDetails;
