import React from "react";
import {
  View,
  Text,
  StyleSheet,
  ImageSourcePropType,
  TouchableOpacity
} from "react-native";
import {
  COLOR_BLUE_LIGHT,
  COLOR_BLUE_TESTWE,
  COLOR_DARK_GREY,
  COLOR_WHITE
} from "../../../static/misc/colors";
import {
  FONTSIZE_10,
  FONT_GILROY_MEDIUM,
  PADDING_SIDES
} from "../../../static/misc/constants";
import { guidGenerator } from "../../../static/misc/utils";
import Icon from "../atoms/Icon";
import i18n from "../../services/i18n";

export interface ExamTakingLeftNavigation {
  id: string;
  show: boolean;
  label: string;
  icon: ImageSourcePropType;
}

export interface ExamTakingMenuLeftState {
  toggledItems: number[];
}

interface ExamTakingMenuLeftProps {
  items: ExamTakingLeftNavigation[];
  shouldDisplayMedias: boolean;
  callback: (index: number) => void;
}

class ExamTakingMenuLeft extends React.Component<
  ExamTakingMenuLeftProps,
  ExamTakingMenuLeftState
> {
  constructor(props: ExamTakingMenuLeftProps) {
    super(props);

    this.state = {
      toggledItems: []
    };
  }

  componentDidMount(): void {
    const { shouldDisplayMedias } = this.props;

    // Display the media panel by default if there are medias
    if (shouldDisplayMedias) {
      this.onToggle(2);
    }
  }

  // Triggered when toggling an element from the left menu
  onToggle(index: number): void {
    const { callback } = this.props;
    const { toggledItems } = this.state;
    const tmpToggledItems = [...toggledItems];
    const idxof = tmpToggledItems.indexOf(index);

    if (idxof > -1) {
      tmpToggledItems.splice(idxof, 1);
    } else {
      tmpToggledItems.push(index);
    }

    this.setState(
      {
        toggledItems: tmpToggledItems
      },
      () => {
        if (index !== undefined && index >= 0) {
          callback(index);
        }
      }
    );
  }

  render(): JSX.Element {
    const { items } = this.props;
    const { toggledItems } = this.state;

    return (
      <View style={[styles.viewStyle]}>
        {items.map((item, index) => {
          if (item.show) {
            return (
              <TouchableOpacity
                key={guidGenerator()}
                onPress={() => this.onToggle(index)}
                style={[
                  styles.touchContainer,
                  toggledItems.includes(index) && styles.toggledContainer
                ]}
              >
                <View key={guidGenerator()} style={[styles.iconContainer]}>
                  <Icon
                    icon={item.icon}
                    iconStyle={[
                      styles.iconStyle,
                      toggledItems.includes(index)
                        ? { tintColor: COLOR_WHITE }
                        : { tintColor: COLOR_BLUE_LIGHT }
                    ]}
                  />
                  <Text
                    style={[
                      styles.label,
                      toggledItems.includes(index) ? { color: COLOR_WHITE } : {}
                    ]}
                  >
                    {i18n.t(item.label)}
                  </Text>
                </View>
              </TouchableOpacity>
            );
          }
          return null;
        })}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  viewStyle: {
    backgroundColor: COLOR_DARK_GREY,
    flexDirection: "column",
    paddingTop: PADDING_SIDES * 0.2
  },
  touchContainer: {
    paddingVertical: PADDING_SIDES * 0.3,
    paddingHorizontal: PADDING_SIDES * 0.3
  },
  toggledContainer: {
    backgroundColor: COLOR_BLUE_TESTWE
  },
  iconContainer: {
    flexDirection: "column",
    flexBasis: "fit-content",
    width: "100%",
    alignItems: "center",
    justifyContent: "center"
  },
  label: {
    fontFamily: FONT_GILROY_MEDIUM,
    fontSize: FONTSIZE_10,
    color: COLOR_BLUE_LIGHT
  },
  iconStyle: {
    width: "25px",
    height: "25px",
    marginBottom: 10
  }
});

export default ExamTakingMenuLeft;
