import React from "react";
import {
  StyleProp,
  StyleSheet,
  TextStyle,
  View,
  ViewStyle
} from "react-native";
import ColorDot from "../atoms/ColorDot";
import ItalicText from "../atoms/ItalicText";

export interface AvailableTagProps {
  containerStyle?: StyleProp<ViewStyle>;
  dotStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  text: string;
}

class AvailableTag extends React.PureComponent<AvailableTagProps> {
  render(): JSX.Element {
    const { containerStyle, dotStyle, textStyle, text } = this.props;
    return (
      <View style={[styles.defaultContainerStyle, containerStyle]}>
        <ColorDot dotStyle={[styles.defaultIcon, dotStyle]} />
        <ItalicText
          textStyle={[styles.defaultTextStyle, textStyle]}
          text={text}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  defaultContainerStyle: {
    flex: 1,
    alignContent: "center",
    justifyContent: "flex-start",
    flexDirection: "row"
  },
  defaultIcon: {
    alignSelf: "center"
  },
  defaultTextStyle: {
    paddingLeft: 5
  }
});

export default AvailableTag;
