import React from "react";
import { StyleSheet, View, ViewStyle } from "react-native";
import { COLOR_BLACK } from "../../../static/misc/colors";

interface DividerProps {
  color?: ViewStyle["backgroundColor"];
  height?: ViewStyle["height"];
  width?: ViewStyle["width"];
  marginHorizontal?: ViewStyle["marginHorizontal"];
  marginVertical?: ViewStyle["marginVertical"];
}

class Divider extends React.PureComponent<DividerProps> {
  render(): JSX.Element {
    const {
      color,
      height,
      width,
      marginHorizontal,
      marginVertical
    } = this.props;
    return (
      <View
        style={[
          styles.divider,
          StyleSheet.flatten({
            backgroundColor: color,
            height,
            width,
            marginVertical,
            marginHorizontal
          })
        ]}
      />
    );
  }
}

const styles = StyleSheet.create({
  divider: {
    height: "100%",
    width: 1,
    backgroundColor: COLOR_BLACK
  }
});

export default Divider;
