/* eslint-disable camelcase */
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "../../../store/rootreducer";
import { clearCredentials } from "../../../modules/authentification/actions/auth";
import { sortExam } from "../../../modules/exams/actions/exams";
import Header from "./Header";
import {
  clearExamTaking,
  createExamTaking
} from "../../../modules/examTaking/actions/examTaking";
import { getMyUser, setLocale } from "../../../modules/main/actions/user";
import { getLatestEquipmentCheck } from "../../../modules/main/actions/equipmentCheck";

const mapStateToProps = (state: RootState) => {
  return {
    authorizationToken: state.auth.credentials?.access_token,
    isOnline: state.status.isOnline,
    gettingUserInfo:
      state.status.isOnline === true ? state.status.gettingUserInfo : false,
    user: state.user,
    examsNumber: state.exams.nextExams.length + state.exams.passedExams.length,
    showLoader:
      state.status.isOnline === true ? state.status.showLoader : false,
    examTaking: state.examTaking.exams,
    nbMediaObjectToGet: state.status.nbMediaObjectToGet,
    isTakingExam: state.examTaking.isTakingExam
  };
};

const mapdispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators(
      {
        clearCredentials,
        sortExam,
        setLocale,
        createExamTaking,
        clearExamTaking,
        getMyUser,
        getLatestEquipmentCheck
      },
      dispatch
    )
  };
};

const HeaderContainer = connect(mapStateToProps, mapdispatchToProps)(Header);

export default HeaderContainer;
