module.exports = () => {
  return {
    getItem: () => {
      return new Promise((resolve) => {
        resolve();
      });
    },
    setItem: () => {
      return new Promise((resolve) => {
        resolve();
      });
    },
    removeItem: () => {
      return new Promise((resolve) => {
        resolve();
      });
    }
  };
};
