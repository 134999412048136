import React from "react";
import { Text, View, StyleSheet } from "react-native";
import { COLOR_BLUE, COLOR_BLUE_TESTWE } from "../../../static/misc/colors";
import {
  FONTSIZE_14,
  FONTSIZE_16,
  FONT_GILROY_BOLD,
  FONT_GILROY_REGULAR,
  PADDING_SIDES,
  WIDTH_8
} from "../../../static/misc/constants";
import { guidGenerator } from "../../../static/misc/utils";
import i18n from "../../services/i18n";
import CustomModal from "../atoms/CustomModal";
import ExamTakingTimer, { TimerTypeEnum } from "../atoms/ExamTakingTimer";
import { ChangeEnum } from "../organisms/ExamTakingContent";
import Button from "./Button";

export interface ExamTakingForcedNavigationModalProps {
  isVisible: boolean;
  typeOfChangeQuestion?: ChangeEnum;
  examId: string;
  onForceNavigation: () => void;
  onClose: () => void;
  onAutoToggleModal: () => void;
}

export default class ExamTakingForcedNavigationModal extends React.Component<ExamTakingForcedNavigationModalProps> {
  shouldComponentUpdate(
    nextProps: ExamTakingForcedNavigationModalProps
  ): boolean {
    const { isVisible } = this.props;
    return nextProps.isVisible !== isVisible;
  }

  renderModalContent(): JSX.Element | null {
    const { typeOfChangeQuestion } = this.props;

    switch (typeOfChangeQuestion) {
      case ChangeEnum.NEXTQUESTION: {
        return (
          <View style={styles.labelContainer}>
            <Text style={styles.labelText}>
              {i18n.t("timerModal.expiredDurationLabel2")}
            </Text>
            <Text style={styles.labelText}>
              {i18n.t("timerModal.expiredDurationLabel3", {
                "0": i18n.t("timerModal.question")
              })}
            </Text>
            <Text style={[styles.labelText, styles.labelTextBold]}>
              {i18n.t("timerModal.expiredDurationLabel6", {
                "0": i18n.t("timerModal.question")
              })}
            </Text>
          </View>
        );
      }
      case ChangeEnum.NEXTPART: {
        return (
          <View style={styles.labelContainer}>
            <Text style={styles.labelText}>
              {i18n.t("timerModal.expiredDurationLabel1")}
            </Text>
            <Text style={styles.labelText}>
              {i18n.t("timerModal.expiredDurationLabel3", {
                "0": i18n.t("timerModal.part")
              })}
            </Text>
            <Text style={[styles.labelText, styles.labelTextBold]}>
              {i18n.t("timerModal.expiredDurationLabel6", {
                "0": i18n.t("timerModal.part")
              })}
            </Text>
          </View>
        );
      }
      case ChangeEnum.FINISHEXAM: {
        return (
          <View style={styles.labelContainer}>
            <Text style={styles.labelText}>
              {i18n.t("timerModal.expiredDurationLabel5")}
            </Text>
            <Text style={styles.labelText}>
              {i18n.t("timerModal.expiredDurationLabel4")}
            </Text>
            <Text style={[styles.labelText, styles.labelTextBold]}>
              {i18n.t("timerModal.expiredDurationLabel7")}
            </Text>
          </View>
        );
      }
      default: {
        return null;
      }
    }
  }

  render(): JSX.Element {
    const {
      isVisible,
      examId,
      typeOfChangeQuestion,
      onClose,
      onForceNavigation,
      onAutoToggleModal
    } = this.props;

    return (
      <CustomModal
        heightModal="20%"
        widthModal="40%"
        borderRadiusModal={5}
        showCloseButton={false}
        visible={isVisible}
        onCloseModal={() => onClose()}
      >
        <View style={styles.contentContainer}>
          <View style={styles.titleContainer}>
            <Text style={styles.titleText}>
              {i18n.t("timerModal.expiredDurationTitle")}
            </Text>
          </View>
          {this.renderModalContent()}
          <View style={styles.timerContainer}>
            <ExamTakingTimer
              key={guidGenerator()}
              examId={examId}
              duration={5}
              displayZeroHour={false}
              running={isVisible}
              timerType={TimerTypeEnum.MODAL}
              onFinish={() => {
                onForceNavigation();
                onAutoToggleModal();
              }}
            />
          </View>
          <View style={styles.buttonsContainer}>
            <Button
              label={
                typeOfChangeQuestion === ChangeEnum.FINISHEXAM
                  ? i18n.t("syncModal.endExamMode")
                  : i18n.t("timerModal.OK")
              }
              onPress={() => {
                onForceNavigation();
                onAutoToggleModal();
              }}
            />
          </View>
        </View>
      </CustomModal>
    );
  }
}

const styles = StyleSheet.create({
  contentContainer: {
    flexGrow: 1,
    flexDirection: "column",
    paddingTop: PADDING_SIDES * 0.2
  },
  titleContainer: {
    flex: 1,
    paddingVertical: PADDING_SIDES * 0.2,
    paddingHorizontal: PADDING_SIDES * 0.5,
    justifyContent: "center",
    alignItems: "center",
    minHeight: "fit-content"
  },
  titleText: {
    fontFamily: FONT_GILROY_BOLD,
    fontSize: FONTSIZE_16,
    color: COLOR_BLUE,
    textAlign: "center",
    width: WIDTH_8
  },
  labelContainer: {
    flex: 1,
    paddingVertical: PADDING_SIDES * 0.2,
    paddingHorizontal: PADDING_SIDES * 0.5,
    justifyContent: "center",
    alignItems: "center",
    minHeight: "fit-content"
  },
  labelText: {
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_14,
    color: COLOR_BLUE_TESTWE,
    textAlign: "center",
    width: WIDTH_8,
    marginBottom: PADDING_SIDES * 0.2
  },
  labelTextBold: {
    fontFamily: FONT_GILROY_BOLD
  },
  timerContainer: {
    justifyContent: "center",
    alignItems: "center"
  },
  buttonsContainer: {
    flex: 2,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: PADDING_SIDES * 0.2
  }
});
