import React from "react";
import { StyleSheet, View, Text } from "react-native";
import i18n from "../../services/i18n";
import {
  FONTSIZE_14,
  FONT_GILROY_REGULAR,
  PADDING_SIDES
} from "../../../static/misc/constants";
import { COLOR_BLUE_TESTWE } from "../../../static/misc/colors";
import { ExamOnboardingStep } from "../../modules/exams/types/exam";
import { OnboardingInfoType } from "./OnboardingItem";
import ExamInfoAgreement from "../molecules/ExamInfoAgreement";

export interface OnboardingStatementStepProps {
  currentOnboardingStep: ExamOnboardingStep;
  currentInfo?: OnboardingInfoType;
  updateCurrentStepInfo: (
    data?: string | Blob | null,
    status?: boolean
  ) => void;
}

export interface OnboardingStatementStepState {
  hasAgreed: boolean;
}

class OnboardingStatementStep extends React.PureComponent<
  OnboardingStatementStepProps,
  OnboardingStatementStepState
> {
  constructor(props: OnboardingStatementStepProps) {
    super(props);

    this.state = {
      hasAgreed: (props.currentInfo && props.currentInfo.status) || false
    };
  }

  render(): JSX.Element | null {
    const { currentOnboardingStep, updateCurrentStepInfo } = this.props;
    const { hasAgreed } = this.state;

    return (
      <View style={styles.proctoringContainer}>
        <View style={styles.titleContainer}>
          <Text style={styles.stepSubtitle}>
            {i18n.t(`onboardingModal.${currentOnboardingStep.subtitle}`)}
          </Text>
        </View>
        <ExamInfoAgreement
          hasAgreed={hasAgreed}
          link={i18n.t("exam.agreement2")}
          text={i18n.t("exam.agreement1")}
          onPress={() => {
            updateCurrentStepInfo("", !hasAgreed);
            this.setState({
              hasAgreed: !hasAgreed
            });
          }}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  titleContainer: {
    paddingVertical: PADDING_SIDES * 0.2,
    alignItems: "center"
  },
  stepSubtitle: {
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_14,
    color: COLOR_BLUE_TESTWE,
    paddingBottom: PADDING_SIDES * 0.2,
    paddingHorizontal: PADDING_SIDES * 0.2
  },
  proctoringContainer: {
    alignItems: "center",
    minHeight: 200,
    marginBottom: 15,
    width: "100%"
  }
});
export default OnboardingStatementStep;
