import * as React from "react";
import { createSelectorHook } from "react-redux";

import {
  createStackNavigator,
  StackNavigationProp
} from "@react-navigation/stack";
import { CompositeNavigationProp } from "@react-navigation/native";

import LoginFlow, { LoginFlowParamList } from "./LoginFlow";
import AuthentifiedFlow, {
  AuthentifiedFlowParamList
} from "./AuthentifiedFlow";
import { RootState } from "../store/rootreducer";

type RootParamList = {
  AuthentifiedFlow: undefined;
  LoginFlow: undefined;
};

export type RouteProps = {
  key: string;
  name: string;
  params?: any;
};

export type StackNavigatorProp = CompositeNavigationProp<
  StackNavigationProp<RootParamList>,
  CompositeNavigationProp<
    StackNavigationProp<LoginFlowParamList>,
    StackNavigationProp<AuthentifiedFlowParamList>
  >
>;

const Stack = createStackNavigator();
const useTypeSelector = createSelectorHook<RootState>();

export default function StackNavigator<RootParamList>(): JSX.Element {
  // if in redux isConnected is true then set a local boolean in order to show the good stack
  const isConnected = useTypeSelector((state) => state.auth.isConnected);
  // Debug - force connexion
  // const isConnected = true;

  return (
    <Stack.Navigator initialRouteName="LoginFlow" headerMode="none">
      {isConnected ? (
        <>
          <Stack.Screen
            key="AuthentifiedFlow"
            name="AuthentifiedFlow"
            component={AuthentifiedFlow}
          />
        </>
      ) : (
        <Stack.Screen key="LoginFlow" name="LoginFlow" component={LoginFlow} />
      )}
    </Stack.Navigator>
  );
}
