import React, { PureComponent } from "react";
import { View, StyleSheet } from "react-native";

import { StudentAnswerType } from "../../../modules/examTaking/types/studentPaper";
import {
  ExamPart,
  ExamQuestion,
  ExamType
} from "../../../modules/exams/types/exam";
import ExamQuestionComponent from "./ExamQuestionComponent";
import {
  getQuestionNumber,
  getQuestions,
  hasBookmarkedQuestion as hasBookmarkedQuestionInExam,
  hasCompletedAnswer,
  hasVisitedQuestion
} from "../../../services/exam-navbar-progress";
import { TRAINING_SPREADSHEET } from "../../../../static/misc/constants";
import { guidGenerator } from "../../../../static/misc/utils";
import { ChangeEnum } from "../ExamTakingContent";

interface ExamBottombarProps {
  selectedExamPart?: ExamPart;
  currentExam: ExamType;
  studentAnswers: StudentAnswerType[];
  currentPartIndex: number;
  onChangeQuestionIndex: (partIndex: number) => void;
  toggleRemainingDurationWarningModal(
    visible: boolean,
    changeType?: ChangeEnum,
    callback?: (changeType: ChangeEnum) => void
  ): void;
}

interface ExamBottombarState {
  hasChrono: boolean;
}

export type ExamQuestionExtended = ExamQuestion & {
  positionInExercise?: number;
  isQuestionInExercise?: boolean;
  exerciseIndex?: number;
  hasExerciseDuration?: boolean;
};

class ExamBottombar extends PureComponent<
  ExamBottombarProps,
  ExamBottombarState
> {
  constructor(props: ExamBottombarProps) {
    super(props);
    this.state = {
      hasChrono: false
    };
  }

  componentDidUpdate(
    prevProps: Readonly<ExamBottombarProps>,
    prevState: Readonly<ExamBottombarState>
  ): void {
    const { hasChrono } = this.state;
    const { currentExam, currentPartIndex, selectedExamPart } = this.props;

    if (
      currentPartIndex !== prevProps.currentPartIndex ||
      prevProps.currentExam !== currentExam ||
      hasChrono !== prevState.hasChrono ||
      selectedExamPart !== prevProps.selectedExamPart
    ) {
      const currentQuestion = selectedExamPart?.partIndexes[currentPartIndex];
      if (currentExam) {
        if (
          (currentQuestion?.question?.durationLimit ??
            currentQuestion?.exercise?.durationLimit) !== hasChrono
        ) {
          this.setState({
            hasChrono:
              currentQuestion?.question?.durationLimit ??
              currentQuestion?.exercise?.durationLimit ??
              false
          });
        }
      }
    }
  }

  hasCompletedQuestion = (
    studentAnswers: StudentAnswerType[],
    questionId?: string
  ): boolean => {
    const answer = studentAnswers.find((sa) => sa.question === questionId);
    return (
      answer?.status === "completed" || (!!answer && hasCompletedAnswer(answer))
    );
  };

  hasVisitedQuestion = (
    studentAnswers: StudentAnswerType[],
    questionId?: string
  ): boolean => {
    const answer = studentAnswers.find((sa) => sa.question === questionId);

    return !!(answer && hasVisitedQuestion(answer));
  };

  isLastQuestionInExamPart = (
    currentQuestionIndex: number,
    examPart?: ExamPart
  ): boolean => {
    return !!(
      (examPart?.partIndexes || []).length - 1 ===
      currentQuestionIndex
    );
  };

  hasBookmarkedQuestion = (
    studentAnswers: StudentAnswerType[],
    currentExam: ExamType,
    question?: ExamQuestion
  ): boolean => {
    return (
      question?.isBookMarked ||
      hasBookmarkedQuestionInExam(question?.id ?? "", currentExam) ||
      studentAnswers.find((sa) => sa.question === question?.id)?.status ===
        "bookmarked"
    );
  };

  render(): JSX.Element {
    const {
      selectedExamPart,
      currentExam,
      currentPartIndex,
      studentAnswers,
      onChangeQuestionIndex,
      toggleRemainingDurationWarningModal
    } = this.props;
    const { hasChrono } = this.state;

    const questions = getQuestions(currentExam, selectedExamPart);

    return (
      <View style={styles.container}>
        {questions.map((question, currentExamPartQuestionIndex) => (
          <ExamQuestionComponent
            key={question?.id ?? guidGenerator()}
            question={question}
            durationFixed={currentExam?.examParams?.durationFixed}
            currentPartIndex={currentPartIndex}
            index={getQuestionNumber(
              currentExam,
              question.id,
              selectedExamPart
            )}
            isFirst={currentExamPartQuestionIndex === 0}
            isLast={
              !!selectedExamPart?.partIndexes.length &&
              currentExamPartQuestionIndex === questions.length - 1
            }
            onPress={onChangeQuestionIndex}
            hasChrono={hasChrono}
            hasVisited={this.hasVisitedQuestion(
              studentAnswers,
              question.spreadsheetFile?.id === TRAINING_SPREADSHEET
                ? question.spreadsheetFile?.id
                : question?.id
            )}
            hasBookmarked={this.hasBookmarkedQuestion(
              studentAnswers,
              currentExam,
              question
            )}
            isLastQuestionInExamPart={this.isLastQuestionInExamPart(
              currentPartIndex,
              selectedExamPart
            )}
            hasCompleted={this.hasCompletedQuestion(
              studentAnswers,
              question.spreadsheetFile?.id === TRAINING_SPREADSHEET
                ? question.spreadsheetFile?.id
                : question?.id
            )}
            isCurrent={currentPartIndex === question.position}
            exerciseIndex={question.exerciseIndex}
            toggleRemainingDurationWarningModal={
              toggleRemainingDurationWarningModal
            }
          />
        ))}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    rowGap: 8,
    paddingVertical: 16,
    flexWrap: "wrap"
  }
});

export default ExamBottombar;
