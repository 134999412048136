import React from "react";
import { StyleProp, StyleSheet, Text, TextStyle } from "react-native";
import { COLOR_WHITE } from "../../../static/misc/colors";
import { FONTSIZE_9, FONT_GILROY_ITALIC } from "../../../static/misc/constants";

export interface ItalicTextProps {
  textStyle?: StyleProp<TextStyle>;
  text: string;
}

class ItalicText extends React.PureComponent<ItalicTextProps> {
  render(): JSX.Element {
    const { textStyle, text } = this.props;
    return <Text style={[styles.defaultItalicText, textStyle]}>{text}</Text>;
  }
}

const styles = StyleSheet.create({
  defaultItalicText: {
    fontFamily: FONT_GILROY_ITALIC,
    fontSize: FONTSIZE_9,
    color: COLOR_WHITE
  }
});

export default ItalicText;
