import React from "react";
import { Image, StyleSheet, Text, View } from "react-native";
import { COLOR_BLUE_TESTWE } from "../../../static/misc/colors";
import {
  FONTSIZE_18,
  FONT_GILROY_REGULAR
} from "../../../static/misc/constants";
import Card from "../atoms/Card";
import RadioButton from "../atoms/RadioButton";

interface EquipmentCardProps {
  selected: boolean;
  title: string;
  icon: any;
  onPress: () => void;
}

const CARD_SIZE = 220;

export default class EquipmentCard extends React.PureComponent<EquipmentCardProps> {
  render(): JSX.Element {
    const { icon, selected, title, onPress } = this.props;
    return (
      <Card selected={selected} onPress={onPress} style={styles.cardStyles}>
        <View style={styles.cardRadioButton}>
          <RadioButton isSelected={selected} />
        </View>
        <Image source={icon} style={styles.cardResumeIcon} />
        <Text style={styles.cardTitle}>{title}</Text>
      </Card>
    );
  }
}

const styles = StyleSheet.create({
  cardStyles: {
    width: CARD_SIZE,
    height: CARD_SIZE,
    justifyContent: "space-between"
  },
  cardRadioButton: {
    alignSelf: "flex-end"
  },
  cardResumeIcon: {
    width: 140,
    height: 80,
    alignSelf: "center",
    resizeMode: "contain"
  },
  cardTitle: {
    fontSize: FONTSIZE_18,
    fontFamily: FONT_GILROY_REGULAR,
    color: COLOR_BLUE_TESTWE,
    alignSelf: "center"
  }
});
