/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { ReactNode } from "react";

import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
// @ts-ignore
import Modal from "modal-enhanced-react-native-web";
import { COLOR_BLUE_TESTWE, COLOR_WHITE } from "../../../static/misc/colors";
import Button from "../molecules/Button";
import { crossIcon } from "../../../static/misc/images";
import { PADDING_SIDES } from "../../../static/misc/constants";

interface CustomModalProps {
  borderRadiusModal?: number;
  backgroundColor?: string;
  widthModal?: string | number;
  transparent?: boolean;
  heightModal?: string | number;
  visible: boolean;
  showCloseButton: boolean;
  onCloseModal: () => void;
  modalStyle?: StyleProp<ViewStyle>;
  children: ReactNode;
  backdropOpacity?: number;
}

interface CustomModalState {
  visibleModal: boolean;
}

class CustomModal extends React.PureComponent<
  CustomModalProps,
  CustomModalState
> {
  static defaultProps = {
    borderRadiusModal: 10,
    transparent: true,
    widthModal: "50%",
    heightModal: "50%",
    backgroundColor: COLOR_WHITE,
    backdropOpacity: 0.7
  };

  render(): JSX.Element {
    const {
      children,
      backgroundColor,
      heightModal,
      transparent,
      widthModal,
      borderRadiusModal,
      visible,
      showCloseButton,
      onCloseModal,
      modalStyle,
      backdropOpacity
    } = this.props;

    return (
      <Modal
        onBackdropPress={() => onCloseModal()}
        isVisible={visible}
        // Temporar Fixing modal transition with modal dependance
        animationInTiming={visible ? 300 : 1}
        //
        transparent={transparent}
        style={styles.modalView}
        backdropOpacity={backdropOpacity}
      >
        <View
          style={[
            {
              width: widthModal,
              minHeight: heightModal,
              backgroundColor,
              borderRadius: borderRadiusModal,
              paddingBottom: PADDING_SIDES * 0.2
            },
            modalStyle
          ]}
        >
          {showCloseButton && (
            <View style={styles.crossButtonsContainer}>
              <Button
                icon={crossIcon}
                onPress={() => onCloseModal()}
                iconStyle={styles.iconStyle}
                containerStyle={[
                  styles.containerStyle,
                  borderRadiusModal
                    ? { borderTopRightRadius: borderRadiusModal }
                    : null
                ]}
              />
            </View>
          )}
          {children}
        </View>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  modalView: {
    justifyContent: "center",
    alignItems: "center",
    zIndex: 999
  },
  crossButtonsContainer: {
    alignItems: "flex-end",
    justifyContent: "center",
    width: "100%"
  },
  iconStyle: {
    left: 4,
    height: 13,
    width: 13
  },
  containerStyle: {
    borderColor: COLOR_BLUE_TESTWE,
    backgroundColor: COLOR_BLUE_TESTWE,
    paddingHorizontal: 0,
    paddingVertical: 0,
    borderRadius: 0,
    borderTopRightRadius: 10,
    width: 32,
    height: 32
  }
});

export default CustomModal;
