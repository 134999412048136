import React from "react";
import {
  StyleSheet,
  View,
  Text,
  ImageURISource,
  Image,
  TouchableOpacity
} from "react-native";
import { BlurView } from "expo-blur";
import {
  FONTSIZE_65,
  FONT_TUNGSTENCONDENSED_LIGHT,
  FONT_TUNGSTENCONDENSED_SEMIBOLD,
  FONTSIZE_40
} from "../../../static/misc/constants";
import { COLOR_BLUE } from "../../../static/misc/colors";

export interface BlurredItemProps {
  icon: {
    source: ImageURISource;
    width: number;
    height: number;
  };
  itemBlurred: string;
  itemBlurredNext?: string | undefined;
}

interface BlurredItemState {
  hideBlur: boolean;
}

class BlurredItem extends React.Component<BlurredItemProps, BlurredItemState> {
  constructor(props: BlurredItemProps) {
    super(props);
    this.state = {
      hideBlur: false
    };
  }

  render(): JSX.Element {
    const { icon, itemBlurred, itemBlurredNext } = this.props;
    const { hideBlur } = this.state;
    return (
      <View style={styles.container}>
        <BlurView
          intensity={hideBlur ? 1 : 50}
          style={[StyleSheet.absoluteFill, styles.nonBlurredContent]}
        >
          <TouchableOpacity
            onPressIn={() => this.setState({ hideBlur: true })}
            onPressOut={() => this.setState({ hideBlur: false })}
          >
            <Image
              source={icon.source}
              style={{
                width: icon.width,
                height: icon.height
              }}
            />
          </TouchableOpacity>
        </BlurView>
        <Text>
          <Text style={styles.blurText}>{itemBlurred}</Text>
          {itemBlurredNext !== undefined && (
            <Text style={styles.blurTextNext}>{itemBlurredNext}</Text>
          )}
        </Text>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
    width: "100%"
  },
  blurText: {
    alignSelf: "center",
    fontFamily: FONT_TUNGSTENCONDENSED_SEMIBOLD,
    color: COLOR_BLUE,
    fontSize: FONTSIZE_65
  },
  blurTextNext: {
    alignSelf: "center",
    fontFamily: FONT_TUNGSTENCONDENSED_LIGHT,
    color: COLOR_BLUE,
    fontSize: FONTSIZE_40
  },
  nonBlurredContent: {
    alignItems: "center",
    justifyContent: "center"
  }
});

export default BlurredItem;
