import React from "react";
import { Linking, StyleSheet, View } from "react-native";
import { ipcRenderer } from "electron";
import { KeycloakInstance } from "keycloak-js";
import os from "os";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import _ from "lodash";
import { StackNavigatorProp } from "../../../router/StackNavigator";
import {
  copie,
  logoutIcon,
  nowifi,
  parameterIcon,
  planetIcon,
  question,
  userIcon,
  wifi
} from "../../../../static/misc/images";
import CguModal from "../CguModal";
import OptionsModal from "../OptionsModal";
import LanguageModal from "../LanguageModal";
import Logo from "../../atoms/Logo";
import HeaderNavigation from "../../molecules/HeaderNavigation";
import { HEADER_MENU } from "../../../../static/misc/menu";
import {
  IS_PREVIEW,
  IS_WEB_ENABLE,
  EACH_SECONDS,
  PADDING_SIDES,
  SEB_QUITTING_LINK
} from "../../../../static/misc/constants";
import i18n from "../../../services/i18n";
import Menu from "../../molecules/Menu";
import { COLOR_BLUE_TESTWE } from "../../../../static/misc/colors";
import {
  ClearExamTakingAction,
  CreateExamTakingAction
} from "../../../modules/examTaking/types/examTaking";
import { ExamType } from "../../../modules/exams/types/exam";
import {
  DetailedUser,
  GetUserAction,
  SetLocale
} from "../../../modules/main/types/user";
import ExamsSynchronisationHandlerContainer from "./ExamsSynchronisationHandlerContainer";
import { ClearCredentialsActions, RootState } from "../../../store/rootreducer";
import { SortExamsAction } from "../../../modules/exams/types/exams";
import StudentPapersSynchronisationHandlerContainer from "./StudentPapersSynchronisationHandlerContainer";
import UpdateModal from "../UpdateModal";
import LoadingModal from "../LoadingModal";
import {
  KeycloakConsumer,
  KeycloakObject
} from "../../../services/web/keycloak";
import TouchableButton from "../../molecules/TouchableButton";
import Icon from "../../atoms/Icon";
import { GetLastestEquipmentCheck } from "../../../modules/main/types/equipmentCheck";
import {
  safeExamBrowserNotFound,
  startExitingApplication
} from "../../../modules/main/actions/status";
import {
  ExitApplicationAction,
  SafeExamBrowserNotFound
} from "../../../modules/main/types/status";
import { GetErrorAction } from "../../../modules/main/types/error";
import { getError } from "../../../modules/main/actions/error";
import {
  megabytesToGigabytes,
  hasNotEnoughAvailableMemory
} from "../../../../static/misc/utils";
import AnimatedBanner, { BannerItemType } from "../../atoms/AnimatedBanner";
import { BannerItemsStatus } from "../../../modules/main/types/persistedStatus";
import { setBannerItemsStatus } from "../../../modules/main/actions/persistedStatus";

export interface HeaderProps {
  isOnline: boolean;
  showMenu: boolean;
  gettingUserInfo: boolean;
  user: DetailedUser;
  nbMediaObjectToGet: number;
  disableSynchronisation?: boolean;
  examsNumber: number;
  showLoader: boolean;
  currentRouteName?: string;
  navigation: StackNavigatorProp;
  isTakingExam: boolean;
  examTaking: ExamType[] | undefined;
  authorizationToken: string | undefined;
  toggleMenu: (toggle?: boolean) => void;
  setLocale: (locale: string) => SetLocale;
  getLatestEquipmentCheck: (
    userToken: string,
    equipmentId: string
  ) => GetLastestEquipmentCheck;
  getMyUser: (token: string) => GetUserAction;
  sortExam: () => SortExamsAction;
  createExamTaking: (exam: ExamType) => CreateExamTakingAction;
  clearExamTaking: (examId: string | undefined) => ClearExamTakingAction;
  clearCredentials: () => ClearCredentialsActions;
  exitApplication: (action?: string) => ExitApplicationAction;
  displayError: (message: string, forceLogout: boolean) => GetErrorAction;
  sebNotFound: () => SafeExamBrowserNotFound;
  updateBannerItemsStatus: (items: BannerItemsStatus) => void;
  bannerItemsStatus: BannerItemsStatus;
}

interface HeaderState {
  showLangModal: boolean;
  showCguModal: boolean;
  showOptionsModal: boolean;
  launchExamPreview: boolean;
  canSynchroniseExams: boolean;
  canSynchroniseSP: boolean;
  showUpdateModal: boolean;
  updateDownloaded: boolean;
  updateErrored: boolean;
  showIsRestarting: boolean;
  autoUpdateProgress: number;
  hasCheckedUserAgent: boolean;
  bannerItems: BannerItemType[];
}
class Header extends React.PureComponent<HeaderProps, HeaderState> {
  sortExamInterval!: ReturnType<typeof setInterval>;

  memoryCheckInterval!: ReturnType<typeof setInterval>;

  handleBannerItemsUpdateInterval!: ReturnType<typeof setInterval>;

  constructor(props: HeaderProps) {
    super(props);
    this.state = {
      launchExamPreview: false,
      showLangModal: false,
      showCguModal: false,
      showOptionsModal: false,
      canSynchroniseExams: false,
      canSynchroniseSP: false,
      showUpdateModal: false,
      updateDownloaded: false,
      updateErrored: false,
      showIsRestarting: false,
      autoUpdateProgress: 0,
      hasCheckedUserAgent: false,
      bannerItems: this.getDefaultBannerItems()
    };

    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.backToHomepage = this.backToHomepage.bind(this);
  }

  componentDidMount(): void {
    const {
      authorizationToken,
      isOnline,
      disableSynchronisation,
      examsNumber,
      user,
      getLatestEquipmentCheck,
      getMyUser,
      sortExam
    } = this.props;
    this.handleBannerItemsUpdate();

    if (!IS_PREVIEW && !IS_WEB_ENABLE) {
      // Modal auto-update available
      if (ipcRenderer.rawListeners("AUTO_UPDATER_AVAILABLE").length === 0) {
        ipcRenderer.on("AUTO_UPDATER_AVAILABLE", () => {
          this.setState({
            showUpdateModal: true,
            updateDownloaded: false
          });
        });
      }

      // Modal auto-update downloaded
      if (ipcRenderer.rawListeners("AUTO_UPDATER_DOWNLOADED").length === 0) {
        ipcRenderer.on("AUTO_UPDATER_DOWNLOADED", () => {
          this.setState({
            showUpdateModal: true,
            updateDownloaded: true,
            updateErrored: false
          });
        });
      }

      // Modal auto-update errored
      if (ipcRenderer.rawListeners("AUTO_UPDATER_ERRORED").length === 0) {
        ipcRenderer.on("AUTO_UPDATER_ERRORED", () => {
          this.setState({
            showUpdateModal: true,
            updateDownloaded: false,
            updateErrored: true
          });
        });
      }

      // Modal auto-update download progress
      if (ipcRenderer.rawListeners("AUTO_UPDATER_PROGRESS").length === 0) {
        ipcRenderer.on("AUTO_UPDATER_PROGRESS", (event, arg) => {
          this.setState({
            showUpdateModal: true,
            updateErrored: false,
            autoUpdateProgress: parseInt(arg, 10)
          });
        });
      }

      this.memoryCheckInterval = setInterval(() => {
        let memoryString = "";
        Object.entries(process.memoryUsage()).map(
          // eslint-disable-next-line no-return-assign
          (x, index) =>
            (memoryString += `${x[0]}: ${(x[1] / (1000.0 * 1000)).toFixed(
              2
            )} MB ${index !== 4 ? "-" : ""} `)
        );

        memoryString += `- available : ${os.freemem() / 1048576} MB`;

        ipcRenderer.send("LOG_INFO", `MEMORY_CHECK | ${memoryString}`);
      }, EACH_SECONDS * 300);
    }
    // if there is a token, get user info
    if (isOnline && authorizationToken) {
      getMyUser(authorizationToken);
      if (user?.equipmentId)
        getLatestEquipmentCheck(authorizationToken, user.equipmentId);
    }
    this.sortExamInterval = setInterval(() => {
      // we don't want sortExam to be called during exam
      if (!disableSynchronisation && examsNumber > 0) {
        sortExam();
      }
    }, 30000);
  }

  componentDidUpdate(prevProps: HeaderProps): void {
    const {
      isOnline,
      showLoader,
      gettingUserInfo,
      user,
      authorizationToken,
      getMyUser,
      getLatestEquipmentCheck,
      displayError,
      sebNotFound,
      bannerItemsStatus
    } = this.props;
    const {
      canSynchroniseExams,
      canSynchroniseSP,
      hasCheckedUserAgent,
      bannerItems
    } = this.state;

    // if isonline was previously false and got back to true, relaunch the sync
    if (isOnline && !prevProps.isOnline && authorizationToken) {
      getMyUser(authorizationToken);
    } else if (isOnline) {
      // exam cannot be sync if app is retrieving the user info or if cgu were not accepted
      if (gettingUserInfo || !user.cguAcceptance) {
        this.setState({
          canSynchroniseExams: false,
          canSynchroniseSP: false
        });
      } else if (
        !canSynchroniseExams &&
        !gettingUserInfo &&
        user.cguAcceptance &&
        !hasCheckedUserAgent
      ) {
        let hasSEBUserAgent = false;
        if (user.currentEstablishment?.requireSafeExamBrowser) {
          hasSEBUserAgent = window.navigator.userAgent.includes("SEB");
          this.setState({
            canSynchroniseExams: hasSEBUserAgent,
            hasCheckedUserAgent: true
          });
          if (!hasSEBUserAgent) {
            ipcRenderer.send(
              "LOG_INFO",
              "Tried to synchronise an establishment requiring SEB outside of SEB"
            );
            displayError(i18n.t("errors.safeExamBrowserNotFound"), false);
            sebNotFound();
          }
        } else {
          this.setState({
            canSynchroniseExams: true,
            hasCheckedUserAgent: true
          });
        }
      } else if (
        !canSynchroniseSP &&
        !showLoader &&
        !gettingUserInfo &&
        user.cguAcceptance
      ) {
        this.setState({
          canSynchroniseSP: true
        });
      }
      if (
        prevProps.authorizationToken !== authorizationToken &&
        authorizationToken &&
        prevProps.user !== user &&
        user?.equipmentId
      )
        getLatestEquipmentCheck(authorizationToken, user.equipmentId);
    }

    // Network
    if (prevProps.isOnline !== isOnline) {
      const newBannerItems = [...bannerItems];
      const index = bannerItems.findIndex((item) => item.key === "network");

      if (index !== -1 && bannerItems[index].key === "network") {
        bannerItems[index].visible = !isOnline;
        bannerItems[index].text = i18n.t("noConnexion");

        this.setState({
          bannerItems: [...newBannerItems]
        });
      }
    }

    // Antivirus
    if (prevProps.bannerItemsStatus !== bannerItemsStatus) {
      const newBannerItems = [...bannerItems];
      const index = bannerItems.findIndex((item) => item.key === "antivirus");
      const shouldHideAntivirusBanner =
        index !== -1 &&
        bannerItemsStatus.antivirus &&
        bannerItems[index].visible &&
        bannerItems[index].key === "antivirus";

      // If the current user closed the antivirus banner, we don't display it anymore
      if (shouldHideAntivirusBanner) {
        bannerItems[index].visible = false;
        bannerItems[index].text = "";

        this.setState({
          bannerItems: [...newBannerItems]
        });
      }
    }
  }

  componentWillUnmount(): void {
    clearInterval(this.sortExamInterval);
    clearInterval(this.memoryCheckInterval);
    clearInterval(this.handleBannerItemsUpdateInterval);
  }

  onUpdateClick(): void {
    const { exitApplication } = this.props;
    this.setState({
      showUpdateModal: false,
      updateDownloaded: false,
      updateErrored: false,
      showIsRestarting: true
    });
    exitApplication("AUTO_UPDATER_INSTALL_VERSION");
  }

  getUserMenu(keycloak?: KeycloakInstance): any {
    const { toggleMenu, exitApplication } = this.props;
    const { showCguModal, showLangModal, showOptionsModal } = this.state;
    return [
      {
        label: "userMenu.profil",
        icon: {
          source: userIcon,
          width: 13,
          height: 16
        },
        onPress: () => {
          this.navigateToProfile();
        }
      },
      {
        label: "userMenu.cgu",
        icon: {
          source: copie,
          width: 16,
          height: 16
        },
        onPress: () => {
          this.setState({ showCguModal: !showCguModal });
          toggleMenu();
        }
      },
      {
        label: "userMenu.lang",
        icon: {
          source: planetIcon,
          width: 16,
          height: 16
        },
        onPress: () => {
          this.setState({ showLangModal: !showLangModal });
          toggleMenu();
        }
      },
      {
        label: "userMenu.options",
        icon: {
          source: parameterIcon,
          width: 16,
          height: 16
        },
        onPress: () => {
          this.setState({ showOptionsModal: !showOptionsModal });
          toggleMenu();
        }
      },
      {
        label: "userMenu.logout",
        icon: {
          source: logoutIcon,
          width: 15,
          height: 16
        },
        onPress: () => {
          this.logout();
          if (!IS_WEB_ENABLE) {
            ipcRenderer.send("KC_LOGOUT");
          } else {
            keycloak?.logout();
          }
        }
      },
      !IS_WEB_ENABLE
        ? {
            label: "userMenu.leave",
            icon: {
              source: logoutIcon,
              width: 15,
              height: 16
            },
            onPress: () => exitApplication()
          }
        : window && window.navigator.userAgent.includes("SEB")
        ? {
            label: "userMenu.leaveSeb",
            icon: {
              source: logoutIcon,
              width: 15,
              height: 16
            },
            onPress: () => Linking.openURL(SEB_QUITTING_LINK)
          }
        : null
    ];
  }

  getDefaultBannerItems = (): BannerItemType[] => [
    {
      key: "network",
      text: i18n.t("noConnexion"),
      tooltip: undefined,
      // eslint-disable-next-line react/destructuring-assignment
      visible: !this.props.isOnline
    },
    {
      key: "ram",
      tooltip: i18n.t("animatedBanner.noMemoryTooltip"),
      text: i18n.t("animatedBanner.noMemory", {
        size: megabytesToGigabytes(0, true)
      }),
      visible: false
    },
    {
      key: "antivirus",
      tooltip: i18n.t("animatedBanner.activeAntivirusTooltip"),
      text: i18n.t("animatedBanner.activeAntivirus", {
        name: "default"
      }),
      visible: false
    }
  ];

  handleBannerItemsUpdate = (): void => {
    const { bannerItemsStatus } = this.props;
    const { bannerItems } = this.state;
    const newBannerItems = [...bannerItems];

    ipcRenderer.send("GET_BANNER_INFO");

    const ONE_MIN = 60000;
    this.handleBannerItemsUpdateInterval = setInterval(() => {
      ipcRenderer.send("GET_BANNER_INFO");
    }, ONE_MIN);

    ipcRenderer.on("GET_BANNER_INFO_RESPOND", (_event, bannerJsonData) => {
      const {
        activeAntivirusArray,
        memoryTotal: memoryTotalUpdated
      } = JSON.parse(bannerJsonData) as {
        memoryTotal: number;
        activeAntivirusArray: string[];
        antivirusNames: string[];
        procList: string[];
      };
      let currentItemIndex = -1;

      // Antivirus
      if (activeAntivirusArray.length > 0) {
        currentItemIndex = bannerItems.findIndex(
          (item) => item.key === "antivirus"
        );

        const activeAntivirusNames = activeAntivirusArray
          .map((n) => _.capitalize(n))
          .join(", ");

        if (
          currentItemIndex !== -1 &&
          bannerItems[currentItemIndex].key === "antivirus"
        ) {
          bannerItems[currentItemIndex].text = i18n.t(
            "animatedBanner.activeAntivirus",
            {
              name: activeAntivirusNames
            }
          );

          bannerItems[currentItemIndex].tooltip = i18n.t(
            "animatedBanner.activeAntivirusTooltip"
          );

          if (bannerItemsStatus.antivirus === false)
            bannerItems[currentItemIndex].visible = true;

          this.setState({
            bannerItems: [...newBannerItems]
          });
        }
      }

      // RAM
      if (memoryTotalUpdated) {
        currentItemIndex = bannerItems.findIndex((item) => item.key === "ram");

        if (
          currentItemIndex > -1 &&
          bannerItems[currentItemIndex].key === "ram"
        ) {
          bannerItems[currentItemIndex].text = i18n.t(
            "animatedBanner.noMemory",
            { size: megabytesToGigabytes(memoryTotalUpdated, true) }
          );

          bannerItems[currentItemIndex].tooltip = i18n.t(
            "animatedBanner.noMemoryTooltip"
          );

          bannerItems[currentItemIndex].visible = hasNotEnoughAvailableMemory(
            memoryTotalUpdated
          );

          this.setState({
            bannerItems: [...newBannerItems]
          });
        }
      }
    });
  };

  // reload homepage
  backToHomepage(): void {
    const { navigation } = this.props;

    navigation.reset({
      index: 0,
      routes: [
        {
          name: "Main" as any
        }
      ]
    });
  }

  // navigate to profile page
  navigateToProfile(): void {
    const { navigation } = this.props;

    navigation.reset({
      index: 0,
      routes: [
        {
          name: "Profile" as any
        }
      ]
    });
  }

  // logout function
  logout(): void {
    ipcRenderer.send("LOG_INFO", "Logout from renderer");
    const { clearCredentials } = this.props;
    clearCredentials();
  }

  render(): JSX.Element {
    const {
      isOnline,
      currentRouteName,
      showLoader,
      examTaking,
      navigation,
      authorizationToken,
      showMenu,
      nbMediaObjectToGet,
      isTakingExam,
      setLocale,
      toggleMenu,
      createExamTaking,
      clearExamTaking,
      exitApplication,
      updateBannerItemsStatus
    } = this.props;
    const {
      launchExamPreview,
      showCguModal,
      showLangModal,
      showOptionsModal,
      showUpdateModal,
      updateDownloaded,
      updateErrored,
      autoUpdateProgress,
      canSynchroniseExams,
      canSynchroniseSP,
      showIsRestarting,
      bannerItems
    } = this.state;

    return (
      <>
        <View style={[styles.defaultContainerStyle]}>
          <CguModal
            isVisible={showCguModal}
            onShowCguModal={(show: boolean) =>
              this.setState({ showCguModal: show })
            }
          />
          <LoadingModal
            isVisible={showIsRestarting}
            loadingMessage={i18n.t("loadingUpdateModal")}
          />
          {!isTakingExam && (
            <UpdateModal
              onCloseModal={() => this.setState({ showUpdateModal: false })}
              onQuit={() => this.onUpdateClick()}
              isVisible={showUpdateModal}
              isDownloaded={updateDownloaded}
              isErrored={updateErrored}
              downloadProgress={autoUpdateProgress}
            />
          )}
          <OptionsModal
            isVisible={showOptionsModal}
            onShowOptionsModal={(show: boolean) =>
              this.setState({ showOptionsModal: show })
            }
          />
          <LanguageModal
            setLocale={setLocale}
            isVisible={showLangModal}
            onShowLangModal={(show: boolean) =>
              this.setState({ showLangModal: show })
            }
          />
          <View style={styles.viewStyle}>
            <Logo />
            <HeaderNavigation
              createExamTaking={createExamTaking}
              clearExamTaking={clearExamTaking}
              examTaking={examTaking}
              currentRouteName={currentRouteName}
              launchExamPreview={launchExamPreview}
              navigation={navigation}
              containerStyle={{ marginLeft: 60 }}
              items={HEADER_MENU}
              token={authorizationToken}
              disabled={showLoader}
            />
          </View>
          <View style={styles.viewStyle}>
            <ExamsSynchronisationHandlerContainer
              canSynchronise={canSynchroniseExams}
              backToHomepage={this.backToHomepage}
              startExamPreview={() => {
                this.setState({
                  launchExamPreview: true
                });
              }}
            />
            {!IS_PREVIEW && (
              <View style={styles.viewStyle}>
                <StudentPapersSynchronisationHandlerContainer
                  canSynchronise={canSynchroniseSP && nbMediaObjectToGet === 0}
                />
                <Icon
                  icon={isOnline ? wifi : nowifi}
                  iconStyle={[styles.defaultIconStyle]}
                />
                <TouchableButton
                  containerStyle={{ marginRight: 20 }}
                  label={i18n.t("homepage.header.faq")}
                  icon={question}
                  onPress={() => {
                    if (!showLoader && isOnline) {
                      navigation.navigate("Faq");
                    }
                  }}
                />
                {!IS_WEB_ENABLE && (
                  <TouchableButton
                    containerStyle={{ marginRight: 12 }}
                    label={i18n.t("userMenu.leave")}
                    icon={logoutIcon}
                    onPress={() => {
                      exitApplication();
                    }}
                  />
                )}
                {IS_WEB_ENABLE ? (
                  <KeycloakConsumer>
                    {(keycloakObject: KeycloakObject) => (
                      <Menu
                        showMenu={showMenu}
                        isOnline={isOnline}
                        isExamSync={showLoader}
                        items={this.getUserMenu(keycloakObject.keycloak)}
                        toggleMenu={() => {
                          toggleMenu();
                        }}
                      />
                    )}
                  </KeycloakConsumer>
                ) : (
                  <Menu
                    showMenu={showMenu}
                    isOnline={isOnline}
                    isExamSync={showLoader}
                    items={this.getUserMenu()}
                    toggleMenu={() => {
                      toggleMenu();
                    }}
                  />
                )}
              </View>
            )}
          </View>
        </View>
        <AnimatedBanner
          items={[...bannerItems]}
          setBannerItemsStatus={updateBannerItemsStatus}
        />
      </>
    );
  }
}

const styles = StyleSheet.create({
  defaultIconStyle: {
    marginHorizontal: 20,
    width: 20,
    height: 20
  },
  defaultContainerStyle: {
    zIndex: 10,
    backgroundColor: COLOR_BLUE_TESTWE,
    paddingVertical: PADDING_SIDES * 0.1,
    paddingHorizontal: PADDING_SIDES * 0.4,
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  viewStyle: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  }
});

const mapStateToProps = (state: RootState) => {
  return {
    bannerItemsStatus: state.persistedStatus.bannerItemsStatus
  };
};

const mapdispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators(
      {
        exitApplication: startExitingApplication,
        displayError: getError,
        sebNotFound: safeExamBrowserNotFound,
        updateBannerItemsStatus: setBannerItemsStatus
      },
      dispatch
    )
  };
};

export default connect(mapStateToProps, mapdispatchToProps)(Header);
