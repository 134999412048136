import React from "react";
import {
  ScrollView,
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Image
} from "react-native";
import moment from "moment";
import CustomModal from "../../atoms/CustomModal";
import i18n from "../../../services/i18n";
import {
  FONT_GILROY_BOLD,
  PADDING_SIDES
} from "../../../../static/misc/constants";
import Button from "../../molecules/Button";
import RadioButton from "../../atoms/RadioButton";
import LightText from "../../atoms/LightText";
import { checkGreen, logoTestWeBleu } from "../../../../static/misc/images";
import {
  COLOR_BLUE_TESTWE,
  COLOR_GREY_BACKGROUND,
  COLOR_RED,
  COLOR_WHITE
} from "../../../../static/misc/colors";
import SelectorField from "../../molecules/SelectorField";
import HtmlRenderer from "../../atoms/HtmlRenderer";

export interface CguModalFullscreenProps {
  isVisible: boolean;
  onShowCguModal: (bool: boolean) => void;
  rejectCgu: () => void;
  validateCgu: () => void;
  hasReadCgu: boolean;
  setHasReadCgu: () => void;
  setLocale: (locale: string) => void;
}

class CguModalFullscreen extends React.PureComponent<CguModalFullscreenProps> {
  render(): JSX.Element {
    const {
      isVisible,
      rejectCgu,
      validateCgu,
      setHasReadCgu,
      onShowCguModal,
      setLocale,
      hasReadCgu
    } = this.props;

    const i18nArray = Object.keys(i18n.translations);
    let currentIndex = 0;
    i18nArray.forEach((loc, index) => {
      if (loc === i18n.locale) {
        currentIndex = index;
      }
    });

    function changeLocale(choice: number): void {
      const newLocal = i18nArray[choice];
      i18n.locale = newLocal;
      moment.locale(newLocal);
      setLocale(newLocal);
    }

    return (
      <CustomModal
        heightModal="100vh"
        widthModal="100vw"
        borderRadiusModal={0}
        visible={isVisible}
        showCloseButton={false}
        onCloseModal={() => onShowCguModal(false)}
      >
        <View style={styles.modalContainer}>
          <View style={styles.headerContainer}>
            <View style={styles.logoContainer}>
              <Image source={logoTestWeBleu} style={[styles.logo]} />
              <SelectorField
                defaultIndex={currentIndex}
                choiceList={i18nArray.map((prop) => i18n.t(prop))}
                changeChoice={(choice) => changeLocale(choice)}
                selectorStyle={styles.selector}
              />
            </View>
            <Text style={styles.headerTitle}>{i18n.t("cgu.title")}</Text>
          </View>
          <ScrollView style={styles.scrollContainer}>
            <HtmlRenderer content={`${i18n.t("cgu.content")}`} isLinkUsable />
            <TouchableOpacity onPress={() => setHasReadCgu()}>
              <View style={styles.checkboxContainer}>
                <RadioButton isSelected={hasReadCgu} icon={checkGreen} />
                <View style={{ paddingHorizontal: PADDING_SIDES * 0.1 }} />
                <LightText text={i18n.t("cgu.checkbox")} />
              </View>
            </TouchableOpacity>
          </ScrollView>
          <View style={styles.buttonsContainer}>
            <Button
              containerStyle={[
                styles.buttons,
                {
                  backgroundColor: COLOR_GREY_BACKGROUND,
                  borderColor: COLOR_RED
                }
              ]}
              label={i18n.t("cgu.reject")}
              onPress={() => rejectCgu()}
              textStyle={{ color: COLOR_RED }}
            />
            <Button
              containerStyle={[styles.buttons]}
              label={i18n.t("cgu.accept")}
              disabled={!hasReadCgu}
              onPress={() => {
                validateCgu();
                onShowCguModal(false);
              }}
            />
          </View>
        </View>
      </CustomModal>
    );
  }
}

const styles = StyleSheet.create({
  modalContainer: {
    height: "100vh",
    width: "100vw"
  },
  buttonsContainer: {
    padding: PADDING_SIDES * 0.5,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  buttons: {
    borderWidth: 1,
    marginRight: PADDING_SIDES
  },
  checkboxContainer: {
    flex: 3,
    flexDirection: "row",
    marginLeft: "auto",
    marginRight: "auto"
  },
  scrollContainer: {
    height: "95vh",
    zIndex: 1,
    padding: PADDING_SIDES
  },
  selector: {
    backgroundColor: COLOR_WHITE
  },
  logoContainer: {
    flex: 3,
    zIndex: 5,
    flexDirection: "row",
    alignItems: "center"
  },
  headerContainer: {
    zIndex: 2,
    padding: PADDING_SIDES * 0.5
  },
  headerTitle: {
    color: COLOR_BLUE_TESTWE,
    fontFamily: FONT_GILROY_BOLD,
    fontWeight: "bold",
    zIndex: 3,
    fontSize: 24,
    marginLeft: "auto",
    marginRight: "auto"
  },
  logo: {
    marginRight: PADDING_SIDES,
    height: 25,
    width: 115
  }
});

export default CguModalFullscreen;
