import { ClearCredentialsActions } from "../../../store/rootreducer";
import { GetExamsHashAction } from "./exams";

export const SET_MEDIA_OBJECT = "SET_MEDIA_OBJECT";

export const DOWNLOAD_MEDIA_OBJECT = "DOWNLOAD_MEDIA_OBJECT";
export const DOWNLOAD_MEDIA_OBJECT_SUCCESS = "DOWNLOAD_MEDIA_OBJECT_SUCCESS";
export const DOWNLOAD_MEDIA_OBJECT_FAIL = "DOWNLOAD_MEDIA_OBJECT_FAIL";

export const UPLOAD_MEDIA_OBJECT = "UPLOAD_MEDIA_OBJECT";
export const UPLOAD_MEDIA_OBJECT_SUCCESS = "UPLOAD_MEDIA_OBJECT_SUCCESS";
export const UPLOAD_MEDIA_OBJECT_FAIL = "UPLOAD_MEDIA_OBJECT_FAIL";

export const UPLOAD_MEDIA_OBJECT_AWS = "UPLOAD_MEDIA_OBJECT_AWS";
export const UPLOAD_MEDIA_OBJECT_AWS_SUCCESS =
  "UPLOAD_MEDIA_OBJECT_AWS_SUCCESS";
export const UPLOAD_MEDIA_OBJECT_AWS_FAIL = "UPLOAD_MEDIA_OBJECT_AWS_FAIL";

export const UPLOAD_DATA_AWS = "UPLOAD_DATA_AWS";
export const UPLOAD_DATA_AWS_SUCCESS = "UPLOAD_DATA_AWS_SUCCESS";
export const UPLOAD_DATA_AWS_FAIL = "UPLOAD_DATA_AWS_FAIL";

export const UPLOAD_DATA_API = "UPLOAD_DATA_API";
export const UPLOAD_DATA_API_SUCCESS = "UPLOAD_DATA_API_SUCCESS";
export const UPLOAD_DATA_API_FAIL = "UPLOAD_DATA_API_FAIL";

export const GENERATE_SIGNED_URL = "GENERATE_SIGNED_URL";
export const GENERATE_SIGNED_URL_SUCCESS = "GENERATE_SIGNED_URL_SUCCESS";
export const GENERATE_SIGNED_URL_FAIL = "GENERATE_SIGNED_URL_FAIL";

export const CREATE_ARCHIVE_BEFORE_UPLOAD = "CREATE_ARCHIVE_BEFORE_UPLOAD";
export const CREATE_ARCHIVE_BEFORE_UPLOAD_SUCCESS =
  "CREATE_ARCHIVE_BEFORE_UPLOAD_SUCCESS";
export const CREATE_ARCHIVE_BEFORE_UPLOAD_FAIL =
  "CREATE_ARCHIVE_BEFORE_UPLOAD_FAIL";

export const CREATE_PROCTOR_ARCHIVE = "CREATE_PROCTOR_ARCHIVE";
export const CREATE_PROCTOR_ARCHIVE_SUCCESS = "CREATE_PROCTOR_ARCHIVE_SUCCESS";
export const CREATE_PROCTOR_ARCHIVE_FAIL = "CREATE_PROCTOR_ARCHIVE_FAIL";

export const CHANGE_DATA_MEDIA_OBJECT = "CHANGE_DATA_MEDIA_OBJECT";
export const UPDATE_MEDIA_OBJECT = "UPDATE_MEDIA_OBJECT";

export const REMOVE_MEDIA_OBJECT = "REMOVE_MEDIA_OBJECT";

// files type for generate signed url
export const FILE_TYPE_LOG = "log";
export const FILE_TYPE_ARCHIVE = "archive";
export const FILE_TYPE_ONBOARDING_ID = "identity";
export const FILE_TYPE_ONBOARDING_VIDEO = "video";
export const FILE_TYPE_PROCTORING_AUDIO = "audio";
export const FILE_TYPE_PROCTORING_WEBCAM = "webcam";
export const FILE_TYPE_EXAM = "file";

export const FILE_TYPE_ARCHIVE_NAME = "archive.zip";
export const FILE_TYPE_STUDENTPAPER_NAME = "studentPaper.json";

export interface UploadMediaObjectAction {
  meta?: any;
  type:
    | typeof UPLOAD_MEDIA_OBJECT
    | typeof UPLOAD_MEDIA_OBJECT_SUCCESS
    | typeof UPLOAD_MEDIA_OBJECT_FAIL
    | typeof UPLOAD_DATA_API
    | typeof UPLOAD_DATA_API_SUCCESS
    | typeof UPLOAD_DATA_API_FAIL
    | typeof GENERATE_SIGNED_URL
    | typeof GENERATE_SIGNED_URL_SUCCESS
    | typeof GENERATE_SIGNED_URL_FAIL
    | typeof UPLOAD_MEDIA_OBJECT_AWS
    | typeof UPLOAD_MEDIA_OBJECT_AWS_SUCCESS
    | typeof UPLOAD_MEDIA_OBJECT_AWS_FAIL
    | typeof UPLOAD_DATA_AWS
    | typeof UPLOAD_DATA_AWS_SUCCESS
    | typeof UPLOAD_DATA_AWS_FAIL;
  payload: any;
}

export interface RemoveMediaObjectAction {
  type: typeof REMOVE_MEDIA_OBJECT;
  payload: string;
}

export interface UpdateMediaObjectAction {
  type: typeof UPDATE_MEDIA_OBJECT;
  payload: any;
}

export interface SetMediaObjectAction {
  type: typeof SET_MEDIA_OBJECT;
  payload: any;
}

export interface DownloadMediaObjectAction {
  type:
    | typeof DOWNLOAD_MEDIA_OBJECT
    | typeof DOWNLOAD_MEDIA_OBJECT_SUCCESS
    | typeof DOWNLOAD_MEDIA_OBJECT_FAIL;
  payload: any;
  meta?: any;
}

export interface CreateArchiveBeforeUploadAction {
  type:
    | typeof CREATE_ARCHIVE_BEFORE_UPLOAD
    | typeof CREATE_ARCHIVE_BEFORE_UPLOAD_SUCCESS
    | typeof CREATE_PROCTOR_ARCHIVE_FAIL;
  payload: any;
  meta?: any;
}

export interface MediaType {
  mediaId: string;
  data?: string;
  blobUrl?: string;
  isUnciphered: boolean;
  isErrored: boolean;
  retryCount: number;
  mimeType: string;
  isDownloaded: boolean;
  isDownloading: boolean;
}
export interface MediaObjectType {
  examId: string;
  medias: MediaType[];
}

export interface AttachedFilesState {
  mediaObjects: MediaObjectType[];
}

export type AttachedFilesActionsType =
  | ClearCredentialsActions
  | UploadMediaObjectAction
  | RemoveMediaObjectAction
  | UpdateMediaObjectAction
  | DownloadMediaObjectAction
  | SetMediaObjectAction
  | GetExamsHashAction;
