import React from "react";
import { StyleSheet, Text } from "react-native";
import { COLOR_BLUE_TESTWE } from "../../../static/misc/colors";
import {
  FONTSIZE_14,
  FONT_GILROY_EXTRA_BOLD
} from "../../../static/misc/constants";
import i18n from "../../services/i18n";

export interface QuestionTitleProps {
  questionNumber: number;
}

class QuestionTitle extends React.PureComponent<QuestionTitleProps> {
  render(): JSX.Element {
    const { questionNumber } = this.props;
    return (
      <Text style={styles.defaultText}>{`${i18n.t(
        "exam.question"
      )} n°${questionNumber}`}</Text>
    );
  }
}

const styles = StyleSheet.create({
  defaultText: {
    color: COLOR_BLUE_TESTWE,
    textDecorationLine: "underline",
    fontSize: FONTSIZE_14,
    fontFamily: FONT_GILROY_EXTRA_BOLD
  }
});

export default QuestionTitle;
