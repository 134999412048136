import React from "react";
import { WIN } from "../../../static/misc/constants";
import TinyBlank from "../atoms/TinyBlank";

export interface FillInBlankProps {
  question: string | undefined;
  previousAnswer: string | undefined;
  onGetAnswer: (answer: string) => void;
}

class FillInBlank extends React.PureComponent<FillInBlankProps> {
  render(): JSX.Element {
    const { question, onGetAnswer, previousAnswer } = this.props;
    return (
      <TinyBlank
        previousAnswer={previousAnswer || question}
        height={WIN.height / 2}
        onTyping={(answer) => onGetAnswer(answer)}
      />
    );
  }
}

export default FillInBlank;
