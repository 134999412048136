import {
  CLEAR_CREDENTIALS,
  PROCTOR_NOT_SEND,
  SENT,
  STARTED
} from "../../../../static/misc/constants";
import {
  b64toblob,
  examsSplitter,
  replaceExamById
} from "../../../../static/misc/utils";
import {
  MyStudentPaperType,
  POST_STUDENT_PAPER_FAIL,
  UPDATE_STUDENT_PAPER_FAIL
} from "../../examTaking/types/studentPaper";
import { DisplayedExamsEnum, DisplayedExamsOrderEnum } from "../ExamsView";
import { ExamType } from "../types/exam";
import { SET_ESTABLISHMENT_SUCCESS } from "../../main/types/user";

import {
  CHANGE_DISPLAYED_EXAMS_ORDER,
  CHANGING_EXAM_MYSTUDPAPER_STATUS,
  DISPLAY_NEXT_EXAMS,
  DISPLAY_PASSED_EXAMS,
  ExamsActionsType,
  ExamsOrderingMeta,
  ExamsState,
  GET_EXAMS_HASH_SUCCESS,
  GET_SYNC_EXAM_SUCCESS,
  REMOVE_EXAMS,
  SET_NEXT_EXAM,
  SORT_EXAMS,
  UPDATE_SELECTED_ACADEMIC_YEAR,
  GET_MY_PAPER_B64_SUCCESS,
  CLEAR_EXAMS,
  GET_EXAM_FOR_PREVIEW_SUCCESS,
  UPDATE_PROCTOR_ARCHIVE_EXAM_STATUS,
  ExamsHash,
  GET_SYNC_PAST_EXAM_SUCCESS
} from "../types/exams";
import { END_STUDENTPAPER_SUBMISSION } from "../../main/types/status";
import { DATE } from "../../../atomic/organisms/ExamsList";
import { CREATE_EXAM_TAKING } from "../../examTaking/types/examTaking";

const initialState: ExamsState = {
  passedExams: [],
  examsHash: [],
  nextExam: undefined,
  nextExams: [],
  lastUpdate: undefined,
  displayedExams: DisplayedExamsEnum.NEXT_EXAMS,
  selectedAcademicYear: undefined,
  ordered: {
    nextExams: {
      category: DATE,
      order: DisplayedExamsOrderEnum.ASC
    },
    passedExams: {
      category: DATE,
      order: DisplayedExamsOrderEnum.DESC
    }
  }
};

export default function reducer(
  state = initialState,
  action: ExamsActionsType
): ExamsState {
  switch (action.type) {
    case CREATE_EXAM_TAKING: {
      const examTaking = { ...action.payload };
      const tmpNextExams = [...state.nextExams];
      const foundedIndex = tmpNextExams.findIndex(
        (e) => e.id === examTaking.id
      );
      if (foundedIndex >= 0 && tmpNextExams[foundedIndex]) {
        const studentPaper: MyStudentPaperType = {
          status: STARTED,
          startDate: examTaking.startDate
            ? examTaking.startDate.toString()
            : undefined
        };
        tmpNextExams[foundedIndex].myStudentPaper = studentPaper;
      }
      return {
        ...state,
        nextExams: tmpNextExams
      };
    }

    case CLEAR_EXAMS: {
      return initialState;
    }

    case GET_MY_PAPER_B64_SUCCESS: {
      const tmpPassedExams = [...state.passedExams];
      const foundedIndex = tmpPassedExams.findIndex(
        (e) =>
          e.myStudentPaper?.id ===
          action.meta.previousAction.payload.studentPaperId
      );
      if (
        foundedIndex >= 0 &&
        tmpPassedExams[foundedIndex] &&
        tmpPassedExams[foundedIndex].myStudentPaper &&
        tmpPassedExams[foundedIndex].myStudentPaper !== undefined &&
        action.payload.data.data
      ) {
        const blob = b64toblob(
          action.payload.data.data,
          action.payload.data.mimeType
        );

        (tmpPassedExams[foundedIndex]
          .myStudentPaper as MyStudentPaperType).blobUrl = URL.createObjectURL(
          blob
        );
        (tmpPassedExams[foundedIndex]
          .myStudentPaper as MyStudentPaperType).base64 =
          action.payload.data.data;
      }
      return {
        ...state,
        passedExams: tmpPassedExams
      };
    }
    case UPDATE_PROCTOR_ARCHIVE_EXAM_STATUS: {
      const tmpPassedExams = [...state.passedExams];
      (action.payload.examList as string[]).forEach((element) => {
        const foundedIndex = tmpPassedExams.findIndex((e) => e.id === element);
        if (
          action.payload.incomplete &&
          foundedIndex >= 0 &&
          tmpPassedExams[foundedIndex] &&
          tmpPassedExams[foundedIndex].myStudentPaper &&
          tmpPassedExams[foundedIndex].myStudentPaper !== undefined &&
          tmpPassedExams[foundedIndex].myStudentPaper?.status
        ) {
          (tmpPassedExams[foundedIndex]
            .myStudentPaper as MyStudentPaperType).proctorArchiveIssue = PROCTOR_NOT_SEND;
        } else if (
          !action.payload.incomplete &&
          foundedIndex >= 0 &&
          tmpPassedExams[foundedIndex]
        ) {
          (tmpPassedExams[foundedIndex]
            .myStudentPaper as MyStudentPaperType).proctorArchiveIssue = undefined;
        }
      });
      return {
        ...state,
        passedExams: tmpPassedExams
      };
    }

    case SET_NEXT_EXAM: {
      return {
        ...state,
        nextExam: action.payload
      };
    }

    case GET_SYNC_EXAM_SUCCESS:
    case GET_SYNC_PAST_EXAM_SUCCESS: {
      const newExam = action.payload.data;
      const getExamHash = state.examsHash.filter((e) => e.id === newExam.id)[0]
        .hash;
      newExam.hash = getExamHash;
      newExam.lastSync = Math.round(new Date().getTime() / 1000).toString();
      return {
        ...state,
        lastUpdate: Math.round(new Date().getTime() / 1000).toString(),
        ...replaceExamById(
          {
            nextExams: [...state.nextExams],
            passedExams: [...state.passedExams],
            nextExam: { ...state.nextExam } as ExamType | undefined
          },
          newExam
        )
      };
    }

    case GET_EXAM_FOR_PREVIEW_SUCCESS: {
      const tmp = [];
      tmp.push(action.payload.data);
      return {
        ...state,
        nextExams: tmp
      };
    }

    case END_STUDENTPAPER_SUBMISSION: {
      const tmpPassedExams = [...state.passedExams];
      const indexExamDone = tmpPassedExams.findIndex(
        (e) => e.id === action.payload?.studentPaper?.examId
      );
      if (indexExamDone >= 0)
        tmpPassedExams[indexExamDone] = {
          ...tmpPassedExams[indexExamDone],
          myStudentPaper: {
            ...tmpPassedExams[indexExamDone].myStudentPaper,
            status: SENT
          }
        };
      return {
        ...state,
        passedExams: tmpPassedExams
      };
    }

    case GET_EXAMS_HASH_SUCCESS: {
      const examsHash: ExamsHash[] = [];
      action.payload.data.map(
        (eh: { id: any; hash: any; examFromPast: string }) =>
          examsHash.push({
            id: eh.id,
            hash: eh.hash,
            examFromPast: eh.examFromPast !== "0"
          })
      );
      return {
        ...state,
        examsHash
      };
    }

    case POST_STUDENT_PAPER_FAIL:
    case UPDATE_STUDENT_PAPER_FAIL: {
      let tmpNextExam = { ...state.nextExam } as ExamType | undefined;
      const tmpNextExams = [...state.nextExams];
      const tmpPassedExams = [...state.passedExams];
      const examId =
        action.payload?.meta?.previousAction?.payload?.request?.data?.examId;
      if (
        action.payload?.meta?.previousAction?.payload?.request?.data?.endDate
      ) {
        if (tmpNextExam && tmpNextExam.id === examId) tmpNextExam = undefined;
        tmpNextExams.forEach((exam, index, array) => {
          if (exam.id === examId) {
            tmpPassedExams.push(exam);
            array.splice(index, 1);
          }
        });
      }
      return {
        ...state,
        nextExam: tmpNextExam,
        nextExams: tmpNextExams,
        passedExams: tmpPassedExams
      };
    }

    case CHANGING_EXAM_MYSTUDPAPER_STATUS: {
      const tmpNextExam = { ...state.nextExam } as ExamType;
      const tmpNextExams = [...state.nextExams];
      const tmpPassedExams = [...state.passedExams];
      if (tmpNextExam.id === action.payload.examId) {
        tmpNextExam.myStudentPaper = {
          status: action.payload.status
        };
      }
      tmpNextExams.forEach((exams, index) => {
        if (exams.id === action.payload.examId) {
          tmpNextExams[index].myStudentPaper = {
            status: action.payload.status
          };
        }
      });
      tmpPassedExams.forEach((exams, index) => {
        if (exams.id === action.payload.examId) {
          tmpPassedExams[index].myStudentPaper = {
            status: action.payload.status
          };
        }
      });
      return {
        ...state,
        ...examsSplitter([...tmpNextExams, ...tmpPassedExams])
      };
    }

    case SORT_EXAMS: {
      return {
        ...state,
        ...examsSplitter([...state.nextExams, ...state.passedExams])
      };
    }

    case REMOVE_EXAMS: {
      const nextExamsTmp = [...state.nextExams];
      let nextExams: ExamType[] = [];

      if (action.payload.exams && action.payload.exams.length > 0) {
        const toRemove = action.payload.exams as string[];
        nextExamsTmp.forEach((old) => {
          if (!toRemove.includes(old.id)) {
            nextExams.push(old);
          }
        });
      } else {
        nextExams = nextExamsTmp;
      }

      return {
        ...state,
        nextExams
      };
    }

    case DISPLAY_NEXT_EXAMS: {
      return {
        ...state,
        displayedExams: DisplayedExamsEnum.NEXT_EXAMS
      };
    }
    case DISPLAY_PASSED_EXAMS: {
      return {
        ...state,
        displayedExams: DisplayedExamsEnum.PASSED_EXAMS
      };
    }
    case UPDATE_SELECTED_ACADEMIC_YEAR: {
      return {
        ...state,
        selectedAcademicYear: action.payload.academicYear
      };
    }
    case CHANGE_DISPLAYED_EXAMS_ORDER: {
      const { ordered } = state;
      const newOrdered: ExamsOrderingMeta = {
        nextExams: {
          category: ordered.nextExams.category,
          order: ordered.nextExams.order
        },
        passedExams: {
          category: ordered.passedExams.category,
          order: ordered.passedExams.order
        }
      };

      if (action.payload.examType === DisplayedExamsEnum.NEXT_EXAMS) {
        newOrdered.nextExams.category = action.payload.category;
        newOrdered.nextExams.order = action.payload.order;
      } else {
        newOrdered.passedExams.category = action.payload.category;
        newOrdered.passedExams.order = action.payload.order;
      }
      return {
        ...state,
        ordered: newOrdered
      };
    }
    case CLEAR_CREDENTIALS:
      return initialState;
    case SET_ESTABLISHMENT_SUCCESS: {
      return {
        ...initialState
      };
    }
    default:
      return state;
  }
}
