import {
  GETSUCCESS,
  GetSuccessAction,
  ResetSuccessAction,
  RESET_SUCCESS
} from "../types/success";

export const resetSuccess = (): ResetSuccessAction => {
  return {
    type: RESET_SUCCESS,
    payload: {}
  };
};

export const getSuccess = (successMessage: string): GetSuccessAction => {
  return {
    type: GETSUCCESS,
    payload: {
      successMessage
    }
  };
};
