import React from "react";
import { StyleSheet, View } from "react-native";
import {
  COLOR_BLUE_TESTWE,
  COLOR_RED,
  COLOR_YELLOW_INFO
} from "../../../static/misc/colors";
import Button from "./Button";
import i18n from "../../services/i18n";
import AvailableTag from "./AvailableTag";
import {
  FONTSIZE_11,
  FONT_GILROY_REGULAR,
  PADDING_SIDES
} from "../../../static/misc/constants";
import { SyncStatus } from "../../modules/main/types/status";
import Tooltip from "../atoms/Tooltip";

interface ExamInfoStartExamProps {
  canStartExam: boolean;
  label?: string;
  available?: boolean;
  delayExpired: boolean;
  startLimitHour: string;
  totalFiles: number;
  downloadedFiles: number;
  showTimeOptions: boolean;
  onPress: () => void;
  isInstructionStep?: boolean;
  isLoadingExamSync?: boolean;
  currentSyncStatus?: SyncStatus;
}

class ExamInfoStartExam extends React.PureComponent<ExamInfoStartExamProps> {
  static defaultProps = {
    isLoadingExamSync: false
  };

  render(): JSX.Element {
    const {
      canStartExam,
      available,
      delayExpired,
      startLimitHour,
      label,
      totalFiles,
      downloadedFiles,
      showTimeOptions,
      isInstructionStep = false,
      onPress,
      isLoadingExamSync,
      currentSyncStatus
    } = this.props;

    const percent = (downloadedFiles * 100) / totalFiles;
    const disabledBtn =
      (totalFiles > 0 && downloadedFiles < totalFiles) ||
      !canStartExam ||
      isLoadingExamSync ||
      currentSyncStatus === SyncStatus.FAIL;
    return (
      <View style={styles.buttonsRight}>
        <View style={styles.buttonWithLoader}>
          {disabledBtn && isInstructionStep ? (
            <Tooltip
              contentText={i18n.translate("exam.startExamWait")}
              bold={false}
              placement="left-start"
            >
              <Button
                label={label || `${i18n.t("exam.startExam")}`}
                disabled
                onPress={() => onPress()}
              />
            </Tooltip>
          ) : (
            <Button
              label={label || `${i18n.t("exam.startExam")}`}
              disabled={disabledBtn}
              onPress={() => onPress()}
            />
          )}
        </View>

        {(totalFiles > 0 && downloadedFiles < totalFiles && (
          <AvailableTag
            text={`${i18n.t("homepage.incomplete", [percent.toFixed()])}`}
            dotStyle={styles.incompleteIcon}
            containerStyle={styles.availableContainer}
            textStyle={styles.availableText}
          />
        )) ||
          (!delayExpired && available && (
            <AvailableTag
              text={
                showTimeOptions
                  ? `${i18n.t("homepage.availableUntil")} ${startLimitHour}`
                  : `${i18n.t("homepage.available")}`
              }
              containerStyle={styles.availableContainer}
              textStyle={styles.availableText}
            />
          )) ||
          (delayExpired && (
            <AvailableTag
              text={`${i18n.t("homepage.unavailable")}`}
              dotStyle={styles.expiredIcon}
              containerStyle={styles.availableContainer}
              textStyle={styles.availableText}
            />
          ))}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  buttonWithLoader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  buttonsRight: {
    paddingLeft: PADDING_SIDES * 0.1,
    flexDirection: "column",
    marginRight: 120
  },
  availableContainer: {
    paddingTop: PADDING_SIDES * 0.2,
    flexBasis: "fit-content",
    alignSelf: "flex-end"
  },
  availableText: {
    color: COLOR_BLUE_TESTWE,
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_11
  },
  expiredIcon: {
    backgroundColor: COLOR_RED,
    alignSelf: "flex-start"
  },
  incompleteIcon: {
    backgroundColor: COLOR_YELLOW_INFO,
    alignSelf: "flex-start"
  }
});

export default ExamInfoStartExam;
