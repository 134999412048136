/* eslint-disable react/no-unused-state */
import React from "react";
import { TouchableOpacity, View, Text, StyleSheet } from "react-native";
import {
  COLOR_BLUE,
  COLOR_RED,
  COLOR_WHITE
} from "../../../static/misc/colors";

import {
  FONT_GILROY_REGULAR,
  PADDING_SIDES
} from "../../../static/misc/constants";
import { guidGenerator } from "../../../static/misc/utils";

interface ListPaginationProps {
  numberOfPages: number;
  pageSelected: number;
  changePage: (pageNumber: number) => void;
  nbPreviousPagesVisible: number;
}

class ListPagination extends React.PureComponent<ListPaginationProps> {
  render(): JSX.Element {
    const {
      changePage,
      pageSelected,
      nbPreviousPagesVisible,
      numberOfPages
    } = this.props;

    const pages = [];
    for (
      let page = Math.max(pageSelected - nbPreviousPagesVisible, 1);
      page <= Math.min(pageSelected + nbPreviousPagesVisible, numberOfPages);
      page += 1
    ) {
      pages.push(page);
    }
    return (
      <View style={styles.view}>
        {pageSelected > nbPreviousPagesVisible + 1 && (
          <TouchableOpacity
            key={guidGenerator()}
            onPress={() => {
              changePage(1);
            }}
          >
            <Text style={[styles.text, styles.endpoint]}>1</Text>
          </TouchableOpacity>
        )}
        {pageSelected > 1 && (
          <TouchableOpacity
            key={guidGenerator()}
            onPress={() => {
              if (pageSelected > 1) {
                changePage(pageSelected - 1);
              }
            }}
            disabled={pageSelected <= 1}
          >
            <Text style={[styles.text]}>&#9664;</Text>
          </TouchableOpacity>
        )}
        {pages.map((page) => {
          return (
            <TouchableOpacity
              key={guidGenerator()}
              onPress={() => {
                changePage(page);
              }}
            >
              <Text
                style={[
                  styles.text,
                  pageSelected === page ? styles.selectedPage : null
                ]}
              >
                {page}
              </Text>
            </TouchableOpacity>
          );
        })}
        {pageSelected < numberOfPages && (
          <TouchableOpacity
            key={guidGenerator()}
            onPress={() => {
              if (pageSelected < numberOfPages) {
                changePage(pageSelected + 1);
              }
            }}
            disabled={pageSelected >= numberOfPages}
          >
            <Text style={[styles.text]}>&#9654;</Text>
          </TouchableOpacity>
        )}
        {pageSelected < numberOfPages - nbPreviousPagesVisible && (
          <TouchableOpacity
            key={guidGenerator()}
            onPress={() => {
              changePage(numberOfPages);
            }}
          >
            <Text
              style={[styles.text, styles.endpoint]}
            >{`${numberOfPages}`}</Text>
          </TouchableOpacity>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  view: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    paddingVertical: PADDING_SIDES * 0.5,
    zIndex: -10
  },
  text: {
    paddingHorizontal: PADDING_SIDES * 0.2,
    paddingVertical: PADDING_SIDES * 0.1,
    marginHorizontal: PADDING_SIDES * 0.1,
    fontFamily: FONT_GILROY_REGULAR,
    color: COLOR_BLUE
  },
  selectedPage: {
    backgroundColor: COLOR_RED,
    borderRadius: 1000,
    color: COLOR_WHITE
  },
  endpoint: {
    color: COLOR_RED,
    fontWeight: "bold"
  }
});

export default ListPagination;
