import React from "react";

import {
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  ViewStyle
} from "react-native";
import { PADDING_SIDES } from "../../../static/misc/constants";
import ButtonLabel from "../atoms/ButtonLabel";
import i18n from "../../services/i18n";
import { COLOR_BLUE_TESTWE } from "../../../static/misc/colors";

export interface ButtonBackListProps {
  onPress: () => void;
  containerStyle?: StyleProp<ViewStyle>;
}

class ButtonBackList extends React.PureComponent<ButtonBackListProps> {
  render(): JSX.Element {
    const { onPress, containerStyle } = this.props;
    return (
      <TouchableOpacity
        style={[styles.backButtonContainer, containerStyle || {}]}
        onPress={onPress}
      >
        <ButtonLabel text={i18n.t("media.listMediaBack")} />
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  backButtonContainer: {
    paddingHorizontal: PADDING_SIDES * 0.2,
    justifyContent: "center",
    height: 25,
    borderRadius: 3,
    backgroundColor: COLOR_BLUE_TESTWE
  }
});

export default ButtonBackList;
