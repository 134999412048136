import React from "react";
import { Image, View } from "react-native";
import Webcam from "react-webcam";
import {
  HEIGHT_DEFAULT_ONBOARDING,
  MIME_JPEG,
  VIDEO_CONSTRAINTS,
  WIDTH_DEFAULT_ONBOARDING
} from "../../../static/misc/constants";
import { faceTemplate, idCardTemplate } from "../../../static/misc/images";
import { ONBOARDING_ID_PHOTO } from "../../../static/misc/onboarding/onboardingsteps";
import { ExamOnboardingStep } from "../../modules/exams/types/exam";
import { GetErrorAction } from "../../modules/main/types/error";
import i18n from "../../services/i18n";

export interface PhotoTakingProps {
  currentPhoto: string | Blob | null | undefined;
  currentOnboardingStep?: ExamOnboardingStep;
  webcamRef: React.RefObject<Webcam>;
  getError?: (message: string, forceLogout: boolean) => GetErrorAction;
  toggleLoader?: (load: boolean) => void;
}

export class PhotoTaking extends React.PureComponent<PhotoTakingProps> {
  render(): JSX.Element {
    const {
      currentPhoto,
      currentOnboardingStep,
      webcamRef,
      getError,
      toggleLoader
    } = this.props;

    const videoConstraints = {
      width: currentOnboardingStep?.width || WIDTH_DEFAULT_ONBOARDING,
      height: currentOnboardingStep?.height || HEIGHT_DEFAULT_ONBOARDING,
      facingMode: "user"
    };

    return (
      <View>
        {currentPhoto ? (
          <View style={{ position: "relative", justifyContent: "center" }}>
            <Image
              source={{ uri: currentPhoto as string }}
              style={{
                width: VIDEO_CONSTRAINTS.width,
                height: VIDEO_CONSTRAINTS.height,
                resizeMode: "contain"
              }}
            />
          </View>
        ) : (
          <View style={{ position: "relative", justifyContent: "center" }}>
            <Image
              source={
                currentOnboardingStep?.type === ONBOARDING_ID_PHOTO
                  ? idCardTemplate
                  : faceTemplate
              }
              style={[
                {
                  position: "absolute",
                  width: "100%",
                  height: "75%"
                },
                currentOnboardingStep?.type === ONBOARDING_ID_PHOTO && {
                  transform: [{ scaleX: -1 }]
                }
              ]}
            />
            <Webcam
              ref={webcamRef}
              screenshotFormat={MIME_JPEG}
              minScreenshotWidth={
                videoConstraints.width || WIDTH_DEFAULT_ONBOARDING
              }
              minScreenshotHeight={
                videoConstraints.height || HEIGHT_DEFAULT_ONBOARDING
              }
              screenshotQuality={1}
              audio={false}
              onUserMedia={() => toggleLoader && toggleLoader(false)}
              onUserMediaError={(e) => {
                if (getError) getError(i18n.t("errors.webcam", [e]), false);
              }}
              width={VIDEO_CONSTRAINTS.width}
              height={VIDEO_CONSTRAINTS.height}
              videoConstraints={videoConstraints}
            />
          </View>
        )}
      </View>
    );
  }
}
