import React from "react";
import { Image, StyleSheet, View } from "react-native";
import { PADDING_SIDES } from "../../../static/misc/constants";
import FileTransferProgressBar from "../atoms/FileTransferProgressBar";
import FileTransferSpeedLabel from "../atoms/FileTransferSpeedLabel";
import { checkGreen } from "../../../static/misc/images";

interface FileTransferProgressProps {
  completion: number;
  label: string;
  speed: number;
  validated: boolean;
}

class FileTransferProgress extends React.PureComponent<FileTransferProgressProps> {
  render(): JSX.Element {
    const { completion, label, speed, validated } = this.props;
    return (
      <View style={[styles.defaultContainerStyle]}>
        <FileTransferProgressBar
          completion={completion}
          label={label}
          style={styles.padding}
        />
        <FileTransferSpeedLabel speed={speed} style={styles.padding} />
        {validated && (
          <Image
            source={checkGreen}
            style={[styles.selectedIcon, styles.padding]}
          />
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  defaultContainerStyle: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: PADDING_SIDES * 0.2
  },
  selectedIcon: {
    width: 12,
    height: 12
  },
  padding: {
    paddingHorizontal: PADDING_SIDES * 0.1
  }
});

export default FileTransferProgress;
