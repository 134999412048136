import { IS_PREVIEW } from "../../../../static/misc/constants";
import {
  DOWNLOAD_MEDIA_OBJECTS,
  GET_SIGNED_URL,
  TESTWE_API,
  UPLOAD_MEDIA_OBJECTS
} from "../../../../static/misc/network";
import {
  SetMediaObjectAction,
  SET_MEDIA_OBJECT,
  RemoveMediaObjectAction,
  REMOVE_MEDIA_OBJECT,
  UploadMediaObjectAction,
  UPLOAD_MEDIA_OBJECT,
  UpdateMediaObjectAction,
  UPDATE_MEDIA_OBJECT,
  DownloadMediaObjectAction,
  DOWNLOAD_MEDIA_OBJECT,
  GENERATE_SIGNED_URL,
  CREATE_ARCHIVE_BEFORE_UPLOAD,
  CreateArchiveBeforeUploadAction,
  UPLOAD_MEDIA_OBJECT_AWS,
  UPLOAD_DATA_AWS,
  UPLOAD_DATA_API
} from "../types/attachedfiles";

export function setMediaObject(
  examId: string,
  mediaObjectId: string
): SetMediaObjectAction {
  return {
    type: SET_MEDIA_OBJECT,
    payload: {
      examId,
      mediaObjectId
    }
  };
}

export function createArchiveBeforeUpload(
  userId: string,
  examId: string,
  filename: string,
  archiveType: string
): CreateArchiveBeforeUploadAction {
  return {
    type: CREATE_ARCHIVE_BEFORE_UPLOAD,
    payload: {
      filename,
      archiveType,
      meta: {
        userId,
        examId
      },
      client: "ipc"
    }
  };
}

export function initUploadMediaObjectOnAWS(
  userToken: string,
  studentId: string,
  examId: string,
  filename: string,
  filetype: string,
  filepath: string,
  filedata: Buffer | null
): UploadMediaObjectAction {
  return {
    type: GENERATE_SIGNED_URL,
    payload: {
      filename,
      filepath,
      filetype,
      filedata,
      examId,
      client: "default",
      request: {
        headers: {
          accept: "application/json",
          authorization: `Bearer ${userToken}`
        },
        url: `${GET_SIGNED_URL}?studentUuid=${studentId}&examUuid=${examId}&fileName=${filename}&type=${filetype}`
      },
      method: "get"
    }
  };
}

export function uploadMediaObjectOnAWS(
  examId: string,
  questionId: string,
  filename: string,
  url: string
): UploadMediaObjectAction {
  return {
    type: UPLOAD_MEDIA_OBJECT_AWS,
    payload: {
      requestLink: url,
      filename,
      studentPaperMeta: {
        questionId,
        examId
      },
      client: "ipc"
    }
  };
}

export function uploadDataOnAWS(
  data: string,
  url: string
): UploadMediaObjectAction {
  return {
    type: UPLOAD_DATA_AWS,
    payload: {
      client: "default",
      request: {
        url,
        method: "put",
        data
      }
    }
  };
}

export function uploadMediaObjectOnAPI(
  userToken: string,
  examId: string,
  questionId: string,
  filename: string
): UploadMediaObjectAction {
  return {
    type: UPLOAD_MEDIA_OBJECT,
    payload: {
      requestLink: `${TESTWE_API}${UPLOAD_MEDIA_OBJECTS}`,
      filename,
      userToken,
      studentPaperMeta: {
        questionId,
        examId
      },
      client: "ipc"
    }
  };
}

export function uploadDataOnAPI(
  userToken: string,
  examId: string,
  questionId: string,
  form: FormData
): UploadMediaObjectAction {
  return {
    type: UPLOAD_DATA_API,
    payload: {
      studentPaperMeta: {
        questionId,
        examId
      },
      request: {
        headers: {
          accept: "application/json",
          "content-type": "multipart/form-data",
          authorization: `Bearer ${userToken}`
        },
        method: "post",
        url: `${TESTWE_API}${UPLOAD_MEDIA_OBJECTS}`,
        data: form
      }
    }
  };
}

export function updateMediaObject(
  examId: string,
  mediaObjectId: string,
  mimeType: string | undefined,
  isUnciphered = false,
  blobUrl?: string
): UpdateMediaObjectAction {
  return {
    type: UPDATE_MEDIA_OBJECT,
    payload: {
      examId,
      mediaObjectId,
      mimeType,
      isUnciphered,
      blobUrl
    }
  };
}

export function removeMediaObject(examId: string): RemoveMediaObjectAction {
  return {
    type: REMOVE_MEDIA_OBJECT,
    payload: examId
  };
}

export function downloadMediaObject(
  examId: string,
  mediaId: string,
  userToken: string
): DownloadMediaObjectAction {
  return {
    type: DOWNLOAD_MEDIA_OBJECT,
    payload: {
      examId,
      mediaId,
      client: "default",
      request: {
        headers: {
          accept: "application/json",
          authorization: `Bearer ${userToken}`
        },
        url: `${DOWNLOAD_MEDIA_OBJECTS(
          mediaId
        )}?toBase64=true&isPreview=${IS_PREVIEW}`
      },
      method: "get"
    }
  };
}
