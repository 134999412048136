import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { COLOR_BLUE_TESTWE, COLOR_WHITE } from "../../../../static/misc/colors";
import {
  FONT_GILROY_BOLD,
  FONT_GILROY_REGULAR,
  FONT_GILROY_SEMI_BOLD,
  PADDING_SIDES
} from "../../../../static/misc/constants";
import {
  checkGreen,
  settings,
  time,
  toggle
} from "../../../../static/misc/images";
import { MainNameUserProps } from "../../../modules/main/types/MainTypes";
import { CompareTimeWithServerAction } from "../../../modules/main/types/status";
import i18n from "../../../services/i18n";
import Card from "../../atoms/Card";
import CustomModal from "../../atoms/CustomModal";
import Icon from "../../atoms/Icon";
import LightText from "../../atoms/LightText";
import RadioButton from "../../atoms/RadioButton";
import WelcomeTxt from "../../atoms/WelcomeTxt";
import Button from "../../molecules/Button";

interface ServerTimeSynchronisationWebModalProps extends MainNameUserProps {
  isVisible: boolean;
  token: string | undefined;
  verifyTimeWithServer(userToken: string): CompareTimeWithServerAction;
}

interface ServerTimeSynchronisationWebModalState {
  hasSetTime: boolean;
}

export default class ServerTimeSynchronisationWebModal extends React.PureComponent<
  ServerTimeSynchronisationWebModalProps,
  ServerTimeSynchronisationWebModalState
> {
  constructor(props: ServerTimeSynchronisationWebModalProps) {
    super(props);
    this.state = {
      hasSetTime: false
    };
  }

  render(): JSX.Element {
    const {
      isVisible,
      givenName,
      familyName,
      token,
      verifyTimeWithServer
    } = this.props;
    const { hasSetTime } = this.state;
    return (
      <CustomModal
        heightModal="100vh"
        widthModal="100vw"
        borderRadiusModal={0}
        visible={isVisible}
        showCloseButton={false}
        onCloseModal={() => undefined}
      >
        <View style={styles.container}>
          <WelcomeTxt
            regularTxt={`${i18n.t("homepage.hello")} `}
            boldTxt={`${givenName} ${familyName}`}
          />
          <Text style={styles.title}>{i18n.t("homepage.wrongTimeTitle2")}</Text>
          <View style={styles.cardsList}>
            <Card style={styles.card}>
              <View style={styles.cardContainer}>
                <Icon icon={settings} iconStyle={styles.icon} />
                <Text style={styles.cardTitle}>{i18n.t("homepage.step1")}</Text>
                <Text style={styles.cardContent}>
                  {i18n.t("homepage.explanation1")}
                </Text>
              </View>
            </Card>
            <Card style={styles.card}>
              <View style={styles.cardContainer}>
                <Icon icon={time} iconStyle={styles.icon} />
                <Text style={styles.cardTitle}>{i18n.t("homepage.step2")}</Text>
                <Text style={styles.cardContent}>
                  {i18n.t("homepage.explanation2")}
                </Text>
              </View>
            </Card>
            <Card style={styles.card}>
              <View style={styles.cardContainer}>
                <Icon icon={toggle} iconStyle={styles.icon} />
                <Text style={styles.cardTitle}>{i18n.t("homepage.step3")}</Text>
                <Text style={styles.cardContent}>
                  {i18n.t("homepage.explanation3")}
                </Text>
              </View>
            </Card>
          </View>
          <View style={styles.buttonContainer}>
            <TouchableOpacity
              onPress={() => this.setState({ hasSetTime: !hasSetTime })}
            >
              <View style={styles.checkboxContainer}>
                <RadioButton isSelected={hasSetTime} icon={checkGreen} />
                <View style={{ paddingHorizontal: PADDING_SIDES * 0.1 }} />
                <View style={{ flexDirection: "row" }}>
                  <LightText text={i18n.t("homepage.checkboxText1")} />
                  <LightText text=" " />
                  <LightText
                    textStyle={{ fontFamily: FONT_GILROY_BOLD }}
                    text={i18n.t("homepage.checkboxText2")}
                  />
                </View>
              </View>
            </TouchableOpacity>
            <Button
              label={i18n.t("homepage.homepageAction")}
              onPress={() => {
                verifyTimeWithServer(token || "");
              }}
              disabled={!hasSetTime}
              containerStyle={[styles.button]}
              textStyle={[styles.buttonText]}
            />
          </View>
        </View>
      </CustomModal>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column"
  },
  cardsList: {
    flexDirection: "row",
    justifyContent: "center"
  },
  card: {
    marginHorizontal: PADDING_SIDES * 0.5,
    borderRadius: 0
  },
  cardContainer: {
    flexDirection: "column",
    maxWidth: 222,
    alignItems: "flex-start"
  },
  icon: {
    width: 48,
    height: 49,
    alignSelf: "flex-start"
  },
  cardTitle: {
    color: COLOR_BLUE_TESTWE,
    fontFamily: FONT_GILROY_BOLD,
    fontSize: 16,
    marginVertical: PADDING_SIDES * 0.25
  },
  cardContent: {
    color: COLOR_BLUE_TESTWE,
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: 12
  },
  checkboxContainer: {
    flex: 3,
    flexDirection: "row",
    marginLeft: "auto",
    marginRight: "auto"
  },
  title: {
    color: COLOR_BLUE_TESTWE,
    fontFamily: FONT_GILROY_SEMI_BOLD,
    fontSize: 25,
    margin: PADDING_SIDES * 2,
    alignSelf: "center",
    textAlignVertical: "center",
    textAlign: "center"
  },
  button: {
    margin: "20px",
    borderColor: COLOR_BLUE_TESTWE,
    backgroundColor: COLOR_BLUE_TESTWE,
    width: "max-content",
    marginTop: PADDING_SIDES
  },
  buttonText: {
    color: COLOR_WHITE,
    fontSize: 19
  },
  buttonContainer: {
    marginTop: PADDING_SIDES,
    width: "fit-content",
    display: "flex",
    textAlign: "center",
    flexDirection: "row",
    alignSelf: "center",
    textAlignVertical: "center"
  }
});
