import React from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import {
  COLOR_BLUE_TESTWE,
  COLOR_GREY_BORDER
} from "../../../static/misc/colors";
import {
  FONTSIZE_14,
  FONT_GILROY_REGULAR,
  PADDING_SIDES
} from "../../../static/misc/constants";
import LightText from "../atoms/LightText";
import { guidGenerator } from "../../../static/misc/utils";
import i18n from "../../services/i18n";

interface GridRowProps {
  containerStyle?: StyleProp<ViewStyle>;
  rowLabels: Array<string>;
}

class GridRow extends React.PureComponent<GridRowProps> {
  render(): JSX.Element {
    const { containerStyle, rowLabels } = this.props;
    rowLabels.unshift(" ");
    return (
      <View style={[styles.gridRowContainer, containerStyle]}>
        <View style={styles.rowItemStyle}>
          {rowLabels.map((item) => {
            return (
              <LightText
                key={guidGenerator()}
                text={item || i18n.t("examTaking.questions.noLabel")}
                textStyle={[styles.rowTextStyle]}
              />
            );
          })}
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  gridRowContainer: {
    flexDirection: "row",
    borderRightWidth: 1,
    paddingRight: PADDING_SIDES * 0.4,
    borderRightColor: COLOR_GREY_BORDER
  },
  rowItemStyle: {
    flexDirection: "column"
  },
  rowTextStyle: {
    paddingBottom: PADDING_SIDES * 0.3,
    alignSelf: "center",
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_14,
    color: COLOR_BLUE_TESTWE
  }
});

export default GridRow;
