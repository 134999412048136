import React, { Component } from "react";
import { StyleSheet, TextInput, TouchableOpacity, View } from "react-native";
import {
  COLOR_BLACK,
  COLOR_BLUE,
  COLOR_GREY_STEPPER,
  COLOR_WHITE
} from "../../../static/misc/colors";
import { PADDING_SIDES } from "../../../static/misc/constants";
import { comment } from "../../../static/misc/images";
import i18n from "../../services/i18n";
import Icon from "../atoms/Icon";
import Button from "./Button";

interface PdfViewerTipProps {
  onConfirm: (comment: string) => void;
  onOpen: () => void;
  onUpdate?: () => void;
}

interface PdfViewerTipState {
  compact: boolean;
  text: string;
}

class PdfViewerTip extends Component<PdfViewerTipProps, PdfViewerTipState> {
  constructor(props: PdfViewerTipProps) {
    super(props);
    this.state = {
      compact: true,
      text: ""
    };
  }

  // for TipContainer
  componentDidUpdate(
    nextProps: PdfViewerTipProps,
    nextState: PdfViewerTipState
  ): void {
    const { onUpdate } = this.props;
    const { compact } = this.state;

    if (onUpdate && compact !== nextState.compact) {
      onUpdate();
    }
  }

  render(): JSX.Element {
    const { onConfirm, onOpen } = this.props;
    const { compact, text } = this.state;

    return (
      <View>
        {compact ? (
          <TouchableOpacity
            onPress={() => {
              onOpen();
              this.setState({ compact: false });
            }}
          >
            <Icon icon={comment} iconStyle={{ width: 30, height: 30 }} />
          </TouchableOpacity>
        ) : (
          <View style={styles.tipCard}>
            <View style={styles.inputFrame}>
              <TextInput
                style={styles.input}
                placeholder={i18n.translate("media.placeholder")}
                onChangeText={(input) => {
                  this.setState({ text: input });
                }}
                value={text}
                multiline
                editable
                numberOfLines={4}
              />
            </View>
            <Button
              containerStyle={[styles.buttonStyle]}
              textStyle={styles.buttonTextStyle}
              label={i18n.translate("media.ok")}
              onPress={() => onConfirm(text)}
            />
          </View>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  tipCard: {
    padding: 10,
    backgroundColor: COLOR_WHITE,
    border: 1,
    borderColor: COLOR_GREY_STEPPER,
    borderRadius: 4,
    shadowColor: COLOR_BLACK,
    shadowOffset: {
      width: 2,
      height: 4
    },
    shadowOpacity: 0.2
  },
  inputFrame: {
    borderColor: COLOR_GREY_STEPPER,
    borderWidth: 1,
    borderRadius: 1,
    marginBottom: 10
  },
  buttonStyle: {
    backgroundColor: COLOR_BLUE,
    borderColor: COLOR_BLUE
  },
  buttonTextStyle: {
    fontSize: 10
  },
  input: {
    padding: PADDING_SIDES * 0.1
  }
});

export default PdfViewerTip;
