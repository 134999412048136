import React from "react";
import { StyleProp, StyleSheet, Text, TextStyle } from "react-native";
import { COLOR_BLUE } from "../../../static/misc/colors";
import {
  FONTSIZE_16,
  FONT_GILROY_EXTRA_BOLD
} from "../../../static/misc/constants";

interface ExtraBoldTitleProps {
  textStyle?: StyleProp<TextStyle>;
  text: string;
}

class ExtraBoldTitle extends React.PureComponent<ExtraBoldTitleProps> {
  render(): JSX.Element {
    const { textStyle, text } = this.props;
    return <Text style={[styles.defaultExtraBoldTxt, textStyle]}>{text}</Text>;
  }
}

const styles = StyleSheet.create({
  defaultExtraBoldTxt: {
    fontFamily: FONT_GILROY_EXTRA_BOLD,
    fontSize: FONTSIZE_16,
    color: COLOR_BLUE
  }
});

export default ExtraBoldTitle;
