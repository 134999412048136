import { useKeycloak } from "@react-keycloak/web";
import Keycloak from "keycloak-js";
import { FunctionComponent } from "react";

import { KEYCLOAK_BASE_URL } from "../../../static/misc/network";

export const keycloakLoaded = Keycloak({
  url: `${KEYCLOAK_BASE_URL}/auth`,
  clientId: "studentweb",
  realm: "users"
});

export type KeycloakObject = {
  initialized: boolean;
  keycloak: Keycloak.KeycloakInstance;
};

type KeycloakChildren = (keycloak: KeycloakObject) => any;

interface IScreenWidthProps {
  children: KeycloakChildren;
}

export const KeycloakConsumer: FunctionComponent<IScreenWidthProps> = ({
  children
}) => {
  const keycloak: KeycloakObject = useKeycloak();

  return children(keycloak);
};
