import React from "react";
import { StyleSheet, View, Text } from "react-native";
import CustomModal from "../atoms/CustomModal";
import { COLOR_BLUE_TESTWE } from "../../../static/misc/colors";
import {
  FONT_GILROY_BOLD,
  FONTSIZE_21,
  PADDING_SIDES
} from "../../../static/misc/constants";
import Loader from "../atoms/Loader";

export interface LoadingModalProps {
  isVisible: boolean;
  loadingMessage: string;
}

class LoadingModal extends React.PureComponent<LoadingModalProps> {
  render(): JSX.Element {
    const { isVisible, loadingMessage } = this.props;
    return (
      <CustomModal
        heightModal="20%"
        widthModal="50%"
        visible={isVisible}
        showCloseButton={false}
        onCloseModal={() => null}
      >
        <View style={styles.contentContainer}>
          <Text style={styles.textStyle}>{loadingMessage}</Text>
          <Loader iconStyle={styles.loaderIcon} />
        </View>
      </CustomModal>
    );
  }
}

const styles = StyleSheet.create({
  contentContainer: {
    padding: PADDING_SIDES,
    alignContent: "center"
  },
  textStyle: {
    fontFamily: FONT_GILROY_BOLD,
    fontSize: FONTSIZE_21,
    color: COLOR_BLUE_TESTWE,
    width: "100%",
    textAlign: "center",
    paddingBottom: PADDING_SIDES * 0.5
  },
  loaderIcon: {
    height: 30,
    width: 30
  }
});

export default LoadingModal;
