import { Dispatch } from "react";
import { Action, Middleware, MiddlewareAPI } from "redux";
import moment from "../../services/moment";
import { askRefreshToken } from "../../modules/authentification/actions/auth";
import {
  REFRESH_TOKEN,
  REFRESH_TOKEN_FAIL
} from "../../modules/authentification/types/auth";

interface EffectAction extends Action {
  error: {
    response: string;
  };
  payload: any;
}

// Homemade middleware, in oder to print redux FAILURE or ERROR into the logger (in app data)
const refreshTokenMiddleware: Middleware = (store: MiddlewareAPI<any>) => (
  next: Dispatch<EffectAction>
) =>
  ((action: EffectAction) => {
    if (action.type !== REFRESH_TOKEN && action.type !== REFRESH_TOKEN_FAIL) {
      const currentDate = moment().unix();
      // eslint-disable-next-line camelcase
      const refreshToken = store.getState().auth.credentials?.refresh_token;
      const expireToken = store.getState().auth.credentials?.exp as number;
      const alreadyRefreshing = store.getState().status.isRefreshingToken;
      // refreshing token if it expires in the next 10 minutes
      if (
        !alreadyRefreshing &&
        refreshToken &&
        expireToken &&
        expireToken <= currentDate + 600
      ) {
        store.dispatch(askRefreshToken(refreshToken));
      }
    }
    return next(action);
  }) as Dispatch<EffectAction>;

export default refreshTokenMiddleware;
