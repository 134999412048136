import React from "react";
import { StyleSheet, View, Text } from "react-native";

import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { COLOR_BLUE_TESTWE, COLOR_WHITE } from "../../../static/misc/colors";
import {
  FONTSIZE_16,
  FONTSIZE_24,
  FONT_GILROY_BOLD,
  FONT_GILROY_REGULAR,
  PADDING_SIDES
} from "../../../static/misc/constants";
import i18n from "../../services/i18n";
import CustomInput from "../atoms/CustomInput";
import YellowBanner from "../atoms/YellowBanner";
import Button from "./Button";
import { ExitApplicationAction } from "../../modules/main/types/status";
import { startExitingApplication } from "../../modules/main/actions/status";

export interface ContentModalConfirmUserProps {
  onValidation: () => void;
  username: string | undefined;
  description: string;
  boldText: string;
  textBanner: string;
  labelButton: string;
  descriptionNext: string;
  exitApplication: (action?: string) => ExitApplicationAction;
}

interface ContentModalConfirmUserState {
  inputUsername: string;
  error: boolean;
}

class ContentModalConfirm extends React.PureComponent<
  ContentModalConfirmUserProps,
  ContentModalConfirmUserState
> {
  constructor(props: ContentModalConfirmUserProps) {
    super(props);
    this.state = {
      error: false,
      inputUsername: ""
    };
  }

  validateUsername(): void {
    const { username, onValidation } = this.props;
    const { inputUsername } = this.state;
    if (inputUsername === username) onValidation();
    else this.setState({ error: true });
  }

  render(): JSX.Element {
    const { error } = this.state;
    const {
      labelButton,
      boldText,
      description,
      descriptionNext,
      textBanner,
      exitApplication
    } = this.props;

    return (
      <View style={styles.modalContentContainer}>
        <View style={{ width: "100%" }}>
          <Text
            style={{
              paddingHorizontal: PADDING_SIDES
            }}
          >
            <Text style={styles.description}>{description}</Text>
            <Text style={styles.descriptionBold}>{boldText}</Text>
            <Text style={styles.description}>{descriptionNext}</Text>
          </Text>
          {error && (
            <>
              <View style={{ paddingVertical: 7 }} />
              <YellowBanner textBanner={textBanner} />
            </>
          )}
        </View>
        <CustomInput
          width="60%"
          onChangeText={(a) => this.setState({ inputUsername: a })}
          placeholder={boldText}
          fontSize={FONTSIZE_24}
        />
        <View style={styles.buttonsContainer}>
          <Button
            label={labelButton}
            containerStyle={[styles.buttonConfirm]}
            textStyle={styles.buttonConfirmTextStyle}
            onPress={() => this.validateUsername()}
          />
          <View style={{ paddingVertical: 7 }} />
          <Button
            label={i18n.t("userMenu.leave")}
            containerStyle={[styles.leaveButton]}
            onPress={() => {
              exitApplication();
            }}
          />
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  modalContentContainer: {
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 50
  },
  leaveButton: {
    width: "50%"
  },
  buttonConfirm: {
    width: "50%",
    backgroundColor: COLOR_WHITE,
    borderWidth: 1,
    borderColor: COLOR_BLUE_TESTWE
  },
  buttonsContainer: {
    alignItems: "center",
    width: "100%",
    paddingHorizontal: PADDING_SIDES
  },
  buttonConfirmTextStyle: {
    color: COLOR_BLUE_TESTWE
  },
  description: {
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_16,
    color: COLOR_BLUE_TESTWE
  },
  descriptionBold: {
    fontFamily: FONT_GILROY_BOLD,
    fontSize: FONTSIZE_16,
    color: COLOR_BLUE_TESTWE
  }
});

const mapStateToProps = () => {
  return {};
};

const mapdispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators(
      {
        exitApplication: startExitingApplication
      },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapdispatchToProps
)(ContentModalConfirm);
