import { ipcRenderer } from "electron";
import * as CryptoJS from "crypto-js";

const uncipher = async (
  blob: string,
  publicKey: string
): Promise<string | null> => {
  try {
    if (!blob || !blob.includes("|")) {
      ipcRenderer.send(
        "error",
        `File ${blob} could not be read, cannot uncipher`
      );
      return null;
    }

    // preparing file for unciphering
    const explodedStr = blob.split("|");
    if (explodedStr.length !== 3) {
      ipcRenderer.send(
        "error",
        `Incorrect data format for file ${blob}, cannot uncipher`
      );
      return null;
    }

    return mediaUnciphering(publicKey, explodedStr);
  } catch (err) {
    ipcRenderer.send(
      "error",
      `An exception occurred while unciphering media ${blob}`,
      JSON.stringify(err)
    );
    return null;
  }
};

const mediaUnciphering = (publicKey: string, explodeStr: string[]) => {
  const cp = CryptoJS.lib.CipherParams.create({
    ciphertext: CryptoJS.enc.Base64.parse(explodeStr[0])
  });
  const decrypted = CryptoJS.AES.decrypt(
    cp,
    CryptoJS.enc.Hex.parse(CryptoJS.SHA256(publicKey).toString()),
    {
      mode: CryptoJS.mode.CBC,
      iv: CryptoJS.enc.Base64.parse(explodeStr[2])
    }
  );
  return decrypted.toString(CryptoJS.enc.Utf8);
};

const uncipherMedia = async (
  blob: string,
  publicKey: string
): Promise<string | null> => {
  try {
    const res = await uncipher(blob, publicKey);
    return res;
  } catch (err) {
    ipcRenderer.send(
      "error",
      `An exception occurred while trying to uncipher media : ${blob} ${publicKey}`,
      JSON.stringify(err)
    );
    return null;
  }
};

export default uncipherMedia;
