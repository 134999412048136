import React from "react";
import { Image, ImageStyle, StyleProp, StyleSheet } from "react-native";
import { logoTestWe } from "../../../static/misc/images";

interface LogoProps {
  logoStyle?: StyleProp<ImageStyle>;
}

class Logo extends React.PureComponent<LogoProps> {
  render(): JSX.Element {
    const { logoStyle } = this.props;
    return (
      <Image source={logoTestWe} style={[styles.defaultLogoStyle, logoStyle]} />
    );
  }
}

const styles = StyleSheet.create({
  defaultLogoStyle: {
    width: 120,
    height: 30,
    resizeMode: "contain"
  }
});

export default Logo;
