import React from "react";

import { ReactKeycloakProvider } from "@react-keycloak/web";
import jwtDecode from "jwt-decode";
import { keycloakLoaded } from "../../services/web/keycloak";
import { CredentialsType } from "../authentification/types/auth";

interface KeycloakWrapperProps {
  children: React.ReactNode;
  onSetCredentials: (cred: CredentialsType | undefined) => void;
}

// interface KeycloakWrapperState {}

class KeycloakWrapper extends React.Component<KeycloakWrapperProps> {
  constructor(props: KeycloakWrapperProps) {
    super(props);

    this.state = {};
  }

  render(): JSX.Element {
    const { children, onSetCredentials } = this.props;

    return (
      <ReactKeycloakProvider
        authClient={keycloakLoaded}
        onTokens={(tokens) => {
          if (tokens && tokens.token)
            onSetCredentials({
              ...jwtDecode(tokens.token),
              id_token: tokens.idToken,
              refresh_token: tokens.refreshToken,
              access_token: tokens.token
            });
          else onSetCredentials(undefined);
        }}
        LoadingComponent={<div />}
        initOptions={{ onLoad: "login-required", checkLoginIframe: false }}
      >
        {children}
      </ReactKeycloakProvider>
    );
  }
}

export default KeycloakWrapper;
