import React from "react";
import Iframe from "react-iframe";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import { FONTSIZE_21, PADDING_SIDES } from "../../../static/misc/constants";
import { FAQ_LINK } from "../../../static/misc/network";
import { StackNavigatorProp } from "../../router/StackNavigator";
import i18n from "../../services/i18n";
import RegularText from "./RegularText";

interface FaqWebviewProps {
  navigation: StackNavigatorProp;
}

class FaqWebview extends React.PureComponent<FaqWebviewProps> {
  getFAQUrl = (authorizedLocale: string[]): string => {
    const currentLang = authorizedLocale.includes(i18n.locale)
      ? i18n.locale
      : "en";

    const currentStudentTranslation =
      !i18n.t("studentFaqUrl").includes("[missing") &&
      authorizedLocale.includes(i18n.locale)
        ? i18n.t("studentFaqUrl")
        : "student";

    return `${FAQ_LINK}${currentLang}/${currentStudentTranslation}`;
  };

  render(): JSX.Element {
    const { navigation } = this.props;
    const authorizedLocale = ["fr", "pt", "en", "es"];
    return (
      <View style={styles.container}>
        <TouchableOpacity onPress={navigation.goBack} style={styles.touchable}>
          <RegularText
            text={i18n.t("exam.back")}
            textStyle={[styles.textStyle]}
          />
        </TouchableOpacity>
        <Iframe
          width="100%"
          height="100%"
          url={this.getFAQUrl(authorizedLocale)}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    height: "100%"
  },
  touchable: {
    alignSelf: "flex-end",
    paddingHorizontal: PADDING_SIDES
  },
  textStyle: {
    fontSize: FONTSIZE_21
  }
});

export default FaqWebview;
