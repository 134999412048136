import React, { ReactNode } from "react";
import {
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle
} from "react-native";
import { COLOR_BLACK, COLOR_BLUE_TESTWE } from "../../../static/misc/colors";

export interface CardProps {
  selected?: boolean;
  style?: StyleProp<ViewStyle>;
  onPress?: () => void;
  children: ReactNode;
}

export default class Card extends React.PureComponent<CardProps> {
  render(): JSX.Element {
    const { selected, children, style, onPress } = this.props;
    return onPress ? (
      <TouchableOpacity
        style={[
          styles.card,
          selected ? styles.cardSelected : styles.cardNotSelected,
          style
        ]}
        onPress={onPress}
      >
        {children}
      </TouchableOpacity>
    ) : (
      <View
        style={[
          styles.card,
          selected ? styles.cardSelected : styles.cardNotSelected,
          style
        ]}
      >
        {children}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  card: {
    borderRadius: 20,
    padding: 20
  },
  cardSelected: {
    borderColor: COLOR_BLUE_TESTWE,
    borderWidth: 1
  },
  cardNotSelected: {
    shadowColor: COLOR_BLACK,
    shadowOffset: { width: 0, height: 20 },
    shadowOpacity: 0.1,
    shadowRadius: 10
  }
});
