import React from "react";
import { View, StyleSheet } from "react-native";
import GridSingleColumn from "./GridSingleColumn";
import GridRow from "../molecules/GridRow";
import {
  GridLabel,
  ExamChoice,
  ChoiceGridWithSelection
} from "../../modules/exams/types/exam";

export interface GridSingleQuestionProps {
  columnLabels: GridLabel[];
  previousAnswer?: Array<string>;
  givenChoices?: Array<string>;
  changeSelection: (
    rowItems: ChoiceGridWithSelection[][],
    choices: Array<ExamChoice>,
    columnLabels: GridLabel[]
  ) => void;
  rowLabels: GridLabel[];
  choices: ExamChoice[];
}

class GridSingleQuestion extends React.PureComponent<GridSingleQuestionProps> {
  render(): JSX.Element {
    const {
      columnLabels,
      rowLabels,
      choices,
      givenChoices,
      changeSelection,
      previousAnswer
    } = this.props;
    const rowName = rowLabels.map((item) => {
      return item.name;
    });
    return (
      <View style={styles.gridSingleQuestionStyle}>
        <GridRow rowLabels={rowName} />
        <GridSingleColumn
          previousAnswer={previousAnswer}
          givenChoices={givenChoices}
          columns={columnLabels}
          rows={rowLabels}
          choices={choices}
          changeSelection={(
            rowItems: ChoiceGridWithSelection[][],
            choicesList: ExamChoice[],
            columnLab: GridLabel[]
          ) => changeSelection(rowItems, choicesList, columnLab)}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  gridSingleQuestionStyle: {
    flexDirection: "row"
  }
});
export default GridSingleQuestion;
