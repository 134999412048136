// cf https://www.twilio.com/blog/troubleshooting-twilio-video-app for Twilio bandwidth requirements
const downloadSpeeds = {
  minimum: 180,
  recommanded: 250
};

const uploadSpeeds = {
  minimum: 36,
  recommanded: 50
};

export default function getSpeedRanking(
  type: "download" | "upload",
  speed: number
): number {
  if (type === "download") {
    if (speed < downloadSpeeds.minimum) return 0;
    if (speed < downloadSpeeds.recommanded) return 1;
    return 2;
  }
  if (speed < uploadSpeeds.minimum) return 0;
  if (speed < uploadSpeeds.recommanded) return 1;
  return 2;
}
