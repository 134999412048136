import React from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { COLOR_BLUE_TESTWE } from "../../../static/misc/colors";
import { guidGenerator } from "../../../static/misc/utils";
import {
  FONTSIZE_14,
  FONT_GILROY_REGULAR,
  PADDING_SIDES
} from "../../../static/misc/constants";
import LightText from "../atoms/LightText";
import QcmItem from "../molecules/QcmItem";
import {
  GridLabel,
  ChoiceGridWithSelection,
  ExamChoice
} from "../../modules/exams/types/exam";
import i18n from "../../services/i18n";

export interface GridMultipleColumnState {
  rowItems: ChoiceGridWithSelection[][];
}

export interface GridMultipleColumnProps {
  previousAnswer?: Array<string>;
  columns: Array<GridLabel>;
  rows: Array<GridLabel>;
  changeSelection: (
    rowItems: ChoiceGridWithSelection[][],
    choices: Array<ExamChoice>,
    columnLabels: GridLabel[]
  ) => void;
  choices: Array<ExamChoice>;
}

class GridMultipleColumn extends React.PureComponent<
  GridMultipleColumnProps,
  GridMultipleColumnState
> {
  constructor(props: GridMultipleColumnProps) {
    super(props);
    const { rows, columns, previousAnswer, choices } = this.props;
    this.state = {
      rowItems: columns.map(() => {
        return rows.map((v) => ({ ...v, isSelected: false }));
      })
    };
    if (previousAnswer) {
      const current = previousAnswer.map((currentChoice) => {
        const currentChoices = choices.find(
          (choice) => choice.id === currentChoice
        );
        return currentChoices;
      });
      const { rowItems } = this.state;
      const listTmp = [...rowItems];
      columns.map((columLab, columIndex) => {
        current.map((currentChoice) => {
          if (columLab.index === currentChoice?.columnIndex) {
            rows.map((rowLab, rowIndex) => {
              if (rowLab.index === currentChoice?.rowIndex) {
                listTmp[columIndex][rowIndex].isSelected = true;
                this.state = {
                  rowItems: listTmp
                };
              }
              return rowLab;
            });
          }
          return currentChoice;
        });
        return columLab;
      });
    }
  }

  // Updating the selected answer
  changeSelectioner(
    columnPos: number,
    rowIndex: number,
    rowLength: number
  ): void {
    const { rowItems } = this.state;
    const listTmp = [...rowItems];
    if (
      listTmp[columnPos][rowIndex].name ===
        i18n.t("examTaking.questions.noGoodAnswers") &&
      listTmp[columnPos][rowIndex].isSelected === false
    ) {
      listTmp[columnPos].map((_item, index) => {
        listTmp[columnPos][index].isSelected = false;
        return listTmp;
      });
    }
    for (let i = 0; i < rowLength; i++) {
      if (
        listTmp[columnPos][i].name ===
          i18n.t("examTaking.questions.noGoodAnswers") &&
        listTmp[columnPos][i].isSelected === true
      ) {
        listTmp[columnPos][i].isSelected = false;
      }
    }

    listTmp[columnPos][rowIndex].isSelected = !listTmp[columnPos][rowIndex]
      .isSelected;
    this.setState(
      {
        rowItems: listTmp
      },
      () => {
        this.forceUpdate(); // TODO one day, using forceUpdate is not a good thing
      }
    );
  }

  render(): JSX.Element {
    const { columns, rows, changeSelection, choices } = this.props;
    const { rowItems } = this.state;
    return (
      <ScrollView horizontal style={styles.scrollViewStyle}>
        {columns.map((column, columnPos) => {
          return (
            <View key={guidGenerator()} style={styles.columnItemStyle}>
              <LightText
                text={column.name || i18n.t("examTaking.questions.noLabel")}
                textStyle={[styles.textStyle]}
              />
              {rows.map((_rowName, rowPos) => {
                return (
                  <QcmItem
                    key={guidGenerator()}
                    containerStyle={styles.container}
                    isSelected={rowItems[columnPos][rowPos].isSelected}
                    onSelect={() => {
                      this.changeSelectioner(columnPos, rowPos, rows.length);
                      changeSelection(rowItems, choices, columns);
                    }}
                  />
                );
              })}
            </View>
          );
        })}
      </ScrollView>
    );
  }
}

const styles = StyleSheet.create({
  scrollViewStyle: {
    flexDirection: "row",
    paddingLeft: PADDING_SIDES
  },
  columnItemStyle: {
    paddingRight: PADDING_SIDES
  },
  container: {
    flexDirection: "row",
    paddingBottom: PADDING_SIDES * 0.3,
    alignSelf: "center"
  },
  textStyle: {
    fontFamily: FONT_GILROY_REGULAR,
    flexWrap: "wrap",
    paddingBottom: PADDING_SIDES * 0.3,
    alignItems: "flex-start",
    fontSize: FONTSIZE_14,
    color: COLOR_BLUE_TESTWE
  }
});

export default GridMultipleColumn;
