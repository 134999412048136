import React from "react";
import {
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle
} from "react-native";
import { COLOR_BLUE_TESTWE } from "../../../static/misc/colors";
import {
  FONTSIZE_14,
  FONT_GILROY_REGULAR,
  PADDING_SIDES
} from "../../../static/misc/constants";
import LightText from "../atoms/LightText";
import SquareButton from "../atoms/SquareButton";

interface QcmItemProps {
  isSelected?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  onSelect: () => void;
  answer?: string;
}

class QcmItem extends React.PureComponent<QcmItemProps> {
  static defaultProps = {
    isSelected: false
  };

  render(): JSX.Element {
    const { isSelected, onSelect, answer, containerStyle } = this.props;
    return (
      <TouchableOpacity
        onPress={() => onSelect()}
        style={[styles.defaultContainer, containerStyle]}
      >
        <SquareButton isSelected={isSelected} />
        <View style={{ paddingHorizontal: PADDING_SIDES * 0.1 }} />
        {answer && <LightText text={answer} textStyle={[styles.answerStyle]} />}
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  defaultContainer: {
    flexDirection: "row"
  },
  answerStyle: {
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_14,
    color: COLOR_BLUE_TESTWE
  }
});

export default QcmItem;
