import React from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { connect } from "react-redux";
import { COLOR_WHITE } from "../../../../static/misc/colors";
import UpcomingExamItem from "../../organisms/UpcomingExamItem";
import { guidGenerator } from "../../../../static/misc/utils";
import { PADDING_SIDES, WIN } from "../../../../static/misc/constants";
import TitleAndTagsExam from "../../molecules/TitleAndTagsExam";
import i18n from "../../../services/i18n";
import ExtraBoldTitle from "../../atoms/ExtraBoldTitle";
import { UpdateDisplayedExamsAction } from "../../../modules/exams/types/exams";
import moment from "../../../services/moment";
import { DisplayedExamsEnum } from "../../../modules/exams/ExamsView";
import { ExamType } from "../../../modules/exams/types/exam";
import { StackNavigatorProp } from "../../../router/StackNavigator";
import ExamListPlaceholder from "../../molecules/ExamListPlaceholder";
import { MediaObjectType } from "../../../modules/exams/types/attachedfiles";
import { StudentPaperType } from "../../../modules/examTaking/types/studentPaper";
import { RootState } from "../../../store/rootreducer";
import { UserState } from "../../../modules/main/types/user";
import { hasStudentPaper } from "../../../modules/exams/utils";

export interface UpcomingExamsProps {
  containerStyle?: StyleProp<ViewStyle>;
  navigation: StackNavigatorProp;
  isOnline: boolean;
  nextExams: Array<ExamType>;
  attachedFiles: MediaObjectType[];
  studentPapers: StudentPaperType[];
  currentExamTaking: ExamType[] | undefined;
  updateDisplayedExams: (
    examType: DisplayedExamsEnum
  ) => UpdateDisplayedExamsAction;
  displaySingleExamInfo?: (examInfoId: string) => void;
  currentUser: UserState;
}

class UpcomingExams extends React.PureComponent<UpcomingExamsProps> {
  render(): JSX.Element {
    const {
      containerStyle,
      isOnline,
      navigation,
      nextExams,
      attachedFiles,
      currentExamTaking,
      studentPapers,
      updateDisplayedExams,
      displaySingleExamInfo,
      currentUser
    } = this.props;
    const nextExamsTmp = [...nextExams].sort((a, b) => {
      return moment(a.startDate).isBefore(b.startDate)
        ? -1
        : moment(a.startDate).isAfter(b.startDate)
        ? 1
        : 0;
    });

    function isTakingExam(exam: ExamType) {
      let isTaking = false;
      isTaking = currentExamTaking
        ? currentExamTaking.filter((examTaking) => {
            return examTaking.id === exam.id;
          }).length > 0
        : false;
      isTaking = !isTaking
        ? studentPapers.filter((es: StudentPaperType) => {
            return es.examId === exam.id;
          }).length > 0
        : true;
      return isTaking;
    }

    return (
      <View style={[styles.defaultContainerStyle, containerStyle]}>
        {(isOnline && (
          <TitleAndTagsExam
            title={`${i18n.t("homepage.nextexam2")} (${nextExams.length})`}
            rightText={`${i18n.t("homepage.allexam")} (${nextExams.length})`}
            onPress={() => {
              updateDisplayedExams(DisplayedExamsEnum.NEXT_EXAMS);
              navigation.reset({
                index: 0,
                routes: [
                  {
                    name: "Exams" as any
                  }
                ]
              });
            }}
          />
        )) || <ExtraBoldTitle text={i18n.t("homepage.nextexam2")} />}

        {(nextExamsTmp &&
          nextExamsTmp.length > 0 &&
          nextExamsTmp.map((exam, index) => {
            if (index >= 3 || exam === null) return null;
            return (
              <UpcomingExamItem
                key={guidGenerator()}
                examId={exam.id}
                containerStyle={styles.examWrapper}
                duration={exam.duration}
                hasExamTaking={isTakingExam(exam)}
                hasStudentPaper={hasStudentPaper(
                  exam.id,
                  currentUser.id,
                  studentPapers
                )}
                attachedFilesNumber={exam.allAttachedFiles?.length || 0}
                timeZeroSecond={exam.timeZeroSecond}
                subject={`${exam.name} - ${exam.subject?.name || ""}`}
                startDate={new Date(exam.startDate)}
                startTime={new Date(exam.startDate)}
                examParams={exam.examParams}
                displaySingleExamInfo={displaySingleExamInfo}
                currentAttachedFiles={attachedFiles.find(
                  (file) => file.examId === exam.id
                )}
              />
            );
          })) || (
          <ExamListPlaceholder text={i18n.t("homepage.nextExamsPlaceholder")} />
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  defaultContainerStyle: {
    width: "48%",
    minWidth: WIN.width * 0.4,
    marginTop: PADDING_SIDES,
    flexDirection: "column",
    backgroundColor: COLOR_WHITE
  },
  examWrapper: {
    marginVertical: 5
  }
});

export default connect((state: RootState) => {
  return {
    studentPapers: state.studentPaper.studentPapers,
    currentUser: state.user
  };
})(UpcomingExams);
