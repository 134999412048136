import React from "react";
import TinyEditor from "./TinyEditor";

export type TypingContent = {
  content: string;
  htmlContent: string;
};

interface TinySelectorProps {
  onTyping: (content: string) => void;
  previousAnswer?: string;
  width?: string | number;
  height?: string | number;
}

interface TinySelectorState {
  content: string;
}

export default class TinySelector extends React.Component<
  TinySelectorProps,
  TinySelectorState
> {
  static defaultProps = {
    width: "100%",
    height: "100%"
  };

  constructor(props: TinySelectorProps) {
    super(props);
    const { previousAnswer } = this.props;
    this.state = {
      content: previousAnswer || ""
    };
    this.handleClick = this.handleClick.bind(this);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  handleClick(evt: any, editor: any): void {
    const { onTyping } = this.props;
    editor
      .getBody()
      .querySelectorAll("select")
      .forEach((el: any) => {
        el.addEventListener("change", (ev: any) => {
          const input = ev.target;
          input.setAttribute("value", input.value);
          for (let i = 0; i < input.children.length; i += 1) {
            if (
              input.children[i].getAttribute("selected") === "selected" &&
              input.children[i].innerText !== input.value
            ) {
              input.children[i].removeAttribute("selected");
            } else if (
              input.children[i].getAttribute("selected") !== "selected" &&
              input.children[i].innerText === input.value
            ) {
              input.children[i].setAttribute("selected", "selected");
            }
          }
          onTyping(evt.view.document.body.innerHTML);
        });
      });
  }

  render(): JSX.Element {
    const { content } = this.state;
    const { width, height } = this.props;
    return (
      <TinyEditor
        init={{
          height,
          width,
          menubar: false,
          toolbar: false,
          setup(editor: any) {
            editor.on("keydown", (e: any) => {
              if (e.target.nodeName.toLowerCase() !== "select") {
                e.preventDefault();
              }
            });
          }
        }}
        value={content}
        handleClick={this.handleClick}
      />
    );
  }
}
