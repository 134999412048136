import _ from "lodash";

import { Middleware } from "redux";

import timeoutCallback from "timeout-callback";
import { DURATION_TIMEOUT_SOCKET } from "../../../static/misc/constants";
import {
  WS_EVENTS_LISTEN_ARRAY,
  WS_GET_ROOM_AGAIN_SUCCESS,
  WS_GET_ROOM_FAILED,
  WS_GET_ROOM_SUCCESS
} from "../../../static/misc/wsevents";

function createSocketIoMiddleware(
  socket: SocketIOClient.Socket,
  prefix: string
): Middleware {
  // patch socket and add wildcard listener
  function onevent(packet: any) {
    const { emit } = socket.io;
    const args = packet.data || [];
    if (packet.id !== null) args.push(this.ack(packet.id));
    emit.call(this, "*", packet);
    return emit.apply(this, args);
  }

  // emit an event as a promise
  function emitPromise(channel: string, data: any) {
    if (!_.isEmpty(data)) {
      return new Promise((resolve, reject) => {
        socket.emit(
          channel,
          data,
          timeoutCallback(DURATION_TIMEOUT_SOCKET, (timeout: any, res: any) => {
            // console.log(
            //   "Emit : ",
            //   channel,
            //   "with datas : ",
            //   data,
            //   "\nGet resp : ",
            //   res
            // );
            const error = (res && (res.error || res.fail)) || timeout;
            if (
              _.isNil(error) &&
              _.isNil(timeout) &&
              res !== undefined &&
              !res.includes("NOK")
            )
              resolve(res);
            else reject(res);
          })
        );
      });
    }
    return new Promise((resolve, reject) => {
      socket.emit(
        channel,
        timeoutCallback(DURATION_TIMEOUT_SOCKET, (timeout: any, res: any) => {
          // console.log("Emit event : ", channel, "\nGet respond : ", res);
          const error = (res && (res.error || res.fail)) || timeout;
          if (
            _.isNil(error) &&
            _.isNil(timeout) &&
            res !== undefined &&
            !res.includes("NOK")
          )
            resolve(res);
          else reject(res);
        })
      );
    });
  }

  // eslint-disable-next-line no-param-reassign
  if ((socket as any).onevent !== onevent) (socket as any).onevent = onevent;
  return (store: any) => {
    // register wildcard listener to prefix events and dispatch to store
    socket.on("*", (e: any) => {
      // console.log(
      //   "Listen event : ",
      //   e.data[0].toUpperCase(),
      //   "  with payload ",
      //   e.data[1]
      // );
      store.dispatch({
        type: e.data[0].toUpperCase(),
        payload: e.data[1]
      });
    });
    WS_EVENTS_LISTEN_ARRAY.forEach((event) => {
      socket.on(event, (param: any, callback: any) => {
        // console.log(
        //   `Callback responding to ${event} with`,
        //   `${event}_OK PARAM : ${JSON.stringify(param)}`
        // );
        if (
          event === WS_GET_ROOM_SUCCESS ||
          event === WS_GET_ROOM_AGAIN_SUCCESS ||
          event === WS_GET_ROOM_FAILED ||
          event === WS_GET_ROOM_AGAIN_SUCCESS
        ) {
          callback(`${event}_RECEIVED`);
        } else callback(`${event}_OK`, param);
      });
    });

    return (next: any): any => (action: any) => {
      // if it's not a socket event, ignore
      if (!action.type || !action.type.startsWith(prefix)) return next(action);
      // if the prefix matches, emit the event
      // we're using a promise so that the promise middleware catchs it and appends the statuses
      const channel = action.type.slice(prefix.length);
      return next({
        type: channel,
        meta: action.payload,
        payload: emitPromise(channel, action.payload || {})
      });
    };
  };
}

export default createSocketIoMiddleware;
