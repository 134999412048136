import {
  ErrorState,
  ErrorActionTypes,
  RESET_ERROR,
  GETERROR,
  LOCAL_API_ERROR
} from "../types/error";
import i18n from "../../../services/i18n";
import { GET_USER_FAIL, SET_ESTABLISHMENT_FAIL } from "../types/user";

const initialState: ErrorState = {
  errorMessage: null,
  status: 0,
  error: false,
  forceLogout: false
};

export default function reducer(
  state = initialState,
  action: ErrorActionTypes
): ErrorState {
  switch (action.type) {
    case GET_USER_FAIL:
      return {
        ...state,
        error: true,
        forceLogout:
          action.error &&
          action.error.response &&
          action.error.response.data &&
          action.error.response.data.detail &&
          action.error.response.data.detail.includes("not authorized"),
        status:
          action.error && action.error.response && action.error.response.status,
        errorMessage:
          action.error &&
          action.error.response &&
          action.error.response.data &&
          action.error.response.data.detail
            ? action.error.response.data.detail
            : undefined
      };

    case SET_ESTABLISHMENT_FAIL:
      return {
        ...state,
        error: true,
        errorMessage: i18n.t("errors.setEstablishement")
      };

    case RESET_ERROR:
      return {
        ...initialState
      };

    case GETERROR:
      return {
        ...state,
        error: true,
        forceLogout: action.payload.forceLogout,
        errorMessage: action.payload.errorMessage
      };

    case LOCAL_API_ERROR: {
      return {
        ...state
      };
    }

    default:
      return state;
  }
}
