import { ipcRenderer } from "electron";
import {
  UserState,
  UserAction,
  NEWLANG,
  GET_USER_SUCCESS,
  SET_ESTABLISHMENT_SUCCESS,
  PUT_USER_TRAINING,
  PUT_CGU_ACCEPTANCE,
  SET_APP_DATA
} from "../types/user";
import i18n from "../../../services/i18n";
import {
  CLEAR_CREDENTIALS,
  EMPTY_STRING
} from "../../../../static/misc/constants";
import { CONNECTUSER } from "../../authentification/types/auth";

const initialState: UserState = {
  locale: i18n.locale,
  id: EMPTY_STRING,
  firstName: EMPTY_STRING,
  lastName: EMPTY_STRING,
  username: EMPTY_STRING,
  hasTakenTraining: false,
  takenTrainingDate: "",
  email: EMPTY_STRING,
  roles: [],
  avatar: null,
  hasExtraTime: undefined,
  studentNumber: EMPTY_STRING,
  blocked: undefined,
  schoolClasses: [],
  establishments: [],
  currentEstablishment: undefined,
  createdAt: EMPTY_STRING,
  updatedAt: EMPTY_STRING,
  cguAcceptance: false,
  appDataPath: null,
  logsPath: null,
  appVersion: "",
  osPlatform: "",
  osRelease: "",
  equipmentId: ""
};

export default function reducer(
  state = initialState,
  action: UserAction
): UserState {
  switch (action.type) {
    case PUT_USER_TRAINING:
      return {
        ...state,
        hasTakenTraining: true
      };
    case PUT_CGU_ACCEPTANCE:
      return {
        ...state,
        cguAcceptance: true
      };
    case CONNECTUSER:
      return {
        ...state,
        locale: action.payload.locale ? action.payload.locale : state.locale
      };
    case NEWLANG:
      return {
        ...state,
        locale: action.payload
      };

    case GET_USER_SUCCESS: {
      let equipmentId = "";
      if (
        action.payload.data.equipments &&
        action.payload.data.equipments.length > 0
      )
        equipmentId = action.payload.data.equipments[
          action.payload.data.equipments.length - 1
        ].replace("/equipments/", "");
      ipcRenderer.send("AUTO_UPDATE_ME", {
        shouldActivateUpdate: action.payload.data.activateAutoUpdates
      });

      return {
        ...state,
        ...action.payload.data,
        hasTakenTraining:
          !action.payload.data.hasTakenTraining && state.hasTakenTraining
            ? true
            : action.payload.data.hasTakenTraining,
        cguAcceptance:
          !action.payload.data.cguAcceptance && state.cguAcceptance
            ? true
            : action.payload.data.cguAcceptance,
        equipmentId
      };
    }

    case CLEAR_CREDENTIALS:
      return {
        ...initialState,
        locale: state.locale
      };

    case SET_ESTABLISHMENT_SUCCESS:
      return {
        ...state,
        currentEstablishment: action.payload.data?.currentEstablishment
      };

    case SET_APP_DATA: {
      return {
        ...state,
        appDataPath: action.payload.appDataPath,
        logsPath: action.payload.logsPath,
        appVersion: action.payload.appVersion,
        osPlatform: action.payload.osPlatform,
        osRelease: action.payload.osRelease
      };
    }

    default:
      return state;
  }
}
