import React from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { COLOR_GREEN_VALID } from "../../../static/misc/colors";

interface ColorDotProps {
  dotStyle?: StyleProp<ViewStyle>;
}

class ColorDot extends React.PureComponent<ColorDotProps> {
  render(): JSX.Element {
    const { dotStyle } = this.props;
    return <View style={[styles.defaultDot, dotStyle]} />;
  }
}

const styles = StyleSheet.create({
  defaultDot: {
    backgroundColor: COLOR_GREEN_VALID,
    height: 10,
    width: 10,
    borderRadius: 5
  }
});

export default ColorDot;
