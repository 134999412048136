import React from "react";
import {
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  Text,
  TextStyle,
  ViewStyle
} from "react-native";
import { COLOR_RED, COLOR_WHITE } from "../../../static/misc/colors";
import {
  FONTSIZE_13,
  FONT_GILROY_MEDIUM
} from "../../../static/misc/constants";
import i18n from "../../services/i18n";

export interface HeaderItemProps {
  containerStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  label: string;
  itemSelected?: boolean;
  onPress: () => void;
}

class HeaderItem extends React.PureComponent<HeaderItemProps> {
  static defaultProps = {
    itemSelected: false
  };

  render(): JSX.Element {
    const {
      containerStyle,
      textStyle,
      label,
      onPress,
      itemSelected
    } = this.props;
    return (
      <TouchableOpacity style={containerStyle} onPress={onPress}>
        <Text
          style={[
            itemSelected ? styles.selectedStyle : styles.defaultText,
            textStyle
          ]}
        >
          {i18n.t(label)}
        </Text>
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  defaultText: {
    color: COLOR_WHITE,
    fontFamily: FONT_GILROY_MEDIUM,
    fontSize: FONTSIZE_13,
    textAlignVertical: "center",
    paddingVertical: 7,
    paddingHorizontal: 30,
    textAlign: "center"
  },
  selectedStyle: {
    color: COLOR_WHITE,
    fontFamily: FONT_GILROY_MEDIUM,
    fontSize: FONTSIZE_13,
    textAlignVertical: "center",
    textAlign: "center",
    borderRadius: 15,
    paddingVertical: 7,
    paddingHorizontal: 30,
    backgroundColor: COLOR_RED
  }
});

export default HeaderItem;
