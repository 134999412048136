import React from "react";
import { StyleSheet, View } from "react-native";
import momentTZ from "moment-timezone";
import { EstablishmentsType } from "../../modules/main/types/user";
import i18n from "../../services/i18n";
import BoldTitle from "../atoms/BoldTitle";
import ProfileField from "../molecules/ProfileField";
import SelectorField from "../molecules/SelectorField";
import { FONTSIZE_36 } from "../../../static/misc/constants";
import { WHITE_BACKGROUND } from "../../../static/misc/colors";

export interface ProfileProps {
  givenName: string;
  familyName: string;
  email: string;
  currentEstablishment?: EstablishmentsType;
  establishments: EstablishmentsType[];
  changeEstablishmentChoice: (establishmentId: string) => void;
  changeTimezoneChoice: (timezone: string) => void;
  postingEstablishment: boolean;
  defaultTimeZone: string | null;
}

class Profile extends React.PureComponent<ProfileProps> {
  render(): JSX.Element {
    const {
      givenName,
      familyName,
      email,
      currentEstablishment,
      establishments,
      changeEstablishmentChoice,
      changeTimezoneChoice,
      postingEstablishment,
      defaultTimeZone
    } = this.props;

    const establishmentsNames = establishments.map(
      (establishment: EstablishmentsType) => {
        return establishment.name;
      }
    );
    const timeZonesList = momentTZ.tz.names();

    return (
      <View style={styles.contentContainer}>
        <View style={styles.personalInfoSection}>
          <BoldTitle
            text={i18n.t("profile.infoTitle")}
            textStyle={styles.titleText}
          />
          <View style={{ marginTop: 20 }}>
            <ProfileField
              title={i18n.t("profile.lastName")}
              content={familyName}
            />
            <ProfileField
              title={i18n.t("profile.firstName")}
              content={givenName}
            />
            <ProfileField title={i18n.t("profile.email")} content={email} />
            {(establishments && establishments.length > 1 && (
              <View style={styles.styledView}>
                <SelectorField
                  choiceList={establishmentsNames}
                  defaultIndex={
                    currentEstablishment?.name
                      ? establishmentsNames.indexOf(currentEstablishment?.name)
                      : undefined
                  }
                  label={i18n.t("profile.establishment")}
                  changeChoice={(index: number) =>
                    changeEstablishmentChoice(establishmentsNames[index])
                  }
                  loading={postingEstablishment}
                  placeHolder={i18n.t("profile.establishmentPlaceholder")}
                  selectorContainerStyle={styles.selectorContainer}
                  textStyle={styles.selectorText}
                  choiceListStyle={styles.bgListStyle}
                />
              </View>
            )) ||
              (establishments && (
                <ProfileField
                  title={i18n.t("profile.establishment")}
                  content={establishments[0].name}
                />
              ))}
            <View>
              <SelectorField
                choiceList={timeZonesList.map((item: string) => {
                  return `${item} (UTC${momentTZ.tz(item).format("Z")})`;
                })}
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                defaultIndex={timeZonesList.indexOf(defaultTimeZone!)}
                label={i18n.t("profile.timezone")}
                changeChoice={(index: number) =>
                  changeTimezoneChoice(timeZonesList[index])
                }
                placeHolder={i18n.t("profile.timezonePlaceholder")}
                selectorContainerStyle={styles.selectorContainer}
                textStyle={styles.selectorText}
              />
            </View>
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  contentContainer: {
    width: "100%",
    height: "100%",
    flexDirection: "row",
    padding: 50
  },
  titleText: {
    fontSize: FONTSIZE_36,
    marginBottom: 30
  },
  personalInfoSection: {
    width: "50%",
    height: "100%"
  },
  selectorContainer: {
    width: "75%"
  },
  selectorText: {
    fontWeight: "bold"
  },
  styledView: {
    zIndex: 100,
    elevation: 100
  },
  bgListStyle: {
    backgroundColor: WHITE_BACKGROUND
  }
});

export default Profile;
