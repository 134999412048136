import { APP_VERSION } from "../../../../static/misc/constants";
import { GET_USER_ME, USER, USERS_ID } from "../../../../static/misc/network";
import {
  SetLocale,
  NEWLANG,
  GetUserAction,
  GET_USER,
  SetEstablishmentAction,
  SET_ESTABLISHMENT,
  PUT_USER_TRAINING,
  PutUserTrainingAction,
  PUT_CGU_ACCEPTANCE,
  PutUserCguAcceptanceAction,
  SetAppDataAction,
  SET_APP_DATA
} from "../types/user";

export function setLocale(locale: string): SetLocale {
  return {
    type: NEWLANG,
    payload: locale
  };
}

export function setAppData(
  appDataPath: string,
  logsPath: string,
  appVersion: string,
  osPlatform: string,
  osRelease: string
): SetAppDataAction {
  return {
    type: SET_APP_DATA,
    payload: {
      appDataPath,
      logsPath,
      appVersion,
      osPlatform,
      osRelease
    }
  };
}

export function getMyUser(userToken: string): GetUserAction {
  return {
    type: GET_USER,
    payload: {
      client: "default",
      request: {
        headers: {
          accept: "application/json",
          authorization: `Bearer ${userToken}`
        },
        method: "get",
        url: `${GET_USER_ME}?desktopVersion=${APP_VERSION}`
      }
    }
  };
}

export function setEstablishment(
  userToken: string,
  establishmentId: string,
  userId: string
): SetEstablishmentAction {
  return {
    type: SET_ESTABLISHMENT,
    payload: {
      client: "default",
      request: {
        headers: {
          accept: "application/json",
          authorization: `Bearer ${userToken}`
        },
        method: "put",
        url: USER(userId),
        data: {
          currentEstablishment: `/establishments/${establishmentId}`
        }
      }
    }
  };
}

export function putHasTakingTraining(
  userToken: string,
  userId: string,
  hasTakenTraining: boolean,
  takenTrainingDate: string
): PutUserTrainingAction {
  return {
    type: PUT_USER_TRAINING,
    payload: {
      client: "default",
      request: {
        headers: {
          accept: "application/json",
          authorization: `Bearer ${userToken}`
        },
        method: "put",
        url: USERS_ID(userId),
        data: {
          hasTakenTraining,
          takenTrainingDate
        }
      }
    }
  };
}

export function putUserCguAcceptance(
  userToken: string,
  userId: string,
  cguAcceptance: boolean
): PutUserCguAcceptanceAction {
  return {
    type: PUT_CGU_ACCEPTANCE,
    payload: {
      client: "default",
      request: {
        headers: {
          accept: "application/json",
          authorization: `Bearer ${userToken}`
        },
        method: "put",
        url: USERS_ID(userId),
        data: {
          cguAcceptance
        }
      }
    }
  };
}
