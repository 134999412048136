import { ipcRenderer } from "electron";
import React from "react";
import { View, StyleSheet, Text } from "react-native";
import { COLOR_BLUE_TESTWE } from "../../../static/misc/colors";
import { PADDING_SIDES } from "../../../static/misc/constants";
import i18n from "../../services/i18n";
import CustomModal from "../atoms/CustomModal";
import ExtraBoldTitle from "../atoms/ExtraBoldTitle";
import Loader from "../atoms/Loader";
import Button from "../molecules/Button";

export interface OptionsModalProps {
  isVisible: boolean;
  onShowOptionsModal: (bool: boolean) => void;
}
export interface OptionsModalState {
  isArchiveLoading: boolean;
  // isDeleteLoading: boolean;
  archiveMessage: string;
  // deleteMessage: string;
}
class OptionsModal extends React.PureComponent<
  OptionsModalProps,
  OptionsModalState
> {
  constructor(props: OptionsModalProps) {
    super(props);
    this.state = {
      archiveMessage: "",
      // deleteMessage: "",
      isArchiveLoading: false
      // isDeleteLoading: false
    };
  }

  getLocalArchive = (): void => {
    this.setState({ isArchiveLoading: true, archiveMessage: "" });
    ipcRenderer
      .invoke("GET_ARCHIVE", null)
      .then((res) => {
        if (res) {
          this.setState({
            archiveMessage: i18n.t("optionsModal.archives.success")
          });
        } else {
          this.setState({
            archiveMessage: i18n.t("optionsModal.archives.failed")
          });
        }
      })
      .finally(() => {
        this.setState({ isArchiveLoading: false });
      });
  };

  deleteLocalArchive = (): void => {
    // this.setState({ isLoading: isLoading + 2 });
    // TODO NG-589
    // this.setState({ isLoading: isLoading - 2 });
  };

  render(): JSX.Element {
    const { isVisible, onShowOptionsModal } = this.props;
    const {
      isArchiveLoading,
      // isDeleteLoading,
      archiveMessage
      // deleteMessage
    } = this.state;

    return (
      <CustomModal
        heightModal="25vh"
        widthModal="40vw"
        borderRadiusModal={5}
        visible={isVisible}
        showCloseButton
        onCloseModal={() => onShowOptionsModal(false)}
      >
        <View style={styles.viewContainer}>
          <ExtraBoldTitle
            text={i18n.t("optionsModal.header")}
            textStyle={styles.textStyle}
          />
          <View style={styles.contentContainer}>
            <View style={styles.rowContainer}>
              <View style={styles.textContainer}>
                <Text style={[styles.textStyle]}>
                  {i18n.t("optionsModal.archives.title")}
                </Text>
              </View>
              <View style={styles.buttonContainer}>
                {!isArchiveLoading ? (
                  <Button
                    onPress={() => this.getLocalArchive()}
                    label={i18n.t("optionsModal.archives.label")}
                    containerStyle={[styles.buttonStyle]}
                  />
                ) : (
                  <Loader iconStyle={styles.loaderIcon} />
                )}
                {(archiveMessage && (
                  <View style={styles.message}>
                    <Text style={styles.text}>{archiveMessage}</Text>
                  </View>
                )) || <View />}
              </View>
            </View>
            {/* <View style={styles.rowContainer}>
              <View style={styles.textContainer}>
                <Text style={[styles.textStyle]}>
                  {i18n.t("optionsModal.effacer.title")}
                </Text>
              </View>
              <View style={styles.buttonContainer}>
                {!isDeleteLoading ? (
                  <Button
                    onPress={() => this.deleteLocalArchive()}
                    label={i18n.t("optionsModal.effacer.label")}
                    containerStyle={[styles.buttonStyle]}
                    disabled
                  />
                ) : (
                  <Loader iconStyle={styles.loaderIcon} />
                )}
                {(deleteMessage && (
                  <View style={styles.buttonContainer}>
                    <Text style={styles.text}>{deleteMessage}</Text>
                  </View>
                )) || <View />}
              </View>
            </View> */}
          </View>
        </View>
      </CustomModal>
    );
  }
}

const styles = StyleSheet.create({
  viewContainer: {
    paddingHorizontal: PADDING_SIDES * 0.5
  },
  message: {
    paddingTop: PADDING_SIDES * 0.2,
    width: "fit-content",
    marginLeft: "auto",
    marginRight: "auto"
  },
  textContainer: {
    width: "50%"
  },
  buttonContainer: {
    flex: 2,
    width: "50%",
    flexDirection: "column"
  },
  text: {
    color: COLOR_BLUE_TESTWE
  },
  textStyle: {
    color: COLOR_BLUE_TESTWE,
    paddingBottom: PADDING_SIDES * 0.5
  },
  contentContainer: {
    height: "100%",
    width: "100%",
    paddingTop: PADDING_SIDES * 0.75
  },
  rowContainer: {
    flex: 1,
    width: "100%",
    padding: PADDING_SIDES * 0.1,
    flexDirection: "row",
    alignContent: "space-around",
    justifyContent: "space-around"
  },
  buttonStyle: {
    width: "30%",
    marginLeft: "auto",
    marginRight: "auto",
    padding: PADDING_SIDES * 0.2,
    height: "3vh"
  },
  loaderIcon: {
    height: 30,
    width: 30
  }
});

export default OptionsModal;
