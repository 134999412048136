import { ipcRenderer } from "electron";
import React from "react";
import screenfull from "screenfull";

export interface FullScreenProps {
  isEnter: boolean;
  onChange: (b: boolean) => void;
  children: any;
}

export default class FullScreen extends React.Component<FullScreenProps> {
  shouldComponentUpdate(nextProps: FullScreenProps): boolean {
    const { isEnter, onChange, children } = this.props;
    if (nextProps.isEnter !== isEnter) {
      if (nextProps.isEnter) {
        ipcRenderer.send("LOG_INFO", "FULLSCREEN -- setting fullscreen");
        if (!screenfull.isFullscreen)
          screenfull
            .request()
            .then(() => this.lock())
            .catch((err) => {
              ipcRenderer.send(
                "LOG_INFO",
                `FULLSCREEN -- an error occurred ${err}`
              );
            });
      } else {
        ipcRenderer.send("LOG_INFO", "FULLSCREEN - exiting fullscreen");
        this.exitFullscreen();
      }
    }

    if (nextProps.onChange !== onChange) {
      const cb = () => {
        nextProps.onChange(screenfull.isFullscreen);
      };
      if (screenfull.isEnabled) {
        screenfull.on("change", cb);
      }
    }

    return (
      nextProps.isEnter !== isEnter ||
      nextProps.onChange !== onChange ||
      nextProps.children !== children
    );
  }

  componentWillUnmount(): void {
    this.exitFullscreen();
  }

  private async exitFullscreen(): Promise<void> {
    await screenfull.exit();
    this.unlock();
  }

  private lock(): void {
    // we try and lock some key combinations (ALT+TAB, WIN+TAB, ESCAPE)
    if ("keyboard" in navigator && "lock" in (navigator as any).keyboard) {
      (navigator as any).keyboard.lock(["Escape", "MetaLeft", "MetaRight"]);
    }
  }

  private unlock(): void {
    if ("keyboard" in navigator && "unlock" in (navigator as any).keyboard) {
      (navigator as any).keyboard.unlock();
    }
  }

  render(): JSX.Element {
    const { children } = this.props;
    return React.cloneElement(children, {
      ref: (dom: any) => {
        if (children) {
          if (children.ref instanceof Function) {
            children.ref(dom);
          } else if (children.ref) {
            children.ref.current = dom;
          }
        }
      }
    });
  }
}
