import React from "react";
import {
  StyleSheet,
  View,
  FlatList,
  ImageStyle,
  StyleProp
} from "react-native";
import MenuElement, { MenuElementProps } from "../atoms/MenuElement";
import { guidGenerator } from "../../../static/misc/utils";
import UserAvatar from "../atoms/UserAvatar";
import { COLOR_SHADOW } from "../../../static/misc/colors";
import { PADDING_SIDES } from "../../../static/misc/constants";
import { placeholderProfil } from "../../../static/misc/images";

interface MenuProps {
  isExamSync: boolean;
  items: MenuElementProps[];
  showMenu: boolean;
  avatarStyle?: StyleProp<ImageStyle>;
  toggleMenu: () => void;
}

class Menu extends React.PureComponent<MenuProps> {
  render(): JSX.Element {
    const { items, showMenu, isExamSync, avatarStyle, toggleMenu } = this.props;

    return (
      <View>
        <UserAvatar
          avatar={placeholderProfil}
          onPress={() => toggleMenu()}
          avatarStyle={avatarStyle}
        />
        {showMenu && !isExamSync && (
          <FlatList
            data={items}
            style={styles.menuContainer}
            keyExtractor={() => guidGenerator()}
            renderItem={({ item }) =>
              item && (
                <MenuElement
                  label={item.label}
                  icon={item.icon}
                  onPress={() => (item.onPress ? item.onPress() : toggleMenu())}
                />
              )
            }
          />
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  menuContainer: {
    shadowColor: COLOR_SHADOW,
    shadowOffset: {
      width: 0,
      height: 0
    },
    shadowRadius: 5,
    shadowOpacity: 1,
    position: "absolute",
    borderRadius: 10,
    top: PADDING_SIDES * 1,
    right: PADDING_SIDES * 0.1
  }
});

export default Menu;
