import { ipcRenderer } from "electron";
import _ from "lodash";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import {
  COLOR_BLUE,
  COLOR_BLUE_TESTWE,
  COLOR_WHITE
} from "../../../static/misc/colors";
import {
  FONTSIZE_14,
  FONTSIZE_18,
  FONT_GILROY_BOLD,
  FONT_GILROY_REGULAR,
  PADDING_SIDES
} from "../../../static/misc/constants";
import { cancelExitingApplication } from "../../modules/main/actions/status";
import {
  ExitApplicationAction,
  httpStatus,
  SubmittingStudentPaperStatus
} from "../../modules/main/types/status";
import i18n from "../../services/i18n";
import { RootState } from "../../store/rootreducer";
import CustomModal from "../atoms/CustomModal";
import Loader from "../atoms/Loader";
import Button from "./Button";

interface ExitAppModalProps {
  isVisible: boolean;
  action?: string;
  isOnline: boolean;
  submittingStudentPaperStatus: SubmittingStudentPaperStatus[];
  onClose: () => void;
  cancelExiting: () => ExitApplicationAction;
}

interface ExitAppModalState {
  isDownloading: boolean;
}

const validStatuses = [
  httpStatus.SUCCESS,
  httpStatus.FAIL,
  httpStatus.NONE,
  httpStatus.ABORTED
];

class ExitAppModal extends React.PureComponent<
  ExitAppModalProps,
  ExitAppModalState
> {
  constructor(props: ExitAppModalProps) {
    super(props);

    this.state = {
      isDownloading: false
    };
  }

  componentDidUpdate(
    prevProps: ExitAppModalProps,
    prevState: ExitAppModalState
  ): void {
    const {
      isVisible,
      action,
      submittingStudentPaperStatus,
      isOnline
    } = this.props;
    const { isDownloading } = this.state;

    const newDownloadingStatus = this.checkIsDownloading(
      submittingStudentPaperStatus
    );

    if (newDownloadingStatus !== isDownloading) {
      this.setState({
        isDownloading: newDownloadingStatus
      });
    }

    // Executing close command if modal just opened and wre arre offline or if modal just opened and we didn't have anything to send
    if (
      prevProps.isVisible !== isVisible &&
      isVisible &&
      (!isOnline ||
        (isDownloading === prevState.isDownloading && !isDownloading))
    ) {
      ipcRenderer.send(action ?? "EXIT_APP");
    }
  }

  checkIsDownloading(
    submittingStudentPaperStatus: SubmittingStudentPaperStatus[]
  ): boolean {
    let isDownloading = false;

    submittingStudentPaperStatus.forEach(
      (value: SubmittingStudentPaperStatus) => {
        isDownloading =
          isDownloading ||
          !_.includes(validStatuses, value.submittingMediaObjectsStatus);
        isDownloading =
          isDownloading ||
          !_.includes(validStatuses, value.submittingStudentPaperStatus);
      }
    );

    return isDownloading;
  }

  render(): JSX.Element {
    const { isVisible, action, onClose, cancelExiting } = this.props;
    const { isDownloading } = this.state;

    return (
      <CustomModal
        heightModal="30%"
        widthModal="40%"
        borderRadiusModal={5}
        showCloseButton={false}
        visible={isVisible}
        onCloseModal={() => onClose()}
      >
        {isDownloading ? (
          <View style={styles.container}>
            <Text style={styles.title}>
              {i18n.t("exitAppModal.modalTitle")}
            </Text>
            <Text style={styles.description}>
              {i18n.t("exitAppModal.modalDescription")}&nbsp;&nbsp;&nbsp;
              <Loader />
            </Text>
            <View style={styles.actionButtons}>
              <Button
                label={i18n.t("exitAppModal.modalCancel")}
                onPress={() => cancelExiting()}
                containerStyle={[styles.inverseButtonStyle]}
                textStyle={[styles.inverseButtonTextStyle]}
              />
              <Button
                label={i18n.t("exitAppModal.modalValidate")}
                onPress={() => ipcRenderer.send(action ?? "EXIT_APP")}
                containerStyle={[styles.buttonStyle]}
                textStyle={[styles.buttonTextStyle]}
              />
            </View>
          </View>
        ) : (
          <View style={styles.container}>
            <Text style={styles.title}>
              {i18n.t("exitAppModal.modalTitleEnd")}
            </Text>
            <Text style={styles.description}>
              {i18n.t("exitAppModal.modalDescriptionEnd")}
            </Text>
            <View style={styles.actionButtons}>
              <Button
                label={i18n.t("exitAppModal.modalValidate2")}
                onPress={() => ipcRenderer.send(action ?? "EXIT_APP")}
                containerStyle={[styles.buttonStyle]}
                textStyle={[styles.buttonTextStyle]}
              />
            </View>
          </View>
        )}
      </CustomModal>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%"
  },
  title: {
    fontFamily: FONT_GILROY_BOLD,
    fontSize: FONTSIZE_18,
    color: COLOR_BLUE_TESTWE,
    paddingVertical: PADDING_SIDES * 0.4,
    paddingHorizontal: PADDING_SIDES * 0.2
  },
  description: {
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_14,
    color: COLOR_BLUE_TESTWE,
    padding: PADDING_SIDES * 0.2
  },
  actionButtons: {
    flexDirection: "row",
    alignItems: "flex-end"
  },
  buttonStyle: {
    backgroundColor: COLOR_BLUE,
    borderColor: COLOR_BLUE,
    padding: PADDING_SIDES * 0.15,
    margin: PADDING_SIDES * 0.2
  },
  inverseButtonStyle: {
    backgroundColor: COLOR_WHITE,
    borderColor: COLOR_BLUE,
    padding: PADDING_SIDES * 0.15,
    margin: PADDING_SIDES * 0.2
  },
  buttonTextStyle: {
    fontSize: FONTSIZE_14
  },
  inverseButtonTextStyle: {
    fontSize: FONTSIZE_14,
    color: COLOR_BLUE
  }
});

const mapStateToProps = (state: RootState) => {
  return {
    submittingStudentPaperStatus: state.status.submittingStudentPaperStatus,
    action: state.status.exitApplicationAction,
    isOnline: state.status.isOnline
  };
};

const mapdispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators(
      {
        cancelExiting: cancelExitingApplication
      },
      dispatch
    )
  };
};

export default connect(mapStateToProps, mapdispatchToProps)(ExitAppModal);
