import _ from "lodash";
import {
  CLEAR_CREDENTIALS,
  IS_PREVIEW,
  IS_WEB_ENABLE,
  MIME_SPREADSHEET
} from "../../../../static/misc/constants";
import { b64toblob } from "../../../../static/misc/utils";
import {
  AttachedFilesActionsType,
  AttachedFilesState,
  UPLOAD_MEDIA_OBJECT,
  UPLOAD_MEDIA_OBJECT_FAIL,
  UPLOAD_MEDIA_OBJECT_SUCCESS,
  REMOVE_MEDIA_OBJECT,
  SET_MEDIA_OBJECT,
  UPDATE_MEDIA_OBJECT,
  DOWNLOAD_MEDIA_OBJECT_SUCCESS,
  DOWNLOAD_MEDIA_OBJECT_FAIL,
  DOWNLOAD_MEDIA_OBJECT
} from "../types/attachedfiles";
import { GET_EXAMS_HASH } from "../types/exams";

const initialState: AttachedFilesState = {
  mediaObjects: []
};

export default function reducer(
  state = initialState,
  action: AttachedFilesActionsType
): AttachedFilesState {
  switch (action.type) {
    case SET_MEDIA_OBJECT: {
      const examId = action.payload.examId as string;
      const attachedFileId = action.payload.mediaObjectId as string;

      const mediaObjectsTmp = [...state.mediaObjects];
      const mediaObjectsExamIndex = mediaObjectsTmp.findIndex(
        (mediaObject) => mediaObject.examId === examId
      );

      if (mediaObjectsExamIndex > -1) {
        const mediaObjectExamTmp = {
          ...mediaObjectsTmp[mediaObjectsExamIndex]
        };
        // If examId has already some medias
        const mediasIndex = mediaObjectExamTmp.medias.findIndex(
          (media) => media.mediaId === attachedFileId
        );
        // if media status not existing, adding it
        if (mediasIndex === -1) {
          mediaObjectExamTmp.medias.push({
            isUnciphered: false,
            mediaId: attachedFileId,
            mimeType: "",
            isErrored: false,
            retryCount: 0,
            isDownloaded: false,
            isDownloading: false
          });
        } else {
          const mediaTmp = [...mediaObjectsTmp[mediaObjectsExamIndex].medias];

          // TODO (maybe) add condition if hash changed then redownload else do nothing
          // otherwise, setting downloaded and downloading to default because we want to re-download it
          const mediaFileTmp = {
            isUnciphered: mediaTmp[mediasIndex].isUnciphered,
            mediaId: attachedFileId,
            mimeType: mediaTmp[mediasIndex].mimeType,
            isErrored: mediaTmp[mediasIndex].isErrored,
            retryCount: mediaTmp[mediasIndex].retryCount,
            isDownloaded: mediaTmp[mediasIndex].isDownloaded,
            isDownloading: mediaTmp[mediasIndex].isDownloading
          };

          mediaTmp[mediasIndex] = mediaFileTmp;
          mediaObjectExamTmp.medias = mediaTmp;
          mediaObjectsTmp[mediaObjectsExamIndex] = mediaObjectExamTmp;
        }
      } else {
        // else create the media object with examId
        const mediasArray = [];
        mediasArray.push({
          mediaId: attachedFileId,
          isUnciphered: false,
          mimeType: "",
          isErrored: false,
          retryCount: 0,
          isDownloaded: false,
          isDownloading: false
        });
        mediaObjectsTmp.push({
          examId,
          medias: mediasArray
        });
      }
      return {
        ...state,
        mediaObjects: [...mediaObjectsTmp]
      };
    }

    case GET_EXAMS_HASH: {
      const mediaObjectsTmp = [...state.mediaObjects];

      const moTmp = mediaObjectsTmp.map((exam) => {
        const examTmp = { ...exam };

        examTmp.medias = examTmp.medias.map((media) => {
          const mediaTmp = { ...media };

          // we want to reset the download status when getting new hash
          if (!mediaTmp.isDownloaded) {
            mediaTmp.isDownloading = false;
            mediaTmp.isErrored = false;
            mediaTmp.retryCount = 0;
          }

          return { ...mediaTmp };
        });
        return { ...examTmp };
      });

      return {
        ...state,
        mediaObjects: [...moTmp]
      };
    }

    case DOWNLOAD_MEDIA_OBJECT: {
      const mediaObjectsTmp = [...state.mediaObjects];
      const mediaObjectExamIndex = mediaObjectsTmp.findIndex(
        (mediaObject) => mediaObject.examId === action.payload.examId
      );

      if (mediaObjectExamIndex > -1) {
        const mediaObjectExamTmp = { ...mediaObjectsTmp[mediaObjectExamIndex] };
        const mediaIndex = mediaObjectExamTmp.medias.findIndex(
          (media) => media.mediaId === action.payload.mediaId
        );

        if (mediaIndex > -1) {
          const mediaTmp = [...mediaObjectsTmp[mediaObjectExamIndex].medias];
          const mediaFileTmp = { ...mediaTmp[mediaIndex] };

          mediaFileTmp.isDownloading = true;
          mediaFileTmp.isDownloaded = false;

          mediaTmp[mediaIndex] = mediaFileTmp;
          mediaObjectExamTmp.medias = mediaTmp;
          mediaObjectsTmp[mediaObjectExamIndex] = mediaObjectExamTmp;
        }
      }
      return {
        ...state,
        mediaObjects: [...mediaObjectsTmp]
      };
    }

    case DOWNLOAD_MEDIA_OBJECT_SUCCESS: {
      const mediaObjectsTmp = [...state.mediaObjects];
      const mediaObjectExamIndex = mediaObjectsTmp.findIndex(
        (mediaObject) =>
          mediaObject.examId === action.meta.previousAction.payload.examId
      );

      if (mediaObjectExamIndex > -1) {
        const mediaObjectExamTmp = { ...mediaObjectsTmp[mediaObjectExamIndex] };
        const mediaIndex = mediaObjectExamTmp.medias.findIndex(
          (media) =>
            media.mediaId === action.meta.previousAction.payload.mediaId
        );

        if (mediaIndex > -1) {
          const mediaTmp = [...mediaObjectsTmp[mediaObjectExamIndex].medias];
          const mediaFileTmp = { ...mediaTmp[mediaIndex] };

          mediaFileTmp.isDownloaded = true;
          mediaFileTmp.isDownloading = false;
          mediaFileTmp.isErrored = false;
          mediaFileTmp.retryCount = 0;
          mediaFileTmp.mimeType = action.payload.data.mimeType;

          if (IS_PREVIEW || IS_WEB_ENABLE) {
            const blob = b64toblob(
              action.payload.data.data,
              action.payload.data.mimeType
            );
            mediaFileTmp.blobUrl = URL.createObjectURL(blob);
            // if spreadsheet OR web, exceptionally we store the data in the store
            if (
              _.includes(MIME_SPREADSHEET, action.payload.data.mimeType) ||
              IS_WEB_ENABLE
            ) {
              mediaFileTmp.data = action.payload.data.data;
            }
          }
          mediaTmp[mediaIndex] = mediaFileTmp;
          mediaObjectExamTmp.medias = mediaTmp;
          mediaObjectsTmp[mediaObjectExamIndex] = mediaObjectExamTmp;
        }
      }
      return {
        ...state,
        mediaObjects: [...mediaObjectsTmp]
      };
    }

    case DOWNLOAD_MEDIA_OBJECT_FAIL: {
      const examId = action.meta.previousAction.payload.examId as string;
      const attachedFileId = action.meta.previousAction.payload
        .mediaId as string;

      const mediaObjectsTmp = [...state.mediaObjects];

      const mediaObjectsExamIndex = mediaObjectsTmp.findIndex(
        (mediaObject) => mediaObject.examId === examId
      );

      if (mediaObjectsExamIndex > -1) {
        const mediaObjectExamTmp = {
          ...mediaObjectsTmp[mediaObjectsExamIndex]
        };
        // If examId has already some medias
        const mediasIndex = mediaObjectExamTmp.medias.findIndex(
          (media) => media.mediaId === attachedFileId
        );

        if (mediasIndex > -1) {
          const mediaTmp = [...mediaObjectsTmp[mediaObjectsExamIndex].medias];
          const mediaFileTmp = { ...mediaTmp[mediasIndex] };

          mediaFileTmp.isUnciphered = false;
          mediaFileTmp.mediaId = attachedFileId;
          mediaFileTmp.mimeType = "";
          mediaFileTmp.isErrored = true;
          mediaFileTmp.isDownloaded = false;
          mediaFileTmp.isDownloading = false;
          mediaFileTmp.retryCount += 1;

          mediaTmp[mediasIndex] = mediaFileTmp;
          mediaObjectExamTmp.medias = mediaTmp;
          mediaObjectsTmp[mediaObjectsExamIndex] = mediaObjectExamTmp;
        }
      }
      return {
        ...state,
        mediaObjects: [...mediaObjectsTmp]
      };
    }

    case UPLOAD_MEDIA_OBJECT: {
      return {
        ...state
      };
    }

    case UPLOAD_MEDIA_OBJECT_SUCCESS: {
      return {
        ...state
      };
    }

    case UPLOAD_MEDIA_OBJECT_FAIL: {
      return {
        ...state
      };
    }

    case REMOVE_MEDIA_OBJECT: {
      let mediaObjectArrayTmp = [...state.mediaObjects];
      mediaObjectArrayTmp = mediaObjectArrayTmp.filter(
        (e) => e.examId !== action.payload
      );
      return {
        ...state,
        mediaObjects: mediaObjectArrayTmp
      };
    }

    case UPDATE_MEDIA_OBJECT: {
      const mediaObjectArrayTmp = [...state.mediaObjects];
      const mediaObjectIndex = mediaObjectArrayTmp.findIndex(
        (e) => e.examId === action.payload.examId
      );
      if (mediaObjectIndex >= 0) {
        const mediaIndex = mediaObjectArrayTmp[
          mediaObjectIndex
        ].medias.findIndex((e) => e.mediaId === action.payload.mediaObjectId);
        if (mediaIndex >= 0 && action.payload.mimeType)
          mediaObjectArrayTmp[mediaObjectIndex].medias[mediaIndex].mimeType =
            action.payload.mimeType;
        if (mediaIndex >= 0 && action.payload.isUnciphered)
          mediaObjectArrayTmp[mediaObjectIndex].medias[
            mediaIndex
          ].isUnciphered = action.payload.isUnciphered;
        if (mediaIndex >= 0 && action.payload.blobUrl)
          mediaObjectArrayTmp[mediaObjectIndex].medias[mediaIndex].blobUrl =
            action.payload.blobUrl;
      }
      return {
        ...state,
        mediaObjects: mediaObjectArrayTmp
      };
    }

    case CLEAR_CREDENTIALS:
      return initialState;

    default:
      return state;
  }
}
