import DetectOS from "detectos.js";
import {
  APP_VERSION,
  AUDIO_QUESTION,
  IS_WEB_ENABLE
} from "../../static/misc/constants";
import { ONBOARDING_MICROPHONE } from "../../static/misc/onboarding/onboardingsteps";
import { ExamType } from "../modules/exams/types/exam";
import { EquipmentCheck } from "../modules/main/types/equipmentCheck";

export const UNKNOWN = "unknown";

export const examNeedsMicrophone = (exam: ExamType): boolean => {
  return (
    exam.questionTypes.find((type) => type === AUDIO_QUESTION) !== undefined ||
    exam.examParams?.proctoringLiveRecord === true ||
    (exam.onboarding
      ? exam.onboarding.steps.findIndex(
          (elm) => elm.type === ONBOARDING_MICROPHONE
        ) > -1
      : false)
  );
};

export const examNeedsCamera = (exam: ExamType): boolean => {
  return (
    exam.examParams?.proctoring || exam.examParams?.proctoringLive || false
  );
};

export const getEquipmentInformation = (
  equipmentCheck: EquipmentCheck
): EquipmentCheck => {
  const newEquipmentCheck = { ...equipmentCheck };

  if (newEquipmentCheck) {
    newEquipmentCheck.desktopVersion = APP_VERSION;

    const detect = new DetectOS();
    newEquipmentCheck.osName = detect.OS || UNKNOWN;

    if (IS_WEB_ENABLE) {
      newEquipmentCheck.webBrowser = detect.browser || UNKNOWN;
      newEquipmentCheck.webVersion = String(detect.version) || UNKNOWN;
    }
  }

  return newEquipmentCheck;
};
