import React from "react";
import {
  Image,
  ImageStyle,
  StyleProp,
  StyleSheet,
  TouchableOpacity
} from "react-native";

interface UserAvatarProps {
  avatarStyle?: StyleProp<ImageStyle>;
  onPress: () => void;
  disabled?: boolean;
  avatar: string;
}

class UserAvatar extends React.PureComponent<UserAvatarProps> {
  static defaultProps = {
    disabled: false
  };

  render(): JSX.Element {
    const { avatarStyle, avatar, onPress, disabled } = this.props;
    return (
      <TouchableOpacity onPress={onPress} disabled={disabled}>
        <Image
          style={[styles.defaultAvatarStyle, avatarStyle]}
          source={{ uri: avatar }}
        />
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  defaultAvatarStyle: {
    height: 50,
    width: 50
  }
});

export default UserAvatar;
