import { Editor, IAllProps } from "@tinymce/tinymce-react";
import React from "react";
import {
  IS_PREVIEW,
  IS_WEB_ENABLE,
  TINY_MCE_PREVIEW
} from "../../../static/misc/constants";
import {
  FMATH_PLUGIN_LOCAL,
  TINY_MCE_LOCAL
} from "../../../static/misc/network";
import { guidGenerator } from "../../../static/misc/utils";

interface TinyEditorProps {
  hasMath?: boolean;
  init: any;
  initialValue?: string;
  value: string;
  handleClick?: (evt: any, editor: any) => void;
  onEditorChange?: IAllProps["onEditorChange"];
}

export default class TinyEditor extends React.PureComponent<TinyEditorProps> {
  render(): JSX.Element {
    const {
      hasMath,
      init,
      initialValue,
      value,
      handleClick,
      onEditorChange
    } = this.props;

    const myInit = hasMath
      ? {
          ...init,
          external_plugins: {
            FMathEditor: IS_WEB_ENABLE
              ? "/FMathEditor/plugin.js"
              : FMATH_PLUGIN_LOCAL
          }
        }
      : init;

    return (
      <Editor
        tinymceScriptSrc={
          (IS_PREVIEW || IS_WEB_ENABLE) &&
          TINY_MCE_PREVIEW &&
          TINY_MCE_PREVIEW !== ""
            ? TINY_MCE_PREVIEW
            : TINY_MCE_LOCAL
        }
        id={guidGenerator()}
        textareaName={guidGenerator()}
        init={myInit}
        initialValue={initialValue}
        value={value}
        onClick={handleClick}
        onEditorChange={onEditorChange}
      />
    );
  }
}
