import React from "react";
import {
  Image,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View
} from "react-native";
import { IHighlight } from "react-pdf-highlighter";
import {
  COLOR_BLUE,
  COLOR_DARK_GREY,
  COLOR_GREY_PDF_HIGHLIGHT_BACKGROUND,
  COLOR_GREY_PDF_HIGHLIGHT_BACKGROUND_SELECTED,
  COLOR_GREY_PDF_HIGHLIGHT_FOREGROUND,
  COLOR_WHITE
} from "../../../static/misc/colors";
import {
  FONT_GILROY_SEMI_BOLD,
  PADDING_SIDES
} from "../../../static/misc/constants";
import { crossRedIcon, pen } from "../../../static/misc/images";
import i18n from "../../services/i18n";
import Icon from "../atoms/Icon";
import Button from "./Button";

interface PdfViewerAnnotationProps {
  highlight: IHighlight;
  isSelected: boolean;
  onAnnotationSelected: () => void;
  onCommentModified: (content: string) => void;
  onDelete: () => void;
}

interface PdfViewerAnnotationState {
  isModifying: boolean;
  isDeleting: boolean;
  annotationComment: string;
  textInputLines: number;
}

class PdfViewerAnnotation extends React.PureComponent<
  PdfViewerAnnotationProps,
  PdfViewerAnnotationState
> {
  constructor(props: PdfViewerAnnotationProps) {
    super(props);

    this.state = {
      isDeleting: false,
      isModifying: false,
      annotationComment: props.highlight.comment.text,
      textInputLines: 3
    };
  }

  render(): JSX.Element {
    const {
      highlight,
      isSelected,
      onAnnotationSelected,
      onCommentModified,
      onDelete
    } = this.props;
    const {
      annotationComment,
      isDeleting,
      isModifying,
      textInputLines
    } = this.state;

    return (
      <View
        style={[
          styles.annotation,
          {
            display: "flex",
            backgroundColor: isSelected
              ? COLOR_GREY_PDF_HIGHLIGHT_BACKGROUND_SELECTED
              : COLOR_GREY_PDF_HIGHLIGHT_BACKGROUND
          }
        ]}
      >
        {isDeleting ? (
          <View style={styles.delete}>
            <Text style={styles.text}>
              {i18n.translate("media.deletionConfirmation")}
            </Text>
            <View
              style={[
                styles.annotationActions,
                { paddingTop: PADDING_SIDES * 0.1 }
              ]}
            >
              <Button
                containerStyle={[styles.inverseButtonStyle]}
                textStyle={styles.inverseButtonTextStyle}
                label={i18n.translate("media.cancel")}
                onPress={() => this.setState({ isDeleting: false })}
              />
              <Button
                containerStyle={[styles.buttonStyle, { marginStart: 5 }]}
                textStyle={styles.buttonTextStyle}
                label={i18n.translate("media.confirm")}
                onPress={onDelete}
              />
            </View>
          </View>
        ) : (
          <>
            <View style={styles.annotationActions}>
              {isModifying ? (
                <Button
                  containerStyle={[styles.buttonStyle, { marginBottom: 5 }]}
                  textStyle={styles.buttonTextStyle}
                  label={i18n.translate("media.ok")}
                  onPress={() => onCommentModified(annotationComment)}
                />
              ) : (
                <>
                  <TouchableOpacity
                    onPress={() => this.setState({ isModifying: true })}
                  >
                    <Icon icon={pen} />
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => this.setState({ isDeleting: true })}
                    style={{ marginStart: 5 }}
                  >
                    <Icon icon={crossRedIcon} />
                  </TouchableOpacity>
                </>
              )}
            </View>
            {isModifying ? (
              <View style={{ flex: 1 }}>
                <TextInput
                  onBlur={() => onCommentModified(annotationComment)}
                  multiline
                  numberOfLines={textInputLines}
                  placeholder={i18n.translate("media.placeholder")}
                  onChangeText={(input) => {
                    this.setState({
                      annotationComment: input
                    });
                  }}
                  value={annotationComment}
                  style={[styles.input, { maxHeight: 120 }]}
                  onContentSizeChange={(e) => {
                    this.setState({
                      textInputLines: e.nativeEvent.contentSize.height / 8
                    });
                  }}
                />
              </View>
            ) : (
              <>
                <TouchableOpacity
                  onPress={() => onAnnotationSelected()}
                  style={{ flex: 1 }}
                >
                  <View style={[styles.annotationInfo, { flex: 1 }]}>
                    {highlight.content.text ? (
                      <Text numberOfLines={1} style={styles.title}>
                        {highlight.content.text}
                      </Text>
                    ) : (
                      highlight.content.image && (
                        <Image
                          style={{ width: 50, height: 50 }}
                          resizeMode="cover"
                          source={{ uri: highlight.content.image }}
                        />
                      )
                    )}
                    {isModifying ? (
                      <TextInput
                        multiline
                        numberOfLines={textInputLines}
                        placeholder={i18n.translate("media.placeholder")}
                        onChangeText={(input) => {
                          this.setState({
                            annotationComment: input
                          });
                        }}
                        value={annotationComment}
                        style={[styles.input, { maxHeight: 120 }]}
                        onContentSizeChange={(e) => {
                          this.setState({
                            textInputLines: e.nativeEvent.contentSize.height / 8
                          });
                        }}
                      />
                    ) : highlight.comment.text ? (
                      <Text style={styles.text}>{highlight.comment.text}</Text>
                    ) : null}
                  </View>
                </TouchableOpacity>
                <View style={styles.annotationActions}>
                  <Text style={styles.text}>{`${i18n.translate("media.page")} ${
                    highlight.position.pageNumber
                  }`}</Text>
                </View>
              </>
            )}
          </>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  annotation: {
    flexDirection: "column",
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderColor: COLOR_GREY_PDF_HIGHLIGHT_FOREGROUND,
    padding: PADDING_SIDES * 0.1
  },
  title: {
    fontFamily: FONT_GILROY_SEMI_BOLD,
    color: COLOR_GREY_PDF_HIGHLIGHT_FOREGROUND,
    fontSize: 8
  },
  text: {
    color: COLOR_GREY_PDF_HIGHLIGHT_FOREGROUND,
    fontSize: 8
  },
  delete: {
    flexDirection: "column"
  },
  annotationActions: {
    flexDirection: "row",
    justifyContent: "flex-end",
    flexWrap: "wrap"
  },
  buttonStyle: {
    backgroundColor: COLOR_BLUE,
    borderColor: COLOR_BLUE,
    paddingHorizontal: PADDING_SIDES * 0.1,
    paddingVertical: PADDING_SIDES * 0.05
  },
  inverseButtonStyle: {
    backgroundColor: COLOR_WHITE,
    borderColor: COLOR_BLUE,
    paddingHorizontal: PADDING_SIDES * 0.1,
    paddingVertical: PADDING_SIDES * 0.05
  },
  buttonTextStyle: {
    fontSize: 8
  },
  inverseButtonTextStyle: {
    fontSize: 8,
    color: COLOR_BLUE
  },
  input: {
    padding: PADDING_SIDES * 0.1,
    color: COLOR_DARK_GREY,
    backgroundColor: COLOR_WHITE,
    fontSize: 8
  },
  annotationInfo: {
    marginVertical: PADDING_SIDES * 0.2
  }
});

export default PdfViewerAnnotation;
