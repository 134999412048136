import moment from "moment";
import React from "react";
import {
  StyleProp,
  ViewStyle,
  TextStyle,
  StyleSheet,
  View
} from "react-native";
import { connect } from "react-redux";
import { COLOR_RED, COLOR_YELLOW_INFO } from "../../../static/misc/colors";
import { EACH_SECONDS } from "../../../static/misc/constants";
import { ExamType } from "../../modules/exams/types/exam";
import { playIcon } from "../../../static/misc/images.electron";
import AvailableTag from "../molecules/AvailableTag";
import i18n from "../../services/i18n";
import Button from "../molecules/Button";
import { MediaObjectType } from "../../modules/exams/types/attachedfiles";
import { RootState } from "../../store/rootreducer";
import { UserState } from "../../modules/main/types/user";
import { StudentPaperType } from "../../modules/examTaking/types/studentPaper";
import { hasStudentPaper } from "../../modules/exams/utils";

interface ButtonNextExamListProps {
  containerTagStyle?: StyleProp<ViewStyle>;
  textTagStyle?: StyleProp<TextStyle>;
  exam: ExamType;
  currentAttachedFiles?: MediaObjectType;
  currentExamTaking: ExamType[] | undefined;
  onPress: () => void;
  currentUser: UserState;
  studentPapers: StudentPaperType[];
}

interface ButtonNextExamListState {
  isAvailable: boolean;
  isGoingBackToExam: boolean;
  isTagAvailable: boolean;
}

class ButtonNextExamList extends React.Component<
  ButtonNextExamListProps,
  ButtonNextExamListState
> {
  intervalTimer!: ReturnType<typeof setInterval>;

  constructor(props: ButtonNextExamListProps) {
    super(props);
    const { exam, currentExamTaking, studentPapers, currentUser } = props;
    const isTakingExam = currentExamTaking
      ? currentExamTaking.filter((examTaking) => {
          return examTaking.id === exam.id;
        }).length > 0
      : false;

    this.state = {
      isGoingBackToExam:
        isTakingExam && hasStudentPaper(exam.id, currentUser.id, studentPapers),
      isAvailable: isTakingExam
        ? moment(
            new Date(exam.startDate).getTime() +
              exam.timeZeroSecond * 1000 +
              exam.duration * 1000
          ).isSameOrAfter(new Date().getTime())
        : moment(
            new Date(exam.startDate).getTime() + exam.timeZeroSecond * 1000
          ).isSameOrAfter(new Date().getTime()) === true,
      isTagAvailable: isTakingExam
        ? true
        : moment(new Date(exam.startDate).getTime()).isSameOrBefore(
            new Date().getTime()
          ) === true &&
          moment(
            new Date(exam.startDate).getTime() + exam.timeZeroSecond * 1000
          ).isSameOrAfter(new Date().getTime()) === true
    };
  }

  componentDidMount(): void {
    const { exam, currentExamTaking, currentUser, studentPapers } = this.props;
    const isTakingExam = currentExamTaking
      ? currentExamTaking.filter((examTaking) => {
          return examTaking.id === exam.id;
        }).length > 0
      : false;
    // Setting up the interval to check every second if the exam has become available
    this.intervalTimer = setInterval(() => {
      if (
        isTakingExam &&
        hasStudentPaper(exam.id, currentUser.id, studentPapers)
      ) {
        this.setState({ isGoingBackToExam: true });
        if (
          moment(
            new Date(exam.startDate).getTime() +
              exam.timeZeroSecond * 1000 +
              exam.duration * 1000
          ).isSameOrAfter(new Date().getTime()) === true
        ) {
          this.setState({ isAvailable: true, isTagAvailable: true });
        }
      } else if (
        moment(
          new Date(exam.startDate).getTime() + exam.timeZeroSecond * 1000
        ).isSameOrAfter(new Date().getTime()) === true
      ) {
        this.setState({ isAvailable: true });
        if (
          moment(new Date(exam.startDate).getTime()).isSameOrBefore(
            new Date().getTime()
          ) === true
        )
          this.setState({ isTagAvailable: true });
        else this.setState({ isTagAvailable: false });
      } else {
        this.setState({ isAvailable: false });
        if (
          moment(new Date(exam.startDate).getTime()).isSameOrBefore(
            new Date().getTime()
          ) === true
        )
          this.setState({ isTagAvailable: true });
        else this.setState({ isTagAvailable: false });
      }
    }, EACH_SECONDS);
  }

  componentWillUnmount(): void {
    clearInterval(this.intervalTimer);
  }

  getAvailableText(showTimeOptions: boolean, exam: ExamType): string {
    const { isGoingBackToExam } = this.state;

    if (!showTimeOptions) {
      return `${i18n.t("homepage.available")}`;
    }

    if (isGoingBackToExam) {
      return `${i18n.t("homepage.availableBack")}: ${moment(
        new Date(
          new Date(exam.startDate).getTime() +
            exam.timeZeroSecond * 1000 +
            exam.duration * 1000
        )
      ).format("LL HH[h]mm")}`;
    }

    return `${i18n.t("homepage.availableUntil")} : ${moment(
      new Date(new Date(exam.startDate).getTime() + exam.timeZeroSecond * 1000)
    ).format("LL HH[h]mm")}`;
  }

  render(): JSX.Element {
    const {
      exam,
      currentAttachedFiles,
      onPress,
      containerTagStyle,
      textTagStyle
    } = this.props;
    const { isAvailable, isTagAvailable, isGoingBackToExam } = this.state;

    const totalFiles = exam.allAttachedFiles?.length || 0;
    const downloadedFiles =
      currentAttachedFiles && currentAttachedFiles.medias.length > 0
        ? currentAttachedFiles.medias.filter((file) => file.isDownloaded).length
        : 0;
    const percent = (downloadedFiles * 100) / totalFiles;
    const showTimeOptions = exam.examParams?.showTimeOptions ?? true;

    return (
      <View>
        <Button
          label={
            isGoingBackToExam
              ? i18n.t("homepage.backToExam")
              : i18n.t("homepage.exam")
          }
          disabled={!isAvailable}
          icon={isAvailable ? playIcon : null}
          onPress={onPress}
        />
        {(!isAvailable && (
          <AvailableTag
            text={
              isGoingBackToExam
                ? `${i18n.t("homepage.backUnavailable")}`
                : `${i18n.t("homepage.unavailable")}`
            }
            dotStyle={styles.expiredIcon}
            containerStyle={containerTagStyle}
            textStyle={textTagStyle}
          />
        )) ||
          (totalFiles > 0 && downloadedFiles < totalFiles && (
            <AvailableTag
              text={`${i18n.t("homepage.incomplete", [percent.toFixed()])}`}
              dotStyle={styles.incompleteIcon}
              containerStyle={{ paddingTop: 10 }}
              textStyle={textTagStyle}
            />
          )) ||
          (isAvailable && isTagAvailable && (
            <AvailableTag
              text={this.getAvailableText(showTimeOptions, exam)}
              containerStyle={containerTagStyle}
              textStyle={textTagStyle}
            />
          ))}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  expiredIcon: {
    backgroundColor: COLOR_RED,
    alignSelf: "flex-start"
  },
  incompleteIcon: {
    backgroundColor: COLOR_YELLOW_INFO,
    alignSelf: "flex-start"
  }
});

export default connect((state: RootState) => {
  return {
    currentExamTaking: state.examTaking.exams,
    currentUser: state.user,
    studentPapers: state.studentPaper.studentPapers
  };
})(ButtonNextExamList);
