/* eslint-disable camelcase */
import { ClearCredentialsActions } from "../../../store/rootreducer";

export const CONNECTUSER = "CONNECTUSER";

export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_FAIL = "REFRESH_TOKEN_FAIL";

export interface RefreshTokenAction {
  type:
    | typeof REFRESH_TOKEN
    | typeof REFRESH_TOKEN_FAIL
    | typeof REFRESH_TOKEN_SUCCESS;
  payload: any;
}

export interface ConnectUserAction {
  type: typeof CONNECTUSER;
  payload: any;
}

interface RessourceAccess {
  account: Array<string>;
  sonarqube: Array<string>;
}

interface RealmAccess {
  roles: Array<string>;
}

export interface CredentialsType {
  scope: string;
  session_state: string;
  sub: string;
  locale: string;
  token_type: string;
  typ: string;
  ressource_access: RessourceAccess;
  realm_access: RealmAccess;
  refresh_token: string;
  refresh_expires_in: number;
  preferred_username: string;
  ["not-before-policy"]: number;
  name: string;
  jti: string;
  iss: string;
  id_token: string;
  iat: string;
  groups: Array<string>;
  given_name: string;
  family_name: string;
  auth_time: number;
  azp: string;
  email: string;
  email_verified: boolean;
  exp: number;
  aud: Array<string>;
  allowed: Array<string>;
  access_token: string;
  acr: string;
}

export interface AuthState {
  isConnected: boolean;
  failureRefreshToken: boolean;
  failureRefreshTokenStatus: number;
  credentials: CredentialsType | undefined;
}

export type AuthActionTypes =
  | ConnectUserAction
  | RefreshTokenAction
  | ClearCredentialsActions;
