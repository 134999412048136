import React from "react";
import { StyleSheet, View, Text } from "react-native";

import { COLOR_BLUE_TESTWE, COLOR_WHITE } from "../../../static/misc/colors";
import {
  FONTSIZE_16,
  FONT_GILROY_REGULAR,
  PADDING_SIDES
} from "../../../static/misc/constants";
import Button from "./Button";

export interface ContentModalProps {
  onValidation: () => void;
  description: string;
  labelButton: string;
}

export default class ContentModal extends React.PureComponent<ContentModalProps> {
  render(): JSX.Element {
    const { description, labelButton, onValidation } = this.props;

    return (
      <View style={styles.modalContentContainer}>
        <View style={{ width: "100%" }}>
          <Text
            style={{
              paddingHorizontal: PADDING_SIDES
            }}
          >
            <Text style={styles.description}>{description}</Text>
          </Text>
        </View>
        <View style={styles.buttonsContainer}>
          <Button
            label={labelButton}
            containerStyle={[styles.buttonConfirm]}
            textStyle={styles.buttonConfirmTextStyle}
            onPress={onValidation}
          />
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  modalContentContainer: {
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 50
  },
  buttonConfirm: {
    width: "50%",
    backgroundColor: COLOR_WHITE,
    borderWidth: 1,
    borderColor: COLOR_BLUE_TESTWE
  },
  buttonsContainer: {
    alignItems: "center",
    width: "100%",
    paddingHorizontal: PADDING_SIDES
  },
  buttonConfirmTextStyle: {
    color: COLOR_BLUE_TESTWE
  },
  description: {
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_16,
    color: COLOR_BLUE_TESTWE
  }
});
