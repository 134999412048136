import React from "react";
import { bindActionCreators, Dispatch } from "redux";
import { StyleSheet, Text, View } from "react-native";
import { connect } from "react-redux";
import { COLOR_BLUE_TESTWE, COLOR_WHITE } from "../../../static/misc/colors";
import {
  FONTSIZE_16,
  FONT_GILROY_BOLD,
  FONT_GILROY_REGULAR,
  PADDING_SIDES
} from "../../../static/misc/constants";
import { NetworkTestStatus } from "../../modules/testing/types/connectionTesting";
import i18n from "../../services/i18n";
import Button from "../molecules/Button";
import FileTransferProgress from "../molecules/FileTransferProgress";
import { RootState } from "../../store/rootreducer";
import { downloadTestFile } from "../../modules/testing/actions/connectionTesting";
import getSpeedRanking from "../../services/web/network";

export interface ConnectionTestingViewProps {
  downloadCompletion: number;
  downloadSpeed: number;
  uploadCompletion: number;
  uploadSpeed: number;
  networkTestStatus: NetworkTestStatus;
  downloadTestFile: () => any;
  onTestCompleted: (downloadSpeed: number, uploadSpeed: number) => void;
}

export interface ConnectionTestingViewState {
  dlSpeed: number;
  ulSpeed: number;
  dlValidated: boolean;
  ulValidated: boolean;
  dlCompletion: number;
  ulCompletion: number;
}

class ConnectionTestingView extends React.PureComponent<
  ConnectionTestingViewProps,
  ConnectionTestingViewState
> {
  constructor(props: ConnectionTestingViewProps) {
    super(props);

    this.state = {
      dlSpeed: 0,
      ulSpeed: 0,
      dlValidated: false,
      ulValidated: false,
      dlCompletion: 0,
      ulCompletion: 0
    };
  }

  componentDidUpdate(prevProps: ConnectionTestingViewProps): void {
    const {
      downloadSpeed,
      uploadSpeed,
      downloadCompletion,
      uploadCompletion,
      networkTestStatus,
      onTestCompleted
    } = this.props;
    if (
      prevProps.networkTestStatus !== networkTestStatus &&
      networkTestStatus === NetworkTestStatus.finished
    ) {
      onTestCompleted(Math.trunc(downloadSpeed), Math.trunc(uploadSpeed));
    }
    if (prevProps.downloadSpeed !== downloadSpeed) {
      this.setState({
        dlSpeed: downloadSpeed,
        dlValidated: getSpeedRanking("download", downloadSpeed) >= 2,
        dlCompletion: downloadCompletion
      });
    }
    if (prevProps.uploadSpeed !== uploadSpeed) {
      this.setState({
        ulSpeed: uploadSpeed,
        ulValidated: getSpeedRanking("upload", uploadSpeed) >= 2,
        ulCompletion: uploadCompletion
      });
    }
  }

  render(): JSX.Element {
    const {
      networkTestStatus,
      // eslint-disable-next-line no-shadow
      downloadTestFile
    } = this.props;
    const {
      dlSpeed,
      ulSpeed,
      dlValidated,
      ulValidated,
      dlCompletion,
      ulCompletion
    } = this.state;
    return (
      <View style={{ flex: 1, alignItems: "center" }}>
        <Text style={[styles.defaultBoldTxt]}>
          {i18n.t("testing.connectionTesting.connectionSpeedTestLabel")}
        </Text>
        <FileTransferProgress
          completion={dlCompletion}
          label={i18n.t("testing.connectionTesting.downloadLabel")}
          speed={dlSpeed}
          validated={dlValidated}
        />
        <FileTransferProgress
          completion={ulCompletion}
          label={i18n.t("testing.connectionTesting.uploadLabel")}
          speed={ulSpeed}
          validated={ulValidated}
        />
        <Button
          label={
            networkTestStatus === NetworkTestStatus.started
              ? i18n.t(
                  "testing.connectionTesting.connectionSpeedTestStartedLabel"
                )
              : i18n.t(
                  "testing.connectionTesting.connectionSpeedTestStartLabel"
                )
          }
          disabled={networkTestStatus === NetworkTestStatus.started}
          containerStyle={[styles.testButtonContainer]}
          textStyle={styles.testButtonText}
          onPress={downloadTestFile}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  defaultBoldTxt: {
    fontFamily: FONT_GILROY_BOLD,
    fontSize: FONTSIZE_16,
    color: COLOR_BLUE_TESTWE,
    marginBottom: PADDING_SIDES * 0.5
  },
  testButtonContainer: {
    borderStyle: "solid",
    borderColor: COLOR_BLUE_TESTWE,
    borderWidth: 1,
    borderRadius: 50,
    backgroundColor: COLOR_WHITE,
    marginTop: PADDING_SIDES * 0.5
  },
  testButtonText: {
    color: COLOR_BLUE_TESTWE,
    fontFamily: FONT_GILROY_REGULAR
  }
});

const mapStateToProps = (state: RootState) => {
  return {
    downloadSpeed: state.connectionTesting.downloadSpeed,
    downloadCompletion: state.connectionTesting.downloadCompletion,
    uploadSpeed: state.connectionTesting.uploadSpeed,
    uploadCompletion: state.connectionTesting.uploadCompletion,
    networkTestStatus: state.connectionTesting.testStatus
  };
};

const mapdispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators(
      {
        downloadTestFile
      },
      dispatch
    )
  };
};

const ConnectionTesting = connect(
  mapStateToProps,
  mapdispatchToProps
)(ConnectionTestingView);

export default ConnectionTesting;
