import React from "react";
import {
  StyleSheet,
  View,
  Text,
  StyleProp,
  ViewStyle,
  TextStyle
} from "react-native";
import i18n from "../../services/i18n";
import { guidGenerator } from "../../../static/misc/utils";
import RuleExamItem from "../atoms/RuleExamItem";
import {
  COLOR_SHADOW,
  COLOR_BLUE_TESTWE,
  COLOR_WHITE
} from "../../../static/misc/colors";
import {
  FONTSIZE_13,
  FONT_GILROY_BOLD,
  PADDING_SIDES
} from "../../../static/misc/constants";
import { ExamParam } from "../../modules/exams/types/exam";

export type DataRuleExam = {
  rule: string;
  isAvailable: boolean;
};

export interface RuleExamProps {
  data: ExamParam;
  containerStyle?: StyleProp<ViewStyle>;
  titleStyle?: StyleProp<TextStyle>;
  itemContainerStyle?: StyleProp<ViewStyle>;
  itemTextStyle?: StyleProp<TextStyle>;
}

class RuleExam extends React.PureComponent<RuleExamProps> {
  render(): JSX.Element {
    const {
      data,
      containerStyle,
      itemContainerStyle,
      titleStyle,
      itemTextStyle
    } = this.props;
    const rules: DataRuleExam[] = [];

    rules.push(
      {
        rule: i18n.t("rules.allowCopyAndPaste"),
        isAvailable: data.allowCopyAndPaste
      },
      {
        rule: i18n.t("rules.anonymousCorrection"),
        isAvailable: data.anonymousCorrection
      },
      {
        rule: i18n.t("rules.scientificCalculator"),
        isAvailable: data.scientificCalculator
      },
      {
        rule: i18n.t("rules.basicCalculator"),
        isAvailable: data.basicCalculator
      },
      {
        rule: i18n.t("rules.proctoring"),
        isAvailable:
          data.proctoring || data.proctoringLive || data.proctoringLiveRecord
      },
      {
        rule: i18n.t("rules.openBook"),
        isAvailable: data.openBook
      }
    );

    return (
      <View style={[styles.container, containerStyle]}>
        <Text style={[styles.textStyle, titleStyle]}>
          {i18n.t("rules.rules")}
        </Text>
        <View style={styles.itemList}>
          {rules.map((rule) => {
            return (
              <RuleExamItem
                key={guidGenerator()}
                text={rule.rule}
                isAvailable={rule.isAvailable}
                containerStyle={itemContainerStyle}
                textStyle={itemTextStyle}
              />
            );
          })}
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLOR_WHITE,
    flexDirection: "column",
    zIndex: 91,
    borderRadius: 20,
    width: 170,
    shadowColor: COLOR_SHADOW,
    shadowOffset: {
      width: 0,
      height: 0
    },
    shadowRadius: 5,
    shadowOpacity: 1,
    paddingVertical: PADDING_SIDES * 0.25,
    paddingHorizontal: PADDING_SIDES * 0.4,
    flexShrink: 1
  },
  itemList: {
    width: "100%",
    flexDirection: "column",
    flexShrink: 1
  },
  textStyle: {
    alignSelf: "center",
    fontFamily: FONT_GILROY_BOLD,
    fontSize: FONTSIZE_13,
    color: COLOR_BLUE_TESTWE,
    paddingBottom: PADDING_SIDES * 0.1
  }
});

export default RuleExam;
