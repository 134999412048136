export default {
  toolbar: {
    undo: "Annuler",
    redo: "Rétablir",
    print: "Imprimer",
    paintformat: "Appliquer le format",
    clearformat: "Annuler le format",
    format: "Format",
    fontName: "Police",
    fontSize: "Taille de Police",
    fontBold: "Gras",
    fontItalic: "Italic",
    underline: "Souligner",
    strike: "Barrer",
    color: "Couleur du texte",
    bgcolor: "Couleur de remplissage",
    border: "Bordures",
    merge: "Fusionner les cellules",
    align: "Aligner horizontalement",
    valign: "Aligner vericalement",
    textwrap: "Text wrapping",
    freeze: "Freeze cell",
    autofilter: "Filter",
    formula: "Functions",
    more: "More"
  },
  contextmenu: {
    copy: "Copier",
    cut: "Couper",
    paste: "Coller",
    pasteValue: "Paste values only",
    pasteFormat: "Paste format only",
    hide: "Hide",
    insertRow: "Insert row",
    insertColumn: "Insert column",
    deleteSheet: "Delete",
    deleteRow: "Delete row",
    deleteColumn: "Delete column",
    deleteCell: "Delete cell",
    deleteCellText: "Delete cell text",
    validation: "Data validations",
    cellprintable: "Enable export",
    cellnonprintable: "Disable export",
    celleditable: "Enable editing",
    cellnoneditable: "Disable editing"
  },
  print: {
    size: "Paper size",
    orientation: "Page orientation",
    orientations: ["Landscape", "Portrait"]
  },
  format: {
    normal: "Normal",
    text: "Plain Text",
    number: "Number",
    percent: "Percent",
    rmb: "RMB",
    usd: "USD",
    eur: "EUR",
    date: "Date",
    time: "Time",
    datetime: "Date time",
    duration: "Duration"
  },
  formula: {
    ABS:  "ABS",
    ACCRINT:  "INTERET.ACC",
    ACOS:  "ACOS",
    ACOSH:  "ACOSH",
    ACOT:  "ACOT",
    ACOTH:  "ACOTH",
    AGGREGATE:  "AGREGAT",
    AND:  "ET",
    ARABIC:  "CHIFFRE.ARABE",
    ASIN:  "ASIN",
    ASINH:  "ASINH",
    ATAN:  "ATAN",
    ATAN2:  "ATAN2",
    ATANH:  "ATANH",
    AVEDEV:  "ECART.MOYEN",
    AVERAGE:  "MOYENNE",
    AVERAGEA:  "AVERAGEA",
    AVERAGEIF:  "MOYENNE.SI",
    AVERAGEIFS:  "MOYENNE.SI.ENS",
    BASE:  "BASE",
    BESSELI:  "BESSELI",
    BESSELJ:  "BESSELJ",
    BESSELK:  "BESSELK",
    BESSELY:  "BESSELY",
    "BETA\.DIST":  "LOI.BETA.N",
    "BETA\.INV":  "BETA.INVERSE.N",
    BETADIST:  "LOI.BETA",
    BETAINV:  "BETA.INVERSE",
    BIN2DEC:  "BINDEC",
    BIN2HEX:  "BINHEX",
    BIN2OCT:  "BINOCT",
    "BINOM\.DIST":  "LOI.BINOMIALE.N",
    "BINOM\.DIST.RANGE":  "LOI.BINOMIALE.SERIE",
    "BINOM\.INV":  "LOI.BINOMIALE.INVERSE",
    BINOMDIST:  "LOI.BINOMIALE",
    BITAND:  "BITET",
    BITLSHIFT:  "BITDECALG",
    BITOR:  "BITOU",
    BITRSHIFT:  "BITDECALD",
    BITXOR:  "BITOUEXCLUSIF",
    CEILING:  "PLAFOND",
    CHAR:  "CAR",
    "CHISQ\.DIST":  "LOI.KHIDEUX.N",
    "CHISQ\.DIST.RT":  "LOI.KHIDEUX.DROITE",
    "CHISQ\.INV":  "LOI.KHIDEUX.INVERSE",
    "CHISQ\.INV.RT":  "LOI.KHIDEUX.INVERSE.DROITE",
    CHOOSE:  "CHOISIR",
    CLEAN:  "EPURAGE",
    CODE:  "CODE",
    COLUMN:  "COLONNE",
    COLUMNS:  "COLONNES",
    COMBIN:  "COMBIN",
    COMBINA:  "COMBINA",
    COMPLEX:  "COMPLEXE",
    CONCATENATE:  "CONCATENER",
    CONFIDENCE:  "INTERVALLE.CONFIANCE",
    "CONFIDENCE\.NORM":  "INTERVALLE.CONFIANCE.NORMAL",
    "CONFIDENCE\.T":  "INTERVALLE.CONFIANCE.STUDENT",
    CONVERT:  "CONVERT",
    CORREL:  "COEFFICIENT.CORRELATION",
    COS:  "COS",
    COSH:  "COSH",
    COT:  "COT",
    COTH:  "COTH",
    COUNT:  "NB",
    COUNTA:  "NBVAL",
    COUNTBLANK:  "NB.VIDE",
    COUNTIF:  "NB.SI",
    COUNTIFS:  "NB.SI.ENS",
    "COVARIANCE\.P":  "COVARIANCE.PEARSON",
    "COVARIANCE\.S":  "COVARIANCE.STANDARD",
    CSC:  "CSC",
    CSCH:  "CSCH",
    CUMIPMT:  "CUMUL.INTER",
    CUMPRINC:  "CUMUL.PRINCPER",
    DATE:  "DATE",
    DATEVALUE:  "DATEVAL",
    DAY:  "JOUR",
    DAYS:  "JOURS",
    DAYS360:  "JOURS360",
    DB:  "DB",
    DDB:  "DDB",
    DEC2BIN:  "DECBIN",
    DEC2HEX:  "DECHEX",
    DEC2OCT:  "DECOCT",
    DECIMAL:  "DECIMAL",
    DEGREES:  "DEGRES",
    DELTA:  "DELTA",
    DEVSQ:  "SOMME.CARRES.ECARTS",
    DOLLARDE:  "PRIX.DEC",
    DOLLARFR:  "PRIX.FRAC",
    EDATE:  "MOIS.DECALER",
    EFFECT:  "TAUX.EFFECTIF",
    EOMONTH:  "FIN.MOIS",
    ERF:  "ERF",
    ERFC:  "ERFC",
    EVEN:  "PAIR",
    EXACT:  "EXACT",
    EXP:  "EXP",
    "EXPON\.DIST":  "LOI.EXPONENTIELLE.N",
    EXPONDIST:  "LOI.EXPONENTIELLE",
    "F\.DIST":  "LOI.F.N",
    "F\.DIST.RT":  "LOI.F.DROITE",
    "F\.INV":  "INVERSE.LOI.F.N",
    "F\.INV.RT":  "INVERSE.LOI.F.DROITE",
    FACT:  "FACT",
    FACTDOUBLE:  "FACTDOUBLE",
    FALSE:  "FAUX",
    FDIST:  "LOI.F",
    FIND:  "TROUVE",
    FINV:  "INVERSE.LOI.F",
    FISHER:  "FISHER",
    FISHERINV:  "FISHER.INVERSE",
    FLOOR:  "PLANCHER",
    FORECAST:  "PREVISION",
    FREQUENCY:  "FREQUENCE",
    FV:  "VC",
    FVSCHEDULE:  "VC.PAIEMENTS",
    GAMMA:  "GAMMA",
    "GAMMA\.DIST":  "LOI.GAMMA.N",
    "GAMMA\.INV":  "LOI.GAMMA.INVERSE.N",
    GAMMADIST:  "LOI.GAMMA",
    GAMMAINV:  "LOI.GAMMA.INVERSE",
    GAMMALN:  "LNGAMMA",
    "GAMMALN\.PRECISE":  "LNGAMMA.PRECIS",
    GAUSS:  "GAUSS",
    GCD:  "PGCD",
    GEOMEAN:  "MOYENNE.GEOMETRIQUE",
    GESTEP:  "SUP.SEUIL",
    GROWTH:  "CROISSANCE",
    HARMEAN:  "MOYENNE.HARMONIQUE",
    HEX2BIN:  "HEXBIN",
    HEX2DEC:  "HEXDEC",
    HEX2OCT:  "HEXOCT",
    HOUR:  "HEURE",
    "HYPGEOM\.DIST":  "LOI.HYPERGEOMETRIQUE.N",
    HYPGEOMDIST:  "LOI.HYPERGEOMETRIQUE",
    IF:  "SI",
    IMABS:  "COMPLEXE.MODULE",
    IMAGINARY:  "COMPLEXE.IMAGINAIRE",
    IMARGUMENT:  "COMPLEXE.ARGUMENT",
    IMCONJUGATE:  "COMPLEXE.CONJUGUE",
    IMCOS:  "COMPLEXE.COS",
    IMCOSH:  "COMPLEXE.COSH",
    IMCOT:  "COMPLEXE.COT",
    IMCSC:  "COMPLEXE.CSC",
    IMCSCH:  "COMPLEXE.CSCH",
    IMDIV:  "COMPLEXE.DIV",
    IMEXP:  "COMPLEXE.EXP",
    IMLN:  "COMPLEXE.LN",
    IMLOG10:  "COMPLEXE.LOG10",
    IMLOG2:  "COMPLEXE.LOG2",
    IMPOWER:  "COMPLEXE.PUISSANCE",
    IMPRODUCT:  "COMPLEXE.PRODUIT",
    IMREAL:  "COMPLEXE.REEL",
    IMSEC:  "COMPLEXE.SEC",
    IMSECH:  "COMPLEXE.SECH",
    IMSIN:  "COMPLEXE.SIN",
    IMSINH:  "COMPLEXE.SINH",
    IMSQRT:  "COMPLEXE.RACINE",
    IMSUB:  "COMPLEXE.DIFFERENCE",
    IMSUM:  "COMPLEXE.SOMME",
    IMTAN:  "COMPLEXE.TAN",
    INT:  "ENT",
    INTERCEPT:  "ORDONNEE.ORIGINE",
    IPMT:  "INTPER",
    IRR:  "TRI",
    ISBLANK:  "ESTVIDE",
    ISEVEN:  "EST.PAIR",
    ISLOGICAL:  "ESTLOGIQUE",
    ISNONTEXT:  "ESTNONTEXTE",
    ISNUMBER:  "ESTNUM",
    ISODD:  "EST.IMPAIR",
    ISOWEEKNUM:  "NO.SEMAINE.ISO",
    ISPMT:  "ISPMT",
    ISTEXT:  "ESTTEXTE",
    KURT:  "KURTOSIS",
    LARGE:  "GRANDE.VALEUR",
    LCM:  "PPCM",
    LEFT:  "GAUCHE",
    LEN:  "NBCAR",
    LINEST:  "DROITEREG",
    LN:  "LN",
    LOG:  "LOG",
    LOG10:  "LOG10",
    LOGEST:  "LOGREG",
    "LOGNORM\.DIST":  "LOI.LOGNORMALE.N",
    "LOGNORM\.INV":  "LOI.LOGNORMALE.INVERSE.N",
    LOGNORMDIST:  "LOI.LOGNORMALE",
    LOWER:  "MINUSCULE",
    MATCH:  "EQUIV",
    MAX:  "MAX",
    MAXA:  "MAXA",
    MEDIAN:  "MEDIANE",
    MID:  "STXT",
    MIN:  "MIN",
    MINA:  "MINA",
    MINUTE:  "MINUTE",
    MIRR:  "TRIM",
    MOD:  "MOD",
    "MODE\.MULT":  "MODE.MULTIPLE",
    "MODE\.SNGL":  "MODE.SIMPLE",
    MONTH:  "MOIS",
    MROUND:  "ARRONDI.AU.MULTIPLE",
    MULTINOMIAL:  "MULTINOMIALE",
    "NEGBINOM\.DIST":  "LOI.BINOMIALE.NEG.N",
    NEGBINOMDIST:  "LOI.BINOMIALE.NEG",
    NETWORKDAYS:  "NB.JOURS.OUVRES",
    NOMINAL:  "TAUX.NOMINAL",
    "NORM\.DIST":  "LOI.NORMALE.N",
    "NORM\.INV":  "LOI.NORMALE.INVERSE.N",
    "NORM\.S.DIST":  "LOI.NORMALE.STANDARD.N",
    "NORM\.S.INV":  "LOI.NORMALE.STANDARD.INVERSE.N",
    NORMDIST:  "LOI.NORMALE",
    NORMINV:  "LOI.NORMALE.INVERSE",
    NORMSDIST:  "LOI.NORMALE.STANDARD",
    NORMSINV:  "LOI.NORMALE.STANDARD.INVERSE",
    NOT:  "NON",
    NOW:  "MAINTENANT",
    NPER:  "NPM",
    NPV:  "VAN",
    OCT2BIN:  "OCTBIN",
    OCT2DEC:  "OCTDEC",
    OCT2HEX:  "OCTHEX",
    ODD:  "IMPAIR",
    OR:  "OU",
    PDURATION:  "PDUREE",
    PEARSON:  "PEARSON",
    PERMUT:  "PERMUTATION",
    PERMUTATIONA:  "PERMUTATIONA",
    PHI:  "PHI",
    PI:  "PI",
    PMT:  "VPM",
    "POISSON\.DIST":  "LOI.POISSON.N",
    POWER:  "PUISSANCE",
    PPMT:  "PRINCPER",
    PROB:  "PROBABILITE",
    PRODUCT:  "PRODUIT",
    PROPER:  "NOMPROPRE",
    PV:  "VA",
    "QUARTILE\.EXC":  "QUARTILE.EXCLURE",
    "QUARTILE\.INC":  "QUARTILE.INCLURE",
    QUOTIENT:  "QUOTIENT",
    RADIANS:  "RADIANS",
    RAND:  "ALEA",
    RANDBETWEEN:  "ALEA.ENTRE.BORNES",
    "RANK\.AVG":  "MOYENNE.RANG",
    "RANK\.EQ":  "EQUATION.RANG",
    RATE:  "TAUX",
    REPLACE:  "REMPLACER",
    REPT:  "REPT",
    RIGHT:  "DROITE",
    ROMAN:  "ROMAIN",
    ROUND:  "ARRONDI",
    ROUNDDOWN:  "ARRONDI.INF",
    ROUNDUP:  "ARRONDI.SUP",
    ROW:  "LIGNE",
    ROWS:  "LIGNES",
    RRI:  "TAUX.INT.EQUIV",
    RSQ:  "COEFFICIENT.DETERMINATION",
    SEARCH:  "CHERCHE",
    SEC:  "SEC",
    SECH:  "SECH",
    SECOND:  "SECONDE",
    SERIESSUM:  "SOMME.SERIES",
    SIGN:  "SIGNE",
    SIN:  "SIN",
    SINH:  "SINH",
    SKEW:  "COEFFICIENT.ASYMETRIE",
    "SKEW\.P":  "COEFFICIENT.ASYMETRIE.P",
    SLN:  "AMORLIN",
    SLOPE:  "PENTE",
    SMALL:  "PETITE.VALEUR",
    SQRT:  "RACINE",
    SQRTPI:  "RACINE.PI",
    STANDARDIZE:  "CENTREE.REDUITE",
    "STDEV\.P":  "ECARTYPE.PEARSON",
    "STDEV\.S":  "ECARTYPE.STANDARD",
    STDEVA:  "STDEVA",
    STDEVP:  "ECARTYPEP",
    STDEVPA:  "STDEVPA",
    STEYX:  "ERREUR.TYPE.XY",
    SUBSTITUTE:  "SUBSTITUE",
    SUBTOTAL:  "SOUS.TOTAL",
    SUM:  "SOMME",
    SUMIF:  "SOMME.SI",
    SUMIFS:  "SOMME.SI.ENS",
    SUMPRODUCT:  "SOMMEPROD",
    SUMSQ:  "SOMME.CARRES",
    SUMX2MY2:  "SOMME.X2MY2",
    SUMX2PY2:  "SOMME.X2PY2",
    SUMXMY2:  "SOMME.XMY2",
    SWITCH:  "SI.MULTIPLE",
    SYD:  "SYD",
    T:  "T",
    "T\.DIST":  "LOI.STUDENT.N",
    "T\.DIST.2T":  "LOI.STUDENT.BILATERALE",
    "T\.DIST.RT":  "LOI.STUDENT.DROITE",
    "T\.INV":  "LOI.STUDENT.INVERSE.N",
    "T\.INV.2T":  "LOI.STUDENT.INVERSE.BILATERALE",
    TAN:  "TAN",
    TANH:  "TANH",
    TBILLEQ:  "TAUX.ESCOMPTE.R",
    TBILLPRICE:  "PRIX.BON.TRESOR",
    TBILLYIELD:  "RENDEMENT.BON.TRESOR",
    TDIST:  "LOI.STUDENT",
    TIME:  "TEMPS",
    TIMEVALUE:  "TEMPSVAL",
    TINV:  "LOI.STUDENT.INVERSE",
    TODAY:  "AUJOURDHUI",
    TRANSPOSE:  "TRANSPOSE",
    TREND:  "TENDANCE",
    TRIM:  "SUPPRESPACE",
    TRIMMEAN:  "MOYENNE.REDUITE",
    TRUE:  "VRAI",
    TRUNC:  "TRONQUE",
    UNICHAR:  "UNICAR",
    UNICODE:  "UNICODE",
    UPPER:  "MAJUSCULE",
    "VAR\.P":  "VAR.P.N",
    "VAR\.S":  "VAR.S",
    VARA:  "VARA",
    VARP:  "VAR.P",
    VARPA:  "VARPA",
    WEEKDAY:  "JOURSEM",
    WEEKNUM:  "NO.SEMAINE",
    "WEIBULL\.DIST":  "LOI.WEIBULL.N",
    WORKDAY:  "SERIE.JOUR.OUVRE",
    XIRR:  "TRI.PAIEMENTS",
    XNPV:  "VAN.PAIEMENTS",
    XOR:  "OUX",
    YEAR:  "ANNEE",
    YEARFRAC:  "FRACTION.ANNEE",
  },
  validation: {
    required: "it must be required",
    notMatch: "it not match its validation rule",
    between: "it is between {} and {}",
    notBetween: "it is not between {} and {}",
    notIn: "it is not in list",
    equal: "it equal to {}",
    notEqual: "it not equal to {}",
    lessThan: "it less than {}",
    lessThanEqual: "it less than or equal to {}",
    greaterThan: "it greater than {}",
    greaterThanEqual: "it greater than or equal to {}"
  },
  error: {
    pasteForMergedCell: "Unable to do this for merged cells"
  },
  calendar: {
    weeks: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ]
  },
  button: {
    next: "Suivant",
    cancel: "Annuler",
    remove: "Remove",
    save: "Sauvegarder",
    ok: "OK"
  },
  sort: {
    desc: "Sort Z -> A",
    asc: "Sort A -> Z"
  },
  filter: {
    empty: "empty"
  },
  dataValidation: {
    mode: "Mode",
    range: "Cell Range",
    criteria: "Criteria",
    modeType: {
      cell: "Cell",
      column: "Colun",
      row: "Row"
    },
    type: {
      list: "List",
      number: "Number",
      date: "Date",
      phone: "Phone",
      email: "Email"
    },
    operator: {
      be: "between",
      nbe: "not betwwen",
      lt: "less than",
      lte: "less than or equal to",
      gt: "greater than",
      gte: "greater than or equal to",
      eq: "equal to",
      neq: "not equal to"
    }
  }
};
