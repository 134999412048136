import React from "react";
import { StyleSheet, Image, View } from "react-native";
import {
  COLOR_BLUE_TESTWE,
  COLOR_GREY_BACKGROUND,
  COLOR_GREY_BORDER
} from "../../../static/misc/colors";
import { coche } from "../../../static/misc/images";

interface SquareButtonProps {
  isSelected?: boolean;
}

class SquareButton extends React.PureComponent<SquareButtonProps> {
  static defaultProps = {
    isSelected: false
  };

  render(): JSX.Element {
    const { isSelected } = this.props;
    return (
      <View style={styles.container}>
        {isSelected && (
          <View style={styles.containerSelected}>
            <Image source={coche} style={styles.selectedIcon} />
          </View>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLOR_GREY_BACKGROUND,
    borderColor: COLOR_GREY_BORDER,
    width: 16,
    height: 16,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 2,
    borderStyle: "solid",
    borderWidth: 0.5
  },
  containerSelected: {
    width: 16,
    height: 16,
    backgroundColor: COLOR_BLUE_TESTWE,
    borderColor: COLOR_GREY_BORDER,
    justifyContent: "center",
    alignItems: "center",
    borderStyle: "solid",
    borderWidth: 0.5
  },
  selectedIcon: {
    width: 9,
    height: 6
  }
});

export default SquareButton;
