import React from "react";
import { StyleProp, StyleSheet, View, ViewStyle, Image } from "react-native";
import { connect } from "react-redux";
import moment from "../../services/moment";
import {
  COLOR_BLUE_TESTWE,
  COLOR_WHITE,
  COLOR_YELLOW_INFO
} from "../../../static/misc/colors";
import { illuHomepage1, playIcon } from "../../../static/misc/images";
import NextExamDate from "../atoms/NextExamDate";
import NextExamSubject from "../atoms/NextExamSubject";
import AvailableTag from "../molecules/AvailableTag";
import Button from "../molecules/Button";
import i18n from "../../services/i18n";
import { PADDING_SIDES, EACH_SECONDS } from "../../../static/misc/constants";
import { MediaObjectType } from "../../modules/exams/types/attachedfiles";
import { RootState } from "../../store/rootreducer";
import { ExamType } from "../../modules/exams/types/exam";
import { StudentPaperType } from "../../modules/examTaking/types/studentPaper";
import { UserState } from "../../modules/main/types/user";
import { hasStudentPaper } from "../../modules/exams/utils";

export interface NextExamProps {
  containerStyle?: StyleProp<ViewStyle>;
  subject: string;
  duration: number;
  timeZeroSecond: number;
  date: Date;
  examId: string;
  currentAttachedFiles?: MediaObjectType;
  attachedFilesNumber: number;
  currentExamTaking?: ExamType[];
  showTimeOptions: boolean;
  studentPapers: StudentPaperType[];
  displaySingleExamInfo?: (examInfoId: string) => void;
  currentUser: UserState;
}

interface NextExamState {
  isGoingBackToExam: boolean;
  isAvailable: boolean;
  isTagAvailable: boolean;
}

class NextExam extends React.PureComponent<NextExamProps, NextExamState> {
  intervalTimer!: ReturnType<typeof setInterval>;

  constructor(props: NextExamProps) {
    super(props);
    const {
      date,
      timeZeroSecond,
      examId,
      duration,
      currentExamTaking,
      studentPapers,
      currentUser
    } = this.props;

    let isTakingExam = false;
    isTakingExam = currentExamTaking
      ? currentExamTaking.filter((examTaking) => {
          return examTaking.id === examId;
        }).length > 0
      : false;
    isTakingExam = !isTakingExam
      ? studentPapers.filter((es: StudentPaperType) => {
          return es.examId === examId;
        }).length > 0
      : false;

    this.state = {
      isGoingBackToExam:
        isTakingExam && hasStudentPaper(examId, currentUser.id, studentPapers),
      isAvailable: isTakingExam
        ? moment(
            new Date(date).getTime() + timeZeroSecond * 1000 + duration * 1000
          ).isSameOrAfter(new Date().getTime()) === true
        : moment(
            new Date(date).getTime() + timeZeroSecond * 1000
          ).isSameOrAfter(new Date().getTime()) === true,
      isTagAvailable: isTakingExam
        ? true
        : moment(new Date(date).getTime()).isSameOrBefore(
            new Date().getTime()
          ) === true &&
          moment(
            new Date(date).getTime() + timeZeroSecond * 1000
          ).isSameOrAfter(new Date().getTime()) === true
    };
  }

  componentDidMount(): void {
    const {
      date,
      timeZeroSecond,
      duration,
      examId,
      currentExamTaking,
      studentPapers,
      currentUser
    } = this.props;
    // Setting up the interval to check every second if the exam has become available
    this.intervalTimer = setInterval(() => {
      let isTakingExam = false;
      isTakingExam = currentExamTaking
        ? currentExamTaking.filter((examTaking) => {
            return examTaking.id === examId;
          }).length > 0
        : false;
      isTakingExam = !isTakingExam
        ? studentPapers.filter((es: StudentPaperType) => {
            return es.examId === examId;
          }).length > 0
        : true;

      if (
        isTakingExam &&
        hasStudentPaper(examId, currentUser.id, studentPapers)
      ) {
        this.setState({ isGoingBackToExam: true });
        if (
          moment(
            new Date(date).getTime() + timeZeroSecond * 1000 + duration * 1000
          ).isSameOrAfter(new Date().getTime()) === true
        ) {
          this.setState({ isAvailable: true, isTagAvailable: true });
        }
      } else if (date !== undefined) {
        if (
          moment(
            new Date(date).getTime() + timeZeroSecond * 1000
          ).isSameOrAfter(new Date().getTime()) === true
        ) {
          this.setState({ isAvailable: true });
          if (
            moment(new Date(date).getTime()).isSameOrBefore(
              new Date().getTime()
            ) === true
          )
            this.setState({ isTagAvailable: true });
          else this.setState({ isTagAvailable: false });
        } else {
          this.setState({ isAvailable: false });
          if (
            moment(new Date(date).getTime()).isSameOrBefore(
              new Date().getTime()
            ) === true
          )
            this.setState({ isTagAvailable: true });
          else this.setState({ isTagAvailable: true });
        }
      }
    }, EACH_SECONDS);
  }

  componentWillUnmount(): void {
    clearInterval(this.intervalTimer);
  }

  getAvailableText(showTimeOptions: boolean, startDate: Date): string {
    const { duration, timeZeroSecond } = this.props;
    const { isGoingBackToExam } = this.state;

    if (!showTimeOptions) {
      return `${i18n.t("homepage.available")}`;
    }

    if (isGoingBackToExam) {
      return `${i18n.t("homepage.availableBack")}: ${moment(
        new Date(
          new Date(startDate).getTime() +
            timeZeroSecond * 1000 +
            duration * 1000
        )
      ).format("LL HH[h]mm")}`;
    }

    return `${i18n.t("homepage.availableUntil")} : ${moment(
      new Date(startDate.getTime() + timeZeroSecond * 1000)
    ).format("LL HH[h]mm")}`;
  }

  render(): JSX.Element {
    const {
      containerStyle,
      duration,
      subject,
      date,
      examId,
      currentAttachedFiles,
      attachedFilesNumber,
      showTimeOptions,
      displaySingleExamInfo
    } = this.props;
    const { isAvailable, isTagAvailable, isGoingBackToExam } = this.state;

    const totalFiles = attachedFilesNumber;
    const downloadedFiles =
      currentAttachedFiles && currentAttachedFiles.medias.length > 0
        ? currentAttachedFiles.medias.filter((file) => file.isDownloaded).length
        : 0;
    const percent = (downloadedFiles * 100) / totalFiles;

    return (
      <View style={[styles.defaultContainerStyle, containerStyle]}>
        <Image source={illuHomepage1} style={styles.illustration} />
        <View style={styles.contentWrapper}>
          <NextExamDate
            date={moment(date).format("DD/MM/YYYY")}
            time={moment(date).format("HH[h]mm")}
            labelNextExam={i18n.t("homepage.nextexam")}
            labelAt={i18n.t("homepage.at")}
          />
          <NextExamSubject
            textStyle={{ paddingBottom: 10 }}
            subject={subject}
            duration={duration}
          />
          <View style={{ flexDirection: "row" }}>
            <Button
              label={
                isGoingBackToExam
                  ? i18n.t("homepage.backToExam")
                  : i18n.t("homepage.exam")
              }
              disabled={!isAvailable}
              containerStyle={[styles.examButton]}
              icon={playIcon}
              onPress={() =>
                displaySingleExamInfo ? displaySingleExamInfo(examId) : null
              }
            />
            {(totalFiles > 0 && downloadedFiles < totalFiles && (
              <AvailableTag
                text={`${i18n.t("homepage.incomplete", [percent.toFixed()])}`}
                dotStyle={styles.incompleteIcon}
                textStyle={styles.availableTag}
                containerStyle={{ paddingTop: 10 }}
              />
            )) ||
              (isTagAvailable && (
                <AvailableTag
                  containerStyle={{ alignItems: "center" }}
                  textStyle={styles.availableTag}
                  text={this.getAvailableText(showTimeOptions, date)}
                />
              ))}
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  defaultContainerStyle: {
    paddingHorizontal: 10,
    paddingVertical: 10,
    alignItems: "center",
    flexDirection: "row",
    backgroundColor: COLOR_WHITE
  },
  contentWrapper: {
    left: -PADDING_SIDES * 0.5,
    paddingLeft: 30,
    flex: 1
  },
  illustration: {
    width: 191,
    height: 130
  },
  examButton: {
    marginRight: "7%"
  },
  availableTag: {
    color: COLOR_BLUE_TESTWE,
    flex: 1
  },
  incompleteIcon: {
    backgroundColor: COLOR_YELLOW_INFO,
    alignSelf: "flex-start"
  }
});

export default connect((state: RootState) => {
  return {
    currentExamTaking: state.examTaking.exams,
    studentPapers: state.studentPaper.studentPapers,
    currentUser: state.user
  };
})(NextExam);
