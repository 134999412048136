/* eslint-disable camelcase */
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "../../store/rootreducer";
import ProfileView from "./ProfileView";
import { setEstablishment } from "./actions/user";

const mapStateToProps = (state: RootState) => {
  return {
    userId: state.user.id,
    token: state.auth.credentials?.access_token,
    locale: state.user.locale,
    isOnline: state.status.isOnline,
    firstName: state.user.firstName,
    lastName: state.user.lastName,
    email: state.user.email,
    currentEstablishment: state.user.currentEstablishment,
    establishments: state.user.establishments,
    postingEstablishment: state.status.postingEstablishment
  };
};

const mapdispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators(
      {
        setEstablishment
      },
      dispatch
    )
  };
};

const ExamsContainer = connect(
  mapStateToProps,
  mapdispatchToProps
)(ProfileView);

export default ExamsContainer;
