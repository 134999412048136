import React from "react";
import { View, StyleSheet } from "react-native";
import { COLOR_BLUE_TESTWE, COLOR_RED } from "../../../static/misc/colors";
import {
  IS_PREVIEW,
  PARTINDEX_TYPE_EXERCISE,
  PARTINDEX_TYPE_QUESTION
} from "../../../static/misc/constants";
import Button from "../molecules/Button";
import i18n from "../../services/i18n";
import { ExamPart } from "../../modules/exams/types/exam";
import BackButton from "../molecules/BackButton";
import { ChangeEnum } from "./ExamTakingContent";

interface ButtonExamTakingProps {
  examParts: ExamPart[];
  isTimer: boolean;
  fixedDuration?: boolean;
  isDisable: boolean;
  onNextQuestion: () => void;
  onNextPart: () => void;
  returnLastQuestion: () => void;
  returnLastPart: () => void;
  onRemainingDurationWarning: (
    callback: (changeType: ChangeEnum) => void,
    changeType: ChangeEnum
  ) => void;
  onFinishExam: () => void;
  onWriteStudentAnswers: (isLastQuestion: boolean) => void;
  currentExamPart: number;
  currentPartIndex: number;
}

class ButtonExamTaking extends React.PureComponent<ButtonExamTakingProps> {
  // Checking if the back button should be displayed
  shouldDisplayBackButton(): boolean {
    const { examParts, currentExamPart, currentPartIndex } = this.props;

    // If we are on the first question of the first part, there cannot be a previous button
    if (currentExamPart === 0 && currentPartIndex === 0) {
      return false;
    }

    let prevItem;
    const currentItem =
      examParts[currentExamPart].partIndexes[currentPartIndex];

    // If we are NOT on the first question of the current part,
    // then the previous item is the previous question of the same part
    if (currentPartIndex > 0) {
      prevItem = examParts[currentExamPart].partIndexes[currentPartIndex - 1];
    } else {
      // Otherwise, the previous item is the last question of the previous part
      // First, checking if the previous part or current part has any timer
      if (
        (examParts[currentExamPart].durationLimit === true &&
          examParts[currentExamPart].duration !== undefined &&
          examParts[currentExamPart].duration > 0) ||
        (examParts[currentExamPart - 1].durationLimit === true &&
          examParts[currentExamPart - 1].duration !== undefined &&
          examParts[currentExamPart - 1].duration > 0)
      ) {
        // If yes, previous button cannot be displayed
        return false;
      }
      const previousPartLength =
        examParts[currentExamPart - 1].partIndexes.length;
      prevItem =
        examParts[currentExamPart - 1].partIndexes[previousPartLength - 1];
    }
    // Previous button should not be displayed if the current or previous item has a timer
    if (
      (currentItem.type === PARTINDEX_TYPE_EXERCISE &&
        currentItem.exercise?.durationLimit === true) ||
      (currentItem.type === PARTINDEX_TYPE_QUESTION &&
        currentItem.question?.durationLimit === true) ||
      (prevItem.type === PARTINDEX_TYPE_EXERCISE &&
        prevItem.exercise?.durationLimit === true) ||
      (prevItem.type === PARTINDEX_TYPE_QUESTION &&
        prevItem.question?.durationLimit === true)
    ) {
      return false;
    }
    return true;
  }

  render(): JSX.Element {
    const {
      examParts,
      isTimer,
      fixedDuration,
      currentExamPart,
      isDisable,
      currentPartIndex,
      onNextPart,
      onWriteStudentAnswers,
      returnLastQuestion,
      returnLastPart,
      onNextQuestion,
      onRemainingDurationWarning,
      onFinishExam
    } = this.props;
    const lengthPartIndexesOfExamParts =
      examParts[currentExamPart].partIndexes.length;
    const lengthExamParts = examParts.length;
    return (
      <View style={styles.buttonContainer}>
        <View>
          {this.shouldDisplayBackButton() ? (
            <BackButton
              label="<"
              navigation={() => {
                onWriteStudentAnswers(false);
                if (
                  lengthPartIndexesOfExamParts > currentPartIndex &&
                  currentPartIndex !== 0
                ) {
                  if (isTimer && !fixedDuration) {
                    onRemainingDurationWarning(
                      returnLastQuestion,
                      ChangeEnum.PREVQUESTION
                    );
                  } else {
                    returnLastQuestion();
                  }
                } else if (
                  lengthPartIndexesOfExamParts > currentPartIndex &&
                  currentPartIndex === 0
                ) {
                  if (isTimer) {
                    onRemainingDurationWarning(
                      returnLastPart,
                      ChangeEnum.PREVPART
                    );
                  } else {
                    returnLastPart();
                  }
                }
              }}
            />
          ) : null}
        </View>
        <View>
          {(lengthPartIndexesOfExamParts > currentPartIndex + 1 && (
            // NEXT PART INDEX OF THE EXAM PART
            <Button
              label=">"
              disabled={isDisable}
              containerStyle={[styles.buttonBackgroundColor]}
              onPress={() => {
                onWriteStudentAnswers(false);
                if (isTimer) {
                  onRemainingDurationWarning(
                    onNextQuestion,
                    ChangeEnum.NEXTQUESTION
                  );
                } else {
                  onNextQuestion();
                }
              }}
            />
          )) ||
            (lengthExamParts > currentExamPart + 1 && (
              // NEXT EXAM PART
              <Button
                label=">"
                disabled={isDisable}
                containerStyle={[styles.buttonBackgroundColor]}
                onPress={() => {
                  onWriteStudentAnswers(false);
                  if (isTimer) {
                    onRemainingDurationWarning(onNextPart, ChangeEnum.NEXTPART);
                  } else {
                    onNextPart();
                  }
                }}
              />
            )) || (
              // FINISH THE EXAM
              <Button
                disabled={!!IS_PREVIEW || isDisable}
                label={i18n.t("examTaking.validateExam")}
                containerStyle={[styles.buttonBackgroundColorRed]}
                onPress={() => {
                  onWriteStudentAnswers(true);
                  if (isTimer) {
                    onRemainingDurationWarning(
                      onFinishExam,
                      ChangeEnum.FINISHEXAM
                    );
                  } else {
                    onFinishExam();
                  }
                }}
              />
            )}
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  buttonContainer: {
    width: "100%",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between"
  },
  buttonBackgroundColor: {
    borderColor: COLOR_BLUE_TESTWE,
    backgroundColor: COLOR_BLUE_TESTWE
  },
  buttonBackgroundColorRed: {
    backgroundColor: COLOR_RED
  }
});

export default ButtonExamTaking;
