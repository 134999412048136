import {
  PersistedStatusAction,
  PersistedStatusState,
  SET_BANNER_ITEMS_STATUS
} from "../types/persistedStatus";

const initialState: PersistedStatusState = {
  bannerItemsStatus: {
    ram: false,
    network: false,
    antivirus: false
  }
};

export default function reducer(
  state = initialState,
  action: PersistedStatusAction
): PersistedStatusState {
  switch (action.type) {
    case SET_BANNER_ITEMS_STATUS:
      return {
        ...state,
        bannerItemsStatus: action.payload
      };

    default:
      return state;
  }
}
