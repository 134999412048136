import { FILE_SIZE } from "../../../../static/misc/network";
import {
  ConnectionTestingActionsType,
  ConnectionTestingState,
  DOWNLOAD_PROGRESS,
  DOWNLOAD_TEST_FILE,
  DOWNLOAD_TEST_FILE_FAIL,
  DOWNLOAD_TEST_FILE_SUCCESS,
  NetworkTestStatus,
  UPLOAD_PROGRESS,
  UPLOAD_TEST_FILE_FAIL,
  UPLOAD_TEST_FILE_SUCCESS
} from "../types/connectionTesting";

const initialState: ConnectionTestingState = {
  downloadCompletion: 0,
  downloadSpeed: 0,
  uploadCompletion: 0,
  uploadSpeed: 0,
  testStatus: NetworkTestStatus.pending
};

export default function reducer(
  state = initialState,
  action: ConnectionTestingActionsType
): ConnectionTestingState {
  switch (action.type) {
    case DOWNLOAD_TEST_FILE: {
      const testStatus = NetworkTestStatus.started;
      return {
        ...state,
        testStatus
      };
    }
    case DOWNLOAD_TEST_FILE_FAIL: {
      const testStatus = NetworkTestStatus.finished;
      return {
        ...state,
        testStatus
      };
    }
    case DOWNLOAD_TEST_FILE_SUCCESS: {
      const downloadSpeed =
        FILE_SIZE /
        (Date.now() - action.meta.previousAction.payload.downloadStartTime);
      return {
        ...state,
        downloadSpeed
      };
    }
    case UPLOAD_TEST_FILE_FAIL: {
      const testStatus = NetworkTestStatus.finished;
      return {
        ...state,
        testStatus
      };
    }
    case UPLOAD_TEST_FILE_SUCCESS: {
      const testStatus = NetworkTestStatus.finished;
      const uploadSpeed =
        FILE_SIZE /
        (Date.now() - action.meta.previousAction.payload.uploadStartTime);
      return {
        ...state,
        uploadSpeed,
        testStatus
      };
    }
    case DOWNLOAD_PROGRESS: {
      return {
        ...state,
        downloadCompletion: action.payload.progress
      };
    }
    case UPLOAD_PROGRESS: {
      return {
        ...state,
        uploadCompletion: action.payload.progress
      };
    }
    default: {
      return state;
    }
  }
}
