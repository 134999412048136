import React from "react";
import CustomModal from "../atoms/CustomModal";
import ExamTakingConfirmModalItem from "./ExamTakingConfirmModalItem";

export interface ExamTakingConfirmModalProps {
  isVisible: boolean;
  showCloseButton: boolean;
  onClose: () => void;
  onSend: () => void;
}

export default class ExamTakingConfirmModal extends React.Component<ExamTakingConfirmModalProps> {
  shouldComponentUpdate(nextProps: ExamTakingConfirmModalProps): boolean {
    const { isVisible, showCloseButton } = this.props;
    return (
      nextProps.isVisible !== isVisible ||
      nextProps.showCloseButton !== showCloseButton
    );
  }

  render(): JSX.Element {
    const { isVisible, showCloseButton, onClose, onSend } = this.props;

    return (
      <CustomModal
        heightModal="50%"
        widthModal="50%"
        borderRadiusModal={5}
        showCloseButton={showCloseButton}
        visible={isVisible}
        onCloseModal={() => onClose()}
      >
        <ExamTakingConfirmModalItem
          showCloseButton={showCloseButton}
          onClose={() => onClose()}
          onSend={() => onSend()}
        />
      </CustomModal>
    );
  }
}
