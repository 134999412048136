import React from "react";
import { StyleSheet, Text } from "react-native";
import { COLOR_BLUE_TESTWE, COLOR_RED } from "../../../static/misc/colors";
import {
  FONTSIZE_12,
  FONT_GILROY_BOLD,
  FONT_GILROY_REGULAR
} from "../../../static/misc/constants";

interface ExplainTextProps {
  text: string;
  justifiedText: string;
}

class ExplainText extends React.PureComponent<ExplainTextProps> {
  render(): JSX.Element {
    const { text, justifiedText } = this.props;
    return (
      <Text style={{ textAlign: "center" }}>
        <Text style={styles.text}>{text}</Text>
        <Text
          style={[
            styles.text,
            { fontFamily: FONT_GILROY_BOLD, color: COLOR_RED }
          ]}
        >
          {justifiedText}
        </Text>
      </Text>
    );
  }
}

const styles = StyleSheet.create({
  text: {
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_12,
    color: COLOR_BLUE_TESTWE
  }
});

export default ExplainText;
