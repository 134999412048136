/* eslint-disable no-console */
const ipcRenderer = {
  send(channel, ...args) {
    console.log(`[${channel}]`, args);
  },
  on(channel, ...args) {
    console.log("ON event", channel, args);
  },
  invoke(channel, ...args) {
    console.log("INVOKE event", channel, args);
    if (channel === "START_EXAM") {
      return new Promise((resolve) => {
        resolve(JSON.stringify({ type: "START_EXAM_OK", args: [] }));
      });
    }
    if (channel === "GET_APP_DATA") {
      return new Promise((resolve) => {
        resolve(
          JSON.stringify({
            appDataPath: "",
            logsPath: "",
            appVersion: 0,
            osPlatform: "web",
            osRelease: "web"
          })
        );
      });
    }
    return new Promise((resolve) => {
      resolve();
    });
  }
};

module.exports = {
  ipcRenderer
};
