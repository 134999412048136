import React from "react";
import { StyleProp, StyleSheet, Text, View, ViewStyle } from "react-native";
import * as Progress from "react-native-progress";
import {
  COLOR_BLUE_TESTWE,
  COLOR_GREY_BORDER
} from "../../../static/misc/colors";
import { FONTSIZE_16, FONT_GILROY_BOLD } from "../../../static/misc/constants";

interface FileTransferProgressBarProps {
  completion: number;
  label: string;
  style?: StyleProp<ViewStyle>;
}

class FileTransferProgressBar extends React.PureComponent<FileTransferProgressBarProps> {
  render(): JSX.Element {
    const { completion, label, style } = this.props;
    return (
      <View style={[styles.container, style]}>
        <Text style={[styles.defaultBoldTxt]}>{label}</Text>
        <Progress.Bar
          progress={completion}
          unfilledColor={COLOR_GREY_BORDER}
          color={COLOR_BLUE_TESTWE}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  defaultBoldTxt: {
    fontFamily: FONT_GILROY_BOLD,
    fontSize: FONTSIZE_16,
    color: COLOR_BLUE_TESTWE
  },
  container: {
    flex: 1,
    justifyContent: "center"
  }
});

export default FileTransferProgressBar;
