import {
  BannerItemsStatus,
  SetBannerItemsStatusAction,
  SET_BANNER_ITEMS_STATUS
} from "../types/persistedStatus";

// eslint-disable-next-line import/prefer-default-export
export function setBannerItemsStatus(
  payload: BannerItemsStatus
): SetBannerItemsStatusAction {
  return {
    type: SET_BANNER_ITEMS_STATUS,
    payload
  };
}
