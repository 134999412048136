import React from "react";
import { StyleSheet, View } from "react-native";
import { guidGenerator } from "../../../static/misc/utils";
import { ExamChoice } from "../../modules/exams/types/exam";
import QcuItem from "../molecules/QcuItem";

interface QcuState {
  selectedOption: number;
}

export interface QcuProps {
  itemSelected: (selection: string) => void;
  previousAnswer?: Array<string> | undefined;
  items: Array<ExamChoice>;
}

class Qcu extends React.PureComponent<QcuProps, QcuState> {
  constructor(props: QcuProps) {
    super(props);

    const { items, previousAnswer } = this.props;
    const indexGivenAnswer = Array.isArray(previousAnswer)
      ? items.findIndex((item) => item.id === previousAnswer[0])
      : -1;

    this.state = {
      selectedOption: indexGivenAnswer
    };
  }

  render(): JSX.Element {
    const { items, itemSelected } = this.props;
    const { selectedOption } = this.state;
    return (
      <View>
        {items.map((item, index) => {
          return (
            <View style={styles.itemSeparator} key={guidGenerator()}>
              <QcuItem
                onSelect={() => {
                  if (selectedOption === index) {
                    this.setState({ selectedOption: -1 });
                    itemSelected("");
                  } else {
                    this.setState({ selectedOption: index });
                    itemSelected(item.id as string);
                  }
                }}
                answer={item.name}
                isSelected={selectedOption === index}
              />
            </View>
          );
        })}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  itemSeparator: {
    paddingVertical: 5
  }
});

export default Qcu;
