import React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { COLOR_BLUE_TESTWE, COLOR_WHITE } from "../../../static/misc/colors";
import { PADDING_SIDES } from "../../../static/misc/constants";
import { downArrow, establishmentIcon } from "../../../static/misc/images";
import { EstablishmentsType } from "../../modules/main/types/user";
import i18n from "../../services/i18n";
import Icon from "../atoms/Icon";
import SelectorField from "./SelectorField";

interface EstablishmentSelectorProps {
  currentEstablishment?: EstablishmentsType;
  establishments: EstablishmentsType[];
  changeEstablishmentChoice: (establishmentName: string) => void;
}

interface EstablishmentSelectorState {
  isOpened: boolean;
}

export default class EstablishmentSelector extends React.PureComponent<
  EstablishmentSelectorProps,
  EstablishmentSelectorState
> {
  constructor(props: EstablishmentSelectorProps) {
    super(props);
    this.state = {
      isOpened: false
    };
  }

  render(): JSX.Element {
    const {
      currentEstablishment,
      establishments,
      changeEstablishmentChoice
    } = this.props;
    const { isOpened } = this.state;

    const establishmentsNames = establishments.map(
      (establishment: EstablishmentsType) => {
        return establishment.name;
      }
    );

    return establishments && establishments.length > 1 ? (
      <View style={styles.establishmentPicker}>
        <Icon
          iconStyle={styles.establishmentPickerIcon}
          icon={establishmentIcon}
        />
        <SelectorField
          choiceList={establishmentsNames}
          defaultIndex={
            currentEstablishment?.name
              ? establishmentsNames.indexOf(currentEstablishment?.name)
              : undefined
          }
          changeChoice={(index: number) =>
            changeEstablishmentChoice(establishmentsNames[index])
          }
          placeHolder={i18n.t("profile.establishmentPlaceholder")}
          inputStyle={styles.establishmentPickerInput as any}
          textStyle={styles.establishmentPickerText}
          selectorStyle={styles.establishmentPickerSelector}
          containerStyle={styles.establishmentPickerContainer}
          isOpened={isOpened}
          onToggle={(opened: boolean) =>
            this.setState({
              isOpened: opened
            })
          }
        />
        <TouchableOpacity
          onPress={() =>
            this.setState({
              isOpened: !isOpened
            })
          }
        >
          <Icon iconStyle={styles.downArrowIcon} icon={downArrow} />
        </TouchableOpacity>
      </View>
    ) : (
      <></>
    );
  }
}

const styles = StyleSheet.create({
  establishmentPicker: {
    backgroundColor: COLOR_BLUE_TESTWE,
    borderRadius: 8,
    flexDirection: "row",
    paddingHorizontal: PADDING_SIDES * 0.2
  },
  establishmentPickerIcon: {
    paddingEnd: PADDING_SIDES * 0.2
    // height: "100%"
  },
  downArrowIcon: {
    paddingEnd: PADDING_SIDES * 0.2,
    height: "100%"
  },
  establishmentPickerContainer: {
    height: "auto"
  },
  establishmentPickerInput: {
    color: COLOR_WHITE
  },
  establishmentPickerText: {
    color: COLOR_WHITE
  },
  establishmentPickerSelector: {
    borderColor: COLOR_BLUE_TESTWE,
    backgroundColor: COLOR_BLUE_TESTWE
  }
});
