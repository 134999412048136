import React from "react";
import { StyleProp, View, ViewStyle, Text, TextStyle } from "react-native";

interface ExamItemProps {
  viewStyle: StyleProp<ViewStyle>;
  textStyle: StyleProp<TextStyle>;
  text?: string;
  textSetNbOfLines: number;
}

class ListColumnItem extends React.PureComponent<ExamItemProps> {
  render(): JSX.Element {
    const { text, viewStyle, textStyle, textSetNbOfLines } = this.props;

    return (
      <View style={viewStyle}>
        {textSetNbOfLines > 0 && text && (
          <Text
            numberOfLines={textSetNbOfLines}
            ellipsizeMode="tail"
            style={textStyle}
          >
            {text}
          </Text>
        )}
        {textSetNbOfLines < 0 && <Text style={textStyle}>{text}</Text>}
      </View>
    );
  }
}

export default ListColumnItem;
