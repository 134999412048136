import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { COLOR_BLUE_TESTWE, COLOR_RED } from "../../../static/misc/colors";
import {
  FONTSIZE_12,
  FONTSIZE_14,
  FONT_GILROY_REGULAR,
  PADDING_SIDES
} from "../../../static/misc/constants";
import { five, four, one, three, two } from "../../../static/misc/images";
import i18n from "../../services/i18n";
import CustomModal from "../atoms/CustomModal";
import Icon from "../atoms/Icon";

interface PdfViewerExplanationModalProps {
  isVisible: boolean;
  onClose: () => void;
}

export default class PdfViewerExplanationModal extends React.PureComponent<PdfViewerExplanationModalProps> {
  render(): JSX.Element {
    const { isVisible, onClose } = this.props;
    return (
      <CustomModal
        heightModal="33%"
        widthModal="33%"
        borderRadiusModal={5}
        showCloseButton
        backdropOpacity={0}
        visible={isVisible}
        onCloseModal={() => onClose()}
      >
        <View style={styles.container}>
          <View style={styles.item}>
            <Icon icon={one} iconStyle={styles.icon} />
            <Text style={styles.explanationText}>
              {i18n.translate("media.explanation1")}
            </Text>
          </View>
          <View style={styles.item}>
            <Icon icon={two} iconStyle={styles.icon} />
            <Text style={styles.explanationText}>
              {i18n.translate("media.explanation2")}
            </Text>
          </View>
          <View style={styles.item}>
            <Icon icon={three} iconStyle={styles.icon} />
            <Text style={styles.explanationText}>
              {i18n.translate("media.explanation3")}
            </Text>
          </View>
          <View style={styles.item}>
            <Icon icon={four} iconStyle={styles.icon} />
            <Text style={styles.explanationText}>
              {i18n.translate("media.explanation4")}
            </Text>
          </View>
          <View style={styles.item}>
            <Icon icon={five} iconStyle={styles.icon} />
            <Text style={styles.explanationText}>
              {i18n.translate("media.explanation5")}
            </Text>
          </View>
          <View>
            <Text style={styles.explanationRemark}>
              {i18n.translate("media.explanation6")}
            </Text>
          </View>
        </View>
      </CustomModal>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    alignContent: "center",
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    paddingHorizontal: PADDING_SIDES * 0.2
  },
  item: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: PADDING_SIDES * 0.2
  },
  icon: {
    width: 25,
    height: 25,
    marginEnd: PADDING_SIDES * 0.2
  },
  explanationText: {
    color: COLOR_BLUE_TESTWE,
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_14
  },
  explanationRemark: {
    color: COLOR_RED,
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_12
  }
});
