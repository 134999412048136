/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Animated, PanResponder, View, StyleSheet } from "react-native";
import { COLOR_TRANSPARENT } from "../../../static/misc/colors";
import { CalculatorTypeEnum } from "../../modules/examTaking/types/calculator";
import Calculator from "./Calculator";

interface CalculatorViewProps {
  calcType: CalculatorTypeEnum;
}
class CalculatorView extends React.PureComponent<CalculatorViewProps> {
  pan = new Animated.ValueXY();

  // Creating the pan responder to allow moving the calculator on all screen
  panResponder = PanResponder.create({
    onMoveShouldSetPanResponder: () => true,
    onPanResponderMove: Animated.event([
      null,
      { dx: this.pan.x, dy: this.pan.y }
    ]),
    onPanResponderRelease: () => {
      this.pan.extractOffset();
    }
  });

  render(): JSX.Element {
    const { calcType } = this.props;

    return (
      <View style={styles.viewContainer}>
        <Animated.View
          style={{
            transform: [{ translateX: this.pan.x }, { translateY: this.pan.y }]
          }}
          {...this.panResponder.panHandlers}
        >
          <Calculator type={calcType} />
        </Animated.View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  viewContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: 2,
    height: 2,
    backgroundColor: COLOR_TRANSPARENT,
    zIndex: 999
  }
});

export default CalculatorView;
