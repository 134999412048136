import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { COLOR_RED } from "../../../static/misc/colors";
import {
  DEFAULT_EQUIPMENT_CHECK,
  DEV,
  ENV,
  FONTSIZE_14,
  FONT_GILROY_REGULAR,
  PADDING_SIDES
} from "../../../static/misc/constants";
import { EquipmentCheck } from "../../modules/main/types/equipmentCheck";
import i18n from "../../services/i18n";
import getSpeedRanking from "../../services/web/network";
import { RootState } from "../../store/rootreducer";
import ConnectionTesting from "./ConnectionTesting";

interface OnBoardingConnectionCheckProps {
  updateCurrentStepInfo: (
    data?: string | Blob | null,
    status?: boolean
  ) => void;
  onEquipmentValidated: (
    networkUpload: number,
    networkDownload: number
  ) => void;
}

interface OnBoardingConnectionCheckState {
  equipmentCheck: EquipmentCheck;
  error: string | null;
}

class OnBoardingConnectionCheckView extends React.PureComponent<
  OnBoardingConnectionCheckProps,
  OnBoardingConnectionCheckState
> {
  constructor(props: OnBoardingConnectionCheckProps) {
    super(props);

    this.state = {
      equipmentCheck: DEFAULT_EQUIPMENT_CHECK,
      error: null
    };
  }

  onTestCompleted(download: number, upload: number): void {
    const { updateCurrentStepInfo, onEquipmentValidated } = this.props;
    const { equipmentCheck } = this.state;
    const newEquipmentCheck = { ...equipmentCheck };

    if (download > -1 && upload > -1) {
      let error = null;

      newEquipmentCheck.networkDownload = download;
      newEquipmentCheck.networkUpload = upload;

      this.setState({
        equipmentCheck: { ...newEquipmentCheck }
      });

      onEquipmentValidated(
        Math.trunc(newEquipmentCheck.networkUpload),
        Math.trunc(newEquipmentCheck.networkDownload)
      );

      if (ENV === DEV) {
        updateCurrentStepInfo("", true);
      } else if (
        getSpeedRanking("download", download) >= 2 &&
        getSpeedRanking("upload", upload) >= 2
      ) {
        updateCurrentStepInfo("", true);
      } else {
        error = i18n.t("errors.badNetwork");
      }
      this.setState({
        error
      });
    }
  }

  render(): JSX.Element {
    const { error } = this.state;
    return (
      <View style={styles.container}>
        <ConnectionTesting
          onTestCompleted={(download: number, upload: number) =>
            this.onTestCompleted(download, upload)
          }
        />
        {error && <Text style={styles.errorText}>{error}</Text>}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexShrink: 1,
    flexGrow: 1,
    alignItems: "center"
  },
  errorText: {
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_14,
    color: COLOR_RED,
    marginVertical: PADDING_SIDES * 0.4,
    lineHeight: 20
  }
});

const mapStateToProps = (state: RootState) => {
  return {
    // eslint-disable-next-line camelcase
    authorizationToken: state.auth.credentials?.access_token
  };
};

const mapdispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators({}, dispatch)
  };
};

const OnBoardingConnectionCheck = connect(
  mapStateToProps,
  mapdispatchToProps
)(OnBoardingConnectionCheckView);

export default OnBoardingConnectionCheck;
