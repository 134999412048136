import React from "react";
import {
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle
} from "react-native";
import { COLOR_WHITE } from "../../../static/misc/colors";
import { FONTSIZE_12, FONT_GILROY_BOLD } from "../../../static/misc/constants";

interface TimeTagProps {
  containerStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  time: string;
}

class TimeTag extends React.PureComponent<TimeTagProps> {
  render(): JSX.Element {
    const { containerStyle, textStyle, time } = this.props;
    return (
      <View style={[styles.defaultcontainerStyle, containerStyle]}>
        <View style={styles.seperator} />
        <Text style={[styles.defaultTextStyle, textStyle]}>{time}</Text>
        <View style={styles.seperator} />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  defaultcontainerStyle: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  seperator: {
    flex: 1,
    height: 1,
    backgroundColor: COLOR_WHITE
  },
  defaultTextStyle: {
    paddingHorizontal: 3,
    fontFamily: FONT_GILROY_BOLD,
    fontSize: FONTSIZE_12,
    color: COLOR_WHITE
  }
});

export default TimeTag;
