import React from "react";
import { Image, StyleSheet, Text, View } from "react-native";
import { COLOR_BLUE_TESTWE } from "../../../static/misc/colors";
import {
  FONTSIZE_14,
  FONT_GILROY_REGULAR,
  PADDING_SIDES
} from "../../../static/misc/constants";
import {
  OnboardingSubStepperOption,
  ONBOARDING_ORDERED_STEPS
} from "../../../static/misc/onboarding/onboardingsteps";
import i18n from "../../services/i18n";

interface OnboardingItemSubTitleProps {
  activeStepType: string;
  videoIndex: number;
}

export default class OnboardingItemSubTitle extends React.PureComponent<OnboardingItemSubTitleProps> {
  render(): JSX.Element {
    const { activeStepType, videoIndex } = this.props;
    const activeStep = ONBOARDING_ORDERED_STEPS.find(
      (step: OnboardingSubStepperOption) =>
        step.type === activeStepType && step.videoIndex === videoIndex
    );
    return (
      <View style={styles.container}>
        {activeStep?.icon ? (
          <Image source={activeStep?.icon} style={styles.icon} />
        ) : null}
        {activeStep?.subtitle ? (
          <Text style={styles.stepSubtitle}>{i18n.t(activeStep.subtitle)}</Text>
        ) : null}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    paddingVertical: PADDING_SIDES * 0.2
  },
  icon: {
    width: 16,
    height: 16,
    resizeMode: "contain",
    marginRight: PADDING_SIDES * 0.1
  },
  stepSubtitle: {
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_14,
    color: COLOR_BLUE_TESTWE,
    paddingBottom: PADDING_SIDES * 0.2,
    textAlign: "center"
  }
});
