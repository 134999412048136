export const WS_PAUSE_EXAM = "WS_PAUSE_EXAM";

export const WS_UNPAUSE_EXAM = "WS_UNPAUSE_EXAM";

export const WS_STOP_EXAM = "WS_STOP_EXAM";

export const WS_ADD_TIME_EXAM = "WS_ADD_TIME_EXAM";

export const WS_ENABLE_PROCTOR_AUDIO = "WS_ENABLE_PROCTOR_AUDIO";

export const WS_DISABLE_PROCTOR_AUDIO = "WS_DISABLE_PROCTOR_AUDIO";

export const WS_GET_ROOM_SUCCESS = "WS_GET_ROOM_SUCCESS";

export const WS_GET_ROOM_FAILED = "WS_GET_ROOM_FAILED";

export const WS_GET_ROOM_AGAIN_SUCCESS = "WS_GET_ROOM_AGAIN_SUCCESS";

export const WS_GET_ROOM_AGAIN_FAILED = "WS_GET_ROOM_AGAIN_FAILED";

export const WS_EVENTS_LISTEN_ARRAY = [
  WS_PAUSE_EXAM,
  WS_ADD_TIME_EXAM,
  WS_UNPAUSE_EXAM,
  WS_DISABLE_PROCTOR_AUDIO,
  WS_ENABLE_PROCTOR_AUDIO,
  WS_STOP_EXAM,
  WS_GET_ROOM_SUCCESS,
  WS_GET_ROOM_FAILED,
  WS_GET_ROOM_AGAIN_SUCCESS,
  WS_GET_ROOM_AGAIN_FAILED
];
