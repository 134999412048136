/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */
import React from "react";
import { Provider } from "react-redux";
import { ipcRenderer } from "electron";
import { AppLoading } from "expo";
import * as Font from "expo-font";
import * as Sentry from "sentry-expo";
import { Integrations } from "@sentry/tracing";
import qs from "query-string";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { CombinedState } from "redux";
import { Beforeunload } from "react-beforeunload";
import AppNavigator from "./src/router/AppNavigator";
import i18n from "./src/services/i18n";
import {
  getAsyncStorageItem,
  getAuthCodeFromUrl,
  setAsyncStorageItem
} from "./static/misc/utils";
import {
  CUSTOM_FONTS,
  ENV,
  DEV,
  PREVIEW_CLIENT_ID,
  EACH_SECONDS,
  TIMEZONE,
  IS_PREVIEW,
  IS_WEB_ENABLE
} from "./static/misc/constants";
import { CredentialsType } from "./src/modules/authentification/types/auth";
import { isOnline } from "./src/modules/main/actions/status";
import ConfirmUserModal from "./src/atomic/organisms/ConfirmUserModal";
import moment from "./src/services/moment";

import {
  KEYCLOAK_API,
  SENTRY_DSN_APP,
  SENTRY_DSN_WEB,
  TOKEN
} from "./static/misc/network";
import { clearCredentials } from "./src/modules/authentification/actions/auth";
import createTwStore from "./src/store/createStore";
import { setAppData, setLocale } from "./src/modules/main/actions/user";
import { clearExamTaking } from "./src/modules/examTaking/actions/examTaking";
import KeycloakWrapper from "./src/modules/web/KeycloakWrapper";

interface State {
  appIsReady: boolean;
  showModalIdentity: boolean;
  credentials: CredentialsType | undefined;
}

class TestWe extends React.Component<unknown, State> {
  onlineInterval!: ReturnType<typeof setInterval>;

  reduxStore: CombinedState<any>;

  constructor(props: unknown) {
    super(props);
    this.state = {
      showModalIdentity: false,
      credentials: undefined,
      appIsReady: false
    };
  }

  async componentDidMount(): Promise<void> {
    if (!IS_PREVIEW)
      Sentry.init({
        dsn: IS_WEB_ENABLE ? SENTRY_DSN_WEB : SENTRY_DSN_APP,
        environment: ENV,
        autoSessionTracking: true,
        integrations: [new Integrations.BrowserTracing() as any],
        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
        ignoreErrors: [
          /wasm:\/\/wasm\/[0-9]*e/,
          // error is in a sublib of project so wre can't fix it
          "Cannot read property 'set' of null"
        ],
        enableInExpoDevelopment: true,
        debug: ENV === DEV // Sentry will try to print out useful debugging information if something goes wrong with sending an event. Set this to `false` in production.
      });

    ipcRenderer.send("LOG_INFO", "--- Renderer Application is mounted ---");
    ipcRenderer.send(
      "LOG_INFO",
      "Running platform :",
      IS_PREVIEW
        ? "EXAM_PREV"
        : IS_WEB_ENABLE
        ? "WEB_STUDENT"
        : "DESKTOP_STUDENT"
    );

    // Store redhydratation with local storage (cache)
    this.reduxStore = await createTwStore();
    ipcRenderer.send(
      "LOG_INFO",
      "Store was created",
      this.reduxStore !== undefined
    );

    // handle credentials
    await this.performAPICalls();

    // Retrieving info paths
    const appData = await ipcRenderer.invoke("GET_APP_DATA");
    this.reduxStore.dispatch(
      setAppData(
        appData.appDataPath,
        appData.logsPath,
        appData.appVersion,
        appData.osPlatform,
        appData.osRelease
      )
    );
    // Check if the user is online
    this.onlineChecker();

    // Preparing every ressources for the app
    await this.prepareResources();
    ipcRenderer.send("LOG_INFO", "Resources loaded, the application will load");
    // Show the app
    this.setState({ appIsReady: true });
  }

  componentWillUnmount(): void {
    clearInterval(this.onlineInterval);
  }

  // Remplacing NETINFO because not triggering on the handler https://developer.mozilla.org/fr/docs/Web/API/Navigator/connection
  onlineChecker(): void {
    // Check if navigator is online and dispatch isOnline redux action
    if (navigator.onLine) this.reduxStore.dispatch(isOnline(true));
    else {
      this.reduxStore.dispatch(isOnline(false));
      this.confirmUserIdentity();
    }

    this.onlineInterval = setInterval(async () => {
      if (this.reduxStore.getState().status.isOnline !== navigator.onLine)
        this.reduxStore.dispatch(isOnline(navigator.onLine));
    }, EACH_SECONDS);
  }

  onBeforeUnload(event: any): void {
    if (IS_WEB_ENABLE) {
      event.preventDefault();
      // eslint-disable-next-line no-param-reassign
      event.returnValue = "";
    }
  }

  setAppLanguage = async (): Promise<void> => {
    let lang: string;
    // If exam preview, we want to use the lang used in BO which should be passed as arg
    const urlArgs = qs.parse(window.location.search.substring(1));
    if (
      IS_PREVIEW &&
      urlArgs &&
      urlArgs.state &&
      typeof urlArgs.state === "string"
    ) {
      const args = urlArgs.state.split("_");
      if (args.length > 1) {
        lang = args[1];
      } else {
        lang = i18n.locale;
      }
    } else if (
      process.env.FORCE_LANGUAGE &&
      process.env.FORCE_LANGUAGE !== ""
    ) {
      // Get in localstorage the lang saved from the user
      lang = process.env.FORCE_LANGUAGE;
    } else {
      // Get in store the lang saved for the user, otherwise use default i18n.locale
      lang = this.reduxStore.getState().user.locale || i18n.locale;
    }
    // Set i18n (translations) with this one
    i18n.locale = lang;

    // Set moment(date) with the locale
    moment.locale(lang);

    // dispatch lang to be sure to save it in store if it was not already the case
    this.reduxStore.dispatch(setLocale(lang));
  };

  setAppTimezone = async (): Promise<void> => {
    // Get in localstorage the timezone saved
    const timezone = moment.tz.guess();
    const storageTimezone = await getAsyncStorageItem(TIMEZONE);

    if (timezone !== storageTimezone) {
      await setAsyncStorageItem(TIMEZONE, timezone);
      ipcRenderer.send(
        "LOG_INFO",
        `TIMEZONE | Used timezone was different from previous one (${storageTimezone})`
      );
    }

    ipcRenderer.send(
      "LOG_INFO",
      `TIMEZONE | Set moment to use ${timezone} as a timezone.`
    );
    moment.tz.setDefault(timezone);
  };

  async getCredentials(): Promise<any> {
    return new Promise((resolve) => {
      ipcRenderer.on("GET_CREDENTIALS_RESPOND", (event, arg) => {
        ipcRenderer.send("LOG_INFO", "Keycloak creds received in renderer");
        resolve(arg);
      });
      ipcRenderer.send("LOG_INFO", "Getting Keycloak credentials in renderer");
      ipcRenderer.send("GET_CREDENTIALS");
    });
  }

  async performAPICalls(): Promise<void> {
    if (!IS_PREVIEW && !IS_WEB_ENABLE) {
      // If we are not on previsualisation app (web-app for teachers)
      const credentials = await this.getCredentials();

      if (credentials === undefined) {
        this.setState({
          credentials: this.reduxStore.getState().auth?.credentials
        });
      } else if (
        this.reduxStore.getState().auth.credentials?.preferred_username &&
        this.reduxStore.getState().auth.credentials?.preferred_username !==
          credentials.preferred_username
      ) {
        ipcRenderer.send(
          "LOG_INFO",
          `No credentials, will logout from renderer`
        );
        this.reduxStore.dispatch(clearCredentials());
      }
      this.setState({ credentials });
    } else if (IS_PREVIEW) {
      // if we are on preview app
      const tokenUrl = `${KEYCLOAK_API}${TOKEN}`;
      const requestBody = {
        grant_type: "authorization_code",
        code: getAuthCodeFromUrl(window.location.search.substring(1)),
        redirect_uri: window.location.origin,
        client_id: PREVIEW_CLIENT_ID
      };
      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      };

      const res = await axios.post(tokenUrl, qs.stringify(requestBody), config);
      if (res.status === 200) {
        this.setState({
          credentials: {
            ...res.data,
            ...jwtDecode(res.data.access_token)
          }
        });
      } else {
        this.setState({ credentials: undefined });
      }
    }
  }

  // Function which will show the modal identity in order to make the user confirm his identity (offline case)
  confirmUserIdentity(): void {
    const { appIsReady } = this.state;
    if (
      this.reduxStore.getState().auth.credentials?.preferred_username &&
      !appIsReady
    )
      this.setState({ showModalIdentity: true });
  }

  verifyExamTakingType(): void {
    // If exams of examtaking is an object, clear examtaking
    if (
      this.reduxStore.getState().examTaking.exams &&
      this.reduxStore.getState().examTaking.exams !== undefined &&
      !(this.reduxStore.getState().examTaking.exams instanceof Array)
    ) {
      this.reduxStore.dispatch(clearExamTaking(undefined));
    }
  }

  async prepareResources(): Promise<void> {
    this.verifyExamTakingType();
    ipcRenderer.send(
      "LOG_INFO",
      "Preparing renderer resources to load the application..."
    );
    // app language must be done before creating store
    await this.setAppLanguage();
    ipcRenderer.send("LOG_INFO", `User language set up to ${i18n.locale}`);

    await this.setAppTimezone();
    try {
      await Font.loadAsync(CUSTOM_FONTS);
    } catch (e) {
      /*
       * Catches error incase of a timeout
       * Promise doesn't always resolve intime
       * https://github.com/expo/expo/issues/4217
       */
    }
  }

  appRendering(): JSX.Element {
    const { appIsReady, credentials, showModalIdentity } = this.state;
    if (appIsReady)
      return (
        <Provider store={this.reduxStore}>
          <ConfirmUserModal
            isVisible={showModalIdentity}
            onValidation={() => this.setState({ showModalIdentity: false })}
            username={
              this.reduxStore.getState().auth.credentials?.preferred_username
            }
          />
          <AppNavigator credentials={credentials} />
        </Provider>
      );
    return <AppLoading />;
  }

  render(): JSX.Element | null {
    if (IS_WEB_ENABLE) {
      return (
        <Beforeunload onBeforeunload={this.onBeforeUnload}>
          <KeycloakWrapper
            onSetCredentials={(cred: CredentialsType | undefined) =>
              this.setState({ credentials: cred })
            }
          >
            {this.appRendering()}
          </KeycloakWrapper>
        </Beforeunload>
      );
    }
    return <>{this.appRendering()}</>;
  }
}

export default TestWe;
