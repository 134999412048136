export const GETSUCCESS = "GETSUCCESS";
export const RESET_SUCCESS = "RESET_SUCCESS";

export interface ResetSuccessAction {
  type: typeof RESET_SUCCESS;
  payload: any;
}

export interface GetSuccessAction {
  type: typeof GETSUCCESS;
  payload: any;
}

export interface SuccessState {
  successMessage: string | null;
  success: boolean;
}

export type SuccessActionTypes = ResetSuccessAction | GetSuccessAction | any;
