import React from "react";
import { Text, View } from "react-native";
import { connect } from "react-redux";
import { ONBOARDING_DEFAULT } from "../../../static/misc/onboarding/onboardingsteps";
import { ExamOnboarding } from "../../modules/exams/types/exam";
import i18n from "../../services/i18n";
import OnboardingMicroStep from "./OnboardingMicroStep";

export interface MicrophoneTestingViewProps {
  hasPermission: boolean;
  onEquipmentValidated: (microphone: boolean, sound: boolean) => void;
}

export class MicrophoneTestingView extends React.PureComponent<MicrophoneTestingViewProps> {
  render(): JSX.Element {
    const { hasPermission, onEquipmentValidated } = this.props;
    return (
      <View>
        {hasPermission ? (
          <OnboardingMicroStep
            currentOnboardingStep={
              (ONBOARDING_DEFAULT as ExamOnboarding).steps[0]
            }
            updateCurrentStepInfo={() => undefined}
            onEquipmentValidated={onEquipmentValidated}
          />
        ) : (
          <View>
            <Text>
              {i18n.t("testing.microphoneTesting.microphoneNotAvailable")}
            </Text>
          </View>
        )}
      </View>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapdispatchToProps = () => {
  return {};
};

const MicrophoneTesting = connect(
  mapStateToProps,
  mapdispatchToProps
)(MicrophoneTestingView);

export default MicrophoneTesting;
