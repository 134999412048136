import React from "react";

import {
  View,
  StyleSheet,
  Text,
  TouchableOpacity,
  LayoutChangeEvent
} from "react-native";

import {
  COLOR_BLUE_TESTWE,
  COLOR_BLUE_TESTWE_30,
  COLOR_GREEN_VALID,
  COLOR_GREY_EXAM_NAVBAR_COLOR_CODE,
  COLOR_WHITE,
  COLOR_YELLOW_INFO
} from "../../../static/misc/colors";
import {
  FONT_GILROY_BOLD,
  FONT_GILROY_REGULAR
} from "../../../static/misc/constants";
import i18n from "../../services/i18n";

import Divider from "../atoms/Divider";
import Tooltip from "../atoms/Tooltip";
import { ChangeEnum } from "../organisms/ExamTakingContent";

interface ExamPartCarouselItemProps {
  currentPosition: number;
  isLastExamPart: boolean;
  hasCompleted: boolean;
  hasPartiallyCompleted: boolean;
  hasVisited: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  hasBookmarked: boolean;
  boomarkedQuestionsCount: number;
  notAnsweredQuestionsCount: number;
  isCurrent: boolean;
  handleRef: (node: View) => void;
  onLayout?: (event: LayoutChangeEvent) => void;
  onPress: () => void;
  hasChronoInQuestion: boolean;
  hasChronoInExercise: boolean;
  hasChronoInExamPart: boolean;
  toggleRemainingDurationWarningModal(
    visible: boolean,
    changeType?: ChangeEnum,
    callback?: (changeType: ChangeEnum) => void
  ): void;
  isLastQuestionInExamPart: boolean;
  durationFixed?: boolean;
}

class ExamPartCarouselItem extends React.PureComponent<ExamPartCarouselItemProps> {
  getCurrentColor = (): string => {
    const {
      hasCompleted,
      hasPartiallyCompleted,
      isCurrent,
      boomarkedQuestionsCount,
      hasVisited
    } = this.props;

    // User is actually in this exam part
    if (isCurrent) return COLOR_BLUE_TESTWE;

    // User has bookmarked the part but is not current part
    if (boomarkedQuestionsCount > 0) return COLOR_YELLOW_INFO;

    // User has completed the part but is not current part
    if (hasCompleted) return COLOR_GREEN_VALID;

    // User has partially completed the part but is not current part
    if (hasPartiallyCompleted || hasVisited) return COLOR_BLUE_TESTWE_30;

    // default
    return COLOR_GREY_EXAM_NAVBAR_COLOR_CODE;
  };

  buildTooltipContent = (): JSX.Element | undefined => {
    const {
      hasPartiallyCompleted,
      boomarkedQuestionsCount,
      notAnsweredQuestionsCount,
      hasVisited
    } = this.props;

    if (boomarkedQuestionsCount > 0) {
      return (
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start"
          }}
        >
          <Text
            style={{
              color: COLOR_BLUE_TESTWE,
              fontFamily: FONT_GILROY_REGULAR
            }}
          >
            {i18n.t("examNavbar.ExamPartCarouselItem.tooltip.bookmarked", {
              boomarkedQuestionsCount
            })}
          </Text>
          <Text
            style={{
              color: COLOR_BLUE_TESTWE,
              fontFamily: FONT_GILROY_REGULAR
            }}
          >
            {notAnsweredQuestionsCount > 0 &&
              i18n.t(
                "examNavbar.ExamPartCarouselItem.tooltip.partiallyCompleted",
                {
                  notAnsweredQuestionsCount
                }
              )}
          </Text>
        </View>
      );
    }

    if (
      (hasPartiallyCompleted || hasVisited) &&
      notAnsweredQuestionsCount > 0
    ) {
      return (
        <Text
          style={{
            color: COLOR_BLUE_TESTWE,
            fontFamily: FONT_GILROY_REGULAR
          }}
        >
          {i18n.t(
            "examNavbar.ExamPartCarouselItem.tooltip.partiallyCompleted",
            {
              notAnsweredQuestionsCount
            }
          )}
        </Text>
      );
    }

    return undefined;
  };

  shouldDisableTooltip = (): boolean => {
    const {
      hasPartiallyCompleted,
      boomarkedQuestionsCount,
      notAnsweredQuestionsCount,
      isCurrent,
      hasVisited
    } = this.props;

    return (
      (!(
        (hasPartiallyCompleted || hasVisited) &&
        notAnsweredQuestionsCount > 0
      ) &&
        !(boomarkedQuestionsCount > 0)) ||
      isCurrent
    );
  };

  onPressIntermediate = (): void => {
    const {
      toggleRemainingDurationWarningModal,
      onPress,
      isLastQuestionInExamPart,
      hasChronoInQuestion,
      hasChronoInExercise,
      hasChronoInExamPart,
      durationFixed
    } = this.props;

    // If it's last question in exam part and there is chrono in the question, exercise or exam part, we show the warning modal
    if (
      isLastQuestionInExamPart &&
      (hasChronoInExamPart || hasChronoInQuestion || hasChronoInExercise)
    ) {
      // Should not allow user to click when duration is fixed no matter what
      if (durationFixed) return;

      toggleRemainingDurationWarningModal(true, ChangeEnum.NEXTPART, () => {
        onPress();
      });
    }

    if (!(hasChronoInExamPart || hasChronoInQuestion || hasChronoInExercise)) {
      onPress();
    }
  };

  render(): JSX.Element {
    const {
      isLastExamPart,
      currentPosition,
      handleRef,
      onLayout,
      isCurrent
    } = this.props;

    return (
      <View
        style={StyleSheet.flatten([
          styles.item,
          {
            borderBottom: isCurrent
              ? `2px solid ${COLOR_BLUE_TESTWE}`
              : `1px solid ${COLOR_GREY_EXAM_NAVBAR_COLOR_CODE}`
          }
        ])}
        ref={(node) => node && handleRef(node)}
        onLayout={onLayout}
      >
        <Tooltip
          content={this.buildTooltipContent()}
          disabled={this.shouldDisableTooltip()}
          tooltipViewStyle={{ background: COLOR_WHITE }}
        >
          <TouchableOpacity
            onPress={this.onPressIntermediate}
            style={{
              marginHorizontal: 8,
              paddingRight: 0
            }}
          >
            <Text
              style={[
                styles.partName,
                {
                  color: this.getCurrentColor()
                }
              ]}
            >
              {i18n.t("examNavbar.ExamPartCarouselItem.partName", {
                currentPosition
              })}
            </Text>
          </TouchableOpacity>
        </Tooltip>

        <Divider
          width={1}
          height={18}
          color={
            isLastExamPart ? COLOR_WHITE : COLOR_GREY_EXAM_NAVBAR_COLOR_CODE
          }
        />
      </View>
    );
  }
}
const styles = StyleSheet.create({
  item: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: 32
  },
  partName: {
    fontFamily: FONT_GILROY_BOLD,
    fontSize: 12
  }
});
export default ExamPartCarouselItem;
