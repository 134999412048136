import React, { useRef } from "react";
import {
  StyleSheet,
  Image,
  View,
  Text,
  StyleProp,
  ViewStyle
} from "react-native";
import { useHover } from "react-native-web-hooks";
import { infoIcon } from "../../../static/misc/images";
import {
  COLOR_BLUE_TESTWE,
  COLOR_GREY_BACKGROUND,
  COLOR_WHITE,
  COLOR_YELLOW_INFO
} from "../../../static/misc/colors";
import {
  FONT_GILROY_ITALIC,
  PADDING_SIDES
} from "../../../static/misc/constants";

interface HoverProps {
  text: string;
  containerStyle: StyleProp<ViewStyle>;
}

const Hover = (props: HoverProps): JSX.Element => {
  const { text, containerStyle } = props;
  const ref = useRef(null);
  const isHovered = useHover(ref);

  return (
    <View
      ref={ref}
      style={[[styles.defaultContainerWithBorder, containerStyle]]}
    >
      <View
        style={(styles.imageContainer, isHovered && styles.imageContainerHover)}
      >
        <Image source={infoIcon} style={[styles.images]} />
      </View>
      {isHovered && text && (
        <View style={styles.hoverContainer}>
          <Text style={styles.hoverText}>{text}</Text>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  defaultContainerWithBorder: {
    width: 25,
    height: 25,
    borderRadius: 50,
    alignItems: "center",
    justifyContent: "center"
  },
  images: {
    width: 16,
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
    height: 16
  },
  imageContainer: {
    borderWidth: 0.5,
    borderColor: COLOR_WHITE,
    padding: 1,
    borderRadius: 50,
    alignSelf: "flex-start"
  },
  imageContainerHover: {
    borderWidth: 1,
    borderColor: COLOR_YELLOW_INFO,
    padding: 1,
    borderRadius: 50,
    alignSelf: "baseline"
  },
  hoverContainer: {
    position: "absolute",
    zIndex: 10,
    left: 40,
    top: 0,
    bottom: 0,
    borderRadius: 3,
    padding: PADDING_SIDES * 0.2,
    justifyContent: "center",
    alignItems: "center",
    width: 150,
    backgroundColor: COLOR_GREY_BACKGROUND
  },
  hoverText: {
    fontFamily: FONT_GILROY_ITALIC,
    color: COLOR_BLUE_TESTWE,
    fontSize: 11
  }
});

export default Hover;
