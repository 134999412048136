import io from "socket.io-client";

import { DURATION_TIMEOUT_SOCKET } from "../../static/misc/constants";
import { PROCTOR_WS } from "../../static/misc/network";

const socket = io(PROCTOR_WS, {
  transports: ["websocket", "polling"],
  autoConnect: true,
  reconnection: true,
  secure: true,
  reconnectionDelay: 1000,
  reconnectionAttempts: Infinity,
  jsonp: false,
  timeout: DURATION_TIMEOUT_SOCKET
});

export default socket;
