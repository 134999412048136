import React from "react";
import {
  StyleSheet,
  StyleProp,
  ViewStyle,
  ImageStyle,
  ImageSourcePropType,
  View,
  Animated,
  Easing
} from "react-native";
import { COLOR_GREEN_VALID } from "../../../static/misc/colors";

export interface ReloadViewProps {
  containerStyle?: StyleProp<ViewStyle>;
  iconStyle?: StyleProp<ImageStyle>;
  icon?: ImageSourcePropType;
  runAnimation?: boolean;
}
interface ReloadViewState {
  spinAnim: Animated.Value;
}

class ReloadView extends React.PureComponent<ReloadViewProps, ReloadViewState> {
  static defaultProps = {
    runAnimation: false
  };

  constructor(props: ReloadViewProps) {
    super(props);
    this.state = {
      spinAnim: new Animated.Value(0)
    };
  }

  startAnimation(): void {
    const { spinAnim } = this.state;

    Animated.loop(
      Animated.timing(spinAnim, {
        toValue: -1,
        duration: 3000,
        easing: Easing.linear,

        useNativeDriver: false
      })
    ).start();
  }

  render(): JSX.Element {
    const { containerStyle, iconStyle, icon, runAnimation } = this.props;
    const { spinAnim } = this.state;
    const spin = spinAnim.interpolate({
      inputRange: [0, 1],
      outputRange: ["0deg", "360deg"]
    });
    if (runAnimation === true) this.startAnimation();
    else spinAnim.stopAnimation();
    return (
      <View style={[styles.defaultContainerStyle, containerStyle]}>
        {icon && (
          <Animated.Image
            source={icon}
            style={[
              {
                height: 16,
                width: 16,
                alignSelf: "center",
                transform: [{ rotate: spin }]
              },
              iconStyle
            ]}
          />
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  defaultContainerStyle: {
    width: 45,
    height: 30,
    backgroundColor: COLOR_GREEN_VALID,
    paddingHorizontal: 25,
    paddingVertical: 5,
    borderRadius: 15,
    alignContent: "center",
    justifyContent: "center",
    flexDirection: "row"
  }
});

export default ReloadView;
