import React from "react";
import {
  ScrollView,
  StyleSheet,
  Text,
  View,
  TouchableOpacity
} from "react-native";
import type { IHighlight } from "react-pdf-highlighter";
import {
  COLOR_BLUE_TESTWE,
  COLOR_GREY_PDF_HIGHLIGHT_BACKGROUND,
  COLOR_GREY_PDF_HIGHLIGHT_FOREGROUND,
  COLOR_SHADOW,
  COLOR_BLUE_TESTWE_30,
  COLOR_WHITE,
  COLOR_TRANSPARENT,
  COLOR_GREY_BORDER
} from "../../../static/misc/colors";
import {
  FONT_GILROY_BOLD,
  PADDING_SIDES
} from "../../../static/misc/constants";
import { guidGenerator } from "../../../static/misc/utils";
import i18n from "../../services/i18n";
import PdfViewerAnnotation from "./PdfViewerAnnotation";
import Icon from "../atoms/Icon";
import { bin } from "../../../static/misc/images";
import CustomModal from "../atoms/CustomModal";
import Button from "./Button";
import Tooltip from "../atoms/Tooltip";

interface PdfViewerSideBarProps {
  highlights: Array<IHighlight>;
  selectedHighlight?: IHighlight;
  highlightSelected: (index: number) => void;
  modifyHighlightComment: (index: number, content: string) => void;
  removeHighlight: (index: number) => void;
}

interface PdfViewerSideBarState {
  showModalDeleteHighlightsConfirmation: boolean;
  needToDeleteAllHighlights: boolean;
}

class PdfViewerSideBar extends React.PureComponent<
  PdfViewerSideBarProps,
  PdfViewerSideBarState
> {
  static defaultProps = {
    selectedHighlight: undefined
  };

  constructor(props: PdfViewerSideBarProps) {
    super(props);

    this.state = {
      showModalDeleteHighlightsConfirmation: false,
      needToDeleteAllHighlights: false
    };
  }

  componentDidUpdate(): void {
    const { needToDeleteAllHighlights } = this.state;
    const { highlights, removeHighlight } = this.props;

    // Triggered only when the needToDeleteAllHighlights is true
    if (needToDeleteAllHighlights) {
      while (highlights.length > 0) {
        removeHighlight(highlights.length - 1);
      }

      this.setState({
        needToDeleteAllHighlights: false
      });
    }
  }

  sort(a: IHighlight, b: IHighlight): number {
    const sort = a.position.pageNumber - b.position.pageNumber;

    if (sort === 0)
      return a.position.boundingRect.y1 - b.position.boundingRect.y1;

    return sort;
  }

  render(): JSX.Element {
    const {
      highlights,
      selectedHighlight,
      highlightSelected,
      modifyHighlightComment,
      removeHighlight
    } = this.props;

    const { showModalDeleteHighlightsConfirmation } = this.state;

    return (
      <View style={styles.sidebar}>
        <View style={styles.title}>
          <Text style={styles.titleText}>
            {i18n.translate("media.comments")}
          </Text>

          {highlights.length > 0 && (
            <Text style={styles.titleText}> ({highlights.length})</Text>
          )}

          {highlights.length > 0 && (
            <Tooltip
              contentText={i18n.translate("media.deleteAllHighlightsTooltip")}
              placement="bottom"
            >
              <TouchableOpacity
                style={{ marginLeft: 8 }}
                onPress={() =>
                  this.setState({
                    showModalDeleteHighlightsConfirmation: !showModalDeleteHighlightsConfirmation
                  })
                }
              >
                <Icon icon={bin} iconStyle={styles.binIcon} />
              </TouchableOpacity>
            </Tooltip>
          )}
        </View>

        <CustomModal
          heightModal="100%"
          widthModal="100%"
          backdropOpacity={0}
          borderRadiusModal={5}
          showCloseButton={false}
          visible={showModalDeleteHighlightsConfirmation}
          modalStyle={styles.modalStyle}
          onCloseModal={() =>
            this.setState({ showModalDeleteHighlightsConfirmation: false })
          }
        >
          <View style={styles.modalContainer}>
            <Text style={styles.modalText}>
              {i18n.translate("media.deleteAllHighlightsModalText")}
            </Text>

            <View style={styles.modalBtnRow}>
              <Button
                label={i18n.translate("media.cancel")}
                textStyle={[{ fontSize: 12, color: COLOR_BLUE_TESTWE }]}
                containerStyle={[
                  {
                    backgroundColor: COLOR_TRANSPARENT,
                    borderColor: COLOR_BLUE_TESTWE,
                    marginRight: 16
                  }
                ]}
                onPress={() =>
                  this.setState({
                    showModalDeleteHighlightsConfirmation: false
                  })
                }
              />

              <Button
                label={i18n.translate("media.confirm")}
                textStyle={[{ fontSize: 12 }]}
                containerStyle={[
                  {
                    backgroundColor: COLOR_BLUE_TESTWE,
                    borderColor: COLOR_BLUE_TESTWE
                  }
                ]}
                onPress={() =>
                  this.setState({
                    showModalDeleteHighlightsConfirmation: false,
                    needToDeleteAllHighlights: true
                  })
                }
              />
            </View>
          </View>
        </CustomModal>

        <ScrollView>
          {highlights &&
            highlights.length > 0 &&
            highlights.sort(this.sort).map((highlight, index) => {
              return (
                <PdfViewerAnnotation
                  key={guidGenerator()}
                  isSelected={selectedHighlight?.id === highlight.id}
                  highlight={highlight}
                  onAnnotationSelected={() => highlightSelected(index)}
                  onCommentModified={(content) =>
                    modifyHighlightComment(index, content)
                  }
                  onDelete={() => removeHighlight(index)}
                />
              );
            })}
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  sidebar: {
    backgroundColor: COLOR_GREY_PDF_HIGHLIGHT_BACKGROUND,
    height: "100%",
    width: "100%"
  },
  title: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    height: "7%",
    padding: PADDING_SIDES * 0.1
  },
  titleText: {
    fontFamily: FONT_GILROY_BOLD,
    color: COLOR_GREY_PDF_HIGHLIGHT_FOREGROUND,
    fontSize: 10,
    justifyContent: "center",
    alignContent: "center"
  },
  binIcon: {
    color: COLOR_BLUE_TESTWE,
    width: 16,
    height: 16
  },
  modalStyle: {
    position: "absolute",
    top: 180,
    left: 24,
    width: 400,
    minHeight: 100,
    backgroundColor: COLOR_WHITE,
    shadowColor: COLOR_SHADOW,
    shadowOffset: {
      width: 0,
      height: 0
    },
    // There is a default paddingBottom in the modal
    paddingBottom: 0
  },
  modalContainer: {
    border: `1px solid ${COLOR_GREY_BORDER}`,
    borderRadius: 5,
    backgroundColor: COLOR_BLUE_TESTWE_30,
    // Not sure about this one
    padding: 24
  },
  modalText: {
    fontFamily: FONT_GILROY_BOLD,
    color: COLOR_BLUE_TESTWE,
    // Not sure about this one
    fontSize: 16
  },
  modalBtnRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginBottom: 8
  }
});

export default PdfViewerSideBar;
