import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";

import {
  COLOR_BLUE_TESTWE,
  COLOR_GREY_BORDER,
  COLOR_WHITE
} from "../../../static/misc/colors";
import Tooltip from "./Tooltip";
import { FONT_GILROY_SEMI_BOLD } from "../../../static/misc/constants";

import "react-circular-progressbar/dist/styles.css";

interface CircularExamProgressbarProps {
  percentage: number;
  tooltip: string;
  containerStyle?: React.CSSProperties;
}

class CustomCircularProgressbar extends React.PureComponent<CircularExamProgressbarProps> {
  render(): JSX.Element {
    const { percentage, tooltip, containerStyle } = this.props;

    return (
      <Tooltip
        contentText={tooltip}
        tooltipViewStyle={{ background: COLOR_WHITE }}
      >
        <CircularProgressbar
          value={percentage}
          text={`${percentage}%`}
          background
          styles={{
            // Customize the root svg element
            root: {
              ...containerStyle
            },
            // Customize the path, i.e. the "completed progress"
            path: {
              // Path color
              stroke: COLOR_BLUE_TESTWE,
              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
              strokeLinecap: "butt",
              // Customize transition animation
              transition: "stroke-dashoffset 0.5s ease 0s"
            },
            // Customize the circle behind the path, i.e. the "total progress"
            trail: {
              // Trail color
              stroke: COLOR_GREY_BORDER,
              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
              strokeLinecap: "butt"
            },
            // Customize the text
            text: {
              // Text color
              fill: COLOR_BLUE_TESTWE,
              // Text size
              fontSize: "32px",
              fontFamily: FONT_GILROY_SEMI_BOLD
            },
            // Customize background - only used when the `background` prop is true
            background: {
              fill: "transparent"
            }
          }}
        />
      </Tooltip>
    );
  }
}

export default CustomCircularProgressbar;
