import {
  WS_ENABLE_PROCTOR_AUDIO,
  WS_DISABLE_PROCTOR_AUDIO,
  WS_ADD_TIME_EXAM
} from "../../../../static/misc/wsevents";
import i18n from "../../../services/i18n";
import {
  GETSUCCESS,
  RESET_SUCCESS,
  SuccessActionTypes,
  SuccessState
} from "../types/success";

const initialState: SuccessState = {
  success: false,
  successMessage: null
};

export default function reducer(
  state = initialState,
  action: SuccessActionTypes
): SuccessState {
  switch (action.type) {
    case WS_ADD_TIME_EXAM: {
      const { timeAdded } = action.payload;
      if (timeAdded > 0) {
        return {
          ...state,
          success: true,
          successMessage: i18n.t("proctoringLive.addTime")
        };
      }
      return {
        ...state,
        success: true,
        successMessage: i18n.t("proctoringLive.minusTime")
      };
    }
    case WS_ENABLE_PROCTOR_AUDIO:
      return {
        ...state,
        success: true,
        successMessage: i18n.t("proctoringLive.proctorAudioEnable")
      };

    case WS_DISABLE_PROCTOR_AUDIO:
      return {
        ...state,
        success: true,
        successMessage: i18n.t("proctoringLive.proctorAudioDisable")
      };

    case RESET_SUCCESS:
      return {
        ...initialState
      };

    case GETSUCCESS:
      return {
        ...state,
        success: true,
        successMessage: action.payload.errorMessage
      };

    default:
      return state;
  }
}
