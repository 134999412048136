import React from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import Button from "./Button";
import { COLOR_GREY_PLACEHOLDER } from "../../../static/misc/colors";
import { FONTSIZE_16, PADDING_SIDES } from "../../../static/misc/constants";
import { guidGenerator } from "../../../static/misc/utils";
import {
  CalculatorButtonType,
  CalculatorButtonTypeEnum
} from "../../modules/examTaking/types/calculator";
import { CALC_SCIENTIFIC_STRUCTURE } from "../../../static/misc/calculators";

interface CalculatorScientificKeyboardProps {
  addChar: (char: string, buttonType: CalculatorButtonTypeEnum) => void;
  addFunction: (fn: string, waitForUserInput: boolean) => void;
  showSecondButtons: boolean;
  onShowSecondButtons: () => void;
  containerStyle?: StyleProp<ViewStyle>[];
}

class CalculatorScientificKeyboard extends React.PureComponent<CalculatorScientificKeyboardProps> {
  render(): JSX.Element {
    const {
      addFunction,
      addChar,
      showSecondButtons,
      onShowSecondButtons,
      containerStyle
    } = this.props;

    return (
      <View style={[containerStyle, styles.keyboardContainer]}>
        {CALC_SCIENTIFIC_STRUCTURE.map((row: CalculatorButtonType[][]) => {
          return (
            <View key={guidGenerator()} style={styles.calculatorRow}>
              {row.map((cell: CalculatorButtonType[]) => {
                const tmpCell =
                  showSecondButtons && cell.length > 1 ? cell[1] : cell[0];
                return (
                  <View
                    key={guidGenerator()}
                    style={[
                      {
                        width: tmpCell.width
                      },
                      styles.cellContainer
                    ]}
                  >
                    <Button
                      onPress={() => {
                        switch (tmpCell.buttonType) {
                          case CalculatorButtonTypeEnum.FUNCTION: {
                            if (tmpCell.function) {
                              addFunction(
                                tmpCell.function,
                                tmpCell.waitForUserInput || false
                              );
                            }
                            break;
                          }
                          case CalculatorButtonTypeEnum.E:
                          case CalculatorButtonTypeEnum.PI:
                          case CalculatorButtonTypeEnum.OPENINGBRACKET:
                          case CalculatorButtonTypeEnum.CLOSINGBRACKET: {
                            addChar(
                              tmpCell.value || tmpCell.label,
                              tmpCell.buttonType
                            );
                            break;
                          }
                          case CalculatorButtonTypeEnum.SECOND: {
                            onShowSecondButtons();
                            break;
                          }
                          default:
                            break;
                        }
                      }}
                      label={tmpCell.label}
                      containerStyle={[
                        {
                          borderColor: tmpCell.backgroundColor,
                          backgroundColor: tmpCell.backgroundColor
                        },
                        styles.cell
                      ]}
                      textStyle={{
                        color: tmpCell.color,
                        fontSize: FONTSIZE_16
                      }}
                    />
                  </View>
                );
              })}
            </View>
          );
        })}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  keyboardContainer: {
    flexDirection: "column"
  },
  calculatorRow: {
    flexDirection: "row",
    alignItems: "stretch"
  },
  cellContainer: {
    borderWidth: 1,
    borderColor: COLOR_GREY_PLACEHOLDER
  },
  cell: {
    borderRadius: 0,
    paddingVertical: PADDING_SIDES * 0.2,
    paddingHorizontal: PADDING_SIDES * 0.1
  }
});

export default CalculatorScientificKeyboard;
