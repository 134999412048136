import { ClearCredentialsActions } from "../../../store/rootreducer";
import {
  PostStudentPaperAction,
  UpdateStudentPaperAction
} from "../../examTaking/types/studentPaper";
import { DisplayedExamsEnum, DisplayedExamsOrderEnum } from "../ExamsView";
import { ExamType } from "./exam";
import { SetEstablishmentAction, SetLocale } from "../../main/types/user";
import { StudentPaperSubmissionAction } from "../../main/types/status";
import { CREATE_EXAM_TAKING } from "../../examTaking/types/examTaking";

export const SORT_EXAMS = "SORT_EXAMS";

export const GET_EXAM_HASH = "GET_EXAM_HASH";
export const GET_EXAM_HASH_SUCCESS = "GET_EXAM_HASH_SUCCESS";
export const GET_EXAM_HASH_FAIL = "GET_EXAM_HASH_FAIL";

export const GET_EXAMS_HASH = "GET_EXAMS_HASH";
export const GET_EXAMS_HASH_SUCCESS = "GET_EXAMS_HASH_SUCCESS";
export const GET_EXAMS_HASH_FAIL = "GET_EXAMS_HASH_FAIL";

export const POST_SYNCRO = "POST_SYNCRO";
export const POST_SYNCRO_SUCCESS = "POST_SYNCRO_SUCCESS";
export const POST_SYNCRO_FAIL = "POST_SYNCRO_FAIL";

export const GET_MY_PAPER_B64 = "GET_MY_PAPER_B64";
export const GET_MY_PAPER_B64_SUCCESS = "GET_MY_PAPER_B64_SUCCESS";
export const GET_MY_PAPER_B64_FAIL = "GET_MY_PAPER_B64_FAIL";

export const GET_SYNC_EXAM = "GET_SYNC_EXAM";
export const GET_SYNC_EXAM_SUCCESS = "GET_SYNC_EXAM_SUCCESS";
export const GET_SYNC_EXAM_FAIL = "GET_SYNC_EXAM_FAIL";

export const GET_SYNC_PAST_EXAM = "GET_SYNC_PAST_EXAM";
export const GET_SYNC_PAST_EXAM_SUCCESS = "GET_SYNC_PAST_EXAM_SUCCESS";
export const GET_SYNC_PAST_EXAM_FAIL = "GET_SYNC_PAST_EXAM_FAIL";

export const GET_EXAM_FOR_PREVIEW = "GET_EXAM_FOR_PREVIEW";
export const GET_EXAM_FOR_PREVIEW_SUCCESS = "GET_EXAM_FOR_PREVIEW_SUCCESS";
export const GET_EXAM_FOR_PREVIEW_FAIL = "GET_EXAM_FOR_PREVIEW_FAIL";

export const REMOVE_EXAMS = "REMOVE_EXAMS";
export const CLEAR_EXAMS = "CLEAR_EXAMS";

export const UPDATE_PROCTOR_ARCHIVE_EXAM_STATUS =
  "UPDATE_PROCTOR_ARCHIVE_EXAM_STATUS";

export const DISPLAY_NEXT_EXAMS = "DISPLAY_NEXT_EXAMS";
export const DISPLAY_PASSED_EXAMS = "DISPLAY_PASSED_EXAMS";
export const CHANGE_DISPLAYED_EXAMS_ORDER = "CHANGE_DISPLAYED_EXAMS_ORDER";
export const UPDATE_SELECTED_ACADEMIC_YEAR = "UPDATE_SELECTED_ACADEMIC_YEAR";

export const CHANGING_EXAM_MYSTUDPAPER_STATUS =
  "CHANGING_EXAM_MYSTUDPAPER_STATUS";

export const SET_NEXT_EXAM = "SET_NEXT_EXAM";

export interface GetSyncExamActionDefaultPayload {
  data: ExamType;
}

export interface GetSyncExamActionApiPayload {
  examId: string;
  client: string;
  request: {
    headers: {
      accept: string;
      authorization: string;
    };
    url: string;
  };
  method: string;
}

export interface SetNextExamAction {
  type: typeof SET_NEXT_EXAM;
  payload: ExamType;
}
export interface PostSyncronizeAction {
  type:
    | typeof POST_SYNCRO
    | typeof POST_SYNCRO_SUCCESS
    | typeof POST_SYNCRO_FAIL;
  payload: any;
}

export interface GetExamsHashAction {
  type:
    | typeof GET_EXAMS_HASH
    | typeof GET_EXAMS_HASH_FAIL
    | typeof GET_EXAMS_HASH_SUCCESS;
  payload: any;
}

export interface CreateStudentPaperAction {
  type: typeof CREATE_EXAM_TAKING;
  payload: ExamType;
}

export interface RemoveExamsAction {
  type: typeof REMOVE_EXAMS | typeof CLEAR_EXAMS;
  payload: any;
}

export interface UpdateProctorArchiveExamStatusAction {
  type: typeof UPDATE_PROCTOR_ARCHIVE_EXAM_STATUS;
  payload: any;
}

export interface SortExamsAction {
  type: typeof SORT_EXAMS;
}

export interface GetSyncExamAction<T = GetSyncExamActionDefaultPayload> {
  meta?: any;
  type:
    | typeof GET_SYNC_EXAM
    | typeof GET_SYNC_EXAM_FAIL
    | typeof GET_SYNC_EXAM_SUCCESS
    | typeof GET_SYNC_PAST_EXAM
    | typeof GET_SYNC_PAST_EXAM_FAIL
    | typeof GET_SYNC_PAST_EXAM_SUCCESS
    | typeof GET_EXAM_FOR_PREVIEW
    | typeof GET_EXAM_FOR_PREVIEW_SUCCESS
    | typeof GET_EXAM_FOR_PREVIEW_FAIL;
  /**
   * By default, payload type is `GetSyncExamActionDefaultPayload` but you can specify a different type like `GetSyncExamActionApiPayload`
   * to be able to mix types between reducers and actions
   */
  payload: T;
}

export interface UpdateDisplayedExamsAction {
  type:
    | typeof DISPLAY_NEXT_EXAMS
    | typeof DISPLAY_PASSED_EXAMS
    | typeof UPDATE_SELECTED_ACADEMIC_YEAR;
  payload: any;
}

export interface ChangingExamMyStudPaperStatusAction {
  type: typeof CHANGING_EXAM_MYSTUDPAPER_STATUS;
  payload: any;
}

export interface ChangeOrderDisplayedExamsAction {
  type: typeof CHANGE_DISPLAYED_EXAMS_ORDER;
  payload: any;
}

export interface GetMyPaperBase64Action {
  meta: any;
  type:
    | typeof GET_MY_PAPER_B64
    | typeof GET_MY_PAPER_B64_SUCCESS
    | typeof GET_MY_PAPER_B64_FAIL;
  payload: any;
}

export interface ExamsType {
  passedExams: Array<ExamType>;
  nextExam: ExamType | undefined;
  nextExams: Array<ExamType>;
}

export interface ExamsOrdering {
  category: string;
  order: DisplayedExamsOrderEnum;
}

export interface ExamsOrderingMeta {
  nextExams: ExamsOrdering;
  passedExams: ExamsOrdering;
}

export interface ExamsHash {
  id: string;
  hash: string;
  examFromPast: boolean;
}

export interface ExamsState extends ExamsType {
  examsHash: ExamsHash[];
  lastUpdate: string | undefined;
  displayedExams: DisplayedExamsEnum;
  selectedAcademicYear: string | undefined;
  ordered: ExamsOrderingMeta;
}
export interface GetExamHashAction {
  type:
    | typeof GET_EXAM_HASH
    | typeof GET_EXAM_HASH_FAIL
    | typeof GET_EXAM_HASH_SUCCESS;
  payload: {
    examId: string;
    client: string;
    data?: ExamsHash;
    request: {
      headers: {
        accept: string;
        authorization: string;
      };
      url: string;
      method: string;
    };
  };
}

export type ExamsActionsType =
  | SetLocale
  | GetExamsHashAction
  | GetSyncExamAction
  | RemoveExamsAction
  | SortExamsAction
  | PostSyncronizeAction
  | SetNextExamAction
  | UpdateDisplayedExamsAction
  | ChangeOrderDisplayedExamsAction
  | UpdateProctorArchiveExamStatusAction
  | StudentPaperSubmissionAction
  | ChangingExamMyStudPaperStatusAction
  | PostStudentPaperAction
  | UpdateStudentPaperAction
  | ClearCredentialsActions
  | GetMyPaperBase64Action
  | SetEstablishmentAction
  | CreateStudentPaperAction
  | GetExamHashAction;
