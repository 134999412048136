import React from "react";
import { Image, StyleSheet, TouchableOpacity } from "react-native";
import { IS_PREVIEW } from "../../../static/misc/constants";
import { bookmark, bookmarkFilled } from "../../../static/misc/images";

interface BookmarkProps {
  bookmarkOn: boolean | undefined;
  turnOn: () => void;
}
interface BookmarkState {
  bool: boolean | undefined;
}

class Bookmark extends React.PureComponent<BookmarkProps, BookmarkState> {
  constructor(props: BookmarkProps) {
    super(props);
    const { bookmarkOn } = this.props;
    this.state = {
      bool: bookmarkOn
    };
  }

  render(): JSX.Element {
    const { turnOn } = this.props;
    const { bool } = this.state;
    return (
      <TouchableOpacity
        style={styles.touchableStyle}
        disabled={!!IS_PREVIEW}
        onPress={() => {
          turnOn();
          this.setState({ bool: !bool });
        }}
      >
        <Image
          source={bool ? bookmarkFilled : bookmark}
          style={styles.imageStyle}
        />
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  touchableStyle: {
    width: 20,
    height: 20
  },
  imageStyle: {
    width: 9,
    height: 16
  }
});

export default Bookmark;
