import { CLEAR_CREDENTIALS } from "../../../../static/misc/constants";
import {
  AuthActionTypes,
  AuthState,
  CONNECTUSER,
  REFRESH_TOKEN,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAIL
} from "../types/auth";

const initialState: AuthState = {
  credentials: undefined,
  isConnected: false,
  failureRefreshTokenStatus: 0,
  failureRefreshToken: false
};

export default function reducer(
  state = initialState,
  action: AuthActionTypes | any
): AuthState {
  switch (action.type) {
    case CONNECTUSER:
      return {
        ...state,
        isConnected: true,
        failureRefreshTokenStatus: 0,
        failureRefreshToken: false,
        credentials: action.payload
      };

    case REFRESH_TOKEN:
      return {
        ...state,
        failureRefreshTokenStatus: 0,
        failureRefreshToken: false
      };

    case REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        credentials: {
          ...state.credentials,
          ...action.payload.data,
          exp: state.credentials?.exp + action.payload.data.expires_in
        }
      };

    case REFRESH_TOKEN_FAIL:
      return {
        ...state,
        failureRefreshToken: true,
        failureRefreshTokenStatus: action.error.response.status
      };

    case CLEAR_CREDENTIALS:
      return initialState;

    default:
      return state;
  }
}
