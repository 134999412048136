import AsyncStorage from "@react-native-community/async-storage";
import CryptoJS from "crypto-js";
import _ from "lodash";
import { ipcRenderer } from "electron";
import {
  ExamChoice,
  ExamOnboardingStep,
  ExamQuestion,
  ExamType
} from "../../src/modules/exams/types/exam";
import { ExamsType } from "../../src/modules/exams/types/exams";

import { StudentPaperType } from "../../src/modules/examTaking/types/studentPaper";
import moment from "../../src/services/moment";
import {
  AUDIO_QUESTION,
  CORRECTED,
  GRID_MULTIPLE_CHOICE_QUESTION,
  GRID_SINGLE_CHOICE_QUESTION,
  IS_PREVIEW,
  IS_WEB_ENABLE,
  MIME_DEFAULT,
  MIME_MPEG,
  MIME_MS,
  MIME_WAV,
  MULTIPLE_CHOICE_QUESTION,
  NOT_SYNCRO,
  SENT,
  SINGLE_CHOICE_QUESTION,
  SPREADSHEET_QUESTION,
  SYNCRO,
  TEXT_DROPDOWN_QUESTION,
  TOINTEGRATE
} from "./constants";
import { hasStudentPaper } from "../../src/modules/exams/utils";
import { UserState } from "../../src/modules/main/types/user";

export const getOsHostname = (): Promise<any> => {
  return new Promise((resolve) => {
    if (!IS_PREVIEW && !IS_WEB_ENABLE) {
      ipcRenderer.on("GET_OS_HOSTNAME_RESPOND", (event, arg) => {
        resolve(arg);
      });
      ipcRenderer.send("GET_OS_HOSTNAME");
    } else {
      resolve("web");
    }
  });
};

export const b64toblob = (b64: string, mimeType: string): Blob => {
  const byteCharacters = Buffer.from(b64, "base64");
  const byteArray = Uint8Array.from(byteCharacters)?.buffer;
  // const byteNumbers = new Array(byteCharacters.length);
  // for (let i = 0; i < byteCharacters.length; i++) {
  //   byteNumbers[i] = byteCharacters.charCodeAt(i);
  // }
  // const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: mimeType });
};

export const getAuthCodeFromUrl = (url: string): string => {
  const query = url.substr(1);
  let code;
  query.split("&").forEach((part) => {
    const item = part.split("=");
    if (item[0] === "code") {
      code = decodeURIComponent(item[1]);
    }
  });
  return code || "";
};

export const getQuestionFileMimeType = (questionType: string): string => {
  switch (questionType) {
    case AUDIO_QUESTION:
      return MIME_MPEG;
    case SPREADSHEET_QUESTION:
      return MIME_MS;
    default:
      return MIME_DEFAULT;
  }
};

export const getFileExtension = (contentType: string): string => {
  switch (contentType) {
    case MIME_WAV:
      return ".wav";
    case MIME_MPEG:
      return ".mp3";
    case MIME_MS:
      return ".xlsx";
    default:
      return ".bin";
  }
};

export const cipher = (passKey: string, message: string): string => {
  const key = CryptoJS.enc.Hex.parse(CryptoJS.SHA256(passKey).toString());
  const enc = CryptoJS.AES.encrypt(message, key, {
    iv: CryptoJS.lib.WordArray.random(16),
    salt: null
  });
  return `${enc.ciphertext.toString(CryptoJS.enc.Base64)}|${btoa(
    CryptoJS.HmacSHA256(enc.ciphertext, key).toString()
  )}|${enc.iv.toString(CryptoJS.enc.Base64)}` as any;
};

export const enigma = (publicKey: string, explodeStr: string[]): string => {
  // First PIPE in CRYPTED str
  const cp = CryptoJS.lib.CipherParams.create({
    ciphertext: CryptoJS.enc.Base64.parse(explodeStr[0])
  });

  // Third PIPE in CRYPTED str ---> iv
  // SHA256 ==> public key or offline key
  const decrypted = CryptoJS.AES.decrypt(
    cp,
    CryptoJS.enc.Hex.parse(CryptoJS.SHA256(publicKey).toString()),
    {
      mode: CryptoJS.mode.CBC,
      iv: CryptoJS.enc.Base64.parse(explodeStr[2])
    }
  );
  return decrypted.toString(CryptoJS.enc.Utf8);
};

export const formatAnswersId = (
  tmpCurrentStudentPaper: StudentPaperType
): StudentPaperType => {
  let i = 0;
  let iterator = 0;
  while (i !== tmpCurrentStudentPaper.studentAnswers.length) {
    const currentAnswer = tmpCurrentStudentPaper.studentAnswers[i];
    if (typeof currentAnswer !== "string") {
      if (
        currentAnswer.givenChoices &&
        currentAnswer.givenChoices?.length > 0
      ) {
        while (iterator !== currentAnswer.givenChoices?.length) {
          if (currentAnswer.givenChoices[iterator].indexOf("/choices") === -1) {
            currentAnswer.givenChoices[
              iterator
            ] = `/choices/${currentAnswer.givenChoices[iterator]}`;
          }
          iterator += 1;
        }
        iterator = 0;
      }
      if (currentAnswer.question.indexOf("/questions/") === -1) {
        currentAnswer.question = `/questions/${currentAnswer.question}`;
      }
    }
    i += 1;
  }
  return tmpCurrentStudentPaper;
};

export const findStudentPaper = (
  studentPapers: StudentPaperType[],
  examId: string | undefined,
  currentUserId: string | undefined = undefined
): StudentPaperType | undefined => {
  return studentPapers.find(
    (sp) =>
      examId && currentUserId && hasStudentPaper(examId, currentUserId, [sp])
  );
};

export const findIndexStudentPaper = (
  studentPapers: StudentPaperType[],
  examId: string | undefined
): number => {
  return studentPapers.findIndex(
    (studentPaper) => studentPaper.examId?.toString() === examId?.toString()
  );
};

export const displayStaticTimer = (duration: number): string => {
  if (duration < 3600 && duration >= 600) {
    // If between 10 and 59 minutes
    const minutes = Math.floor((duration % 3600) / 60);
    return `${minutes} min`;
  }
  if (duration < 600) {
    // If lower than 10 minutes
    const minutes = Math.floor(duration / 60);
    const seconds = Math.floor(duration % 60);
    return `${minutes}'${seconds > 0 ? seconds : ""}`;
  }
  // If equal to or higher than 1h
  const hour = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  return `${hour}h${minutes < 10 ? "0" : ""}${minutes}`;
};

export const formatNumberWithLeadingZero = (number: number): string => {
  if (number < 10) {
    return `0${number.toString()}`;
  }
  return number.toString();
};

export const shuffleQuestion = (examQuestion: ExamQuestion): ExamQuestion => {
  const question = { ...examQuestion };
  switch (question.type) {
    case TEXT_DROPDOWN_QUESTION: {
      if (question.text) {
        const html = question.text;
        const temp = document.createElement("div");
        temp.innerHTML = html;
        const all = temp.getElementsByTagName("select");
        for (let i = 0, max = all.length; i < max; i++) {
          const select = all[i];
          let keeper = [];
          while (select.firstChild) {
            if (select.firstChild.textContent !== "↵")
              keeper.push(select.firstChild);
            select.removeChild(select.firstChild);
          }
          keeper = shuffle(keeper, keeper.length - 1);
          keeper.forEach((element) => {
            select.append(element);
          });
        }
        question.text = temp.outerHTML;
      }
      break;
    }
    case MULTIPLE_CHOICE_QUESTION:
    case SINGLE_CHOICE_QUESTION: {
      question.choices = shuffle(
        [...question.choices],
        question.choices.length - 1
      );
      break;
    }
    case GRID_MULTIPLE_CHOICE_QUESTION:
    case GRID_SINGLE_CHOICE_QUESTION: {
      const choicesTmp = [...question.choices];
      if (question.columnLabels && question.rowLabels) {
        let columnLabelsTmp = shuffle(
          [...question.columnLabels],
          question.columnLabels.length - 1
        );
        let rowLabelsTmp = shuffle(
          [...question.rowLabels],
          question.rowLabels.length - 1
        );
        const newChoices: ExamChoice[] = [];
        choicesTmp.forEach((el) => {
          const choice = el;
          let mapped = false;
          rowLabelsTmp.forEach((elRow, indexRow) => {
            columnLabelsTmp.forEach((elCol, indexCol) => {
              if (
                !mapped &&
                el.columnIndex === elCol.index &&
                el.rowIndex === elRow.index
              ) {
                mapped = true;
                choice.columnIndex = indexCol;
                choice.rowIndex = indexRow;
                newChoices.push(choice);
              }
            });
          });
        });

        newChoices.sort(function (a, b) {
          if (a.columnIndex === b.columnIndex) {
            return a.rowIndex < b.rowIndex
              ? -1
              : a.rowIndex === b.rowIndex
              ? 0
              : 1;
          }
          return a.columnIndex < b.columnIndex ? -1 : 1;
        });
        columnLabelsTmp = columnLabelsTmp.map((el, index) => {
          const element = el;
          element.index = index;
          return element;
        });
        rowLabelsTmp = rowLabelsTmp.map((el, index) => {
          const element = el;
          element.index = index;
          return element;
        });
        question.columnLabels = columnLabelsTmp;
        question.rowLabels = rowLabelsTmp;
      }
      break;
    }
    default:
      break;
  }
  return question;
};

export const shuffle = (array: Array<any>, length: number): Array<any> => {
  const newArray = array;
  let i = length;
  for (; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    newArray[i] = array[j];
    newArray[j] = temp;
  }
  return newArray;
};

export const replaceNextExam = (
  exams: ExamsType,
  currentExam: ExamType
): ExamsType => {
  const examsCpy = exams;
  const currentExamId = currentExam.id;
  if (exams.nextExam?.id === currentExamId) examsCpy.nextExam = currentExam;
  exams.nextExams.forEach((examItem, index) => {
    if (examItem.id === currentExamId) examsCpy.nextExams[index] = currentExam;
  });
  exams.passedExams.forEach((examItem, index) => {
    if (examItem.id === currentExamId) examsCpy.nextExams[index] = currentExam;
  });
  return examsCpy;
};

export const isAPassedExam = (
  exam: ExamType,
  currentUserId?: UserState["id"],
  studentPapers?: StudentPaperType[]
): boolean => {
  const now = Date.now();
  const isExamStarted =
    exam.myStudentPaper?.startDate &&
    (exam.myStudentPaper?.endDate !== undefined ||
      exam.myStudentPaper?.endDate !== "");

  if (currentUserId && studentPapers) {
    return (
      hasStudentPaper(exam.id, currentUserId, studentPapers) &&
      exam.myStudentPaper?.status === (SENT || CORRECTED || TOINTEGRATE)
    );
  }

  // FIXME: This is not clean at all, we definitly should avoid this massive kind of conditions
  // Maybe we should create utility functions to handle this kind of logic
  // Ex: isStudentPaperSync(exam.myStudentPaper?.status) || isStudentPaperSent(exam.myStudentPaper?.status) || isStudentPaperCorrected(exam.myStudentPaper?.status)
  return (
    (isExamStarted &&
      moment(
        new Date(exam.startDate).getTime() +
          exam.duration * 1000 +
          exam.timeZeroSecond * 1000
      ).isBefore(now, "second")) ||
    (!isExamStarted &&
      moment(
        new Date(exam.startDate).getTime() + exam.timeZeroSecond * 1000
      ).isBefore(now, "second")) ||
    exam.status === CORRECTED ||
    (exam.myStudentPaper?.endDate !== undefined &&
      exam.myStudentPaper?.endDate !== "") ||
    exam.myStudentPaper?.status === SYNCRO ||
    exam.myStudentPaper?.status === SENT ||
    exam.myStudentPaper?.status === CORRECTED ||
    exam.myStudentPaper?.status === NOT_SYNCRO ||
    exam.myStudentPaper?.status === TOINTEGRATE
  );
};

export const replaceExamById = (
  exams: ExamsType,
  exam: ExamType
): ExamsType => {
  const examTmp = exam;

  let passedExams = [...exams.passedExams];
  let nextExams = [...exams.nextExams];
  let { nextExam } = exams;

  const indexExamPassedExam = passedExams.findIndex((e) => e.id === examTmp.id);
  const indexExamNextExam = nextExams.findIndex((e) => e.id === examTmp.id);

  if (indexExamNextExam >= 0) {
    if (nextExams[indexExamNextExam].myStudentPaper?.status === NOT_SYNCRO) {
      examTmp.myStudentPaper = {
        ...nextExams[indexExamNextExam].myStudentPaper
      };
    }
    if (
      nextExams[indexExamNextExam].allAttachedFiles ||
      nextExams[indexExamNextExam].attachedFiles
    ) {
      examTmp.allAttachedFiles = [
        ...nextExams[indexExamNextExam].allAttachedFiles
      ];
      examTmp.attachedFiles = [...nextExams[indexExamNextExam].attachedFiles];
    }
    nextExams.splice(indexExamNextExam, 1);
  }
  if (indexExamPassedExam >= 0) {
    if (
      passedExams[indexExamPassedExam].myStudentPaper?.status === NOT_SYNCRO
    ) {
      examTmp.myStudentPaper = {
        ...passedExams[indexExamPassedExam].myStudentPaper
      };
    }
    passedExams.splice(indexExamPassedExam, 1);
  }

  // ensure onboarding steps sorted in the right order
  if (
    examTmp.onboarding &&
    _.isPlainObject(examTmp.onboarding) &&
    _.isArray(examTmp.onboarding?.steps) &&
    examTmp.onboarding?.steps.length > 0
  ) {
    const steps = [...examTmp.onboarding.steps];
    examTmp.onboarding.steps = steps.sort(
      (a: ExamOnboardingStep, b: ExamOnboardingStep) => {
        if (!b || a.order > b.order) return 1;
        if (a.order === b.order) return 0;
        return -1;
      }
    );
  }

  // pushing new exam in list
  if (isAPassedExam(examTmp)) passedExams.push(examTmp);
  else nextExams.push(examTmp);

  // sorting exams and updating next exam based on nextExams first element
  if (nextExams.length > 0) {
    nextExams = nextExams.sort((a, b) => moment(a.startDate).diff(b.startDate));
    const [one] = nextExams;
    nextExam = one;
  }
  if (passedExams.length > 0) {
    passedExams = passedExams.sort((a, b) =>
      moment(a.startDate).diff(b.startDate)
    );
  }
  return {
    passedExams,
    nextExam,
    nextExams
  };
};

export const examsSplitter = (examsArray: Array<ExamType>): ExamsType => {
  let passedExams: Array<ExamType> = [];
  let nextExam: ExamType | undefined;
  let nextExams: Array<ExamType> = [];

  // if exams array is null, no need to go further
  if (
    examsArray === undefined ||
    examsArray === null ||
    examsArray.length === 0
  ) {
    return {
      passedExams: [],
      nextExams: [],
      nextExam: undefined
    };
  }

  // pushing exam in passed or next exams depending on if is a passed exam or not
  examsArray.forEach((exam) => {
    if (exam.startDate !== null) {
      if (isAPassedExam(exam) && exam.startDate !== null)
        passedExams.push(exam);
      else nextExams.push(exam);
    }
  });

  // sorting exams before returning them
  if (nextExams.length > 0) {
    nextExams = nextExams.sort((a, b) => moment(a.startDate).diff(b.startDate));
    const [one] = nextExams;
    nextExam = one;
  }
  if (passedExams.length > 0) {
    passedExams = passedExams.sort((a, b) =>
      moment(a.startDate).diff(b.startDate)
    );
  }
  return {
    passedExams,
    nextExams,
    nextExam
  };
};

export const guidGenerator = (): string => {
  const S4 = function randmath(): string {
    return ((1 + Math.random()) * 0x10000 || 0).toString(16).substring(1);
  };
  return `${S4() + S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;
};

export const getAsyncStorageItem = async (
  key: string,
  callback = undefined
): Promise<string | null> => {
  const response = await AsyncStorage.getItem(key, callback);
  return response;
};
export const setAsyncStorageItem = async (
  key: string,
  value: string,
  callback = undefined
): Promise<void> => {
  await AsyncStorage.setItem(key, value, callback);
};

export const removeAsyncStorageItem = async (
  key: string,
  callback = undefined
): Promise<void> => {
  await AsyncStorage.removeItem(key, callback);
};

export const clearAsyncStorage = async (
  callback = undefined
): Promise<void> => {
  await AsyncStorage.clear(callback);
};

export const MIN_MEMORY_REQUIRED_IN_MEGA_BYTES = 3500;

export const megabytesToGigabytes = (mb: number, rounded?: boolean): number =>
  rounded ? Math.round((mb / 1000 + Number.EPSILON) * 100) / 100 : mb / 1000;

export const hasNotEnoughAvailableMemory = (
  totalAvailableMemoryInMb: number
): boolean => totalAvailableMemoryInMb < MIN_MEMORY_REQUIRED_IN_MEGA_BYTES;
