import React from "react";
import {
  Image,
  Text,
  ImageURISource,
  TouchableOpacity,
  StyleSheet
} from "react-native";
import { Hoverable } from "react-native-web-hooks";
import {
  COLOR_BLUE,
  COLOR_BLUE_TESTWE,
  COLOR_WHITE
} from "../../../static/misc/colors";
import {
  FONT_GILROY_REGULAR,
  FONTSIZE_11
} from "../../../static/misc/constants";
import i18n from "../../services/i18n";

export interface MenuElementProps {
  onPress: () => void;
  icon: {
    source: ImageURISource;
    width: number;
    height: number;
  };
  label: string;
}

class MenuElement extends React.PureComponent<MenuElementProps> {
  render(): JSX.Element {
    const { icon, label, onPress } = this.props;
    return (
      <Hoverable>
        {(isHovered) => {
          return (
            <TouchableOpacity
              onPress={onPress}
              activeOpacity={0.9}
              style={[
                styles.container,
                isHovered
                  ? { backgroundColor: COLOR_BLUE_TESTWE }
                  : { backgroundColor: COLOR_WHITE }
              ]}
            >
              <Image
                source={icon.source}
                style={[
                  styles.icon,
                  { width: icon.width, height: icon.height },
                  isHovered
                    ? { tintColor: COLOR_WHITE }
                    : { tintColor: COLOR_BLUE_TESTWE }
                ]}
              />
              <Text
                style={[
                  styles.textStyle,
                  isHovered ? { color: COLOR_WHITE } : { color: COLOR_BLUE }
                ]}
              >
                {i18n.t(label)}
              </Text>
            </TouchableOpacity>
          );
        }}
      </Hoverable>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    paddingLeft: 16,
    alignItems: "center",
    paddingVertical: 10,
    width: 156,
    flexDirection: "row"
  },
  icon: {
    marginRight: 16
  },
  textStyle: {
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_11
  }
});

export default MenuElement;
