import React from "react";
import { View, Text, StyleSheet } from "react-native";
import CustomModal from "../atoms/CustomModal";
import i18n from "../../services/i18n";
import {
  FONTSIZE_16,
  FONT_GILROY_BOLD,
  FONT_GILROY_REGULAR,
  PADDING_SIDES
} from "../../../static/misc/constants";
import { COLOR_BLUE_TESTWE } from "../../../static/misc/colors";
import ExamTakingTimer, { TimerTypeEnum } from "../atoms/ExamTakingTimer";
import { guidGenerator } from "../../../static/misc/utils";
import Button from "./Button";

export interface StopExamModalProps {
  isVisible: boolean;
  examId: string;
  onPress: () => void;
}

export default class StopExamModal extends React.Component<StopExamModalProps> {
  shouldComponentUpdate(nextProps: StopExamModalProps): boolean {
    const { isVisible } = this.props;
    return nextProps.isVisible !== isVisible;
  }

  render(): JSX.Element {
    const { isVisible, examId, onPress } = this.props;

    return (
      <CustomModal
        heightModal="30%"
        widthModal="50%"
        borderRadiusModal={5}
        showCloseButton={false}
        visible={isVisible}
        onCloseModal={() => null}
      >
        <View style={styles.modalContainer}>
          <View style={styles.timerContainer}>
            <ExamTakingTimer
              key={guidGenerator()}
              examId={examId}
              duration={15}
              displayZeroHour={false}
              running={isVisible}
              timerType={TimerTypeEnum.MODAL}
              onFinish={() => {
                onPress();
              }}
            />
          </View>
          <Text style={styles.textContainer}>
            {i18n.t("proctoringLive.stopExamTitle")}
          </Text>
          <Text style={styles.labelContainer}>
            {i18n.t("proctoringLive.stopExamLabel")}
          </Text>
          <View style={styles.buttonContainer}>
            <Button
              label={i18n.t("timerModal.OK")}
              onPress={() => {
                onPress();
              }}
            />
          </View>
        </View>
      </CustomModal>
    );
  }
}

const styles = StyleSheet.create({
  modalContainer: {
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 30
  },
  timerContainer: {
    alignSelf: "flex-end",
    paddingRight: PADDING_SIDES / 2,
    justifyContent: "center",
    alignItems: "center"
  },
  textContainer: {
    alignSelf: "center",
    fontFamily: FONT_GILROY_BOLD,
    fontSize: FONTSIZE_16,
    color: COLOR_BLUE_TESTWE,
    paddingVertical: PADDING_SIDES / 4
  },
  labelContainer: {
    alignSelf: "center",
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_16,
    color: COLOR_BLUE_TESTWE,
    paddingVertical: PADDING_SIDES / 4
  },
  buttonContainer: {
    flex: 2,
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "center"
  }
});
