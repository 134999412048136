import { ipcRenderer } from "electron";
import React from "react";
import { View } from "react-native";
import { deleteFileOnFs } from "../../../../static/fileutils";
import {
  EXAM_FILES,
  IS_PREVIEW,
  IS_WEB_ENABLE,
  SENT
} from "../../../../static/misc/constants";
import { MAX_RETRY_NUMBER } from "../../../../static/misc/network";
import { cipher, enigma } from "../../../../static/misc/utils";
import { uploadDataOnAPI } from "../../../modules/exams/actions/attachedfiles";
import {
  CreateArchiveBeforeUploadAction,
  FILE_TYPE_ARCHIVE,
  FILE_TYPE_ARCHIVE_NAME,
  UploadMediaObjectAction
} from "../../../modules/exams/types/attachedfiles";
import { ExamType } from "../../../modules/exams/types/exam";
import { UpdateProctorArchiveExamStatusAction } from "../../../modules/exams/types/exams";
import {
  ClearPublicKeyAction,
  PublicKeyType
} from "../../../modules/exams/types/publickeys";
import { hasStudentPaper } from "../../../modules/exams/utils";
import {
  EditStudentPaperAction,
  ExportStudentPaperAction,
  PostStudentPaperAction,
  StudentAnswerType,
  StudentPaperType,
  UpdateStudentPaperAction
} from "../../../modules/examTaking/types/studentPaper";
import {
  httpStatus,
  StudentPaperSubmissionAction,
  SubmittingMediasObjectsStatus,
  SubmittingStudentPaperStatus
} from "../../../modules/main/types/status";
import { UserState } from "../../../modules/main/types/user";
import prepareUploadFileToApi from "../../../services/web/mediaService";

export interface StudentPapersSynchronisationHandlerProps {
  // auth & other checks
  authorizationToken?: string;
  isOnline: boolean;
  // user
  studentId: string;
  appDataPath: string | null;
  // status
  canSynchronise: boolean;
  // sp status
  submittingStudentPaperStatus: SubmittingStudentPaperStatus[];
  submittingMediaObjectsStatus: SubmittingMediasObjectsStatus[];
  // exams
  examsPublicKeys: PublicKeyType[];
  studentPapers: StudentPaperType[];
  exams: ExamType[];
  uploadMediaObjectOnAPI: (
    userToken: string,
    examId: string,
    questionId: string,
    filename: string
  ) => UploadMediaObjectAction;
  createArchiveBeforeUpload: (
    userId: string,
    examId: string,
    filename: string,
    archiveType: string
  ) => CreateArchiveBeforeUploadAction;
  updateProctorArchiveExamStatus: (
    examList: string[],
    incomplete?: boolean
  ) => UpdateProctorArchiveExamStatusAction;
  postStudentPaper: (
    studentPaper: StudentPaperType,
    userToken: string,
    status?: string | undefined,
    currentUserId?: string
  ) => PostStudentPaperAction;
  editStudentPaper: (
    currentStudentPaper: StudentPaperType
  ) => EditStudentPaperAction;
  updateStudentPaper: (
    studentPaper: StudentPaperType,
    userToken: string,
    status?: string | undefined
  ) => UpdateStudentPaperAction;
  initStudentPaperSubmission: (
    retry: boolean,
    studentPaper: StudentPaperType
  ) => StudentPaperSubmissionAction;
  stopStudentPaperSubmission: (
    abort: boolean,
    studentPaper: StudentPaperType
  ) => StudentPaperSubmissionAction;
  clearPublicKeys: (examId?: string) => ClearPublicKeyAction;
  exportStudentPaperToFile: (examId: string) => ExportStudentPaperAction;
  currentUser: UserState;
}

interface StudentPapersSynchronisationHandlerState {
  settingMediasTerminated: boolean;
}

class StudentPapersSynchronisationHandler extends React.PureComponent<
  StudentPapersSynchronisationHandlerProps,
  StudentPapersSynchronisationHandlerState
> {
  componentDidMount(): void {
    const { updateProctorArchiveExamStatus } = this.props;
    // TODO SBE: proctoring live in web version
    if (
      !IS_WEB_ENABLE &&
      ipcRenderer.rawListeners("PROCTOR_ARCHIVE_UPLOAD_STATUS").length > 0
    ) {
      ipcRenderer.on("PROCTOR_ARCHIVE_UPLOAD_STATUS", (evt, args) => {
        const data = JSON.parse(args);

        // if status is error, we want to update the proctorArchiveIssue property to display a different status
        updateProctorArchiveExamStatus([data.examId], data.status === "error");
      });
    }
  }

  componentDidUpdate(
    prevProps: StudentPapersSynchronisationHandlerProps
  ): void {
    const {
      isOnline,
      canSynchronise,
      authorizationToken,
      studentPapers,
      submittingStudentPaperStatus,
      currentUser
    } = this.props;

    if (!IS_PREVIEW) {
      // exporting existing student papers as archives
      if (!prevProps.canSynchronise && canSynchronise) {
        this.exportStudentPapers();
      }

      // initiating student paper submission if there is student papers to submit
      if (
        isOnline &&
        !prevProps.canSynchronise &&
        canSynchronise &&
        authorizationToken
      ) {
        studentPapers
          .filter((sp) => sp.userId === currentUser.id)
          .forEach((studentPaper) => {
            if (studentPaper.endDate !== null) {
              this.startStudentPaperSubmission(studentPaper);
            }
          });
      }

      // Checking if there is at least one student paper submitting currently initiated (medias currently uploading) or pending
      const currentSubmittingSp = submittingStudentPaperStatus.some(
        (spStatus) =>
          spStatus.submittingStudentPaperStatus !== httpStatus.ABORTED
      );

      if (
        isOnline &&
        canSynchronise &&
        authorizationToken &&
        currentSubmittingSp
      ) {
        this.submitMediasAndStudentPaper();
      }
    }
  }

  componentWillUnmount(): void {
    // TODO SBE: proctoring live in web version
    if (
      !IS_WEB_ENABLE &&
      ipcRenderer.rawListeners("PROCTOR_ARCHIVE_UPLOAD_STATUS").length > 0
    ) {
      ipcRenderer.removeAllListeners("PROCTOR_ARCHIVE_UPLOAD_STATUS");
    }
  }

  startStudentPaperSubmission(studentPaper: StudentPaperType): void {
    const {
      examsPublicKeys,
      submittingStudentPaperStatus,
      initStudentPaperSubmission
    } = this.props;

    const currentStudentPaperStatus = submittingStudentPaperStatus.find(
      (spStatus) => spStatus.examId === studentPaper.examId
    );

    // Should not happen as successfully submitted student paper should have been remove from the state
    // but just in case
    if (
      currentStudentPaperStatus?.submittingStudentPaper !== true &&
      currentStudentPaperStatus?.submittingStudentPaperStatus ===
        httpStatus.SUCCESS
    ) {
      // TODO should add remove student paper
      return;
    }

    const tmpStudentPaper = { ...studentPaper };
    // If has media, we need to decipher the student answers
    // Also if was not registered for submission, we need to decipher it to check if has media
    if (
      !currentStudentPaperStatus ||
      (currentStudentPaperStatus &&
        currentStudentPaperStatus.submittingMediaObjectsStatus !==
          httpStatus.NONE)
    ) {
      // We decipher it only if typeof string, if object = already deciphered
      if (typeof tmpStudentPaper.studentAnswers === "string") {
        const currentPublicKeyIndex = examsPublicKeys.findIndex(
          (keys) => keys.examId === tmpStudentPaper?.examId
        );
        if (currentPublicKeyIndex >= 0) {
          const currentPublicKey = examsPublicKeys[currentPublicKeyIndex];
          const stringifiedAnswers = enigma(
            currentPublicKey.publicKey,
            tmpStudentPaper.studentAnswers.split("|")
          );
          tmpStudentPaper.studentAnswers = JSON.parse(stringifiedAnswers);
        }
      }
    }
    // Initiating student paper submission if not already registered
    // or if already registered BUT is not running anymore/yet and result was not successful
    if (
      !currentStudentPaperStatus ||
      (currentStudentPaperStatus &&
        currentStudentPaperStatus.submittingStudentPaper !== true &&
        currentStudentPaperStatus.submittingStudentPaperStatus !==
          httpStatus.SUCCESS)
    ) {
      if (
        currentStudentPaperStatus &&
        currentStudentPaperStatus.submittingStudentPaperStatus ===
          httpStatus.FAIL &&
        currentStudentPaperStatus.retryCount < MAX_RETRY_NUMBER
      ) {
        initStudentPaperSubmission(true, tmpStudentPaper);
      } else {
        initStudentPaperSubmission(false, tmpStudentPaper);
      }
    }
  }

  exportStudentPapers(): void {
    const { studentPapers, exportStudentPaperToFile } = this.props;

    studentPapers.forEach((studentPaper) => {
      if (
        studentPaper.endDate &&
        studentPaper.studentAnswers &&
        !(studentPaper.exported && studentPaper.zipped)
      ) {
        exportStudentPaperToFile(studentPaper.examId);
      }
    });
  }

  shouldSendFile(
    submittingMediaObjectsStatus: SubmittingMediasObjectsStatus[],
    studentPaper: StudentPaperType,
    answer: StudentAnswerType,
    mediaName: string
  ): boolean {
    if (mediaName.indexOf("internal-") === -1) return false;

    const mediaStatusIndex = submittingMediaObjectsStatus.findIndex(
      (mediaStatus) =>
        mediaStatus.examId === studentPaper.examId &&
        mediaStatus.questionId.replace("/questions/", "") ===
          answer.question.replace("/questions/", "")
    );
    // Try to send the media to the API
    return (
      mediaStatusIndex > -1 &&
      (submittingMediaObjectsStatus[mediaStatusIndex]
        .submittingMediaObjectStatus === httpStatus.INIT ||
        (submittingMediaObjectsStatus[mediaStatusIndex]
          .submittingMediaObjectStatus === httpStatus.FAIL &&
          submittingMediaObjectsStatus[mediaStatusIndex].retryCount <
            MAX_RETRY_NUMBER))
    );
  }

  // function which is called when the student has not submit his exam already
  // this function will send every medias before the student paper
  submitMediasAndStudentPaper(): void {
    const {
      uploadMediaObjectOnAPI,
      editStudentPaper,
      stopStudentPaperSubmission,
      clearPublicKeys,
      createArchiveBeforeUpload,
      exportStudentPaperToFile,
      examsPublicKeys,
      submittingStudentPaperStatus,
      submittingMediaObjectsStatus,
      studentPapers,
      exams,
      studentId,
      authorizationToken,
      currentUser
    } = this.props;

    if (authorizationToken) {
      // for each student paper in local storage
      studentPapers.forEach((studentPaper) => {
        // retrieving student paper submission status
        const spStatus = submittingStudentPaperStatus.find(
          (spSt) =>
            spSt.examId === studentPaper.examId &&
            hasStudentPaper(studentPaper.examId, currentUser.id, [studentPaper])
        );

        // check the studentpaper status and all medias of the student paper status
        if (
          spStatus &&
          spStatus.submittingStudentPaper === true &&
          spStatus.submittingStudentPaperStatus === httpStatus.INIT &&
          spStatus.submittingMediaObjectsStatus === httpStatus.PENDING &&
          typeof studentPaper.studentAnswers !== "string"
        ) {
          // loop on each answers
          studentPaper.studentAnswers.forEach((answer) => {
            if (IS_WEB_ENABLE) {
              if (answer.mediaData && answer.mediaData.length > 0) {
                // if there is media attached to the answer
                answer.mediaData.forEach((media) => {
                  // If media was not already uploaded
                  if (
                    this.shouldSendFile(
                      submittingMediaObjectsStatus,
                      studentPaper,
                      answer,
                      media.filename
                    )
                  ) {
                    const formData = prepareUploadFileToApi(media);
                    if (formData) {
                      uploadDataOnAPI(
                        authorizationToken,
                        studentPaper.examId,
                        answer.question.replace("/questions/", ""),
                        formData
                      );
                    }
                  }
                });
              }
            } else if (answer.mediaObjects && answer.mediaObjects.length > 0) {
              // if there is media attached to the answer
              answer.mediaObjects.forEach((media) => {
                // If media was not already uploaded
                if (
                  this.shouldSendFile(
                    submittingMediaObjectsStatus,
                    studentPaper,
                    answer,
                    media
                  )
                ) {
                  uploadMediaObjectOnAPI(
                    authorizationToken,
                    studentPaper.examId,
                    answer.question.replace("/questions/", ""),
                    media
                  );
                }
              });
            }
          });
        }
        // if the submitting is failed whith the max retrying number reached or if the status is different
        else if (
          spStatus &&
          ((spStatus.submittingStudentPaperStatus === httpStatus.FAIL &&
            spStatus.retryCount < MAX_RETRY_NUMBER) ||
            spStatus.submittingStudentPaperStatus !== httpStatus.FAIL)
        ) {
          const cipheredStudentPaper = { ...studentPaper };

          // checking if the student paper is not yet ciphered
          if (typeof cipheredStudentPaper.studentAnswers !== "string") {
            const tmpCurrentAnswers: StudentAnswerType[] = [
              ...(cipheredStudentPaper?.studentAnswers as StudentAnswerType[])
            ];

            // retrieving the exam public key to cipher the student paper
            const currentPublicKeyIndex = examsPublicKeys.findIndex(
              (keys) => keys.examId === cipheredStudentPaper?.examId
            );

            // if public key is found, ciphering the student paper
            if (currentPublicKeyIndex >= 0) {
              const currentPublicKey = examsPublicKeys[currentPublicKeyIndex];
              const message = JSON.stringify(tmpCurrentAnswers);
              cipheredStudentPaper.studentAnswers = cipher(
                currentPublicKey.publicKey.toString(),
                message
              ) as any;
            } else {
              ipcRenderer.send(
                "LOG_ERROR",
                `Public key for exam ${cipheredStudentPaper?.examId} not found in store, cannot cipher student paper of id "${cipheredStudentPaper.id}" before submitting it.`
              );
            }
          }

          // after uploading all media objects (or none if no media object associated to the exam),
          // now submitting the student paper and the exam proctor archive
          if (
            spStatus &&
            spStatus.submittingStudentPaperStatus === httpStatus.INIT &&
            (spStatus.submittingMediaObjectsStatus === httpStatus.NONE ||
              spStatus.submittingMediaObjectsStatus === httpStatus.SUCCESS)
          ) {
            this.submitStudentPaper(cipheredStudentPaper);
            const examIndex = exams.findIndex(
              (e) => e.id === cipheredStudentPaper.examId
            );
            if (
              !IS_WEB_ENABLE &&
              examIndex > -1 &&
              (exams[examIndex].examParams?.proctoring ||
                exams[examIndex].examParams?.proctoringLive)
            ) {
              createArchiveBeforeUpload(
                studentId,
                cipheredStudentPaper.examId,
                FILE_TYPE_ARCHIVE_NAME,
                FILE_TYPE_ARCHIVE
              );
            }
          } else if (
            spStatus &&
            spStatus.submittingStudentPaperStatus === httpStatus.SUCCESS
          ) {
            // if student paper was successfully submitted, clearing public keys and deleting media files
            clearPublicKeys(cipheredStudentPaper.examId);
            stopStudentPaperSubmission(false, cipheredStudentPaper);
            this.clearAnswersMediaObjects();
          } else if (
            spStatus &&
            spStatus.submittingStudentPaperStatus === httpStatus.FAIL
          ) {
            // if student paper submission fails, exporting the student paper data on the filesystem (if not already exported)
            // and ensuring the student paper in the store is ciphered
            if (!(studentPaper.exported && studentPaper.zipped)) {
              exportStudentPaperToFile(studentPaper.examId);
            }
            stopStudentPaperSubmission(true, cipheredStudentPaper);
            editStudentPaper(cipheredStudentPaper);
          }
        }
      });
    }
  }

  // student paper submission
  submitStudentPaper(cipheredStudentPaper: StudentPaperType): void {
    const {
      isOnline,
      authorizationToken,
      postStudentPaper,
      updateStudentPaper,
      currentUser
    } = this.props;

    if (authorizationToken && isOnline && cipheredStudentPaper) {
      if (!cipheredStudentPaper.id) {
        // if no id, student paper does not exist in db yet
        postStudentPaper(
          cipheredStudentPaper,
          authorizationToken,
          SENT,
          currentUser.id
        );
      } else {
        updateStudentPaper(cipheredStudentPaper, authorizationToken, SENT);
      }
    }
  }

  // delete files which have been successfully uploaded to the API from filesystem
  clearAnswersMediaObjects(): void {
    const { appDataPath, submittingMediaObjectsStatus } = this.props;

    submittingMediaObjectsStatus.forEach(async (mediaStatus) => {
      if (
        mediaStatus.submittingMediaObjectStatus === httpStatus.SUCCESS &&
        appDataPath
      ) {
        await deleteFileOnFs(mediaStatus.mediaId, appDataPath, EXAM_FILES);
      }
    });
  }

  render(): JSX.Element {
    return <View />;
  }
}

export default StudentPapersSynchronisationHandler;
