import { ipcRenderer } from "electron";
import fse from "fs-extra";
import { reject } from "lodash";
import path from "path";
import {
  IS_PREVIEW,
  IS_WEB_ENABLE,
  MIME_JPEG,
  MIME_MP4,
  MIME_MPEG,
  MIME_WAV,
  MIME_WEBM
} from "./misc/constants";

// Write base64 file on filesystem synchronously (file download when syncing)
export const writeFileOnFs = (
  data: string,
  filename: string,
  folderPath: string,
  folderName: string
): void => {
  try {
    fse.outputFileSync(path.join(folderPath, folderName, filename), data, {
      encoding: "utf-8"
    });
    ipcRenderer.send(
      "LOG_INFO",
      `File ${filename} successfully written at ${folderPath}`
    );
  } catch (err) {
    ipcRenderer.send(
      "LOG_ERROR",
      `File ${filename} could not be written at ${folderPath}`,
      JSON.stringify(err)
    );
    throw err;
  }
};

// Write base64 file on filesystem asynchronously (saving audio/excel files)
export const writeFileOnFsAsync = (
  b64: string,
  filename: string,
  folderPath: string,
  folderName: string
): void => {
  const data = Buffer.from(b64, "base64");

  try {
    // bug on async in renderer
    fse.outputFileSync(path.join(folderPath, folderName, filename), data);
    ipcRenderer.send(
      "LOG_INFO",
      `File ${filename} successfully written at ${folderPath}`
    );
  } catch (err) {
    ipcRenderer.send(
      "LOG_ERROR",
      `File ${filename} could not be written at ${folderPath}`,
      JSON.stringify(err)
    );
  }
};

// Check if file exists on filesystem
export const fileExistsOnFs = (
  filename: string,
  folderPath: string,
  folderName: string
): boolean => {
  if (IS_PREVIEW || IS_WEB_ENABLE) return false;
  try {
    const res = fse.pathExistsSync(path.join(folderPath, folderName, filename));
    return res;
  } catch (err) {
    ipcRenderer.send(
      "LOG_ERROR",
      `An error occurred while checking if file ${filename} exists on filesystem`,
      JSON.stringify(err)
    );
    return false;
  }
};

export const createFileIfNotExist = (filepath: string): void => {
  try {
    const exists = fse.pathExistsSync(filepath);
    if (exists) return;

    fse.outputFileSync(filepath, "");
  } catch (err) {
    ipcRenderer.send(
      "LOG_ERROR",
      `An error occurred while trying to create ${filepath} if not already exist`,
      JSON.stringify(err)
    );
  }
};

export const findFileOnFs = (
  partialName1: string,
  partialName2: string,
  folderPath: string,
  folderName: string
): string | undefined => {
  try {
    const folder = path.join(folderPath, folderName);

    if (!fse.pathExistsSync(folder)) {
      return undefined;
    }

    const filenames = fse.readdirSync(folder);
    if (!filenames || filenames.length === 0) {
      return undefined;
    }

    const res = filenames.find(
      (filename) =>
        filename.includes(partialName1) && filename.includes(partialName2)
    );
    if (!res) {
      ipcRenderer.send(
        "LOG_INFO",
        `File with partial names ${partialName1} and ${partialName2} does NOT exist on filesystem`
      );
      return undefined;
    }

    ipcRenderer.send(
      "LOG_INFO",
      `File with partial names ${partialName1} and ${partialName2} does exist on filesystem`
    );
    return path.join(folder, res);
  } catch (err) {
    ipcRenderer.send(
      "LOG_ERROR",
      `An error occurred while checking if file with partial names ${partialName1} and ${partialName2} exists on filesystem`,
      JSON.stringify(err)
    );
    return undefined;
  }
};

// Delete file on filesystem
export const deleteFileOnFs = async (
  filename: string,
  folderPath: string,
  folderName: string
): Promise<void> => {
  const file = path.join(folderPath, folderName, filename);
  try {
    const exists = await fse.pathExists(file);
    if (exists) {
      fse.remove(file);
      ipcRenderer.send(
        "LOG_INFO",
        `File ${file} successfully removed from filesystem`
      );
    } else {
      ipcRenderer.send(
        "LOG_INFO",
        `Could not delete fle ${file} because it does not exist anymore`
      );
    }
  } catch (err) {
    ipcRenderer.send(
      "LOG_ERROR",
      `Could not delete file ${file}`,
      JSON.stringify(err)
    );
  }
};

export const copyFile = async (
  source: string,
  filename: string,
  folderPath: string,
  folderName: string
): Promise<void> => {
  const folderDest = path.join(folderPath, folderName);
  const dest = path.join(folderPath, folderName, filename);
  try {
    const exists = await fse.pathExists(folderDest);

    if (!exists) {
      ipcRenderer.send(
        "LOG_INFO",
        `Path ${folderDest} does not exist, will be created`
      );
      await fse.mkdirs(folderDest);
    }

    await fse.copy(source, dest);
    ipcRenderer.send(
      "LOG_INFO",
      `File ${filename} successfully saved at ${dest}`
    );
  } catch (err) {
    ipcRenderer.send(
      "LOG_ERROR",
      `Could not save file ${filename} from ${source} tp ${dest}`,
      JSON.stringify(err)
    );
  }
};

export const getDestDirAndExt = (
  examId: string,
  contentType: string
): string[] => {
  let destDir: string;
  let ext: string;

  switch (contentType) {
    case MIME_WEBM: {
      destDir = path.join("proctor", examId, "videos");
      ext = "webm";
      break;
    }
    case MIME_MP4: {
      destDir = path.join("proctor", examId, "videos");
      ext = "mp4";
      break;
    }
    case MIME_WAV: {
      destDir = path.join("proctor", examId, "audios");
      ext = "wav";
      break;
    }
    case MIME_MPEG: {
      destDir = path.join("proctor", examId, "audios");
      ext = "mp3";
      break;
    }
    case MIME_JPEG:
    default: {
      destDir = path.join("proctor", examId, "pictures");
      ext = "jpg";
      break;
    }
  }

  return [destDir, ext];
};

export const writeProctorFile = (
  examId: string,
  filename: string,
  fileContent: string,
  contentType: string,
  appDataDir: string
): Promise<{ filepath: string; filename: string } | null> => {
  const destDirAndExt = getDestDirAndExt(examId, contentType);
  const destDir = destDirAndExt[0];
  const ext = destDirAndExt[1];

  return new Promise((resolve) => {
    try {
      writeFileOnFsAsync(
        fileContent.replace(`data:${contentType};base64,`, ""),
        `${filename}.${ext}`,
        appDataDir,
        destDir
      );
      resolve({
        filepath: path.join(appDataDir, destDir, `${filename}.${ext}`),
        filename: `${filename}.${ext}`
      });
    } catch (err) {
      reject(null);
    }
  });
};

export const prepareProctorData = (
  examId: string,
  filename: string,
  data: string,
  mimeType: string,
  appDataPath: string
): {
  filepath: string;
  filename: string;
  filedata: Buffer;
} => {
  let useMimeType = mimeType;
  const destDirAndExt = getDestDirAndExt(examId, mimeType);
  if (useMimeType === "video/webm") {
    useMimeType = "video/webm;codecs=vp8,opus";
  }
  return {
    filepath: path.join(
      appDataPath,
      destDirAndExt[0],
      `${filename}.${destDirAndExt[1]}`
    ),
    filedata: Buffer.from(
      data.replace(`data:${useMimeType};base64,`, ""),
      "base64"
    ),
    filename: `${filename}.${destDirAndExt[1]}`
  };
};
