import React from "react";
import { Image, StyleSheet, Text, View } from "react-native";
import { COLOR_BLUE_TESTWE, COLOR_RED } from "../../../static/misc/colors";
import {
  EACH_SECONDS,
  FONTSIZE_14,
  FONT_GILROY_REGULAR,
  PADDING_SIDES
} from "../../../static/misc/constants";
import { reloadIcon } from "../../../static/misc/images";
import { guidGenerator } from "../../../static/misc/utils";
import { ExamType } from "../../modules/exams/types/exam";
import { examNeedsCamera, examNeedsMicrophone } from "../../services/equipment";
import i18n from "../../services/i18n";
import {
  PermissionError,
  checkMediaPermissions,
  RequestMediaPermissionsResult
} from "../../services/permissions";
import ReloadView from "../atoms/ReloadView";

interface OnBoardingPermissionsCheckProps {
  currentExam: ExamType;
  onPermissionsValidated: () => void;
}

interface OnBoardingPermissionsCheckState {
  tested: boolean;
  permissions: RequestMediaPermissionsResult;
}

export default class OnBoardingPermissionsCheck extends React.PureComponent<
  OnBoardingPermissionsCheckProps,
  OnBoardingPermissionsCheckState
> {
  interval!: ReturnType<typeof setInterval>;

  permissionsValidated = false;

  mounted = false;

  currentPermissions: RequestMediaPermissionsResult = {
    audioError: null,
    videoError: null,
    errors: []
  };

  constructor(props: OnBoardingPermissionsCheckProps) {
    super(props);

    this.state = {
      tested: false,
      permissions: {
        audioError: null,
        videoError: null,
        errors: []
      }
    };
  }

  componentDidMount(): void {
    const { permissions } = this.state;
    const { currentExam, onPermissionsValidated } = this.props;
    this.mounted = true;
    this.interval = setInterval(async () => {
      if (!this.permissionsValidated) {
        const result = await checkMediaPermissions(
          permissions.audioError,
          permissions.videoError,
          permissions.errors,
          examNeedsMicrophone(currentExam),
          examNeedsCamera(currentExam)
        );
        if (result) {
          if (this.mounted) {
            if (!result.audioError && !result.videoError) {
              this.permissionsValidated = true;
              clearInterval(this.interval);
              onPermissionsValidated();
            } else {
              this.currentPermissions = result;
              this.setState({
                tested: true,
                permissions: result
              });
            }
          }
        }
      }
    }, EACH_SECONDS);
  }

  componentWillUnmount(): void {
    this.mounted = false;
    clearInterval(this.interval);
  }

  render(): JSX.Element {
    const { permissions, tested } = this.state;
    return (
      <View style={styles.container}>
        <Text style={styles.text}>
          {i18n.t("exam.permissions.web.description")}
        </Text>
        {permissions.errors.map((error: PermissionError) => (
          <View style={styles.errorContainer} key={guidGenerator()}>
            <Text style={styles.errorText}>{error.message}</Text>
            {error.image && (
              <Image source={error.image} style={styles.errorImage} />
            )}
          </View>
        ))}
        {(!tested || permissions.audioError || permissions.videoError) && (
          <ReloadView
            runAnimation
            icon={reloadIcon}
            iconStyle={{ width: 16, height: 16 }}
            containerStyle={{
              width: 30,
              height: 30,
              borderRadius: 15,
              backgroundColor: COLOR_RED
            }}
          />
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexShrink: 1,
    flexGrow: 1,
    alignItems: "center"
  },
  text: {
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_14,
    color: COLOR_BLUE_TESTWE,
    marginBottom: PADDING_SIDES * 0.4,
    lineHeight: 20
  },
  errorContainer: {
    alignItems: "center"
  },
  errorText: {
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_14,
    color: COLOR_RED,
    marginBottom: PADDING_SIDES * 0.4,
    lineHeight: 20
  },
  errorImage: {
    width: 400,
    height: 400,
    resizeMode: "contain"
  }
});
