import React from "react";
import { StyleProp, StyleSheet, Text, TextStyle } from "react-native";
import { COLOR_BLUE_TESTWE } from "../../../static/misc/colors";
import {
  FONTSIZE_14,
  FONT_GILROY_REGULAR
} from "../../../static/misc/constants";

interface RegularTextProps {
  textStyle?: StyleProp<TextStyle>[];
  text: string;
  textSetNbOfLines?: number;
}

class RegularText extends React.PureComponent<RegularTextProps> {
  render(): JSX.Element {
    const { textStyle, text, textSetNbOfLines } = this.props;
    return textSetNbOfLines && textSetNbOfLines > 0 ? (
      <Text
        style={[styles.defaultRegularTxt, textStyle]}
        numberOfLines={textSetNbOfLines}
        ellipsizeMode="tail"
      >
        {text}
      </Text>
    ) : (
      <Text style={[styles.defaultRegularTxt, textStyle]}>{text}</Text>
    );
  }
}

const styles = StyleSheet.create({
  defaultRegularTxt: {
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_14,
    color: COLOR_BLUE_TESTWE
  }
});

export default RegularText;
