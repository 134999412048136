import React from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import Button from "./Button";
import { COLOR_GREY_PLACEHOLDER } from "../../../static/misc/colors";
import { FONTSIZE_16, PADDING_SIDES } from "../../../static/misc/constants";
import { guidGenerator } from "../../../static/misc/utils";
import {
  CalculatorButtonType,
  CalculatorButtonTypeEnum
} from "../../modules/examTaking/types/calculator";
import { CALC_BASIC_STRUCTURE } from "../../../static/misc/calculators";

interface CalculatorBasicKeyboardProps {
  addChar: (char: string, buttonType: CalculatorButtonTypeEnum) => void;
  addFunction: (fn: string, waitForUserInput: boolean) => void;
  compute: () => void;
  clearAll: () => void;
  clearCurrentExpression: () => void;
  containerStyle?: StyleProp<ViewStyle>[];
}

class CalculatorBasicKeyboard extends React.PureComponent<CalculatorBasicKeyboardProps> {
  render(): JSX.Element {
    const {
      addChar,
      addFunction,
      compute,
      clearAll,
      clearCurrentExpression,
      containerStyle
    } = this.props;

    return (
      <View style={[containerStyle, styles.keyboardContainer]}>
        {CALC_BASIC_STRUCTURE.map((row: CalculatorButtonType[]) => {
          return (
            <View key={guidGenerator()} style={styles.calculatorRow}>
              {row.map((cell: CalculatorButtonType) => {
                return (
                  <View
                    key={guidGenerator()}
                    style={[{ width: cell.width }, styles.cellContainer]}
                  >
                    <Button
                      onPress={() => {
                        switch (cell.buttonType) {
                          case CalculatorButtonTypeEnum.EQUAL: {
                            compute();
                            break;
                          }
                          case CalculatorButtonTypeEnum.FUNCTION: {
                            if (cell.function) {
                              addFunction(
                                cell.function,
                                cell.waitForUserInput || false
                              );
                            }
                            break;
                          }
                          case CalculatorButtonTypeEnum.NUMBER:
                          case CalculatorButtonTypeEnum.DOT:
                          case CalculatorButtonTypeEnum.OPERATOR:
                          case CalculatorButtonTypeEnum.SIGNCHANGE: {
                            addChar(cell.value || cell.label, cell.buttonType);
                            break;
                          }
                          case CalculatorButtonTypeEnum.AC: {
                            clearAll();
                            break;
                          }
                          case CalculatorButtonTypeEnum.C: {
                            clearCurrentExpression();
                            break;
                          }
                          default:
                            break;
                        }
                      }}
                      label={cell.label}
                      containerStyle={[
                        {
                          borderColor: cell.backgroundColor,
                          backgroundColor: cell.backgroundColor
                        },
                        styles.cell
                      ]}
                      textStyle={{
                        color: cell.color,
                        fontSize: FONTSIZE_16
                      }}
                    />
                  </View>
                );
              })}
            </View>
          );
        })}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  keyboardContainer: {
    flexDirection: "column"
  },
  calculatorRow: {
    width: "100%",
    flexDirection: "row"
  },
  cellContainer: {
    borderWidth: 1,
    borderColor: COLOR_GREY_PLACEHOLDER
  },
  cell: {
    borderRadius: 0,
    paddingVertical: PADDING_SIDES * 0.2,
    paddingHorizontal: PADDING_SIDES * 0.1
  }
});

export default CalculatorBasicKeyboard;
