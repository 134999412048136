import React from "react";
import { StyleSheet, View } from "react-native";
import Button from "./Button";
import { COLOR_GREY_PLACEHOLDER } from "../../../static/misc/colors";
import { FONTSIZE_16, PADDING_SIDES } from "../../../static/misc/constants";
import { guidGenerator } from "../../../static/misc/utils";
import {
  CalculatorButtonType,
  CalculatorButtonTypeEnum
} from "../../modules/examTaking/types/calculator";
import { CALC_COMMON_STRUCTURE } from "../../../static/misc/calculators";

interface CalculatorCommonKeyboardProps {
  memory: (buttonType: CalculatorButtonTypeEnum) => void;
  clearAll: () => void;
  clearCurrentExpression: () => void;
}

class CalculatorCommonKeyboard extends React.PureComponent<CalculatorCommonKeyboardProps> {
  render(): JSX.Element {
    const { memory, clearAll, clearCurrentExpression } = this.props;

    return (
      <View style={styles.keyboardContainer}>
        {CALC_COMMON_STRUCTURE.map((row: CalculatorButtonType[]) => {
          return (
            <View key={guidGenerator()} style={styles.calculatorRow}>
              {row.map((cell: CalculatorButtonType) => {
                return (
                  <View
                    key={guidGenerator()}
                    style={[{ width: cell.width }, styles.cellContainer]}
                  >
                    <Button
                      onPress={() => {
                        switch (cell.buttonType) {
                          case CalculatorButtonTypeEnum.AC: {
                            clearAll();
                            break;
                          }
                          case CalculatorButtonTypeEnum.C: {
                            clearCurrentExpression();
                            break;
                          }
                          default: {
                            if (cell.buttonType) {
                              memory(cell.buttonType);
                            }
                            break;
                          }
                        }
                      }}
                      label={cell.label}
                      containerStyle={[
                        {
                          borderColor: cell.backgroundColor,
                          backgroundColor: cell.backgroundColor
                        },
                        styles.cell
                      ]}
                      textStyle={{
                        color: cell.color,
                        fontSize: FONTSIZE_16
                      }}
                    />
                  </View>
                );
              })}
            </View>
          );
        })}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  keyboardContainer: {
    // flexBasis: "100%",
    flexDirection: "column"
  },
  calculatorRow: {
    flexDirection: "row"
  },
  cellContainer: {
    borderWidth: 1,
    borderColor: COLOR_GREY_PLACEHOLDER
  },
  cell: {
    borderRadius: 0,
    paddingVertical: PADDING_SIDES * 0.2,
    paddingHorizontal: PADDING_SIDES * 0.1
  }
});

export default CalculatorCommonKeyboard;
