import { getLanguage, $messages } from '../locale/locale';

// formulaParser is a Parser object from the hot-formula-parser package
const cellRender = (src, formulaParser) => {
  // If cell contains a formula, recursively parse that formula to get the value
  if (src.length > 0 && src[0] === "=") {
    let formula = src.slice(1);
    const language = getLanguage();
    if (language !== 'en') {
      const formulasTranslated = $messages && $messages[language] && $messages[language].formula ? $messages[language].formula : {};
      const translatedFormulaName = src.slice(1, src.indexOf('(')).toUpperCase();
      const parsersFormulaName = Object.keys(formulasTranslated).find(key =>  formulasTranslated[key] === translatedFormulaName);
      if (parsersFormulaName) {
        const parsersFormula = src.toUpperCase().replace(translatedFormulaName, parsersFormulaName.toUpperCase())
        formula = parsersFormula.slice(1);
      }
    }
    const parsedResult = formulaParser.parse(formula);
    const recursedSrc = parsedResult.error
      ? parsedResult.error
      : parsedResult.result;

    const parsedResultRecurse = cellRender(recursedSrc, formulaParser);
    return parsedResultRecurse;
  }

  // If cell doesn't contain a formula, render its content as is
  return src;
};

export default {
  render: cellRender
};
