import React from "react";
import { StyleSheet, Image, View, ImageSourcePropType } from "react-native";
import {
  COLOR_GREY_BACKGROUND,
  COLOR_GREY_BORDER
} from "../../../static/misc/colors";
import { checkBlue } from "../../../static/misc/images";

interface RadioButtonProps {
  isSelected?: boolean;
  icon?: ImageSourcePropType;
}

class RadioButton extends React.PureComponent<RadioButtonProps> {
  static defaultProps = {
    isSelected: false
  };

  render(): JSX.Element {
    const { isSelected, icon } = this.props;
    return (
      <View style={styles.container}>
        {isSelected && (
          <Image source={icon || checkBlue} style={styles.selectedIcon} />
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLOR_GREY_BACKGROUND,
    borderColor: COLOR_GREY_BORDER,
    borderWidth: 0.5,
    width: 16,
    height: 16,
    borderRadius: 8
  },
  selectedIcon: {
    width: 16,
    height: 16
  }
});

export default RadioButton;
