import React from "react";
import {
  View,
  Text,
  StyleSheet,
  StyleProp,
  TextStyle,
  ImageSourcePropType,
  ViewStyle
} from "react-native";
import { COLOR_WHITE } from "../../../static/misc/colors";
import { FONT_GILROY_BOLD, FONTSIZE_10 } from "../../../static/misc/constants";
import Icon from "./Icon";

interface LabelProps {
  backgroundColor: string;
  text: string;
  textStyle?: StyleProp<TextStyle>;
  icon?: ImageSourcePropType;
  containerStyle?: StyleProp<ViewStyle>;
}

class Label extends React.PureComponent<LabelProps> {
  render(): JSX.Element {
    const {
      backgroundColor,
      text,
      textStyle,
      icon,
      containerStyle
    } = this.props;
    return (
      <View
        style={[styles.labelContainer, containerStyle, { backgroundColor }]}
      >
        {icon && (
          <View>
            <Icon icon={icon} />
          </View>
        )}
        <Text style={[styles.defaultTextStyle, textStyle]}>{text}</Text>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  labelContainer: {
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10.5,
    paddingHorizontal: 5,
    width: "fit-content",
    minWidth: 71,
    height: 16
  },
  defaultTextStyle: {
    alignSelf: "center",
    color: COLOR_WHITE,
    fontFamily: FONT_GILROY_BOLD,
    fontSize: FONTSIZE_10
  }
});

export default Label;
