import React from "react";
import { StyleProp, StyleSheet, Text, TextStyle } from "react-native";
import { COLOR_WHITE } from "../../../static/misc/colors";
import {
  FONTSIZE_13,
  FONT_GILROY_SEMI_BOLD
} from "../../../static/misc/constants";

export interface ButtonLabelProps {
  textStyle?: StyleProp<TextStyle>;
  text: string;
}

class ButtonLabel extends React.PureComponent<ButtonLabelProps> {
  render(): JSX.Element {
    const { textStyle, text } = this.props;
    return <Text style={[styles.defaultText, textStyle]}>{text}</Text>;
  }
}

const styles = StyleSheet.create({
  defaultText: {
    color: COLOR_WHITE,
    fontFamily: FONT_GILROY_SEMI_BOLD,
    fontSize: FONTSIZE_13
  }
});

export default ButtonLabel;
