import React from "react";
import { View, Text, StyleSheet } from "react-native";
import CustomModal from "../atoms/CustomModal";
import i18n from "../../services/i18n";
import {
  FONTSIZE_16,
  FONT_GILROY_BOLD,
  FONT_GILROY_REGULAR,
  PADDING_SIDES
} from "../../../static/misc/constants";
import { COLOR_BLUE_TESTWE } from "../../../static/misc/colors";

export interface PauseExamModalProps {
  isVisible: boolean;
}

export default class PauseExamModal extends React.Component<PauseExamModalProps> {
  shouldComponentUpdate(nextProps: PauseExamModalProps): boolean {
    const { isVisible } = this.props;
    return nextProps.isVisible !== isVisible;
  }

  render(): JSX.Element {
    const { isVisible } = this.props;

    return (
      <CustomModal
        heightModal="20%"
        widthModal="50%"
        borderRadiusModal={5}
        showCloseButton={false}
        visible={isVisible}
        onCloseModal={() => null}
      >
        <View style={styles.modalContainer}>
          <Text style={styles.textContainer}>
            {i18n.t("proctoringLive.pauseExamTitle")}
          </Text>
          <Text style={styles.textLabel}>
            {i18n.t("proctoringLive.pauseExamLabel")}
          </Text>
        </View>
      </CustomModal>
    );
  }
}

const styles = StyleSheet.create({
  modalContainer: {
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 50
  },
  textContainer: {
    alignSelf: "center",
    fontFamily: FONT_GILROY_BOLD,
    fontSize: FONTSIZE_16,
    color: COLOR_BLUE_TESTWE,
    paddingBottom: PADDING_SIDES / 2
  },
  textLabel: {
    alignSelf: "center",
    fontFamily: FONT_GILROY_REGULAR,
    fontSize: FONTSIZE_16,
    color: COLOR_BLUE_TESTWE
  }
});
