import React from "react";
import { StyleProp, StyleSheet, Text, TextStyle } from "react-native";
import { COLOR_BLUE_TESTWE } from "../../../static/misc/colors";
import { FONTSIZE_14, FONT_GILROY_LIGHT } from "../../../static/misc/constants";

interface LightTextProps {
  textStyle?: StyleProp<TextStyle>[];
  text: string;
}

class LightText extends React.PureComponent<LightTextProps> {
  render(): JSX.Element {
    const { textStyle, text } = this.props;
    return <Text style={[styles.defaultLightTxt, textStyle]}>{text}</Text>;
  }
}

const styles = StyleSheet.create({
  defaultLightTxt: {
    fontFamily: FONT_GILROY_LIGHT,
    fontSize: FONTSIZE_14,
    color: COLOR_BLUE_TESTWE
  }
});

export default LightText;
