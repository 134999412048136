/* eslint-disable react/no-unused-state */
import React from "react";
import { View, StyleSheet } from "react-native";
import {
  COLOR_BLUE_TESTWE,
  COLOR_SHADOW,
  COLOR_WHITE
} from "../../../static/misc/colors";
import { FONTSIZE_12, PADDING_SIDES } from "../../../static/misc/constants";
import { DisplayedExamsEnum } from "../../modules/exams/ExamsView";
import { UpdateDisplayedExamsAction } from "../../modules/exams/types/exams";
import i18n from "../../services/i18n";
import TopListMenuItem from "../atoms/TopListMenuItem";
import SelectorField from "../molecules/SelectorField";

interface TopListMenuProps {
  countNextExams: number;
  countPassedExams: number;
  displayedExams: DisplayedExamsEnum;
  selectedAcademicYear: string | undefined;
  academicYearsList: string[];
  updateDisplayedExams: (
    examType: DisplayedExamsEnum
  ) => UpdateDisplayedExamsAction;
  updateSelectedAcademicYear: (index: number) => UpdateDisplayedExamsAction;
}

class TopListMenu extends React.PureComponent<TopListMenuProps> {
  render(): JSX.Element {
    const {
      countNextExams,
      countPassedExams,
      displayedExams,
      selectedAcademicYear,
      academicYearsList,
      updateSelectedAcademicYear,
      updateDisplayedExams
    } = this.props;

    return (
      <View style={styles.topListMenu}>
        <TopListMenuItem
          text={`${i18n.t("homepage.nextexam2")} `}
          countExams={countNextExams}
          isSelected={displayedExams === DisplayedExamsEnum.NEXT_EXAMS}
          onPress={() => updateDisplayedExams(DisplayedExamsEnum.NEXT_EXAMS)}
        />
        <TopListMenuItem
          text={`${i18n.t("homepage.passedExams")} `}
          countExams={countPassedExams}
          isSelected={displayedExams === DisplayedExamsEnum.PASSED_EXAMS}
          onPress={() => updateDisplayedExams(DisplayedExamsEnum.PASSED_EXAMS)}
        />
        <SelectorField
          defaultIndex={
            selectedAcademicYear
              ? academicYearsList.indexOf(selectedAcademicYear)
              : 0
          }
          choiceList={academicYearsList}
          changeChoice={(index: number) => updateSelectedAcademicYear(index)}
          containerStyle={styles.selectorFieldContainer}
          textStyle={styles.selectorText}
          selectorStyle={styles.selector}
          textChevron
          selectorContainerStyle={styles.selectorContainer}
          placeHolder={selectedAcademicYear || i18n.t("exam.allAcademicYears")}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  topListMenu: {
    shadowColor: COLOR_SHADOW,
    shadowOffset: {
      width: 0,
      height: 8
    },
    shadowOpacity: 0.1,
    shadowRadius: 2.22,
    elevation: 3,
    zIndex: 10,
    flexBasis: "fit-content",
    flexDirection: "row",
    paddingHorizontal: PADDING_SIDES * 0.5,
    marginRight: PADDING_SIDES * 0.25
  },
  selectorFieldContainer: {
    flexGrow: 1,
    justifyContent: "flex-end"
  },
  selector: {
    backgroundColor: COLOR_BLUE_TESTWE,
    borderWidth: 0,
    borderRadius: 3
  },
  selectorText: {
    color: COLOR_WHITE,
    padding: PADDING_SIDES * 0.2,
    fontSize: FONTSIZE_12
  },
  selectorContainer: {
    width: 250
  }
});

export default TopListMenu;
