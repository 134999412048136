import { ExamType } from "../../exams/types/exam";
import {
  PostStudentPaperAction,
  UpdateStudentPaperAction
} from "./studentPaper";

export const CLEAR_EXAM_TAKING = "CLEAR_EXAM_TAKING";
export const CREATE_EXAM_TAKING = "CREATE_EXAM_TAKING";
export const UPDATE_EXAM_TAKING = "UPDATE_EXAM_TAKING";

// export const UPLOAD_AUDIO_BLOB = "UPLOAD_AUDIO_BLOB";
export const MODIFY_BOOKMARKED = "MODIFY_BOOKMARKED";
export const START_EXAM = "START_EXAM";
export const END_EXAM = "END_EXAM";

// PDF Highlighting
export const ADD_PDF_HIGHLIGHT = "ADD_PDF_HIGHLIGHT";
export const REMOVE_PDF_HIGHLIGHT = "REMOVE_PDF_HIGHLIGHT";
export const MODIFY_PDF_HIGHLIGHT = "MODIFY_PDF_HIGHLIGHT";

// SPREADSHEET MODIFICATIONS ON THE WEB
export const UPDATE_SPREADSHEET = "UPDATE_SPREADSHEET";

export interface CreateExamTakingAction {
  type: typeof CREATE_EXAM_TAKING;
  payload: ExamType;
}

export interface UpdateExamTakingAction {
  type: typeof UPDATE_EXAM_TAKING;
  payload: ExamType;
}

export interface ModifyBookMarkedAction {
  type: typeof MODIFY_BOOKMARKED;
  payload: any;
}

export interface StartExamAction {
  type: typeof START_EXAM;
  payload: any;
}

export interface EndExamAction {
  type: typeof END_EXAM;
  payload: any;
}

export interface ClearExamTakingAction {
  type: typeof CLEAR_EXAM_TAKING;
  payload: string | undefined;
}

export interface ExamTakingState {
  exams: ExamType[] | undefined;
  cursorExam:
    | {
        examPart: number;
        partIndex: number;
      }
    | undefined; // Current ID of question or excercice inside the exam
  isTakingExam: boolean;
}

export interface PdfhighlightAction {
  type:
    | typeof ADD_PDF_HIGHLIGHT
    | typeof REMOVE_PDF_HIGHLIGHT
    | typeof MODIFY_PDF_HIGHLIGHT;
  payload: any;
}

export interface UpdateSpreadsheetAction {
  type: typeof UPDATE_SPREADSHEET;
  payload: any;
}

export type ExamTakingActionsType =
  | CreateExamTakingAction
  | UpdateStudentPaperAction
  | ModifyBookMarkedAction
  | StartExamAction
  | EndExamAction
  | PostStudentPaperAction
  | ClearExamTakingAction
  | PdfhighlightAction
  | UpdateSpreadsheetAction
  | UpdateExamTakingAction;
